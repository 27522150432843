<template>
  <div class="customerfilter">
    <div class="row">
      <div class="col-12">
        <h4>{{ $t('customerSearch.filterTitle') }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <filteritems
          v-model="filter"
          :customer-types="customerTypes"
          :customer-states="customerStates"
          :interests="interests"
          :friendship-types="friendshipTypes"
          :languages="languageFilters"
          :ages="ages"
          @input="filterChanged"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import filteritems from './FilterItems.vue'
  import * as _ from 'lodash'
  import { FriendManagementDto, FindNetFriendManagementGroupContentCriteria, FindStandardFriendManagementGroupContentCriteria } from '../../types/index'
  import { FilterItem } from '../../types/custom'

  // the data object
  export interface TemplateComponentData {
    filter: FindNetFriendManagementGroupContentCriteria | FindStandardFriendManagementGroupContentCriteria
  }

  export default Vue.extend({
    name: 'CustomerFilter',
    components: { filteritems },
    props: {
      filterType: {
        type: String,
        default: 'VOLUNTEER'
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        filter: this.$store.state.searchFilter
      }
    },
    computed: {
      customerTypes (): Array<FilterItem> {
        var self = this
        if (this.group.netFriendship) {
          return _.map(self.$store.state.netCustomerTypes.filter(x => x.id !== 0), function (val, key) {
            return { id: val.id, name: self.$t('netCustomerTypes.' + val.text) }
          })
        } else {
          return _.map(self.$store.state.customerTypes.filter(x => x.id !== 0), function (val, key) {
            return { id: val.id, name: self.$t('customerTypes.' + val.text) }
          })
        }
      },
      customerStates (): Array<FilterItem> {
        var self = this
        return _.map(this.$store.state.customerStates.filter(x => x.id !== 0), function (val, key) {
          return { id: val.id, name: self.$t('customerStates.' + val.text) }
        })
      },
      interests (): Array<FilterItem> {
        var self = this
        return _.map(this.$store.state.customerInterests.filter(x => x.id !== 0), function (val, key) {
          return { id: val.id, name: self.$t('customerInterests.' + val.text) }
        })
      },
      friendshipTypes (): Array<FilterItem> {
        var self = this
        if (this.group.netFriendship) {
          return _.map(
            _.filter(this.$store.state.netFriendshipTypes.filter(x => x.id !== 0), function (val) {
              return (self.filterType === 'VOLUNTEER')
            }), function (val, key) {
              return { id: val.id, name: self.$t('friendshipTypes.' + val.text) }
            })
        } else {
          return _.map(
            _.filter(this.$store.state.friendshipTypes.filter(x => x.id !== 0), function (val) {
              return (self.filterType === 'VOLUNTEER')
            }), function (val, key) {
              return { id: val.id, name: self.$t('friendshipTypes.' + val.text) }
            })
        }
      },
      languageFilters (): Array<FilterItem> {
        const self = this
        return _.map(this.$store.state.customerLanguages, function (val, key) {
          return { id: val.isoCode, name: self.$t('languages.' + val.isoCode) }
        })
      },
      ages (): Array<FilterItem> {
        const self = this
        return _.map(this.$store.state.preferredAge.filter(x => x.id !== 0), function (val, key) {
          return { id: val.id, name: self.$t('preferredAge.' + val.text).toString() } as FilterItem
        })
      }
    },
    methods: {
      filterChanged (val): void {
        this.$emit('filterchanged', val)
      }
    }
  })
</script>
<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .customerfilter {
    background-color: $brand-white;
    padding-top: 20px;

    h4 {
      margin-bottom:50px;
    }

    @media screen and (max-width: $media-md-max) {
      h4 {
        font-size:1.5em !important;
        margin-bottom: 0;
      }
    }
  }

</style>
