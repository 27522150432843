<template>
  <div class="stepvizualizer">
    <div class="row d-none d-lg-block">
      <div class="col-12">
        <div class="grid">
          <div class="line">
          </div>
          <div class="step spanstep" :class="isPersonalInfoDone">
            <div class="tick">
              <font-awesome-icon icon="check" aria-hidden="true" />
            </div>
            {{ $t('customerForm.standardPersonalInformation') }}
          </div>
          <div class="step" :class="isLanguageInfoDone">
            <div class="tick">
              <font-awesome-icon icon="check" aria-hidden="true" />
            </div>
            {{ $t('customerForm.standardLanguageInformation') }}
          </div>
          <div class="step" :class="isInterestInfoDone">
            <div class="tick">
              <font-awesome-icon icon="check" aria-hidden="true" />
            </div>
            {{ $t('customerForm.myInterests') }}
          </div>
          <div class="step" :class="isFriendInfoDone">
            <div class="tick">
              <font-awesome-icon icon="check" aria-hidden="true" />
            </div>
            {{ $t('customerForm.friendWish') }}
          </div>
          <div class="step spanstep" :class="isContactInfoDone">
            <div class="tick">
              <font-awesome-icon icon="check" aria-hidden="true" />
            </div>
            {{ $t('customerForm.contactPersonInfo') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { AnonStandardCustomerRegistrationStep } from '@/types/custom'

  export default Vue.extend({
    name: 'StepVizualizer',
    props: {
      step: {
        type: Number as () => AnonStandardCustomerRegistrationStep,
        required: true
      }
    },
    computed: {
      isPersonalInfoDone (): string {
        return this.step > AnonStandardCustomerRegistrationStep.PersonalInfo
          ? 'done'
          : ''
      },
      isLanguageInfoDone (): string {
        return this.step > AnonStandardCustomerRegistrationStep.LanguageInfo
          ? 'done'
          : ''
      },
      isInterestInfoDone (): string {
        return this.step > AnonStandardCustomerRegistrationStep.InterestInfo
          ? 'done'
          : ''
      },
      isFriendInfoDone (): string {
        return this.step > AnonStandardCustomerRegistrationStep.FriendInfo
          ? 'done'
          : ''
      },
      isContactInfoDone (): string {
        return this.step > AnonStandardCustomerRegistrationStep.ContactInfo
          ? 'done'
          : ''
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .stepvizualizer {
    .grid {
      display: grid;
      grid-template-columns: 10% 10% 20% 20% 20% 10% 10%;
      grid-template-rows: auto;
      justify-items: center;
      margin-bottom: 20px;
      margin-top: 20px;

      .line {
        grid-column-start: 2;
        grid-column-end:7;
        grid-row-start: 1;
        border: solid 2px $brand-red;
        width: 100%;
        margin-top: 40px;
      }

      .step {
        margin-top: -30px;
        grid-row-start: 2;
        text-align: center;
        font-weight: bold;
        font-size: 14px;

        &.spanstep {
          grid-column-end: span 2;
        }

        &.done {
          .tick {
            border-color: $brand-red;
            background-color: $brand-red;
            color: $brand-white;
          }
        }

        .tick {
          text-align: center;
          margin: 0 auto;
          border-radius: 25px;
          border: solid 2px $brand-grey6;
          z-index: 2;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $brand-grey6;
          font-size: 20px;
          background-color: $brand-grey1;
          margin-bottom: 10px;
        }
      }
    }
  }

</style>