<template>
  <div :class="classCombined">
    <input
      :id="_uid" type="checkbox"
      :disabled="disabled"
      :class="{ 'spr-danger': !isValid }"
      :required="isRequired"
      :checked="value"
      :value="value"
      @change="updateValue($event.target)"
    >
    <label :for="_uid" :aria-label="ariaLabel" :class="{ 'no-wrap': noWrap}">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-if="allowHtml" v-linkified v-html="title" />
      <span v-else>{{ title }}</span>
    </label>
  </div>
</template>

<script>
  export default {
    name: 'SprSimpleCheckbox',
    props: {
      title: {
        type: String,
        default: ''
      },
      allowHtml: {
        type: Boolean,
        default: false
      },
      value: Boolean,
      ariaLabel: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        defaul: false
      },
      noWrap: {
        type: Boolean,
        defaul: false
      },
      hasError: Boolean,
      additionalClass: {
        type: String,
        default: 'pb-3'
      },
      isValid: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      whiteWarnings: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      classCombined: function () {
        var rv = 'spr-simple-checkbox ' + this.additionalClass
        if (this.hasError !== null && this.hasError) {
          if (this.whiteWarnings) { rv += ' white-validation-error' } else { rv += ' validation-error' }
        }
        if (this.disabled) {
          rv += ' disabled'
        }
        return rv
      },
      isRequired: function () {
        if (this.required) {
          return this.required
        } else {
          return false
        }
      }
    },
    methods: {
      updateValue: function (target) {
        if (this.value != null) {
          this.$emit('input', target.checked)
        } else {
          this.$emit('input', true)
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
.no-wrap {
  white-space: nowrap;
}
.spr-danger {
      border: 2px solid $brand-red !important;
      background-color: #ff818cdc;
      accent-color: #ff818cdc;
    }
.spr-simple-checkbox {

  /* hiding the initial actual checkbox */
  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }

  /* the actual label styling */
  input[type="checkbox"]:not(:checked) + label,
  input[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
  }

  /* checkbox aspect */
  input[type="checkbox"]:not(:checked) + label:before,
  input[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 1.5em;
    height: 1.5em;
    border: 2px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  }
  /* checked mark aspect */
  input[type="checkbox"]:not(:checked) + label:after,
  input[type="checkbox"]:checked + label:after {
    content: '\1F5F8';
    position: absolute;
    top: .4em;
    left: .3em;
    font-size: 1.1em;
    line-height: 0.8;
    color: $brand-red;
    transition: all .2s;
    font-weight: bold;
    font-family: $font-bold, Arial, Helvetica, sans-serif;
  }
  /* checked mark aspect changes */
  input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  input[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  input[type="checkbox"]:disabled:not(:checked) + label:before,
  input[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
  input[type="checkbox"]:disabled:checked + label:after {
    color: #999;
  }
  input[type="checkbox"]:disabled + label {
    color: #aaa;
    cursor: not-allowed;
  }

  /* accessibility */
  input[type="checkbox"]:checked:focus + label:before,
  input[type="checkbox"]:not(:checked):focus + label:before {
    border: 2px solid $brand-red;
  }

  label > span {
    display: inline-block;
    margin-top: 2px;
  }

  &.white-validation-error {
    label {
      color: $brand-white;
      font-weight: 900;
    }
  }
  &.spr-danger {
      border: 2px solid $brand-red !important;
      background-color: #ff818cdc;
      accent-color: #ff818cdc;
    }

  &.validation-error{
    label {
      color: red;
    }
  }
}
</style>
