<template>
  <div class="groupall col-12">
    <backbar :title="$t('groups.title')" :subtitle="$t('groups.subTitle')" />
    <div class="group-search">
      <div class="col-12">
        <div class="search-section">
          <div class="advanced-search">
            <groupsearchcriteria @change="onChangedSearchCriteria" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="col-12 pager-header">
        <pager-heading pagesizervisible
                       :total="total" :skip="skip" :take="take"
                       @pagesizechanged="onPageSizeChanged"
        ></pager-heading>
      </div>
      <div class="white-block">
        <div class="row grouplist">
          <div class="col-12">
            <div v-if="groups && groups.length > 0 && !groupsLoading">
              <grouplist :list="groups" :detailed="true" />
            </div>
            <div v-if="(!groups || groups.length === 0) && !groupsLoading" class="nomessages">
              <span>{{ $t('groups.noResults') }}</span>
            </div>
            <div v-if="groupsLoading" class="loading brand-red">
              <loading />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 pager-footer">
        <pager :take="take"
               :skip="skip"
               :total="total"
               :max-display="maxDisplay"
               of-text="/"
               :statisticsvisible="false"
               @pagerchanged="pagerClick"
               @pagerprevious="pagerPrevious"
               @pagernext="pagerNext"
        />
      </div>
    </div>
  </div>
</template>

<script >
  import backbar from '../BackBar.vue'
  import * as _ from 'lodash'
  import loading from '../SPRLoading.vue'
  import groupsearchcriteria from '../groups/GroupSearchCriteria.vue'
  import pagerHeading from '../SPRPagerHeading.vue'
  import pager from '../SPRPager.vue'
  import grouplist from './GroupList.vue'

  export default {
    name: 'GroupAll',
    components: { backbar, loading, groupsearchcriteria, grouplist, pagerHeading, pager },
    data () {
      return {
        groupSearchType: 'actionGroup',
        groupsLoading: true,
        includeAdvancedSearch: true,
        search: '',
        advancedSearchVisible: false,
        searchCriteria: {
          groupId: undefined,
          languages: [],
          isActive: true, // inlude only the Active Groups
          isAllLocations: undefined, // include also the National Groups
          groupTypes: [], //  ActionGroups / OpenGroups
          categories: [], // Many categories (17)
          groupOrganizers: [], // (Note: May conflict with Section Criteria)
          searchWord: '',
          searchWordSeparator: '',
          skip: 0,
          take: 50
        },
        actionTotal: 0,
        standardTotal: 0,
        groups: [],
        total: 0,
        maxDisplay: 5,
        take: 50,
        skip: 0,
        debug: true,
        searchingWithGroupId: false
      }
    },
    computed: {
      groupsTotal () {
        return this.groups?.filter((item) => item.groupType === 1).length
      },
      groupId () {
        return this.$route.query.groupId
      }
    },
    watch: {
      searchCriteria () {
        this.getGroups(this.take, this.skip)
      }
    },
    mounted () {
      if (this.groupId) {
        this.searchingWithGroupId = true
        this.searchCriteria.groupId = this.groupId
      }
      this.getGroups(this.take, this.skip)
    },
    methods: {
      clearQueryString () {
        // Get the current URL
        const currentUrl = new URL(window.location.href)

        // Clear the query parameters
        currentUrl.search = ''

        // Replace the current URL with the new URL (without query string)
        window.history.replaceState({}, '', currentUrl)
      },
      onChangedSearchCriteria (searchCriteria) {
        this.clearQueryString()
        if (JSON.stringify(this.searchCriteria) !== JSON.stringify(searchCriteria)) {
          this.searchCriteria = { ...this.searchCriteria, ...searchCriteria }
          this.skip = 0
        }
      },
      toggleAdvancedSearch () {
        this.advancedSearchVisible = !this.advancedSearchVisible
      },
      getGroups () {
        if (this.searchCriteria === undefined) {
          this.searchCriteria = { languages: [],
                                  isActive: true, // inlude only the Active Groups
                                  isAllLocations: undefined, // include also the National Groups
                                  groupTypes: [], //  ActionGroups / OpenGroups
                                  categories: [], // Many categories (17)
                                  groupOrganizers: [], // (Note: May conflict with Section Criteria)
                                  searchWord: '',
                                  searchWordSeparator: '',
                                  skip: 0,
                                  take: 5
          }
        }
        this.searchCriteria.take = this.take
        this.searchCriteria.skip = this.skip
        this.groupsLoading = true
        const self = this
        var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupspersonal[0]}`
        this.$http.post(url, this.searchCriteria).then((data) => {
          self.groups = data.body?.items.map((item) => { return { ...item, sectionname: this.$store.state.sections.find((s) => s.id === item.sectionId)?.name } })
          self.total = data.body?.total
          if (data.body?.total === 0 && this.searchingWithGroupId) {
            this.showAlertInfo(this.$t('groups.noGroupFoundById'))
          }

          self.groupsLoading = false

          // reset group id. We don't want it included in any future searches.
          // make sure it will be 'cleaned' in the next cycle, to prevent unexpected results.
          if (this.searchCriteria.groupId) {
            this.searchingWithGroupId = false
            this.searchCriteria.groupId = undefined
          }
        })
      },
      onPageSizeChanged (pageSize) {
        this.take = pageSize
        this.getGroups(this.take, this.skip)
      },
      pagerClick (val) {
        this.skip = val.skip
        this.getGroups(this.take, this.skip)
      },
      pagerPrevious () {
        this.skip -= this.take
        this.getGroups(this.take, this.skip)
      },
      pagerNext () {
        this.skip += this.take
        this.getGroups(this.take, this.skip)
      }

    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .pager {
    margin-top: 10px;
  }
  .pager-header {
    margin-bottom: 10px;
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
  }
  .pager-footer {
    max-width: 1140px;
    margin-right: auto;
    margin-left: auto;
  }

  .useradmin-tabs {
    border-bottom: 1px solid #C8C8C8;
    margin-bottom: 10px;
    h4 {
      display: inline-block;
      margin-bottom: -1px;
      font-size: 20px;
      a {
        font-family: $font-bold;
        color: $brand-grey8;
        padding: 10px 15px;
        display: block;
        &.selected {
          color: $brand-red;
          border-bottom: 3px solid $brand-red;
          padding: 10px 15px;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  &.main {
    border-bottom: 1px solid $brand-grey4;
    margin-bottom: 20px;
    h4 {
      display: inline-block;
      margin-bottom: -1px;
      font-size: 28px;
    }
  }
 }
 .white-block {
   background: $brand-white;
   padding: 15px;
  }
  .tab-content {
    &.main {
     padding: 15px;
    }
  }

  .search-section {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
  .search {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
  }
  .advanced-search {
    display: inline-block;
    padding: 10px;
    font-size: 18px;
    text-decoration: none;
    font-family: "Signa", Sans-Serif;
    color: #747473;
    transition: color 0.33s;
  }
  .advanced-search-select{
    flex: 0 0 auto;
    align-self: center;
    margin-left: 20px;
    cursor: pointer;

    :hover {
      color: #000000;
      transition: color 0.33s;
    }
  }

  .language-title {
    padding:0 20px;
    align-self: center;
    margin-bottom: unset;
    color: unset;
  }
  .align-dropdown {
    align-self: center;
  }
  .group-search {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  .language-row {
    display: flex;
    flex-direction: row;
    margin: 16px 0px 16px 15px;
  }

  .group-search-item {
    display: flex;
  }

  .spr-simple-checkbox{
    padding-bottom: unset !important;
  }

  .search-box {
    flex: 1 0 auto;
    padding-top: 4px;
  }

  .language-box {
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
  }

  .groupall {
    .loading {
      margin: 0 auto;
      text-align:center;
      margin-top: 15px;
    }

    .grouplist {
      background-color:$brand-white;
      padding: 15px 0;
    }

  }
</style>
