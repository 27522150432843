<template>
  <span>
    <font-awesome-icon icon="spinner" size="2x" aria-hidden="true" pulse spin fixed-width />
  </span>
</template>

<script>
  export default {
    name: 'SprLoading'
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
svg {
  color: $brand-red;
}

</style>
