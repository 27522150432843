<template>
  <div class="offset-3 col-6 notfound">
    <h1>Sivua ei löytynyt</h1>
  </div>
</template>

<script>
  export default {
    name: 'Notfound'
  }
</script>

<style lang="scss" scoped>
  .notfound {
    min-height: 400px;

    h1 {
         padding-top: 100px;
         text-align: center;
       }
  }
</style>
