<template>
  <div class="container discussion" :class="{'disabled' : messagesLoading}">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div class="row">
          <div class="col-12 my-2 send-message">
            <div class="message-body">
              <textarea v-model="messageBody" rows="3" :placeholder="$t('groups.messageBodyPlaceholder')" />
            </div>
            <div class="message-actions">
              <sprbutton type="primary"
                         :size="4"
                         :title="$t('groups.sendMessage')"
                         class="spr-redborder button-width"
                         click-event="sendMessage"
                         :disabled="messageBody.length === 0"
                         @sendMessage="sendMessageThread"
              />
            </div>
          </div>
        </div>
        <noteview v-show="notes && notes.length > 0"
                  :notes="notes"
                  :groupid="fmgId"
                  :portraits="portraits"
                  :focus="focusMessage"
                  :can-delete="canDelete"
                  :can-send-as-email="false"
                  @removed="removed"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import noteview from '../groups/NotesView.vue'
  import moment from 'moment'

  export default {
    name: 'FriendDiscussion',
    components: { sprbutton, noteview },
    data () {
      return {
        notes: null,
        messageBody: '',
        fmgId: this.$route.params.id,
        messagesLoading: true
      }
    },
    computed: {
      canDelete: function () {
        return (this.hasAdminToFriendManagementGroup(this.fmgId))
      },
      portraits: function () {
        return _.map(this.notes, function (x) {
          return { id: x.senderId, url: x.senderPortrait }
        })
      },
      focusMessage: function () {
        var message = parseInt(this.$route.query.msg)
        return (message && !isNaN(message)) ? message : null
      }
    },

    mounted: function () {
      this.getMessages()
      this.$bus.$on('reply-to-thread', this.handleReply)
    },
    beforeDestroy: function () {
      this.$bus.$off('reply-to-thread')
    },
    methods: {
      handleReply: function (reply, cb) {
        this.sendMessage({
          content: reply.message,
          friendManagementGroupId: this.fmgId,
          parentMessageId: reply.parentId,
          link: this.$route.path
        }, true)
        this.messagesLoading = true
        cb()
      },
      removed: function (x) {
        const self = this
        const url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.removefmgmessage[0]}/${x}`
        this.$http.post(url).then(function (resp) {
                                    self.getMessages()
                                  },
                                  function (y) {
                                    console.log('error removing notification!')
                                  })
      },
      getMessages () {
        this.messagesLoading = true
        var url = this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.fmgmessages[0].replace('{0}', this.fmgId)
        var self = this
        this.$http.get(url).then(resp => {
          self.notes = _.sortBy(resp.body, function (message) {
            return moment(message.sent)
          }).reverse()
          self.messagesLoading = false
        })
      },
      sendMessageThread: function () {
        this.sendMessage({
          content: this.messageBody,
          friendManagementGroupId: this.fmgId,
          link: this.$route.path
        })
      },
      sendMessage: function (messageDto, forceSend) {
        if (this.messageBody.length === 0 && !forceSend) {
          return
        }
        var self = this
        this.sendSuccess = false
        var url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.sendfmgmessage[0]}`
        this.cancelSendMessage()
        this.$http.post(url, messageDto).then((resp) => {
          self.getMessages()
        })
      },
      cancelSendMessage () {
        this.messageBody = ''
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .discussion {
    .message-body {
      textarea {
        margin: 3px 0;
        width: 100%;
      }
    }

    &.disabled {
      pointer-events: none;
      /* for "disabled" effect */
      opacity: 0.5;
    }
  }
</style>