<template>
  <div class="tickcrosswarning">
    <font-awesome-icon v-if="isYes" :icon="['far', 'check-circle']" class="tick" />
    <font-awesome-icon v-if="isNo" :icon="['far', 'times-circle']" class="cross" />
    <font-awesome-icon v-if="isWarning" :icon="['fas', 'exclamation-triangle']" class="warning" />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { YesNoWarning } from '@/types/custom'

  export default Vue.extend({
    name: 'TickCrossWarning',
    props: {
      value: {
        type: Number as () => YesNoWarning,
        default: YesNoWarning.No
      }
    },
    computed: {
      isYes (): boolean {
        return this.value === YesNoWarning.Yes
      },
      isNo (): boolean {
        return this.value === YesNoWarning.No
      },
      isWarning (): boolean {
        return this.value === YesNoWarning.Warning
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .tickcrosswarning {
    display:inline-block;

    svg {
      &.tick {
        color:$brand-success;
      }
      &.cross {
        color:$brand-danger;
      }
      &.warning {
        color:$brand-warning;
      }
    }
  }
</style>
