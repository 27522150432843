<template>
  <div class="row">
    <div class="col-md-5 col-sm-12 user">
      <div class="user-img">
        <sprportrait :style="portraitStyle"
                     class="portrait-size"
        />
      </div>
      <div class="user-name">
        <a href="#">{{ volunteer.name }}</a>
        <span class="">{{ $t('userAdmin.member') }}</span>
      </div>
    </div>
    <div class="col-md-4 col-sm-12 interests">
      <strong>{{ $t('userAdmin.areasOfInterest') }}</strong><br>
      {{ volunteer.interests }}

      <p class="user-groups">
        {{ volunteer.group }}
      </p>
    </div>
    <div class="col-md-3 col-sm-12 actions">
      <sprsimpledropdown v-model="volunteer.roles"
                         :title="$t('userAdmin.addRole')"
                         :items="volunteerRoles"
      />
    </div>
  </div>
</template>

<script>
  import sprsimpledropdown from '../SPRSimpleDropdown.vue'
  import sprportrait from '../SPRPortrait.vue'
  import anonPortrait from '../../assets/img/anon-portrait.png'

  export default {
    name: 'Useradminvolunteer',
    components: { sprsimpledropdown, sprportrait },
    props: {
      volunteer: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        volunteerRoles: [
          { value: 0, text: this.$t('userAdmin.roleChairman') },
          { value: 1, text: this.$t('userAdmin.roleViceChairman') },
          { value: 2, text: this.$t('userAdmin.roleSecretary') },
          { value: 3, text: this.$t('userAdmin.roleFundManager') },
          { value: 4, text: this.$t('userAdmin.roleExecutiveManager') },
          { value: 5, text: this.$t('userAdmin.roleHeadOfMembers') },
          { value: 6, text: this.$t('userAdmin.roleCollectionDirector') },
          { value: 7, text: this.$t('userAdmin.roleHomeContact') },
          { value: 8, text: this.$t('userAdmin.roleEAContact') },
          { value: 9, text: this.$t('userAdmin.roleSchoolContact') },
          { value: 10, text: this.$t('userAdmin.roleYouthContact') },
          { value: 11, text: this.$t('userAdmin.rolePublicist') },
          { value: 12, text: this.$t('userAdmin.roleStandbyContact') },
          { value: 13, text: this.$t('userAdmin.roleFriendContact') },
          { value: 14, text: this.$t('userAdmin.roleEADutyContact') },
          { value: 15, text: this.$t('userAdmin.roleHumanitaryRightsContact') },
          { value: 16, text: this.$t('userAdmin.roleMulticultureContact') },
          { value: 17, text: this.$t('userAdmin.roleHealthContact') },
          { value: 18, text: this.$t('userAdmin.roleFundraisingContact') }
        ]
      }
    },
    computed: {
      portraitStyle: function () {
        var port = (this.$store.state.profile && this.$store.state.profile.portrait) ? this.$store.state.profile.portrait : anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      }
    }
  }
</script>
