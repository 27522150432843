<template>
  <div class="container pooldetail">
    <div class="row header">
      <div class="col">
        <HomeButton
          v-if="group"
          :group-id="group.id"
        />
        <BackButton />
      </div>
    </div>
    <div v-if="!isLoading">
      <PoolPreviewNet
        v-if="group.netFriendship"
        :pool="pool"
        @enable="onEnablePool"
        @disable="onDisablePool"
        @removecustomer="onNetCustomerRemoved"
        @removevolunteer="onNetVolunteerRemoved"
      />
      <PoolPreviewStandard
        v-else
        :pool="pool"
        @enable="onEnablePool"
        @disable="onDisablePool"
        @removecustomer="onStandardCustomerRemoved"
        @removevolunteer="onStandardVolunteerRemoved"
      />
    </div>
    <div v-show="isLoading" class="row">
      <div class="col-12">
        <div class="loading">
          <div class="spinner">
            <loading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import { HttpResponse } from 'vue-resource/types/vue_resource'
  import loading from '../SPRLoading.vue'
  import BackButton from '../BackButton.vue'
  import HomeButton from '../HomeButton.vue'
  import PoolPreviewStandard from './PoolPreviewStandard.vue'
  import PoolPreviewNet from './PoolPreviewNet.vue'
  import { FriendManagementDto, FriendPoolNetDto, FriendPoolStandardDto, FriendPoolCustomerSummaryStandardDto, FriendPoolCustomerSummaryNetDto, FriendPoolVolunteerSummaryStandardDto, FriendPoolVolunteerSummaryNetDto, FriendPoolStatus } from '@/types'

  // the data object
  export interface TemplateComponentData {
    isLoading: boolean,
    group: FriendManagementDto | undefined,
    pool: FriendPoolStandardDto | FriendPoolNetDto | undefined
  }

  export default Vue.extend({
    name: 'PoolDetail',
    components: { loading, BackButton, HomeButton, PoolPreviewStandard, PoolPreviewNet },
    props: {
      groupid: {
        type: Number,
        required: true
      },
      poolid: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        isLoading: true,
        group: undefined,
        pool: undefined
      }
    },
    mounted (): void {
      this.isLoading = true
      this.loadFMG().then(x => {
        this.group = x.data
        return this.loadPool()
      }).then(poolresp => {
        this.pool = poolresp.data
        this.isLoading = false
      }, (err: Response | any) => {
        this.displayError(err)
      })
    },
    methods: {
      loadFMG (): PromiseLike<HttpResponse> {
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.groupid)
        return this.$http.get(url)
      },
      loadPool (): PromiseLike<HttpResponse> {
        var url = this.group?.netFriendship
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.getfriendpoolnet[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.getfriendpoolstandard[0]

        url = url.replace('{poolid}', this.poolid)
        return this.$http.get(url)
      },
      onEnablePool (): void {
        this.setPoolStatus(FriendPoolStatus.Active)
      },
      onDisablePool (): void {
        this.setPoolStatus(FriendPoolStatus.Removed)
      },
      setPoolStatus (status: FriendPoolStatus): void {
        this.isLoading = true
        var url = this.group?.netFriendship
          ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.setfriendpoolstatusnet[0]
          : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.setfriendpoolstatusstandard[0]

        url = url.replace('{poolid}', this.pool?.id).replace('{status}', status)
        this.$http.put(url).then((result) => {
          if (this.pool) {
            this.pool.status = status
            this.isLoading = false
          }
        }, (err: Response | any) => {
          this.displayError(err)
        })
      },
      onStandardCustomerRemoved (customer: FriendPoolCustomerSummaryStandardDto): void {
        if (!this.pool) return
        const url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.removecustomerfrompoolstandard[0].replace('{poolid}', this.pool?.id).replace('{customerid}', customer.customerProfileStandardId)}`
        this.$http.delete(url).then((result) => {
          const pc = this.pool?.customers as FriendPoolCustomerSummaryStandardDto[]
          var cIndex = _.findIndex(pc, (c) => {
            return c.customerProfileStandardId === customer.customerProfileStandardId
          })
          if (cIndex > -1) {
            if (this.pool) {
              this.pool.customers.splice(cIndex, 1)
            }
          }
        }, (err: Response | any) => {
          this.displayError(err)
        })
      },
      onStandardVolunteerRemoved (volunteer: FriendPoolVolunteerSummaryStandardDto): void {
        if (!this.pool) return
        const url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.removevolunteerfrompoolstandard[0].replace('{poolid}', this.pool?.id).replace('{volunteerid}', volunteer.volunteerId)}`
        this.$http.delete(url).then((result) => {
          const pv = this.pool?.volunteers as FriendPoolVolunteerSummaryStandardDto[]
          var cIndex = _.findIndex(pv, (v) => {
            return v.friendVolunteerProfileStandardId === volunteer.friendVolunteerProfileStandardId
          })
          if (cIndex > -1) {
            if (this.pool) {
              this.pool.volunteers.splice(cIndex, 1)
            }
          }
        }, (err: Response | any) => {
          this.displayError(err)
        })
      },
      onNetCustomerRemoved (customer: FriendPoolCustomerSummaryNetDto): void {
        if (!this.pool) return
        const url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.removecustomerfrompoolnet[0].replace('{poolid}', this.pool?.id).replace('{customerid}', customer.customerProfileNetId)}`
        const pc = (this.pool?.customers as any) as FriendPoolCustomerSummaryNetDto[]
        this.$http.delete(url).then((result) => {
          var cIndex: number = _.findIndex(pc, (c: FriendPoolCustomerSummaryNetDto) => {
            return c.customerProfileNetId === customer.customerProfileNetId
          })
          if (cIndex > -1) {
            if (this.pool) {
              this.pool.customers.splice(cIndex, 1)
            }
          }
        }, (err: Response | any) => {
          this.displayError(err)
        })
      },
      onNetVolunteerRemoved (volunteer: FriendPoolVolunteerSummaryNetDto): void {
        if (!this.pool) return
        const url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.removevolunteerfrompoolnet[0].replace('{poolid}', this.pool?.id).replace('{volunteerid}', volunteer.volunteerId)}`
        this.$http.delete(url).then((result) => {
          var pv = this.pool?.volunteers as FriendPoolVolunteerSummaryNetDto[]
          var cIndex: number = _.findIndex(pv, (v: FriendPoolVolunteerSummaryNetDto) => {
            return v.friendVolunteerProfileNetId === volunteer.friendVolunteerProfileNetId
          })
          if (cIndex > -1) {
            if (this.pool) {
              this.pool.volunteers.splice(cIndex, 1)
            }
          }
        }, (err: Response | any) => {
          this.displayError(err)
        })
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .pooldetail {
    .header {
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .loading {
      display: flex;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
      .spinner {
        margin: 30px auto;
        color:$brand-red;
      }
    }
  }
</style>
