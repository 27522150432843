export default {
  methods: {
    proxiedGeocode (address) {
      const uri = `${this.$store.state.apiBases.eventservice}${this.$store.state.apiEndpoints.geocode[0]}?address=${address}`
      return this.$http.get(uri)
    },
    proxiedReverseGeocode (lat, lng) {
      const uri = `${this.$store.state.apiBases.eventservice}${this.$store.state.apiEndpoints.geocode[0]}?latlng=${lat},${lng}`
      return this.$http.get(uri)
    },
    proxiedDistance (origins, destinations, mode) {
      const uri = `${this.$store.state.apiBases.eventservice}${this.$store.state.apiEndpoints.distance[0]}?origins=${origins}&destinations=${destinations}&mode=${mode}&language=${this.$store.state.language}`
      return this.$http.get(uri)
    },
    getTravelDistanceTime (origin, destination, transportation) {
      return new Promise((resolve, reject) => {
        var mode
        switch (transportation) {
          case 1:
            mode = 'driving'
            break
          case 2:
            mode = 'transit'
            break
          case 4:
            mode = 'bicycling'
            break
          default:
            mode = 'walking'
        }
        this.proxiedDistance(origin, destination, mode).then(resp => {
          if (resp) {
            resolve({
              text: `${resp.body.distanceText} / ${resp.body.durationText}`,
              minutes: resp.body.durationMinutes,
              isRealDistance: resp.body.isRealDistance
            })
          } else {
            reject(new Error('No response'))
          }
        }, (err) => {
          console.log('error', err)
          reject(new Error('Failed to get proxied distance'))
        })
      })
    }
  }
}
