<template>
  <div class="spr-simple-textarea pb-3">
    <label v-if="title" :for="_uid" class="cap-label">{{ title }}</label>
    <textarea
      :id="_uid"
      ref="autoHeightTextarea"
      :class="{ 'form-control': true, 'spr-danger': !isValid }"
      :rows="rows"
      :value="textValue"
      @keydown="onKeyDown"
      @input="updateValue($event.target.value)"
    />
    <span v-if="subtext" class="sub-label">{{ subtext }}</span>
    <div v-if="showCounter && !isValid" class="character-counter">
      {{ textValue.length }} / {{ maxCharacters }} {{
        $t('customerForm.characterLimit') }}
    </div>
    <div v-else-if="showCounter" class="character-counter">
      {{ textValue.length }} / {{ maxCharacters }}
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'SPRSimpleTextArea',
    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      rows: {
        type: Number,
        default: 3
      },
      subtext: {
        type: String,
        default: ''
      },
      isValid: {
        type: Boolean,
        default: true
      },
      maxCharacters: Number,
      showCounter: {
        type: Boolean,
        default: false
      },
      autoResize: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      textValue: {
        get () {
          return this.value || ''
        },
        set (val) {
          this.$emit('input', val)
        }
      }
    },
    mounted () {
      this.calculateHeight()
      // Add a window resize event listener
      window.addEventListener('resize', this.handleWindowResize)
    },
    methods: {
      updateValue (val) {
        this.$emit('input', val)
      },
      calculateHeight () {
        if (!this.autoResize) return
        const ta = this.$refs.autoHeightTextarea
        if (ta) {
          ta.style.height = 'auto' // Reset the height to auto
          ta.style.minHeight = ta.scrollHeight + 'px' // Set the height based on content
        }
      },
      handleWindowResize () {
        this.calculateHeight()
      },
      onKeyDown (evt) {
        this.calculateHeight()
        if (this.value.length >= this.maxCharacters) {
          //keycodes 48 - 90 are numbers and  regular letters
          //keycodes 93 - 111 are numpad and operators
          //keycodes 186 - 222 are characters like semicolon, comma, equal sign, period, brackets, quotes etc.
          //keycode 173 = dash, i'm not sure if these are all of the regular keycodes, but should prevent inserting most of the typical ones.
          if ((evt.keyCode >= 48 && evt.keyCode <= 90) || (evt.keyCode >= 93 && evt.keyCode <= 111) || (evt.keyCode >= 186 && evt.keyCode <= 222) || (evt.keyCode === 173)) {
            evt.preventDefault() //this prevents adding the input character into the field.
          }
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.spr-simple-textarea {
  label {
    color: $brand-red;
    padding: 0 20px;
  }

  textarea {
    border-radius: 2em;
    border: solid 2px $brand-grey3;
    padding: 10px; /* Adjust padding */
    overflow: hidden; /* Hide the scrollbar handle */
    scrollbar-width: none; /* Hide the scrollbar track (for Firefox) */

    &:focus-visible {
      color: #495057;
      border: solid 2px $brand-grey5;
      outline: 0;
      box-shadow: 0 0 0 0.2rem $brand-grey3;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
  .spr-danger {
    border: 2px solid $brand-red !important;
  }

  .character-counter {
    font-size: 0.8em;
    color: $brand-red;
  }

}</style>