<template>
  <div class="list-group">
    <div v-for="(item) in list" :key="item.id" class="user-row">
      <useradminvolunteerview :volunteer="item" />
    </div>
  </div>
</template>

<script>
  import useradminvolunteerview from './VolunteersView.vue'

  export default {
    name: 'AdminVolunteersList',
    components: { useradminvolunteerview },
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>
