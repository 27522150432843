<template>
  <div ref="container">
    <div class="row">
      <DropZone container="eventimages" :folder="folder" @image-uploaded="populateImageLib"></DropZone>
      <div
        v-for="(e, i) in imageLib"
        :key="e.url + '_' + i"
        class="col-6 col-sm-6 col-md-4 py-1"
      >
        <img
          :src="e.prev"
          :title="e.filename"
          alt="background image option"
          class="select-thumb"
          :class="{ selected: libImage === e.url, blur: selectedImageIndex === i && contextMenuVisible }"
          @click="libImage = e.url"
          @contextmenu.prevent="showContextMenu($event, e, i)"
        />
      </div>
    </div>
    <div v-if="contextMenuVisible" :style="{ top: contextMenuY + 'px', left: contextMenuX + 'px' }" class="context-menu">
      <ul>
        <li @click="deleteImage(selectedImageIndex)">
          Delete
        </li>
        <li @click="downloadImage(selectedImageIndex)">
          Download
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue'
  import DropZone from '../DropZone/DropZone.vue'
  import { ImageObject } from '@/types/custom'

  export interface TemplateComponentData {
    libImage: string,
    pending: boolean,
    imageLib: Array<ImageObject>,
    contextMenuVisible: boolean,
    contextMenuX: number,
    contextMenuY: number,
    selectedImageIndex: number | null
  }

  export default Vue.extend({
    name: 'EditImages',
    components: { DropZone },
    props: {
      container: {
        type: String,
        required: true
      },
      folder: {
        type: String,
        default: ''
      },
      prefix: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        libImage: '',
        pending: false,
        imageLib: Array<ImageObject>(),
        contextMenuVisible: false,
        contextMenuX: 0,
        contextMenuY: 0,
        selectedImageIndex: null
      }
    },
    created () {
      this.populateImageLib()
    },
    methods: {

      populateImageLib () {
        let url =
          this.$store.state.apiBases.contentservice +
          this.$store.state.apiEndpoints.getimages[0] +
          `/${this.container}`

        if (this.folder && this.folder.length > 0) {
          url = url + '?folder=' + this.folder

          if (this.prefix && this.prefix.length > 0) { url = url + '&prefix=' + this.prefix }
        } else if (this.prefix && this.prefix.length > 0) { url = url + '?prefix=' + this.prefix }

        this.$http.get(url).then(
          (result: any) => {
            if (result.ok === true) {
              this.imageLib = result.body.imageNames.filter((i: string) => i.endsWith('.jpg') || i.endsWith('.png')).map((e: string) => {
                return {
                  filename: e,
                  url: `https://sprvatistorage.blob.core.windows.net/${this.container}/` + e,
                  prev: `https://sprvatistorage.blob.core.windows.net/${this.container}/` + e
                } as ImageObject
              })
            }
            if (result.ok === true) {
              this.pending = false
            }
          },
          (fail) => {
            console.log('Error fetching images:', fail)
            // @ts-ignore cannot find mixins
            this.showAlertError(this.$t('common.fetchFailed'))
          }
        )
      },
      handleClickOutside (event: MouseEvent) {
        const contextMenu = this.$el.querySelector('.context-menu') as HTMLElement
        if (contextMenu && !contextMenu.contains(event.target as Node)) {
          this.contextMenuVisible = false
          this.selectedImageIndex = null
          document.removeEventListener('click', this.handleClickOutside)
        }
      },
      showContextMenu (event: MouseEvent, image: { url: string, prev: string }, index: number) {
        const containerRect = (this.$refs.container as HTMLElement).getBoundingClientRect()
        this.contextMenuVisible = !this.contextMenuVisible
        this.contextMenuX = event.clientX - containerRect.left
        this.contextMenuY = event.clientY - containerRect.top + 100
        this.selectedImageIndex = index
        document.addEventListener('click', this.handleClickOutside)
      },
      deleteImage (index: number | null) {
        if (index !== null) {
          const removedObject = this.imageLib[index] as ImageObject
          this.imageLib.splice(index, 1) // remove the image from the imageLib array
          this.contextMenuVisible = false
          this.selectedImageIndex = null
          document.removeEventListener('click', this.handleClickOutside)

          const url =
            this.$store.state.apiBases.contentservice +
            this.$store.state.apiEndpoints.deleteimage[0] +
            `/${this.container}?filename=${removedObject.filename}`

          this.$http.delete(url)
            .then(
              (result: any) => {
                if (result.ok === true) {
                  this.populateImageLib()
                }
                if (result.ok === true) {
                  this.pending = false
                }
              },
              (fail) => {
                // @ts-ignore cannot find mixins
                this.showAlertError(this.$t('common.fetchFailed'))
              }
            )
        }
      },
      downloadImage (index: number | null) {
        if (index !== null) {
          const downloadObject = this.imageLib[index] as ImageObject
          this.contextMenuVisible = false
          this.selectedImageIndex = null
          document.removeEventListener('click', this.handleClickOutside)

          const url =
            this.$store.state.apiBases.contentservice +
            this.$store.state.apiEndpoints.downloadimage[0] +
            `/${this.container}?filename=${downloadObject.filename}`

          this.$http.get(url, {
            responseType: 'blob' // Ensure responseType is set to 'blob'
          }).then(
            (result: any) => {
              if (result.ok === true) {
                // Check if the response is a valid Blob
                if (result.data instanceof Blob) {
                  const url = window.URL.createObjectURL(new Blob([result.data], { type: 'image/jpeg' }))
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', downloadObject.filename)
                  document.body.appendChild(link)
                  link.click()
                  document.body.removeChild(link)
                }
              }
              if (result.ok === true) {
                this.pending = false
              }
            },
            (fail) => {
              // @ts-ignore cannot find mixins
              this.showAlertError(this.$t('common.fetchFailed'))
            })
        }
      },
      beforeDestroy () {
        document.removeEventListener('click', this.handleClickOutside)
      }
    }
  })
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";
.eventimage {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $brand-grey10;
  background-repeat: no-repeat;
  background-position: center center;
  display: none;
}

.thumbimage {
  position: absolute;
  width: 100px;
  height: 100px;
  right: 50px;
  top: 50px;
  background-size: cover;
  border: 2px solid $brand-red;
  background-color: $brand-grey10;
  background-repeat: no-repeat;
  background-position: center center;
  display: none;
}
.blur {
  filter: blur(5px);
}
.select-thumb {
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  border: 2px solid $brand-white;

  &.selected {
    border: 2px solid $brand-red;
    box-shadow: 0 0 10px $brand-red;
  }
}
.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
}
.context-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.context-menu li {
  padding: 8px 12px;
  cursor: pointer;
}
.context-menu li:hover {
  background-color: #eee;
}

</style>
