<template>
  <div v-if="profile" class="row comp-search-result my-2">
    <div class="col-4 col-sm-2 my-auto">
      <sprportrait :style="portraitStyle" class="portrait-size" />
    </div>
    <div class="col-8 col-sm-3 py-2 my-auto">
      {{ profile.firstName }} {{ profile.lastName }}
    </div>
    <div class="col-12 col-sm-7 my-auto">
      <div v-if="isRoleEditingEnabled">
        <roleEditor v-if="computedRoles && computedUserId" :id="computedUserId" :roles="computedRoles" />
      </div>
      <div v-else class="rolelist">
        <pilllist v-model="computedRoles" />
      </div>
    </div>
  </div>
</template>

<script>
  import roleEditor from './UserRoleEditorInline.vue'
  import sprportrait from '../SPRPortrait.vue'
  import pilllist from '../SPRPillList'
  import anonPortrait from '../../assets/img/anon-portrait.png'

  export default {
    name: 'UserRolesCurrent',
    components: { sprportrait, roleEditor, pilllist },
    props: {
      profile: {
        type: Object,
        default: null
      },
      roles: {
        type: Array,
        default: () => []
      },
      userId: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {}
    },
    computed: {
      portraitStyle: function () {
        var port = this.profile && this.profile.portrait ? this.profile.portrait : anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      },
      computedRoles: function () {
        if (this.roles) {
          return this.roles
        }

        const self = this
        var found = _.find(this.$store.state.sectionRoles, function (x) {
          return self.computedUserId === x.userId
        })

        if (found && found.roles) {
          return found.roles
        }

        return []
      },
      computedUserId: function () {
        return this.userId || this.profile.id
      },
      isRoleEditingEnabled: function () {
        // check that the current user has greater than J2 roles
        return (this.hasRole('Admin') || this.hasRole('DistrictAdmin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee') || this.hasRole('DistrictFriendshipAdmin') || this.hasRole('FriendshipAdmin') || this.hasRole('FriendshipGroupManager') || this.hasRole('Employee') || this.hasRole('J1'))
      }
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
  .comp-search-result {
    background: $brand-white;
    cursor: default;
    border: 2px solid $brand-white;

    &:hover {
      border: 2px solid $brand-red;
    }

    .portrait-size {
      height: 40px;
      width: 40px;
      margin: 10px;
    }

    .rolelist {
      text-align: right;
    }
  }
</style>
