const stringhelper = {
  toCamelCase (str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    }).replace(/\s+/g, '')
  },
  isEmptyOrSpaces (str): boolean {
    return str === null || str.match(/^ *$/) !== null
  }
}

export default stringhelper