<template>
  <div class="external-form">
    <h1>{{ $t('userAdmin.externalForms') }}</h1>
    <div v-if="(!loading && externalFormsAvailable) || isAddFormVisible">
      <ul>
        <li v-for="row in externalForms" :key="row.id">
          <ExternalFormRow
            :name="row.name"
            :link="row.link"
            @delete="deleteRow(row.id)"
          />
        </li>
      </ul>
      <ExternalFormRow
        v-if="isAddFormVisible"
        :is-new="isAddFormVisible"
        @save="addRow($event)"
        @cancel="cancelRow"
      />
    </div>
    <div v-else-if="loading" class="loading text-center brand-red">
      <div class="my-2">
        <loading />
      </div>
      <div class="my-3">
        <p>{{ $t('userAdmin.loading') }}</p>
      </div>
    </div>
    <div v-else class="loading text-center brand-red">
      <div class="my-3">
        {{ $t('userAdmin.externalFormsNotAvailable') }}
      </div>
    </div>
    <sprbutton v-if="!isAddFormVisible" type="primary"
               :size="1"
               :title="$t('userAdmin.externalFormsAdd')"
               click-event="add"
               @add="showAddForm"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'
  import ExternalFormRow from './ExternalFormRow.vue'
  import { ExternalFormUpdateDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    externalForms: ExternalFormUpdateDto[],
    isAddFormVisible: boolean,
    isEditFormVisible: boolean,
    loading: boolean
  }

  export default Vue.extend({
    name: 'ExternalForms',
    components: { sprbutton, loading, ExternalFormRow },
    data (): TemplateComponentData {
      return {
        externalForms: [] as ExternalFormUpdateDto[],
        isAddFormVisible: false,
        isEditFormVisible: false,
        loading: false
      }
    },
    computed: {
      externalFormsAvailable (): boolean {
        return this.externalForms && this.externalForms.length > 0
      }
    },
    created () {
      this.fetchExternalForms()
    },
    methods: {
      async fetchExternalForms () {
        try {
          const url =
            this.$store.state.apiBases.externalformservice +
            this.$store.state.apiEndpoints.externalformsall[0]
          this.loading = true
          const response = await this.$http.get(url).then((response) => {
            this.loading = false
            this.externalForms = response.data as Array<ExternalFormUpdateDto>
          })
        } catch (error) {
          console.error('Error fetching external forms:', error)
        }
      },
      showAddForm () {
        this.isAddFormVisible = true
      },
      // async saveRow (externalForm: { id: number, name: string, link: string }) {
      //   try {
      //     let url =
      //       this.$store.state.apiBases.userservice +
      //       this.$store.state.apiEndpoints.externalformsupdate[0]
      //     url = url.replace('{0}', externalForm.id)

      //     const response = await this.$http.put(url, externalForm)

      //     this.$set(this.externalForms, externalForm.id, externalForm)

      //     const index = this.externalForms.findIndex(form => form.id === externalForm.id)
      //     if (index !== -1) {
      //       this.externalForms.splice(index, 1, response.data)
      //     }

      //     this.isEditFormVisible = false
      //   } catch (error) {
      //     console.error('Error updating external form:', error)
      //   }
      // },
      async addRow (externalForm: {name: string, link: string }) {
        try {
          const url =
            this.$store.state.apiBases.userservice +
            this.$store.state.apiEndpoints.externalformsadd[0]

          const response = await this.$http.post(url, externalForm)
          this.externalForms.push(response.data)
          this.isAddFormVisible = false
        } catch (error) {
          console.error('Error adding external form:', error)
        }
      },
      async deleteRow (id: number) {
        try {
          let url =
            this.$store.state.apiBases.userservice +
            this.$store.state.apiEndpoints.externalformsdelete[0]
          url = url.replace('{0}', id)

          await this.$http.delete(url)
          this.externalForms = this.externalForms.filter(form => form.id !== id)
        } catch (error) {
          console.error('Error deleting external form:', error)
        }
      },
      cancelRow () {
        this.isAddFormVisible = false
        this.isEditFormVisible = false
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  .external-form {
    background: $brand-white;
    padding: 30px 15px;

    .create-row {
      margin-top: 30px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0; /* Optional: to remove default padding */
    margin: 0;  /* Optional: to remove default margin */
  }
</style>
