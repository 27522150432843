<template>
  <div class="pooladdeditnetform">
    <div class="container indicator-stripe">
      <div class="form-container">
        <div class="row">
          <div class="col-12 text-center py-2">
            <sprvalidation :id="'validationTarget' + _uid"
                           :validation-errors="validationErrors"
            />
          </div>
        </div>

        <!-- Basic Info -->
        <div class="row">
          <div class="col-sm-6 sectionheader">
            <h3>{{ $t('poolForm.basicInformation') }}</h3>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <forminput
              v-model="localPool.name"
              v-validate="nameValidator"
              :hint="$t('poolForm.name')"
              :is-valid="!errors.has('poolForm.name')"
              name="poolForm.name"
              :required="true"
            />
          </div>
          <div class="col-12">
            <forminput
              v-model="localPool.shortDescription"
              :hint="$t('poolForm.shortDescription')"
              :maxlength="100"
              :is-valid="true"
            />
          </div>
        </div>
        <!-- Address -->
        <div class="row">
          <div class="col-12">
            <forminput
              v-model="localPool.address"
              :hint="$t('poolForm.address')"
              :is-valid="true"
            />
          </div>
          <div class="col-12 col-sm-4 col-md-3">
            <forminput
              v-model="localPool.postalCode"
              :hint="$t('poolForm.postalCode')"
              :is-valid="true"
            />
          </div>
          <div class="col-12 col-sm-8 col-md-9">
            <forminput
              v-model="localPool.city"
              :hint="$t('poolForm.city')"
              :is-valid="true"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <forminput
              v-model="localPool.contactInfo"
              :hint="$t('poolForm.contactInfo')"
              :maxlength="100"
              :is-valid="true"
            />
          </div>
          <div class="col-12">
            <forminput
              v-model="localPool.assemblyTimes"
              :hint="$t('poolForm.assemblyTimes')"
              :maxlength="100"
              :is-valid="true"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <textareainput
              v-model="localPool.description"
              :title="$t('poolForm.description')"
              :rows="4"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <textareainput
              v-model="localPool.notes"
              :title="$t('poolForm.notes')"
              :rows="4"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-end">
              <sprbutton :size="1"
                         :title="$t('poolForm.save')"
                         click-event="save"
                         class="next-button"
                         icon="chevron-right"
                         @save="save"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="bottomspacer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import { HttpResponse } from 'vue-resource/types/vue_resource'
  import sprbutton from '../SPRButton.vue'
  import forminput from '../SPRSimpleText.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import enums from '../../mixins/enums.js'
  import scroll from '../../mixins/scroll.js'
  import sprvalidation from '../SPRValidationError.vue'
  import { Validator } from 'vee-validate'
  import { FriendPoolNetDto, FriendManagementDto, FriendPoolStatus } from '@/types/index'

  import validatorUniquePoolName from '../../mixins/validator-uniquepoolname'

  Validator.extend('uniquepoolname', validatorUniquePoolName)

  // the data object
  export interface TemplateComponentData {
    isAdd: boolean,
    localPool: FriendPoolNetDto | undefined
  }

  export default Vue.extend({
    name: 'PoolAddEditNetForm',
    components: {
      sprbutton,
      forminput,
      textareainput,
      sprvalidation
    },
    mixins: [enums, scroll],
    props: {
      pool: {
        type: Object as () => FriendPoolNetDto,
        required: false,
        default: undefined
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        isAdd: true,
        localPool: {
          address: '',
          assemblyTimes: '',
          city: '',
          contactInfo: '',
          description: '',
          friendManagementGroupId: this.group.id,
          id: 0,
          name: '',
          notes: '',
          postalCode: '',
          shortDescription: '',
          status: FriendPoolStatus.Active
        } as FriendPoolNetDto
      }
    },
    computed: {
      formTitle (): LocaleMessage | string {
        return this.isAdd ? this.$t('poolForm.addTitle') : this.$t('poolForm.editTitle')
      },
      nameValidator (): string {
        var poolId = this.pool ? this.pool.id : ''
        return `required|uniquepoolname:${poolId},${this.group.id},NET`
      },
      validationErrors (): Array<any> {
        return _.map(this.errors.items, function (value, index) {
          return [value.field, String(value.rule), String(value.msg)]
        })
      }
    },
    mounted (): void {
      if (!this.pool) {
        this.isAdd = true
      } else {
        this.isAdd = false
        this.localPool = this.pool
      }
    },
    methods: {
      save (): void {
        this.$validator.validateAll().then(result => {
          if (result) {
            var promise: PromiseLike<HttpResponse>
            if (this.isAdd) {
              promise = this.add()
            } else {
              promise = this.update()
            }

            promise.then((result) => {
              if (result.ok) {
                let uri = ''
                if (this.isAdd) {
                  uri = `/friend/search/${this.group.id}/pools`
                } else {
                  uri = `/friend/pools/${this.group.id}/${this.pool.id}`
                }
                this.$router.push(uri)
              } else {
                this.displayError(this.$t('common.fetchFailed'))
              }
            }, err => this.displayError(err))
          }
        })
      },
      add (): PromiseLike<HttpResponse> {
        var url = `${this.$store.state.apiBases.friendservice}${this.$store.state.apiEndpoints.addfriendpoolnet[0]}`
        return this.$http.post(url, this.localPool)
      },
      update (): PromiseLike<HttpResponse> {
        var url = `${this.$store.state.apiBases.friendservice}${this.$store.state.apiEndpoints.updatefriendpoolnet[0]}`
        return this.$http.put(url, this.localPool)
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .pooladdeditnetform {
    background-color:$brand-white;

    @media (max-width: $media-lg-max) and (min-width: $media-md) {
      box-shadow: inset 30px 0 0px 0px $pools-color-strong;
    }
    @media (max-width: $media-sm-max) {
      box-shadow: inset 15px 0 0px 0px $pools-color-strong;
    }
    .indicator-stripe {
      @media (min-width: $media-xl) {
        box-shadow: inset 50px 0 0px 0px $pools-color-strong;
      }
    }

    .sectionheader {
      margin: 15px 0;
    }

    .currentgroup {
      font-weight: bold;
      margin-bottom: 20px;
      display:block;
    }

    .form-container {
      margin: 0 auto;
      max-width: 900px;
    }

    .spr-button {
      background-color:$brand-red;
      color:$brand-white;
    }

    .bottomspacer {
      margin-bottom: 50px;
    }
  }
</style>