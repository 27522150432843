<template>
  <div class="deletionthankyou col-12">
    <div class="row mainimage">
      <div class="col-12">
        <div class="titlecontainer">
          <h1>{{ deleteThanksTitle }}</h1>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="content">
              <h4>{{ $t('profile.deleteConfirmTitle') }}</h4>
              <div class="text" v-html="$t('profile.deleteConfirmText')">
              </div>
              <div class="buttons">
                <spr-button
                  :title="buttonTitle"
                  :aria-label="buttonTitle"
                  :primary="true"
                  click-event="goToHomepage"
                  @goToHomepage="onGoToHomepage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import SprButton from '@/components/SPRButton.vue'

  export default Vue.extend({
    name: 'DeletionThankYou',
    components: { SprButton },
    props: {
      deletionrequest: { // from the query
        type: Boolean,
        default: false
      }
    },
    computed: {
      buttonTitle (): string | LocaleMessage {
        return this.$t('profile.toHomepage')
      },
      deleteThanksTitle (): string | LocaleMessage {
        return this.deletionrequest
          ? this.$t('profile.deleteRequestedPageTitle')
          : this.$t('profile.deletePageTitle')
      }
    },
    methods: {
      onGoToHomepage (): void {
        this.$router.push('/')
      }
    }
  })
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .deletionthankyou {
    .mainimage {
      background: url('https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_15.jpg') no-repeat 0 0;
      background-size: cover;
      background-position: center;
      min-height: 400px;
      box-shadow: 2px 2px 5px $brand-grey10;

      .titlecontainer {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        h1 {
          color: $brand-white;
          padding: 15px;
          background-color: $brand-red;
          display: inline-block;
        }
      }
    }
    .content {
      margin-top: 60px;
      background-color: $brand-white;
      padding: 30px;

      .text {
        margin-top: 20px;
      }

      .buttons {
        text-align: center;
        margin-top: 100px;
      }
    }
  }
</style>