<template>
  <div class="comp-useradmin-editor my-2">
    <div v-if="showProfileDeleteOrAnonymize" class="container">
      <anonymizeUserProfile
        title="Delete or Anonymize the User Profile"
        :data="profileUsageReportData"
        :user-id="userId"
        @cancel-delete-user="cancelUserDeletion"
        @delete-user="deleteUser"
      />
    </div>
    <div v-else class="container">
      <div class="row">
        <div class="col-12">
          <backbar />
        </div>
      </div>
      <div v-if="hasLoaded && (user && !user.isNonIdent)">
        <!-- User data editor -->
        <div class="row admin-section">
          <div class="col col-9">
            <div class="user-title">
              <span class="user-name">{{ user.firstName }} <span class="last-name">{{ user.lastName }}</span></span>
            </div>
          </div>
          <div class="col col-3 text-right edit-user">
            <spredittool :is-editing="isUserEditing" @editing="toggleUserEdit" @revert="revertUserEdit" />
          </div>
        </div>
        <div v-if="isUserEditing" class="row admin-section">
          <div class="col-12">
            <usereditform :id="userId" :user="user" :original-section-id="originalSectionId" @input="updateUser" />
          </div>
          <div class="col-12">
            <!-- user save/cancel buttons -->
            <div class="row buttons">
              <div class="col-12 col-sm-7">
                <sprbutton type="primary" :primary="true" :title="$t('userAdmin.editSave')"
                           :size="1" click-event="save" @save="saveUser"
                />
              </div>
              <!-- Initiate User Anonomization or Deletion -->
              <div class="col-12 col-sm-5 delete-user-button">
                <deleteUser
                  :id="userId"
                  :user="user"
                  @initiate-delete-or-anonymize="initiateDeleteOrAnonymize"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row admin-section">
          <div class="col-4 text-center">
            <div class="portrait">
              <sprportrait
                :style="portraitStyle"
                :completion="100"
                class="portrait-size"
              />
            </div>
          </div>
          <div class="col-8">
            <dl>
              <dt>{{ $t('profile.email') }}</dt>
              <dd>{{ user.email }}</dd>
              <dt>{{ $t('profile.phoneNumber') }}</dt>
              <dd>{{ user.phoneNumber }}</dd>
              <dt>{{ $t('profile.address') }}</dt>
              <dd>
                {{ user.streetAddress }},
                {{ user.zipCode }}
                {{ user.city }}
              </dd>
              <dt>{{ $t('profile.dateOfBirth') }}</dt>
              <dd>{{ formattedBirthDate }}</dd>
            </dl>
          </div>
        </div>
      </div>
      <!-- Roles editor -->
      <div v-if="hasLoaded && showRolesEditor" class="roles-section">
        <div class="row admin-section">
          <div class="col col-9">
            <div class="user-roles">
              <h5>{{ $t('profile.roles') }}</h5>
            </div>
          </div>
          <div class="col col-3 text-right edit-roles">
            <spredittool :is-editing="isRolesEditing" @editing="toggleRolesEdit" @revert="revertRolesEdit" />
          </div>
        </div>
        <div v-if="isRolesEditing" class="row admin-section">
          <div class="col-12">
            <roleseditor :id="userId" :roles="roles" @input="updateRoles" />
          </div>
          <div class="col-12">
            <!-- roles save/cancel buttons -->
            <div class="row buttons">
              <div class="col-12">
                <sprbutton type="primary" :primary="true" :title="$t('userAdmin.editSave')"
                           class="my-2 mx-1" :size="1" click-event="save" @save="saveRoles"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row admin-section">
          <div class="col-12 assigned-roles">
            {{ formattedRoles }}
          </div>
        </div>
      </div>
      <!-- Password editor -->
      <div v-if="hasLoaded && showPasswordEditor" class="password-section">
        <div class="row admin-section">
          <div class="col col-9">
            <div class="user-roles">
              <h5>{{ $t('adminUserEditor.password') }}</h5>
            </div>
          </div>
          <div class="col col-3 text-right edit-password">
            <spredittool :is-editing="isPasswordEditing" @editing="togglePasswordEdit" @revert="revertPasswordEdit" />
          </div>
        </div>
        <div v-if="isPasswordEditing" class="row admin-section">
          <div class="col-12">
            <passwordeditor
              :id="userId"
              :user="user"
              @input="updatePassword"
            />
          </div>
        </div>
        <div v-else class="row admin-section">
          <div class="col-12">
            <dl>
              <dt>{{ $t('profile.password') }}</dt>
              <dd>********</dd>
              <dt>{{ $t('adminUserEditor.loginName') }}</dt>
              <dd>{{ user.email }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <!-- loading indicator -->
    <div v-show="!hasLoaded" class="col-12 text-center brand-red my-4">
      <loading />
    </div>

    <!-- non-ident user, no editing capabilities -->
    <div v-if="user && user.isNonIdent" class="col-12 text-center my-4">
      <p>{{ $t('adminUserEditor.nonIdentNoEdit') }}</p>
      <p><a href="javascript:void(0)" @click="back">{{ $t('adminUserEditor.backLink') }}</a></p>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import backbar from '../BackBar.vue'
  import anonymizeUserProfile from './AnonymizeUserProfile.vue'
  import deleteUser from './DeleteUser.vue'
  import spredittool from '../SPREditTool.vue'
  import sprbutton from '../SPRButton.vue'
  import roleseditor from './UserRoleEditor.vue'
  import usereditform from './UserEditorForm.vue'
  import passwordeditor from './UserPasswordEditor.vue'
  import sprportrait from '../SPRPortrait.vue'
  import loading from '../SPRLoading.vue'
  import anonPortrait from '../../assets/img/anon-portrait.png'
  import { ProfileUsageDto, UserProfileDto, RoleDataBatchDto, LoginDataDto } from '@/types'
  import { TranslateResult } from 'vue-i18n'

  // the data object
  export interface TemplateComponentData {
    user: undefined | UserProfileDto,
    roles: undefined | Array<string>,
    userId: string,
    showProfileDeleteOrAnonymize: boolean,
    profileUsageReportData: undefined | ProfileUsageDto,
    isUserEditing: boolean,
    isRolesEditing: boolean,
    isPasswordEditing: boolean,
    loginData: undefined | LoginDataDto,
    originalSectionId: undefined | number
  }

  export default Vue.extend({
    name: 'UserEditor',
    components: {
      anonymizeUserProfile,
      deleteUser,
      spredittool,
      sprbutton,
      roleseditor,
      usereditform,
      passwordeditor,
      sprportrait,
      loading,
      backbar
    },
    props: {
      id: {
        type: String,
        required: true
      },
      returnPath: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        user: undefined,
        roles: undefined,
        userId: '',
        showProfileDeleteOrAnonymize: false,
        profileUsageReportData: undefined,
        isUserEditing: false,
        isRolesEditing: false,
        isPasswordEditing: false,
        loginData: undefined,
        originalSectionId: undefined
      }
    },
    provide (): Record<string, any> {
      return { parentValidator: this.$validator }
    },
    computed: {
      showRolesEditor (): boolean {
        // @ts-ignore Cannot find mixins
        return this.hasRole('Admin') || this.hasRole('Employee') || this.hasRole('DistrictAdmin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee') || this.hasRole('J1') || this.hasRole('J2')
      },
      showPasswordEditor (): boolean {
        // @ts-ignore Cannot find mixins
        return this.hasRole('Admin') || this.hasRole('DistrictAdmin')
      },
      showDelete (): boolean {
        // @ts-ignore Cannot find mixins
        return this.hasRole('Admin') || this.hasRole('DistrictAdmin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee')
      },
      formattedRoles (): string | TranslateResult {
        if (this.roles && this.roles.length) {
          return this.roles
            .map(x => this.$t(`roles.${this.lowerCaseFirstLetter(x)}`))
            .join(', ')
        }
        return this.$t('roles.addRole')
      },
      formattedBirthDate (): string {
        if (!this.user) {
          return ''
        }
        var d = new Date(this.user?.age)
        if (d instanceof Date && !isNaN(d.valueOf())) {
          return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`
        }
        return '-'
      },
      portraitStyle (): string {
        var port = this.user?.portrait || anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      },
      hasLoaded (): boolean {
        return Boolean(this.user && (this.user.isNonIdent || this.roles))
      },
      isEditingOwnProfile (): boolean {
        // @ts-ignore Cannot find mixins
        return this.jwtToken.ProfileId === Number(this.id)
      }
    },
    mounted (): void {
      this.loadUserProfile()
      this.loadRoles()
    },
    methods: {
      loadUserProfile (): void {
        var uriProfile = (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.userbyprofileid[0]).replace('{0}', this.id)
        this.$http.get(uriProfile).then(s => {
          this.user = s.data
          this.originalSectionId = this.user?.sectionId
        }, f => {
          console.log(f)
          // @ts-ignore Cannot find mixins
          this.showAlertPermissionDenied().then(() => {
            this.$router.push('/profile')
          })
        })
      },
      loadRoles (): void {
        var uriRoles = (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.profileroles[0]).replace('{0}', this.id)
        this.$http.get(uriRoles).then(s => {
          this.roles = s.data.roles
          this.userId = s.data.userId
        }, f => {
          console.log(f)
          // @ts-ignore Cannot find mixins
          this.showAlertPermissionDenied().then(() => {
            this.$router.push('/profile')
          })
        })
      },
      toggleUserEdit (): void {
        this.isPasswordEditing = false
        this.isRolesEditing = false
        this.isUserEditing = !this.isUserEditing
      },
      revertUserEdit (): void {
        this.isPasswordEditing = false
        this.isRolesEditing = false
        this.isUserEditing = false
      },
      toggleRolesEdit (): void {
        this.isPasswordEditing = false
        this.isUserEditing = false
        this.isRolesEditing = !this.isRolesEditing
      },
      revertRolesEdit (): void {
        this.isPasswordEditing = false
        this.isUserEditing = false
        this.isRolesEditing = false
      },
      togglePasswordEdit (): void {
        this.isRolesEditing = false
        this.isUserEditing = false
        this.isPasswordEditing = !this.isPasswordEditing
      },
      revertPasswordEdit (): void {
        this.isRolesEditing = false
        this.isUserEditing = false
        this.isPasswordEditing = false
      },
      updateUser (user: UserProfileDto): void {
        this.user = user
      },
      updateRoles (roles: Array<string>): void {
        this.roles = roles
      },
      updatePassword (data: LoginDataDto): void {
        this.loginData = data
        this.isPasswordEditing = false
      },
      saveUser (): void {
        this.$validator.validateAll().then(result => {
          if (result) {
            var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.updateidentuser[0].replace('{0}', this.userId)
            this.$http.put(uri, this.user).then(
              s => {
                this.isUserEditing = false
                this.originalSectionId = this.user?.sectionId
                // need to reload roles, as sometimes changing section will remove roles.
                return this.loadRoles()
              }, f => {
                this.isUserEditing = false
                // @ts-ignore Cannot find mixins
                this.showAlertError(this.$t('common.saveFailed'))
                return Promise.reject(new Error(f))
              })
              .then(() => {
                // @ts-ignore Cannot find mixins
                this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
              })
          }
        })
      },
      saveRoles (): void {
        var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.setroles[0]
        var data: RoleDataBatchDto = {
          userId: this.userId,
          roles: this.roles || []
        }
        this.$http.post(uri, data).then(r => {
          this.isRolesEditing = false
          // @ts-ignore Cannot find mixins
          this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
        }, f => {
          // @ts-ignore Cannot find mixins
          this.showAlertError(this.$t('common.saveFailed'))
        })
      },
      cancelUserDeletion (): void {
        this.showAnonymizeUser(null)
      },
      deleteUser (): void {
        var confirmation = prompt(this.$t('adminUserEditor.deleteConfirmPrompt') + ': ' + this.user?.email)
        if (confirmation === this.user?.email) {
          var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.deleteuser[0] + '/' + this.userId
          this.$http.delete(uri).then(succ => {
            this.$bus.$emit('request-admin-back-after-delete')
          }, fail => {
            console.log(fail)
          })
        }
        this.showAnonymizeUser(null)
      },
      initiateDeleteOrAnonymize (): void {
        var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.userprofileusagebyid[0] + this.userId

        var self = this
        this.$http.get(uri).then(succ => {
          if (succ.data) {
            self.showAnonymizeUser(succ.data)
          }
        }, fail => {
          console.log(fail)
        })
      },
      showAnonymizeUser (data): void {
        if (data) {
          this.showProfileDeleteOrAnonymize = true
          this.profileUsageReportData = data
        } else {
          this.showProfileDeleteOrAnonymize = false
          this.profileUsageReportData = undefined
        }
      },
      lowerCaseFirstLetter (str: string): string {
        return str.charAt(0).toLowerCase() + str.slice(1)
      },
      back (): void {
        this.$router.go(-1)
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
  .comp-useradmin-editor {
    .admin-section {
      background-color: $brand-white;
      padding-top: 15px;
      padding-bottom: 15px;

      &.additional-section {
        margin-top: 15px;
      }
    }
    .roles-section,
    .password-section,
    .delete-section {
      margin-top: 30px;
    }

    .delete-section {
      button {
        padding: 5px 15px;
      }
    }

    .portrait {
      width: 150px;
      height: 150px;
      margin: 0 auto;
      .portrait-size {
        width: 100%;
        height: 100%;
      }
    }

    .user-title {
      margin-top: 10px;
      margin-bottom: 15px;
      .user-name {
        font-weight: bold;
        font-size: 24px;
        .last-name {
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }
    .spr-edit-tool {
      margin-left: 10px;
      margin-top:10px;
      font-size: 18px;
      color: $brand-red;

      a {
        color: $brand-red;
      }
    }
    .buttons {
      justify-content: center;
      button {
        padding: 5px 15px;
      }

      .delete-user-button {
        text-align: right;
      }
      @media (max-width: $media-xs-max) {
        .delete-user-button {
          text-align: left;
          margin-top: 30px;
        }
      }
    }

    .fail {
      color: red;
    }

    .success {
      color: green;
    }
  }
</style>
