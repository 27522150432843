<template>
  <b-form-select v-model="selected">
    <option v-for="(item) in languages" :key="item"
            :value="item"
    >
      {{ $t(alternativePrefix ? alternativePrefix : 'profile.nativeLanguage') }}: {{ normalizeTranslation(item) }}
    </option>
  </b-form-select>
</template>

<script>
  export default {
    name: 'Sprsinglelanguageselector',
    props: {
      value: {
        type: String,
        default: ''
      },
      langPool: {
        type: Array,
        default: () => []
      },
      alternativePrefix: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        selected: this.value
      }
    },
    computed: {
      languages: function () {
        if (this.alternativePrefix === 'join.language') {
          return _.union(this.langPool ? this.langPool : [], this.$store.state.languagesJoin)
        } else {
          return _.union(this.langPool ? this.langPool : [], this.$store.state.languages)
        }
      }
    },
    watch: {
      selected: function (val, old) {
        if (val) {
          this.$emit('input', this.selected)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";
  .custom-select:focus {
      border: solid 2px $brand-grey5;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
  }

</style>
