<template>
  <div class="container-fluid">
    <div>
      <div v-if="results" class="row">
        <div class="col-12">
          <result v-for="(r, i) in results"
                  :key="'resultset_' + _uid + '_' + i + '_' + r.profileId"
                  :volunteer="r"
                  :show-contacted="true"
          />
          <div v-if="total === 0" class="text-center my-4">
            {{ $t('userAdmin.emptySet') }}
          </div>
        </div>
      </div>
    </div>

    <pager :take="take"
           :skip="skip"
           :total="total"
           :max-display="maxDisplay"
           of-text="/"
           @pagesizechanged="onPageSizeChanged"
           @pagerchanged="pagerClick"
           @pagerprevious="pagerPage(-1)"
           @pagernext="pagerPage(1)"
    />
  </div>
</template>

<script>
  import pager from '../SPRPager.vue'
  import result from './UserSearchResult.vue'
  export default {
    name: 'UserListing',
    components: { pager, result },
    props: {
      users: {
        type: Array,
        default: () => []
      }
    },
    data: function () {
      return {
        search: '',
        take: 3,
        skip: 0,
        total: 0,
        maxDisplay: 5
      }
    },
    computed: {
      results: function () {
        return this.users.slice(this.skip, this.skip + this.take)
      }
    },
    mounted: function () {
      this.performSearch(0)
      this.$bus.$on('user-search-research', this.performSearch)
      this.$bus.$on('volunteer-mark-contacted', this.markContacted)
      this.$bus.$on('volunteer-mark-uncontacted', this.markUnContacted)
    },
    beforeDestroy: function () {
      this.$bus.$off('volunteer-mark-contacted')
      this.$bus.$off('volunteer-mark-uncontacted')
    },
    methods: {
      onPageSizeChanged (pageSize) {
        this.take = pageSize
        this.performSearch()
      },
      trainLink: function (newState) {
        return '/admin/training/' + newState
      },
      pagerClick: function (val) {
        this.performSearch(val.skip)
      },
      pagerPage: function (val) {
        if (val < 0) {
          if (this.skip > 0) {
            this.performSearch(Math.max(0, this.skip - this.take))
          }
        } else {
          if (this.take + this.skip < this.total) {
            this.performSearch(Math.min(this.skip + this.take, this.total - 1))
          }
        }
      },
      performSearch: function (newSkip) {
        this.skip = newSkip
        this.total = this.users.length
      },
      markContacted (profileId) {
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.markcontacted[0].replace('{0}', profileId)

        this.$http.get(url).then(
          s => {
            const user = _.find(this.users, function (u) {
              return u.profileId === profileId
            })

            if (user) {
              // not the actual value in the db, but they don't want the item to disappear, which it will on reload, so we just need to give it a val for now.
              user.contactedDate = new Date()
            }
          },
          f => {
            console.log('failed', f)
          }
        )
      },
      markUnContacted (profileId) {
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.markuncontacted[0].replace('{0}', profileId)

        this.$http.get(url).then(
          s => {
            const user = _.find(this.users, function (u) {
              return u.profileId === profileId
            })

            if (user) {
              // not the actual value in the db, but they don't want the item to disappear, which it will on reload, so we just need to give it a val for now.
              user.contactedDate = null
            }
          },
          f => {
            console.log('failed', f)
          }
        )
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .comp-user-search {
    .filter-links {
      a {
        text-transform: uppercase;
        font-weight: bold;
        color: $brand-grey7 !important;

        &.router-link-active {
          color: $brand-red !important;
        }
      }
    }

    .spinner {
      color: $brand-red;
      text-align: center;
    }
  }
</style>
