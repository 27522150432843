<template>
  <div v-if="volunteer" class="container volunteermatchcustomerdetails">
    <div class="row header">
      <div class="col-lg-4 col-xl-3">
        <HomeButton
          v-if="group"
          :group-id="group.id"
        />
        <BackButton />
      </div>
      <div class="col-lg-8 col-xl-9 d-none d-lg-block">
        <h4>{{ $t('customerSearch.customerMatchDetails') }}</h4>
      </div>
    </div>
    <VolunteerNetMatchCustomer
      v-if="group.netFriendship"
      :group="group"
      :customer="customer"
      :volunteer="volunteer"
      @onactivate="onActivate"
      @oncancelpending="onCancelPending"
      @onterminate="onTerminate"
    />
    <VolunteerStandardMatchCustomer
      v-else
      :group="group"
      :customer="customer"
      :volunteer="volunteer"
      @onactivate="onActivate"
      @oncancelpending="onCancelPending"
      @onterminate="onTerminate"
    />
    <div v-show="isLoading" class="loading">
      <div class="spinner">
        <loading />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import VolunteerStandardMatchCustomer from './VolunteerStandardMatchCustomer.vue'
  import VolunteerNetMatchCustomer from './VolunteerNetMatchCustomer.vue'
  import BackButton from '../BackButton.vue'
  import HomeButton from '../HomeButton.vue'
  import loading from '../SPRLoading.vue'
  import { FriendCustomerStandardDto, FriendCustomerNetDto, FriendManagementDto, FriendProfileDto, FriendshipNetDto, FriendshipStandardDto, GetFriendProfileByFriendVolunteerIdCriteria } from '@/types'
  import { HttpResponse } from 'vue-resource/types/vue_resource'

  // the data object
  export interface TemplateComponentData {
    customer: undefined | FriendCustomerStandardDto | FriendCustomerNetDto,
    volunteer: undefined | FriendProfileDto,
    group: undefined | FriendManagementDto,
    isLoading: boolean
  }

  export default Vue.extend({
    name: 'VolunteerMatchCustomerDetails',
    components: { VolunteerStandardMatchCustomer, VolunteerNetMatchCustomer, BackButton, HomeButton, loading },
    props: {
      groupid: {
        type: Number,
        required: true
      },
      customerid: {
        type: Number,
        required: true
      },
      volunteerid: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        customer: undefined,
        volunteer: undefined,
        group: undefined,
        isLoading: false
      }
    },
    created () {
      this.load()
    },
    methods: {
      load (): void {
        this.isLoading = false
        this.getGroup().then(resp => {
          this.group = resp.data
          var promises = [] as Array<PromiseLike<HttpResponse>>

          promises.push(this.getCustomer())
          promises.push(this.getVolunteer())

          Promise.all(promises).then(resp => {
            this.customer = resp[0].data
            this.volunteer = resp[1].data

            if (!this.checkFriendshipExists()) {
              // @ts-ignore
              this.showAlertError(this.$t('common.permissionDenied'))
              this.customer = undefined
              this.volunteer = undefined
            }
          })
        })
      },
      checkFriendshipExists (): boolean {
        // confirm this customer is a friend with this volunteer
        var friendships = this.group?.netFriendship
          ? this.volunteer?.friendInfo.netProfile.friendships
          : this.volunteer?.friendInfo.standardProfile.friendships

        if (this.group?.netFriendship) {
          return Boolean(_.find(friendships, (friendship: FriendshipNetDto) => {
            return friendship.customerProfileNetId === this.customerid
          }))
        } else {
          return Boolean(_.find(friendships, (friendship: FriendshipStandardDto) => {
            return friendship.customerProfileStandardId === this.customerid
          }))
        }
      },
      getGroup (): PromiseLike<HttpResponse> {
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.groupid)
        return this.$http.get(url)
      },
      getVolunteer (): PromiseLike<HttpResponse> {
        // get the updated volunteer with new status from the back end (returns promise)
        var criteria: GetFriendProfileByFriendVolunteerIdCriteria = {
          friendManagementGroupId: this.groupid,
          friendVolunteerId: this.volunteerid,
          includeFriendships: true,
          includeFriendPools: true
        }
        var getVolunteerUrl = this.group?.netFriendship
          ? `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteergetnetprofilebyid[0]}`
          : `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteergetstandardprofilebyid[0]}`
        return this.$http.post(getVolunteerUrl, criteria)
      },
      getCustomer (): PromiseLike<HttpResponse> {
        var getCustomerUrl = this.group?.netFriendship
            ? `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customergetnetbyid[0]}`.replace('{customerid}', this.customerid.toString()).replace('{groupid}', this.groupid.toString())
            : `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customergetstandardbyid[0]}`.replace('{customerid}', this.customerid.toString()).replace('{groupid}', this.groupid.toString())
        return this.$http.get(getCustomerUrl)
      },
      onActivate () {
        this.load()
      },
      onCancelPending () {
        this.$router.push(`/friend/volunteerpreview/${this.groupid}/${this.volunteerid}`)
      },
      onTerminate () {
        this.$router.push(`/friend/volunteerpreview/${this.groupid}/${this.volunteerid}`)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteermatchcustomerdetails {
    .header {
      margin-top: 10px;
      margin-bottom: 15px;
      .nav-header {
        border-bottom: 0;
      }
      h4 {
        padding-left: 10px;
      }
    }
  }
</style>
