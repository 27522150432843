<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .payment-provider {
    margin: 2px;
    background: $brand-white;
    padding: 16px 6px;
    border-radius: 5px;
    border: 3px solid $brand-white;
    transition: border-color 0.25s;

    &:hover
    {
      border: 3px solid $brand-grey3;
      transition: border-color 0.25s;
    }

    input[type=submit] {
      border: none;
      height: 30px;
      width: 100px;
      cursor: pointer;
    }
  }
</style>

<template>
  <div class="payment-provider">
    <form :id="'payment_' + _uid"
          method="POST" :action="provider.url" @submit.prevent="$bus.$emit('payment-selected', $event)"
    >
      <input v-for="(parameter, index) in provider.parameters" :key="index" type="hidden" :name="parameter.name" :value="parameter.value">
      <input type="submit" value="" :style="'background: url(' + provider.svg + ') no-repeat center center;'">
    </form>
  </div>
</template>

<script>
  export default {
    name: 'PaymentProvider',
    props: {
      provider: {
        type: Object,
        required: true
      }
    },
    data: function () {
      return {
        //
      }
    },
    methods: {
      // not yet
    }
  }
</script>
