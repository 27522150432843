<template>
  <div class="fill-width comp-wizard-content">
    <div>
      <span class="h1"
            role="heading"
            aria-level="1"
      >{{ $t('wizard.headerFive') }}</span><br>
      <sprvalidation class="validation-box"
                     :validation-errors="validationErrors"
      /><br>
      <p>{{ $t('wizard.ingressTransport') }}</p>
    </div><br>
    <sprcheckbox v-for="(choice, index) in $store.state.transportation"
                 :key="'wizard_5_' + index"
                 :value="choice.id"
                 :title="$t('transportation.' + choice.text)"
                 click-event="box-selected"
                 :collection="$store.state.wizardModel.transportation"
                 @box-selected="handleCheckbox"
    /><br>
    <p class="or-span">
      {{ $t('wizard.reasonableTravel') }}
    </p><br>
    <div class="inputContainer">
      <sprinput v-model="travelTime"
                v-validate="'required|numeric'"
                :hint="hintTime"
                name="timeField"
                class="time-box"
                :center-text="true"
                :is-valid="!errors.has('timeField')"
      />
      <span class="unitLabel">min</span>
    </div>
  </div>
</template>

<script>
  import sprcheckbox from '../SPRCheckbox.vue'
  import sprinput from '../SPRInput.vue'
  import sprvalidation from '../SPRValidationError.vue'

  export default {
    name: 'WizardFive',
    components: { sprcheckbox, sprinput, sprvalidation },
    data () {
      return {
        travelTime: this.$store.state.wizardModel.travelTime && this.$store.state.wizardModel.travelTime !== '' ? this.$store.state.wizardModel.travelTime : '60',
        hintTime: '60',
        validatedFields: ['timeField'],
        selected: new Set()
      }
    },
    computed: {
      validationErrors: function () {
        if (!this.validatedFields) {
          return []
        }

        var arr = []
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['', err.field + '_' + err.rule, ''])
          }
        })
        return arr
      }
    },
    watch: {
      travelTime: function (val) {
        this.errors.remove('timeField')
        this.vuixCommit()
      }
    },
    methods: {
      handleCheckbox: function (value, isChecked) {
        if (isChecked) {
          this.selected.add(value)
        } else {
          this.selected.delete(value)
        }

        this.vuixCommit()
      },
      vuixCommit: function () {
        var arr = [...this.selected]
        this.$store.commit('setWizardTransportation', arr)
        this.$store.commit('setWizardTravelTime', this.travelTime)
      },
      validateSection: function () {
        return this.$validator.validateAll()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

  .inputContainer {
    position: relative;

    .greyed {
      color: $brand-grey8;
    }

    .unitLabel {
      display:inline-block;
      top: calc(50% - 7px);
      pointer-events: none;
      font-family: "Signa-Light", Sans-Serif;
      font-size: 14px;
      color:$brand-white;
    }

    .time-box {
      max-width: 100px;
    }

    .invisible {
      visibility: hidden;
    }
  }
</style>
