<template>
  <div v-if="localCustomer" class="friendinfo">
    <div class="row">
      <div class="col-12">
        <!-- friend wish section -->
        <div class="row">
          <div class="col">
            <h3>{{ $t('customerForm.friendWish') }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-4 col-md-3">
            <span class="cap-label">{{ $t('customerForm.genderPreferences') }}</span>
            <checkboxlist v-model="localCustomer.preferredGenders"
                          :list="genders"
                          :rows="1"
            />
            <div v-if="mustHaveOneGenderSelected && pressedNext" class="validation">
              {{ $t('customerForm.genderPreferencesNotSelected') }}
            </div>
          </div>
          <div class="col-12 offset-sm-2 col-sm-6 col-md-7">
            <span class="cap-label">{{ $t('customerForm.desiredAge') }}</span>
            <checkboxlist v-model="localCustomer.preferredAges"
                          :list="preferredAges"
                          :rows="2"
            />
            <div v-if="mustHaveOneAgeGroupSelected && pressedNext" class="validation">
              {{ $t('customerForm.desiredAgeNotSelected') }}
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <sprtextarea
              v-model="localCustomer.description"
              :title="$t('customerForm.netDescriptionTitle')"
              :rows="6"
              :subtext="$t('customerForm.netDescription')"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="buttons">
          <prevnextbuttons
            @prev="onPrevClicked"
            @next="onNextClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprtextarea from '../SPRSimpleTextArea.vue'
  import checkboxlist from '../SPRSimpleCheckboxBitmaskList.vue'
  import prevnextbuttons from '../SPRPrevNextButtons.vue'
  import { FriendCustomerNetDto } from '@/types/index'
  import { SprCheckboxBitmaskValue, SprCheckboxValue } from '@/types/custom'
  import inpututils from '@/mixins/inpututils'
  import bitmaskhelper from '@/helpers/bitmaskhelper'

  // the data object
  export interface TemplateComponentData {
    localCustomer: FriendCustomerNetDto | undefined,
    pressedNext: boolean
  }

  export default Vue.extend({
    name: 'FriendInfo',
    components: { sprtextarea, checkboxlist, prevnextbuttons },
    mixins: [inpututils],
    props: {
      customer: {
        type: Object as () => FriendCustomerNetDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        localCustomer: undefined,
        pressedNext: false
      }
    },
    computed: {
      genders (): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.preferredGenders, function (val, key) {
          return {
            value: val.id,
            text: self.$t('genders.' + val.text),
            selected: bitmaskhelper.bitmaskToArray(self.customer.preferredGenders).indexOf(val.id) >= 0
          } as SprCheckboxBitmaskValue
        }).filter((x: SprCheckboxBitmaskValue) => x.value !== 0)
      },
      mustHaveOneAgeGroupSelected (): boolean {
        if (this.localCustomer) { return this.localCustomer.preferredAges === 0 }
        return false
      },
      mustHaveOneGenderSelected (): boolean {
        if (this.localCustomer) { return this.localCustomer?.preferredGenders === 0 }
        return false
      },
      preferredAges (): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.preferredAge, function (val, key) {
          return {
            value: val.id,
            text: self.$t('preferredAge.' + val.text),
            selected: bitmaskhelper.bitmaskToArray(self.customer.preferredAges).indexOf(val.id) >= 0
          } as SprCheckboxBitmaskValue
        }).filter((x: SprCheckboxBitmaskValue) => x.value !== 0)
      }
    },
    mounted (): void {
      this.localCustomer = this.customer
    },
    methods: {
      onPrevClicked (): void {
        this.$emit('prev', this.localCustomer)
      },
      onNextClicked (): void {
        this.pressedNext = true
        if (!(this.mustHaveOneAgeGroupSelected || this.mustHaveOneGenderSelected)) {
          this.$validator.validateAll().then(result => {
            if (result) {
              this.$emit('next', this.localCustomer)
            }
          })
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";

  .validation {
    box-sizing: border-box;
    color: #CC0000;
    background: #FFFFFF;
    display: inline-block;
    position: relative;
    align-content: center;
    flex-flow: row;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    padding: 10px;
    margin: 0;
  }
  .friendinfo {
    background-color: $brand-white;
    padding: 15px;
    padding-top: 30px;

    .headertitle {
      margin-bottom: 30px;
    }

    .cap-label {
      color: $brand-red;
    }

    .buttons {
      padding-top: 30px;
    }
  }

</style>