<!-- Use this editor to edit Flag value (decoded enum) -->
<template>
  <div v-if="isLegal" class="comp-profile-editor-pill row">
    <div class="col-12 text-center">
      <span class="cap-label py-5 d-inline-block">{{ $t('profile.checkboxEditorIngress') }}</span>
    </div>
    <div class="col-12 text-center">
      <sprcheckbox v-for="(entry) in wholeSet"
                   :key="'cb_' + _uid + '_' + entry"
                   :title="$t(translationPrefix + '.' + entry)"
                   click-event="on-click"
                   :value="resolveId(entry)"
                   :tabindex="0"
                   :collection="actualData"
                   class="checkbox"
                   @on-click="editSelection"
      />
    </div>
    <div v-if="isLoading" class="brand-red centerloading">
      <loading />
    </div>
    <!-- Save and cancel buttons -->
    <div class="row my-5" style="padding-top: 5%">
      <div class="col-3"></div>
      <div class="col-3 col-md-3 my-1">
        <sprbutton click-event="save-event" :title="$t('profile.save')"
                   :size="1"
                   :bordersize="2" class="fill-width" @save-event="save"
        />
      </div>
      <div class="col-3 col-md-3 my-1">
        <sprbutton click-event="cancel-event" :title="$t('profile.cancel')"
                   :size="1"
                   :bordersize="2" class="fill-width" @cancel-event="cancelButton"
        />
      </div>
    </div>
  </div>
  <div v-else />
</template>
<script lang = "ts">
  import sprcheckbox from '../SPRCheckbox.vue'
  import Vue from 'vue'
  import { TranslateResult } from 'vue-i18n'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'

  import enumhelper from '../../mixins/enums.js'

  // the data object
  export interface TemplateComponentData {
    hasChanges: boolean,
    isLoading: boolean,
    data: any
  }
  export default Vue.extend({
    name: 'ProfileEditorPills',
    components: { sprcheckbox, sprbutton, loading },
    mixins: [enumhelper],
    props: {
      editor: {
        type: String,
        default: ''
      },
      isOwnProfile: {
        type: Boolean,
        default: true
      }
    },
    data (): TemplateComponentData {
      return {
        //
        data: this.$store.state.profile.involvedEventCategories,
        isLoading: false,
        hasChanges: false
      }
    },
    computed: {
      actualData: function () {
        switch (this.editor) {
        case 'involvedEventCategories':
          var involvedEventCategories = this.data
          // @ts-ignore - ts intellisense not finding mixins
          var activeItems = this.getProfileInvolvedEventCategoriesMaskItems(involvedEventCategories)
          return activeItems
        }
        return []
      },
      objectSet: function () {
        switch (this.editor) {
        case 'involvedEventCategories':
          return this.$store.state.translations[this.firstLanguage].involvedEventCategories
        }
        return []
      },
      idSet: function () {
        switch (this.editor) {
        case 'involvedEventCategories':
          return this.$store.state.profile.involvedEventCategories
        }
        return []
      },
      wholeSet: function () {
        return Object.keys(this.objectSet)
      },
      vuixMutation: function () {
        if (this.isLegal) {
          return 'profile' + this.editor
        }
        return ''
      },
      isLegal: function () {
        return (this.editor === 'involvedEventCategories')
      },
      translationPrefix: function () {
        return this.isLegal ? this.editor : ''
      }
    },
    mounted: function () {
      if (!this.isLegal) {
        this.$router.push('/profile')
      }
    },
    methods: {
      addOption: function (value) {
        switch (this.editor) {
        case 'involvedEventCategories':
          this.data = this.data + value
        }
      },
      removeOption: function (value) {
        switch (this.editor) {
        case 'involvedEventCategories':
          this.data = this.data - value
        }
      },
      editSelection: function (value, truth) {
        if (truth) {
          // Value was just enabled
          if (this.actualData.indexOf(value) === -1) {
            // Actual data does not already contain this item, add it
            this.addOption(value)
          }
        } else {
          // Value was just disabled
          var index = this.actualData.indexOf(value)
          if (index !== -1) {
            // Actual data does already contain this item, remove it
            this.removeOption(value)
          }
        }
        if (!_.isEqual(this.$store.state.profile.involvedEventCategories, this.data)) {
          this.hasChanges = true
        } else {
          this.hasChanges = false
        }
        this.$emit('onChange', this.hasChanges)
      },
      resolveId: function (value) {
        switch (this.editor) {
        case 'involvedEventCategories':
          return _.find(this.$store.state.involvedEventCategories, function (o) { return o.text === value }).id
        }
      },
      resolveItem: function (value) {
        switch (this.editor) {
        case 'involvedEventCategories':
          return _.find(this.$store.state.involvedEventCategories, function (o) { return o.text === value })
        }
      },
      cancelButton: function () {
        //revert any changes and move back.
        this.$emit('onCancel')
        this.$router.go(-1)
      },
      save: function () {
        var self = this
        self.isLoading = true
        if (this.isOwnProfile) {
          this.$store.state.profile.involvedEventCategories = this.data
          // @ts-ignore cannot find mixins
          this.updateOwnProfile(succ => {
            // @ts-ignore cannot find mixins
            this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
            this.$emit('onSave')

            this.$router.go(-1)
            self.isLoading = false
          }, f => {
            // @ts-ignore cannot find mixins
            this.showAlertError(this.$t('common.fetchFailed'))
            self.isLoading = false
          })
        } else {
          this.$store.state.profile.involvedEventCategories = this.data
          // @ts-ignore cannot find mixins
          this.updateProfile(succ => {
            // @ts-ignore cannot find mixins
            this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
            this.$emit('onSave')

            this.$router.go(-1)
            self.isLoading = false
          }, f => {
            // @ts-ignore cannot find mixins
            this.showAlertError(this.$t('common.fetchFailed'))
            self.isLoading = false
          })
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.comp-profile-editor-pill {
  .checkbox {
    color: $brand-black;
    border: 1px solid $brand-grey3;
    box-shadow: none;
  }

  .dropdown {
    margin: 30px auto;
    max-width: 300px;
  }
  .page-body {
    min-height: calc(100vh - 466px);
    background: $brand-white;
    padding-bottom: 150px;
    position: relative;

    .button-footer {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      height: 140px;
      margin-top: 10px;
    }
  }

  .inputs {
    width: calc(100% - 20px);
    max-width: 300px;
    border: 2px solid $brand-red;
    background: $brand-white;
    padding-top: 14px;
    padding-bottom: 14px;
    color: $brand-red;
    &:hover

    {
      color: $brand-white;
      background: $brand-red;
    }
  }
  .centerloading {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;

    span {
      margin: auto;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }
  }
}
</style>
