<template>
  <div class="drop-zone" @dragover.prevent @dragenter.prevent @drop="handleDrop">
    <p>Drag and drop an image here to upload</p>
    <input ref="fileInput" type="file" style="display: none" @change="handleFileChange" />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  export interface TemplateComponentData {
    changePending: boolean,
    imageLib: Array<{ url: string, prev: string }>
  }

  export default Vue.extend({
    props: {
      container: {
        type: String,
        required: true
      },
      folder: {
        type: String,
        default: ''
      },
      prefix: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      changePending: false,
      imageLib: []
    }),
    methods: {
      handleDragOver (event) {
        event.preventDefault()
        event.stopPropagation()
      },
      handleDragEnter (event) {
        event.preventDefault()
        event.stopPropagation()
      },
      handleDrop (event) {
        event.preventDefault()
        event.stopPropagation()
        const files = event.dataTransfer.files
        if (files.length) {
          this.uploadFile(files[0])
        }
      },
      handleFileChange (event) {
        const files = event.target.files
        if (files.length) {
          this.uploadFile(files[0])
        }
      },
      uploadFile (file) {
        const formData = new FormData()
        formData.append('file', file)

        let url =
          this.$store.state.apiBases.contentservice +
          this.$store.state.apiEndpoints.uploadimages[0] +
          `/${this.container}`

        if (this.folder && this.folder.length > 0) {
          url = url + '?folder=' + this.folder

          if (this.prefix && this.prefix.length > 0) { url = url + '&prefix=' + this.prefix }
        } else if (this.prefix && this.prefix.length > 0) { url = url + '?prefix=' + this.prefix }

        //console.log('Dropzone: ', url)

        this.$http
          .post(
            url,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(
            (result: any) => {
              if (result.ok === true) {
                this.$emit('image-uploaded')
                this.changePending = false
              }
            },
            (fail) => {
              const message = 'Multipart body length limit 3145728 exceeded.'
              if (fail.bodyText && fail.bodyText.includes(message))
              // @ts-ignore cannot find mixins
                this.showAlertError(this.$t('common.fileTooLarge') + ': ' + message )
              else
              // @ts-ignore cannot find mixins
              this.showAlertError(this.$t('common.fetchFailed'))
            }
          )
      }
    }
  })
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables.scss";
.drop-zone {
  width: 300px;
  height: 200px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #aaa;
}
.drop-zone:hover {
  border-color: #aaa;
}
</style>
