<template>
  <div class="showing">
    <span class="statistics-title">{{ $t('listingPager.profileTotal') }}</span>
    <span class="highlight"> {{ total }}, </span>
    <span class="statistics-title">{{ $t('listingPager.profilePageVisible') }}</span>
    <span class="highlight"> {{ currentStartNumber }}-{{ currentEndNumber }}</span>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue'

  export default Vue.extend({
    name: 'PagerStats',
    props: {
      total: {
        type: Number,
        default: 0
      },
      take: {
        type: Number,
        default: 0
      },
      skip: {
        type: Number,
        default: 0
      }
    },
    computed: {
      currentStartNumber (): number {
        return this.total === 0 ? 0 : this.skip + 1
      },
      currentEndNumber (): number {
        const maxTotal = this.skip + this.take
        return maxTotal > this.total ? this.total : maxTotal
      }
    }
  })
</script>
<style lang="scss" >
  @import "../assets/scss/_variables.scss";
  .showing {
    .highlight {
      font-weight: bold;
    }
}

</style>
