<template>
  <div class="pillbox">
    <checkpill v-for="v in items" :key="v.id" :title="v.text" :checked="isSelected(v.id)" @click="onClick"></checkpill>
  </div>
</template>

<script>
  import checkpill from '../../common/Pill/CheckPill.vue'

  export default {
    name: 'PillBox',
    components: { checkpill },
    props: {
      items: {
        type: Array,
        default () { return [] }
      },
      selected: {
        type: Array,
        default () { return [] }
      }
    },
    methods: {
      onClick (v) {
        const selectedItems = [...this.selected]
        // if item was clicked, it was unchecked if it existed in selected.
        if (selectedItems.some((s) => s.id === v.id)) {
          selectedItems.splice(selectedItems.findIndex((s) => s.id === v.id), 1)
        } else { selectedItems.push(v) }
        this.$emit('change', selectedItems)
      },
      isSelected (v) {
        return this.selected.some((s) => s.id === v)
      }
    }

  }
</script>

<style scoped>
  .pillbox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
  }
</style>