import Vue from 'vue'
import VueResource from 'vue-resource'
import store from '../store/index.js'

Vue.use(VueResource)

const validatorUniquePoolName = {
  getMessage (field, args) {
    return 'Another pool with this name already exists'
  },
  validate (value, args) {
    return new Promise((resolve, reject) => {
      var url = args[2] === 'STANDARD'
        ? `${store.state.apiBases.friendservice}${store.state.apiEndpoints.getfriendmanagementpoolsstandard[0]}`.replace('{fmgid}', args[1])
        : `${store.state.apiBases.friendservice}${store.state.apiEndpoints.getfriendmanagementpoolsnet[0]}`.replace('{fmgid}', args[1])
      Vue.http.get(url).then((resp) => {
        if (resp.ok) {
          var foundSameName = _.find(resp.body, (pool) => {
            return pool.name.toLowerCase().trim() === value.toLowerCase().trim() && args[0] && Number(pool.id) !== Number(args[0]) // name is not the same as any others. Do not count yourself!
          })

          if (foundSameName) {
            resolve({
              valid: false // found a pool with this name
            })
          }
          resolve({
            valid: true // should NOT exist
          })
        } else {
          reject(new Error({
            valid: false,
            data: {
              message: 'Unable to get response from the server'
            }
          }))
        }
      })
    })
  }
}

export default validatorUniquePoolName
