<template>
  <div v-if="profile" class="profileinfoform">
    <div class="row">
      <div class="col-12 col-sm-6">
        <forminput
          v-model="profile.firstName"
          v-validate="'required'"
          :hint="$t('nonIdentProfileForm.firstName')"
          :is-valid="!errors.has('nonIdentProfileForm.firstname')"
          name="nonIdentProfileForm.firstname"
          :required="true"
        />
      </div>
      <div class="col-12 col-sm-6">
        <forminput
          v-model="profile.lastName"
          v-validate="'required'"
          :hint="$t('nonIdentProfileForm.lastName')"
          :is-valid="!errors.has('nonIdentProfileForm.lastname')"
          name="nonIdentProfileForm.lastname"
          :required="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput
          v-model="profile.streetAddress"
          v-validate="'required'"
          :hint="$t('nonIdentProfileForm.street')"
          :is-valid="!errors.has('nonIdentProfileForm.streetAddress')"
          name="nonIdentProfileForm.streetAddress"
          :required="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <forminput
          v-model="profile.zipCode"
          v-validate="'required|regex:^[0-9]{5}$'"
          :hint="$t('nonIdentProfileForm.zipCode')"
          :is-valid="!errors.has('nonIdentProfileForm.zipCode')"
          name="nonIdentProfileForm.zipCode"
          :required="true"
        />
      </div>
      <div class="col-12 col-sm-8">
        <forminput
          v-model="profile.city"
          v-validate="'required'"
          :hint="$t('nonIdentProfileForm.city')"
          :is-valid="!errors.has('nonIdentProfileForm.city')"
          name="nonIdentProfileForm.city"
          :required="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="datepicker">
          <datepicker
            v-validate="birthdateValidation"
            :value="dateBirthdate"
            :title="$t('nonIdentProfileForm.birthdate')"
            name="onIdentProfileForm.birthDate"
            :is-valid="!errors.has('onIdentProfileForm.birthDate')"
            :required="true"
            @input="onBirthdayChange"
          />
        </div>
      </div>
    </div>
    <!-- Languages -->
    <div class="row">
      <div class="col-12 col-sm-6">
        <dropdowninput
          v-model="profile.nativeLanguage"
          :title="$t('nonIdentProfileForm.languageNative')"
          :items="checkboxLanguages(profile.spokenLanguages, nativeLanguageOptions)"
          :is-valid="true"
        />
      </div>
      <div class="col-12">
        <span class="cap-label brand-red px-3 mb-2 d-inline-block">
          {{ $t('nonIdentProfileForm.languageSpoken') }}
        </span>
        <checkboxlist
          v-model="profile.spokenLanguages"
          :list="checkboxLanguages(profile.spokenLanguages, $store.state.friendCustomerLanguages)"
          :rows="2"
        />
      </div>
      <div class="col-12">
        <forminput
          v-model="profile.otherLanguages"
          :hint="$t('nonIdentProfileForm.otherLanguages')"
          :is-valid="true"
        />
      </div>
    </div>
    <div v-if="!isNetProfile">
      <!-- travel -->
      <div class="row">
        <div class="col-12">
          <span class="cap-label brand-red px-3 mb-2 d-inline-block">
            {{ $t('nonIdentProfileForm.traveloptions') }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <checkboxlist v-model="profile.transportation"
                        :list="travelOptions"
                        :rows="1"
          />
        </div>
        <div class="col-12 col-sm-5">
          <forminput
            v-model="profile.travelTime"
            v-validate="'numeric'"
            :hint="$t('nonIdentProfileForm.travelTime')"
            name="nonIdentProfileForm.travelTimeField"
            :is-valid="!errors.has('nonIdentProfileForm.travelTimeField')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import moment from 'moment'
  import forminput from '../SPRSimpleText.vue'
  import dropdowninput from '../SPRSimpleDropdown.vue'
  import checkboxlist from '../SPRSimpleCheckboxList.vue'
  import datepicker from '../SPRSimpleDatepicker.vue'
  import inpututils from '../../mixins/inpututils.js'
  import _ from 'lodash'
  import { Validator } from 'vee-validate'
  import validatordateisbefore from '../../mixins/validator-dateisbefore'
  import { FriendProfileDto } from '@/types/index'
  import { SprDropDownValue } from '@/types/custom'

  Validator.extend('dateisbefore', validatordateisbefore)

  // the data object
  export interface TemplateComponentData {
    datePickerOptions: Record<string, any>
  }

  export default Vue.extend({
    name: 'ProfileInfoForm',
    inject: ['$validator'],
    components: {
      forminput,
      dropdowninput,
      checkboxlist,
      datepicker
    },
    mixins: [inpututils],
    props: {
      profile: {
        type: Object as () => FriendProfileDto,
        required: true
      },
      isNetProfile: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        datePickerOptions: {
          inputStyle: {
            display: 'inline-block',
            padding: '10px 20px',
            'line-height': '22px',
            'font-size': '16px',
            border: '2px solid #DDDDDD',
            'box-shadow': 'none',
            'border-radius': '24px',
            color: '#5F5F5F',
            width: '100%'
          }
        }
      }
    },
    computed: {
      birthdateValidation (): string {
        const date = new Date()
        const nowDay = date.getDate()
        const nowDayString = nowDay < 10 ? '0' + nowDay : nowDay.toString()
        const nowMonth = date.getMonth() + 1
        const nowMonthString = nowMonth < 10 ? '0' + nowMonth : nowMonth.toString()
        const nowYear = date.getUTCFullYear()
        const currentDateAsString = `${nowDayString}.${nowMonthString}.${nowYear}`

        return `required|dateisbefore:${currentDateAsString}`
      },
      nativeLanguageOptions (): Array<SprDropDownValue> {
        return this.$store.state.friendCustomerLanguages
      },
      travelOptions (): Array<SprDropDownValue> {
        return _.map(this.$store.state.transportation, (val, key) => {
          return { value: val.id, text: this.$t('transportation.' + val.text), selected: this.profile.transportation.indexOf(val.id) >= 0 }
        })
      },
      dateBirthdate (): Date {
        if (!this.profile || !this.profile.age) {
          return new Date()
        }
        return new Date(this.profile.age)
      }
    },
    methods: {
      onBirthdayChange (newVal: Date): void {
        // @ts-ignore eslint-disable-next-line
        this.profile.age = newVal
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .profileinfoform {
    background-color: $brand-white;

    @media (max-width: $media-lg-max) and (min-width: $media-md) {
      box-shadow: inset 30px 0 0px 0px red;
    }
    @media (max-width: $media-sm-max) {
      box-shadow: inset 15px 0 0px 0px red;
    }
    .indicator-stripe {
      @media (min-width: $media-xl) {
        box-shadow: inset 50px 0 0px 0px red;
      }
    }

    .subheader  {
      color: $brand-red;
      text-transform: uppercase;
      font-family: $font-bold;
      margin: 0 0 10px 20px;
      display: block;
      font-size: 14px;
    }

    .sectionheader {
      margin: 15px 0;
    }

    .currentgroup {
      font-weight: bold;
      margin-bottom: 20px;
      display: block;
    }

    .contact-info {
      margin-top: 50px;
    }

    .datepicker {
      .datepickbox {
        input {
          width: 100%;
          box-shadow: none !important;
          border: solid 2px $brand-grey3 !important;
        }
      }
    }

    .form-container {
      margin: 0 auto;
      max-width: 900px;
    }

    .spr-button {
      background-color: $brand-red;
      color: $brand-white;
    }

    .bottomspacer {
      margin-bottom: 50px;
    }
  }
</style>
