<template>
  <div class="spr-password-container">
    <div class="inputcontainer">
      <input :type="passwordType"
             :class="{ 'spr-danger': !isValid && !dark, 'spr-danger-dark': !isValid && dark, 'spr-roundbox-1 spr-password': true }"
             :required="required"
             :disabled="disabled"
             :placeholder="hint"
             :value="value"
             @input="handleInput"
      >
      <a class="iconToggler" @click="toggleType">
        <font-awesome-icon :icon="icon" aria-hidden="true" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  // the data object
  export interface TemplateComponentData {
      passwordType: string,
      icon: string
  }

  export default Vue.extend({
    name: 'SprPassword',
    props: {
      dark: Boolean,
      hint: {
        type: String,
        default: ''
      },
      isValid: Boolean,
      value: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        passwordType: 'password',
        icon: 'eye'
      }
    },
    computed: {
      computedType (): string {
        if (this.passwordType) {
          return this.passwordType
        }
        return 'text'
      }
    },
    methods: {
      handleInput: function (e) {
        this.$emit('input', e.target.value)
      },
      toggleType () {
        if (this.passwordType === 'password') {
          this.passwordType = 'text'
          this.icon = 'eye-slash'
          setTimeout(() => {
            this.passwordType = 'password'
            this.icon = 'eye'
          }, 15000)
        } else {
          this.passwordType = 'password'
          this.icon = 'eye'
        }
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .spr-password-container {
    height: 100%;

    .inputcontainer {
      position: relative;

      .spr-password {
        width: 100%;
        padding: 12px 20px;
        box-sizing: border-box;
        font-family: "Signa-Light", Sans-Serif;
        margin: 5px 3px;
        border: 2px solid $brand-grey3;

        &::placeholder {
          color:$brand-grey6;
        }

        &:disabled {
          cursor: not-allowed;
        }

        &:focus-visible {
          color: #495057;
          border: solid 2px $brand-grey5;
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
      }

      .iconToggler {
        position: absolute;
        right: 10px;
        top: 18px;

        svg {
          display: inline-block;
          color: $brand-red;
          z-index: 1000;
        }
      }
    }
  }

  .spr-danger {
    border: 2px solid $brand-red !important;
  }
  .spr-danger-dark {
    border: 2px solid $error-dark !important;
  }
  .center {
    text-align: center;
  }
</style>
