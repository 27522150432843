<template>
  <div class="row">
    <div class="column col-md-2 col-sm-12 event-name">
      <a href="#">{{ sprevent.title }}</a>
    </div>
    <div class="column col-md-2 col-sm-12 event-time">
      {{ sprevent.date }}<span class="time">{{ sprevent.time }}</span>
    </div>
    <div class="column col-md-2 col-sm-12 event-place">
      {{ sprevent.location }}
    </div>
    <div class="column col-md-2 col-sm-12 event-category">
      {{ sprevent.category }}
    </div>
    <div class="column col-md-2 col-sm-12 event-type">
      {{ sprevent.type }}
    </div>
    <div class="column col-md-2 col-sm-12 event-organizer">
      {{ sprevent.organizer }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Useradmineventview',
    components: { },
    props: {
      sprevent: {
        type: Object,
        default: null
      }
    },
    methods: {
      activateGroupButton: function () {
        console.log('activate button clicked')
      }
    }
  }
</script>
