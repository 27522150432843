<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .tickcross {
    display:inline-block;

    svg {
      &.tick {
        color:$brand-success;
      }
      &.cross {
        color:$brand-danger;
      }
    }
  }
</style>

<template>
  <div class="tickcross">
    <font-awesome-icon v-if="value" :icon="['far', 'check-circle']" class="tick" />
    <font-awesome-icon v-if="!value" :icon="['far', 'times-circle']" class="cross" />
  </div>
</template>

<script>
  export default {
    name: 'TickCross',
    props: {
      value: Boolean
    }
  }
</script>
