<template>
  <div>
    <div v-if="!isNew" class="container">
      <div class="input-container">
        <span class="input-text">{{ name }}</span>
        <a class="input-text" :href="link" target="_blank">{{ link }}</a>
      </div>
      <sprbutton
        class="input-button"
        type="primary"
        :size="1"
        :title="$t('externalForm.delete')"
        click-event="delete"
        @delete="deleteForm"
      />
    </div>
    <div v-else>
      <div class="container">
        <div class="input-container">
          <sprinput v-model="formData.name"
                    class="input-text"
                    :hint="$t('externalForm.link')"
                    :placeholder="$t('externalForm.name')"
          />
          <sprinput v-model="formData.link" class="input-text"
                    :hint="$t('externalForm.link')"
                    :placeholder="$t('externalForm.link')"
          />
        </div>
        <sprbutton class="input-button"
                   type="primary"
                   :size="1"
                   :title="isNew ? $t('externalForm.add') : $t('externalForm.save')"
                   click-event="submit"
                   @submit="submitForm"
        />
        <sprbutton class="input-button"
                   type="primary"
                   :size="1"
                   :title="$t('externalForm.cancel')"
                   click-event="cancel"
                   @cancel="cancel"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprinput from '../SPRInput.vue'
  import sprbutton from '../SPRButton.vue'
  //  import { ValidationProvider, ValidationObserver, Validator } from 'vee-validate'
  import { ExternalFormUpdateDto } from '@/types/index'
  //import { required, url } from 'vee-validate/dist/rules.umd'

  // Validator.extend('required', required)
  // Validator.extend('url', url)
  // the data object
  export interface TemplateComponentData {
    isEditing: boolean,
    formData: ExternalFormUpdateDto
  }

  export default Vue.extend({
    components: { sprinput, sprbutton },
    props: {
      name: {
        type: String,
        default: ''
      },
      link: {
        type: String,
        default: ''
      },
      isNew: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isEditing: this.isNew,
        formData: {
          id: 0,
          name: this.name,
          link: this.link
        } as ExternalFormUpdateDto
      }
    },
    methods: {
      edit () {
        this.isEditing = true
      },
      cancel () {
        this.$emit('cancel')
      },
      submitForm () {
        this.$emit('save', this.formData)
      },
      deleteForm () {
        this.$emit('delete', this.formData.id)
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
.container {
  display: flex;
  align-items: center;
}

.input-container {
  display: flex;
  flex-grow: 1;
}

.input-text {
  flex: 1;
  margin-right: 10px; /* Adjust the space between the inputs if needed */
}

.input-button {
  margin-left: 10px; /* Adjust the space between the inputs and buttons if needed */
}
</style>
