<template>
  <div class="eventjoin">
    <div class="event-block-container container">
      <div class="row">
        <div class="col-12">
          <div class="instructions">
            {{ $t('eventPage.joinInstructions') }}
          </div>
        </div>
      </div>
      <div v-if="rolesAvailable" class="row">
        <checkboxlist
          v-if="!isLeaving"
          v-model="selectedRoles"
          v-validate="'checkbox-selected'"
          name="participantRoles"
          :is-valid="!errors.has('participantRoles')"
          @change="validateSelectedRoles"
        />
        <checkboxlist
          v-else
          v-model="selectedRoles"
          name="participantRoles"
        />
        <div class="col-sm-12">
          <label class="cap-label brand-red">{{ $t('nonIdentProfileForm.volunteerInformation') }}</label>
          <p v-if="moreInfoQuestion">
            {{ moreInfoQuestion }}
          </p>
          <textareainput v-model="eventParticipant.moreInfo"
                         :rows="8"
          />
        </div>
        <div class="col-12">
          <div class="col-12">
            <checkboxinput
              v-model="eventParticipant.privacyStatement"
              v-validate="'option-selected'"
              :title="$t('eventPage.privacyStatement')"
              :aria-label="$t('eventPage.privacyStatement')"
              name="privacyStatement"
              :allow-html="true"
              :white-warnings="false"
              :has-error="errors.has('privacyStatement')"
            />
          </div>
          <sprbutton type="primary"
                     :size="4"
                     :title="$t('eventPage.join')"
                     click-event="join"
                     @join="onJoin"
          />
        </div>
      </div>

      <div v-else class="row no-roles">
        <div class="col-12 no-roles-available-text">
          {{ $t('eventPage.noRolesAvailable') }}
        </div>
        <div class="col-12">
          <sprbutton type="primary"
                     :size="4"
                     :title="$t('eventPage.join')"
                     :disabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import checkboxlist from '../SPRSimpleCheckboxList2.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import { EventRoleDto } from '../../types/index'
  import { SprDropDownValue } from '../../types/custom'

  export default Vue.extend({
    name: 'EventJoin',
    components: {
      sprbutton,
      checkboxinput,
      checkboxlist,
      textareainput
    },
    props: {
      isLeaving: {
        type: Boolean,
        default: false
      },
      isAnonymous: {
        type: Boolean,
        default: false
      },
      roles: {
        type: Array as () => Array<EventRoleDto>,
        default () {
          return []
        }
      },
      moreInfoQuestion: {
        type: String,
        default: ''
      },
      profileId: {
        type: Number,
        default: null // Default value as null
      }
    },
    data () {
      return {
        selectedRoles: [] as Array<SprDropDownValue>,
        eventParticipant: {
          roleIds: [] as Array<number>,
          moreInfo: '',
          privacyStatement: false,
          privacyStatementInError: false
        }
      }
    },
    computed: {
      rolesAvailable (): boolean {
        return this.roles.length > 0 && this.selectedRoles.length > 0
      }
    },
    created () {
      // get "a" role with a participation by the current user, so that we can lookup the "moreInfo" field.
      this.eventParticipant.moreInfo = this.findMoreInfo(this.roles, this.profileId) ?? ''

      // Selected roles are only visible if they are not occupied yet up to the maximum number of participants,
      // or if the role is occupied by the current user (based on his profileId.)
      this.selectedRoles = this.roles.filter((r) => {
        return r.maximumParticipants === 0 || r.maximumParticipants > r.participants.length || r.participants.some(p => p.profileId === this.profileId)
      }).map((r) => {
        let selected = false

        if (this.profileId) { selected = r.participants.some(p => p.profileId === this.profileId) }

        return {
          value: r.id,
          text: r.name,
          selected: selected
        } as SprDropDownValue
      })
    },
    methods: {
      validateSelectedRoles () {
        this.$validator.validate('participantRoles')
      },
      findMoreInfo (roles, profileId) {
        let moreInfo = null

        roles.some(role => {
          const participant = role.participants.find(participant => participant.profileId === profileId)

          if (participant) {
            moreInfo = participant.moreInfo
            return true // Stop iteration once a participant with the specified profileId is found
          }

          return false
        })

        return moreInfo
      },
      onJoin () {
        this.$validator.validateAll().then(resp => {
          if (resp) {
            this.eventParticipant.roleIds = this.selectedRoles
              .filter((r) => r.selected)
              .map((r) => r.value as number)

            this.$emit('join', this.eventParticipant)
          }
        }).catch(error => {
          console.log('onJoin.error', error)
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .eventjoin {
    .instructions {
      margin-bottom: 15px;
    }

  .no-roles {
    .no-roles-available-text {
      margin-bottom: 15px;
    }
  }
}
</style>
