<template>
  <div class="broker-volunteers mt-3">
    <hr>
    <p>{{ $t('userAdmin.brokerRightsTitle') }}</p>

    <searchbox v-model="search" />

    <!-- Friendship broker search -->
    <div v-if="brokerVolunteers && brokerVolunteers.results && !loading">
      <friendshipBrokerSearchResult v-for="(r, i) in brokerVolunteers.results"
                                    :key="'resultsetv_' + _uid + '_' + i + '_' + r.profileId"
                                    :volunteer="r"
                                    :friend-management-group="groupId"
                                    :is-net-profile="isNetProfile"
      />
    </div>
    <div v-else class="spinner my-4 col-12">
      <loading />
    </div>

    <pager v-if="!loading"
           :take="take"
           :skip="skip"
           :total="total"
           :max-display="maxDisplay"
           of-text="/"
           @pagesizechanged="onPageSizeChanged"
           @pagerchanged="pagerClick"
           @pagerprevious="pagerPrevious"
           @pagernext="pagerNext"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import searchbox from '../SPRSearchBox.vue'
  import pager from '../SPRPager.vue'
  import friendshipBrokerSearchResult from './FriendshipBrokerSearchResult.vue'
  import loading from '../SPRLoading.vue'
  import { FriendManagementDto, SearchResult, FriendBrokerDto } from '@/types'
  import { HttpResponse } from 'vue-resource/types/vue_resource'

  // the data object
  export interface TemplateComponentData {
    search: string,
    take: number,
    skip: number,
    total: number,
    maxDisplay: number,
    brokerVolunteers: SearchResult<FriendBrokerDto> | undefined,
    loading: boolean,
    group: FriendManagementDto | undefined
  }

  export default Vue.extend({
    name: 'BrokerVolunteers',
    components: { searchbox, pager, friendshipBrokerSearchResult, loading },
    props: {
      id: {
        default: '-1',
        type: String
      },
      friendshipGroup: {
        default: null,
        type: Number
      }
    },
    data (): TemplateComponentData {
      return {
        search: '',
        take: 5,
        skip: 0,
        total: 0,
        maxDisplay: 5,
        brokerVolunteers: undefined,
        loading: false,
        group: undefined
      }
    },
    computed: {
      isNetProfile (): boolean {
        return Boolean(this.group?.netFriendship)
      },
      groupId (): number {
        return Number(this.id)
      }
    },
    watch: {
      search (val): void {
        this.skip = 0
        this.performSearch()
      }
    },
    mounted (): void {
      this.getGroup()
        .then((result: HttpResponse) => {
          this.group = result.data
          this.performSearch()
          this.$bus.$on('broker-search-research', this.performSearch)
        })
        .catch((err: any) => {
          // @ts-ignore
          console.log(err)
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        })
    },
    beforeDestroy (): void {
      this.$bus.$off('broker-search-research')
    },
    methods: {
      onPageSizeChanged (pageSize) {
        this.take = pageSize
        this.performSearch()
      },
      trainLink (newState): string {
        return '/admin/training/' + newState
      },
      pagerClick (val): void {
        this.skip = val.skip
        this.performSearch()
      },
      pagerPrevious (): void {
        this.skip -= this.take
        this.performSearch()
      },
      pagerNext (): void {
        this.skip += this.take
        this.performSearch()
      },
      getGroup (): Promise<HttpResponse> {
        const url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.id)
        return this.$http.get(url) as Promise<HttpResponse>
      },
      performSearch (): void {
        this.loading = true
        if (this.skip < 0) this.skip = 0

        var url = this.group?.netFriendship
          ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationFindNetBrokers[0]
          : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationFindStandardBrokers[0]

        var dto = {
          search: this.search,
          friendManagementGroupId: Number(this.id),
          potentialBrokersOnly: true,
          skip: this.skip,
          take: this.take
        }
        this.$http.post(url, dto).then(s => {
          this.brokerVolunteers = s.data
          this.total = this.brokerVolunteers?.total ?? 0

          this.loading = false
        })
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .broker-volunteers {
    p {
      font-family: $font-bold;
      margin-top: 30px;
    }
    .filter-links {
      a {
        text-transform: uppercase;
        font-weight: bold;
        color: $brand-grey7 !important;

        &.router-link-active {
          color: $brand-red !important;
        }
      }
    }

    .spinner {
      color: $brand-red;
      text-align: center;
    }
  }
</style>
