<template>
  <div v-if="volunteer" class="volunteerprofilestandardform">
    <a name="standard"></a>
    <div class="row">
      <div class="col-sm-6 sectionheader">
        <h3>{{ $t('volunteerForm.standardFormTitle') }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <forminputarea v-model="volunteer.shortDescription"
                       v-validate="{max:100}"
                       :title="$t('volunteerForm.shortDescriptionThirdPerson')"
                       name="volunteerForm.shortDescription"
                       :is-valid="!errors.has('volunteerForm.shortDescription')"
                       :maxlength="100"
                       :max-characters="100"
                       show-counter
                       auto-resize
                       :rows="1"
        />
      </div>
    </div>

    <!-- separate address -->
    <div class="row">
      <div class="col-12">
        <checkboxinput v-model="volunteer.hasAlternativeAddress"
                       :title="$t('volunteerForm.hasSeparateAddress')"
                       :aria-label="$t('volunteerForm.hasSeparateAddress')"
        />
      </div>
    </div>
    <div v-if="volunteer.hasAlternativeAddress">
      <div class="row">
        <div class="col-12">
          <forminput v-model="volunteer.alternativeStreet"
                     v-validate="'required'"
                     :hint="$t('volunteerForm.alternativeAddress')"
                     :is-valid="!errors.has('volunteerForm.streetAddress')"
                     name="volunteerForm.streetAddress"
                     :required="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4 col-md-3">
          <forminput v-model="volunteer.alternativeZipCode"
                     v-validate="'required|regex:^[0-9]{5}$'"
                     :hint="$t('volunteerForm.postalCode')"
                     :is-valid="!errors.has('volunteerForm.postalCode')"
                     name="volunteerForm.postalCode"
                     :required="true"
          />
        </div>
        <div class="col-12 col-sm-8 col-md-9">
          <forminput v-model="volunteer.alternativeCity"
                     v-validate="'required'"
                     :hint="$t('volunteerForm.city')"
                     :is-valid="!errors.has('volunteerForm.city')"
                     name="volunteerForm.city"
                     :required="true"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('volunteerForm.friendshipType') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxlist v-model="volunteer.friendshipTypes"
                      :list="friendshipTypes"
                      :rows="2"
        />
      </div>
    </div>

    <!-- interests -->
    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('volunteerForm.interests') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxlist v-model="volunteer.interests"
                      :list="customerInterests"
                      :rows="3"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.otherInterests"
                   :hint="$t('volunteerForm.otherInterests')"
                   :is-valid="true"
        />
      </div>
    </div>
    <!-- CUSTOMER PREFERENCES -->
    <div class="row">
      <div class="col-12 sectionheader">
        <h3>{{ $t('volunteerForm.friendWishTitle') }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <checkboxinput v-model="volunteer.multipleCustomers"
                       :title="$t('volunteerForm.multipleCustomers2')"
                       :aria-label="$t('volunteerForm.multipleCustomers2')"
        />
      </div>
    </div>

    <div class="row">
      <!-- gender -->
      <div class="col-12 col-md-6">
        <span class="subheader">{{ $t('volunteerForm.genderPreference') }}*</span>
        <checkboxlist v-model="volunteer.preferredGenders"
                      v-validate="'option-selected'"
                      :list="genders"
                      :rows="1"
                      name="preferredGenderRequired"
                      :is-valid="!errors.has('preferredGenderRequired')"
        />
        <span v-if="errors.has('preferredGenderNotSelected')" class="error-message">{{ $t('validation.preferredGenderRequired') }}</span>
        <span v-else class="error-message">&nbsp;</span>
      </div>
      <!-- Desired age -->
      <div class="col-12 col-md-6">
        <span class="subheader">{{ $t('volunteerForm.desiredAge') }}*</span>
        <checkboxlist v-model="volunteer.preferredAges"
                      v-validate="'option-selected'"
                      :list="preferredAge"
                      :rows="1"
                      name="ageGroupRequired"
                      :is-valid="!errors.has('ageGroupRequired')"
        />
        <span v-if="errors.has('ageGroupNotSelected')" class="error-message">{{ $t('validation.ageGroupNotSelected') }}</span>
        <span v-else class="error-message">&nbsp;</span>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('volunteerForm.customerType') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxlist v-model="volunteer.customerTypes"
                      :list="customerTypes"
                      :rows="2"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('volunteerForm.availabilityTimes') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxlist v-model="volunteer.availabilityTimes"
                      :list="availabilityTimes"
                      :rows="3"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <sprdropdownnumberkey v-model="volunteer.availabilityFrequence"
                              :title="$t('volunteerForm.availabilityFrequency')"
                              :items="availabilityFrequency"
                              :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.availabilityDetails"
                   :hint="$t('volunteerForm.availabilityDetails')"
                   :is-valid="true"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import forminput from '../SPRSimpleText.vue'
  import forminputarea from '../SPRSimpleTextArea.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import checkboxlist from '../SPRSimpleCheckboxBitmaskList.vue'
  import sprdropdownnumberkey from '../SPRDropdownNumberKey.vue'
  import enums from '../../mixins/enums.js'
  import scroll from '../../mixins/scroll.js'
  import locationutils from '../../mixins/locationutils.js'
  // import enumhelper from '../../mixins/enums.js'
  import * as _ from 'lodash'
  import { FriendVolunteerProfileStandardDto } from '../../types/index'
  import { SprCheckboxBitmaskValue, SprDropDownNumberValue } from '../../types/custom'

  export default Vue.extend({
    name: 'VolunteerProfileStandard',
    components: {
      forminput,
      checkboxinput,
      checkboxlist,
      sprdropdownnumberkey,
      forminputarea
    },
    mixins: [enums, scroll, locationutils],
    model: {
      prop: 'volunteer',
      event: 'update'
    },
    props: {
      volunteer: {
        type: Object as () => FriendVolunteerProfileStandardDto,
        required: true
      }
    },
    computed: {
      availabilityTimes (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.availabilityTimes.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('availabilityTimes.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      friendshipTypes (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.friendshipTypes.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('friendshipTypes.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      // not enabled yet, but will be.
      // travelOptions (): Array<SprCheckboxBitmaskValue> {
      //   return _.map(this.$store.state.transportation, (val, key) => {
      //     return { value: val.id, text: this.$t('transportation.' + val.text) } as SprCheckboxBitmaskValue
      //   })
      // },
      customerTypes (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.customerTypes.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('customerTypes.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      customerInterests (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.customerInterests.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('customerInterests.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      genders (): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.genders, function (val, key) {
          return { value: val.id, text: self.$t('genders.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      preferredAge (this: any): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.preferredAge.filter(x => x.id !== 0), function (val, key) {
          return { value: val.id, text: self.$t('preferredAge.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      availabilityFrequency (): Array<SprDropDownNumberValue> {
        var self = this
        return _.map(this.$store.state.availabilityFrequency.filter(x => x.id !== 0), function (val, key) {
          return { value: val.id, text: self.$t('availabilityFrequency.' + val.text) } as SprDropDownNumberValue
        })
      }
    },
    mounted (): void {
      this.$nextTick(() => {
        const hashName = this.$route.hash && this.$route.hash.substring(1)
        if (hashName) {
          const hashSelector = `a[name=${hashName}]`
          const el = document.querySelector(hashSelector)
          el && el.scrollIntoView()
        }
      })
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteerprofilestandardform {
    background-color:$brand-white;

    .subheader {
      color:$brand-red;
      text-transform: uppercase;
      font-family: $font-bold;
      margin: 0 0 10px 20px;
      display:block;
      font-size: 14px;
    }

    .error-message {
      color:$brand-red;
      margin: 0 0 2px 20px;
      display:block;
      font-size: 14px;
    }
    .sectionheader {
      margin: 15px 0;
    }
  }
</style>
