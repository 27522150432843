<template>
  <div v-if="localCustomer" class="personalinfo">
    <div class="row">
      <div class="col-12">
        <!-- languages -->
        <div class="row headertitle">
          <div class="col">
            <h3>{{ $t('customerForm.standardLanguageInformation') }}</h3>
          </div>
        </div>
        <div class="row">
          <!-- native language-->
          <div class="col-12 col-md-4 col-lg-3">
            <dropdowninput v-model="localCustomer.nativeLanguage"
                           :title="$t('customerForm.nativeLanguage')"
                           :items="checkboxLanguages(customer.customerLanguages, nativeLanguageOptions)"
                           :is-valid="true"
            />
          </div>
          <!-- spoken languages -->
          <div class="col-12 col-md-8 col-lg-9">
            <span class="cap-label brand-red px-3 mb-2 d-inline-block">
              {{ $t('customerForm.standardCustomerOtherLanguages') }}
            </span>
            <checkboxlist
              :value="mappedCustomerLanguages"
              :list="customerLanguageOptions"
              :rows="4"
              @input="onCustomerLanguageChanged"
            />
            <!-- other languages freetext -->
            <forminput  v-model="localCustomer.otherLanguages"
                        v-validate="{ max: 50 }"
                        :hint="$t('customerForm.otherLanguages')"
                        name="customerForm.shortDescription"
                        :is-valid="localCustomer.otherLanguages.length<=50"
                        :maxlength="50"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="buttons">
          <prevnextbuttons
            @prev="onPrevClicked"
            @next="onNextClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import forminput from '../SPRSimpleText.vue'
  import checkboxlist from '../SPRSimpleCheckboxList.vue'
  import dropdowninput from '../SPRSimpleDropdown.vue'
  import prevnextbuttons from '../SPRPrevNextButtons.vue'
  import { FriendCustomerStandardDto, LanguageDto } from '@/types/index'
  import { SprCheckboxBitmaskValue, SprDropDownValue, SprCheckboxValue } from '@/types/custom'
  import inpututils from '@/mixins/inpututils'

  // the data object
  export interface TemplateComponentData {
    localCustomer: FriendCustomerStandardDto | undefined
  }

  export default Vue.extend({
    name: 'LanguageInfo',
    components: { forminput, checkboxlist, dropdowninput, prevnextbuttons },
    mixins: [inpututils],
    props: {
      customer: {
        type: Object as () => FriendCustomerStandardDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        localCustomer: undefined
      }
    },
    computed: {
      genders (): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.genders, function (val, key) {
          return { value: val.id, text: self.$t('genders.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      mappedCustomerLanguages (): Array<string> {
        if (!this.localCustomer) return []
        var mappedLanguages = this.localCustomer.customerLanguages.map((x: LanguageDto) => {
          return x.isoCode
        })
        return mappedLanguages
      },
      customerLanguageOptions (): Array<SprDropDownValue> {
        if (!this.$store.state.friendCustomerLanguages) return []
        var options = _.map(this.$store.state.customerLanguages, (val: LanguageDto, key: number) => {
          return { value: val.isoCode, text: this.$t('languages.' + val.isoCode), selected: Boolean(this.mappedCustomerLanguages.filter(x => x === val.isoCode).length) } as SprDropDownValue
        })
        return options
      },
      nativeLanguageOptions (): Array<SprCheckboxValue> {
        return this.$store.state.friendCustomerLanguages as Array<SprCheckboxValue>
      }
    },
    mounted (): void {
      this.localCustomer = this.customer
    },
    methods: {
      onCustomerLanguageChanged (arr: Array<string>): void {
        if (!this.localCustomer) return
        var langs = arr.map((x: string) => {
          return _.find(this.$store.state.customerLanguages, (lang: LanguageDto) => {
            return lang.isoCode === x
          })
        })
        this.localCustomer.customerLanguages = langs || []
      },
      onPrevClicked (): void {
        this.$emit('prev', this.localCustomer)
      },
      onNextClicked (): void {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('next', this.localCustomer)
          }
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .personalinfo {
    background-color: $brand-white;
    padding: 15px;
    padding-top: 30px;

    .headertitle {
      margin-bottom: 30px;
    }

    .buttons {
      padding-top: 30px;
    }
  }

</style>