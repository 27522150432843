<template>
  <div v-if="localCustomer" class="contactinfo">
    <div class="row">
      <div class="col-12">
      <!-- contact section-->
      <div class="row contact-info">
        <div class="col-12">
          <h3>{{ $t('customerForm.contactInformation') }}</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <forminput
            v-model="localCustomer.contactFirstName"
            :hint="$t('customerForm.contactFirstname')"
            :is-valid="true"
            name="customerForm.contactFirstName"
          />
        </div>
        <div class="col-sm-6">
          <forminput
            v-model="localCustomer.contactLastName"
            :hint="$t('customerForm.contactLastname')"
            :is-valid="true"
            name="customerForm.contactLastName"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <forminput
            v-model="localCustomer.contactStreetAddress"
            :hint="$t('customerForm.contactStreet')"
            :is-valid="true"
            name="customerForm.contactStreetAddress"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4">
          <forminput
            v-model="localCustomer.contactZipCode"
            :hint="$t('customerForm.contactZipCode')"
            :is-valid="true"
            name="customerForm.contactZipCode"
          />
        </div>
        <div class="col-12 col-sm-8">
          <forminput
            v-model="localCustomer.contactCity"
            :hint="$t('customerForm.contactCity')"
            :is-valid="true"
            name="customerForm.contactCity"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <forminput
            v-model="localCustomer.contactPhonenumber"
            :hint="$t('customerForm.contactPhone')"
            :is-valid="true"
            name="customerForm.contactPhoneNumber"
          />
        </div>
        <div class="col-sm-6">
          <forminput
            v-model="localCustomer.contactEmail"
            :hint="$t('customerForm.contactEmail')"
            :is-valid="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <forminput
            v-model="localCustomer.contactRelation"
            :hint="$t('customerForm.contactRelation')"
            :is-valid="true"
            name="customerForm.contactRelation"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <textareainput
            v-model="localCustomer.otherContact"
            :title="$t('customerForm.otherContact')"
            v-validate="{ max: 100 }"
            :is-valid="localCustomer.otherContact.length <= 100"
            :maxCharacters="100"
            :showCounter="true"
          />
        </div>
      </div>
      </div>
      <div class="col-12">
        <div class="buttons">
          <prevnextbuttons
            @prev="onPrevClicked"
            @next="onNextClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import forminput from '../SPRSimpleText.vue'
  import prevnextbuttons from '../SPRPrevNextButtons.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import { FriendCustomerStandardDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    localCustomer: FriendCustomerStandardDto | undefined
  }

  export default Vue.extend({
    name: 'ContactInfo',
    components: { forminput, textareainput, prevnextbuttons },
    props: {
      customer: {
        type: Object as () => FriendCustomerStandardDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        localCustomer: undefined
      }
    },
    mounted (): void {
      this.localCustomer = this.customer
    },
    methods: {
      onPrevClicked (): void {
        this.$emit('prev', this.localCustomer)
      },
      onNextClicked (): void {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('next', this.localCustomer)
          }
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .contactinfo {
    background-color: $brand-white;
    padding: 15px;
    padding-top: 30px;

    .headertitle {
      margin-bottom: 30px;
    }

    .instructions {
      margin-bottom: 30px;
    }

    .buttons {
      padding-top: 30px;
    }
  }

</style>