<template>
  <div ref="vuesingleselect" class="spr-autocomplete-dropdown">
    <div v-if="!selectedOption" :class="classes.wrapper">
      <div class="relative inline-block w-full">
        <input :id="inputId"
               ref="search"
               v-model="searchText"
               type="text"
               :class="[classes.input, isRequired, 'clearclose']"
               :placeholder="placeholder"
               autocomplete="off"
               :required="required"
               @click="seedSearchText"
               @focus="seedSearchText"
               @keyup.enter="setOption"
               @keyup.down="movePointerDown"
               @keyup.tab.stop="closeOut"
               @keyup.esc.stop="closeOut"
               @keyup.up="movePointerUp"
        >

        <div v-if="showArrow" :class="[classes.icons]" class="cursor-pointer absolute flex items-center" @click="toggleDropdown">
          <div v-if="!dropdownOpen" class="arrow-down" aria-hidden="true" />
        </div>

        <ul v-if="matchingOptions" ref="options" tabindex="-1"
            :style="{'max-height': maxHeight}" style="z-index: 5;padding"
            :class="[classes.dropdown]"
            class="absolute w-full overflow-auto appearance-none mt-px list-reset"
        >
          <li v-for="(option, idx) in matchingOptions"
              :key="idx" tabindex="-1"
              :class="idx === pointer ? classes.activeClass : ''"
              class="cursor-pointer outline-none"
              @blur="handleClickOutside($event)"
              @mouseover="setPointerIdx(idx)"
              @keyup.enter="setOption()"
              @keyup.up="movePointerUp()"
              @keyup.down="movePointerDown()"
              @click.prevent="setOption()"
          >
            <slot name="option" v-bind="{option,idx}">
              {{ getOptionDescription(option) }}
            </slot>
          </li>
        </ul>
      </div>
    </div>

    <div v-if="selectedOption" :class="classes.wrapper">
      <input :id="inputId"
             ref="match"
             :class="[classes.input, 'clearclose']"
             :required="required"
             :value="getOptionDescription(selectedOption)"
             @input="switchToSearch($event)"
             @click="switchToSearch($event)"
      >
      <input ref="selectedValue" type="hidden" :name="name" :value="getOptionValue(selectedOption)">

      <div class="flex absolute items-center" :class="classes.icons">
        <svg aria-hidden="true" class="cursor-pointer" viewBox="0 0 512 512" @click="closeOut">
          <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z" />
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
/*
This is based on the Vue Single Select component, but with some modifications to meet our needs.
https://vuejsexamples.com/single-select-dropdown-with-autocomplete/
*/
/* eslint-disable indent */
  import pointerScroll from '../mixins/pointerScroll'
  export default {
    name: 'SprAutoCompleteDropdown',

    mixins: [pointerScroll],
    props: {
      value: {
        type: Object,
        default: undefined
      },
      name: {
        type: String,
        required: false,
        default: () => ''
      },
      options: {
        type: Array,
        required: false,
        default: () => []
      },
      optionLabel: {
        type: String,
        required: false,
        default: () => null
      },
      optionKey: {
        type: String,
        required: false,
        default: () => null
      },
      placeholder: {
        type: String,
        required: false,
        default: () => 'Search Here'
      },
      maxHeight: {
        type: String,
        default: () => '220px',
        required: false
      },
      inputId: {
        type: String,
        default: () => 'single-select',
        required: false
      },
      showArrow: {
        type: Boolean,
        default: true
      },
      showDefaultOptions: {
        type: Boolean,
        default: true
      },
      classes: {
        type: Object,
        required: false,
        default: () => {
          return {
            pointer: -1,
            wrapper: 'single-select-wrapper',
            input: 'search-input',
            icons: 'icons',
            required: 'required',
            activeClass: 'active',
            dropdown: 'dropdown'
          }
        }
      },
      dark: Boolean,
      required: {
        type: Boolean,
        required: false,
        default: () => false
      },
      maxResults: {
        type: Number,
        required: false,
        default: () => 30
      },
      tabindex: {
        type: String,
        required: false,
        default: () => {
          return ''
        }
      },
      getOptionDescription: {
        type: Function,
        default: function (option) {
          if (this.optionKey && this.optionLabel) {
            return option[this.optionKey] + ' ' + option[this.optionLabel]
          }
          if (this.optionLabel) {
            return option[this.optionLabel]
          }
          if (this.optionKey) {
            return option[this.optionKey]
          }
          return option
        }
      },
      getOptionValue: {
        type: Function,
        default: function (option) {
          if (this.optionKey) {
            return option[this.optionKey]
          }

          if (this.optionLabel) {
            return option[this.optionLabel]
          }

          return option
        }
      },
      filterBy: {
        type: Function,
        default: function (option) {
          if (this.optionLabel && this.optionKey) {
            return (
              option[this.optionLabel]
                .toString()
                .toLowerCase()
                .includes(this.searchText.toString().toLowerCase()) ||
              option[this.optionKey]
                .toString()
                .toLowerCase()
                .includes(this.searchText.toString().toLowerCase())
            )
          }

          if (this.optionLabel) {
            return option[this.optionLabel]
              .toString()
              .toLowerCase()
              .includes(this.searchText.toString().toLowerCase())
          }

          if (this.optionKey) {
            option[this.optionKey]
              .toString()
              .toLowerCase()
              .includes(this.searchText.toString().toLowerCase())
          }

          return option
            .toString()
            .toLowerCase()
            .includes(this.searchText.toString().toLowerCase())
        }
      }
    },
    data () {
      return {
        searchText: null,
        selectedOption: null,
        dropdownOpen: false
      }
    },
    computed: {
      isRequired () {
        if (!this.required) {
          return ''
        }

        if (this.selectedOption) {
          return ''
        }

        if (this.dark) {
          return 'required-dark'
        }

        return 'required'
      },
      matchingOptions () {
        if (this.searchText === null) {
          return null
        }
        if (!this.searchText.length) {
          if (!this.showDefaultOptions) {
            return null
          }
          return [...this.options].slice(0, this.maxResults)
        }

        return this.options
          .filter(option =>
            this.filterBy(option)
          )
          .slice(0, this.maxResults)
      }
    },
    watch: {
      value (curr, prev) {
        this.selectedOption = curr
      },
      searchText (curr, prev) {
        if (curr !== prev) {
          this.pointer = -1
        }
      },
      selectedOption (curr, prev) {
        if (curr) {
          this.$emit('input', curr)
        }
      },
      dropdownOpen (curr, prev) {
        if (curr === prev) {
          return
        }

        if (!curr) {
          this.searchText = null
          return
        }

        if (!this.searchText) {
          this.searchText = ''
        }

        this.$nextTick().then(() => {
          this.$refs.search.focus()
        })
      }
    },
    mounted () {
      document.addEventListener('click', this.handleClickOutside)
      document.addEventListener('keyup', this.handleClickOutside)
      if (this.value && this.options.includes(this.value)) {
        this.selectedOption = this.value
      }
    },
    destroyed () {
      document.removeEventListener('keyup', this.handleClickOutside)
      document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
      setPointerIdx (idx) {
        this.pointer = idx
      },
      seedSearchText () {
        if (!this.searchText) {
          return
        }

        this.searchText = ''
      },
      switchToSearch (event) {
        this.$refs.selectedValue.value = null
        this.searchText = event.target.value
        this.selectedOption = null
        this.dropdownOpen = true
      },
      toggleDropdown () {
        if (!this.searchText) {
          this.dropdownOpen = false
          return
        }

        this.dropdownOpen = !this.dropdownOpen
      },
      closeOut () {
        this.selectedOption = null
        this.dropdownOpen = false
        this.searchText = null
      },
      movePointerDown () {
        if (!this.matchingOptions) {
          return
        }
        if (this.pointer >= this.matchingOptions.length - 1) {
          return
        }

        this.pointer++
      },
      movePointerUp () {
        if (this.pointer > 0) {
          this.pointer--
        }
      },
      setOption () {
        if (!this.matchingOptions || !this.matchingOptions.length) {
          return
        }
        if (this.pointer === -1) {
          this.pointer++
        }

        this.selectedOption = this.matchingOptions[this.pointer]
        this.searchText = null
        this.dropdownOpen = false
        this.pointer = -1
        this.$nextTick().then(() => {
          this.$refs.match.focus()
        })
      },
      handleClickOutside (e) {
        if (this.$el.contains(e.target)) {
          return
        }

        this.dropdownOpen = false
        this.searchText = null
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
 .spr-autocomplete-dropdown {
   outline: none;
   input:focus-visible{
      color: #495057;
      border: solid 2px $brand-grey5;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
 }
 .w-full {
     width: 100%;
 }
 .inline-block {
     display: inline-block;
 }
 .block {
     display: block;
 }
 .flex {
     display: flex;
 }
 .border {
     border-width: thin;
     border-style: solid;
 }
 .rounded {
     border-radius: 0.25em;
 }
 .text-black {
     color: #22292f;
 }
 .absolute {
     position: absolute;
 }
 .relative {
     position: relative;
 }
 .items-center {
     align-items: center;
 }
 .mt-px {
     margin-top: 1px;
 }
 .list-reset {
     list-style: none;
     padding: 0;
 }
 .overflow-auto {
     overflow: auto;
 }
 .appearance-none {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
 }
 .search-input {
     display: block;
     width: 100%;
     padding: 12px 20px;
     font-size: 1em;
     line-height: 1.5;
     color: $brand-font;
     background-color: #fff;
     background-clip: padding-box;
     border: 2px solid $brand-grey3;
     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
     box-sizing: border-box;
     border-radius: 60px;
     outline: none;

    &.clearclose {
        &::-ms-clear {
            display: none !important;
            width: 0;
            height: 0;
        }
    }
 }
 .arrow-down {
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;

    border-top: 6px solid #000;
 }
 .icons {
     padding: 0 1em;
     right: 0;
     top: 0;
     bottom: 0;
     fill: #606f7b;
     padding: 0 10px 0 20px;
 }
 .icons svg {
     width: 0.75em;
     height: 0.75em;
 }
 .single-select-wrapper {
     position: relative;
     margin-bottom: 0.5em;
 }
 .required {
     _color: $brand-red;
     _background-color: #f8d7da;
     border-color: $brand-red;
 }
 .required-dark {
     _color: $brand-red;
     _background-color: #f8d7da;
     border-color: $error-dark;
 }
 .cursor-pointer {
     cursor: pointer;
 }
 .dropdown {
     -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
     0 2px 4px 0 rgba(0, 0, 0, 0.08);
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
     background-color: #fff;
     color: #606f7b;
     border-radius: 0.25em;
     line-height: 1.25;
     text-align: left;
 }
 .dropdown > li {
     padding: 0.5em 0.75em;
 }
 .active {
     background: #dae1e7;
 }
</style>
