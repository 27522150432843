<template>
  <div :id="'msg-' + note.id" :class="'sprmessage thread-message' + activeClass">
    <div class="msg-sender">
      <div class="sender-details">
        <div class="sender">
          <span class="sentby cap-label brand-red">{{ note.sender }}</span>
        </div>
        <span class="date">{{ formattedDate(note.sent) }}</span>
      </div>
      <span v-if="canDelete" title="Poista" class="rem-btn" @click="$emit('remove', note)">
        <font-awesome-icon icon="ban" class="brand-red" />
      </span>
    </div>
    <div class="msg-content">
      <p v-linkified:options="{ nl2br: true }">
        {{ note.content }}
      </p>
      <p>
        <a v-if="isLast" href="reply" @click.prevent="$emit('reply')">{{ $t('groups.reply') }}</a>
      </p>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import messages from '../../mixins/messages.js'

  export default {
    name: 'ReplyView',
    components: {},
    mixins: [messages],
    props: {
      note: {
        type: Object,
        required: true
      },
      focus: {
        type: Number,
        default: 0
      },
      canDelete: Boolean,
      isLast: Boolean
    },
    data () {
      return {
      }
    },
    computed: {
      activeClass: function () {
        return this.note.id === this.focus ? ' active' : ''
      }
    },
    methods: {
      formattedDate (date) {
        return moment.utc(date).local().format('DD.MM.YYYY H:mm')
      }
    }
  }
</script>
