<template>
  <div v-if="isLegal" class="comp-profile-editor-checkbox row">
    <div class="col-12 text-center">
      <span v-if="editor === 'languages'" class="cap-label py-5 d-inline-block">{{ $t('profile.languageEditorIngress') }}</span>
      <span v-else class="cap-label py-5 d-inline-block">{{ $t('profile.checkboxEditorIngress') }}</span>
    </div>
    <div class="col-12 text-center">
      <sprcheckbox v-for="(entry) in wholeSet"
                   :key="'cb_' + _uid + '_' + entry"
                   :title="editor === 'languages' ? normalizeTranslation(entry) : $t(translationPrefix + '.' + entry)"
                   click-event="on-click"
                   :value="editor === 'languages' ? entry : resolveId(entry)"
                   :tabindex="0"
                   :collection="actualData"
                   class="checkbox"
                   @on-click="editSelection"
      />
      <div v-if="editor === 'languages'" class="col-12 text-center">
        <sprlanguageselector select-event="custom-language"
                             class="dropdown spr-roundbox-1"
                             @custom-language="editSelection"
        />
      </div>
    </div>
    <div v-if="loading" class="brand-red centerloading">
      <loading />
    </div>
    <!-- Save and cancel buttons -->
    <div class="row my-5" style="padding-top: 5%">
      <div class="col-3"></div>
      <div class="col-3 col-md-3 my-1">
        <sprbutton click-event="save-event" :title="$t('profile.save')"
                   :size="1"
                   :bordersize="2" class="fill-width" @save-event="save"
        />
      </div>
      <div class="col-3 col-md-3 my-1">
        <sprbutton click-event="cancel-event" :title="$t('profile.cancel')"
                   :size="1"
                   :bordersize="2" class="fill-width" @cancel-event="cancelButton"
        />
      </div>
    </div>
  </div>
  <div v-else />
  </div>
</template>

<script lang = "ts">
  import sprcheckbox from '../SPRCheckbox.vue'
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'
  import Vue from 'vue'
  import { TranslateResult } from 'vue-i18n'
  import * as _ from 'lodash'

  import sprlanguageselector from '../SPRLanguageSelector.vue'

  import enumhelper from '../../mixins/enums.js'
  // the data object

  export interface TemplateComponentData {
    hasChanges: boolean,
    isLoading: boolean,
    skillsData: any,
    interestsData: any,
    languagesData: any
  }

  export default Vue.extend({
    name: 'ProfileEditorCheckbox',
    components: {
      sprcheckbox, sprlanguageselector, sprbutton, loading },
    mixins: [enumhelper],

    props: {
      editor: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        //
        skillsData: this.$store.state.profile.personalSkills,
        interestsData: this.$store.state.profile.areasOfInterest,
        languagesData: this.$store.state.profile.spokenLanguages,
        isLoading: false,
        hasChanges: false
      }
    },
    computed: {
      actualData: function () {
        switch (this.editor) {
        case 'skills':
          return this.skillsData
        case 'interest':
          return this.interestsData
        case 'languages':
          return this.languagesData
        }
        return []
      },
      objectSet: function () {
        switch (this.editor) {
        case 'skills':
          return this.$store.state.translations[this.firstLanguage].skills
        case 'interest':
          return this.$store.state.translations[this.firstLanguage].interest
        case 'languages':
          return _.pickBy(this.$store.state.translations[this.firstLanguage].languages, (lang, key) => { return (key !== 'none' && key.length === 2) })
        }
        return []
      },
      idSet: function () {
        switch (this.editor) {
        case 'skills':
          return this.$store.state.profilePersonalSkills
        case 'interest':
          return this.$store.state.profileAreasOfInterest
        case 'languages':
          return null
        }
        return []
      },
      wholeSet: function () {
        if (this.editor === 'languages') {
          return _.union(Object.keys(this.objectSet), this.$store.state.profile.spokenLanguages)
        }
        return Object.keys(this.objectSet)
      },
      vuixMutation: function () {
        if (this.isLegal) {
          return 'profile' + this.editor
        }
        return ''
      },
      isLegal: function () {
        return (this.editor === 'skills' || this.editor === 'interest' || this.editor === 'languages')
      },
      translationPrefix: function () {
        return this.isLegal ? this.editor : ''
      }
    },
    mounted: function () {
      if (!this.isLegal) {
        this.$router.push('/profile')
      }
    },
    methods: {
      editSelection: function (value, truth) {
        if (truth) {
          // value was just enabled
          if (this.actualData.indexOf(value) === -1) {
            // actual data does not already contain this item, add it
            var add = this.actualData.slice()
            add.push(value)
            switch (this.editor) {
            case 'skills':
              this.skillsData = add
              break
            case 'interest':
              this.interestsData = add
              break
            case 'languages':
              this.languagesData = add
              break
            }
          }
        } else {
          // value was just disabled

          var index = this.actualData.indexOf(value)
          if (index !== -1) {
            // actual data does already contain this item, remove it
            var remove = this.actualData.slice()
            remove.splice(index, 1)
            switch (this.editor) {
            case 'skills':
              this.skillsData = remove
              break
            case 'interest':
              this.interestsData = remove
              break
            case 'languages':
              this.languagesData = remove
              break
            }
          }
        }

        switch (this.editor) {
        case 'skills':
          if (!_.isEqual(this.$store.state.profile.personalSkills, this.skillsData)) {
            this.hasChanges = true
          } else {
            this.hasChanges = false
          }
          break
        case 'interest':
          if (!_.isEqual(this.$store.state.profile.areasOfInterest, this.interestsData)) {
            this.hasChanges = true
          } else {
            this.hasChanges = false
          }
          break
        case 'languages':
          if (!_.isEqual(this.$store.state.profile.spokenLanguages, this.languagesData)) {
            this.hasChanges = true
          } else {
            this.hasChanges = false
          }
          break
        }
        this.$emit('onChange', this.hasChanges)
      },
      resolveId: function (value) {
        switch (this.editor) {
        case 'skills':
          return _.find(this.$store.state.profilePersonalSkills, function (o) { return o.text === value }).id
        case 'interest':
          return _.find(this.$store.state.profileAreasOfInterest, function (o) { return o.text === value }).id
        case 'languages':
          return null
        }
      },
      cancelButton: function () {
        this.$emit('onCancel')
        this.$router.go(-1)
      },
      save: function () {
        this.isLoading = true
        switch (this.editor) {
        case 'skills':
          this.$store.state.profile.personalSkills = this.skillsData
          break
        case 'interest':
          this.$store.state.profile.areasOfInterest = this.interestsData
          break
        case 'languages':
          this.$store.state.profile.spokenLanguages = this.languagesData
          break
        }
        // @ts-ignore cannot find mixins
        this.updateOwnProfile(succ => {
          // @ts-ignore cannot find mixins
          this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
          this.$emit('onSave')
          this.$router.go(-1)
          this.isLoading = false
        }, f => {
          // @ts-ignore cannot find mixins
          this.showAlertError(this.$t('common.fetchFailed'))
          this.isLoading = false
        })
      }
    }
  })
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.comp-profile-editor-checkbox {
  .checkbox {
    color: $brand-black;
    border: 1px solid $brand-grey3;
    box-shadow: none;
  }

  .dropdown {
    margin: 30px auto;
    max-width: 300px;
  }
  .page-body {
    min-height: calc(100vh - 466px);
    background: $brand-white;
    padding-bottom: 150px;
    position: relative;

    .button-footer {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      height: 140px;
      margin-top: 10px;
    }
  }

  .inputs {
    width: calc(100% - 20px);
    max-width: 300px;
    border: 2px solid $brand-red;
    background: $brand-white;
    padding-top: 14px;
    padding-bottom: 14px;
    color: $brand-red;
    &:hover

    {
      color: $brand-white;
      background: $brand-red;
    }
  }
  .centerloading {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;

    span {
      margin: auto;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }
  }
}

</style>
