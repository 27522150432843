import Vue from 'vue'
import VueResource from 'vue-resource'
import moment from 'moment'
import { Rule, RuleValidate } from 'vee-validate'

Vue.use(VueResource)

const getTypeString = (target: any): string => {
  var targetType = Object.prototype.toString.call(target)
  return targetType
}

const isADate = (d: any): boolean => {
  if (!(Object.prototype.toString.call(d) === '[object Date]')) {
    return false
  }

  return !isNaN(d.getTime())
}

const convertFromFinnishDateStringToDate = (d: string): Date => {
  const arr = d.toString().split('.')

  return new Date(`${arr[2]}-${arr[1]}-${arr[0]}`)
}

const getDate = (val: string | Date): Date => {
  if (isADate(val)) {
    return val as Date
  }

  var regex = /^\d{1,2}\.\d{1,2}\.\d{4}$/
  const valToTest = val.toString()

  if (regex.test(valToTest)) {
    return convertFromFinnishDateStringToDate(val.toString())
  } else {
    var parts = val.toString().split('-') // In format: YYYY-MM-DDT00:00:00Z ?

    if (parts.length === 3) {
      var year = parts[0]
      var month = parts[1]
      var endParts = parts[2].toString().split(':')

      if (endParts.length === 3) {
        var dayParts = endParts[0].toString().split('T')

        if (dayParts.length === 2) {
          var day = dayParts[0]
          var date = `${day}.${month}.${year}`

          if (regex.test(date)) {
            return convertFromFinnishDateStringToDate(date.toString())
          }
        }
      }
    }

    throw new Error(`Value must be in the format DD.MM.YYYY, but it is ${val}`)
  }
}

// usage: v-validate="required|dateisbefore:20.02.2000"
const validatorDateIsBefore: RuleValidate | Rule = {
  getMessage (field: string, args: Array<string>): string {
    return `${field} must be before ${moment(args[0], 'DD.MM.YYYY').format('DD.MM.YYYY')}.`
  },
  validate (value: Date | string, args: object | Array<any> | undefined): boolean {
    if (!args) {
      return false
    }

    if (Array.isArray(args) && !(typeof args[0] === 'string' && !isADate(args[0]))) {
      throw new Error('The first argument must be a string in the format DD.MM.YYYY or a Date')
    }

    value = getDate(value)

    var checkDate = Array.isArray(args)
      ? getDate(args[0])
      // @ts-ignore
      : getDate(args)

    var result = value <= checkDate

    return result
  }
}

export default validatorDateIsBefore