<template>
  <div class="comp-wizard-content container join-container">
    <div class="row mx-auto">
      <div class="col-12">
        <div>
          <span class="h1"
                role="heading"
                aria-level="1"
          >{{ $t('join.personalHeader') }}</span>
        </div>
      </div>
      <div v-if="bannerVisible" class="col-12">
        <div class="option warning-banner">
          <div class="option-value warning-option">
            <tickcrosswarning :value="option1Value" />
          </div>
          <div class="option-label">
            {{ $t('join.personalHeaderBanner') }}
          </div>
        </div>
      </div>

      <div class="col-12">
        <sprvalidation class="validation-box"
                       :validation-errors="validationErrors"
        />
      </div>
      <div class="col-12">
        <p>{{ $t('join.personalDescription') }}</p>
      </div>
    </div>

    <div class="row mx-auto">
      <div class="col-12 col-sm-12">
        <sprinput v-model="value.personal.firstName"
                  v-validate="'required'"
                  :hint="$t('wizard.firstNameField')"
                  class="w-100"
                  :is-valid="!errors.has('firstNameField')"
                  name="firstNameField"
                  data-vv-delay="15000"
                  dark
        />
      </div>
      <div class="col-12 col-sm-6">
        <sprinput v-model="value.personal.lastName"
                  v-validate="'required'"
                  :hint="$t('wizard.lastNameField')"
                  class="w-100"
                  :is-valid="!errors.has('lastNameField')"
                  name="lastNameField"
                  data-vv-delay="15000"
                  dark
        />
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-12">
        <date v-model="value.personal.age"
              v-validate="birthdateValidation"
              :in-title="$t('wizard.ageField') + ': '"
              class="fill-width wizard-style ml-1 mt-2"
              name="ageField"
              :is-valid="!errors.has('ageField')"
              :wizard-style="true"
              dark
        />
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-12 col-sm-6">
        <native v-model="value.personal.nativeLanguage"
                class="spr-input spr-roundbox-1 spr-shadow pl-3 w-100 mx-1"
                :lang-pool="undefined"
                name="nativeLanguage"
                alternative-prefix="join.language"
        />
      </div>
    </div>
  </div>
</template>

<script lang ="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import forminput from '../SPRSimpleText.vue'
  import sprinput from '../SPRInput.vue'
  import sprvalidation from '../SPRValidationError.vue'
  import date from '../SPRSimpleDatepickerDashYear.vue'
  import native from '../SPRNativeLanguageSelector.vue'
  import datetwoyears from '../../helpers/datehelper'
  import tickcrosswarning from '../SPRTickCrossWarning.vue'
  import { TranslateResult } from 'vue-i18n'

  // the data object
  export interface TemplateComponentData {
    validatedFields: Array<string>
  }

  export default Vue.extend({
    name: 'JoinPersonal',
    components: { forminput, sprinput, sprvalidation, native, date, tickcrosswarning },
    props: {
      option1Value: {
        type: Number,
        required: false,
        default: 3 // 3 - Warning
      },
      value: {
        type: Object,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        validatedFields: ['firstNameField', 'lastNameField', 'ageField']
      }
    },
    computed: {
      bannerVisible (): string | TranslateResult {
        return this.$t('join.personalHeaderBanner')
      },
      birthdateValidation () {
        return 'required' //datetwoyears.birthdateValidation()
      },
      dateIsValid (): boolean {
        if (!this.value.personal.age) return false
        else return !this.value.personal.age.toString().includes('0000')
      },
      validationErrors: function (): Array<Array<string>> {
        if (!this.validatedFields) {
          return []
        }
        var arr = [] as Array<Array<string>>
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['', err.field + '_' + err.rule, ''])
          }
        })
        return arr
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

  .warning-option {
    margin-left: 11px;
    margin-right: 11px;
  }

  .warning-banner {
    margin-top: 6px;
    padding: 8px;
    display: flex;
    background: lemonchiffon;
  }

  .join-container {
    .row {
      max-width: 600px;
         }
  }

</style>
