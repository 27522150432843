<template>
  <div v-if="volunteer && volunteer.friendInfo" class="volunteercardnet">
    <div class="topsection">
      <div class="circle">
        <friendshipicon
          v-if="friendshipStateOverride"
          :friendname="volunteer.firstName"
          :state="friendshipStateOverride"
        />
        <friendicon
          v-else
          :friendname="volunteer.firstName"
          :image="volunteer.portrait"
          :state="volunteer.friendInfo.netProfile.onHold ? 6 : volunteer.friendInfo.netProfile.state"
          :multiple-friendships="volunteer.friendInfo.netProfile.multipleCustomers"
        />
      </div>
      <div class="details">
        <div class="name">
          <span>{{ volunteer.firstName }} {{ volunteer.lastName }}</span>
          <font-awesome-icon v-if="!volunteer.isNonIdent" icon="user" class="identuser" :title="$t('customerSearch.isIdent')" />
        </div>
        <div class="personal">
          <div>{{ genderAgeText }}</div>
          <div>{{ $t('customerSearch.clientTypeWish') }}: {{ customerTypes || '&ndash;' }}</div>
          <div v-if="volunteer.friendInfo.netProfile.shortDescription" class="short-description">
            <font-awesome-icon icon="quote-left" aria-hidden="true" /><span>{{ volunteer.friendInfo.netProfile.shortDescription }}</span><font-awesome-icon icon="quote-left" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>

    <div class="properties">


      <div class="row property friendshiptypes">
        <div class="icon">
          <font-awesome-icon icon="calendar" />
        </div>
        <div class="content">
          {{ $t('customerSearch.seek') }}: <strong>{{ friendshipTypes || '&ndash;' }}</strong>
        </div>

      </div>

      <div class="row property genders">
        <div class="icon">
          <font-awesome-icon icon="venus-mars" />
        </div>
        <div class="content">
          <div>{{ $t('customerSearch.am') }} <strong>{{ gender.toLowerCase() || '...?' }}</strong></div>
          <div>{{ $t('customerSearch.forFriend') }}: <strong>{{ genderPreferences || '&ndash;' }}</strong></div>
        </div>
        <div v-if="showMatches" class="match">
          <tickcrosswarning v-model="matches.genderMatch" />
        </div>
      </div>

      <div class="row property age">
        <div class="icon">
          <font-awesome-icon icon="id-badge" />
        </div>
        <div class="content">
          <div>{{ $t('customerSearch.am') }} <strong>{{ age || '?' }} {{ $t('customerSearch.years') }}</strong></div>
          <div>{{ $t('customerSearch.forFriend') }}: <strong>{{ agePreferences || '&ndash;' }}</strong></div>
        </div>
        <div v-if="showMatches" class="match">
          <tickcross v-model="matches.ageMatch" />
        </div>
      </div>

      <div class="row property language">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'comment']" />
        </div>
        <div class="content">
          <div>{{ $t('customerSearch.speak') }}: <strong>{{ normalizeTranslation(volunteer.nativeLanguage) }} ({{ $t('customerSearch.nativeLanguage') }})</strong></div>
          <div v-if="spokenLanguages">
            <strong>{{ spokenLanguages }}</strong>
          </div>
        </div>
        <div v-if="showMatches" class="match">
          <tickcross v-model="matches.languageMatch" />
        </div>
      </div>

      <div class="row property interest">
        <div class="icon">
          <font-awesome-icon icon="puzzle-piece" />
        </div>
        <div class="content">
          {{ $t('customerSearch.interestedIn') }}: <strong>{{ interests || '&ndash;' }}</strong>
        </div>
      </div>

      <div
        v-if="hasNetProfileContactDetails"
        class="row property netcontacts"
      >
        <div class="icon">
          <font-awesome-icon icon="wifi" />
        </div>
        <div class="content">
          {{ $t('customerSearch.netcontactdetails') }}
          <dl v-if="volunteer.friendInfo.netProfile.econtactEmail">
            <dt>{{ $t('connectivity.email') }}</dt>
            <dd>{{ volunteer.friendInfo.netProfile.econtactEmail }}</dd>
          </dl>
          <dl v-if="volunteer.friendInfo.netProfile.econtactSkype">
            <dt>{{ $t('connectivity.skype') }}</dt>
            <dd>{{ volunteer.friendInfo.netProfile.econtactSkype }}</dd>
          </dl>
          <dl v-if="volunteer.friendInfo.netProfile.econtactDiscord">
            <dt>{{ $t('connectivity.discord') }}</dt>
            <dd>{{ volunteer.friendInfo.netProfile.econtactDiscord }}</dd>
          </dl>
          <dl v-if="volunteer.friendInfo.netProfile.econtactInstagram">
            <dt>{{ $t('connectivity.instagram') }}</dt>
            <dd>{{ volunteer.friendInfo.netProfile.econtactInstagram }}</dd>
          </dl>
          <dl v-if="volunteer.friendInfo.netProfile.econtactKik">
            <dt>{{ $t('connectivity.kik') }}</dt>
            <dd>{{ volunteer.friendInfo.netProfile.econtactKik }}</dd>
          </dl>
          <dl v-if="volunteer.friendInfo.netProfile.econtactTelegram">
            <dt>{{ $t('connectivity.telegram') }}</dt>
            <dd>{{ volunteer.friendInfo.netProfile.econtactTelegram }}</dd>
          </dl>
          <dl v-if="volunteer.friendInfo.netProfile.econtactFreetext">
            <dt>{{ $t('connectivity.freetext') }}</dt>
            <dd>{{ volunteer.friendInfo.netProfile.econtactFreetext }}</dd>
          </dl>
        </div>
      </div>

      <div v-show="volunteer.friendInfo.hasHealthCareTraining" class="row property training">
        <div class="icon">
          <font-awesome-icon icon="plus" />
        </div>
        <div class="content">
          <span v-if="volunteer.friendInfo.healthCareTrainingFreetext">{{ $t('customerSearch.hasHealthCareTraining') }} | {{ volunteer.friendInfo.healthCareTrainingFreetext }}</span>
          <span v-else>{{ $t('customerSearch.unspecifiedHealthcareTraining') }}</span>
        </div>
      </div>
    </div>

    <div class="moreinfo">
      <div class="row">
        <div class="col description">
          <sprcol :heading="$t('profile.notes')">
            <div slot="body">
              <sprprivatenotes v-model="volunteer.friendInfo" endpoint="volunteerupdateprivatenotesnet" :friend-management-group-id="group.id" :target-id="targetId" is-net />
            </div>
          </sprcol>
        </div>
      </div>
      <div class="row">
        <div class="col contact">
          <sprcol :heading="$t('customerSearch.contactTitle')">
            <div slot="body">
              <p class="smalltext">
                {{ contactDetails }}
              </p>
            </div>
          </sprcol>
        </div>
      </div>
      <div v-if="volunteer.friendInfo.netProfile.volunteerInterviewed" class="row">
        <div class="col other">
          <sprcol :heading="$t('customerSearch.otherInfoTitle')">
            <div slot="body">
              <dl class="row">
                <dt class="col-12">
                  {{ $t('customerForm.customerInterviewed') }}
                </dt>
                <dd class="col-12">
                  {{ volunteer.friendInfo.netProfile.volunteerInterviewedDate }} || {{ volunteer.friendInfo.netProfile.volunteerInterviewedBy }}
                </dd>
              </dl>
            </div>
          </sprcol>
        </div>
      </div>
      <div v-if="isOnHold" class="row">
        <div class="col onhold">
          <sprcol :heading="$t('volunteerForm.onHoldDescription')">
            <div slot="body">
              <dl class="row">
                <dt class="col-12">
                  {{ $t('customerSearch.onHoldUntilDate') }}
                </dt>
                <dd class="col-12">
                  {{ formattedOnHoldUntilDate }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-12">
                  {{ $t('customerSearch.onHoldComment') }}
                </dt>
                <dd class="col-12">
                  {{ volunteer.friendInfo.netProfile.onHoldDetails }}
                </dd>
              </dl>
            </div>
          </sprcol>
        </div>
      </div>
    </div>

    <!-- Actions -->
    <div v-if="loaded" class="row button-row">
      <div v-if="showActions" class="actions">
        <div class="col buttons">
          <volunteerActions
            v-model="volunteer"
            :group="group"
            :loaded="loaded"
            @saveVolunteer="saveVolunteer"
            @toggleActivity="toggleActivity"
          />
        </div>
      </div>
    </div>
    <div v-if="isSending" class="text-center">
      <Loading />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import friendicon from './FriendIcon.vue'
  import friendshipicon from './FriendshipIcon.vue'
  import tickcross from '../SPRTickCross.vue'
  import tickcrosswarning from '../SPRTickCrossWarning.vue'
  import sprcol from '../SPRCollapsible.vue'
  import sprprivatenotes from '../SPRPrivateNotes.vue'
  import volunteerActions from './VolunteerActions.vue'
  import Loading from '../SPRLoading.vue'

  import moment from 'moment'

  import enumhelper from '../../mixins/enums.js'
  import datehelper from '../../mixins/dates.js'
  import locationhelper from '../../mixins/locationutils.js'
  import scrollhelper from '../../mixins/scroll.js'
  import { UserProfileDto, FriendshipState, FriendManagementDto, CustomerState } from '@/types/index'
  import { YesNoWarning, MatchData } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    volunteer: UserProfileDto,
    loaded: boolean,
    isSending: boolean
  }

  export default Vue.extend({
    name: 'VolunteerCardNet',
    components: { friendicon, friendshipicon, tickcross, tickcrosswarning, sprcol, sprprivatenotes, volunteerActions, Loading },
    mixins: [enumhelper, datehelper, locationhelper, scrollhelper],
    props: {
      value: {
        type: Object as () => UserProfileDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      },
      showActions: {
        type: Boolean,
        default: () => {
          return true
        }
      },
      showMatches: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      matches: {
        type: Object as () => MatchData,
        default: () => {
          return {
            travelData: {
              travelDistanceTime: '',
              isMatch: false,
              isRealDistance: false
            },
            availabilityTimesMatch: false,
            availabilityFrequencyMatch: false,
            friendshipTypesMatch: false,
            genderMatch: YesNoWarning.No, // 'FAIL',
            ageMatch: false,
            languageMatch: false,
            interestMatch: false
          } as MatchData
        }
      },
      friendshipStateOverride: {
        type: Number as () => FriendshipState,
        required: false,
        default: undefined
      }
    },
    data (): TemplateComponentData {
      return {
        volunteer: this.value,
        loaded: true,
        isSending: false
      }
    },
    computed: {
      isNonIdent (): boolean {
        return this.value.isNonIdent
      },
      gender (): string | LocaleMessage {
        // @ts-ignore - mixins not picked up
        return this.getGenderTranslation(this.volunteer.friendInfo.gender)
      },
      isOnHold (): boolean {
        return this.volunteer.friendInfo.netProfile.state === CustomerState.OnHold
      },
      formattedOnHoldUntilDate (): string {
        if (this.volunteer.friendInfo.netProfile.onHoldDeadline) {
          const date = this.volunteer.friendInfo.netProfile.onHoldDeadline
          const formattedDate = moment(date).format('DD.MM.YYYY')

          return formattedDate
        } else {
          return ''
        }
      },
      age (): number {
        // @ts-ignore - mixins not picked up
        return this.calculateAge(this.volunteer.age)
      },
      genderAgeText (): string {
        return [this.gender, this.age ? `${this.age} ${this.$t('customerSearch.years')}` : ''].filter(Boolean).join(', ')
      },
      customerTypes (): string {
        var self = this
        // @ts-ignore - mixins not picked up
        return this.getNetCustomerTypesTranslations((item) => {
          // @ts-ignore - mixins not picked up
          return _.includes(this.integerIntoBitmaskProxy(self.volunteer.friendInfo.netProfile.customerTypes), item.id)
        }).filter(Boolean).join(', ')
      },
      fastestTransportation (): number | undefined {
        var self = this
        var fastest = [1, 2, 4, 3]
        return _.find(fastest, (item) => {
          return _.includes(self.volunteer.transportation, item)
        })
      },
      transportationText (): string {
        // @ts-ignore - mixins not picked up
        var mode = this.getTransportationTranslation(this.fastestTransportation)
        var time = (this.volunteer.travelTime ? this.volunteer.travelTime : '?') + ' min'
        return [time, mode].filter(Boolean).join(', ')
      },
      availabilityText (): string {
        var self = this
        // @ts-ignore - mixins not picked up
        var times = this.getAvailabilityTimesTranslations((item) => {
          // @ts-ignore - mixins not picked up
          return _.includes(this.integerIntoBitmaskProxy(self.volunteer.friendInfo.netProfile.availabilityTimes), item.id)
        }).filter(Boolean).join(', ')
        // @ts-ignore - mixins not picked up
        var frequency = this.getAvailabilityFrequencyTranslation(this.volunteer.friendInfo.netProfile.availabilityFrequence)
        return [times, frequency].filter(Boolean).join(' | ')
      },
      friendshipTypes (): string {
        // @ts-ignore - mixins not picked up
        return this.getNetFriendshipTypesMaskTranslation(this.volunteer.friendInfo.netProfile.friendshipTypes)
      },
      genderPreferences (): string {
        // @ts-ignore - mixins not picked up
        return this.getGenderPreferencesMaskTranslation(this.volunteer.friendInfo.netProfile.preferredGenders)
      },
      agePreferences (): string {
        // @ts-ignore - mixins not picked up
        return this.getAgePreferencesMaskTranslation(this.volunteer.friendInfo.netProfile.preferredAges)
      },
      spokenLanguages (): string {
        var self = this
        var languages = [] as string[]
        _.forEach(this.volunteer.spokenLanguages, (item) => {
          if (item !== self.volunteer.nativeLanguage) {
            // @ts-ignore - mixins not picked up
            languages.push(self.normalizeTranslation(item))
          }
        })
        languages.push(this.volunteer.otherLanguages)
        return languages.filter(Boolean).join(', ')
      },
      interests (): string {
        // @ts-ignore - mixins not picked up
        return this.getInterestsMaskTranslation(this.volunteer.friendInfo.netProfile.interests)
      },
      contactDetails (): string {
        var phone = this.volunteer.phoneNumber ? `${this.$t('customerSearch.telAbbreviation')} ${this.volunteer.phoneNumber}` : ''
        var email = this.volunteer.email ? `${this.$t('customerSearch.emailAbbreviation')}: ${this.volunteer.email}` : ''
        return [phone, email].filter(Boolean).join(' | ')
      },
      hasNetProfileContactDetails (): boolean {
        var p = this.volunteer.friendInfo.netProfile
        return p && (
          Boolean(p.econtactDiscord) ||
          Boolean(p.econtactEmail) ||
          Boolean(p.econtactFreetext) ||
          Boolean(p.econtactInstagram) ||
          Boolean(p.econtactKik) ||
          Boolean(p.econtactSkype) ||
          Boolean(p.econtactTelegram))
      },
      targetId (): number {
        return this.volunteer.friendInfo.netProfile.id
      }
    },
    watch: {
      value (newVal): void {
        this.volunteer = newVal
      }
    },
    methods: {
      getUpdateData (): any {
        if (!this.isNonIdent) {
          // @ts-ignore - mixins not picked up
          return this.prune(this.volunteer.friendInfo)
        }
        // @ts-ignore - mixins not picked up
        return this.prune(this.volunteer)
      },
      getUpdateUrl (): string {
        if (!this.isNonIdent) {
          return this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.updateidentnetfriendvolunteer[0]
        }
        return this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.updatenonidentnetfriendvolunteer[0]
      },
      notifyRefresh (): void {
        this.$emit('refresh')
      },
      saveVolunteer (): void {
        var self = this
        self.isSending = true
        this.$validator.validateAll().then(result => {
          if (result) {
            var url = this.getUpdateUrl()
            var data = this.getUpdateData()
            this.$http.put(url, data).then(response => {
              if (response.ok) {
                self.isSending = false
              }
            }, response => {
              self.isSending = false
              if (response.bodyText) {
                // @ts-ignore - mixins not picked up
                this.showAlertError(`${this.$t('common.saveFailed')}: ${response.bodyText}`)
              }
              console.log('Error saving volunteer')
            })
          } else {
            // @ts-ignore - mixins not picked up
            this.firstError()
          }
        })
      },
      toggleActivity (): void {
        this.isSending = true
        var uri = this.$store.state.apiBases.friendservice
        if (this.value.friendInfo.netProfile.state === CustomerState.NonActive) {
          uri += this.$store.state.apiEndpoints.markactivevolunteernet[0]
        } else {
          uri += this.$store.state.apiEndpoints.markinactivevolunteernet[0]
        }

        uri = uri
          .replace('{volunteerid}', this.value.friendInfo.id)
          .replace('{groupid}', this.group?.id)

        const self = this

        this.$http.post(uri).then(() => {
          self.isSending = false
          this.notifyRefresh()
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteercardnet {
    margin-bottom: 30px;
    .topsection {
      min-height: 120px;
      .circle {
        float: left;
        margin-top:20px;
      }
      .details {
        margin-left:70px;

        .name {
          text-transform: uppercase;
          color: $brand-red;
          font-weight: bold;
          position: relative;
          .edit {
            position: absolute;
            left:-15px;
            text-decoration: none;
            color:$brand-red;
          }
          svg {
            margin-left: 5px;
          }
        }

        .personal {
          font-size: 0.8em;
          font-weight:bold;
          .short-description {
            font-weight:normal;
            color:$brand-red;
            font-style: italic;
            margin-top: 10px;
            position:relative;
            span {
              padding: 0 5px;
            }
            svg {
              font-size:12px;
              display:inline-block;
            }
          }
        }
      }
    }

    .properties {
      clear: both;
      margin-top: 10px;
      margin-bottom: 30px;

      .property {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        min-height: 55px;
        margin: 0;
        border-bottom: solid 1px $brand-grey3;
        padding: 5px 0;

        .icon {
          flex: 0 0 40px;
          text-align: center;

          font-size: 2em;
          color:$brand-red;
        }
        //IE11
        *::-ms-backdrop, .icon { margin-top: 15px; }

        .content {
          margin-left: 10px;
          flex: auto;

          font-size: 0.8em;
          dl {
            margin-bottom: 0;
            dt {
              font-size: 1em;
            }
          }
        }

        .match {
          flex: 0 0 40px;
          text-align: right;

          font-size: 1.5em;
        }
      }
    }

    .moreinfo {
      .description,
      .contact,
      .other {
        margin-bottom: 20px;
      }
      dl {
        margin-bottom: 0;
        font-size: 0.8em;
        dt {
          font-size: 1em;
        }
      }
    }

    .button-row {
    text-align: center;
    margin-top: 15px;

      .load-indicator {
        width: 100%;
        margin-bottom: 15px;
        color: $brand-red;
      }
      button {
        margin-bottom: 15px;
        &.button-disabled {
          border-color: $brand-grey4;
          color: $brand-grey4;
          cursor: not-allowed;
          outline: none;
          &:hover {
            background-color:$brand-white;
          }
        }
      }
    }
  }
</style>
