import Vue from 'vue'
import VueResource from 'vue-resource'
import { Rule, RuleValidate } from 'vee-validate'

Vue.use(VueResource)

const validatorHasCheckboxSelected: RuleValidate | Rule = {
  getMessage (field: string, args: Array<any>): string {
    return 'validation.' + field
  },
  validate (value: number, args: Array<any> | undefined | object): boolean {
    if (Array.isArray(value) && value.length > 0) {
      // Check if any element in args has an object with selected equals to true
      return value.some(item => typeof item === 'object' && item.selected === true)
    }

    // If args is not an array or has no elements, return false
    return false
  }
}

export default validatorHasCheckboxSelected