<template>
  <div class="row customermatchstatus">
    <div
      v-if="canAddNewMatch"
      class="col-12 addnew text-center"
    >
      <sprbutton type="primary"
                 :size="4"
                 :title="$t('customerSearch.newMatch')"
                 click-event="new"
                 @new="onNewMatch"
      />
    </div>
    <div v-if="!hasCustomer" class="col-12">
      <div class="no-volunteers">
        <div>{{ $t('customerSearch.noMatches') }}</div>
      </div>
    </div>
    <div v-else class="col-12">
      <ul class="customer-list">
        <li v-for="item in customer.friendships" :key="item.id" :class="{ 'starthidden fadein': isHighlightedItem(item.volunteerId) }">
          <customermatchdetail :friendship="item" :group="group" @selectFriendship="onSelectFriendship" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  import customermatchdetail from './CustomerMatchDetail.vue'
  import { FriendCustomerStandardDto, FriendCustomerNetDto, FriendManagementDto } from '@/types/index'

  export default Vue.extend({
    name: 'CustomerMatchStatus',
    components: { sprbutton, customermatchdetail },
    props: {
      customer: {
        type: Object as () => FriendCustomerStandardDto | FriendCustomerNetDto | undefined,
        default: null
      },
      group: {
        type: Object as () => FriendManagementDto,
        default: ''
      },
      highlightedvolunteerid: {
        type: String,
        default: ''
      }
    },
    computed: {
      hasCustomer (): boolean {
        return Boolean(this.customer && this.customer.friendships?.length)
      },
      canAddNewMatch (): boolean {
        return !this.hasCustomer || Boolean(this.customer?.multipleVolunteers)
      }
    },
    methods: {
      onNewMatch (): void {
        this.$emit('addNewMatch')
      },
      onSelectFriendship (friendship): void {
        this.$emit('volunteerMatchSelected', friendship)
      },
      isHighlightedItem (id): boolean {
        return Boolean(this.highlightedvolunteerid && Number(this.highlightedvolunteerid) === Number(id))
      }
    }
  })
</script>
<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .customermatchstatus {
    margin-bottom: 30px;
    .addnew {
      margin-bottom: 20px;
    }
    .inactive {
      text-align: center;
      padding: 15px 5px 30px 5px;
    }
    .no-volunteers {
      text-align: center;
    }

    .customer-list {
      list-style-type: none;
      padding: 15px;

      li {
        padding: 10px 0 0 0;

        &.fadein {
          -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fadein 2s; /* Firefox < 16 */
          -ms-animation: fadein 2s; /* Internet Explorer */
          -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;
        }

        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }

        /* Firefox < 16 */
        @-moz-keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }

        /* Safari, Chrome and Opera > 12.1 */
        @-webkit-keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }

        /* Internet Explorer */
        @-ms-keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }

        /* Opera < 12.1 */
        @-o-keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
      }
    }
  }
</style>
