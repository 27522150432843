<template>
  <div class="row comp-profile-editor-personal mx-auto text-center my-5">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <sprvalidation :validation-errors="validationErrors" class="mb-5" />
        </div>
      </div>
      <div class="row">
        <!-- first name -->
        <div class="col-12 col-md-6">
          <sprinput v-model="personal.firstName" v-validate="'required'"
                    :hint="$t('wizard.firstNameField')" class="editor-field"
                    :is-valid="!errors.has('firstNameField')" name="firstNameField"
          />
        </div>
        <!-- last name -->
        <div class="col-12 col-md-6">
          <sprinput v-model="personal.lastName" v-validate="'required'"
                    :hint="$t('wizard.lastNameField')" class="editor-field"
                    :is-valid="!errors.has('lastNameField')" name="lastNameField"
          />
        </div>
      </div>
      <div class="row">
        <!-- phone -->
        <div class="col-12">
          <sprinput v-model="personal.phoneNumber" v-validate="isPhoneRequired"
                    :hint="$t('wizard.phoneNumberField')" class="editor-field"
                    :is-valid="!errors.has('phoneNumberField')" name="phoneNumberField"
          />
        </div>
      </div>
      <div class="row">
        <!-- street address -->
        <div class="col-12">
          <sprinput v-model="personal.streetAddress" v-validate="'required'"
                    :hint="$t('wizard.streetAddressField')" class="editor-field"
                    :is-valid="!errors.has('streetAddressField')" name="streetAddressField"
          />
        </div>
      </div>
      <div class="row">
        <!-- zip code -->
        <div class="col-12 col-md-4">
          <sprinput v-model="personal.zipCode" v-validate="'required|digits:5'"
                    :hint="$t('wizard.zipCodeField')" class="editor-field"
                    :is-valid="!errors.has('zipCodeField')" name="zipCodeField"
          />
        </div>
        <!-- city == postitoimipaikka -->
        <div class="col-12 col-md-8">
          <sprinput v-model="personal.city" v-validate="'required'"
                    :hint="$t('wizard.cityField')" class="editor-field"
                    :is-valid="!errors.has('cityField')" name="cityField"
          />
        </div>
      </div>
      <div class="row">
        <!-- country -->
        <div class="col-12">
          <sprinput v-model="personal.country" v-validate="'required'"
                    :hint="$t('wizard.countryField')" class="editor-field"
                    :is-valid="!errors.has('countryField')" name="countryField"
          />
        </div>
      </div>
      <div class="row">
        <!-- age -->
        <div class="col-12 mt-2 wrap-date">
          <sprdatepicker v-model="personal.age"
                         v-validate="birthdateValidation"
                         :in-title="$t('wizard.ageField') + ': '"
                         :placeholder="$t('wizard.ageField')"
                         :required="true"
                         class="d-inline-block fill-width"
                         name="ageField"
                         :has-errors="errors.has('ageField')"
          />
        </div>
        <!-- native language -->
        <div class="col-12 col-md-8 mt-2">
          <sprnativelanguageselector v-model="personal.nativeLanguage"
                                     class="spr-roundbox-1 pl-3"
                                     :lang-pool="$store.state.profile.spokenLanguages"
          />
        </div>
      </div>
      <!-- save and cancel buttons -->
      <div v-if="!pending" class="row py-4">
        <div class="col-12 col-md-6 my-1">
          <sprbutton click-event="save-event" :title="$t('profile.save')"
                     :size="1"
                     :bordersize="2" class="fill-width" @save-event="onSave"
          />
        </div>
        <div class="col-12 col-md-6 my-1">
          <sprbutton click-event="cancel-event" :title="$t('profile.cancel')"
                     :size="1"
                     :bordersize="2" class="fill-width" @cancel-event="onCancel"
          />
        </div>
      </div>
      <div v-else class="row pending">
        <div class="col-12 py-4">
          <loading />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang = "ts">
  import sprinput from '../SPRInput.vue'
  import sprnativelanguageselector from '../SPRNativeLanguageSelector.vue'
  import sprdatepicker from '../SPRSimpleDatepicker.vue'
  import sprbutton from '../SPRButton.vue'
  import sprvalidation from '../SPRValidationError.vue'
  import loading from '../SPRLoading.vue'
  import locationutils from '../../mixins/locationutils.js'
  import datetwoyears from '../../helpers/datehelper'
  import Vue from 'vue'
  import * as _ from 'lodash'

  // the data object
  export interface TemplateComponentData {
    initialPersonal: any,
    personal: any,
    data: any,
    hasChanges: boolean,
    pending: boolean,
    validatedFields: string[]
  }

  export default Vue.extend({
    name: 'ProfileEditorPersonal',
    components: { sprinput, sprnativelanguageselector, sprdatepicker, sprbutton, sprvalidation, loading },
    mixins: [locationutils],
    data (): TemplateComponentData {
      const profile = this.$store.state.profile
      const initialPersonal = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        phoneNumber: profile.phoneNumber,
        streetAddress: profile.streetAddress,
        zipCode: profile.zipCode,
        city: profile.city,
        country: profile.country,
        age: new Date(profile.age),
        nativeLanguage: profile.nativeLanguage
      }
      return {
        personal: { ...initialPersonal }, // Use spread operator to create a copy
        initialPersonal, // Store initial values for comparison
        hasChanges: false,
        pending: false,
        validatedFields: ['firstNameField', 'lastNameField', 'streetAddressField', 'zipCodeField', 'cityField', 'countryField', 'ageField', 'phoneNumberField']
      } as TemplateComponentData
    },
    computed: {
      birthdateValidation (): any {
        return datetwoyears.birthdateValidation()
      },
      validationErrors (): any {
        if (!this.validatedFields) {
          return []
        }

        var arr = [] as Array<any>
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['wizard.' + err.field, String(err.rule), String(err.msg)])
          }
        })
        return arr
      },
      zipCode (): string {
        return this.personal.zipCode
      },
      isPhoneRequired (): string {
        return this.$store.state.profile.friendInfo ? 'required' : ''
      }
    },
    watch: {
      zipCode: function (val) {
        if (val.length === 5) {
          var self = this
          this.cityFromZip(val, this, response => {
            self.personal.city = self.$store.state.language === 'se' ? response.body.citySe : response.body.cityFi
          })
        }
      },
      personal: {
        handler (newVal, oldVal) {
          // Do something when any property within personal changes
          this.checkForChanges()
        },
        deep: true // Watch for deep changes within the object
      }
    },
    methods: {
      vuixCommit: function () {
        this.$store.commit('profilepersonal', this.personal)
      },
      checkForChanges () {
        this.hasChanges = false
        // Compare each property of personal with its counterpart in initialPersonal
        for (const key in this.personal) {
          if (key === 'age') { //age needs to be checked separately, as the date on the UI is of a different format than the date from the $store.state object.
            var newVal = this.personal[key]
            var oldVal = this.initialPersonal[key]

            // If the current property is a date, convert it to a Unix timestamp
            if (newVal instanceof Date && oldVal instanceof Date) {
              newVal = newVal.getTime()
              oldVal = oldVal.getTime()
            }
            if (newVal !== oldVal) {
              this.hasChanges = true
              break
            }
          } else {
            if (this.personal[key] !== this.initialPersonal[key]) {
              this.hasChanges = true
              break
            }
          }
        }
        this.$emit('onChange', this.hasChanges)
      },
      onSave: function () {
        this.pending = true
        this.$validator.validateAll().then(success => {
          if (success) {
            this.vuixCommit()
            //@ts-ignore
            this.updateOwnProfile(response => {
              //@ts-ignore
              this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
              this.pending = false
              this.$emit('onSave')
              this.$router.go(-1)
            }, response => {
              //@ts-ignore
              this.showAlertError(this.$t('common.fetchFailed'))
              console.log('Update failed!', response)
              this.pending = false
            })
          }
        })
      },
      onCancel: function () {
        this.$emit('onCancel')
        this.$router.go(-1)
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
.comp-profile-editor-personal {
  max-width: 600px;

  .editor-field {
    width: 100%;
    text-align: left;
  }

  .row input {
    box-shadow: none;
    border: 2px solid $brand-grey3;
  }

  .wrap-date input {
    box-shadow: none !important;
    border: 2px solid $brand-grey3 !important;
  }

  .pending {
    color: $brand-red;
  }
}
</style>
