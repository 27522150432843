<template>
  <div class="comp-friendshipgroups container">
    <div class="listing">
      <div v-for="(g, i) in groups" :key="i" class="listing-row">
        <router-link :to="linkTo(g)">
          {{ g.split(':')[0] }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FriendshipGroups',
    computed: {
      isFriendshipAdmin: function () {
        return this.hasRole('FriendshipAdmin')
      },
      isFriendshipGroupManager: function () {
        return this.hasRole('FriendshipGroupManager')
      },
      isDistrictFriendshipAdmin: function () {
        return this.hasRole('DistrictFriendshipAdmin')
      },
      groups: function () {
        // This is the landing page from the header 'Ystävävälitys' link for FriendshipGroupManager, Friendship Broker and Net Friendship Broker roles.
        // Just load the friendship and net friendship groups they are members of if they are in the above roles
        var isFriendshipBroker = this.hasRole('FriendshipBroker')
        var isNetFriendshipBroker = this.hasRole('NetFriendshipBroker')
        var groups = []
        var netGroups = []
        var finalGroups = []

        if (!this.isFriendshipAdmin && !this.isDistrictFriendshipAdmin && !isFriendshipBroker && !isNetFriendshipBroker && !this.isFriendshipGroupManager) return groups

        groups = this.jwtToken.FriendshipGroups?.split(';')
        netGroups = this.jwtToken.NetFriendshipGroups?.split(';')

        if (isFriendshipBroker || this.isFriendshipAdmin || this.isDistrictFriendshipAdmin || this.isFriendshipGroupManager) {
          finalGroups = finalGroups.concat(groups)
        }

        if (isNetFriendshipBroker) {
          finalGroups = finalGroups.concat(netGroups)
        }
        return finalGroups
      }
    },
    methods: {
      linkTo: function (test) {
        if (this.isFriendshipAdmin || this.isDistrictFriendshipAdmin || this.isFriendshipGroupManager) {
          return '/admin/friendship/' + test.split(':')[1] + '/clients'
        } else {
          return '/friend/search/' + test.split(':')[1] + '/customer'
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .comp-friendshipgroups {
    background: $brand-white;
    padding: 15px;
    .listing {
      display: flex;
      display: -ms-flexbox;
      flex-direction: column;
      -ms-flex-direction: column;

      .listing-row {
        padding: 10px 15px;
        color: $brand-red;

        &:nth-child(even) {
          background: rgba(0,0,0,0.03);
        }

        a {
          color: $brand-red;
        }
      }
    }
  }
</style>
