<template>
  <div class="row comp-useradmin-role-editor my-2">
    <div class="col-12">
      <sprmulti :value="editedRoles"
                :title="$t('roles.rolesDropdown')"
                :items="possibleRoles"
                :placeholder="$t('roles.addRole')"
                class="select-style"
                :has-errors="false"
                @input="mutate"
      />
    </div>
  </div>
</template>

<script>
  import sprmulti from '../SPRMultiSelect.vue'

  export default {
    name: 'UserRoleEditor',
    components: { sprmulti },
    props: {
      roles: {
        type: Array,
        default: () => []
      },
      id: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {
        editedRoles: []
      }
    },
    computed: {
      postValue: function () {
        const self = this

        // user cannot revoke roles they are not allowed to revoke. So there may be some roles they cannot see in the pills
        // we have to make sure that these roles remain in the array for updating
        // add new roles to the existing roles
        const mutatedRoles = _.union(this.roles, this.editedRoles)

        // remove any roles that the user is allowed to remove, and are removed in editedRoles
        _.remove(mutatedRoles, function (role) {
          const possibleToDeleteRole = _.find(self.possibleRoles, function (r) {
            if (r === '') return false

            return r.value === role
          })

          const editedRole = self.editedRoles[_.indexOf(self.editedRoles, role)]

          return _.isUndefined(editedRole) && possibleToDeleteRole
        })

        return {
          userId: this.id,
          roles: mutatedRoles
        }
      },
      possibleRoles: function () {
        var rv = []

        rv.push({ value: 'Admin', text: this.$t('roles.admin'), disabled: !this.hasRole('Admin') })
        rv.push({ value: 'NationalAdmin', text: this.$t('roles.nationalAdmin'), disabled: !this.hasRole('Admin') })
        //rv.push({ value: 'NationalEmployee', text: this.$t('roles.nationalEmployee'), disabled: !this.hasRole('Admin') })
        rv.push({ value: 'NationalEmployee', text: this.$t('roles.nationalEmployee'), disabled: !this.hasRole('Admin') && !this.hasRole('NationalAdmin') })
        rv.push({ value: 'DistrictAdmin', text: this.$t('roles.districtAdmin'), disabled: !this.hasRole('Admin') })
        rv.push({ value: 'Employee', text: this.$t('roles.employee'), disabled: !this.hasRole('Admin') && !this.hasRole('DistrictAdmin') && !this.hasRole('NationalAdmin') && !this.hasRole('NationalEmployee') })
        rv.push({ value: 'J1', text: this.$t('roles.j1'), disabled: !this.hasRole('Admin') && !this.hasRole('Employee') && !this.hasRole('DistrictAdmin') && !this.hasRole('NationalAdmin') && !this.hasRole('NationalEmployee') })
        rv.push({ value: 'J2', text: this.$t('roles.j2'), disabled: !this.hasRole('Admin') && !this.hasRole('Employee') && !this.hasRole('J1') && !this.hasRole('DistrictAdmin') && !this.hasRole('NationalAdmin') && !this.hasRole('NationalEmployee') })
        rv.push({ value: 'FriendshipAdmin', text: this.$t('roles.friendshipAdmin'), disabled: !this.hasRole('Admin') })
        rv.push({ value: 'DistrictFriendshipAdmin', text: this.$t('roles.districtFriendshipAdmin'), disabled: !this.hasRole('Admin') })
        rv.push({ value: 'FriendshipGroupManager', text: this.$t('roles.friendshipGroupManager'), disabled: !this.hasRole('Admin') && !this.hasRole('FriendshipAdmin') && !this.hasRole('DistrictFriendshipAdmin') })
        rv.push({ value: 'FriendshipTrainer', text: this.$t('roles.friendshipTrainer'), disabled: !this.hasRole('Admin') && !this.hasRole('FriendshipAdmin') })
        rv.push({ value: 'FriendshipBroker', text: this.$t('roles.friendshipBroker'), disabled: !this.hasRole('Admin') && !this.hasRole('FriendshipGroupManager') && !this.hasRole('FriendshipAdmin') && !this.hasRole('DistrictFriendshipAdmin') })
        rv.push({ value: 'NetFriendshipBroker', text: this.$t('roles.netFriendshipBroker'), disabled: !this.hasRole('Admin') && !this.hasRole('FriendshipAdmin') })

        return rv
      }
    },
    mounted: function () {
      this.editedRoles = this.roles
    },
    methods: {
      mutate: function (value) {
        this.editedRoles = _.map(value, function (v) { return v.value })
        this.editedRoles = _.filter(this.editedRoles, function (v) { return v !== '' })
        this.$emit('input', this.editedRoles)
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
  .comp-useradmin-role-editor {
    .select-style {
      width: 100%;

      .spr-simple-dropdown {
        max-width: 300px;
      }

      .spr-roundbox-1 { border: none !important; }
      select { border: 2px solid $brand-grey3 !important; }
    }
  }
</style>
