<template>
  <div class="customercommon">
    <!-- personal info section -->
    <div class="row">
      <div class="col">
        <h3>{{ $t('customerForm.personalInformation') }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <sprdropdownnumberkey v-model="customer.gender"
                              :title="$t('customerForm.gender')"
                              :items="genders"
                              :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <forminput
          v-model="customer.firstName"
          v-validate="'required'"
          :hint="$t('customerForm.firstname')"
          :is-valid="!errors.has('customerForm.firstname')"
          name="customerForm.firstname"
          :required="true"
        />
      </div>
      <div class="col-sm-6">
        <forminput
          v-model="customer.lastName"
          v-validate="'required'"
          :hint="$t('customerForm.lastname')"
          :is-valid="!errors.has('customerForm.lastname')"
          name="customerForm.lastname"
          :required="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <forminput
          v-model="customer.streetAddress"
          v-validate="'required'"
          :hint="$t('customerForm.street')"
          :is-valid="!errors.has('customerForm.streetAddress')"
          name="customerForm.streetAddress"
          :required="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <forminput
          v-model="customer.zipCode"
          v-validate="'required|regex:^[0-9]{5}$'"
          :hint="$t('customerForm.zipCode')"
          :is-valid="!errors.has('customerForm.zipCode')"
          name="customerForm.zipCode"
          :required="true"
        />
      </div>
      <div class="col-12 col-sm-8">
        <forminput
          v-model="customer.city"
          v-validate="'required'"
          :hint="$t('customerForm.city')"
          :is-valid="!errors.has('customerForm.city')"
          name="customerForm.city"
          :required="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <forminput
          v-model="customer.homePhone"
          v-validate="'required'"
          :hint="$t('customerForm.homePhone')"
          :is-valid="!errors.has('customerForm.homePhone')"
          name="customerForm.homePhone"
          :required="true"
        />
      </div>
      <div class="col-sm-6">
        <forminput
          v-model="customer.gsmPhone"
          :hint="$t('customerForm.gSMPhone')"
          :is-valid="true"
          name="customerForm.gsmPhone"
        />
      </div>
    </div>
    <div class="row">
      <!-- Todo - Move this out to standard/net as its required/not required in each. Or pass in if its net or standard to know whether to make required or not -->
      <!-- <div class="col-sm-6">
        <forminput
          v-model="customer.email"
          :hint="$t('customerForm.email')"
          :is-valid="true"
        />
      </div> -->
      <div class="col-sm-6">
        <div class="datepicker">
          <simpledate
            v-validate="birthdateValidation"
            :value="birthDateDate"
            :title="$t('customerForm.birthDate')"
            :required="true"
            name="customerForm.birthDate"
            :is-valid="!errors.has('customerForm.birthDate')"
            @input="onBirthDateSelected"
          />
        </div>
      </div>
    </div>
    <!-- languages -->
    <div class="row">
      <!-- native language-->
      <div class="col-12 col-sm-6">
        <dropdowninput
          v-model="customer.nativeLanguage"
          :title="$t('customerForm.nativeLanguage')"
          :items="nativeLanguageOptions"
          :is-valid="true"
        />
      </div>
      <!-- spoken languages -->
      <div class="col-12">
        <span class="cap-label brand-red px-3 mb-2 d-inline-block">
          {{ $t('nonIdentProfileForm.languageSpoken') }}
        </span>
        <objectcheckboxlist
          :value="customerLanguages"
          :list="customerLanguageOptions"
          :rows="2"
          @input="onCustomerLanguagesChanged"
        />
      </div>
      <!-- other languages freetext -->
      <div class="col-12">
        <forminput
          v-model="customer.otherLanguages"
          :hint="$t('customerForm.otherLanguages')"
          name="customerForm.otherLanguages"
          :is-valid="true"
        />
      </div>
    </div>

    <!-- customer type section-->
    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('customerForm.customerType') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxbitmasklist
          v-model="customer.customerTypes"
          :list="useCustomerTypes"
          :rows="2"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput
          v-model="customer.customerTypeOtherInfo"
          :hint="$t('customerForm.customerTypeOtherInfo')"
          :is-valid="true"
        />
      </div>
    </div>

    <!-- customer interests section-->
    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('customerForm.customerInterests') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxbitmasklist
          v-model="customer.interests"
          :list="customerInterests"
          :rows="3"
        />
      </div>
      <div class="col-12">
        <forminput
          v-model="customer.interestOther"
          :hint="$t('customerForm.customerInterestsFreeText')"
          name="customerForm.interestFreeText"
          :is-valid="true"
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h3>{{ $t('customerForm.friendWish') }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <checkboxinput
          v-model="customer.multipleVolunteers"
          :title="$t('customerForm.multipleVolunteers')"
          :aria-label="$t('customerForm.multipleVolunteers')"
        />
      </div>
    </div>

    <div class="row">
      <!-- gender -->
      <div class="col-12 col-md-6">
        <span class="subheader">{{ $t('volunteerForm.genderPreference') }}*</span>
        <checkboxbitmasklist v-model="customer.preferredGenders"
                             v-validate="'option-selected'"
                             :list="genders"
                             :rows="1"
                             name="preferredGenderRequired"
                             :is-valid="!errors.has('preferredGenderRequired')"
        />
        <span v-if="errors.has('preferredGenderNotSelected')" class="error-message">{{ $t('validation.preferredGenderRequired') }}</span>
        <span v-else class="error-message">&nbsp;</span>
      </div>
      <!-- Desired age -->
      <div class="col-12 col-md-6">
        <span class="subheader">{{ $t('volunteerForm.desiredAge') }}*</span>
        <checkboxbitmasklist v-model="customer.preferredAges"
                             v-validate="'option-selected'"
                             :list="preferredAge"
                             :rows="1"
                             name="ageGroupRequired"
                             :is-valid="!errors.has('ageGroupRequired')"
        />
        <span v-if="errors.has('ageGroupNotSelected')" class="error-message">{{ $t('validation.ageGroupNotSelected') }}</span>
        <span v-else class="error-message">&nbsp;</span>
      </div>
    </div>

    <div v-if="!isNet" class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('customerForm.availabilityTimes') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxbitmasklist
          v-if="!isNet"
          v-model="customer.availabilityTimes"
          :list="availabilityTimes"
          :rows="3"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <sprdropdownnumberkey v-model="customer.availabilityFrequency"
                              :title="$t('customerForm.availabilityFrequency')"
                              :items="availabilityFrequency"
                              :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <textareainput
          v-model="customer.description"
          :title="$t('customerForm.description')" :rows="3"
        />
      </div>
    </div>

    <!-- contact section-->
    <div class="row contact-info">
      <div class="col-12">
        <h3>{{ $t('customerForm.contactInformation') }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <forminput
          v-model="customer.contactFirstName"
          :hint="$t('customerForm.contactFirstname')"
          :is-valid="true"
          name="customerForm.contactFirstName"
        />
      </div>
      <div class="col-sm-6">
        <forminput
          v-model="customer.contactLastName"
          :hint="$t('customerForm.contactLastname')"
          :is-valid="true"
          name="customerForm.contactLastName"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <forminput
          v-model="customer.contactStreetAddress"
          :hint="$t('customerForm.contactStreet')"
          :is-valid="true"
          name="customerForm.contactStreetAddress"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-4">
        <forminput
          v-model="customer.contactZipCode"
          :hint="$t('customerForm.contactZipCode')"
          :is-valid="true"
          name="customerForm.contactZipCode"
        />
      </div>
      <div class="col-12 col-sm-8">
        <forminput
          v-model="customer.contactCity"
          :hint="$t('customerForm.contactCity')"
          :is-valid="true"
          name="customerForm.contactCity"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <forminput
          v-model="customer.contactPhonenumber"
          :hint="$t('customerForm.contactPhone')"
          :is-valid="true"
          name="customerForm.contactPhoneNumber"
        />
      </div>
      <div class="col-sm-6">
        <forminput
          v-model="customer.contactEmail"
          :hint="$t('customerForm.contactEmail')"
          :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <forminput
          v-model="customer.contactRelation"
          :hint="$t('customerForm.contactRelation')"
          :is-valid="true"
          name="customerForm.contactRelation"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <textareainput
          v-model="customer.otherContact"
          :title="$t('customerForm.otherContact')"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <forminput
          v-model="customer.shortDescription"
          v-validate="{max:100}"
          :hint="$t('customerForm.shortDescription')"
          name="customerForm.shortDescription"
          :is-valid="!errors.has('customerForm.shortDescription')"
          :maxlength="100"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import moment from 'moment'
  import forminput from '../SPRSimpleText.vue'
  import sprdropdownnumberkey from '../SPRDropdownNumberKey.vue'
  import dropdowninput from '../SPRSimpleDropdown.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import objectcheckboxlist from '../SPRObjectCheckboxList.vue'
  import simpledate from '../SPRSimpleDatepicker.vue'
  import checkboxbitmasklist from '../SPRSimpleCheckboxBitmaskList.vue'
  import enums from '../../mixins/enums.js'
  import scroll from '../../mixins/scroll.js'
  import locationutils from '../../mixins/locationutils.js'
  import * as _ from 'lodash'
  import { FriendCustomerStandardDto, FriendCustomerNetDto, LanguageDto } from '../../types/index'
  import { SprDropDownValue, SprDropDownNumberValue, SprCheckboxBitmaskValue, SprCheckboxObjectItem } from '../../types/custom'

  export default Vue.extend({
    name: 'CustomerCommon',
    components: {
      forminput,
      sprdropdownnumberkey,
      dropdowninput,
      checkboxinput,
      textareainput,
      objectcheckboxlist,
      checkboxbitmasklist,
      simpledate
    },
    mixins: [enums, scroll, locationutils],
    inject: ['$validator'],
    props: {
      customer: {
        type: Object as () => FriendCustomerStandardDto | FriendCustomerNetDto,
        required: true
      },
      isNet: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      birthDateDate (): Date {
        return new Date(this.customer.birthdate)
      },
      birthdateValidation (): string {
        const date = new Date()
        const nowDay = date.getDate()
        const nowDayString = nowDay < 10 ? '0' + nowDay : nowDay.toString()
        const nowMonth = date.getMonth() + 1
        const nowMonthString = nowMonth < 10 ? '0' + nowMonth : nowMonth.toString()
        const nowYear = date.getUTCFullYear()
        const currentDateAsString = `${nowDayString}.${nowMonthString}.${nowYear}`

        return `required|dateisbefore:${currentDateAsString}`
      },
      useCustomerTypes (): Array<SprCheckboxBitmaskValue> {
        if (this.isNet) {
          return this.netCustomerTypes
        }
        return this.customerTypes
      },
      availabilityTimes (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.availabilityTimes.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('availabilityTimes.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      customerTypes (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.customerTypes.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('customerTypes.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      netCustomerTypes (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.netCustomerTypes.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('netCustomerTypes.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      customerInterests (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.customerInterests.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('customerInterests.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      genders (): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.genders, function (val, key) {
          return { value: val.id, text: self.$t('genders.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      preferredAge (this: any): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.preferredAge.filter(x => x.id !== 0), function (val, key) {
          return { value: val.id, text: self.$t('preferredAge.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      availabilityFrequency (): Array<SprDropDownNumberValue> {
        var self = this
        return _.map(this.$store.state.availabilityFrequency.filter(x => x.id !== 0), function (val, key) {
          return { value: val.id, text: self.$t('availabilityFrequency.' + val.text) } as SprDropDownNumberValue
        })
      },
      nativeLanguageOptions (): Array<SprDropDownValue> {
        return _.map(this.$store.state.customerLanguages, (val: LanguageDto, key) => {
          return { value: val.isoCode, text: this.$t('languages.' + val.isoCode) } as SprDropDownValue
        })
      },
      customerLanguages (): Array<SprCheckboxObjectItem> {
        return _.map(this.customer.customerLanguages, (val: LanguageDto) => {
          return { obj: val, key: val.id, text: this.$t('languages.' + val.isoCode) } as SprCheckboxObjectItem
        })
      },
      customerLanguageOptions (): Array<SprCheckboxObjectItem> {
        return _.map(this.$store.state.customerLanguages, (val: LanguageDto) => {
          return { obj: val, key: val.id, text: this.$t('languages.' + val.isoCode) } as SprCheckboxObjectItem
        })
      }
    },
    methods: {
      onBirthDateSelected (selectedDate: string): void {
        if (this.customer) {
          // @ts-ignore eslint-disable-next-line
          this.customer.birthdate = moment(selectedDate, ['YYYY-MM-DD']).format('YYYY-MM-DD') // new Date(newVal)
        }
      },
      onCustomerLanguagesChanged (val: Array<SprCheckboxObjectItem>): void {
        this.customer.customerLanguages = _.map(val, (x: SprCheckboxObjectItem) => {
          return x.obj as LanguageDto
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .customercommon {
    background-color:$brand-white;

    .subheader {
      color:$brand-red;
      text-transform: uppercase;
      font-family: $font-bold;
      margin: 0 0 10px 20px;
      display:block;
      font-size: 14px;
    }

    .sectionheader {
      margin: 15px 0;
    }
  }
</style>
