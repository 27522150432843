<template>
  <div class="backbutton">
    <SprButton
      :size="4"
      :title="$t('customerForm.previousButton')"
      preicon="chevron-left"
      click-event="back"
      class="back"
      @back="onBackClicked"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import SprButton from './SPRButton.vue'

  export default Vue.extend({
    name: 'BackButton',
    components: { SprButton },
    props: {
      // If this is supplied, it will go there. Otherwise browser back.
      backUrl: {
        type: String,
        required: false,
        default: ''
      }
    },
    methods: {
      onBackClicked (): void {
        var useBackUrl = this.backUrl
        if (useBackUrl) {
          this.$router.push(this.backUrl)
        } else {
          this.$router.back()
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .backbutton {
    display: inline-block;
    .back {
      padding: 3px 6px;
      font-size: 13px;
    }
  }
</style>