<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .spr-multiselect {
    .selecteditems {
      margin-top:-25px;

      .pill {
        background-color:$brand-white;
        border: solid 2px $brand-red;
        padding:5px 10px;
        border-radius:20px;
        position: relative;
        display: inline-block;
        color:$brand-red;
        margin-top: 5px;
        font-family: $font-light;
        font-weight: bold;
        font-size:12px;

        .lbl {
          margin-right:10px;
        }

        svg {
          top:14px;
          right:8px;
          display:inline-block;
          position: absolute;
          margin-top:-10px;
          color:$brand-red;
          cursor:pointer;
        }
        &.disabled {
          color:$brand-grey3;
          border: solid 2px $brand-grey3;
          .lbl {
            margin-right: 0;
          }
        }
      }
    }
  }

</style>

<template>
  <div class="spr-multiselect">
    <sprdropdown :value="selectedItem"
                 :title="title"
                 :items="filteredItems"
                 :placeholder="placeholder"
                 :is-valid="true"
                 :disabled="filteredItems.length == 0"
                 @input="onSelect"
    />
    <div class="selecteditems mt-3">
      <ul class="list-inline">
        <li v-for="item in selectedItems" :key="item.value" class="list-inline-item" :value="item.value">
          <span class="pill" :class="{'disabled': item.disabled}">
            <span class="lbl">{{ item.text }}</span>
            <font-awesome-icon v-if="!item.disabled" icon="times" @click="removeItem(item)" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import sprdropdown from './SPRSimpleDropdown.vue'
  export default {
    name: 'Sprmultiselect',
    components: { sprdropdown },
    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: Array,
        default: () => []
      },
      items: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        selectedItem: null,
        selectedItems: []
      }
    },
    computed: {
      // only display items that have not already been selected
      filteredItems: function () {
        const self = this
        return _.filter(this.items, function (item) {
          return !_.includes(self.value, item.value) && !item.disabled
        })
      }
    },
    watch: {
      value: function (val) {
        var arr = []
        this.value.forEach(item => {
          var res = _.find(this.items, function (x) {
            return x.value === item
          })
          if (res) {
            arr.push(res)
          }
        })
        this.selectedItems = arr
      }
    },
    methods: {
      removeItem (item) {
        var index = (_.indexOf(this.selectedItems, item))
        this.$delete(this.selectedItems, index)
        this.$emit('input', this.selectedItems)
        this.selectedItem = null // reset to default
      },
      onSelect (item) {
        if (!item) return // stop looping when reset
        var found = (_.find(this.selectedItems, function (o) {
          return o.value === item
        }))
        if (found === undefined) {
          // find the item from the list of items
          var chosenItem = _.find(this.items, function (i) {
            return i.value === item
          })
          this.$set(this.selectedItems, this.selectedItems.length, chosenItem)
          this.$emit('input', this.selectedItems)
        }
        this.selectedItem = null // reset to default
      }
    }
  }
</script>
