<template>
  <div class="matchfilter">
    <div class="row">
      <div class="col-12">
        <h4>{{ $t('customerSearch.filterTitle') }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <filteritems
          v-model="filter"
          :customer-types="customerTypes"
          :languages="languageFilters"
          :ages="ages"
          @input="filterChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import filteritems from './FilterItems'
  export default {
    name: 'MatchFilter',
    components: { filteritems },
    props: {
      value: {
        type: Object,
        required: true
      },
      isNet: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        filter: {
          customerTypes: [],
          languages: [],
          motherTongs: [],
          ages: []
        }
      }
    },
    computed: {
      customerTypes () {
        var self = this
        if (this.isNet) {
          return _.map(this.$store.state.netCustomerTypes.filter(x => x.id !== 0), function (val, key) {
            return { id: val.id, name: self.$t('netCustomerTypes.' + val.text) }
          })
        } else {
          return _.map(this.$store.state.customerTypes.filter(x => x.id !== 0), function (val, key) {
            return { id: val.id, name: self.$t('customerTypes.' + val.text) }
          })
        }
      },
      languageFilters () {
        const self = this
        return _.map(this.$store.state.customerLanguages, function (val, key) {
          return { id: val.isoCode, name: self.$t('languages.' + val.isoCode) }
        })
      },
      ages () {
        const self = this
        return _.map(this.$store.state.preferredAge.filter(x => x.id !== 0), function (val, key) {
          return { id: val.id, name: self.$t('preferredAge.' + val.text) }
        })
      }
    },
    watch: {
      value (newVal) {
        this.filter = newVal
      }
    },
    mounted () {
      this.filter = this.value
    },
    methods: {
      filterChanged (val) {
        this.filter = val
        this.$emit('filterchanged', val)
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .matchfilter {
    @media screen and (max-width: $media-md-max) {
      h4 {
        font-size:1.5em !important;
      }
    }
  }

</style>
