<template>
  <div class="eventlist col-12">
    <backbar :title="$t('eventPage.eventListTitle')" />
    <div class="container">
      <div class="event-search">
        <div class="col-12">
          <div class="search-section">
            <div class="advanced-search">
              <eventsearchcriteria :is-public="isPublic" :search-values="searchCriteria" @change="onChangedSearchCriteria" />
            </div>
          </div>
        </div>
      </div>
      <div class="row event-list">
        <div v-show="isLoading" class="col-12 loading text-center">
          <div class="spinner">
            <loading />
          </div>
        </div>
        <div v-for="evt in sprEvents" v-show="!isLoading" :key="evt.id" class="col-sm-12 col-md-6 col-lg-4">
          <eventtile :sprevent="evt" />
        </div>
      </div>
      <div v-show="showPager" class="row pager">
        <div class="col-12">
          <pager
            :skip="skip"
            :take="take"
            :total="total"
            :max-display="5"
            @pagerchanged="onPagerChanged"
            @pagerprevious="onPagerPrevious"
            @pagernext="onPagerNext"
          />
        </div>
      </div>
      <div v-if="!hasEvents && !isLoading" class="row no-events text-center">
        <div class="col-12">
          <span>{{ $t('eventPage.noEventsFound') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import backbar from '../BackBar.vue'
  import * as _ from 'lodash'
  import pager from '../SPRPager.vue'
  import loading from '../SPRLoading.vue'
  import eventtile from './EventTile.vue'
  import eventsearchcriteria from './EventSearchCriteria.vue'
  import { AdvancedEventSearchCriteria, IsOnlineEventFilterType, EventTypes, EventCategory, EventOrganizerCriteria, Languages } from '@/types'
  import { EventOrganizerIdentifier } from '@/types/custom'
  import { isEventOrganizerIdentifier } from '@/types/typevalidators'
  import datehelper from '@/helpers/datehelper'

  export interface TemplateComponentData {
    sprEvents: any[],
    searchValues: Record<string, any>,
    searchCriteria: AdvancedEventSearchCriteria,
    locale: string,
    isPublic: boolean,
    isLoading: boolean,
    isSearching: boolean,
    searchTimer: ReturnType<typeof setTimeout> | null,
    searchWaitIntervalMs: number,
    total: number,
    skip: number,
    take: number
  }

  export default Vue.extend({
    name: 'EventList',
    components: {
      backbar,
      eventsearchcriteria,
      eventtile,
      pager,
      loading
    },
    data () {
      return {
        sprEvents: [],
        searchValues: {},
        searchCriteria: {
          eventCategory: undefined as EventCategory | undefined,
          eventOrganizer: {} as EventOrganizerIdentifier,
          eventType: undefined as EventTypes | undefined,
          isOnline: undefined as IsOnlineEventFilterType | undefined,
          isPublic: undefined as boolean | undefined,
          languages: [] as Languages[],
          locale: '' as string,
          searchWord: '' as string,
          searchWordDelimiters: [] as string[],
          startDate: null as Date | null,
          endDate: null as Date | null,
          skip: 0 as number,
          take: 12 as number
        } as AdvancedEventSearchCriteria,
        locale: '',
        isPublic: false,
        isLoading: false,
        isSearching: false,
        searchTimer: null,
        searchWaitIntervalMs: 600,
        total: 0,
        skip: 0,
        take: 12
      }
    },
    computed: {
      hasEvents (): boolean {
        return this.sprEvents.length > 0
      },
      showPager (): boolean {
        return this.sprEvents.length > 0 && this.total > 0 && this.total > this.take
      }
    },
    mounted () {
      this.isLoading = true
      this.isPublic = this.$router.currentRoute.path.toLowerCase().indexOf('publicevents') > -1

      this.handleQueryString()
    },
    methods: {
      handleQueryString () {
        const searchParams = new URLSearchParams(window.location.search)

        if (searchParams.has('locale')) {
          this.$set(this.searchCriteria, 'locale', searchParams.get('locale') || '')
        }

        if (searchParams.has('searchWord')) {
          this.$set(this.searchCriteria, 'searchWord', searchParams.get('searchWord') || '')
        }

        this.$set(this.searchCriteria, 'eventCategory', undefined)
        if (searchParams.has('eventCategory')) {
          const eventCategoryNumber = Number(searchParams.get('eventCategory'))
          if (!isNaN(eventCategoryNumber)) {
            this.$set(this.searchCriteria, 'eventCategory', eventCategoryNumber)
          }
        }

        this.$set(this.searchCriteria, 'eventType', undefined)
        if (searchParams.has('eventType')) {
          const eventTypeNumber = Number(searchParams.get('eventType'))
          if (!isNaN(eventTypeNumber)) {
            this.$set(this.searchCriteria, 'eventType', eventTypeNumber)
          }
        }

        this.$set(this.searchCriteria, 'isOnline', undefined)
        if (searchParams.has('isOnline')) {
          const isOnlineFilterTypeNumber = JSON.parse(searchParams.get('isOnline') || '')
          if (typeof isOnlineFilterTypeNumber === 'boolean') {
            this.$set(this.searchCriteria, 'isOnline', isOnlineFilterTypeNumber ? 1 : 0)
          }
        }

        this.$set(this.searchCriteria, 'languages', [])
        if (searchParams.has('languages')) {
          const selectedLanguages = searchParams.get('languages')?.split(',').map(l => l.trim())
          if (selectedLanguages !== null && selectedLanguages !== undefined && selectedLanguages.length > 0 && selectedLanguages[0] !== '') {
            this.$set(this.searchCriteria, 'languages', selectedLanguages.map(l => Number(l)))
          }
        }

        this.$set(this.searchCriteria, 'eventOrganizer', {})
        if (searchParams.has('eventOrganizerType') && searchParams.has('organizerId')) {
          const eventOrganizerType = Number(searchParams.get('eventOrganizerType'))
          const organizerId = Number(searchParams.get('organizerId'))
          if (!isNaN(eventOrganizerType) && !isNaN(organizerId)) {
            this.$set(this.searchCriteria, 'eventOrganizer', { eventOrganizerType: eventOrganizerType, organizerId: organizerId })
          }
        }
        this.$set(this.searchCriteria, 'skip', 0)
        if (searchParams.has('skip')) {
          const skip = JSON.parse(searchParams.get('skip') || '')
          if (typeof skip === 'number') {
            this.$set(this.searchCriteria, 'skip', skip)
            this.skip = skip
          }
        }

        this.$set(this.searchCriteria, 'take', 12)
        if (searchParams.has('take')) {
          const take = JSON.parse(searchParams.get('take') || '')
          if (typeof take === 'number') {
            this.$set(this.searchCriteria, 'take', take)
            this.take = take
          }
        }
      },
      updateQueryString () {
        const searchParams = new URLSearchParams()

        // Helper function to determine if a value should be included in the query string
        const shouldIncludeValue = (prop, value) => {
          if (value === null || value === undefined || value === '') {
            return false
          }

          if (Array.isArray(value) && value.length === 0) {
            return false
          }

          if (prop === 'eventOrganizer' && !isEventOrganizerIdentifier(value)) {
            return false
          }

          return true
        }

        // Iterate over each property of the searchCriteria object
        for (const prop in this.searchCriteria) {
          if (Object.prototype.hasOwnProperty.call(this.searchCriteria, prop)) {
            const value = this.searchCriteria[prop]
            if (shouldIncludeValue(prop, value)) {
              if (isEventOrganizerIdentifier(value)) {
                searchParams.set('eventOrganizerType', value.eventOrganizerType.toString())
                searchParams.set('organizerId', value.organizerId.toString())
              } else if (Array.isArray(value)) {
                searchParams.set(prop, value.join(','))
              } else if (prop.toLowerCase() === 'isOnline'.toLowerCase()) {
                if (value !== undefined) {
                  searchParams.set(prop, value === 1 ? 'true' : 'false')
                }
              } else {
                searchParams.set(prop, String(value))
              }
            }
          }
        }
        const query = searchParams.toString()

        // Replace the current URL with the updated query string
        const newUrl = `${window.location.pathname}?${query}`
        window.history.replaceState(null, '', newUrl)
      },
      onChangedSearchCriteria (searchCriteria) {
        // Check if searchCriteria contains a date field and if it's a string
        if (searchCriteria.startDate && typeof searchCriteria.startDate === 'string') {
          // Convert the date string back to a Date object
          searchCriteria.startDate = datehelper.parseDateFromString(searchCriteria.startDate)
        }
        // Check if searchCriteria contains a date field and if it's a string
        if (searchCriteria.endDate && typeof searchCriteria.endDate === 'string') {
          searchCriteria.endDate = datehelper.parseDateFromString(searchCriteria.endDate)
        }

        if (JSON.stringify(this.searchCriteria) !== JSON.stringify(searchCriteria)) {
          this.searchCriteria = { ...this.searchCriteria, ...searchCriteria }
          this.skip = 0
          this.searchCriteria.skip = 0
          this.updateQueryString()
          this.getEvents()
        }
      },
      getEvents () {
        this.isLoading = true
        let url = ''
        if (this.isPublic) {
          url = this.$store.state.apiBases.eventservice + this.$store.state.apiEndpoints.eventsbycriteriapublic[0]
        } else {
          url = this.$store.state.apiBases.eventservice + this.$store.state.apiEndpoints.eventsbycriteria[0]
        }
        this.searchCriteria.startDate = datehelper.toUtcWithoutTime(this.searchCriteria.startDate)
        this.searchCriteria.endDate = datehelper.toUtcWithoutTime(this.searchCriteria.endDate)
        const self = this
        this.$http.post(url, this.searchCriteria).then(response => {
          self.sprEvents = response.data.items
          self.total = response.data.total
          self.isLoading = false
        }, response => {
          console.log('Error loading', response)
        })
      },
      onPageSizeChanged (pageSize) {
        this.take = pageSize
        this.updatePage()
      },
      onPagerChanged (value) {
        this.skip = value.skip
        this.updatePage()
      },
      onPagerPrevious () {
        this.skip -= this.take
        if (this.skip < 0) this.skip = 0
        this.updatePage()
      },
      onPagerNext () {
        if (this.skip + this.take > this.total) return
        this.skip += this.take
        this.updatePage()
      },
      updatePage () {
        this.searchCriteria.take = this.take
        this.searchCriteria.skip = this.skip
        this.updateQueryString()
        this.getEvents()
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";
  .eventlist {

    .event-search {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1140px;
      padding-right: 15px;
      padding-left: 15px;
      margin-top:30px;
      margin-right: auto;
      margin-bottom:10px;
      margin-left: auto;

      label {
        color:$brand-red;
        padding:0 20px;
      }

      .spr-searchbox {
        &.spr-roundbox-1 {
          border: none;
          input.form-control {
            border: solid 2px $brand-grey3;
            &:focus {
              box-shadow: 0 0 0 0.2rem $brand-grey3;
            }
          }
        }
      }

      .spr-simple-dropdown {
        .form-group {
          .spr-roundbox-1 {
            border: none;
            select.form-control {
              -webkit-appearance: none;
              -moz-appearance: none;
              -o-appearance: none;
              appearance: none;
              background-image: url('../../assets/img/caret.png');
              background-position: 95% center;
              background-repeat: no-repeat;
              outline: none;
              border: solid 2px $brand-grey3;
              &:focus {
                box-shadow: 0 0 0 0.2rem $brand-grey3;
              }
            }
          }
        }

        padding-bottom: 0 !important;
      }

      .event-organizers-dropdown {
        .simpletext {
          input {
            border: solid 2px $brand-grey5;
          }
        }
      }
    }

    .no-events {
      margin-top:20px;
      margin-bottom: 20px;
    }

    .spinner {
      margin: auto;
      color: $brand-red;
    }
  }

</style>
