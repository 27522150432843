<template>
  <b-form-select v-model="selected">
    <template slot="first">
      <option :value="null" disabled>
        {{ $t('wizard.otherLanguages') }}
      </option>
    </template>
    <option v-for="(item, index) in languages"
            :key="index"
            :value="item.isoCode"
    >
      {{ item.displayName }}
    </option>
  </b-form-select>
</template>

<script>
  export default {
    name: 'SprLanguageSelector',
    props: {
      selectEvent: {
        type: String,
        default: ''
      },
      customElements: {
        type: Array,
        default: null
      }
    },
    data () {
      return {
        selected: null
      }
    },
    computed: {
      languages: function () {
        return this.customElements ? this.customElements : this.$store.state.allLanguages
      }
    },
    watch: {
      selected: function (val, old) {
        if (val && this.selectEvent) {
          this.$emit(this.selectEvent, val, true)
          this.selected = null
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .custom-select:focus {
      border: solid 2px $brand-grey5;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
  }

</style>
