<template>
  <div class="spr-edit-tool">
    <sprbutton
      v-if="!localIsEditing"
      click-event="edit"
      :title="$t('userAdmin.editUser')"
      :primary="true"
      :size="5"
      :aria-label="$t('userAdmin.editUser')"
      preicon="pencil-alt"
      @edit="toggleEdit"
    />
    <sprbutton
      v-else
      click-event="revert"
      :title="$t('userAdmin.editCancel')"
      :primary="false"
      :size="5"
      :aria-label="$t('userAdmin.editCancel')"
      preicon="times"
      @revert="revertChanges"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprbutton from './SPRButton.vue'
  // the data object
  export interface TemplateComponentData {
      localIsEditing: boolean
  }

  export default Vue.extend({
    name: 'SPREditTool',
    components: {
      sprbutton
    },
    props: {
      isEditing: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        localIsEditing: false
      }
    },
    watch: {
      isEditing (newVal: boolean) {
        this.localIsEditing = newVal
      }
    },
    mounted (): void {
      this.localIsEditing = this.isEditing
    },
    methods: {
      toggleEdit (): void {
        this.localIsEditing = !this.localIsEditing
        this.$emit('editing', this.localIsEditing)
      },
      revertChanges (): void {
        this.localIsEditing = false
        this.$emit('revert')
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
    .spr-edit-tool {
        display: inline-block;
        button {
          border-color: $brand-red;
          outline: none;
        }
    }
</style>