<template>
  <div class="comp-wizard-join">
    <div :is="steps[currentStep]"
         ref="currentStage"
         class="swapped-elements"
         :value="formInfo"
         :memberships="membershipTypes"
         :flow="flow"
    />
    <div class="common-elements">
      <sprbutton v-if="currentStep < steps.length - 1"
                 :size="1"
                 :title="$t('wizard.next')"
                 click-event="wizard-next"
                 class="next-button"
                 icon="chevron-right"
                 @wizard-next="onNext"
      /><br>

      <button tabindex="0"
              class="back-link"
              role="link"
              @click="onBack"
      >
        {{ $t('wizard.back') }}
      </button>
      <div class="step-container">
        <font-awesome-icon v-for="(step, index) in steps"
                           :key="index"
                           icon="circle"
                           class="step-indicator"
                           :class="{ 'current-step': index === currentStep, 'filled-step': index < currentStep }"
                           aria-hidden="true"
                           @click="stepIndicatorClick(index)"
        />
      </div>
      <a class="privacy-link link" href="https://www.punainenristi.fi/tietosuoja" target="_blank">{{ $t('wizard.privacyPolicy') }}</a>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import basic from './JoinPersonal.vue'
  import section from './JoinSection.vue'
  import contact from './JoinContact.vue'
  import payment from './JoinPayment.vue'
  import locationutils from '../../mixins/locationutils.js'

  export default {
    name: 'Join',
    components: { sprbutton, contact },
    mixins: [locationutils],
    props: {
      page: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        formInfo: {
          personal: {
            firstName: '',
            lastName: '',
            age: '',
            nativeLanguage: 'fi'
          },
          address: {
            street: '',
            zipCode: '',
            city: '',
            email: '',
            phone: ''
          },
          section: '',
          marketingEmail: false,
          marketingSms: false,
          omaId: null
        },
        steps: [basic, contact, section, payment],
        currentStep: 0,
        // deep watcher does not work with child props proper, need to cache stuff
        lastHandledZip: '',
        lastHandledCityZip: '',
        lastHandledLanguage: '',
        membershipTypes: []
      }
    },
    computed: {
      productListUrl: function () {
        return `${this.$store.state.apiBases.paymentservice + this.$store.state.apiEndpoints.productlist[0]}`
      },
      flow: function () {
        const source = this.$route.query.source
        return (source || 'default') + '|' + btoa(this.formInfo.address.email)
      }
    },
    watch: {
      formInfo: {
        deep: true,
        handler: function (x) {
          if (!x.gdpr) {
            x.gdpr = null // v-validate does not handle checkboxes well
          }

          const zip = x.address.zipCode
          if (zip.length === 5 && (zip !== this.lastHandledZip || x.personal.nativeLanguage !== this.lastHandledLanguage)) {
            this.handleZipChange(zip)
          }

          if (zip.length === 5 && this.lastHandledCityZip !== zip) {
            var self = this
            this.cityFromZip(zip, this, response => {
              self.formInfo.address.city = self.$store.state.language === 'sv' ? response.body.citySe : response.body.cityFi
            })
            this.lastHandledCityZip = zip
          }
        }
      },
      currentStep: function (val) {
        this.$router.replace({ name: this.$route.name, params: { page: (val + 1).toString() } }).catch(() => {})
      }
    },
    mounted: function () {
      this.getProductList()
      const source = this.$route.query.source

      if (source === 'register') {
        // load data from vuex: register form info
        this.loadFromWizard()
      }

      if (source === 'profile') {
        // load data from vuex: profile info
        this.loadFromProfile()
      }

      if (!this.page || this.page !== '1') {
        if (this.$route.path.indexOf('/bli-medlem') >= 0) {
          this.$router.replace('/bli-medlem/1').catch(() => {})
        } else {
          this.$router.replace('/liity-jaseneksi/1').catch(() => {})
        }
      }
    },
    methods: {
      handleZipChange: function (zipCode) {
        const order = [this.formInfo.personal.nativeLanguage === 'sv' ? 'desc' : 'asc']
        const sections = _.orderBy(_.filter(this.$store.state.joinsections, function (x) {
          return x.zipCodes.indexOf(zipCode) >= 0
        }), ['language'], [order])

        this.formInfo.section = sections.length > 0 ? sections[0].name : ''
        this.lastHandledZip = zipCode
        this.lastHandledLanguage = this.formInfo.personal.nativeLanguage
      },
      getProductList: function () {
        const self = this
        this.httpPromise('get', this.productListUrl).then(data => {
          self.membershipTypes = data
        }).catch(fail => {
          console.log('Fetching products failed!', fail)
        })
      },
      onNext: function () {
        var self = this
        this.$refs.currentStage.$validator.validateAll().then(function (data) {
          if (data === true) {
            self.incrementStep()
          } else {
            window.scrollTo(0, 0)
          }
        })
      },
      onBack: function () {
        window.scrollTo(0, 0)
        this.currentStep = Math.max(this.currentStep - 1, 0)
      },
      incrementStep: function () {
        window.scrollTo(0, 0)
        if (this.currentStep >= this.steps.length - 1) {
          // this is the end
        } else {
          this.currentStep = (this.currentStep + 1) % this.steps.length
        }
      },
      stepIndicatorClick: function (index) {
        if (index < this.currentStep && index >= 0) {
          window.scrollTo(0, 0)
          this.currentStep = index
        }
      },
      handleError: function (error) {
        var rv = []
        if (error.body) {
          error.body.forEach(s => rv.push(['', this.lcFirst(s.code), '']))
        }

        this.$refs.currentStage.registerErrors = rv
      },
      loadFromWizard: function () {
        this.formInfo = {
          personal: {
            firstName: this.$store.state.wizardModel.firstName,
            lastName: this.$store.state.wizardModel.lastName,
            age: this.$store.state.wizardModel.age,
            nativeLanguage: this.$store.state.wizardModel.nativeLanguage
          },
          address: {
            street: this.$store.state.wizardModel.streetAddress,
            zipCode: this.$store.state.wizardModel.zipCode,
            city: this.$store.state.wizardModel.city,
            email: this.$store.state.wizardModel.email,
            phone: this.$store.state.wizardModel.phoneNumber,
            country: this.$store.state.wizardModel.country // filter this?
          },
          omaId: this.$store.state.registerId
        }
      },
      loadFromProfile: function () {
        this.formInfo = {
          personal: {
            firstName: this.$store.state.profile.firstName,
            lastName: this.$store.state.profile.lastName,
            age: this.$store.state.profile.age,
            nativeLanguage: this.$store.state.profile.nativeLanguage
          },
          address: {
            street: this.$store.state.profile.streetAddress,
            zipCode: this.$store.state.profile.zipCode,
            city: this.$store.state.profile.city,
            email: this.$store.state.profile.email,
            phone: this.$store.state.profile.phoneNumber,
            country: this.$store.state.profile.country // filter this?
          },
          omaId: this.$store.state.profile.profileId
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .comp-wizard-join {
    box-sizing: border-box;
    padding: 0 10px;
    width: 100%;

    min-height: calc(100vh - 200px);
    padding-bottom: 25px;

    background:$brand-back center center;
    background-repeat: no-repeat;
    background-size: contain cover;
    text-align: center;

    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;

    -ms-grid-columns: 1fr;
    grid-template-rows: auto 40px 180px;
    -ms-grid-rows: auto 40px 180px;

    a {
        color: white;
        text-decoration: underline;
    }

    .next-button {
      width: 250px;
    }

    .back-link {
      padding: 0;
      background: none;
      border: none;
      font-size: 15px;
      text-transform: uppercase;
      font-family: $font-bold;
      letter-spacing: 1.25px;
      color: white;
      margin: 25px 0 15px 0;
      display: inline-block;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .swapped-elements {
      grid-column: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    .common-elements {
      grid-column: 1;
      grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    .step-container {
      .step-indicator {
        margin: 2px;
        font-size: 12px;
        color: $brand-grey11;
        transition: color 0.5s;
      }
      .current-step {
        color: $brand-white;
        transition: color 1s;
      }
      .filled-step {
        color: $brand-grey4;
        cursor: pointer;
      }
    }

    .privacy-link {
      color: $brand-grey5;
      padding: 2px;
      margin: 18px;
      display: inline-block;
    }
  }

</style>
