<template>
  <div class="pagesize-wrapper">
    <div class="pagesize-title">
      {{ $t('listingPager.profilePageSize') }}:
    </div>
    <div class="pagesizecontainer">
      <div v-for="pageSize in pageSizes" :key="pageSize.value">
        <div v-if="isSelected(pageSize.value)" class="pagesize" :class="{'selected': isSelected(pageSize.value)}">
          <a href="javascript:void(0);" @click="pageSizeChanged(pageSize.value)">
            {{ pageSize.value }}
          </a>
        </div>
        <div v-else class="pagesize" :class="{'selected': pageSize.selected}">
          <a href="javascript:void(0);" @click="pageSizeChanged(pageSize.value)">
            {{ pageSize.value }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue'

  export default Vue.extend({
    name: 'PageSizeSelector',
    components: { },
    props: {
      take: {
        type: Number,
        default: 5
      }
    },
    data () {
      return {
        actualDisplayed: 0,
        pageSizes: [{ value: 5 }, { value: 20 }, { value: 50 }, { value: 100 }, { value: 150 }],
        currentPageSize: 5
      }
    },
    mounted () {
      if (this.pageSizes.find((p) => p.value === this.take) === undefined) { this.pageSizes.push({ value: this.take }) }
      this.pageSizes.sort((a, b) => a.value - b.value)
      this.currentPageSize = this.take
    },
    methods: {
      isSelected (value): boolean {
        return this.currentPageSize === value
      },
      pageSizeChanged (pageSize): void {
        this.currentPageSize = pageSize
        this.$emit('pagesizechanged', pageSize)
      },
      checkIsVisible (page): boolean {
        // check for view rights of this user.
        // The rights should be: superadmin, DA, J1 or J2 & employees
        return true
      }
    }
  })
</script>
<style lang="scss">
    @import "../assets/scss/_variables.scss";

    .pagesize-wrapper {
      display: flex;
      font-size: 14px;
    }
    .pagesizecontainer {
      display: flex;
      height: 30px;
      align-self: center;
      background: $brand-grey1;
      border-radius: 50px;
    }

    .pagesize-title{
      align-self: center;
      color: $brand-grey13;
      margin-right: 5px;
    }

    .pagesize {
      background-color: $brand-grey1;
      color: $brand-grey13;
      border: solid 2px $brand-grey1;
      border-radius: 50px;
      width: 45px;
      height: 30px;
      display: inline-block;

      &:hover {
        background-color:$brand-red;
        a {
          text-decoration: none;
          color:$brand-white;
        }
      }

      a {
        display:block;
        text-align:center;
        color:$brand-grey13;
        margin-top:2px;
      }

      &.selected {
        background-color:$brand-white;

        a {
          color: $brand-red;
          font-weight: 600;
        }
      }
    }

</style>