<template>
  <div class="row friendvolunteercommondetails">
    <div class="col-12">
      <h5>{{ $t('volunteerForm.personalInformation') }}</h5>
      <router-link class="edit" :to="'/friend/volunteer/edit/common'">
        <font-awesome-icon icon="pencil-alt" />
      </router-link>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <dl>
            <dt>{{ $t('volunteerForm.gender') }}</dt>
            <dd>{{ computedGender }}</dd>
          </dl>
        </div>
        <div class="col-12">
          <dl>
            <dt>{{ $t('volunteerForm.hasHealthcareTraining') }}</dt>
            <dd>{{ $t('boolean.' + volunteer.hasHealthCareTraining) }}</dd>
          </dl>
        </div>
        <div v-if="volunteer.healthCareTrainingFreetext" class="col-12">
          <dl>
            <dt>{{ $t('volunteerForm.healthCareTrainingFreetext') }}</dt>
            <dd>{{ volunteer.healthCareTrainingFreetext }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { FriendVolunteerDto } from '@/types/index'
  import enumhelper from '../../mixins/enums.js'

  export default Vue.extend({
    name: 'FriendVolunteerCommonDetails',
    mixins: [enumhelper],
    props: {
      volunteer: {
        type: Object as () => FriendVolunteerDto,
        required: true
      }
    },
    computed: {
      // all computed props should define return type, or you will get ts errors!
      computedGender (): string {
        // @ts-ignore can't find mixins
        return this.getGenderTranslation(this.volunteer.gender)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .friendvolunteercommondetails {
    background-color: $brand-white;
    padding-top: 15px;
    padding-bottom: 15px;
  }
</style>
