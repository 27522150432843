<template>
  <div class="customerpoolsstandard">
    <div v-if="!showPoolChooser" class="row">
      <div class="col-12 poolbuttons">
        <sprbutton :size="4"
                   :title="$t('customerSearch.addCustomerToPool')"
                   click-event="add"
                   @add="onAdd"
        />
      </div>
    </div>
    <div v-else class="row">
      <div class="col-12 poolbuttons">
        <sprbutton :size="4"
                   :title="$t('customerSearch.cancelAddToPool')"
                   click-event="cancelAdd"
                   @cancelAdd="onCancelAdd"
        />
      </div>
      <div class="col-12">
        <PoolChooserStandard
          :friend-management-group="group"
          :selected-pools="pools"
          @poolchosen="onPoolChosen"
        />
      </div>
    </div>
    <div class="row">
      <div v-if="pools && pools.length" class="col-12">
        <div class="row">
          <div class="col-12">
            <h5>{{ $t('customerSearch.existingPools') }}</h5>
          </div>
        </div>
        <poolview
          v-for="pool in pools"
          :key="pool.id"
          :pool="pool"
          :groupid="group.id"
          action-view="remove"
          @remove="onRemove"
        />
      </div>
      <div v-if="(!pools || !pools.length) && !showPoolChooser" class="col-12">
        <div class="text-center nopools">
          {{ $t('customerSearch.customerNoPools') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import poolview from './PoolView.vue'
  import PoolChooserStandard from './PoolChooserStandard.vue'
  import { FriendCustomerStandardDto, FriendManagementDto, FriendPoolFindStandardDto } from '@/types'

  // the data object
  export interface TemplateComponentData {
    showPoolChooser: boolean
  }

  export default Vue.extend({
    name: 'CustomerPoolsStandard',
    components: { sprbutton, poolview, PoolChooserStandard },
    props: {
      customer: {
        type: Object as () => FriendCustomerStandardDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      },
      isActive: {
        type: Boolean,
        default: true
      }
    },
    data (): TemplateComponentData {
      return {
        showPoolChooser: false
      }
    },
    computed: {
      pools (): Array<FriendPoolFindStandardDto> {
        return this.customer?.friendPools ?? []
      }
    },
    methods: {
      onPoolChosen (pool) {
        const url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.addcustomertopoolstandard[0].replace('{poolid}', pool.id).replace('{customerid}', this.customer.id)
        var self = this
        this.$http.post(url).then(resp => {
          this.showPoolChooser = false
          this.$emit('poolsupdated', pool)
        }, err => this.displayError(err))
      },
      onAdd () {
        this.showPoolChooser = true
      },
      onCancelAdd () {
        this.showPoolChooser = false
      },
      onRemove (pool) {
        const url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.removecustomerfrompoolstandard[0].replace('{poolid}', pool.id).replace('{customerid}', this.customer.id)
        var self = this
        this.$http.delete(url).then(resp => {
          this.$emit('poolsupdated', pool)
        }, err => this.displayError(err))
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .customerpoolsstandard {
    .poolbuttons {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
</style>