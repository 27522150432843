<template>
  <div class="comp-district-list">
    <div v-if="districts" class="listing">
      <div v-for="(d, i) in districts" :key="i" class="listing-row">
        <router-link :to="'/admin/district/' + d.id + '/section'">
          {{ d.name }}
        </router-link>
      </div>
    </div>
    <div v-else class="loading text-center brand-red">
      <div class="my-2">
        <loading />
      </div>
      <div class="my-3">
        <p>{{ $t('userAdmin.loading') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import loading from '../SPRLoading.vue'
  export default {
    name: 'AdminDistrictList',
    components: { loading },
    data: function () {
      return {
        districts: null
      }
    },
    mounted: function () {
      if (!this.hasAdmin() && !this.hasRole('NationalAdmin') && !this.hasRole('NationalEmployee')) {
        this.showAlertPermissionDenied().then(() => this.$router.push('/profile'))
      } else {
        this.$http.get(this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.districts[0]).then(success => {
          this.districts = success.body
        }, f => {
          this.showAlertError(this.$t('common.fetchFailed'))
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .comp-district-list {
    background: $brand-white;
    padding: 15px;

    .listing {
      display: flex;
      display: -ms-flexbox;
      flex-direction: column;
      -ms-flex-direction: column;

      .listing-row {
        padding: 10px 15px;
        color: $brand-red;

        &:nth-child(even) {
          background: rgba(0,0,0,0.03);
        }

        a {
          color: $brand-red;
        }
      }
    }
  }
</style>
