<template>
  <div class="pilllist">
    <div v-for="pill in value" :key="pill" :value="pill">
      <pill :value="pill" :selected="false" />
    </div>
  </div>
</template>

<script>
  import pill from './SPRPill.vue'

  export default {
    name: 'PillList',
    components: { pill },
    props: {
      value: {
        type: Array,
        default () {
          return []
        }
      }
    }
  }
</script>

<style lang="scss">
  .pilllist {
    > div {
      display:inline-block;
      margin-right: 10px;
    }
  }
</style>
