<template>
  <div class="volunteermatchresults">
    <div class="row search">
      <div class="col-12">
        <searchbox v-model="search" :placeholder="placeholder" />
      </div>
    </div>
    <div class="col">
      <div class="row results-list">
        <ul>
          <li v-for="item in filteredItems" :key="item.id">
            <volunteermatchview :match="item" @customerSelected="onCustomerSelected(item)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import volunteermatchview from './VolunteerMatchView.vue'
  import searchbox from '../SPRSearchBox.vue'
  import { MatchItemNetCustomerDto, MatchItemStandardCustomerDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    placeholder: string | LocaleMessage,
    search: string
  }

  export default Vue.extend({
    name: 'VolunteerMatchResults',
    components: { volunteermatchview, searchbox },
    props: {
      list: {
        type: Array as () => Array<MatchItemNetCustomerDto> | Array<MatchItemStandardCustomerDto>,
        default: () => []
      }
    },
    data (): TemplateComponentData {
      return {
        placeholder: this.$t('customerSearch.friendSearchPlaceholder'),
        search: ''
      }
    },
    computed: {
      filteredItems (): Array<MatchItemNetCustomerDto> | Array<MatchItemStandardCustomerDto> {
        if (this.search === '') {
          return this.list
        }

        var searchTerms = this.search.toLowerCase().split(/[^a-z0-9åäö]/)

        var textParts = _.filter(searchTerms, s => { return isNaN(Number(s)) })
        var zipParts = _.filter(searchTerms, s => { return !isNaN(Number(s)) })

        return _.filter(this.list, (item: MatchItemStandardCustomerDto | MatchItemNetCustomerDto) => {
          return (
            _.every(textParts, s => {
              return _.includes(item.match.firstName.toLowerCase() + ' ' + item.match.lastName.toLowerCase(), s)
            }) ||
            _.every(textParts, s => {
              return _.includes(item.match.shortDescription.toLowerCase(), s)
            })
          ) && (
            !zipParts.length || _.some(zipParts, z => {
              return _.startsWith(item.match.zipCode, z)
            })
          )
        }) as Array<MatchItemNetCustomerDto> | Array<MatchItemStandardCustomerDto>
      }
    },
    methods: {
      onCustomerSelected (item): void {
        this.$emit('customerSelected', item)
      }
    }
  })
</script>
<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .volunteermatchresults {
    ul {
      width: 100%;
      list-style-type: none;
      margin: 0;
      padding: 0;
        li {
          margin:0;
          padding:0;
        }
      }
    .search {
      margin-bottom: 15px;
    }
  }
</style>
