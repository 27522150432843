<template>
  <div class="objectcheckboxlist">
    <div class="row">
      <div v-for="item in list" :key="item.key" :class="calculatedClass" :value="item">
        <checkbox :value="item.key" :checked="isSelected(item.key)" :title="item.text" @changed="onChecked(item)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import checkbox from './SPRSimpleNumberCheckbox.vue'
  import { SprCheckboxObjectItem } from '../types/custom'
  export default Vue.extend({
    name: 'SprObjectCheckboxList',
    components: { checkbox },
    props: {
      list: {
        type: Array as () => Array<SprCheckboxObjectItem>,
        default () { return [] }
      },
      value: {
        type: Array as () => Array<SprCheckboxObjectItem>,
        default: []
      },
      rows: {
        type: Number,
        default () { return 1 }
      }
    },
    computed: {
      calculatedClass (): string {
        return 'col-12 col-sm-' + Math.floor(12 / this.rows)
      }
    },
    methods: {
      isSelected (key: number): boolean {
        return this.value.map(x => x.key).indexOf(key) > -1
      },
      onChecked (val: SprCheckboxObjectItem): void {
        var localVal = this.value
        var index = _.indexOf(this.value.map(x => x.key), val.key)
        // does this exist in the value already?
        if (index === -1) {
          //if it does not, add it and emit
          localVal.push(val)
        } else {
          // otherwise remove it
          localVal.splice(index, 1)
        }
        this.$emit('input', localVal)
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/scss/_variables.scss";

.objectcheckboxlist {

}
</style>
