<template>
  <div class="poolfilter">
    <div class="row">
      <div class="col-12">
        <h4>{{ $t('customerSearch.filterTitle') }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="cap-label">
          {{ $t('customerSearch.filterPoolTypeTitle') }}
        </div>
      </div>
    </div>
    <div class="row filter-pool-type">
      <div class="col-12">
        <span v-for="(status) in poolStatuses" :key="status.id">
          <span :class="{ 'filterbutton': true, 'selected': isSelected(status.id) }">
            <sprbutton :title="status.name"
                       :size="4"
                       :bordersize="2"
                       :value="status.id.toString()"
                       click-event="changePoolStatus"
                       @changePoolStatus="onFilterChanged"
            />
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton'
  export default {
    name: 'PoolFilter',
    components: { sprbutton },
    props: {
      filter: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        poolStatuses: [
          { id: 1, name: this.$t('customerSearch.friendPoolStatusActive') },
          { id: 2, name: this.$t('customerSearch.friendPoolStatusRemoved') }
        ]
      }
    },
    methods: {
      onFilterChanged (val) {
        this.$emit('filterchanged', Number(val))
      },
      isSelected (filterType) {
        return this.filter.indexOf(filterType) > -1
      }
    }
  }
</script>
<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .poolfilter {
    background-color: $brand-white;
    padding-top: 20px;

    h4 {
      margin-bottom:50px;
    }

    .cap-label {
      margin-bottom: 15px;
    }

    @media screen and (max-width: $media-md-max) {
      h4 {
        font-size:1.5em !important;
        margin-bottom: 0;
      }
    }

    .filterbutton {
      padding-right:6px;
      margin-bottom: 8px;
      display: inline-block;

      button {
        border-color:$brand-grey5;
        color:$brand-black;
        font-size:12px;
        padding:5px 10px;

        &:hover {
          border-color:$brand-red;
          color:$brand-white;
        }
      }

      &.selected {
        button {
          border-color:$brand-red;
          color:$brand-white;
          background-color:$brand-red;

          &:hover {
            color:$brand-white;
          }
        }
      }
    }
  }

</style>
