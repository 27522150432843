<template>
  <div class="poolicon">
    <div class="lettercontainer">
      <div class="lettertext">
        {{ letter }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Poolicon',
    props: {
      pool: {
        type: Object,
        required: true
      }
    },
    computed: {
      letter () {
        if (this.pool.name && this.pool.name.length > 0) {
          return this.pool.name.substring(0, 1)
        }
        return '?'
      }
    }
  }
</script>
<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";
  .poolicon {
    background-color: $pools-color-strong;
    border-color: $pools-color-strong;
    cursor: default;
    display: inline-block;
    border-radius:50%;
    width:50px;
    height:50px;
    margin:0 auto;
    position: relative;

    .lettercontainer {
      display:flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      align-content: center;

      .lettertext {
        align-self: center;
        font-weight: bold;
        color:$brand-white;
        font-size: 1.4em;
        text-transform: uppercase;
      }
    }
  }
</style>
