<template>
  <div class="fill-width comp-wizard-content">
    <div>
      <span class="h1"
            role="heading"
            aria-level="1"
      >{{ $t('wizard.headerSeven') }}</span><br>
      <sprvalidation class="validation-box"
                     :validation-errors="validationErrors"
      /><br>
      <p>{{ $t('wizard.ingressLogin') }}</p>
    </div>

    <sprinput v-model="firstName"
              v-validate="'required'"
              :hint="$t('wizard.firstNameField')"
              class="login-info"
              :is-valid="!errors.has('firstNameField')"
              name="firstNameField"
              dark
    />
    <sprinput v-model="lastName"
              v-validate="'required'"
              :hint="$t('wizard.lastNameField')"
              class="login-info"
              :is-valid="!errors.has('lastNameField')"
              name="lastNameField"
              dark
    /><br>
    <sprinput v-model="email"
              v-validate="'required|email'"
              :hint="$t('wizard.emailField')"
              class="login-info"
              :is-valid="!errors.has('emailField')"
              name="emailField"
              :disabled="token"
              dark
    />
    <sprinput v-model="phone"
              :hint="$t('wizard.phoneNumberField')"
              class="login-info"
              :is-valid="true"
    /><br>

    <div class="mx-auto text-left checkbox-container pt-5">
      <checkbox v-model="ethics"
                v-validate="'required'"
                :title="$t('wizard.acknowledgeEthics')"
                :allow-html="true"
                :white-warnings="true"
                name="ethicsCheckbox"
                :has-error="errors.has('ethicsCheckbox')"
      />
      <checkbox v-model="market"
                :white-warnings="true"
                :title="$t('wizard.acceptEmailMarketing')"
      />
      <checkbox v-model="gdpr"
                v-validate="'required'"
                :title="$t('wizard.complyGDPR')"
                :white-warnings="true"
                :allow-html="true"
                name="gdprCheckbox"
                :has-error="errors.has('gdprCheckbox')"
      />
    </div>
    <!--
        When implementing these, keep branding guidelines in mind.
        https://developers.google.com/identity/branding-guidelines#incorrect
        https://developers.facebook.com/docs/facebook-login/best-practices/
    <externalloginbutton title="Kirjaudu Facebookilla"
                         class="login-info login-facebook"
                         icon="facebook"
                         clickEvent="ext-click"
                         @ext-click="facebookLogin"></externalloginbutton>
    <externalloginbutton title="Kirjaudu Googlella"
                         class="login-info login-google"
                         icon="google"
                         clickEvent="ext-click"
                         @ext-click="googleLogin"></externalloginbutton>
    -->
  </div>
</template>

<script>
  import sprinput from '../SPRInput.vue'
  import sprvalidation from '../SPRValidationError.vue'
  import checkbox from '../SPRSimpleCheckbox.vue'

  export default {
    name: 'WizardNine',
    components: { sprinput, sprvalidation, checkbox },
    props: {
      token: {
        type: String,
        required: false,
        default: ''
      }
    },
    data () {
      return {
        firstName: this.$store.state.wizardModel.firstName,
        lastName: this.$store.state.wizardModel.lastName,
        email: this.selectEmail(this.token, this.$store.state.wizardModel.email),
        phone: this.$store.state.wizardModel.phoneNumber,
        market: false,
        gdpr: null,
        ethics: null,
        validatedFields: ['firstNameField', 'lastNameField', 'emailField', 'ethicsCheckbox', 'gdprCheckbox'],
        registerErrors: []
      }
    },
    computed: {
      validationErrors: function () {
        if (!this.validatedFields) {
          return []
        }

        var arr = []
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['', err.field + '_' + err.rule, ''])
          }
        })

        if (self.registerErrors) {
          self.registerErrors.forEach(function (registrationError) {
            arr.push(registrationError)
          })
        }

        return arr
      }
    },
    watch: {
      firstName: function () {
        this.errors.remove('firstNameField')
        this.vuixCommit()
      },
      lastName: function () {
        this.errors.remove('lastNameField')
        this.vuixCommit()
      },
      email: function () {
        this.errors.remove('emailField')
        this.vuixCommit()
      },
      phone: function () {
        this.vuixCommit()
      },
      ethics: function (val) {
        // need to have false as null for required to work
        if (val !== null) {
          if (!val) {
            this.ethics = null
          }
          this.vuixCommit()
        }
      },
      gdpr: function (val) {
        if (val !== null) {
          if (!val) {
            this.gdpr = null
          }
          this.vuixCommit()
        }
      },
      market: function () {
        this.vuixCommit()
      }
    },
    methods: {
      vuixCommit: function () {
        this.$store.commit('setWizardBasicInformation', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          market: this.market,
          gdpr: this.gdpr,
          ethics: this.ethics
        })
      },
      facebookLogin: function () {
        console.log('facebook login clicked')
      },
      googleLogin: function () {
        console.log('google login clicked')
      },
      validateSection: function () {
        return this.$validator.validateAll()
      },
      printThis: function () {
        console.log(this)
      },
      selectEmail: function (token, vuex) {
        if (token) {
          return token.UserId
        }

        return vuex
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

  .login-info {
    max-width: 300px;
    width: 100%;
    text-align: left;

    @media (max-width: $media-sm-max) {
      max-width: 500px;
    }
  }

  .login-google {
    background: $google-blue;
    border: none;
    color: $brand-white;
  }

  .login-facebook {
    background: $facebook-blue;
    border: none;
    color: $brand-white;
  }

  .checkbox-container {
    max-width: 600px;
    width: 100%;
    color: $brand-white;

    @media (max-width: $media-sm-max) {
      max-width: 500px;
    }
  }
</style>
