<template>
  <div class="simpledate pb-3">
    <label v-if="title" class="cap-label brand-red ml-3">{{ title }}{{ required ? "*" : "" }}</label>
    <div class="select-container form-control" :class="{ error: !isValid, 'wizard spr-shadow': wizardStyle, 'disabled': disabled }">
      <span v-if="inTitle" class="infield-title">{{ inTitle }} </span>
      <select v-model="day" :disabled="disabled">
        <option v-for="(x, index) in dayOptions(month, year)" :key="index" :value="x">
          {{ x }}
        </option>
      </select>
      <select v-model="month" :disabled="disabled">
        <option v-for="(x, index) in monthOptions" :key="index" :value="x.value">
          {{ x.text }}
        </option>
      </select>
      <select v-model="year" :disabled="disabled">
        <option v-for="(x, index) in yearOptions" :key="index" :value="x.value">
          {{ x.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import moment from 'moment'
  import { SprDropDownValue } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    day: number,
    month: string, //yuck
    year: string
  }

  export default Vue.extend({
    name: 'SimpleDate',
    props: {
      title: {
        type: String,
        default: ''
      },
      inTitle: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: ''
      },
      required: {
        default: false,
        type: Boolean
      },
      value: {
        type: [String, Date], // this comes in in as a UTC date, need to switch to local
        default: undefined
      },
      isValid: {
        default: true,
        type: Boolean
      },
      future: {
        default: 0,
        type: Number
      },
      wizardStyle: {
        default: false,
        type: Boolean
      },
      disabled: {
        default: false,
        type: Boolean
      }
    },
    data (): TemplateComponentData {
      return {
        day: 1,
        month: '01',
        year: '0001'
      }
    },
    computed: {
      monthOptions (): Array<SprDropDownValue> {
        return [
          { value: '01', text: this.$t('datePicker.january') },
          { value: '02', text: this.$t('datePicker.february') },
          { value: '03', text: this.$t('datePicker.march') },
          { value: '04', text: this.$t('datePicker.april') },
          { value: '05', text: this.$t('datePicker.may') },
          { value: '06', text: this.$t('datePicker.june') },
          { value: '07', text: this.$t('datePicker.july') },
          { value: '08', text: this.$t('datePicker.august') },
          { value: '09', text: this.$t('datePicker.september') },
          { value: '10', text: this.$t('datePicker.october') },
          { value: '11', text: this.$t('datePicker.november') },
          { value: '12', text: this.$t('datePicker.december') }
        ]
      },
      yearOptions (): Array<SprDropDownValue> {
        var rv = [] as Array<SprDropDownValue>
        rv.push({ value: '0001', text: this.$t('datePicker.year') })
        for (var i = this.currentYear + this.future; i >= 1900; i--) {
          rv.push({ value: i.toString(), text: i.toString() })
        }
        return rv
      },
      currentYear (): number {
        return (new Date()).getFullYear()
      }
    },
    watch: {
      value: function (val) {
        this.initializeInternalFields(val)
      },
      day: function () {
        this.outputValue()
      },
      month: function () {
        this.outputValue()
      },
      year: function () {
        this.outputValue()
      }
    },
    mounted: function () {
      this.initializeInternalFields(this.value)
    },
    methods: {
      initializeInternalFields: function (val) {
        const date = new Date(val)
        const time = moment(val, 'YYYY-MM-DDTHH:mm:ssZ')

        if (time.isValid()) {
          this.day = Number(time.format('D'))
          this.month = time.format('MM')
          this.year = time.format('YYYY')
        }
      },
      outputValue: function (): void {
        const year = Number(this.year)
        if (year >= 1900) {
          var rv = `${this.year}-${this.month}-${this.day < 10 ? '0' + this.day : this.day.toString()}T00:00:00Z`
          this.$emit('input', new Date(rv))
        } else {
          this.$emit('input', null)
        }
      },
      dayOptions: function (month, year) {
        var number = 0
        const mon = parseInt(month, 10)

        if ([1, 3, 5, 7, 8, 10, 12].indexOf(mon) >= 0) {
          number = 31
        } else if (mon === 2) {
          if (this.isLeapYear(year)) {
            number = 29
          } else {
            number = 28
          }
        } else {
          number = 30
        }

        var rv = [] as Array<number>
        for (var i = 1; i <= number; i++) {
          rv.push(i)
        }

        return rv
      },
      isLeapYear: function (year) {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .select-container {
    border-radius: 24px;
    border: 2px solid $brand-grey3;
    padding: 0 6px;
    overflow: hidden;
    text-align: left;
    display: inline-table !important;

    &.error {
      border: 2px solid $brand-red !important;
    }

    &.disabled {
      border: 2px solid $brand-grey3 !important;
      background-color: #e9ecef !important;
      cursor: not-allowed;
    }

    .infield-title,
    select {
      height: 30px;
      border: none;
      padding: 5px;
      font-family: Signa-Light;
      background: none;
      border: 0px;
      outline: 0px;

      &:disabled {
        cursor: not-allowed;
      }
    }

    &.wizard {
      background: white;
      border: none;
    }

    @media (max-width: $media-xs-max) {
      .select-container {
        display: flex;
        .infield-title,
        select {
          flex-grow: 1;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
</style>
