import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

const validatorHasOptionalGroupOrganizer = {
  getMessage (field, args) {
    return 'Group Organizer can be defined'
  },
  validate (value, args) {
    var rule = value.organizerId === null || value.organizerId > 0
    return rule
  }
}

export default validatorHasOptionalGroupOrganizer
