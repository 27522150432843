<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";

  .section {
    min-height: calc(100vh - 200px);
    .button-width

  {
    width: 100%;
    max-width: 300px;
    direction: inline-block;
  }

  .feature {
    background-color: $brand-white;
    padding: 15px 0;
    margin-bottom: 30px;
  }

  .spr-button {
    margin-bottom: 10px;
  }

  h1 {
    margin: 30px 0 30px;
  }

  .language {
    margin-bottom: 30px;
  }
  .center {
    text-align: center;
  }
  }
</style>

<template>
  <div class="container section">
    <div class="row">
      <div class="col-12">
        <h1>
          {{ section.name }}
        </h1>
      </div>
    </div>
    <div class="row feature">
      <div v-if="sectionLoading" class="brand-red">
        <loading />
      </div>
      <div v-if="!sectionLoading" class="col-12 district">
        <div class="col-12">
          {{ section.districtName }}
        </div>
        <div class="col-12 language">
          {{ $t('userAdmin.sectionLanguage') }} {{ sectionLanguage }}
        </div>
      </div>
      <p v-if="!sectionLoading" v-linkified:options="{ nl2br: true }" class="col-12">
        {{ section.description }}
      </p>
    </div>

    <div v-if="!sectionLoading" class="row feature">
      <div class="col-12">
        <h5>{{ $t('section.actionGroupsTitle') }}</h5>
        <p>
          {{ $t('section.actionGroupsText') }}
        </p>
        <p v-if="section.actionGroups.length === 0" class="center">
          {{ $t('section.noActionGroups') }}
        </p>
      </div>
      <groups :groups="section.actionGroups" />
    </div>
    <div v-if="!sectionLoading" class="row feature">
      <div class="col-12">
        <h5>{{ $t('section.standardGroupsTitle') }}</h5>
        <p>
          {{ $t('section.standardGroupsText') }}
        </p>
        <p v-if="section.standardGroups.length === 0" class="center">
          {{ $t('section.noStandardGroups') }}
        </p>
      </div>
      <groups :groups="section.standardGroups" />
    </div>
  </div>
</template>

<script>
  import groups from './Groups.vue'
  import loading from '../SPRLoading.vue'
  import messages from '../../mixins/messages.js'

  export default {
    name: 'Section',
    components: { groups, loading },
    mixins: [messages],
    data () {
      return {
        section: Object
      }
    },
    computed: {
      sectionLanguage: function () {
        if (this.section.language === 0) {
          return this.$t('languages.fi')
        } else if (this.section.language === 1) {
          return this.$t('languages.sv')
        } else if (this.section.language === 2) {
          return this.$t('userAdmin.bilingual')
        }

        return ''
      }
    },
    created: function () {
      this.getMySection()
    },
    methods: {
      getMySection () {
        var self = this
        self.sectionLoading = true
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.section[0]
        this.$http.get(url).then(resp => {
          self.section = resp.body
          self.sectionLoading = false
        })
      }
    }
  }
</script>
