<template>
  <div class="customernetcontactform">
    <div class="row">
      <div class="col-12">
        <h3>{{ $t('customerForm.netFriendContacts') }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput
          v-model="localCustomer.econtactEmail"
          :hint="$t('connectivity.email')"
          :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput
          v-model="localCustomer.econtactTelegram"
          :hint="$t('connectivity.telegram')"
          :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput
          v-model="localCustomer.econtactKik"
          :hint="$t('connectivity.kik')"
          :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput
          v-model="localCustomer.econtactInstagram"
          :hint="$t('connectivity.instagram')"
          :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput
          v-model="localCustomer.econtactSkype"
          :hint="$t('connectivity.skype')"
          :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput
          v-model="localCustomer.econtactDiscord"
          :hint="$t('connectivity.discord')"
          :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput
          v-model="localCustomer.econtactFreetext"
          :hint="$t('connectivity.freetext')"
          :is-valid="true"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import forminput from '../SPRSimpleText.vue'
  import { FriendCustomerNetDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    localCustomer: FriendCustomerNetDto
  }

  export default Vue.extend({
    name: 'CustomerNetContactForm',
    components: { forminput },
    props: {
      value: {
        type: Object as () => FriendCustomerNetDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        localCustomer: {} as FriendCustomerNetDto
      }
    },
    watch: {
      value: function (newVal: FriendCustomerNetDto): void {
        this.localCustomer = newVal
      }
    },
    mounted (): void {
      if (this.value) {
        this.localCustomer = this.value
      }
    },
    methods: {
      update (): void {
        this.$emit('input', this.localCustomer)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

</style>
