<template>
  <div class="list-row">
    <span class="name" @click="handleLink">{{ department.name }}</span>
    <span class="district">{{ department.district }}</span>
  </div>
</template>

<script>
  export default {
    name: 'Useradmindepartment',
    props: {
      department: {
        type: Object,
        default: () => { return {} }
      },
      link: {
        type: String,
        default: ''
      }
    }
  }
</script>
