<template>
  <div class="customer-actions row button-row">
    <div class="col buttons">
      <!-- Copied/moved from CustomerPreview -->
      <div class="edit-customer">
        <sprbutton
          :size="4"
          :title="$t('customerSearch.editCustomerButton')"
          click-event="edit"
          class="edit-button"
          @edit="editCustomer"
        />
      </div>
      <div class="disable-customer">
        <sprbutton
          :size="4"
          :title="activityText"
          click-event="delete-customer"
          :disabled="inFriendship"
          :tooltip="inFriendship ? $t('customerSearch.inFriendshipNoDelete'): ''"
          @delete-customer="toggleActivity"
        />
      </div>
      <div v-if="!inFriendship" class="customer-onhold">
        <onhold
          :on-hold="onHoldValue"
          @update="onHoldUpdate"
        />
      </div>
      <div v-else class="disable-customer">
        <sprbutton
          :title="$t('customerSearch.showHoldCustomerButton')"
          :size="4"
          :tooltip="inFriendship ? $t('moveFMGForm.disabledHasFriendship') : ''"
          disabled
          class="disable-button button-disabled"
        />
      </div>
      <div v-show="isMoveAdmin" class="edit-customer">
        <sprbutton
          :size="4"
          :title="$t('customerSearch.moveCustomerButton')"
          :tooltip="inFriendship || inFriendshipPool ? $t('moveFMGForm.disabledHasFriendship') : ''"
          click-event="move"
          class="edit-button"
          :disabled="!!inFriendship || !!inFriendshipPool"
          @move="moveCustomer"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import onhold from './OnHold.vue'
  import sprbutton from '../SPRButton.vue'
  import { CustomerState, FriendCustomerNetDto, FriendCustomerStandardDto, FriendManagementDto } from '../../types/index'
  import { OnHoldData } from '@/types/custom'

  export default Vue.extend({
    name: 'CustomerActions',

    components: { onhold, sprbutton },
    props: {
      value: {
        type: Object as () => FriendCustomerNetDto | FriendCustomerStandardDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: false,
        default: null
      },
      loaded: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      activityText (): string | LocaleMessage {
        return this.isInactive ? this.$t('customerSearch.enableCustomerButton') : this.$t('customerSearch.disableCustomerButton')
      },
      isInactive (): boolean {
        return this.value.state === CustomerState.NonActive
      },
      inactiveText (): string | LocaleMessage {
        switch (this.value.state) {
        case CustomerState.PendingApproval:
          return this.$t('customerSearch.noMatchingNotInterviewed')
        case CustomerState.NonActive:
          return this.$t('customerSearch.noMatchingRemoved')
        case CustomerState.OnHold:
          return this.$t('customerSearch.noMatchingOnHold')
        }

        return ''
      },
      inFriendship (): boolean {
        return (this.value.state === CustomerState.ActiveFriendship) || (this.value.state === CustomerState.PendingFriend)
      },
      inFriendshipPool (): boolean {
        return (this.value.friendPools && this.value.friendPools.length > 0)
      },
      onHoldValue (): OnHoldData {
        return {
          onHold: this.value.state === CustomerState.OnHold,
          onHoldDeadline: this.value.onHoldUntilDate,
          onHoldDetails: this.value.onHoldComment
        } as OnHoldData
      }
    },
    methods: {
      isMoveAdmin (): boolean {
        // @ts-ignore
        const isAdmin = this.hasRole('Admin')
        // @ts-ignore
        const isFriendshipAdmin = this.hasRole('FriendshipAdmin')
        // @ts-ignore
        const isDistrictFriendshipAdmin = this.hasRole('DistrictFriendshipAdmin')
        return isAdmin || isFriendshipAdmin || isDistrictFriendshipAdmin
      },
      moveCustomer (): void {
        this.$emit('moveCustomer')
      },
      editCustomer (): void {
        var url = this.group.netFriendship
          ? `/friend/netcustomer/edit/${this.group.id}/${this.value.id}`
          : `/friend/standardcustomer/edit/${this.group.id}/${this.value.id}`

        this.$router.push(url)
      },
      onHoldUpdate (onHold: OnHoldData): void {
        this.value.state = CustomerState.OnHold
        this.value.onHoldUntilDate = onHold.onHoldDeadline
        this.value.onHoldComment = onHold.onHoldDetails
        this.saveCustomer()
      },
      saveCustomer (): void {
        this.$emit('saveCustomer')
      },
      toggleActivity (): void {
        this.$emit('toggleActivity')
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .customer-actions {
    .disable-customer .nodeletewarning {
      color: $brand-red;
      font-size: 13px;
    }
  }
</style>
