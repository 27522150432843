<template>
  <div class="group-organizers-dropdown">
    <div v-if="textOnly">
      <label v-if="hint" class="cap-label">{{ hint }}</label>
      {{ groupOrganizer }}
    </div>
    <div v-else>
      <sprsimpletext
        v-validate="'invalidGroupOrganizer'"
        :hint="hint"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        name="userAdmin.groupOrganizer"
        :is-valid="isValid && !errors.has('userAdmin.groupOrganizer')"
        :value="groupOrganizer"
        :list="datalistid"
        @input="onInput"
      />
      <datalist :id="datalistid" class="dl">
        <option v-for="item in groupOrganizerList" :key="item.id" :class="{ 'groupOrganizerType': item.groupOrganizerType }">
          {{ item.name }}
        </option>
      </datalist>
    </div>
  </div>
</template>

<script>
  import sprsimpletext from './SPRSimpleText.vue'

  export default {
    name: 'GroupOrganizersDropdown',
    components: { sprsimpletext },
    props: {
      hint: {
        type: String,
        default: ''
      },
      textOnly: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        default: () => {
          return {
            groupOrganizerType: -1,
            organizerId: null
          }
        }
      },
      placeholder: {
        type: String,
        default: ''
      },
      required: Boolean,
      disabled: Boolean,
      isValid: Boolean
    },
    data () {
      return {
        groupOrganizer: ''
      }
    },
    computed: {
      datalistid () {
        return `datalist_${this._uid}`
      },
      groupOrganizerList () {
        // GroupOrganizerType: Institution = 0, District = 100, Section = 200, NotSet = -1
        const groupOrganizerTypeNotSet = -1
        const groupOrganizerTypeInstitution = 0
        const groupOrganizerTypeDistrict = 100
        const groupOrganizerTypeSection = 200
        var list = []

        list.push({
          code: `${groupOrganizerTypeNotSet}:-1`,
          name: '-',
          groupOrganizer: {
            groupOrganizerType: groupOrganizerTypeNotSet,
            organizerId: null
          }
        })

        if (!this.$store.state.institutions || !this.$store.state.institutions.length) return list

        for (var institutionIndex = 0; institutionIndex < this.$store.state.institutions.length; institutionIndex++) {
          var institution = this.$store.state.institutions[institutionIndex]

          list.push({
            code: `${groupOrganizerTypeInstitution}:${institution.id}`,
            name: institution.name,
            groupOrganizer: {
              groupOrganizerType: groupOrganizerTypeInstitution,
              organizerId: institution.id
            }
          })
        }

        if (!this.$store.state.districts || !this.$store.state.districts.length) return list

        for (var districtIndex = 0; districtIndex < this.$store.state.districts.length; districtIndex++) {
          var district = this.$store.state.districts[districtIndex]
          var sections = _.sortBy(_.filter(this.$store.state.sections, (section) => {
            // select in this district
            return section.districtId === district.id
          }), (section) => {
            // sort by
            return section.name
          })

          list.push({
            code: `${groupOrganizerTypeDistrict}:${district.id}`,
            name: district.name.toUpperCase(),
            groupOrganizer: {
              groupOrganizerType: groupOrganizerTypeDistrict,
              organizerId: district.id
            }
          })

          for (var j = 0; j < sections.length; j++) {
            var section = sections[j]
            list.push({
              code: `${groupOrganizerTypeSection}:${section.id}`,
              name: section.name,
              groupOrganizer: {
                groupOrganizerType: groupOrganizerTypeSection,
                organizerId: section.id
              }
            })
          }
        }
        return list
      }
    },
    watch: {
      value (newVal) {
        this.setLocalVal(newVal)
      }
    },
    created () {
      var self = this
      this.$validator.extend('invalidGroupOrganizer', {
        getMessage (field, args) {
          return 'GroupOrganizer Invalid'
        },
        validate (value, args) {
          // check the value is one of the ones in the list
          var item = _.find(self.groupOrganizerList, (groupOrganizer) => {
            return groupOrganizer.name === value
          })

          if (!item) {
            return false
          }

          return true
        }
      })
    },
    mounted () {
      if (this.value) {
        this.setLocalVal(this.value)
      }
    },
    methods: {
      setLocalVal (val) {
        if (!val) {
          this.groupOrganizer = null
        } else {
          var ds = _.find(this.groupOrganizerList, (groupOrganizer) => {
            return groupOrganizer.code === `${val.groupOrganizerType}:${val.organizerId}`
          })
          if (ds) {
            this.groupOrganizer = ds.name
          } else {
            this.groupOrganizer = null
          }
        }
      },
      onInput (groupOrganizerName) {
        this.groupOrganizer = groupOrganizerName
        var self = this
        this.$validator.validate().then(result => {
          if (result) {
            var item = _.find(self.groupOrganizerList, (item) => {
              return item.name.toLowerCase() === groupOrganizerName.toLowerCase()
            })

            if (!item) {
              self.$emit('input', null)
              return
            }

            var data = item.groupOrganizer

            self.$emit('input', data)
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .group-organizers-dropdown {
    width: 100%;
    input::-webkit-calendar-picker-indicator {
      display: none;
    }
  }

</style>
