<template>
  <div class="eventleavepage col-12">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h3 v-if="eventLeaveSuccess">
            {{ $t('eventPage.leaveEventSuccessTitle') }}
          </h3>
          <h3 v-if="!eventLeaveSuccess">
            {{ $t('eventPage.leaveEventFailTitle') }}
          </h3>
        </div>
      </div>
      <div class="row">
        <div v-if="eventLeaveSuccess" class="col-12 text-center">
          {{ $t('eventPage.leaveEventSuccess') }}
        </div>
        <div v-if="!eventLeaveSuccess" class="col-12 text-center">
          {{ $t('eventPage.leaveEventFail') }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center cta">
          <sprbutton type="primary"
                     :size="1"
                     :title="$t('eventPage.viewEvents')"
                     class="spr-redborder button-width"
                     click-event="viewEvents"
                     @viewEvents="onViewEvents"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  export default {
    name: 'Eventleavepage',
    components: {
      sprbutton
    },
    props: {
      cancellationToken: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        eventLeaveSuccess: false
      }
    },
    created () {
      if (this.cancellationToken.length === 0) {
        this.eventLeaveSuccess = false
        return
      }

      var leaveurl = `${this.$store.state.apiBases.eventservice + this.$store.state.apiEndpoints.eventleavepublic[0]}`.replace('{cancellationToken}', this.cancellationToken)
      return this.$http.get(leaveurl).then(resp => {
        var result = Number(resp.body)

        switch (result) {
        case 1: // unsubscription successful
          this.eventLeaveSuccess = true
          break

        case 0: // already unsubscribed
          this.eventLeaveSuccess = true
          break

        case -1: // could not find the cancellation token
          this.eventLeaveSuccess = false
          break
        }
      })
    },
    methods: {
      onViewEvents () {
        this.$router.replace('/publicevents')
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .eventleavepage {
    margin-top:50px;

    .cta {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
</style>
