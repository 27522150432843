export default {
  methods: {
    expSeconds: function (seconds) {
      return Date.now() + seconds * 1000
    },
    expMinutes: function (minutes) {
      return Date.now() + minutes * 60000
    },
    expHours: function (hours) {
      return Date.now() + hours * 3600000
    },
    fetchItem: function (uri, expiration, success, failure) {
      if (this.$store.state.cache && this.$store.state.cache[uri] && this.$store.state.cache[uri].value && this.$store.state.cache[uri].exp && this.$store.state.cache[uri].exp > Date.now()) {
        if (success) {
          success(this.$store.state.cache[uri].value)
        }
      } else {
        this.privateFetch(3, uri, expiration, success, failure)
      }
    },
    privateFetch: function (retry, uri, expiration, success, failure) {
      this.$http.get(uri).then(s => {
        this.$store.commit('toCache', { key: uri, value: s.body, exp: expiration })
        if (success) {
          success(s.body)
        }
      }, f => {
        if (retry > 0) {
          this.privateFetch(retry - 1, uri, expiration, success, failure)
        } else {
          if (failure) {
            failure()
          }
        }
      })
    }
  }
}
