<template>
  <div class="comp-user-search">
    <h3 v-if="primary === 'training'">
      {{ $t('friendTraining.trainingHeader') }}
    </h3>
    <div v-if="primary === 'clients' || primary === 'volunteers'">
      <checkboxlist v-model="activeClientFilters"
                    :list="clientFilters"
                    :rows="2"
      />
    </div>
    <p v-if="primary === 'brokers' && purpose === 5">
      {{ $t('userAdmin.existingBrokerRightsTitle') }}
    </p>
    <div class="search-section">
      <div class="search">
        <div class="searchbox">
          <searchbox v-model="search" />
        </div>
        <div v-show="canExport" class="export-button">
          <sprbutton class="export-button"
                     :primary="false"
                     :title="$t('userAdmin.exportVolunteerButton')"
                     :size="5"
                     :aria-label="$t('userAdmin.exportVolunteerButton')"
                     click-event="exportVolunteers"
                     @exportVolunteers="exportVolunteers"
          />
        </div>
        <div v-if="includeAdvancedSearch" class="advanced-search-select" @click="toggleAdvancedSearch">
          <span class="advanced-search">{{ $t('searchCriteria.sCAdvancedSearchTitle') }}</span>
          <font-awesome-icon v-if="advancedSearchVisible" icon="chevron-down" class="brand-red" aria-hidden="true" />
          <font-awesome-icon v-else icon="chevron-up" class="brand-red" aria-hidden="true" />
        </div>
      </div>
      <div v-if="includeAdvancedSearch && advancedSearchVisible" class="advanced-search">
        <usersearchcriteria :v-if="advancedSearchVisible" @change="onChangedSearchCriteria" @clear="onClearSearchCriteria" />
      </div>
    </div>
    <div v-if="primary === 'user' || primary === 'employee'" class="row">
      <div v-if="results.results && !loading" class="col-12">
        <pager-heading pagesizervisible
                       :total="total" :skip="skip" :take="take"
                       @pagesizechanged="onPageSizeChanged"
        ></pager-heading>
        <result v-for="(r, i) in results.results"
                :key="'resultset_' + _uid + '_' + i + '_' + r.profileId"
                :volunteer="r"
        />
        <div v-if="results.total === 0" class="text-center my-4">
          {{ $t('userAdmin.emptySet') }}
        </div>
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <div v-if="primary === 'roles'" class="row">
      <div v-if="results.results && !loading" class="col-12">
        <rresult v-for="(r, i) in results.results"
                 :key="'resultset_' + _uid + '_' + i + '_' + r.profileId"
                 :profile="r"
        />
        <div v-if="results.total === 0" class="text-center my-4">
          {{ $t('userAdmin.emptySet') }}
        </div>
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <div v-if="primary === 'training'">
      <div class="filter-links">
        <router-link v-for="(f, i) in trainingFilters"
                     :key="'filter_' + _uid + '_' + i"
                     :to="trainLink(f.value)"
                     class="d-inline-block m-2"
        >
          {{ f.text }}
        </router-link>
      </div>
      <div v-if="!loading">
        <fresult v-for="(r, i) in results.results"
                 :key="'resultsetf_' + _uid + '_' + i + '_' + r.profileId"
                 :volunteer="r"
                 :mode="id"
        />
        <div v-if="results.total === 0" class="text-center my-4">
          {{ $t('userAdmin.emptySet') }}
        </div>
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <!--Client search -->
    <div v-if="primary === 'clients'">
      <div v-if="results && results.items && !loading">
        <cresult v-for="(r, i) in results.items"
                 :key="'resultsetc_' + _uid + '_' + i + '_' + r.profileId"
                 :customer="r"
        />
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <!-- Friend volunteer search -->
    <div v-if="primary === 'volunteers'">
      <div v-if="results && results.items && !loading">
        <volunteerresult v-for="(r, i) in results.items"
                         :key="'resultsetv_' + _uid + '_' + i + '_' + r.profileId"
                         :volunteer="r"
                         :is-net-profile="isNetProfile"
                         :group-id="id"
                         :typed-profile-id="getTypedProfileId(r)"
        />
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <!-- Friendship broker search -->
    <div v-if="primary === 'brokers'">
      <div v-if="results && results.results && !loading">
        <brokerresult v-for="(r, i) in results.results"
                      :key="'resultsetv_' + _uid + '_' + i + '_' + r.profileId"
                      :volunteer="r"
                      :friend-management-group="group.id"
                      :is-net-profile="isNetProfile"
        />
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <pager v-if="!loading"
           :take="take"
           :skip="skip"
           :total="total"
           :max-display="maxDisplay"
           of-text="/"
           :statisticsvisible="!pagerheadingvisible"
           @pagerchanged="pagerClick"
           @pagerprevious="pagerPrevious"
           @pagernext="pagerNext"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import searchbox from '../SPRSearchBox.vue'
  import pagerHeading from '../SPRPagerHeading.vue'
  import pager from '../SPRPager.vue'
  import result from './UserSimpleSearchResult.vue'
  import fresult from './FriendSearchResult.vue'
  import cresult from './CustomerSearchResult.vue'
  import volunteerresult from './FriendVolunteerSearchResult.vue'
  import brokerresult from './FriendshipBrokerSearchResult.vue'
  import rresult from './UserRolesCurrent.vue'
  import checkboxlist from '../SPRSimpleCheckboxList.vue'
  import loading from '../SPRLoading.vue'
  import { GetSectionVolunteersCriteria, ProfileState, AvailabilityTimesEnum, GetVolunteersCriteria,
           InvolvedEventCategories, FriendManagementDto, FindFriendManagementGroupVolunteersResult,
           FindFriendManagementGroupCustomersResult, FindNetFriendManagementGroupContentCriteria,
           FindStandardFriendManagementGroupContentCriteria, CustomerTypes,
           PreferredAges, FriendshipTypes, PersonalSkills, AreaOfInterest, PreferredContactMethods, SimpleUserProfileDto } from '@/types'
  import { SprCheckboxValue, FilterItem } from '@/types/custom'
  import { HttpResponse } from 'vue-resource/types/vue_resource'
  import usersearchcriteria from '../sections/UserSearchCriteria.vue'
  import cache from '../../mixins/cache.js'
  import enumhelper from '../../mixins/enums.js'
  import inpututils from '../../mixins/inpututils'
  import ExcelJS from 'exceljs'
  import stringhelper from '@/helpers/stringhelper'

  // the data object
  export interface TemplateComponentData {
    searchCriteria: GetSectionVolunteersCriteria | GetVolunteersCriteria,
    group: undefined | FriendManagementDto,
    search: string,
    take: number,
    skip: number,
    total: number,
    maxDisplay: number,
    pagerheadingvisible: boolean,
    results: FindFriendManagementGroupVolunteersResult | FindFriendManagementGroupCustomersResult | any, // todo: This should be updated for the new return types (see #3618, #3619)
    loading: boolean,
    trainingFilters: Array<SprCheckboxValue>,
    activeClientFilters: Array<number>,
    advancedSearchVisible: boolean
  }

  export default Vue.extend({
    name: 'UserSearch',
    components: { sprbutton, searchbox, pager, pagerHeading, result, fresult, cresult, checkboxlist, volunteerresult, brokerresult, rresult, loading, usersearchcriteria },
    mixins: [cache, enumhelper, inpututils],
    props: {
      filter: {
        default: 'all',
        type: String
      },
      id: {
        default: '0',
        type: String
      },
      purpose: {
        default: 1,
        type: Number
      },
      primary: {
        default: 'user',
        type: String
      }
    },
    data (): TemplateComponentData {
      return {
        searchCriteria: {
          involvedEventCategories: InvolvedEventCategories.NotDefined as InvolvedEventCategories,
          profileStates: [] as ProfileState[],
          skills: [] as PersonalSkills[],
          spokenLanguages: [] as string[],
          isAgeBelow30: undefined,
          areasOfInterest: [] as AreaOfInterest[],
          availabilityTimes: [] as AvailabilityTimesEnum[],
          preferredContactMethods: 0,
          contacted: undefined,
          volunteerOnline: undefined
        } as GetSectionVolunteersCriteria,
        group: undefined,
        search: '',
        take: 5,
        skip: 0,
        total: 0,
        maxDisplay: 5,
        results: {},
        loading: false,
        pagerheadingvisible: false,
        advancedSearchVisible: false,
        trainingFilters: [
          { text: this.$t('friendTraining.noCert').toString(), value: 1 },
          { text: this.$t('friendTraining.hasCert').toString(), value: 2 }
        ],
        activeClientFilters: []
      }
    },
    computed: {
      canExport (): boolean {
        //@ts-ignore
        return this.primary === 'volunteers' && (this.hasRole('Admin') || this.hasRole('FriendshipGroupManager'))
      },
      includeAdvancedSearch (): boolean {
        if (this.id && this.filter === 'section' && (this.primary === 'user' || this.primary === 'employee')) {
          return true
        }

        return false
      },
      clientFilters (): Array<SprCheckboxValue> {
        const self = this
        return _.map(this.$store.state.customerStates, function (val, key) {
          return { value: val.id, text: self.$t('customerStates.' + val.text), selected: false } as SprCheckboxValue
        })
      },
      isNetProfile (): boolean {
        return Boolean(this.group?.netFriendship)
      }
    },
    watch: {
      searchCriteria () {
        this.performSearch()
      },
      search: function (val) {
        this.skip = 0
        this.performSearch()
      },
      activeClientFilters: {
        handler: function (val) {
          this.skip = 0
          this.performSearch()
        },
        deep: true
      }
    },
    created () {
      this.pagerheadingvisible = this.primary === 'user' || this.primary === 'employee'
    },
    mounted (): void {
      if (this.filter === 'friendship') {
        this.getGroup()
          .then((result: HttpResponse) => {
            this.group = result.data
            this.$bus.$on('user-search-research', this.performSearch)
            this.performSearch()
          })
          .catch((err: any) => {
            // @ts-ignore
            console.log(err)
            // @ts-ignore
            this.showAlertError(this.$t('common.fetchFailed'))
          })
      } else {
        this.performSearch()
      }
    },
    beforeDestroy: function () {
      this.$bus.$off('user-search-research')
    },
    methods: {
      exportVolunteers (): void {
        this.loading = true
        const self = this

        if (this.skip < 0) this.skip = 0

        if (this.primary === 'volunteers') {
          const params = {
            skip: 0,
            take: 0,
            searchString: this.search,
            orderByNew: false,
            ages: PreferredAges.NotDefined,
            customerTypes: CustomerTypes.NotDefined,
            friendshipTypes: FriendshipTypes.NotDefined,
            customerStates: this.activeClientFilters,
            languages: [],
            natives: [],
            includeFriendPools: false,
            includeFriendships: false,
            groupId: Number(this.id)
          } as FindStandardFriendManagementGroupContentCriteria

          const url = this.group?.netFriendship
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteerexportnet[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteerexportstandard[0]

          this.$http.post(url, params).then((response: any) => {
            const workbook = new ExcelJS.Workbook()

            // Add a worksheet to the workbook
            const worksheet = workbook.addWorksheet('Volunteers')

            // Define the column headers
            worksheet.columns = [
              { header: 'Etunimi', key: 'firstName', width: 30 },
              { header: 'Sukunimi', key: 'lastName', width: 30 },
              { header: 'Sähköposti', key: 'email', width: 30 },
              { header: 'Puhelin', key: 'phone', width: 30 },
              { header: 'Tila', key: 'state', width: 30 }
            ]

            // Apply bold font to the header row
            const headerRow = worksheet.getRow(1)
            headerRow.font = { bold: true }

            // Add member data to the worksheet
            response.body.forEach(function (member: any) {
              //@ts-ignore
              worksheet.addRow({ firstName: member.firstName, lastName: member.lastName, email: member.email, phone: member.phoneNumber, state: self.$t('customerStates.' + stringhelper.toCamelCase(member.state), 'fi') })
            })

            // Set filters on the header row
            headerRow.eachCell(function (cell) {
              cell.alignment = { vertical: 'middle', horizontal: 'center' }
            })
            worksheet.autoFilter = 'A1:E1' // Apply filters to all columns

            // Generate the Excel file as a binary buffer
            return workbook.xlsx.writeBuffer()
          })
            .then(function (buffer) {
              if (buffer === null) return
              // Create a Blob object with the Excel file buffer
              const link = document.createElement('a')
              link.href = URL.createObjectURL(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
              const dateAsTimestamp = new Date().toISOString().substr(0, 19).replace(/:/g, '-').replace('T', '-')
              //@ts-ignore
              link.download = `VolunteerExport-${this.group.id}-${dateAsTimestamp}.xlsx`

              // Append the link to the document body or any other parent element
              document.body.appendChild(link)

              // Trigger the click event to start the download
              link.click()
              link.remove()
            }, (err: Error) => {
              // eslint-disable-next-line no-console
              console.log(err)
              this.loading = false
              this.$store.dispatch('alert/error', { message: this.$t('report.failed') })
            })
        }
      },
      onClearSearchCriteria (): void {
        this.search = ''
      },
      onChangedSearchCriteria (searchCriteria): void {
        if (JSON.stringify(this.searchCriteria) !== JSON.stringify(searchCriteria)) {
          this.searchCriteria = searchCriteria
          this.skip = 0
        }
      },
      toggleAdvancedSearch (): void {
        this.advancedSearchVisible = !this.advancedSearchVisible
      },
      onPageSizeChanged (pageSize): void {
        this.take = pageSize
        this.skip = pageSize
        this.performSearch()
      },
      getTypedProfileId (volunteer): string {
        if (this.isNetProfile === true) {
          return volunteer.friendInfo.netProfile.id
        }
        return volunteer.friendInfo.standardProfile.id
      },
      trainLink (newState): string {
        return '/admin/training/' + newState
      },
      pagerClick (val): void {
        this.skip = val.skip
        this.performSearch()
      },
      pagerPrevious (): void {
        this.skip -= this.take
        this.performSearch()
      },
      pagerNext (): void {
        this.skip += this.take
        this.performSearch()
      },
      getGroup (): Promise<HttpResponse> {
        const url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.id)
        return this.$http.get(url) as Promise<HttpResponse>
      },
      performSearch (): void {
        this.loading = true
        if (this.skip < 0) this.skip = 0

        if (this.primary === 'training' || this.primary === 'user' || this.primary === 'employee' || this.primary === 'roles') {
          // check if section is defined, if it is, use advanced search to collect the volunteers
          if (this.id && this.filter === 'section' && this.primary === 'user') {
            const params = {
            skip: this.skip,
            take: this.take,
            areasOfInterest: this.searchCriteria.areasOfInterest, // areasOfInterest: AreaOfInterest[];
            availabilityTimes: this.searchCriteria.availabilityTimes, // availabilityTimes: AvailabilityTimesEnum[];
            contacted: this.searchCriteria.contacted,
            involvedEventCategories: this.searchCriteria.involvedEventCategories, // involvedEventCategories: InvolvedEventCategories;
            isAgeBelow30: this.searchCriteria.isAgeBelow30,
            preferredContactMethods: this.searchCriteria.preferredContactMethods,
            profileStates: this.searchCriteria.profileStates, // profileStates: ProfileState[];
            searchWord: this.search, //searchWord: string;
            sectionId: Number(this.id), // sectionId: number;
            skills: this.searchCriteria.skills, // skills: PersonalSkills[];
            spokenLanguages: this.searchCriteria.spokenLanguages, // spokenLanguages: string[];
            volunteerOnline: this.searchCriteria.volunteerOnline
          } as GetSectionVolunteersCriteria
            const url = this.url()
            this.$http.post(url, params).then(s => {
              this.results = s.data
              this.total = this.results.total
              this.loading = false
            })
          } else if (this.primary === 'employee') {
            const params = {
            skip: this.skip,
            take: this.take,
            areasOfInterest: this.searchCriteria.areasOfInterest, // areasOfInterest: AreaOfInterest[];
            availabilityTimes: this.searchCriteria.availabilityTimes, // availabilityTimes: AvailabilityTimesEnum[];
            contacted: this.searchCriteria.contacted,
            involvedEventCategories: this.searchCriteria.involvedEventCategories, // involvedEventCategories: InvolvedEventCategories;
            isAgeBelow30: this.searchCriteria.isAgeBelow30,
            preferredContactMethods: this.searchCriteria.preferredContactMethods,
            profileStates: this.searchCriteria.profileStates, // profileStates: ProfileState[];
            searchWord: this.search, //searchWord: string;
            skills: this.searchCriteria.skills, // skills: PersonalSkills[];
            spokenLanguages: this.searchCriteria.spokenLanguages, // spokenLanguages: string[];
            volunteerOnline: this.searchCriteria.volunteerOnline
          } as GetVolunteersCriteria
            const url = this.url()

            this.$http.get(url).then(s => {
              this.results = s.data
              this.total = this.results.total
              this.loading = false
            })
          } else {
            const url = this.url()

            this.$http.get(url).then(s => {
              this.results = s.data
              this.total = this.results.total
              this.loading = false
            })
          }
        } else if (this.primary === 'brokers') {
          const url = this.group?.netFriendship
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationFindNetBrokers[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationFindStandardBrokers[0]

          const dto = {
            search: this.search,
            friendManagementGroupId: Number(this.id),
            potentialBrokersOnly: false,
            skip: this.skip,
            take: this.take
          }

          this.$http.post(url, dto).then(s => {
            this.results = s.data
            this.total = this.results.total
            this.loading = false
          })
        } else if (this.primary === 'volunteers') {
          const params = {
            skip: this.skip,
            take: this.take,
            searchString: this.search,
            orderByNew: false,
            ages: PreferredAges.NotDefined,
            customerTypes: CustomerTypes.NotDefined,
            friendshipTypes: FriendshipTypes.NotDefined,
            customerStates: this.activeClientFilters,
            languages: [],
            natives: [],
            includeFriendPools: false,
            includeFriendships: false,
            groupId: Number(this.id)
          } as FindStandardFriendManagementGroupContentCriteria

          const url = this.group?.netFriendship
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteersearchnet[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteersearchstandard[0]

          this.$http.post(url, params).then(s => {
            this.results = s.data as FindFriendManagementGroupVolunteersResult
            this.total = this.results.total
            this.loading = false
          })
        } else if (this.primary === 'clients') {
          const clientdto = {} as FindNetFriendManagementGroupContentCriteria | FindStandardFriendManagementGroupContentCriteria
          clientdto.skip = this.skip
          clientdto.take = this.take
          clientdto.searchString = this.search
          clientdto.customerStates = this.activeClientFilters
          clientdto.groupId = Number(this.id)
          clientdto.includeFriendPools = false
          clientdto.includeFriendships = false

          const uri = this.group?.netFriendship
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customersearchnet[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customersearchstandard[0]

          this.$http.post(uri, clientdto).then(s => {
            this.results = s.data as FindFriendManagementGroupCustomersResult
            this.total = this.results.total
            this.loading = false
          })
        }
      },
      url: function () {
        if (this.primary === 'training') {
          return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findbyfriendship[0]) // returns SearchResult<PublicProfileDto>
            .replace('{state}', this.id)
            .replace('{skip}', this.skip)
            .replace('{take}', this.take)
            .replace('{search}', this.search)
        } else if (this.primary === 'clients') {
          // return (this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customersimplesearch[0]) // to be updated - update the TemplateComponentData (data) typescript type for results to new one)
        } else if (this.primary === 'volunteers') {
          // return (this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteersimplesearch[0]) // to be updated - update the TemplateComponentData (data) typescript type for results to new one)
        } else if (this.primary === 'user') {
          if (this.id) {
            return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findsectionvolunteers[0])
          } else {
            return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findSimpleIdentOnly[0]) // Returns SearchResult<SimpleUserProfileDto> to be updated - update the TemplateComponentData (data) typescript type for results to new one)
              .replace('{from}', this.filter)
              .replace('{id}', Number(this.id))
              .replace('{skip}', this.skip)
              .replace('{take}', this.take)
              .replace('{search}', this.search)
          }
        } else if (this.primary === 'employee') {
          return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findEmployees[0]) // Returns SearchResult<SimpleUserProfileDto>
            .replace('{skip}', this.skip)
            .replace('{take}', this.take)
            .replace('{search}', this.search)
        }

        return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findIdentOnly[0]) // This does not seem to be used. I cannot replicate this.
          .replace('{from}', this.filter)
          .replace('{id}', this.id)
          .replace('{purpose}', this.purpose)
          .replace('{skip}', this.skip)
          .replace('{take}', this.take)
          .replace('{search}', this.search)
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .export-button {
      font-size: 13px;
      line-height: 20px;
      margin-right: -15px;
    }

  .advanced-search{
    font-family: "Signa-Light", Sans-Serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin-right: 20px;
  }

  .search-section {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
  .search {
  display: flex;
  flex-direction: row;
}

.searchbox {
  flex-grow: 1; /* Take up remaining space */
  flex-shrink: 0; /* Do not shrink */
  flex-basis: 0; /* Allow content to dictate size */
}

.export-button {
  flex-shrink: 1; /* Allow shrinking */
  flex-basis: auto; /* Let content dictate initial size */
  /* Add any other styles you need for your button */
}
  .advanced-search {
    display: inline-block;
    padding: 10px;
    font-size: 18px;
    text-decoration: none;
    font-family: "Signa", Sans-Serif;
    color: #747473;
    transition: color 0.33s;
  }
  .advanced-search-select{
    flex: 0 0 auto;
    align-self: center;
    margin-left: 20px;
    cursor: pointer;

    :hover {
      color: #000000;
      transition: color 0.33s;
    }
  }
  .comp-user-search {
    p {
      font-family: $font-bold;
    }
    .filter-links {
      a {
        text-transform: uppercase;
        font-weight: bold;
        color: $brand-grey7 !important;

        &.router-link-active {
          color: $brand-red !important;
        }
      }
    }

    .spinner {
      color: $brand-red;
      text-align: center;
    }

    .actions {
      padding-top: 7px;
    }
  }
</style>
