<template>
  <div class="resultlist">
    <transition name="fade">
      <div v-if="searchType === 'CUSTOMER'" class="row results">
        <div class="col">
          <CustomerFindResult
            v-for="customer in customerlist"
            :key="customer.id"
            :customer="customer"
            :group="group"
          />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="searchType === 'VOLUNTEER'" class="row results">
        <div class="col">
          <div v-if="group.netFriendship">
            <VolunteerNetFindResult
              v-for="volunteer in volunteerlist"
              :key="volunteer.id"
              :volunteer="volunteer"
              :group="group"
            />
          </div>
          <div v-else>
            <VolunteerStandardFindResult
              v-for="volunteer in volunteerlist"
              :key="volunteer.id"
              :volunteer="volunteer"
              :group="group"
            />
          </div>
        </div>
      </div>
    </transition>
    <div v-show="showPager" class="row">
      <div class="col">
        <pager :skip="skip" :take="take" :total="total" :max-display="5" @pagerchanged="onPagerChanged" @pagerprevious="onPagerPrevious" @pagernext="onPagerNext" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import CustomerFindResult from './CustomerFindResult.vue'
  import VolunteerNetFindResult from './VolunteerNetFindResult.vue'
  import VolunteerStandardFindResult from './VolunteerStandardFindResult.vue'
  import pager from '../SPRPager.vue'
  import { UserProfileDto, FriendCustomerFindDto, FriendManagementDto } from '../../types/index'

  export default Vue.extend({
    name: 'ResultList',
    components: { CustomerFindResult, VolunteerStandardFindResult, VolunteerNetFindResult, pager },
    props: {
      searchType: {
        type: String,
        default: ''
      },
      skip: {
        type: Number,
        default: 0
      },
      take: {
        type: Number,
        default: 10
      },
      total: {
        type: Number,
        default: 0
      },
      customerlist: {
        type: Array as () => Array<FriendCustomerFindDto>,
        default: () => []
      },
      volunteerlist: {
        type: Array as () => Array<UserProfileDto>,
        default: () => []
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      }
    },
    computed: {
      showPager (): boolean {
        return this.total > 0 && (this.customerlist.length > 0 || this.volunteerlist.length > 0)
      }
    },
    methods: {
      onPagerChanged (value): void {
        this.$emit('pagechanged', value)
      },
      onPagerPrevious (): void {
        this.$emit('previous')
      },
      onPagerNext (): void {
        this.$emit('next')
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

</style>
