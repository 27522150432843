<template>
  <div class="onhold">
    <div v-show="!onHold.onHold" class="onhold-customer">
      <sprbutton :size="4"
                 :title="$t('customerSearch.showHoldCustomerButton')"
                 click-event="showHold"
                 class="showhold-button"
                 @showHold="showHold"
      />
    </div>
    <div v-if="showOnHoldInfo" class="onholdinfo">
      <div class="row">
        <div class="col-12 holddate">
          <sprdatepicker :key="componentKey"
                         v-model="resultHoldDate"
                         v-validate="onHoldDeadlineRules"
                         :title="$t('customerSearch.onHoldUntilDate')"
                         :placeholder="$t('customerSearch.chooseHoldDate')"
                         :initial="currentDate"
                         name="customerSearch.onHoldUntilDate"
                         :has-error="errors.has('customerSearch.onHoldUntilDate')"
                         :options="datepickerOptions"
          />
        </div>
        <div class="col-12 holdcomment">
          <sprinput v-model="onHold.onHoldDetails"
                    :hint="$t('customerSearch.onHoldComment')"
                    :is-valid="true"
          />
        </div>
        <div class="col-6">
          <a href="javascript:void(0)" @click="cancelHold">{{ $t('customerSearch.cancelHold') }}</a>
        </div>
        <div class="col-6">
          <sprbutton :size="4"
                     :title="$t('customerSearch.holdCustomerButton')"
                     class="hold-button"
                     click-event="hold"
                     @hold="onHoldCommit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import moment from 'moment'
  import sprbutton from '../SPRButton.vue'
  import sprinput from '../SPRSimpleText.vue'
  import sprdatepicker from '../SPRDatepicker.vue'
  import validatordateisafter from '../../mixins/validator-dateisafter.js'
  import { Validator } from 'vee-validate'
  import { OnHoldData } from '@/types/custom'

  Validator.extend('dateisafter', validatordateisafter)

  // the data object
  export interface TemplateComponentData {
    showOnHoldButton: boolean,
    showOnHoldInfo: boolean,
    resultHoldDate: string,
    currentDate: string,
    componentKey: number,
    datepickerOptions: Record<string, any>
  }

  export default Vue.extend({
    name: 'Onhold',
    components: { sprbutton, sprinput, sprdatepicker },
    props: {
      onHold: {
        type: Object as () => OnHoldData,
        required: true,
        default: () => {
          return {
            onHold: false,
            onHoldDeadline: new Date(),
            onHoldDetails: ''
          }
        }
      }
    },
    data (): TemplateComponentData {
      return {
        showOnHoldButton: true,
        showOnHoldInfo: false,
        resultHoldDate: '',
        currentDate: moment().format('DD.MM.YYYY'),
        componentKey: 0,
        datepickerOptions: {
          inputStyle: {
            display: 'inline-block',
            padding: '10px 20px',
            'line-height': '22px',
            'font-size': '16px',
            border: '2px solid #DDDDDD',
            'box-shadow': 'none',
            'border-radius': '24px',
            color: '#5F5F5F',
            width: '100%'
          }
        }
      }
    },
    computed: {
      onHoldDeadlineRules (): string {
        return this.showOnHoldInfo
          ? `required|dateisafter:${moment().add(1, 'day').startOf('day').format('DD.MM.YYYY')}`
          : ''
      }
    },
    created (): void {
      // Re-render the date component on changing language to update translations
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'setLanguage') {
          this.componentKey += 1
        }
      })
    },
    mounted () {
      this.resultHoldDate = moment(this.onHold.onHoldDeadline).format('DD.MM.YYYY')
    },
    methods: {
      setShowOnHoldButton (): void {
        this.showOnHoldButton = !this.showOnHoldInfo
      },
      showHold (): void {
        this.showOnHoldInfo = true
        this.setShowOnHoldButton()
      },
      cancelHold (): void {
        this.showOnHoldInfo = false
        this.setShowOnHoldButton()
      },
      onHoldCommit (): void {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.commitParent()
            this.cancelHold() // To hide the ShowOnHold button
          }
        })
      },
      commitParent (): void {
        this.$emit('update', {
          onHold: true,
          onHoldDeadline: this.resultHoldDate ? moment(this.resultHoldDate, 'DD.MM.YYYY').utc(true).toDate() : null,
          onHoldDetails: this.onHold.onHoldDetails
        } as OnHoldData)
      }
    }
  })
</script>
