<template>
  <input ref="inputRef"
         :type="computedType"
         class="spr-shadow"
         :class="[roundbox, { 'spr-danger': !isValid && !dark, 'spr-danger-dark': !isValid && dark, 'spr-input': true, 'center': centerText,}]"
         :placeholder="hint"
         :value="value"
         @input="handleInput"
  >
</template>

<script lang="ts">
  import Vue from 'vue'

  export default Vue.extend({
    name: 'Sprinput',
    props: {
      dark: Boolean,
      hint: {
        type: String,
        default: ''
      },
      isValid: Boolean,
      centerText: Boolean,
      unit: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: '1'
      },
      inputType: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      }

    },
    computed: {
      computedType (): string {
        if (this.inputType) {
          return this.inputType
        }
        return 'text'
      },
      roundbox (): string {
        return `spr-roundbox-${this.size}`
      }
    },
    methods: {
      handleInput: function () {
        //@ts-ignore
        this.$emit('input', this.$refs.inputRef.value)
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";
    .spr-input {
      padding: 12px 20px;
      box-sizing: border-box;
      font-family: "Signa-Light", Sans-Serif;
      margin: 5px 3px;
      border: none;

      &::placeholder {
        color:$brand-grey6;
      }

      &:disabled {
        cursor: not-allowed;
      }
      &:focus-visible {
        color: #495057;
        border: solid 2px $brand-grey5;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }
    }

    .spr-danger {
      border: 2px solid $brand-red !important;
    }

    .spr-danger-dark {
      border: 2px solid $error-dark !important;
    }

    .center {
      text-align: center;
    }
    .spr-roundbox-4 {
      text-transform: none;
      padding: 12px 15px;
      border-radius: 23px;
      border-width: 2px;
    }

    .spr-roundbox-5 {
      text-transform: none;
      padding: 6px 12px;
      border-radius: 23px;
      border-width: 2px;
    }
</style>
