<template>
  <div class="col-12 anonymize-user-profile">
    <!-- roles save/cancel buttons -->
    <div class="row buttons-top">
      <div class="col-12">
        <sprbutton type="primary" :title="$t('adminUserEditor.removeUser')"
                   class="my-2 mx-1" :size="1" click-event="delete" @delete="deleteUser"
        />
        <sprbutton type="primary" :title="$t('userAdmin.editCancel')"
                   class="my-2 mx-1" :size="1" click-event="cancel" @cancel="cancelUserDeletion"
        />
      </div>
    </div>
    <!-- Separator line -->
    <div class="separator-line" />
    <!-- Profile Usage Report -->
    <div class="profile-usage">
      <profileUsage
        :title="title"
        :data="data"
        :user-id="userId"
      />
    </div>
    <!-- Separator line -->
    <div class="separator-line" />
    <!-- roles save/cancel buttons -->
    <div class="row buttons-bottom">
      <div class="col-12">
        <sprbutton type="primary" :title="$t('adminUserEditor.removeUser')"
                   class="my-2 mx-1" :size="1" click-event="delete" @delete="deleteUser"
        />
        <sprbutton type="primary" :title="$t('userAdmin.editCancel')"
                   class="my-2 mx-1" :size="1" click-event="cancel" @cancel="cancelUserDeletion"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import profileUsage from './UserProfileUsageReport.vue'
  import sprbutton from '../SPRButton.vue'

  export default {
    name: 'AnonymizeUserProfile',
    components: { profileUsage, sprbutton },
    props: {
      title: {
        type: String,
        default: 'Delete or Anonymize the User Profile'
      },
      data: {
        type: Object,
        default: () => { return {} }
      },
      userId: {
        type: String,
        default: ''
      }
    },
    methods: {
      cancelUserDeletion: function () {
        this.$emit('cancel-delete-user')
      },
      deleteUser: function () {
        this.$emit('delete-user')
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
  .anonymize-user-profile {
      .buttons-top {
        margin-bottom: 20px;
      }

      .buttons-bottom {
        margin-top: 40px;
      }

      .separator-line {
        margin-bottom: 20px;
        border-top: 1px solid red;
      }

      .title {
        font-family: "Signa-Light", Sans-Serif;
      }
  }
</style>
