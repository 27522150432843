<template>
  <div class="grid">
    <table class="grid-table">
      <thead class="grid-column">
        <tr>
          <th v-for="(column, i) in columns" :key="i"
              :class="getCssClass(column)"
              @click="sortBy(column)"
          >
            {{ formatHeader(column) }}
            <span class="arrow" :class="sortOrders[column.name] > 0 ? 'asc' : 'dsc'" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="!filteredData || !filteredData.length">
          <td class="nodata" :colspan="columns.length">
            {{ $t('userAdmin.noResults') }}
          </td>
        </tr>
        <tr v-for="(rowItem, i) in filteredData" :key="i">
          <td v-for="(columnKey, j) in columns" :key="j" :class="getCssClass(columnKey)">
            <div v-if="hasColumnCallBack(columnKey)">
              <div v-if="hasItem(columnKey, rowItem)">
                <font-awesome-icon class="grid-icon" :icon="columnKey.iconName" @click="$emit('show', rowItem)" />
              </div>
            </div>
            <div v-else-if="columnKey.name === 'participated'" class="text-center">
              <input type="checkbox" :checked="rowItem[columnKey.name]" @change="$emit('change', rowItem)">
            </div>
            <div v-else-if="typeof rowItem[columnKey.name] === 'boolean'" class="text-center">
              <input type="checkbox" :checked="rowItem[columnKey.name]" @change="$emit('changeRemoval', rowItem)">
            </div>
            <div v-else>
              {{ rowItem[columnKey.name] | formatData }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import moment from 'moment'
  // import checkboxinput from './SPRSimpleCheckbox.vue'

  export default {
    name: 'SprGrid',
    components: {
      // checkboxinput
    },
    filters: {
      formatData (value) {
        if (moment.isMoment(value)) {
          return moment(value).format('DD.MM.YYYY HH.mm')
        }
        return value
      }
    },
    props: {
      columns: {
        type: Array,
        default: () => []
      },
      data: {
        type: Array,
        default: () => []
      },
      filterKey: {
        type: String,
        default: ''
      },
      translationKey: {
        type: String,
        default: ''
      }
    },
    data () {
      var sortOrders = {}
      this.columns.forEach(function (key) {
        sortOrders[key.name] = 1
      })
      return {
        sortKey: '',
        sortOrders: sortOrders
      }
    },
    computed: {
      filteredData () {
        var sortKey = this.sortKey
        var filterKey = this.filterKey && this.filterKey.toLowerCase()
        var order = this.sortOrders[sortKey] || 1
        var data = this.data

        if (filterKey) {
          data = data.filter(function (row) {
            return Object.keys(row.name).some(function (key) {
              return (
                String(row[key])
                  .toLowerCase()
                  .indexOf(filterKey) > -1
              )
            })
          })
        }

        if (sortKey) {
          data = data.slice().sort(function (a, b) {
            a = a[sortKey]
            b = b[sortKey]
            return (a === b ? 0 : a > b ? 1 : -1) * order
          })
        }

        return data
      }
    },
    methods: {
      hasColumnCallBack (columnKey) {
        var hasCallBack = false

        if (columnKey.callBack) {
          hasCallBack = true
        }

        return hasCallBack
      },
      hasItem (columnKey, rowItem) {
        var columnName = columnKey.name

        if (rowItem[columnName]) {
          return true
        }

        return false
      },
      getCssClass (col) {
        var c = col.cssClass
        if (this.sortKey === col.name) {
          c += ' active'
        }
        if (col.iconName) {
          c += ' has-icon'
        }

        return c
      },
      sortBy: function (key) {
        this.sortKey = key.name
        this.sortOrders[key.name] = this.sortOrders[key.name] * -1
      },
      formatHeader (obj) {
        if (this.translationKey) {
          return this.$t(`${this.translationKey}.${obj.name}`)
        }
        return obj.name.charAt(0).toUpperCase() + obj.slice(1)
      }
    }
  }
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";
.grid {
  padding: 0 -15px;
  width: 100%;
  overflow-x: auto;
  .grid-column{
    overflow: auto;
    font-family: $font-bold;
    text-transform: uppercase;
    font-size: 14px;
  }
  .grid-icon {
    color: $brand-red;
    font-size: 16px;
  }
  .grid-table {
    border: none;
    border-radius: 3px;
    background-color: #fff;
    overflow: auto;
    width: 100%;
  }
}

th {
  background-color: $brand-grey2;
  color: $brand-red;
  cursor: pointer;
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: auto;
}

td {
  background-color: #f9f9f9;
  vertical-align: top;

  &.nodata {
    text-align: center;
  }
}

th,
td {
  min-width: 20px;
  padding: 10px 10px;
  &.has-icon {
    text-align: center;
  }
}

th.active {
  color: $brand-red;
}

th.active .arrow {
  opacity: 1;
  &.asc {
    border-bottom: 4px solid $brand-red;
  }
  &.dsc {
    border-top: 4px solid $brand-red;
  }
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid $brand-grey8;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid $brand-grey8;
}
</style>
