import Vue from 'vue'
import VueResource from 'vue-resource'
import { Rule, RuleValidate } from 'vee-validate'

Vue.use(VueResource)

const validatorHasOptionSelected: RuleValidate | Rule = {
  getMessage (field: string, args: Array<any>): string {
    return 'validation.' + field
  },
  validate (value: number, args: Array<any> | undefined | object): boolean {
    return !!value
  }
}

export default validatorHasOptionSelected