<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .pill {
    background-color: $brand-white;
    border: solid 2px $brand-red;
    padding: 5px 10px;
    border-radius: 20px;
    position: relative;
    display: inline-block;
    background-color: $brand-white;
    color: $brand-red;
    margin-top: 5px;
    font-family: $font-light;
    font-weight: bold;
    font-size:13px;
    display:inline-block;

    &.selected {
      background-color: $brand-red;
      color: $brand-white;
    }

    .pillcontent {
      display:inline-block;
      margin: 0 auto;
      font-weight: bold;
      font-size:14px;
    }

    &.deletable {
      .pillcontent {
        margin-right:10px;
      }
    }

    svg {
      top:14px;
      right:8px;
      display:inline-block;
      position: absolute;
      margin-top:-10px;
      color:$brand-red;
      cursor:pointer;
    }
  }
</style>
<template>
  <div class="pill" :class="{'selected': selected, 'deletable': showDelete}">
    <span class="pillcontent">{{ value }}</span>
    <a href="javascript:void(0)" @click="onRemove">
      <font-awesome-icon v-if="showDelete" icon="times" />
    </a>
  </div>
</template>

<script>
  export default {
    name: 'Pill',
    props: {
      value: {
        type: String,
        default () {
          return ''
        }
      },
      selected: {
        type: Boolean,
        default () {
          return false
        }
      },
      showDelete: {
        type: Boolean,
        default () {
          return false
        }
      }
    },
    methods: {
      onRemove () {
        this.$emit('remove', this.value)
      }
    }
  }
</script>
