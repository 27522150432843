<template>
  <div v-if="currentStep >= 0" class="comp-wizard">
    <div :is="steps[currentStep]"
         ref="currentStage"
         class="swapped-elements bg-top"
         :token="adToken"
    />
    <div class="common-elements bg-bottom">
      <div v-if="!pending" class="col-12 py-4">
        <sprbutton :size="1"
                   :title="buttonTitle"
                   click-event="wizard-next"
                   class="next-button"
                   icon="chevron-right"
                   @wizard-next="onNext"
        /><br>
      </div>
      <div v-else class="row pending">
        <div class="col-12 py-4">
          <loading />
        </div>
      </div>

      <button tabindex="0"
              class="back-link"
              role="link"
              icon="chevron-right" @click="onBack"
      >
        {{ $t('wizard.back') }}
      </button>
      <div class="step-container">
        <font-awesome-icon v-for="(step, index) in steps"
                           :key="index"
                           icon="circle"
                           class="step-indicator"
                           :class="{ 'current-step': index === currentStep, 'filled-step': index < currentStep }"
                           aria-hidden="true"
                           @click="stepIndicatorClick(index)"
        />
      </div>
    </div>
  </div>
  <div v-else class="comp-wizard" />
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import moment from 'moment'
  import interest from './Wizard-Interest.vue'
  import skills from './Wizard-Skill.vue'
  import language from './Wizard-Language.vue'
  import location from './Wizard-Location.vue'
  import transport from './Wizard-Transport.vue'
  import time from './Wizard-Time.vue'
  import basicinfo from './Wizard-Basic.vue'
  import contactinfo from './Wizard-Contact.vue'
  import profileinfo from './Wizard-Profile.vue'
  import sprbutton from '../SPRButton.vue'
  import locationutils from '../../mixins/locationutils.js'
  import loading from '../SPRLoading.vue'
  import { EnumDto, UserProfileDto } from '@/types'
  import { TranslateResult } from 'vue-i18n'

  export default Vue.extend({
    name: 'Wizard',
    components: { sprbutton, loading },
    mixins: [locationutils],
    props: {
      token: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        currentStep: -1,
        currentDate: moment().format('DD.MM.YYYY'),
        parameters: {},
        pending: false,
        initialNumbers: { Age: null, TravelTime: null },
        digiaMap: {
          // AreasOfInterest
          WorkInATeam: 8,
          GuideAndTeach: 4,
          WorkWithYoung: 1,
          HelpInCrisis: 10,
          CollectDonations: 7,
          FirstAid: 9,
          WorkInMulticulturalEnvironment: 11,
          OrganiseActivities: 5,
          WorkWithElderly: 2,
          PromoteWellbeing: 6,
          ImmigrantSupport: 3,
          // AvailabilityTimes
          WeekendMorning: 4,
          WeekdayMorning: 1,
          WeekendEvening: 6,
          WeekdayDaytime: 2,
          WeekendDaytime: 5,
          WeekdayEvening: 3,
          // Languages
          English: 'en',
          Swedish: 'sv',
          Arabic: 'ar',
          Russian: 'ru',
          Finnish: 'fi',
          Estonian: 'et',
          Somali: 'so',
          // PersonalSkills
          CommunicationAndMarketing: 11,
          EventHosting: 3,
          TeamGuide: 6,
          MeetingPeople: 1,
          EventOrganizaton: 8,
          DigitalCompetence: 10,
          TrainPeople: 7,
          PublicPerformances: 4,
          CookingAndBaking: 2,
          PhotographyAndVideo: 9,
          Organizational: 5,
          Rescue: 14,
          HealthCareOrFirstAid: 13,
          FinanceAndAccounting: 12,
          // Transportation
          Own: 1,
          Public: 2,
          Walk: 3,
          Bike: 4
        },
        steps: [interest, skills, language, location, transport, time, profileinfo, contactinfo, basicinfo]
      }
    },
    computed: {
      availabilityTimes (): Array<EnumDto> {
        return this.$store.state.availabilityTimes.filter(x => x.id !== 0)
      },
      wizardState (): UserProfileDto {
        return this.$store.state.wizardModel
      },
      buttonTitle (): TranslateResult {
        if (this.currentStep < this.steps.length - 1) {
          return this.$t('wizard.next')
        }
        return this.$t('wizard.createProfile')
      },
      adToken (): Record<string, any> | null {
        if (this.token) {
          // @ts-ignore cant find mixins
          return this.decodeJwtToken(this.token)
        }

        return null
      },
      customerUrl (): string {
        return `${this.$store.state.apiBases.paymentservice + this.$store.state.apiEndpoints.membershipcustomer[0]}`
      }
    },
    mounted: function () {
      this.evaluateParameters(this.$route.query)
      this.commitMap()
      const cid = this.$route.query.cid
      if (cid) {
        const url = `${this.customerUrl}${cid}`
        const self = this
        // @ts-ignore cant find mixins
        this.httpPromise('get', url).then(data => {
          self.commitInitialData(data)
          self.startForm()
        }).catch(() => {
          self.startForm()
        })
      } else {
        this.startForm()
      }
    },
    methods: {
      focusOnErrors (): void {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      },
      evaluateParameters: function (parameters) {
        const lowerParams = this.toLower(parameters)

        const params = {
          Age: lowerParams.age ? Number(lowerParams.age) : null,
          TravelTime: lowerParams.traveltime ? Number(lowerParams.traveltime) : null,
          WorkInATeam: lowerParams.aintworkinateam ? Boolean(lowerParams.aintworkinateam) : null,
          GuideAndTeach: lowerParams.aintguideandteach ? Boolean(lowerParams.aintguideandteach) : null,
          WorkWithYoung: lowerParams.aintworkwithyoung ? Boolean(lowerParams.aintworkwithyoung) : null,
          HelpInCrisis: lowerParams.ainthelpincrisis ? Boolean(lowerParams.ainthelpincrisis) : null,
          CollectDonations: lowerParams.aintcollectdonations ? Boolean(lowerParams.aintcollectdonations) : null,
          FirstAid: lowerParams.aintfirstaid ? Boolean(lowerParams.aintfirstaid) : null,
          WorkInMulticulturalEnvironment: lowerParams.aintworkinmulticulturalenv ? Boolean(lowerParams.aintworkinmulticulturalenv) : null,
          OrganiseActivities: lowerParams.aintorganiseactivities ? Boolean(lowerParams.aintorganiseactivities) : null,
          WorkWithElderly: lowerParams.aintworkwithelderly ? Boolean(lowerParams.aintworkwithelderly) : null,
          PromoteWellbeing: lowerParams.aintpromotewellbeing ? Boolean(lowerParams.aintpromotewellbeing) : null,
          ImmigrantSupport: lowerParams.aintimmigrantsupport ? Boolean(lowerParams.aintimmigrantsupport) : null,
          WeekendMorning: lowerParams.atweekendmorning ? Boolean(lowerParams.atweekendmorning) : null,
          WeekdayMorning: lowerParams.atweekdaymorning ? Boolean(lowerParams.atweekdaymorning) : null,
          WeekendEvening: lowerParams.atweekendevening ? Boolean(lowerParams.atweekendevening) : null,
          WeekdayDaytime: lowerParams.atweekdaydaytime ? Boolean(lowerParams.atweekdaydaytime) : null,
          WeekendDaytime: lowerParams.atweekenddaytime ? Boolean(lowerParams.atweekenddaytime) : null,
          WeekdayEvening: lowerParams.atweekdayevening ? Boolean(lowerParams.atweekdayevening) : null,
          English: lowerParams.langenglish ? Boolean(lowerParams.langenglish) : null,
          Swedish: lowerParams.langswedish ? Boolean(lowerParams.langswedish) : null,
          Arabic: lowerParams.langarabic ? Boolean(lowerParams.langarabic) : null,
          Russian: lowerParams.langrussian ? Boolean(lowerParams.langrussian) : null,
          Finnish: lowerParams.langfinnish ? Boolean(lowerParams.langfinnish) : null,
          Estonian: lowerParams.langestonian ? Boolean(lowerParams.langestonian) : null,
          Somali: lowerParams.langsomali ? Boolean(lowerParams.langsomali) : null,
          CommunicationAndMarketing: lowerParams.pscommunicationandmarketing ? Boolean(lowerParams.pscommunicationandmarketing) : null,
          EventHosting: lowerParams.pseventhosting ? Boolean(lowerParams.pseventhosting) : null,
          TeamGuide: lowerParams.psteamguide ? Boolean(lowerParams.psteamguide) : null,
          MeetingPeople: lowerParams.psmeetingpeople ? Boolean(lowerParams.psmeetingpeople) : null,
          EventOrganizaton: lowerParams.pseventorganizaton ? Boolean(lowerParams.pseventorganizaton) : null,
          DigitalCompetence: lowerParams.psdigitalcompetence ? Boolean(lowerParams.psdigitalcompetence) : null,
          TrainPeople: lowerParams.pstrainpeople ? Boolean(lowerParams.pstrainpeople) : null,
          PublicPerformances: lowerParams.pspublicperformances ? Boolean(lowerParams.pspublicperformances) : null,
          CookingAndBaking: lowerParams.pscookingandbaking ? Boolean(lowerParams.pscookingandbaking) : null,
          PhotographyAndVideo: lowerParams.psphotographyandvideo ? Boolean(lowerParams.psphotographyandvideo) : null,
          Organizational: lowerParams.psorganizational ? Boolean(lowerParams.psorganizational) : null,
          Rescue: lowerParams.psrescue ? Boolean(lowerParams.psrescue) : null,
          HealthCareOrFirstAid: lowerParams.pshealthcareorfirstaid ? Boolean(lowerParams.pshealthcareorfirstaid) : null,
          FinanceAndAccounting: lowerParams.psfinanceandaccounting ? Boolean(lowerParams.psfinanceandaccounting) : null,
          Own: lowerParams.tpcar ? Boolean(lowerParams.tpcar) : null,
          Public: lowerParams.tppublic ? Boolean(lowerParams.tppublic) : null,
          Walk: lowerParams.tpwalk ? Boolean(lowerParams.tpwalk) : null,
          Bike: lowerParams.tpbike ? Boolean(lowerParams.tpbike) : null
        }

        this.parameters = params
      },
      toLower (params): Record<string, any> {
        const lowerParams = {}
        for (const key in params) {
          lowerParams[key.toLowerCase()] = params[key]
        }

        return lowerParams
      },
      commitMap: function () {
        var areasOfInterest = ['WorkInATeam', 'GuideAndTeach', 'WorkWithYoung', 'HelpInCrisis', 'CollectDonations', 'FirstAid', 'WorkInMulticulturalEnvironment', 'OrganiseActivities', 'WorkWithElderly', 'PromoteWellbeing', 'ImmigrantSupport']
        this.commitMapList(areasOfInterest, 'setWizardInterests')

        var availabilityTimes = ['WeekendMorning', 'WeekdayMorning', 'WeekendEvening', 'WeekdayDaytime', 'WeekendDaytime', 'WeekdayEvening']
        this.commitMapList(availabilityTimes, 'setWizardBestTimes')

        var languages = ['English', 'Swedish', 'Arabic', 'Russian', 'Finnish', 'Estonian', 'Somali']
        this.commitMapList(languages, 'setWizardLanguages')

        this.commitMapNumbers()

        var personalSkills = ['CommunicationAndMarketing', 'EventHosting', 'TeamGuide', 'MeetingPeople', 'EventOrganizaton', 'DigitalCompetence', 'TrainPeople', 'PublicPerformances', 'CookingAndBaking', 'PhotographyAndVideo', 'Organizational', 'Rescue', 'HealthCareOrFirstAid', 'FinanceAndAccounting']
        this.commitMapList(personalSkills, 'setWizardSkills')

        var transportation = ['Own', 'Public', 'Walk', 'Bike']
        this.commitMapList(transportation, 'setWizardTransportation')
      },
      addActiveItem: function (key, items) {
        const from = this.parameters
        const value = this.digiaMap

        if (from[key] === true) {
          items.push(value[key])
        }
      },
      commitMapList: function (list, action) {
        var items = []

        for (const item of list) {
          this.addActiveItem(item, items)
        }

        this.$store.commit(action, items)
      },
      commitMapNumbers: function () {
        // @ts-ignore
        this.initialNumbers.Age = this.parameters.Age ? moment().subtract(this.parameters.Age, 'years').utc(true) : null
        // @ts-ignore
        this.initialNumbers.TravelTime = this.parameters.TravelTime

        this.$store.commit('setWizardNumberInformation', this.initialNumbers)
      },
      commitInitialData: function (data) {
        this.$store.commit('setWizardBasicInformation', {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          market: false,
          gdpr: false
        })

        const lang = data.nativeLanguage.toLowerCase()
        this.$store.commit('setWizardContactInformation', {
          streetaddress: data.street,
          zipcode: data.zipCode,
          city: data.city,
          country: 'Suomi',
          age: data.dateOfBirth,
          language: lang
        })

        this.$store.commit('setWizardWorkingZip', data.zipCode)
        this.$store.commit('setWizardLanguages', [lang])
      },
      startForm: function () {
        if (this.currentStep < 0) {
          this.currentStep = 0
        }
      },
      onNext: function () {
        // @ts-ignore
        if (this.$refs.currentStage.validateSection != null) {
          var self = this
          // @ts-ignore
          this.$refs.currentStage.validateSection().then(function (data) {
            if (data === true) {
              self.incrementStep()
              // @ts-ignore
              if (self.$refs.currentStage.zipCodeChanged) {
                self.prefillCityFromZipcode()
              }
            } else {
              window.scrollTo(0, 0)
            }
          })
        } else {
          this.incrementStep()
        }
      },
      onBack: function () {
        window.scrollTo(0, 0)
        if (this.currentStep === 0) {
          this.$router.push('register')
        }
        this.currentStep = Math.max(this.currentStep - 1, 0)
      },
      incrementStep: function () {
        window.scrollTo(0, 0)
        if (this.currentStep >= this.steps.length - 1) {
          if (this.token && this.token !== '') {
            this.attemptAdRegister()
          } else {
            this.attemptRegister()
          }
        } else {
          this.currentStep = (this.currentStep + 1) % this.steps.length
        }
      },
      stepIndicatorClick: function (index) {
        if (index < this.currentStep && index >= 0) {
          window.scrollTo(0, 0)
          this.currentStep = index
        }
      },
      prefillCityFromZipcode: function () {
        var zip = this.$store.state.wizardModel.zipCode
        var self = this
        // @ts-ignore cannot find mixins
        this.cityFromZip(zip, this, response => {
          self.$store.commit('setWizardCity', self.$store.state.language === 'se' ? response.body.citySe : response.body.cityFi)
        })
      },
      attemptRegister: function () {
        var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.register[0]
        var payload = this.$store.state.wizardModel as UserProfileDto
       const self = this

        this.setDefaultValues(payload)

        this.pending = true
        this.$http.post(uri, payload
        ).then(s => {
          this.pending = false
          if (s.status === 200) {
            if (self.$store.state.enableRegisterToJoinFlow && (!self.$route.query || self.$route.query.cid === undefined)) {
              self.$store.commit('setRegisterId', s.data)
              this.$router.push({ name: 'registerthanks' })
            } else {
              sessionStorage.setItem('email', this.$store.state.wizardModel.email)
              this.$router.push({
                name: 'login',
                query: {
                  message: 'registersuccess'
                }
              })
            }
          } else {
            this.handleError(s)
          }
        }, (err) => {
          console.log('attemptRegister.error', err)
          this.handleError(err)
          this.pending = false
        })
      },
      setDefaultValues (payload: UserProfileDto) {
        // default native language to the language set for the user if its not set
        if (!payload.nativeLanguage || payload.nativeLanguage === '') {
          payload.nativeLanguage = this.$store.state.language
        }

        // quick and dirty fix
        if (!payload.travelTime || payload.travelTime === '') {
          payload.travelTime = '60'
        }
      },
      attemptAdRegister: function () {
        var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.adregister[0]
        var payload = this.$store.state.wizardModel as UserProfileDto
        const self = this

        this.setDefaultValues(payload)

        this.pending = true

        this.$http.post(uri, payload,
                        {
                          headers: {
                            Authorization: 'bearer ' + this.token
                          }
                        }
        ).then(s => {
          this.pending = false
          if (s.status === 200) {
            window.location.href = this.$store.state.aduri
          } else {
            this.handleError(s)
          }
        }, (err) => {
          this.handleError(err)
          this.pending = false
        })
      },
      handleError: function (error) {
        var rv = [] as Array<Array<string>>
        if (error.body) {
          // error.body.forEach(s => rv.push(['', this.lcFirst(s.code), '']))
          //this is for the old error handling, which is still used in some niche cases

          if (error.body.errorCode === 101) {
            // @ts-ignore cannot find mixins
            rv.push(['', this.lcFirst(error.body.translationKey), ''])
          } else {
            if (error.body.description.toLowerCase() === 'emailexists') {
              // @ts-ignore cannot find mixins
              this.showAlertInfo(this.$t('error.emailExists'))
            } else {
              //do we have any other errors? Backend seems to just have emailexists error and generic user/profile creation failed errors.
              //for now using this generic error message for everything else than emailexists.z

              // @ts-ignore cannot find mixins
              this.showAlertInfo(this.$t('validation.registrationFailed'))
            }
          }
        }

        console.log(error, rv)
        // @ts-ignore
        this.$refs.currentStage.registerErrors = rv
        this.focusOnErrors()
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';

  .comp-wizard {
    box-sizing: border-box;
    padding: 0px;
    width: 100%;

    min-height: calc(100vh - 200px);
    padding-bottom: 25px;
    margin-bottom: 15px;
    background:$brand-white center center;
    background-repeat: no-repeat;
    background-size: contain cover;
    text-align: center;

    display: block;
    display: -ms-grid;
    grid-template-columns: 1fr;

    -ms-grid-columns: 1fr;
    grid-template-rows: auto 40px 180px;
    -ms-grid-rows: auto 40px 180px;

    .next-button {
      width: 250px;
    }

    .bg-top {
      background: $brand-back center center;
    }
    .bg-bottom {
      background: $brand-white center center;
    }

    .back-link {
      padding: 0;
      background: none;
      border: none;
      font-size: 15px;
      text-transform: uppercase;
      font-family: $font-bold;
      letter-spacing: 1.25px;
      color: $brand-font;
      margin: 25px 0 15px 0;
      display: inline-block;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .swapped-elements {
      grid-column: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    .common-elements {
      grid-column: 1;
      grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    .step-container {
      .step-indicator {
        margin: 2px;
        font-size: 12px;
        color: $brand-grey11;
        transition: color 0.5s;
      }
      .current-step {
        color: $brand-white;
        transition: color 1s;
      }
      .filled-step {
        color: $brand-grey4;
        cursor: pointer;
      }
    }

    .privacy-link {
      color: $brand-grey5;
      padding: 20px;
      margin: 18px;
      // display: inline-block;
    }
  }

</style>
