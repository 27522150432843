<template>
  <div>
    <div class="search-criteria-title">
      {{ title }}
    </div>
    <div class="search-criteria">
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCInterest') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.areasOfInterest"
          :items="areasOfInterest"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCSkill') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.skills"
          :items="personalSkills"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCLanguage') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.spokenLanguages"
          :items="spokenLanguages"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCOnline') }}
        </div>
        <singleselect
          v-model="searchCriteria.volunteerOnline"
          :items="volunteerOnline"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCInvolved') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.involvedEventCategories"
          :items="eventCategories"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCProfileState') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.profileStates"
          :items="profileStates"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCPreferredContact') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.preferredContactMethods"
          :items="preferredContactMethods"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCContacted') }}
        </div>
        <singleselect
          v-model="searchCriteria.contacted"
          :items="contacted"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCAvailability') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.availabilityTimes"
          :items="availabilityTimes"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('searchCriteria.sCAge') }}
        </div>
        <singleselect
          v-model="searchCriteria.isAgeBelow30"
          :items="ageBelow30"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t("searchCriteria.areaOfActivity") }}
        </div>
        <sprautocomplete
          v-model="searchCriteria.activityArea"
          :items="sectionZips"
          :placeholder="$t('searchCriteria.workingZipPlaceholder')"
        />
      </div>
    </div>
    <div class="button-position">
      <sprbutton type="primary"
                 :size="1"
                 :title="$t('searchCriteria.filter')"
                 class="spr-redborder"
                 click-event="search"
                 @search="onSearch"
      />
      <sprbutton type="primary"
                 :size="1"
                 :title="$t('searchCriteria.clear')"
                 class="spr-redborder"
                 click-event="search"
                 @search="onClear"
      />
    </div>
  </div>
</template>
<script lang='ts'>
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  import singleselect from '../common/Dropdown/SingleSelectDropdown.vue'
  import multiselectinput from '../common/Dropdown/MultiSelectDropdown.vue'
  import cache from '../../mixins/cache.js'
  import enumhelper from '../../mixins/enums.js'
  import inpututils from '../../mixins/inpututils'
  import sprautocomplete from '../SPRSimpleAutocompleteDropdown.vue'

  // the data object
  export interface TemplateComponentData {
    disabled: boolean,
    searchCriteria: Record<string, any>,
    isvalid: boolean,
    required: boolean,
    searchtext: string,
    isSearching: boolean,
    eventCategories: Array<any>,
    profileStates: Array<any>,
    personalSkills: Array<any>,
    spokenLanguages: Array<any>,
    ageBelow30: Array<any>,
    areasOfInterest: Array<any>,
    availabilityTimes: Array<any>,
    preferredContactMethods: Array<any>,
    contacted: Array<any>,
    volunteerOnline: Array<any>,
    sectionZips: Array<any>
  }

  export default Vue.extend({
    name: 'UserSearchCriteria',
    components: { multiselectinput, singleselect, sprbutton, sprautocomplete },
    mixins: [cache, enumhelper, inpututils],
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        disabled: false,
        searchCriteria: {
        involvedEventCategories: [],
        profileStates: [],
        skills: [],
        spokenLanguages: [],
        isAgeBelow30: { id: 0, text: '-' },
        contacted: { id: 0, text: '-' },
        volunteerOnline: { id: 0, text: '-' },
        areasOfInterest: [],
        availabilityTimes: [],
        preferredContactMethods: [],
        activityArea: ''
      } as Record<string, any>,
        isvalid: true,
        required: true,
        searchtext: '',
        isSearching: false,
        eventCategories: [],
        profileStates: [],
        personalSkills: [],
        spokenLanguages: [],
        ageBelow30: [],
        areasOfInterest: [],
        availabilityTimes: [],
        preferredContactMethods: [],
        contacted: [],
        volunteerOnline: [],
        sectionZips: []
      }
    },
    mounted () {
      this.eventCategories = this.$store.state.involvedEventCategories.map((v) => { return { id: v.id, text: this.$t('eventCategory.' + v.text) } })
      this.profileStates = this.$store.state.profileState.map((v) => { return { id: v.id, text: this.$t('profile.' + v.text) } })
      this.personalSkills = this.$store.state.profilePersonalSkills.map((v) => { return { id: v.id, text: this.$t('skills.' + v.text) } })
      this.spokenLanguages = this.$store.state.customerLanguages.map((v) => { return { id: v.id, text: this.$t('languages.' + v.isoCode), isoCode: v.isoCode } })
      this.ageBelow30 = this.$store.state.optionalBooleanValues.map((v) => { return { id: v.id, text: this.$t('boolean.' + v.text) } })
      this.areasOfInterest = this.$store.state.profileAreasOfInterest.map((v) => { return { id: v.id, text: this.$t('interest.' + v.text) } })
      this.availabilityTimes = this.$store.state.availabilityTimes.map((v) => { return { id: v.id, text: this.$t('availabilityTimes.' + v.text) } }).filter((v) => v.id !== 0)
      this.preferredContactMethods = this.$store.state.preferredContactMethods.map((v) => { return { id: v.id, text: this.$t('preferredContactMethods.' + v.text) } }).filter((v) => v.id !== 0)
      this.contacted = this.$store.state.optionalBooleanValues.map((v) => { return { id: v.id, text: this.$t('boolean.' + v.text) } })
      this.volunteerOnline = this.$store.state.optionalBooleanValues.map((v) => { return { id: v.id, text: this.$t('boolean.' + v.text) } })
    },
    created () {
      this.sectionZips = [] as Array<string>

      const uniqueZipCodes = this.$store.state.sections.reduce((acc, section) => {
        section.zipCodes.forEach((zipCode) => acc.add(zipCode))
        return acc
      }, new Set())

      this.sectionZips = Array.from(uniqueZipCodes).sort()
    },
    methods: {
      zipFilterFunction (option) {
        return option
          .toString()
          .toLowerCase()
          //@ts-ignore
          .includes(this.searchText.toString().toLowerCase())
      },
      onSearch () {
        const involvedEventCategories = this.searchCriteria.involvedEventCategories.reduce((c, v) => c + v.id, 0)
        const preferredContactMethods = this.searchCriteria.preferredContactMethods.reduce((c, v) => c + v.id, 0)

        const searchCriteria = {
          areasOfInterest: this.searchCriteria.areasOfInterest.map(v => v.id),
          skills: this.searchCriteria.skills.map(v => v.id),
          spokenLanguages: this.searchCriteria.spokenLanguages.map(v => v.isoCode),
          profileStates: this.searchCriteria.profileStates.map(v => v.id),
          involvedEventCategories: involvedEventCategories,
          preferredContactMethods: preferredContactMethods,
          availabilityTimes: this.searchCriteria.availabilityTimes.map(v => v.id),
          isAgeBelow30: this.convertBoolean(this.searchCriteria.isAgeBelow30),
          contacted: this.convertBoolean(this.searchCriteria.contacted),
          volunteerOnline: this.convertBoolean(this.searchCriteria.volunteerOnline),
          activityArea: this.searchCriteria.activityArea
        }

        this.$emit('change', searchCriteria)
      },
      onClear () {
        this.searchCriteria = {
          involvedEventCategories: [],
          profileStates: [],
          skills: [],
          spokenLanguages: [],
          areasOfInterest: [],
          availabilityTimes: [],
          preferredContactMethods: [],
          isAgeBelow30: { id: 0, text: '-' },
          contacted: { id: 0, text: '-' },
          volunteerOnline: { id: 0, text: '-' },
          activityArea: ''
        }
        this.$emit('clear')
      },
      convertBoolean (value) {
        return value.id === 0 ? undefined : value.id < 2
      },
      // @ts-ignore - ts intellisense not finding mixins
      activeProfileInvolvedEventCategories (): TranslateResult[] | string[] {
        var involvedEventCategories = this.$store.state.profile.involvedEventCategories
        // @ts-ignore - ts intellisense not finding mixins
        var activeItems = this.getProfileInvolvedEventCategoriesMaskItems(involvedEventCategories)
        return activeItems
      },
      // @ts-ignore - ts intellisense not finding mixins
      profileInvolvedEventCategories (): TranslateResult | string {
        var involvedEventCategories = this.$store.state.profile.involvedEventCategories
        // @ts-ignore - ts intellisense not finding mixins
        return this.getProfileInvolvedEventCategoriesMaskTranslation(involvedEventCategories)
      }
    }
  })
</script>
<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .search-criteria {
    display: flex;
    flex: 1 0 auto;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .cap-label {
    color:$brand-red;
    padding:0 20px;
  }

  .search-item {
    flex: 1 0 auto;
    max-width: 100%;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .button-position {
      float: right;
  }

</style>