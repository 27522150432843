export default {
  methods: {
    cityFromZip: function (zip, vm, success, failure) {
      var uri = (vm.$store.state.apiBases.contentservice + vm.$store.state.apiEndpoints.zipcode[0]).replace('{0}', zip)

      vm.$http.get(uri).then(response => {
        if (success) {
          success(response)
        }
      }, response => {
        if (failure) {
          failure(response)
        }
      })
    },
    sectionsFromZip (zip, vm) {
      return _.filter(vm.$store.state.sections, function (sec) {
        return _.contains(sec.zipCodes, zip)
      })
    },
    formatAddress (streetAddress, postalCode, city) {
      return [streetAddress, [postalCode, city].filter(Boolean).join(' ')].filter(Boolean).join(', ')
    }
  }
}
