<style lang="scss">
@import "../../assets/scss/_variables.scss";
.spr-modal-footer {
  .buttons {
    border: 2px solid $brand-red;
    background: $brand-white;

    padding-top: 10px;
    padding-bottom: 10px;

    color: $brand-red;

    &:hover {
      color: $brand-white;
      background: $brand-red;
    }
  }
}
</style>

<template>
  <!-- BACK BUTTON -->
  <div class="spr-modal-footer container-fluid">
    <div class="row">
      <div class="col-12">
        <div>
          <button v-for="(b, index) in buttons"
                  :key="index"
                  class="btn spr-roundbox-3 cap-label buttons float-right ml-2"
                  @click="onClick(b.event)"
          >
            {{ b.text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalFooter',
    props: {
      buttons: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      onClick: function (val) {
        this.$emit(val)
      }
    }
  }
</script>
