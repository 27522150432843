<template>
  <div v-if="localApprovalData" class="approvalform">
    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('customerForm.approvalSectionHeader') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxinput
          v-model="localApprovalData.approval"
          :title="$t('customerForm.customerApproval')"
          :aria-label="$t('customerForm.customerApproval')"
          @input="update"
        />
      </div>
      <div v-if="localApprovalData.approval" class="col-12 col-sm-6 col-md-4">
        <forminput
          v-model="localApprovalData.approvedBy"
          v-validate="'required'"
          :placeholder="$t('customerForm.customerApprovedBy')"
          :is-valid="!errors.has('customerForm.approveName')"
          name="customerForm.approveName"
          :required="true"
          @input="update"
        />
      </div>
      <div v-if="localApprovalData.approval" class="col-12 col-sm-6 col-md-4">
        <forminput
          v-model="localApprovalData.approvalDate"
          v-validate.initial="computedDateRequired"
          :placeholder="datePlaceholder"
          :is-valid="!errors.has('customerForm.approveDate')"
          name="customerForm.approveDate"
          :required="true"
          @input="update"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import forminput from '../SPRSimpleText.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import { ApprovalData } from '../../types/custom'

  // the data object
  export interface TemplateComponentData {
    localApprovalData: undefined | ApprovalData
  }

  export default Vue.extend({
    name: 'ApprovalForm',
    components: {
      forminput,
      checkboxinput
    },
    inject: ['$validator'],
    props: {
      value: {
        type: Object as () => ApprovalData,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        localApprovalData: undefined as undefined | ApprovalData
      }
    },
    computed: {
      datePlaceholder (): string {
        return this.$store.state.language === 'sv' ? 'dd.mm.åååå' : 'pp.kk.vvvv'
      },
      computedDateRequired (): Record<string, any> {
        return {
          required: this.localApprovalData?.approvalDate,
          regex: /^\d{1,2}\.\d{1,2}\.\d{4}$/
        }
      }
    },
    watch: {
      value: function (newVal: ApprovalData): void {
        this.localApprovalData = newVal
      }
    },
    mounted (): void {
      if (this.value) {
        this.localApprovalData = this.value
      }
    },
    methods: {
      update (): void {
        if (!this.localApprovalData?.approval) {
          if (this.localApprovalData) {
            this.localApprovalData.approvalDate = ''
            this.localApprovalData.approvedBy = ''
          }
        }

        this.$emit('input', this.localApprovalData)
      }
    }
  })
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.approvalform {
  background-color: $brand-white;

  .subheader {
    color: $brand-red;
    text-transform: uppercase;
    font-family: $font-bold;
    margin: 0 0 10px 20px;
    display: block;
    font-size: 14px;
  }

  .sectionheader {
    margin: 15px 0;
  }
}
</style>
