<template>
  <div class="row comp-search-result my-2">
    <div class="col-md-5 col-12 grid-row">
      <div class="grid-name">
        {{ $props.volunteer.fullName }}
      </div>
      <div v-if="pending" class="griz-zip text-right">
        <span class="mt-1"><loading /></span>
      </div>
    </div>

    <div class="col-md-7 col-12 actions text-right">
      <sprbutton v-if="!$props.volunteer.isBroker && !$props.volunteer.isGroupManager"
                 type="primary"
                 :size="4"
                 :title="$t('userAdmin.addBrokerRights')"
                 class="spr-redborder button-width"
                 click-event="makeBroker"
                 @makeBroker="onMakeBroker"
      />
      <sprbutton v-if="$props.volunteer.isBroker && !$props.volunteer.isGroupManager"
                 type="primary"
                 :size="4"
                 :title="$t('userAdmin.removeBrokerRights')"
                 class="spr-redborder button-width"
                 click-event="removeBroker"
                 @removeBroker="onRemoveBroker"
      />
      <div v-if="$props.volunteer.isGroupManager">
        {{ $t('userAdmin.groupManager') }}
        <font-awesome-icon icon="star" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue'
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'
  import { FriendBrokerDto, FriendRoleDataDto } from '@/types'

  export interface FriendshipBrokerSearchData {
    selected: boolean,
    pending: boolean
  }

  export default Vue.extend({
    name: 'FriendshipBrokerSearchResult',
    components: { sprbutton, loading },
    props: {
      volunteer: {
        type: Object as PropType<FriendBrokerDto | undefined>,
        default: null
      },
      isNetProfile: {
        type: Boolean,
        default: false
      },
      friendManagementGroup: {
        type: Number,
        default: -1
      }
    },
    data (): FriendshipBrokerSearchData {
      return {
        selected: false,
        pending: false
      }
    },
    computed: {
      friendManagementGroupId (): number {
        return this.friendManagementGroup
      },
      setBrokerRoleUrl (): string {
        if (this.isNetProfile === true) {
          return this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.setnetbrokerrole[0]
        }
        return this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.setstandardbrokerrole[0]
      },
      removeBrokerRoleUrl (): string {
        if (this.isNetProfile === true) {
          return this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.revokenetbrokerrole[0]
        }
        return this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.revokestandardbrokerrole[0]
      },
      brokerRoleData (): FriendRoleDataDto {
        if (this.isNetProfile === true) {
          return {
            userId: this.$props.volunteer.userId,
            friendManagementGroupId: this.friendManagementGroupId
          }
        }
        return {
          userId: this.$props.volunteer.userId,
          friendManagementGroupId: this.friendManagementGroupId
        }
      }
    },
    methods: {
      onMakeBroker (): void {
        var url = this.setBrokerRoleUrl
        var postData = this.brokerRoleData

        this.$http.post(url, postData).then(s => {
          this.$bus.$emit('user-search-research')
          this.$props.volunteer.isBroker = true
        }, f => {
          console.log('errors', f)
        })
      },
      onRemoveBroker (): void {
        var uri = this.removeBrokerRoleUrl
        var postData = this.brokerRoleData
        this.$http.post(uri, postData).then(s => {
          this.$bus.$emit('user-search-research')
          this.$bus.$emit('broker-search-research')
        }, f => {
          console.log('errors', f)
        })
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';

  .comp-search-result {
    background: $brand-white;
    border: 2px solid $brand-white;
    &:hover

  {
    border: 2px solid $brand-red;
  }

  .portrait-size {
    height: 40px;
    width: 40px;
    margin: 10px;
  }

  .grid-row {
    display: grid;
    display: -ms-grid;
    grid-template-rows: 60px;
    -ms-grid-rows: 60px;
    grid-template-columns: 1fr 120px;
    -ms-grid-columns: 1fr 120px;
  }

  .grid-name {
    grid-column: 1;
    -ms-grid-column: 1;
    line-height: 60px;
    font-size: 13px;
    letter-spacing: 0.3px;
    font-weight: 700;
    color: $brand-red;
    text-transform: uppercase;
    font-family: "Signa-Light", Sans-Serif;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .actions {
    padding-top: 7px;
  }

  svg {
    color: $brand-red;
    padding-top: 10px;
  }
}
</style>
