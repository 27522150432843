<template>
  <div class="fill-width comp-wizard-content">
    <div>
      <span class="h1"
            role="heading"
            aria-level="1"
      >{{ $t('wizard.headerThree') }}</span><br>
      <p>{{ $t('wizard.ingressLanguages') }}</p>
    </div><br>
    <sprcheckbox v-for="(language, index) in languages"
                 :key="'wizard_3_' + index"
                 :value="language"
                 :title="normalizeTranslation(language)"
                 click-event="box-selected"
                 :collection="$store.state.wizardModel.spokenLanguages"
                 @box-selected="handleCheckbox"
    /><br>

    <sprlanguageselector select-event="custom-language"
                         class="dropdown spr-roundbox-1"
                         @custom-language="handleCheckbox"
    />
  </div>
</template>

<script>
  import sprcheckbox from '../SPRCheckbox.vue'
  import sprlanguageselector from '../SPRLanguageSelector.vue'

  export default {
    name: 'WizardOne',
    components: { sprcheckbox, sprlanguageselector },
    data () {
      return {
        selected: this.$store.state.wizardModel.spokenLanguages
      }
    },
    computed: {
      registrationLanguages: function () {
        return this.$store.state.registrationLanguages
      },
      languages: function () {
        return _.union(this.registrationLanguages, this.selected)
      }
    },
    methods: {
      handleCheckbox: function (value, isChecked) {
        if (isChecked) {
          if (this.selected.indexOf(value) === -1) {
            this.selected.push(value)
            this.vuixCommit()
          }
        } else {
          var index = this.selected.indexOf(value)
          if (index !== -1) {
            this.selected.splice(index, 1)
            this.vuixCommit()
          }
        }
      },
      vuixCommit: function () {
        this.$store.commit('setWizardLanguages', this.selected)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

  .dropdown {
    margin: 30px auto;
    max-width: 300px;
  }
</style>
