<template>
  <adhandler />
</template>

<script>
  import adhandler from '@/components/ad/AdStateMachine.vue'
  export default {
    name: 'AdSignin',
    components: { adhandler }
  }
</script>
