<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .myparticipatedevents {

   .event-list {
     margin-top: 30px;
   }

   .actions {
     margin-top: 30px;
     margin-bottom: 30px;
   }

   .no-events {
     background-color:$brand-white;
     text-align: center;
     padding-top:30px;
     padding-bottom: 30px;
     margin-bottom: 30px;
   }
  }
</style>

<template>
  <div class="myparticipatedevents col-12">
    <backbar :title="$t('eventPage.myParticipatedEvents')" />
    <div class="container">
      <div class="row event-list">
        <div v-for="evt in myevents" :key="evt.id" class="col-sm-12 col-md-6 col-lg-4">
          <eventtile :sprevent="evt" />
        </div>
      </div>
      <div v-if="!myevents || myevents.length == 0" class="row no-events">
        <div class="col-12">
          <span>{{ $t('eventPage.noEventsFound') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import backbar from '../BackBar.vue'
  import eventtile from './EventTile.vue'
  export default {
    name: 'MyParticipatedEvents',
    components: {
      backbar,
      eventtile
    },
    data () {
      return {
        myevents: []
      }
    },
    created () {
      this.getEvents()
    },
    methods: {
      getEvents () {
        var url = this.$store.state.apiBases.eventservice + this.$store.state.apiEndpoints.eventparticipated[0]
        this.$http.get(url).then(response => {
          this.myevents = response.body
        }, response => {
          console.log('Error loading')
        })
      }
    }
  }
</script>
