<template>
  <div class="radio-button-group">
    <baseradiobutton v-for="(element, index) in elements" :key="element" :option="index + 1" :title="$t(translationKey + element) " :class="calculatedClass" />
  </div>
</template>

<script>

  import baseradiobutton from './BaseRadioButton.vue'

  export default {
    name: 'BaseRadioButtonGroup',
    components: { baseradiobutton },
    props: {
      options: {
        required: true,
        type: Array
      },
      translationKey: {
        type: String,
        default: ''
      }
    },
    computed: {
      elements: function () {
        if (this.options) {
          return this.options
        }
        return []
      },
      calculatedClass () {
        return 'col-12 col-sm-' + Math.floor(12 / 2)
      }
    }
  }
</script>

<style>

 .radio-button-group {
    color: white;
    width: 100%;
    border-radius: 8px;
    display: block;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
}

</style>