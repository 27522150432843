
<template>
  <div class="product-admin">
    <div v-if="products">
      <product v-for="(product, i) in products"
               :key="i"
               class="row"
               :product="product"
               @edit="editProduct"
      />
    </div>
    <div v-else class="loading text-center brand-red">
      <div class="my-2">
        <loading />
      </div>
      <div class="my-3">
        <p>{{ $t('userAdmin.loading') }}</p>
      </div>
    </div>
    <div class="row create-row">
      <div class="col-12">
        <sprbutton type="primary"
                   :size="1"
                   :title="$t('payment.newProduct')"
                   click-event="create"
                   @create="createProduct"
        />
      </div>
    </div>

    <!-- editor modal -->
    <b-modal v-model="editing">
      <div v-if="current" class="edit-form">
        <div class="row">
          <sprinput v-model="current.code"
                    class="col-12"
                    :hint="$t('payment.productCode')"
                    :placeholder="$t('payment.productCodeHint')"
          />
        </div>
        <div class="row">
          <sprnumber :value="current.priceCents"
                     class="col-6"
                     :title="$t('payment.priceCents')"
                     :min="0"
                     @input="handlePriceChange"
          />
          <sprnumber :value="current.vat"
                     class="col-6"
                     :title="$t('payment.vatPercent')"
                     :min="0"
                     :max="100"
                     @input="handleVatChange"
          />
        </div>
        <div class="row">
          <div class="col-12">
            <checkbox v-model="current.annual"
                      title="Vuosittainen maksu"
            />
          </div>
        </div>
        <div class="row">
          <sprinput v-model="current.descriptionFin"
                    class="col-12"
                    hint="Kuvaus"
                    placeholder="Tuotteen kuvaus suomeksi"
          />
        </div>
        <div class="row">
          <sprinput v-model="current.descriptionSwe"
                    class="col-12"
                    hint="På svenska"
                    placeholder="Tuotteen kuvaus ruotsiksi"
          />
        </div>
        <div class="row">
          <sprnumber v-model="current.minAge"
                     class="col-6"
                     title="Ikä vähintään"
                     :min="0"
          />
          <sprnumber v-model="current.maxAge"
                     class="col-6"
                     title="Ikä enintään"
                     :min="0"
                     :max="150"
          />
        </div>
        <div class="row">
          <div class="col-12">
            <checkbox v-model="current.disabled"
                      :title="$t('payment.productDisabled')"
            />
          </div>
        </div>
      </div>
      <div slot="modal-header">
        <h3 v-if="current && current.id">
          {{ $t('payment.manageProductHeader') }}
        </h3>
        <h3 v-else>
          {{ $t('payment.createProductHeader') }}
        </h3>
      </div>
      <div slot="modal-footer">
        <div class="row">
          <div class="col-12 text-right">
            <sprbutton type="primary"
                       :size="1"
                       :title="$t('userAdmin.editCancel')"
                       click-event="cancel"
                       @cancel="cancelChanges"
            />
            <sprbutton type="primary"
                       :size="1"
                       :title="$t('userAdmin.editSave')"
                       click-event="save"
                       @save="saveChanges"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  import sprinput from '../SPRSimpleText.vue'
  import sprnumber from '../SPRSimpleNumberInput.vue'
  import checkbox from '../SPRSimpleCheckbox.vue'
  import product from './ProductView.vue'
  import loading from '../SPRLoading.vue'
  import { SprPaymentProduct } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    current: SprPaymentProduct | null,
    products: Array<SprPaymentProduct> | null
  }

  export default Vue.extend({
    name: 'ProductAdmin',
    components: { sprbutton, sprinput, sprnumber, product, checkbox, loading },
    data (): TemplateComponentData {
      return {
        current: null as SprPaymentProduct | null,
        products: null as Array<SprPaymentProduct> | null
      }
    },
    computed: {
      editing (): boolean {
        return this.current !== null
      }
    },
    mounted (): void {
      this.getProducts()
    },
    methods: {
      handlePriceChange (val: string) {
        // This is a hack. val is a string, no matter what I tried, so here I have to declare a method to convert it to the proper type
        if (this.current) { this.current.priceCents = parseFloat(val) }
      },
      handleVatChange (val: string) {
        // This is a hack. val is a string, no matter what I tried, so here I have to declare a method to convert it to the proper type
        if (this.current) { this.current.vat = parseFloat(val) }
      },
      editProduct (product: SprPaymentProduct) {
        this.current = product
      },
      createProduct () {
        this.newProduct()
      },
      cancelChanges () {
        this.current = null
      },
      newProduct (): SprPaymentProduct {
        return {
          code: '',
          disabled: false,
          id: 0,
          priceCents: 100,
          vat: 0,
          descriptionFin: '',
          descriptionSwe: '',
          annual: false,
          minAge: null,
          maxAge: null
        }
      },
      getProducts (): void {
        const productsUrl = `${this.$store.state.apiBases.paymentservice + this.$store.state.apiEndpoints.producteditor[0]}`
        this.$http.get(productsUrl)
          .then(success => {
            // @ts-ignore
            this.$data.products = success.body
          }, failure => {
            console.log('failed fetching products', failure)
          })
      },
      saveChanges () {
        const editUrl = `${this.$store.state.apiBases.paymentservice + this.$store.state.apiEndpoints.productsave[0]}`

        var toSave = this.current
        this.products = null
        this.current = null

        this.$http.put(editUrl, toSave)
          .then(() => this.getProducts(), () => this.getProducts())
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .product-admin {
    background: $brand-white;
    padding: 30px 15px;

    .create-row {
      margin-top: 30px;
    }
  }
</style>
