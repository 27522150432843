<template>
  <div :id="`sectioncontacts${_uid}`" class="sectioncontacts">
    <div class="editor-header">
      <!-- This is intentionally <a> element. This is the proper way to
      enable keyboard navigation for the element, believe it or not. -->
      <a
        v-show="!isEditMode"
        class="cap-label"
        tabindex="0"
        href="javascript:void(0);"
        @click="toggleEdit"
      >
        {{ title }}
        <font-awesome-icon icon="pencil-alt" aria-hidden="true" />
      </a>
      <a
        v-show="isEditMode"
        class="cap-label action-icon"
        tabindex="0"
        href="javascript:void(0);"
        @click="toggleEdit"
      >
        {{ title }}
        <font-awesome-icon icon="check" class="saveChanges" aria-hidden="true" />
      </a>
      <a
        v-show="isEditMode"
        class="cap-label"
        tabindex="0"
        href="javascript:void(0);"
        @click="cancelChanges"
      ><font-awesome-icon icon="times" aria-hidden="true" /></a>
    </div>
    <div v-if="isEditMode" class="row-1 row pt-3 editor-area">
      <div class="col-12">
        <label
          v-if="hasDefinition || isEditMode"
          class="cap-label"
          :class="{ 'is-edit': isEditMode }"
        >{{ $t("userAdmin.sectionReportContact") }}</label>
      </div>
    </div>
    <div v-if="isEditMode" class="row editor-area">
      <div class="col-9 col-md-6">
        <div class="emailinput">
          <sprinput
            v-model="inputEmail"
            v-validate="'email'"
            input-type="email"
            :placeholder="placeholdertext"
            name="sectioncontacts.contactemail"
            :required="false"
            :is-valid="!errors.has('sectioncontacts.contactemail')"
          />
        </div>
      </div>
      <div class="col-3 col-md-6">
        <sprbutton
          :size="5"
          :title="$t('userAdmin.sectionReportContactAdd')"
          click-event="addContact"
          class="add-button"
          @addContact="onAddContact"
        />
      </div>
    </div>
    <div class="row">
      <div v-if="contacts" class="col-12">
        <div v-if="isEditMode" class="col-12 editor-area">
          <span v-if="isEditMode">
            <pill
              v-for="contact in sectionContacts"
              :key="contact"
              :value="contact"
              :show-delete="true"
              @remove="onRemoveContact(contact)"
            />
          </span>
        </div>
        <div v-else class="onlyContactsInfo">
          <div>{{ contactsReadOnly }}</div>
        </div>
      </div>
    </div>
    <div class="row pt-3">
      <div v-if="contacts" class="col-12 col-sm-9 col-md-6">
        <div v-if="isEditMode" class="editor-area">
          <span v-if="isEditMode" class="frequencySelector editor-area">
            <div class="frequency">{{ $t("userAdmin.sectionReportFrequency") }}</div>
            <sprradiogroupkey
              ref="frequency"
              v-model="frequency"
              radio-group-color="basic"
              :options="reportFrequencies"
            />
          </span>
        </div>
        <div v-else class="onlyFrequencyDefinition">
          <dl>
            <dt>{{ $t("userAdmin.sectionReportFrequency") }}</dt>
            <dd>{{ frequencyDefinition }}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import sprinput from '../SPRSimpleText.vue'
  import sprradiogroupkey from '../SPRRadioGroupKey.vue'
  import pill from '../SPRPill.vue'

  export default {
    name: 'SectionContacts',
    components: {
      sprbutton,
      sprinput,
      sprradiogroupkey,
      pill
    },
    props: {
      sectionId: {
        type: Number,
        default: 0
      },
      title: {
        type: String,
        default: ''
      },
      contacts: {
        type: Array,
        default () {
          return []
        }
      },
      reportFrequency: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        isEditMode: false,
        inputEmail: null,
        initialEmails: [],
        sectionContacts: [],
        frequency: 7, // Weekly
        sectionReportDto: null
      }
    },
    computed: {
      isValid () {
        if (this.sectionContacts.length > 0) {
          if (this.frequency === 0) {
            return false
          }
        }

        return true
      },
      hasDefinition () {
        return this.contacts.length > 0 || this.sectionContacts.length > 0
      },
      hasChanges () {
        const initial = JSON.stringify(this.initialDefinition)
        const current = JSON.stringify(this.currentDefinition)

        return initial !== current
      },
      initialDefinition () {
        let sectionReportDto = {
          id: this.sectionId,
          contacts: [],
          reportFrequency: this.reportFrequency
        }

        const sectionContacts = this.initialEmails.map(this.toContact)
        sectionReportDto = {
          id: this.sectionId,
          contacts: sectionContacts,
          reportFrequency: this.reportFrequency
        }

        return sectionReportDto
      },
      currentDefinition () {
        if (this.frequency === null) {
          this.setDefaultFrequency()
        }

        let sectionReportDto = {
          id: this.sectionId,
          contacts: [],
          reportFrequency: this.frequency
        }

        const sectionContacts = this.sectionContacts.map(this.toContact)
        sectionReportDto = {
          id: this.sectionId,
          contacts: sectionContacts,
          reportFrequency: this.frequency
        }

        // todo: this should not be in a computed prop!
        this.sectionReportDto = sectionReportDto // eslint-disable-line vue/no-side-effects-in-computed-properties

        return sectionReportDto
      },
      frequencyDefinition () {
        if (this.hasDefinition) {
          return this.currentReportFrequency
        }

        return this.$t('userAdmin.sectionReportNoDefinition')
      },
      currentReportFrequency () {
        var self = this
        const enums = _.map(this.$store.state.reportFrequency, function (val, key) {
          return {
            value: val.id,
            text: self.$t('userAdmin.sectionReportFrequency' + val.text)
          }
        })

        const item = enums.filter((x) => {
          return x.value === this.reportFrequency
        })

        return item ? item[0].text : null
      },
      reportFrequencies: function () {
        var self = this
        return _.map(this.$store.state.reportFrequency, function (val, key) {
          return {
            value: val.id,
            text: self.$t('userAdmin.sectionReportFrequency' + val.text)
          }
        })
      },
      reportFrequencyTexts: function () {
        return _.map(this.$store.state.reportFrequency, function (val, key) {
          return val.text
        })
      },
      parentNode: function () {
        return document.getElementById(`sectioncontacts${this._uid}`)
      },
      placeholdertext () {
        return this.$t('userAdmin.sectionReportContact')
      },
      contactsReadOnly () {
        if (this.contacts) {
          return this.contacts.map(this.toEmail).join('; ')
        }

        return ''
      },
      filteredContacts () {
        return _.map(_.groupBy(this.sectionContacts, 'email'), (emailGroup) => {
          if (emailGroup.length === 1) {
            return emailGroup[0]
          } else {
            return _.filter(emailGroup, (contact) => {
              return contact.email
            })[0]
          }
        })
      }
    },
    beforeDestroy: function () {
      window.removeEventListener('focus', this.handleBlur)
      window.removeEventListener('click', this.detectClicks)
    },
    mounted () {
      window.addEventListener('focus', this.handleBlur, true)
      window.addEventListener('click', this.detectClicks, true)
      this.resetLocals()
    },
    methods: {
      cancelChanges () {
        this.resetLocals()
        this.isEditMode = false
      },
      contactsEditButton () {
        this.toggleEdit()
      },
      detectClicks: function (event) {
        this.handleBlur(event)
      },
      handleBlur: function (event) {
        if (
          this.isEditMode &&
          event.target &&
          this.parentNode &&
          event.target.top === undefined &&
          !this.parentNode.contains(event.target)
        ) {
          this.toggleEdit()
        }
      },
      onAddContact () {
        this.$validator.validateAll().then((resp) => {
          if (resp) {
            const email = this.inputEmail.toLowerCase()
            if (!this.sectionContacts.find((contact) => contact.toLowerCase() === email)) {
              this.sectionContacts.push(email)
            }
          }
          this.inputEmail = null
        })
      },
      onRemoveContact (contact) {
        this.sectionContacts = this.sectionContacts.filter((x) => x !== contact)
      },
      resetLocals () {
        this.inputEmail = null
        this.initialEmails = this.contacts.map(this.toEmail) || []
        this.sectionContacts = this.contacts.map(this.toEmail) || []
        this.frequency = this.reportFrequency
      },
      setDefaultFrequency () {
        this.frequency = 7 // Weekly
      },
      toContact: function (item) {
        var contact = {
          sectionId: this.sectionId,
          email: item
        }

        return contact
      },
      toEmail: function (contact) {
        const value = contact.email
        return value
      },
      toggleEdit: function () {
        this.isEditMode = !this.isEditMode

        if (this.isEditMode) {
          // opening edit mode
          this.resetLocals()
        } else {
          // closing edit mode
          this.sectionReportDto = this.currentDefinition

          if (this.hasChanges) {
            if (!this.isValid) {
              this.isEditMode = true
              return
            }
            this.$emit('commitChanges', this.sectionReportDto)
          }
        }
      }
    }
  }
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";
.sectioncontacts {
  .pill {
    margin-right: 5px;

    &.readonly {
      border-color: $brand-grey7;
      color: $brand-grey7;
      background-color: $brand-grey1;
      cursor: not-allowed;
    }
  }

  .frequencySelector {
    .comp-radio-group-key {
      padding-top: 10px;
      .spr-simple-radio {
        padding-left: 20px;
      }
    }
  }

  .emailinput {
    display: inline-block;
    width: 100%;
  }
  .add-button {
    display: inline-block;
  }
  .is-edit {
    color: $brand-red;
    padding: 0 20px;
  }
  .frequency {
    color: $brand-red;
    font-family: $font-bold;
    padding: 0 20px;
  }
  .editor-header {
    a {
      color: $brand-red;
      font-family: $font-bold;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
    }

    .action-icon {
      color: $brand-red;
      font-family: $font-bold;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
      margin-right: 5px;

      .saveChanges {
        color: $brand-success;
      }
    }
  }

  .editor-area {
    background-color: whitesmoke;

    textarea {
      width: calc(100% - 15px);
      resize: none;
      margin-top: 10px;
      font-family: "Signa-Book", Sans-Serif;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .spr-danger {
    border: 2px solid $brand-red !important;
  }
}
</style>
