<template>
  <div v-if="volunteer" class="volunteerprofilenetform">
    <a name="net"></a>
    <div class="row">
      <div class="col-sm-6 sectionheader">
        <h3>{{ $t('volunteerForm.netFormTitle') }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <forminput v-model="volunteer.shortDescription"
                   v-validate="{max:100}"
                   :hint="$t('volunteerForm.shortDescriptionThirdPerson')"
                   name="volunteerForm.shortDescription"
                   :is-valid="!errors.has('volunteerForm.shortDescription')"
                   :maxlength="100"
        />
      </div>
    </div>

    <!-- Friendship Types -->
    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('volunteerForm.friendshipType') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxlist v-model="volunteer.friendshipTypes"
                      :list="friendshipTypes"
                      :rows="2"
        />
      </div>
    </div>

    <!-- interests -->
    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('volunteerForm.interests') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxlist v-model="volunteer.interests"
                      :list="customerInterests"
                      :rows="3"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.otherInterests"
                   :hint="$t('volunteerForm.otherInterests')"
                   :is-valid="true"
        />
      </div>
    </div>
    <!-- CUSTOMER PREFERENCES -->
    <div class="row">
      <div class="col-12 sectionheader">
        <h3>{{ $t('volunteerForm.friendWishTitle') }}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <checkboxinput v-model="volunteer.multipleCustomers"
                       :title="$t('volunteerForm.multipleCustomers2')"
                       :aria-label="$t('volunteerForm.multipleCustomers2')"
        />
      </div>
    </div>

    <div class="row">
      <!-- gender -->
      <div class="col-12 col-md-6">
        <span class="subheader">{{ $t('volunteerForm.genderPreference') }}*</span>
        <checkboxlist v-model="volunteer.preferredGenders"
                      v-validate="'option-selected'"
                      :list="genders"
                      :rows="1"
                      name="preferredGenderRequired"
                      :is-valid="!errors.has('preferredGenderRequired')"
        />
        <span v-if="errors.has('preferredGenderNotSelected')" class="error-message">{{ $t('validation.preferredGenderRequired') }}</span>
        <span v-else class="error-message">&nbsp;</span>
      </div>
      <!-- Desired age -->
      <div class="col-12 col-md-6">
        <span class="subheader">{{ $t('volunteerForm.desiredAge') }}*</span>
        <checkboxlist v-model="volunteer.preferredAges"
                      v-validate="'option-selected'"
                      :list="preferredAge"
                      :rows="1"
                      name="ageGroupRequired"
                      :is-valid="!errors.has('ageGroupRequired')"
        />
        <span v-if="errors.has('ageGroupNotSelected')" class="error-message">{{ $t('validation.ageGroupNotSelected') }}</span>
        <span v-else class="error-message">&nbsp;</span>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <sprdropdownnumberkey v-model="volunteer.availabilityFrequence"
                              :title="$t('volunteerForm.availabilityFrequency')"
                              :items="availabilityFrequency"
                              :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.availabilityDetails"
                   :hint="$t('volunteerForm.availabilityDetails')"
                   :is-valid="true"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('volunteerForm.customerType') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxlist v-model="volunteer.customerTypes"
                      :list="customerTypes"
                      :rows="2"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 sectionheader">
        <h3>{{ $t('customerForm.netFriendContacts') }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.econtactEmail"
                   :hint="$t('connectivity.email')"
                   :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.econtactTelegram"
                   :hint="$t('connectivity.telegram')"
                   :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.econtactKik"
                   :hint="$t('connectivity.kik')"
                   :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.econtactInstagram"
                   :hint="$t('connectivity.instagram')"
                   :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.econtactSkype"
                   :hint="$t('connectivity.skype')"
                   :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.econtactDiscord"
                   :hint="$t('connectivity.discord')"
                   :is-valid="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <forminput v-model="volunteer.econtactFreetext"
                   :hint="$t('connectivity.freetext')"
                   :is-valid="true"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import forminput from '../SPRSimpleText.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import checkboxlist from '../SPRSimpleCheckboxBitmaskList.vue'
  import sprdropdownnumberkey from '../SPRDropdownNumberKey.vue'
  import enums from '../../mixins/enums.js'
  import scroll from '../../mixins/scroll.js'
  import locationutils from '../../mixins/locationutils.js'
  // import enumhelper from '../../mixins/enums.js'
  import * as _ from 'lodash'
  import { FriendVolunteerProfileNetDto } from '../../types/index'
  import { SprCheckboxBitmaskValue, SprDropDownNumberValue } from '../../types/custom'

  export default Vue.extend({
    name: 'VolunteerProfileNet',
    components: {
      forminput,
      checkboxinput,
      checkboxlist,
      sprdropdownnumberkey
    },
    mixins: [enums, scroll, locationutils],
    model: {
      prop: 'volunteer',
      event: 'update'
    },
    props: {
      volunteer: {
        type: Object as () => FriendVolunteerProfileNetDto,
        required: true
      }
    },
    computed: {
      friendshipTypes (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.netFriendshipTypes.filter(x => x.id !== 0 && x.id !== 32), (val, key) => {
          return { value: val.id, text: this.$t('friendshipTypes.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      customerTypes (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.netCustomerTypes.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('netCustomerTypes.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      customerInterests (): Array<SprCheckboxBitmaskValue> {
        return _.map(this.$store.state.customerInterests.filter(x => x.id !== 0), (val, key) => {
          return { value: val.id, text: this.$t('customerInterests.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      genders (): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.genders, function (val, key) {
          return { value: val.id, text: self.$t('genders.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      preferredAge (this: any): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.preferredAge.filter(x => x.id !== 0), function (val, key) {
          return { value: val.id, text: self.$t('preferredAge.' + val.text) } as SprCheckboxBitmaskValue
        })
      },
      availabilityFrequency (): Array<SprDropDownNumberValue> {
        var self = this
        return _.map(this.$store.state.availabilityFrequency.filter(x => x.id !== 0), function (val, key) {
          return { value: val.id, text: self.$t('availabilityFrequency.' + val.text) } as SprDropDownNumberValue
        })
      }
    },
    mounted (): void {
      this.$nextTick(() => {
        const hashName = this.$route.hash && this.$route.hash.substring(1)
        if (hashName) {
          const hashSelector = `a[name=${hashName}]`
          const el = document.querySelector(hashSelector)
          el && el.scrollIntoView()
        }
      })
    },
    methods: {
      addError (fieldName, translationField): void {
        if (!this.errors.has(fieldName)) { this.$validator.errors.add({ field: fieldName, rule: translationField, msg: 'empty message' }) }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteerprofilenetform {
    background-color:$brand-white;

    .subheader {
      color:$brand-red;
      text-transform: uppercase;
      font-family: $font-bold;
      margin: 0 0 10px 20px;
      display:block;
      font-size: 14px;
    }

    .sectionheader {
      margin: 15px 0;
    }
  }
</style>
