<template>
  <div class="list-group">
    <div v-if="isLoading" class="brand-red text-center col-12 my-5">
      <loading />
    </div>
    <div v-else>
      <div v-for="(item) in orderedList" :key="item.id">
        <volunteerview :volunteer="item" :admin="isAdmin" :is-standard-group="isStandardGroup" @remove="onRemove" />
      </div>
      <div v-if="!list || list.length == 0">
        <span class="novolunteers">{{ $t('groups.noVolunteer') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import loading from '../SPRLoading.vue'
  import volunteerview from './VolunteersView.vue'

  export default {
    name: 'VolunteerList',
    components: { loading, volunteerview },
    props: {
      list: {
        type: Array,
        default: () => []
      },
      isAdmin: {
        type: Boolean,
        default: false
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      isStandardGroup: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      orderedList () {
        return _.orderBy(this.list, [volunteer => volunteer.firstName.toLowerCase()])
      }
    },
    methods: {
      onRemove (volunteer) {
        this.$emit('remove', volunteer)
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
  .novolunteers {
    display:block;
    margin:0 auto;
    text-align:center;
    margin-top: 40px;
  }
</style>
