<template>
  <div class="row groupcreate">
    <div class="column col-12">
      <div class="space-between-row">
        <div class="col-12 col-sm-6 col-md-4">
          <forminput v-model="group.name"
                     v-validate="'required'"
                     :hint="$t('groups.name')"
                     name="group.name"
                     :required="true"
                     :is-valid="!errors.has('group.name')"
          />
        </div>
        <div class="simple-text right-outer">
          <div class="cap-label">
            {{ $t('groups.language') }}
          </div>
          <pillbox :items="languages" :selected="selectedlanguages" @change="onLanguageChanged">
          </pillbox>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <textareainput
            v-model="group.description"
            :rows="4"
            :title="$t('groups.description')"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4">
          <sprdropdownnumberkey
            v-model="group.category"
            v-validate="'required'"
            :title="$t('groups.category')"
            name="group.category"
            :items="groupCategories"
            :required="group.groupType === 1"
            :is-valid="!errors.has('group.category') || group.groupType !== 1"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sprcheckbox
            v-model="group.isAllLocations"
            :title="$t('groups.isAllLocations')"
            :aria-label="$t('groups.isAllLocations')"
          />
        </div>
      </div>
      <!-- Group Organizer -->
      <div v-if="isActionGroup()">
        <div class="row grouporganizer">
          <div class="col-12">
            <groupOrganizer
              v-validate="'hasOptionalGroupOrganizer'"
              :hint="$t('userAdmin.groupOrganizer')"
              name="userAdmin.groupOrganizer"
              :value="group.groupOrganizer"
              :required="false"
              :is-valid="!errors.has('userAdmin.groupOrganizer')"
              @input="onGroupOrganizerChanged"
            />
          </div>
        </div>
      </div>
      <div class="row button-row">
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <sprbutton
              type="button"
              :size="4"
              :title="$t('groups.cancel')"
              class="spr-redborder button-width"
              click-event="cancelSave"
              @cancelSave="onCancelCreate"
            />
            <sprbutton
              type="submit"
              :size="4"
              :title="$t('groups.save')"
              icon="chevron-right"
              class="spr-redborder button-width"
              click-event="createGroup"
              @createGroup="onCreateGroup"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import forminput from '../SPRSimpleText.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import sprcheckbox from '../SPRSimpleCheckbox.vue'
  import sprdropdownnumberkey from '../SPRDropdownNumberKey.vue'
  import groupOrganizer from '../SPRGroupOrganizersDropdown.vue'
  import pillbox from '../common/Pill/PillBox.vue'

  import { Validator } from 'vee-validate'
  import hasOptionalGroupOrganizer from '../../mixins/validator-hasOptionalGroupOrganizer.js'

  Validator.extend('hasOptionalGroupOrganizer', hasOptionalGroupOrganizer)

  export default {
    name: 'GroupCreate',
    components: { sprbutton, textareainput, forminput, sprcheckbox, sprdropdownnumberkey, groupOrganizer, pillbox },
    props: {
      grouptype: {
        type: Number,
        default: 2
      },
      isDistrictGroup: {
        type: Boolean,
        default: false
      },
      isSectionGroup: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        group: {
          name: '',
          description: '',
          groupCreatorId: this.$store.state.profile.profileId,
          members: [],
          isAllLocations: false,
          sectionId: this.isSectionGroup ? Number(this.$route.params.id) : -1,
          districtId: this.isDistrictGroup ? Number(this.$route.params.id) : -1,
          groupType: this.grouptype,
          category: null,
          languages: 0
        },
        languages: [],
        selectedlanguages: [],
        groupCategories: null
      }
    },
    mounted () {
      const lang = this.$store.state.customerLanguages
      this.languages = this.$store.state.groupLanguages.filter((l) => l.id !== 0).map((v) => {
        return { id: v.id, text: 'actionLanguage.' + lang.find((l) => l.displayName.toUpperCase() === v.text.toUpperCase()).isoCode }
      })
      this.selectedlanguages = this.languages.filter((v) => v.id === null || v.id === 0 ? false : (v.id & this.group.languages) === v.id)
    },
    created () {
      this.getCategories()
      this.setDefaultGroupOrganizer()
    },
    methods: {
      onLanguageChanged (e) {
        this.group.languages = e.reduce((b, v) => b + v.id, 0)
        this.selectedlanguages = e
      },
      getCategories () {
        var self = this
        this.groupCategories = _.map(this.$store.state.eventCategories, function (val, key) {
          return { value: val.id, text: self.$t('eventCategory.' + val.text) }
        })
      },
      isActionGroup () {
        return this.group.groupType === 1 // public enum GroupType: 1-ActionGroup, 2-StandardGroup (OpenGroup)
      },
      onGroupOrganizerChanged (groupOrganizer) {
        this.group.groupOrganizer = groupOrganizer
      },
      setDefaultGroupOrganizer () {
        if (this.isActionGroup()) {
          if (this.group.sectionId === -1) {
            this.group.groupOrganizer = {
              groupOrganizerType: 100, // public enum GroupOrganizerType: Section = 200
              organizerId: this.group.districtId
            }
          } else {
            this.group.groupOrganizer = {
              groupOrganizerType: 200, // public enum GroupOrganizerType: Section = 200
              organizerId: this.group.sectionId
            }
          }
        } else {
          this.group.groupOrganizer = {
            groupOrganizerType: -1, // public enum GroupOrganizerType: NotSet = -1
            organizerId: null
          }
        }
      },
      onCreateGroup () {
        this.$validator.validateAll().then(resp => {
          if (resp) {
            this.$emit('save', this.group)
          }
        })
      },
      onCancelCreate () {
        this.$emit('cancel', this.group)
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .space-between-row{
    display: flex;
    justify-content: space-between;
  }
  .groupcreate {
    background-color:$brand-white;
    padding: 20px 0;

    .button-row {
      button:first-child {
        margin-right:5px;
      }
    }
  }
</style>
