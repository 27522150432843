<template>
  <div v-if="localCustomer" class="interestinfo">
    <div class="row">
      <div class="col-12">
        <!-- customer interests section-->
        <div class="row headertitle">
          <div class="col-12">
            <h3>{{ $t('customerForm.myInterests') }}</h3>
            <span class="cap-label">{{ $t('customerForm.netCustomerInterests') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <checkboxlist v-model="localCustomer.interests"
                          :list="customerInterests"
                          :rows="3"
            />
          </div>
          <div class="col-12">
            <span class="cap-label">{{ $t('customerForm.netCustomerInterestsFreeTextTitle') }}</span>
            <sprtextarea
              v-model="localCustomer.interestOther"
              name="customerForm.interestFreeText"
              :is-valid="true"
              :subtext="$t('customerForm.netCustomerInterestsFreeText')"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="buttons">
          <prevnextbuttons
            @prev="onPrevClicked"
            @next="onNextClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprtextarea from '../SPRSimpleTextArea.vue'
  import checkboxlist from '../SPRSimpleCheckboxBitmaskList.vue'
  import prevnextbuttons from '../SPRPrevNextButtons.vue'
  import { FriendCustomerNetDto } from '@/types/index'
  import { SprCheckboxBitmaskValue, SprCheckboxValue } from '@/types/custom'
  import inpututils from '@/mixins/inpututils'
  import bitmaskhelper from '@/helpers/bitmaskhelper'

  // the data object
  export interface TemplateComponentData {
    localCustomer: FriendCustomerNetDto | undefined
  }

  export default Vue.extend({
    name: 'InterestInfo',
    components: { sprtextarea, checkboxlist, prevnextbuttons },
    mixins: [inpututils],
    props: {
      customer: {
        type: Object as () => FriendCustomerNetDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        localCustomer: undefined
      }
    },
    computed: {
      customerInterests (): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.customerInterests, function (val, key) {
          return {
            value: val.id,
            text: self.$t('customerInterests.' + val.text),
            selected: bitmaskhelper.bitmaskToArray(self.customer.interests).indexOf(val.id) >= 0
          } as SprCheckboxBitmaskValue
        }).filter((x: SprCheckboxBitmaskValue) => x.value !== 0)
      }
    },
    mounted (): void {
      this.localCustomer = this.customer
    },
    methods: {
      onPrevClicked (): void {
        this.$emit('prev', this.localCustomer)
      },
      onNextClicked (): void {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('next', this.localCustomer)
          }
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .interestinfo {
    background-color: $brand-white;
    padding: 15px;
    padding-top: 30px;

    .headertitle {
      margin-bottom: 30px;
    }

    .cap-label {
      color: $brand-red;
    }

    .buttons {
      padding-top: 30px;
    }
  }

</style>