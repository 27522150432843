<template>
  <div class="testenums">
    <h1>Test Enums</h1>
    <div class="title">
      Genders
    </div>
    <div v-for="item in getGenders" :key="item">
      {{ item }}
    </div>
    <div class="title">
      Gender Preferences
    </div>
    <div v-for="item in getGenderPreferences" :key="item">
      {{ item }}
    </div>
    <div class="title">
      Preferred Age
    </div>
    <div v-for="item in getPreferredAge" :key="item">
      {{ item }}
    </div>
    <div class="title">
      Availability Frequency
    </div>
    <div v-for="item in getAvailabilityFrequency" :key="item">
      {{ item }}
    </div>
    <div class="title">
      Availability Times
    </div>
    <div v-for="item in getAvailabilityTimes" :key="item">
      {{ item }}
    </div>
    <div class="title">
      Customer Types
    </div>
    <div v-for="item in getCustomerTypes" :key="item">
      {{ item }}
    </div>
    <div class="title">
      Friendship Types
    </div>
    <div v-for="item in getFriendshipTypes" :key="item">
      {{ item }}
    </div>
    <div class="title">
      Interests
    </div>
    <div v-for="item in getInterests" :key="item">
      {{ item }}
    </div>
    <div class="title">
      Customer States
    </div>
    <div v-for="item in getCustomerStates" :key="item">
      {{ item }}
    </div>
    <div class="title">
      First items in collections
    </div>
    <div>{{ getGenderPreference }}</div>
    <div>{{ getAge }}</div>
    <div>{{ getAvailabilityFreq }}</div>
    <div>{{ getAvailabilityTime }}</div>
    <div>{{ getCustomerType }}</div>
    <div>{{ getFriendshipType }}</div>
    <div>{{ getInterest }}</div>
    <div>{{ getCustomerState }}</div>
  </div>
</template>

<script>
  import enumhelper from '../../mixins/enums.js'
  export default {
    name: 'TestCors',
    mixins: [enumhelper],
    computed: {
      gender () {
        return this.getGenderTranslation(1)
      },
      getGenderPreference () {
        return this.getGenderPreferencesMaskTranslation(1)
      },
      getAge () {
        return this.getPreferredAgeTranslation(1)
      },
      getAvailabilityFreq () {
        return this.getAvailabilityFrequencyTranslation(1)
      },
      getAvailabilityTime () {
        return this.getAvailabilityTimesTranslation(1)
      },
      getCustomerType () {
        return this.getCustomerTypesTranslation(1)
      },
      getFriendshipType () {
        return this.getFriendshipTypesTranslation(1)
      },
      getInterest () {
        return this.getInterestsTranslation(1)
      },
      getCustomerState () {
        return this.getCustomerStatesTranslation(1)
      },

      getGenders () {
        return this.getGendersTranslations()
      },
      getGenderPreferences () {
        return this.getGenderPreferencesMaskTranslations()
      },
      getPreferredAge () {
        return this.getAgePreferencesMaskTranslations()
      },
      getAvailabilityFrequency () {
        return this.getAvailabilityFrequencyTranslations()
      },
      getAvailabilityTimes () {
        return this.getAvailabilityTimesTranslations()
      },
      getCustomerTypes () {
        return this.getCustomerTypesTranslations()
      },
      getFriendshipTypes () {
        return this.getFriendshipTypesTranslations()
      },
      getInterests () {
        return this.getInterestsTranslations()
      },
      getCustomerStates () {
        return this.getCustomerStatesTranslations()
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .testenums {
    margin:15px;
    .title {
      font-weight: bold;
      margin-top:20px;
    }
  }
</style>
