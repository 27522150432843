<template>
  <div class="pager-heading">
    <div class="page-state">
      <pagerstats :total="total" :skip="skip" :take="take"></pagerstats>
    </div>
    <div v-if="pagesizervisible" class="page-sizer">
      <pagesizeselector :take="take" @pagesizechanged="onPageSizeChanged" />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import pagesizeselector from './SPRPageSizeSelector.vue'
  import pagerstats from './SPRPagerStats.vue'

  export default Vue.extend({
    name: 'PagerHeading',
    components: { pagesizeselector, pagerstats },
    props: {
      total: {
        type: Number,
        default: 0
      },
      take: {
        type: Number,
        default: 0
      },
      skip: {
        type: Number,
        default: 0
      },
      pagesizervisible: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onPageSizeChanged (pageSize): void {
        this.$emit('pagesizechanged', pageSize)
      }
    }
  })
</script>

<style lang="scss" >
  @import "../assets/scss/_variables.scss";
  .pager-heading {
    display: flex;
    flex: 1 0 0;
    font-family: Verdana, sans-serif; // Because Signa-Light numbers jump all over the place!
    margin-top: 10px;
    margin-right: -15px;
    margin-left: -15px;
    background: $brand-white;
    align-content: center;
    height: 3rem;
    padding-left: 15px;
    padding-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .page-sizer {
    justify-self: flex-end;
    align-self: center;
    flex: 0 0 auto;
  }
  .page-state {
    justify-self: flex-start;
    align-self: center;
    width: 100%;
  }
  @media (max-width: $media-md) {
    .pager-heading {
      flex-direction: column;
      height: 4.5rem !important;
    }
    .page-state {
      padding-top: 7px;
    }
    .page-sizer {
      align-self: flex-start;
      padding-top: 7px;
    }
  }
  @media (max-width: $media-xs) {
    .pager-heading {
      height: 5rem !important;
    }
  }
</style>
