<template>
  <div class="volunteermatchstandardconfirm">
    <div v-if="isInappropriate">
      <div class="row">
        <div class="col maintitle">
          <span class="title">{{ $t('customerSearch.inappropriate') }}</span>
        </div>
      </div>
      <div class="row instructions">
        <div class="col instructions">
          {{ $t('customerSearch.inappropriateInstructions') }}
        </div>
      </div>
      <div class="row">
        <div class="col buttons">
          <sprbutton :size="4"
                     :title="$t('customerSearch.endInappropriate')"
                     click-event="endinappropriate"
                     class="end-inappropriate-button"
                     @endinappropriate="onEndInappropriate"
          />
        </div>
      </div>
    </div>
    <!-- Not inappropriate match -->
    <div v-else>
      <div class="row">
        <div class="col maintitle">
          <span class="title">{{ $t('customerSearch.matchConfirmTitle') }}</span>
        </div>
      </div>
      <div class="row starscore">
        <div class="col-12">
          <stars :score="score" />
        </div>
      </div>
      <div class="row instructions">
        <div class="col instructions">
          {{ $t('customerSearch.createMatchInstructions') }}
        </div>
      </div>
      <div class="row">
        <div class="col buttons">
          <sprbutton :size="4"
                     :title="$t('customerSearch.suggestButton')"
                     click-event="suggest"
                     class="suggest-button"
                     :disabled="!isActive"
                     @suggest="suggest"
          />
        </div>
      </div>
      <div v-if="!showInappropriate" class="row inappropriate">
        <div class="col-12">
          <a href="javascript:void(0)" @click="markInappropriate">{{ $t('customerSearch.markInnapropriateButton') }}</a>
        </div>
      </div>
      <div v-if="showInappropriate" class="row">
        <div class="col-12">
          <span class="inappropriate-instructions">{{ $t('customerSearch.markInapproprateInstructions') }}</span>
        </div>
        <div class="col-6">
          <a href="javascript:void(0)" class="cancel-inappropriate" @click="cancelInappropriate">{{ $t('customerSearch.cancelInappropriateButton') }}</a>
        </div>
        <div class="col-6">
          <sprbutton :size="4"
                     :title="$t('customerSearch.confirmInappropriateButton')"
                     click-event="confirmInappropriate"
                     class="confirm-inappropriate-button"
                     @confirmInappropriate="confirmInappropriate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import { LocaleMessage } from 'vue-i18n'
  import stars from '../SPRStars.vue'
  import sprbutton from '../SPRButton.vue'
  import { UserProfileDto, FriendCustomerStandardDto, CustomerState } from '@/types/index'
  import { StandardMatchPair } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    suggestionMessage: string | LocaleMessage,
    showInappropriate: boolean
  }

  export default Vue.extend({
    name: 'VolunteerMatchStandardConfirm',
    components: { stars, sprbutton },
    props: {
      customer: {
        type: Object as () => FriendCustomerStandardDto,
        required: true
      },
      volunteer: {
        type: Object as () => UserProfileDto,
        required: true
      },
      score: {
        type: Number,
        required: true
      },
      isInappropriate: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        suggestionMessage: this.$t('customerSearch.suggestionMessage'),
        showInappropriate: false
      }
    },
    computed: {
      isActive (): boolean {
        return (this.volunteer.friendInfo && _.includes([CustomerState.Active, CustomerState.PendingFriend, CustomerState.ActiveFriendship], this.volunteer.friendInfo.standardProfile.state)) &&
          (_.includes([CustomerState.Active, CustomerState.PendingFriend, CustomerState.ActiveFriendship], this.customer.state))
      }
    },
    methods: {
      suggest (): void {
        this.$bus.$emit('initiate-friendship', { volunteer: this.volunteer, customer: this.customer, message: this.suggestionMessage } as StandardMatchPair)
      },
      markInappropriate (): void {
        this.showInappropriate = true
      },
      confirmInappropriate (): void {
        this.$bus.$emit('inappropriate-friendship', { volunteer: this.volunteer, customer: this.customer } as StandardMatchPair)
      },
      cancelInappropriate (): void {
        this.showInappropriate = false
      },
      onEndInappropriate (): void {
        this.$bus.$emit('end-inappropriate-friendship', { volunteer: this.volunteer, customer: this.customer } as StandardMatchPair)
      }
    }
  })
</script>
<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .volunteermatchstandardconfirm {
    text-align: center;

    .maintitle {
      margin: 30px 0 20px 0;
    }

    .title {
      text-transform: uppercase;
      color:$brand-red;
      font-weight: bold;
      display:block;
    }

    .starscore {
      margin-top:50px;
      margin-bottom: 50px;
    }

    .instructions {
      margin-bottom: 20px;
      font-size: 13px;
    }

    .buttons {
      margin-bottom: 20px;
    }

    .inappropriate,
    .cancel {
      margin-bottom: 50px;
      a {
        color:$brand-red;
      }
    }

    .cancel-inappropriate {
      display: flex;
      height: 100%;
      align-items: center;
    }

    .inappropriate-instructions {
      margin-bottom:30px;
      display:block;
      font-size: 13px;
    }

    .message {
      width: 100%;
      margin-bottom:30px;

      textarea {
        width: 100%;
      }
    }
  }
</style>
