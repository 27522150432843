<template>
  <div class="fill-width comp-wizard-content">
    <div>
      <span class="h1"
            role="heading"
            aria-level="1"
      >{{ $t('wizard.headerProfile') }}</span><br>
      <p>{{ $t('wizard.ingressProfile') }}</p>
    </div>
    <div class="row">
      <div class="col-12">
        <sprvalidation class="validation-box"
                       :validation-errors="validationErrors"
        />
      </div>
    </div>

    <!-- Story editor -->
    <div class="row">
      <div class="col col-12">
        <textareainput v-validate="{max:3000}"
                       name="storyField"
                       :is-valid="!errors.has('storyField')"
                       :maxlength="3000"
                       class="story"
                       :value="story"
                       :rows="6"
                       @input="onStoryChange"
        />
      </div>
    </div>

    <!-- profile status -->
    <div class="row">
      <div class="col col-12">
        <div class="profile-state">
          <baseradiobuttongroup ref="profileState"
                                v-model="profileStateRadio"
                                translation-key="profile."
                                :options="statusOptions"
                                radio-group-color="basic"
                                @input="onProfileStateChange"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-10 col-md-6 col-lg-4 offset-sm-1 offset-md-3 offset-lg-4 alreadyinvolved">
      <simplecheckbox
        v-model="AlreadyInvolved"
        :title="$t('wizard.alreadyInvolved')"
        :aria-label="$t('wizard.alreadyInvolved')"
      />
    </div>
    <div v-if="AlreadyInvolved">
      <sprcheckbox v-for="(event, index) in $store.state.involvedEventCategories"
                   :key="'wizard_7_' + index"
                   :value="event.id"
                   :title="$t('eventCategory.' + event.text)"
                   click-event="box-selected"
                   :collection="$store.state.wizardModel.areasOfInvolved"
                   @box-selected="handleCheckbox"
      />
    </div>
  </div>
</template>

<script>
  import sprvalidation from '../SPRValidationError.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import baseradiobuttongroup from '../BaseRadioButtonGroup.vue'
  import simplecheckbox from '../SPRSimpleCheckbox.vue'
  import sprcheckbox from '../SPRCheckbox.vue'

  export default {
    name: 'WizardSeven',
    components: { sprvalidation, textareainput, simplecheckbox, sprcheckbox, baseradiobuttongroup },
    data () {
      return {
        profileState: this.$store.state.wizardModel.profileState,
        validatedFields: ['storyField'],
        registerErrors: [],
        profileStateRadio: 1,
        AlreadyInvolved: false,
        involves: this.$store.state.wizardModel.areasOfInvolved,
        selected: new Set()
      }
    },
    computed: {
      story () {
        return this.$store.state.wizardModel.story
      },
      statusOptions () {
        return _.map(this.$store.state.profileState, function (val, key) {
          return val.id
        })
      },
      validationErrors: function () {
        if (!this.validatedFields) {
          return []
        }

        var arr = []
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['', err.field + '_' + err.rule, ''])
          }
        })
        return arr
      }
    },
    methods: {
      onProfileStateChange: function (value) {
        this.$store.commit('setProfileState', value)
      },
      onProfileState: function (value) {
        this.$store.commit('setProfileState', value)
      },
      onStoryChange: function (value) {
        this.$store.commit('setStory', value)
      },
      validateSection: function () {
        return this.$validator.validateAll()
      },
      handleCheckbox: function (value, isChecked) {
        if (isChecked) {
          this.selected.add(value)
        } else {
          this.selected.delete(value)
        }
        var arr = [...this.selected]
        var sum = 0

        arr.forEach(function (value) {
          sum += value
        })

        this.$store.commit('setWizardInvolved', sum)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

    .alreadyinvolved {
      margin-top: 20px;
      color: $brand-white;
    }

    .story {
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 90%;
      max-width: 650px;

      textarea {
        resize: none;
        font-family: "Signa-Book", Sans-Serif;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .profile-state {
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 90%;
      max-width: 650px;
    }

</style>
