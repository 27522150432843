<template>
  <div class="container acceptinvitation">
    <div v-if="groupLoading">
      <loading />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12 col-md-8 mx-auto">
          <span class="prompt d-block mb-3">{{ $t('groups.invitationPrompt') }}</span>
          <h3 class="d-block">
            {{ group.name }}
          </h3>
          <div>{{ group.description }}</div>
          <div class="spacer" />
        </div>
      </div>
      <div class="row button-row">
        <div class="col-12 col-md-8 mx-auto">
          <sprbutton type="submit"
                     :size="1"
                     :title="$t('groups.acceptInvitation')"
                     icon="chevron-right"
                     class="spr-redborder button-width"
                     click-event="joinGroup"
                     @joinGroup="acceptInvitation"
          />
          <div class="spacer" />
          <sprbutton type="submit"
                     :size="1"
                     :title="$t('groups.declineInvitation')"
                     icon="chevron-right"
                     class="spr-redborder button-width inline"
                     click-event="removeInvitation"
                     @removeInvitation="removeInvitation"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'

  export default {
    name: 'Acceptinvitation',
    components: { sprbutton, loading },
    data () {
      return {
        groupLoading: false,
        group: {
          description: ''
        }
      }
    },
    mounted () {
      this.loadGroup()
    },
    methods: {
      removeInvitation () {
        const self = this
        const payload = {
          payload1: this.$route.params.id,
          payload2: this.$store.state.profile.profileId
        }

        const uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupremmembershiprequest[0]
        this.$http.post(uri, payload).then(s => {
          this.$router.push('/')
        }, f => {
          self.inProgress = false
        })
      },
      acceptInvitation () {
        this.groupLoading = true
        var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupacceptinvitation[0]}`.replace('{0}', this.$route.params.id)
        this.$http.put(url, null).then((resp) => {
                                         this.groupLoading = false
                                         this.$router.push(`/groups/group/${this.$route.params.id}`)
                                       },
                                       (error) => {
                                         console.log('accept invitation failed', error)
                                         this.groupLoading = false
                                       })
      },
      loadGroup () {
        var self = this
        var groupId = this.$route.params.id

        if (!groupId) this.$router.push('/profile')

        // load group
        var url = `${self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupgetbyidlight[0]}?id=${groupId}`
        this.$http.get(url).then((resp) => {
          self.group = resp.body
        }, fail => {
          if (fail.status === 403) {
            this.showAlert403()
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .acceptinvitation {
    min-height: calc(100vh - 230px);
    text-align: center;
    padding-top: 50px;

    .spacer {
      min-height: 20px
    }

    .prompt {
      font-size: 1.3rem;
    }

    @media (min-width: $media-md) {
      padding-top: 150px;
    }
  }
</style>