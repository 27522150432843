<template>
  <div class="row comp-search-result my-2">
    <div class="col-12 grid-row" @click="clicked">
      <div class="grid-img">
        <sprportrait :style="portraitStyle"
                     class="portrait-size"
        />
      </div>
      <div class="grid-name">
        <font-awesome-icon v-if="open" icon="chevron-down" class="brand-red smallView" aria-hidden="true" />
        <font-awesome-icon v-else icon="chevron-up" class="brand-red smallView" aria-hidden="true" />
        {{ volunteer.firstName }} {{ volunteer.lastName }}
      </div>
      <div class="grid-contacted">
        <span v-if="showContacted && volunteer.contactedDate"><font-awesome-icon icon="check-circle" /><span class="contactedtext">{{ $t('profile.contacted') }}</span></span>
      </div>
      <div class="grid-createddate">
        <font-awesome-icon icon="calendar" />
        {{ createDateFormat(volunteer.created) }}
      </div>
      <div class="grid-zip">
        <font-awesome-icon icon="map-marker-alt" />
        {{ volunteer.workingZipCode }}
        <font-awesome-icon v-if="open" icon="chevron-down" class="brand-red" aria-hidden="true" />
        <font-awesome-icon v-else icon="chevron-up" class="brand-red" aria-hidden="true" />
      </div>
    </div>
    <div v-show="open" class="col-12 info">
      <div class="row">
        <div class="col-sm-6 col-12">
          <dl>
            <dd>{{ profileState }}</dd>
            <dt>{{ $t('wizard.preferredContactMethod') }}</dt>
            <dd>{{ profilePreferredContactMethods }}</dd>
            <!-- Profile.InvolvedEventCategories -->
            <dt>{{ $t('wizard.alreadyInvolved') }}</dt>
            <dd>{{ profileInvolvedEventCategories }}</dd>
            <dt>{{ $t('profile.email') }}</dt>
            <dd>{{ volunteer.email }}</dd>
            <dt>{{ $t('profile.phoneNumber') }}</dt>
            <dd>{{ volunteer.phoneNumber }}</dd>
            <dt>{{ $t('profile.address') }}</dt>
            <dd>
              {{ volunteer.streetAddress }},
              {{ volunteer.zipCode }}
              {{ volunteer.city }}
            </dd>
            <dt>{{ $t('profile.dateOfBirth') }}</dt>
            <dd>{{ formatDate(volunteer.age) }}</dd>
          </dl>
        </div>
        <div class="col-sm-6 col-12">
          <dl>
            <dt>{{ $t('adminUserEditor.workingZipCode') }}</dt>
            <dd>{{ volunteer.workingZipCode }}</dd>
            <dt>{{ $t('adminUserEditor.userCreated') }}</dt>
            <dd>{{ createDateFormat(volunteer.created) }}</dd>
            <dt>{{ $t('adminUserEditor.volunteerOnline') }}</dt>
            <dd>{{ formatBoolean(volunteer.volunteerOnline) }}</dd>
            <dt>{{ $t('customerSearch.myTimes') }}</dt>
            <dd>{{ availabilityTimes }}</dd>
            <dt>{{ $t('transportation.title') }}</dt>
            <dd>{{ transportation }}</dd>
            <dt>{{ $t('profile.travelTime') }}</dt>
            <dd>{{ volunteer.travelTime }} min</dd>
          </dl>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <dl>
            <dt>{{ $t('profile.nativeLanguage') }}</dt>
            <dd>{{ normalizeTranslation(volunteer.nativeLanguage) }}</dd>
            <dt>{{ $t('nonIdentProfileForm.languageSpoken') }}</dt>
            <dd>{{ spokenLanguages }}</dd>
            <dt>{{ $t('profile.interestEditorHeader') }}</dt>
            <dd>{{ volunteerInterests }}</dd>
            <dt>{{ $t('profile.skillEditorHeader') }}</dt>
            <dd>{{ volunteerSkills }}</dd>
            <dt>{{ $t('profile.motto') }}</dt>
            <dd>{{ motto }}</dd>
            <dt>{{ $t('profile.story') }}</dt>
            <dd>{{ story }}</dd>
            <dt>{{ $t('groups.actionGroupsTitle') }}</dt>
            <dd>{{ actionGroups }}</dd>
            <dt>{{ $t('groups.standardGroupsTitle') }}</dt>
            <dd>{{ standardGroups }}</dd>
          </dl>
        </div>
        <div class="col-12 col-sm-6">
          <div v-if="roleLoadState < 2" class="text-center">
            <loading />
          </div>
          <div v-if="roleLoadState >= 2">
            <h6>Punaisen Ristin jäsenyystiedot</h6>
            <dl v-if="member">
              <MembershipData :member="member"></MembershipData>
              <dt>{{ $t('profile.roles') }}</dt>
              <dd>{{ userRoles }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('profile.rcCourses') }}</dt>
              <dd v-if="trainText">
                {{ trainText }}
              </dd>
              <dd v-else>
                -
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <sprbutton type="primary" :size="1" :title="$t('userAdmin.editUser')"
                   class="d-inline-block my-2" click-event="edit" @edit="editClick"
        />
        <sprbutton v-if="showContacted && !volunteer.contactedDate" type="primary" :size="1" :title="$t('userAdmin.markContacted')"
                   class="d-inline-block my-2" click-event="markContacted" @markContacted="markContactedClick"
        />
        <sprbutton v-if="showContacted && volunteer.contactedDate" type="primary" :size="1" :title="$t('userAdmin.markUnContacted')"
                   class="d-inline-block my-2" click-event="markUnContacted" @markUnContacted="markUnContactedClick"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import sprportrait from '../SPRPortrait.vue'
  import loading from '../SPRLoading.vue'
  import enumhelper from '../../mixins/enums.js'
  import cache from '../../mixins/cache.js'
  import inpututils from '../../mixins/inpututils.js'
  import moment from 'moment'
  import MembershipData from '../MembershipData.vue'
  import { CrmDataDto, UserProfileDto } from '@/types/index'
  import { TranslateResult } from 'vue-i18n'
  import bitmaskhelper from '@/helpers/bitmaskhelper'


  // the data object
  export interface TemplateComponentData {
    open: boolean,
    roleLoadState: number,
    roles: Array<string> | undefined,
    member: CrmDataDto | undefined,
    train: Array<string> | undefined
  }

  export default Vue.extend({
    name: 'UserSearchResult',
    components: { sprbutton, sprportrait, loading, MembershipData },
    mixins: [cache, enumhelper, inpututils],
    props: {
      volunteer: {
        type: Object as () => UserProfileDto,
        default: null
      },
      showContacted: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        open: false,
        roleLoadState: 0,
        roles: [] as Array<string>,
        member: undefined,
        train: undefined
      }
    },
    computed: {
      profileInvolvedEventCategories (): TranslateResult | string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getProfileInvolvedEventCategoriesMaskTranslation(this.volunteer?.involvedEventCategories)
      },
      profilePreferredContactMethods (): TranslateResult | string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getProfilePreferredContactMethodsMaskTranslation(this.volunteer?.preferredContactMethods)
      },
      trainText (): string | null {
        // @ts-ignore
        return this.parseArray(this.train) || null
      },
      userRoles (): string {
        if (this.roles && this.roles.length) {
          const self = this
          const mapped = _.map(this.roles, function (x) {
            // @ts-ignore cannot find mixins
            return self.$t('roles.' + self.lcFirst(x))
          })
          return mapped.join(', ')
        }
        return '-'
      },
      portraitStyle (): string {
        const portrait = this.volunteer && this.volunteer.portrait ? this.volunteer.portrait : require('../../assets/img/anon-portrait.png')
        return 'background: url(' + portrait + '); background-size: cover; background-position: center center;'
      },
      volunteerInterests (): string {
        // @ts-ignore cannot find mixins
        return this.parseArray(this.volunteer.areasOfInterest, this.$store.state.profileAreasOfInterest, 'interest') || '-'
      },
      volunteerSkills (): string {
        // @ts-ignore cannot find mixins
        return this.parseArray(this.volunteer.personalSkills, this.$store.state.profilePersonalSkills, 'skills') || '-'
      },
      profileState (): TranslateResult | string | null {
        // this.volunteer.profileState
        // profile.activeNoContact
        // profile.activeNoNew
        // profile.activeOpen
        // profile.inactive
        switch (this.volunteer.profileState) {
        case 1:
          return this.$t('profile.activeOpen')
        case 2:
          return this.$t('profile.activeNoContact')
        case 3:
          return this.$t('profile.activeNoNew')
        case 4:
          return this.$t('profile.inactive')
        }
        return null
      },
      motto (): string {
        return this.volunteer.motto || '-'
      },
      story (): string {
        return this.volunteer.story || '-'
      },
      spokenLanguages (): string {
        if (this.volunteer.spokenLanguages && this.volunteer.spokenLanguages.length) {
          const self = this
          const langs = []
          this.volunteer.spokenLanguages.forEach(function (l) {
            // @ts-ignore cannot find mixins
            langs.push(self.normalizeTranslation(l))
          })
          return langs.join(', ')
        }
        return '-'
      },
      transportation (): string {
        // @ts-ignore cannot find mixins
        return this.parseArray(this.volunteer.transportation, this.$store.state.transportation, 'transportation') || '-'
      },
      availabilityTimes (): string {
         let bmArr = bitmaskhelper.bitmaskToArray(bitmaskhelper.translateEnumsToBitmask(this.volunteer.availabilityTimes!))
        // @ts-ignore cannot find mixins
        //return this.parseArray(this.volunteer.availabilityTimes, this.$store.state.availabilityTimes, 'availabilityTimes') || '-'
        // @ts-ignore cannot find mixins
        return this.parseArray(bmArr, this.$store.state.availabilityTimes, 'availabilityTimes') || '-'
      },
      actionGroups (): string {
        // @ts-ignore cannot find mixins
        return this.parseArray(this.volunteer.actionGroups) || '-'
      },
      standardGroups (): string {
        // @ts-ignore cannot find mixins
        return this.parseArray(this.volunteer.standardGroups) || '-'
      }
    },
    mounted (): void {
      this.$bus.$on('clicked-search-result', this.handleClicked)
    },
    beforeDestroy (): void {
      this.$bus.$off('clicked-search-result')
    },
    methods: {
      formatBoolean (val): string {
        if (val != null) {
          // @ts-ignore cannot find mixins
          return this.tBool(val)
        }
        return '-'
      },
      createDateFormat (val): string {
        return moment(val).format('DD.MM.YYYY')
      },
      fetchRoles (): void {
        this.roleLoadState = 1
        const self = this
        var url = (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.roles[0]).replace('{0}', this.volunteer.profileId)
        this.$http.get(url).then(s => {
          self.roleLoadState = 2
          self.roles = s.data
        }, f => {
          self.roleLoadState = 3
        })

        const url2 = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.membership[0].replace('{0}', this.volunteer.profileId)

        // @ts-ignore cannot find mixins
        this.fetchItem(url2, this.expSeconds(3600), function (x) {
          self.member = x
        })

        const url3 = this.$store.state.apiBases.eventservice + this.$store.state.apiEndpoints.trainings[0].replace('{0}', this.volunteer.profileId)
        // @ts-ignore cannot find mixins
        this.fetchItem(url3, this.expSeconds(300), function (x) {
          self.train = x
        })
      },
      clicked (): void {
        // fired on just this item clicked
        const prevOpen = this.open
        // @ts-ignore yeah shouldnt use this._uid, ts is right.
        this.$bus.$emit('clicked-search-result', this._uid)
        this.open = !prevOpen // open this accordion if it was previously closed, and vice versa
        if (this.roleLoadState === 0) {
          this.fetchRoles()
        }
      },
      handleClicked (uid): void {
        // fired on all clicks, for all accordions on the page. Close all the accordions
        this.open = false
      },
      editClick (event): void {
        this.$router.push('/admin/user/' + this.volunteer.profileId)
      },
      markContactedClick (): void {
        this.$bus.$emit('volunteer-mark-contacted', this.volunteer.profileId)
      },
      markUnContactedClick (): void {
        this.$bus.$emit('volunteer-mark-uncontacted', this.volunteer.profileId)
      }
    }
  })
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
  .comp-search-result {
    background: $brand-white;
    cursor: pointer;
    border: 2px solid $brand-white;

    &:hover {
      border: 2px solid $brand-red;
    }

    .portrait-size {
      height: 40px;
      width: 40px;
      margin: 10px;
    }

    .smallView {
      display:none;
    }

    .grid-row {
      display: grid;
      display: -ms-grid;
      grid-template-rows: 60px;
      -ms-grid-rows: 60px;
      grid-template-columns: 60px 3fr 1fr 110px 90px;
      -ms-grid-columns: 60px 3fr 1fr 120px 90px;

      svg {
        color:$brand-red;
      }

    }
    .grid-img {
      grid-column: 1;
      -ms-grid-column: 1;
      line-height: 60px;
    }

    .grid-name {
      grid-column: 2;
      -ms-grid-column: 2;
      line-height: 60px;

      font-size: 13px;
      letter-spacing: 0.3px;
      font-weight: 700;
      color: $brand-red;
      text-transform: uppercase;
      font-family: "Signa-Light", Sans-Serif;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .grid-contacted {
      grid-column: 3;
      -ms-grid-column: 3;
      line-height: 60px;
      text-align: right;
      color:$brand-success;
      padding-right:5px;

      svg {
        color:$brand-success;
        padding-right:5px;
      }

      @media (max-width: $media-md-max) {
        .contactedtext {
          display:none;
        }
      }

    }

    .grid-createddate {
      grid-column: 4;
      -ms-grid-column: 4;
      line-height: 60px;
      text-align: left;
    }

    .grid-zip {
      grid-column: 5;
      -ms-grid-column: 5;
      line-height: 60px;
      text-align: right;
    }

    @media (max-width: 520px) {
        .grid-row {
          grid-template-columns: 60px 3fr;
        }
        .grid-createddate {
          display:none;
        }
        .grid-zip {
          display:none;
        }
        .grid-contacted {
          display:none;
        }
        .smallView {
          display:inline;
        }
     }
  }
</style>
