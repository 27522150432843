<template>
  <div v-if="localCustomer" class="summary">
    <div class="row">
      <div class="col-12">
        <!-- summary section-->
        <div class="row headertitle">
          <div class="col-12">
            <h3>{{ $t('customerForm.summaryTitle') }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="cap-label">{{ $t('customerForm.netPersonalInformation') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="inputcontent">
              <dl>
                <dt>
                  {{ localCustomer.firstName }} {{ localCustomer.lastName }}
                </dt>
                <dd>
                  <div>{{ localCustomer.streetAddress }}</div>
                  <div>{{ localCustomer.zipCode }} {{ localCustomer.city }}</div>
                </dd>
                <dd>
                  <div>{{ localCustomer.homePhone }}</div>
                  <div>{{ localCustomer.email }}</div>
                </dd>
              </dl>
            </div>
            <div v-if="localCustomer.contactFirstName || localCustomer.contactLastName" class="inputcontent">
              <dl>
                <dt>
                  {{ localCustomer.contactFirstName }} {{ localCustomer.contactLastName }}
                </dt>
                <dd>
                  <div>{{ localCustomer.contactPhoneNumber }}</div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="inputcontent">
              <dl v-if="birthdateString">
                <dt>{{ $t('customerForm.birthDate') }}</dt>
                <dd>{{ birthdateString }}</dd>
              </dl>
              <dl>
                <dt>{{ $t('customerForm.gender') }}</dt>
                <dd>{{ genderString }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span class="cap-label">{{ $t('customerForm.netLanguageInformation') }}</span>
            <div class="inputcontent">
              <dl>
                <dt>{{ $t('customerForm.nativeLanguage') }}</dt>
                <dd>{{ languageString }}</dd>
              </dl>
              <dl>
                <dt>{{ $t('customerForm.otherLanguages') }}</dt>
                <dd v-for="lang in customerOtherLanguages" :key="lang">
                  {{ lang }}
                </dd>
                <dd>{{ localCustomer.otherLanguages }}</dd>
              </dl>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <span class="cap-label">{{ $t('customerForm.myInterests') }}</span>
            <div class="inputcontent">
              {{ interestsString }}
            </div>
            <div v-if="localCustomer.interestOther" class="inputcontent">
              {{ localCustomer.interestOther }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span class="cap-label">{{ $t('customerForm.friendWish') }}</span>
            <div class="inputcontent">
              <dl>
                <dt>{{ $t('customerForm.genderPreferences') }}</dt>
                <dd>{{ preferredGenders }}</dd>
                <dt>{{ $t('customerForm.desiredAge') }}</dt>
                <dd>{{ preferredAges }}</dd>
              </dl>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <span class="cap-label">{{ $t('customerForm.netFriendTitle') }}</span>
            <div class="inputcontent">
              <dl v-if="localCustomer.econtactEmail">
                <dt>{{ $t('connectivity.email') }}</dt>
                <dd>{{ localCustomer.econtactEmail }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactTelegram">
                <dt>{{ $t('connectivity.telegram') }}</dt>
                <dd>{{ localCustomer.econtactTelegram }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactKik">
                <dt>{{ $t('connectivity.kik') }}</dt>
                <dd>{{ localCustomer.econtactKik }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactInstagram">
                <dt>{{ $t('connectivity.instagram') }}</dt>
                <dd>{{ localCustomer.econtactInstagram }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactSkype">
                <dt>{{ $t('connectivity.skype') }}</dt>
                <dd>{{ localCustomer.econtactSkype }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactDiscord">
                <dt>{{ $t('connectivity.discord') }}</dt>
                <dd>{{ localCustomer.econtactDiscord }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactFreetext">
                <dt>{{ $t('connectivity.freetext') }}</dt>
                <dd>{{ localCustomer.econtactFreetext }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8 inputcontent">
            <dl>
              <dt>{{ $t('customerForm.netDescriptionTitle') }}</dt>
              <dd>{{ netDescription }}</dd>
            </dl>
          </div>
        </div>
        <div v-if="localCustomer.customerTypeOtherInfo" class="row">
          <div class="col-12 col-md-8">
            <span class="cap-label">{{ $t('customerForm.netAnythingElse') }}</span>
            <div v-if="localCustomer.customerTypeOtherInfo" class="inputcontent">
              {{ localCustomer.customerTypeOtherInfo }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import enums from '@/mixins/enums.js'
  import { FriendCustomerNetDto, LanguageDto } from '@/types/index'
  import { TranslateResult } from 'vue-i18n'

  // the data object
  export interface TemplateComponentData {
    localCustomer: FriendCustomerNetDto | undefined
  }

  export default Vue.extend({
    name: 'NetCustomerSummary',
    mixins: [enums],
    data () {
      return {
        localCustomer: undefined as FriendCustomerNetDto | undefined
      }
    },
    computed: {
      birthdateString (): string {
        if (!this.localCustomer) return ''
        var d = new Date(this.localCustomer.birthdate)
        return d ? d.toLocaleDateString('fi-FI') : ''
      },
      genderString (): string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getGenderTranslation(this.localCustomer.gender)
      },
      languageString (): string | TranslateResult {
        // @ts-ignore - ts intellisense not finding mixins
        var isoCode = this.getLanguage(this.localCustomer?.nativeLanguage)?.isoCode
        var translateString = `languages.${isoCode}`
        return this.$t(translateString)
      },
      netDescription (): string {
        if (this.localCustomer && this.localCustomer.description) { return this.localCustomer.description }

        return '-'
      },
      interestsString (): string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getInterestsMaskTranslation(this.localCustomer?.interests)
      },
      preferredGenders (): string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getGenderPreferencesMaskTranslation(this.localCustomer?.preferredGenders)
      },
      preferredAges (): string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getAgePreferencesMaskTranslation(this.localCustomer?.preferredAges)
      },
      customerOtherLanguages (): Array<string> | undefined {
        return this.localCustomer?.customerLanguages
          .filter((x: LanguageDto) => x.isoCode !== 'other')
          .map((x: LanguageDto) => {
          return this.$t('languages.' + x.isoCode).toString()
        })
      }

    },
    mounted (): void {
      const key = this.$route.params.key
      const self = this
      const url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customergetnetsummarybykey[0]}`.replace('{key}', key)
      this.$http.get(url).then(resp => {
        // @ts-ignore
        this.localCustomer = resp.body
      }, f => {
        // @ts-ignore cannot find mixins
        this.showAlertError(this.$t('common.fetchFailed'))
      })
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .summary {
    background-color: $brand-white;
    padding: 15px;
    padding-top: 30px;

    .headertitle {
      margin-bottom: 30px;
    }

    .cap-label {
      color: $brand-red;
    }

    .name {
      font-weight: bold;
    }

    .inputcontent {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .buttons {
      padding-top: 30px;
    }
  }

</style>