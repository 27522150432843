<template>
  <div class="comp-friendshipmanagement-list">
    <div class="row search">
      <div class="col-12 col-sm-9 col-md-7 col-lg-5">
        <searchbox v-model="search" :placeholder="placeholder" />
      </div>
    </div>
    <div v-if="exchanges" class="listing">
      <div v-for="d in filteredItems" :key="d.id" class="listing-row">
        <router-link :to="'/admin/friendship/' + d.id + '/clients'">
          {{ d.name }}
        </router-link>
      </div>
    </div>
    <div v-else class="loading text-center brand-red">
      <div class="my-2">
        <loading />
      </div>
      <div class="my-3">
        <p>{{ $t('userAdmin.loading') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import searchbox from '../SPRSearchBox.vue'
  import loading from '../SPRLoading.vue'
  export default {
    name: 'AdminFriendshipManagementList',
    components: { searchbox, loading },
    data: function () {
      return {
        exchanges: null,
        placeholder: this.$t('userAdmin.searchFriendGroupPlaceholder'),
        search: ''
      }
    },
    computed: {
      filteredItems () {
        if (this.search === '') {
          return this.exchanges
        }
        return this.exchanges.filter(item => {
          return item.name.toLowerCase().concat(' ' + item.name.toLowerCase()).indexOf(this.search.toLowerCase()) > -1
        })
      }
    },
    mounted: function () {
      if (!this.hasAdminToFriendship(Number(this.jwtToken.DistrictMembership))) {
        this.showAlertPermissionDenied().then(() => this.$router.push('/profile'))
        return
      }
      this.$http.get(this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganization[0]).then(success => {
        this.exchanges = success.body
      })
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .comp-friendshipmanagement-list {
    padding: 0 15px 15px;

    .listing {
      background: $brand-white;
      display: flex;
      display: -ms-flexbox;
      flex-direction: column;
      -ms-flex-direction: column;

      .listing-row {
        padding: 10px 15px;
        color: $brand-red;

        &:nth-child(even) {
          background: rgba(0,0,0,0.03);
        }

        a {
          color: $brand-red;
        }
      }
    }
  }
</style>
