<template>
  <div class="comp-profile-editor-image row text-center row">
    <div class="col-12">
      <div class="row">
        <div class="col image-editor">
          <!-- image preview panel-->
          <div class="row">
            <div class="col-12 image-preview">
              <img class="prev" :src="imageChooser">
            </div>
          </div>
          <div v-if="uploadState > 0" class="row py-2">
            <div class="col-12">
              <span :class="feedbackClass">{{ uploadFeedback }}</span>
            </div>
          </div>
          <div v-if="!changePending" class="row py-2">
            <div class="col-12 text-left">
              <b-form-file ref="fileInput" accept="image/*" prefix="img"
                           :placeholder="$t('profile.imagePlaceholder')"
                           :choose-label="$t('profile.choose') + ': '"
                           :drop-label="$t('profile.drop')"
                           @change="onFileChange"
              />
            </div>
          </div>
          <div v-if="!changePending" class="row text-center py-2">
            <div class="col-12 col-md-6 mb-2">
              <button class="btn spr-roundbox-3 image-inputs cap-label" @click="progressButton(editorState)">
                {{ progressText }}
              </button>
            </div>
            <div class="col-12 col-md-6 mb-2">
              <button class="btn spr-roundbox-3 image-inputs cap-label" @click="backButton">
                {{ cancelText }}
              </button>
            </div>
          </div>
          <div v-else class="row text-center py-2">
            <div class="col image-editor">
              <loading />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import loading from '../SPRLoading.vue'
  import anonPortrait from '../../assets/img/anon-portrait.png'
  export default {
    name: 'ProfileEditorImage',
    components: { loading },
    props: {
      editor: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        image: '',
        changePending: false,
        uploadState: 0,
        editorState: 0
      }
    },
    computed: {
      cancelText: function () {
        return this.editorState === 0 ? this.$t('profile.back') : this.$t('profile.cancel')
      },
      imageChooser: function () {
        return this.image ? this.image : (this.$store.state.profile.portrait ? this.$store.state.profile.portrait : anonPortrait)
      },
      uploadFeedback: function () {
        if (this.uploadState === 1) {
          return this.$t('profile.imageSuccess')
        }
        return this.$t('profile.imageFailure')
      },
      feedbackClass: function () {
        var css = 'cap-label feedback-'
        css += this.uploadState === 1 ? 'success' : 'failure'
        return css
      },
      progressText: function () {
        return this.editorState === 0 ? this.$t('profile.choose') : this.$t('profile.save')
      }
    },
    methods: {
      progressButton: function (val) {
        if (val === 1) {
          this.uploadImage()
        } else {
          this.$refs.fileInput.$el.click()
        }
      },
      onFileChange: function (e) {
        var files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }
        this.createImage(files[0])
        this.editorState = 1
      },
      createImage: function (file) {
        var reader = new FileReader()
        var vm = this

        reader.onload = (e) => {
          vm.image = e.target.result
        }
        reader.readAsDataURL(file)
      },
      backButton: function () {
        this.$router.push('/profile')
      },
      uploadImage: function () {
        this.uploadState = 0
        this.changePending = true
        this.$http.post(this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.uploadimage[0], {
          fileName: this.$store.state.profile.id,
          fileBytes: this.image,
          container: 'userimages'
        }).then(result => {
          if (result.ok === true) {
            this.$store.commit('updateProfilePicture', result.body.body)
            this.changePending = false
            this.uploadState = 1
          }
        }, result => {
          this.uploadState = 2
        })
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';

.comp-profile-editor-image {
  .image-editor {
    max-width: 600px;
    margin: 0 auto;
  }

  .portrait {
    width: 150px;
    height: 150px;
    border-radius: 50%;

    margin: 0 auto;
  }

  .prev {
    display: block;
    width: 100%;
    height: auto;
  }

  .image-preview {
    max-width: 400px;
    margin: 15px auto;
  }

  .image-inputs {
    width: 100%;
    border: 2px solid $brand-red;
    background: $brand-white;

    padding-top: 14px;
    padding-bottom: 14px;

    color: $brand-red;

    &:hover {
      color: $brand-white;
      background: $brand-red;
    }
  }

  .feedback-failure {
    color: red;
  }

  .feedback-success {
    color: green;
  }

  .custom-file {
    border: 2px solid $brand-grey3;
    border-radius: 5px;
    position: relative;

    span {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;

      line-height: 34px;
      padding-left: 10px;
    }
  }
}

</style>
