<template>
  <div class="comp-profile-editor-reset-password mx-auto text-center container my-5">
    <div class="row">
      <div class="col-12">
        <span role="heading" aria-level="1" class="h1 brand-red text-center text-md-left">
          {{ $t('profile.resetExpiredPasswordTitle') }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <sprvalidation :validation-errors="validationErrors" class="mb-5" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="instructions">{{ $t('profile.editCredentialsInstructions') }}</span>
      </div>
    </div>
    <div class="row">
      <!-- current password -->
      <div class="col-12 col-sm-8 col-md-6">
        <sprPassword v-model="currentPassword" v-validate="'required|min:6|max:128'"
                     :hint="$t('profile.currentPassword')"
                     class="editor-field"
                     input-type="password"
                     :is-valid="!errors.has('currentPassword')"
                     name="currentPassword"
                     data-vv-validate-on="none"
                     autocomplete="false"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <span class="instructions">{{ $t('profile.editPasswordInstructions') }}</span>
      </div>
    </div>
    <div class="row">
      <!-- password -->
      <div class="col-12 col-md-6">
        <sprPassword ref="password"
                     v-model="password"
                     v-validate="'min:12|max:128'"
                     :hint="$t('profile.password')"
                     class="editor-field"
                     input-type="password"
                     :is-valid="!errors.has('password')"
                     name="password"
                     data-vv-validate-on="none"
                     autocomplete="false"
        />
      </div>
      <!-- retype password -->
      <div class="col-12 col-md-6">
        <sprPassword v-model="confirmPassword"
                     v-validate="{required: retypeRequired, confirmed:password }"
                     data-vv-as="password"
                     :hint="$t('profile.retypePassword')"
                     class="editor-field"
                     input-type="password"
                     :is-valid="!errors.has('retypePassword')"
                     name="retypePassword"
                     data-vv-validate-on="none"
                     autocomplete="false"
        />
      </div>
    </div>
    <!-- save and cancel buttons -->
    <div v-if="!pending" class="row py-4">
      <div class="col-12 col-md-6 my-1">
        <sprbutton click-event="save-event" :title="$t('profile.save')"
                   :size="1"
                   :bordersize="2" class="fill-width" @save-event="onSave"
        />
      </div>
    </div>
    <div v-else class="row pending">
      <div class="col-12 py-4">
        <loading />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  import sprPassword from '../SprPassword.vue'
  import sprvalidation from '../SPRValidationError.vue'
  import loading from '../SPRLoading.vue'
  import validatoremailexists from '../../mixins/validator-emailexists.js'
  import { Validator } from 'vee-validate'

  Validator.extend('emailexists', validatoremailexists)

  // the data object
  export interface TemplateComponentData {
    currentPassword: string,
    currentPasswordFailure: any,
    password: string,
    confirmPassword: string,
    pending: boolean,
    validatedFields: Array<string>
  }

  export default Vue.extend({
    name: 'ProfileEditorResetExpiredPassword',
    components: { sprbutton, sprPassword, sprvalidation, loading },
    props: {
      email: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        currentPassword: '',
        currentPasswordFailure: null,
        password: '',
        confirmPassword: '',
        pending: false,
        validatedFields: ['currentPassword', 'password', 'retypePassword']
      }
    },
    computed: {
      retypeRequired (): boolean {
        return this.password.length > 0
      },
      validationErrors (): Array<any> {
        var arr = [] as Array<any>
        var self = this

        if (self.currentPasswordFailure) {
          arr.push(['login.attempt', 'currentPasswordFailed', ''])
          self.currentPasswordFailure.forEach(err => {
            //@ts-ignore
            if (err.code) { arr.push(['', 'error.' + this.toLowerCamelCase(err.code), '']) }
          })
        }

        if (!this.validatedFields) {
          return arr
        }

        this.validatedFields.forEach(function (fieldName) {
          //@ts-ignore
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })

          if (err != null) {
            arr.push(['profile.' + err.field, String(err.rule), String(err.msg)])
          }
        })
        return arr
      }
    },
    methods: {
      focusOnErrors (): void {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      },
      onSave (): void {
        const self = this

        this.currentPasswordFailure = null
        this.$validator.validateAll().then((result) => {
          if (result) {
            self.pending = true
            const url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.expiredpasswordupdate[0]
            const data = {
              currentPassword: this.currentPassword,
              email: this.email,
              password: this.password
            }
            this.$http.post(url, data).then((resp: any) => {
              if (resp.ok && resp.body.success) {
                //@ts-ignore
                this.logout('/login?email=' + data.email)
              }
            }, function (errorResponse) {
              self.currentPasswordFailure = errorResponse.body?.error?.errors
              self.focusOnErrors()
              self.pending = false
            })
          }
        })
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
.comp-profile-editor-reset-password {
    max-width: 600px;

   .editor-field {
    width: 100%;
    text-align: left;
  }

  .row input {
    box-shadow: none;
    border: 2px solid $brand-grey3;
    display: inline-block;
  }

  .spr-shadow {
    box-shadow: none !important;
    border: 2px solid $brand-grey3;
  }

  .wrap-date input {
    box-shadow: none !important;
    border: 2px solid $brand-grey3 !important;
  }

  .instructions {
    display: block;
    margin-bottom: 30px;
    text-align:left
  }

  .pending {
    color: $brand-red;
  }
}
</style>
