<template>
  <div class="customerfindresult" :class="{ 'hasfriend': hasFriendship }">
    <div class="col-lg-6 col-xl-4 customer-container">
      <div class="customerimage">
        <friendicon :cross="customer.customerNotForSpr" :friendname="customer.firstName" :state="customer.state" :multiple-friendships="customer.multipleVolunteers" />
      </div>
      <div class="customerinfo">
        <div class="customername">
          <a href="javascript:void(0)" @click="selectCustomer">{{ customer.firstName }} {{ customer.lastName }}</a>
        </div>
        <span class="age">{{ genderAgeText }}</span>
        <div class="zip">
          {{ zipCodeText }}
        </div>
      </div>
    </div>

    <div class="d-none d-xl-block col-xl-4">
      <div class="description-container">
        <div v-show="customer.shortDescription" class="description">
          &#8220;{{ customer.shortDescription }}&#8221;
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-xl-4 friend-container">
      <div v-if="!hasFriendship" class="row nofriend">
        <div class="col-12 pending">
          {{ daysWithoutFriend(customer.lastFriendshipEndDate) }}
        </div>
      </div>
      <div v-else>
        <div v-for="(friendship, i) in customer.friendships" :key="i" class="row friendrow">
          <div class="col-12">
            <div class="friendinfo">
              <router-link :to="'/friend/volunteerpreview/' + group.id + '/' + friendship.friendVolunteerId">
                {{ friendship.firstName }} {{ friendship.lastName }}
              </router-link>
            </div>
            <div class="friendimage">
              <friendshipicon :friendname="friendship.firstName" :state="friendship.friendshipState" />
            </div>
          </div>
        </div>
      </div>
      <div v-for="(pool) in customer.friendPools" :key="pool.id" class="row pools">
        <div class="col-12">
          <div class="poolinfo" @click="onPoolClicked(pool)">
            {{ pool.name }}
          </div>
          <div class="poolimage">
            <poolicon :pool="pool" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import friendicon from './FriendIcon.vue'
  import friendshipicon from './FriendshipIcon.vue'
  import poolicon from './PoolIcon.vue'
  import datehelper from '../../mixins/dates.js'
  import enumhelper from '../../mixins/enums.js'
  import { CustomerState, FriendCustomerFindDto, FriendshipState, FriendManagementDto } from '../../types/index'

  export default Vue.extend({
    name: 'CustomerFindResult',
    components: { friendicon, friendshipicon, poolicon },
    mixins: [enumhelper, datehelper],
    props: {
      customer: {
        type: Object as () => FriendCustomerFindDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      }
    },
    computed: {
      hasFriendship (): boolean {
        return this.customer.friendships !== null && this.customer.friendships.length > 0
      },
      computedGender (): string | LocaleMessage {
        // @ts-ignore (no mixins found)
        return this.getGenderTranslation(this.customer.gender)
      },
      computedAge (): number {
        // @ts-ignore (no mixins found)
        return this.calculateAge(this.customer.birthdate)
      },
      genderAgeText (): string {
        return [this.computedGender, this.computedAge ? `${this.computedAge} ${this.$t('customerSearch.years')}` : ''].filter(Boolean).join(', ')
      },
      zipCodeText (): string {
        return '(' + this.customer.zipCode + ')'
      }
    },
    methods: {
      daysWithoutFriend (lastFriendshipDate): string {
        if (!lastFriendshipDate) {
          return `${this.$t('customerSearch.neverHadFriend')}`
        }
        // @ts-ignore (no mixins found)
        return `${this.calculateDayDiff(lastFriendshipDate)} ${this.$t('customerSearch.daysWithoutFriend')}`
      },
      selectCustomer (): void {
        this.$bus.$emit('customerSelected', this.customer)
      },
      onPoolClicked (pool): void {
        this.$router.push(`/friend/pools/${this.group.id}/${pool.id}`)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .customerfindresult {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $brand-white;
    border-left: solid 5px $customer-color-strong;
    margin: 10px 0;
    padding: 20px 0;
    position: relative;

    &.hasfriend {
      border-right: solid 5px $volunteer-color-strong;
    }

    .customer-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .customerinfo {
        padding-left: 10px;
        text-align: left;
        font-size: 13px;
        .customername {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }

    .description-container {
      .description {
        color: $brand-red;
        font-style: italic;
        font-size: 13px;
        text-align: center;
      }
    }

    .friend-container {
      text-align: right;
      font-size: 13px;
      .friendrow {
        padding: 3px 0;
        display: block;
        .friendinfo {
          display: inline-block;
          margin-right: 5px;
          text-transform: uppercase;
          color: $brand-red;
          font-weight: bold;
        }
        .friendimage {
          display: inline-block;
          .friendicon {
            width: 24px;
            height: 24px;
            .lettertext {
              font-size: 13px;
            }
          }
        }
      }

      .nofriend {
        text-align: right;
        font-size: 13px;
      }

      .pools {
        cursor: pointer;
        padding: 3px 0;
        display: block;
        .poolinfo {
          display: inline-block;
          margin-right: 5px;
          text-transform: uppercase;
          color: $brand-red;
          font-weight: bold;
        }
        .poolimage {
          display: inline-block;
          .poolicon {
            width: 24px;
            height: 24px;
            .lettertext {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
</style>
