<template>
  <div class="poolmemberview" :class="{ 'disabled': disabled, 'customer': type.toUpperCase() === 'CUSTOMER', 'volunteer': type.toUpperCase() === 'VOLUNTEER' }">
    <div class="row">
      <div class="col-6 namecol">
        <span class="name" @click="onMemberClicked">{{ member.firstName }} {{ member.lastName }}</span>
      </div>
      <div class="col-6 text-right">
        <sprbutton :size="4"
                   :title="$t('poolPreview.removeMember')"
                   click-event="remove"
                   @remove="onRemove"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import { FriendPoolCustomerSummaryNetDto, FriendPoolCustomerSummaryStandardDto, FriendPoolVolunteerSummaryNetDto, FriendPoolVolunteerSummaryStandardDto } from '@/types'
  export default Vue.extend({
    name: 'PoolMemberView',
    components: { sprbutton },
    props: {
      member: {
        type: Object as () => FriendPoolCustomerSummaryNetDto | FriendPoolCustomerSummaryStandardDto | FriendPoolVolunteerSummaryNetDto | FriendPoolVolunteerSummaryStandardDto,
        required: true
      },
      type: {
        type: String,
        default: 'CUSTOMER',
        validator: (prop: string) => [
          'CUSTOMER',
          'VOLUNTEER'
        ].includes(prop)
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onMemberClicked () {
        this.$emit('memberclicked', this.member)
      },
      onRemove () {
        if (this.disabled) return
        this.$emit('remove', this.member)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .poolmemberview {
    background-color:$brand-white;
    margin: 10px 0;
    padding: 20px 0;

    &.customer {
      border-left: solid 5px $customer-color-strong;
    }

    &.volunteer {
      border-left: solid 5px $brand-red;
    }

    &.disabled {
      border-left: solid 5px $brand-grey7;
      background-color: $brand-grey3;

      button {
        border-color: $brand-grey7;
        color: $brand-grey7;
        cursor: not-allowed;

        &:hover {
          background-color:$brand-white;
          color: $brand-grey7;
        }
      }
    }

    .namecol {
      margin: auto;
      .name {
        text-transform: uppercase;
        color: $brand-red;
        font-weight: 700;
        margin-left: 10px;
        font-size: 13px;
        cursor: pointer;
      }
    }

    button {
      font-size: 13px;
      padding: 5px 10px;
      margin-right: 10px;
    }
  }
</style>
