<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .messages {
    .messagelist {
      background-color:$brand-white;
      margin-top: 30px;
    }
  }

</style>

<template>
  <div class="messages col-12">
    <backbar :title="$t('message.allMessages')" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="messagelist">
            <div v-if="messages && !messagesLoading">
              <message v-for="(message, index) in messages" :key="_uid + '_message_' + index" :message="message" />
            </div>
            <div v-if="!messages && !messagesLoading" class="nomessages">
              <span>{{ $t('frontPageRegistered.noMessages') }}</span>
            </div>
            <div v-if="messagesLoading" class="brand-red">
              <loading />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import backbar from '../BackBar.vue'
  import message from './SPRMessage.vue'
  import loading from '../SPRLoading.vue'
  import messages from '../../mixins/messages.js'
  export default {
    name: 'Messages',
    components: { backbar, message, loading },
    mixins: [messages],
    data () {
      return {
        messages: [],
        messagesLoading: false
      }
    },
    mounted () {
      this.getMessages()
    },
    methods: {
      getMessages () {
        var self = this
        self.messagesLoading = true

        // load from the cache if its been loaded before
        if (self.$store.state.messages.length > 0) {
          self.messagesLoading = false
          self.messages = self.$store.state.messages
          return
        }

        // otherwise get from back end
        this.loadMessages().then(() => {
          self.messagesLoading = false
          self.messages = self.$store.state.messages
        })
      }
    }
  }
</script>
