<template>
  <div class="container move-to-fmg-form">
    <div v-if="showConfirmation" class="modal-level2">
      <div class="modal-content-level2">
        <p>
          {{ $t('moveFMGForm.confirmationMessage') }}
        </p>
        <SPRButton type="primary"
                   :size="1"
                   :title="$t('moveFMGForm.confirmationYes')"
                   class="spr-shadow margintop20"
                   click-event="confirmMove"
                   @confirmMove="confirmMove"
        />
        <SPRButton
          :size="1"
          :title="$t('moveFMGForm.confirmationNo')"
          class="spr-shadow margintop20"
          click-event="cancelMove"
          @cancelMove="cancelMove"
        />
      </div>
    </div>

    <h2>{{ $t('moveFMGForm.title') }}</h2>
    <div class="dropdown">
      <sprautocomplete
        v-model="selectedGroup"
        :options="friendManagementGroups"
        :placeholder="$t('moveFMGForm.placeholder')"
      />
    </div>
    <SPRButton type="primary"
               :size="1"
               :title="$t('moveFMGForm.cancel')"
               class="spr-shadow margintop20"
               click-event="cancel"
               @cancel="cancel"
    />
    <SPRButton
      :size="1"
      :title="$t('moveFMGForm.save')"
      class="spr-shadow margintop20"
      click-event="save"
      :disabled="!isGroupSelected"
      @save="save"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import SPRButton from '@/components/SPRButton.vue'
  import sprautocomplete from '@/components/SPRAutoCompleteDropdown2.vue'
  import { StringKeyValuePair } from '@/types/custom'

  export interface TemplateComponentData {
  selectedGroup: StringKeyValuePair | null,
  showConfirmation: boolean
}

  export default Vue.extend({
    components: { sprautocomplete, SPRButton },
    props: {
      currentGroup: {
        type: Object as () => StringKeyValuePair,
        required: true,
        validator (value: any) {
          return value === null || (typeof value === 'object' && 'key' in value && 'value' in value)
        }
      },
      targetId: {
        type: String,
        required: true
      },
      friendManagementGroups: {
        type: Array<StringKeyValuePair>,
        required: true
      }
    },
    data () {
      return {
        selectedGroup: null as StringKeyValuePair | null,
        showConfirmation: false
      }
    },
    computed: {
      isGroupSelected (): boolean {
        return this.selectedGroup !== null
      }
    },
    created () {
      if (this.currentGroup !== null) { this.selectedGroup = this.currentGroup } else { this.selectedGroup = null }
    },
    methods: {
      cancel () {
        this.selectedGroup = null
        this.$emit('cancel')
      },
      save () {
        this.showConfirmation = true
      },
      confirmMove () {
        // Logic to move the customer to the selected group
        this.showConfirmation = false

        if (this.selectedGroup === null) {
          return
        }

        this.$emit('save', this.selectedGroup)
      },
      cancelMove () {
        this.showConfirmation = false
        this.$emit('cancel')
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/variables.scss";

  .move-to-fmg-form {
    max-width: 400px;
    h2 {
      font-size: $font-size-1;
      margin-bottom: 20px;
    }
  }

  .dropdown {
    margin: 20px;
  }

.modal-level2 {
  display: block;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
.modal-content-level2 {
    background-color: #fefefe;
    margin: 5% auto;
    max-width: 600px;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}
</style>