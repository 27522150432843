<template>
  <div class="row admin-district-level">
    <div class="col-12">
      <h3>{{ district.name }}</h3>
    </div>
    <div class="col-12">
      <adminnavigation :options="options" />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  import adminnavigation from './AdminHorizontalNavigation.vue'

  export interface TemplateComponentData {
    optionsConstant: any,
    loaded: any,
    district: any
  }

  export default Vue.extend({
    name: 'AdminDistrictLevel',
    components: {
      adminnavigation
    },
    props: {
      primary: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      filter: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        optionsConstant: [
          { uri: '/admin/district/' + this.id + '/section', text: this.$t('userAdmin.sections'), primary: 'section' },
          { uri: '/admin/district/' + this.id + '/user', text: this.$t('userAdmin.users'), primary: 'user' },
          { uri: '/admin/district/' + this.id + '/messages', text: this.$t('userAdmin.messages'), primary: 'user' },
          { uri: '/admin/district/' + this.id + '/group', text: this.$t('userAdmin.groups'), primary: 'group' }
        ],
        loaded: 0,
        district: { name: '' }
      }
    },
    computed: {
      options (): any {
        var rv = this.optionsConstant.concat()

        rv.push({ uri: '/admin/training/1', text: this.$t('userAdmin.training'), primary: 'training' })

        return rv
      }
    },
    mounted: function () {
      this.triggerFetch()
    },
    methods: {
      fetchDistrict: function (uri, retry) {
        this.$http.get(uri).then((s: any) => {
          this.loaded = 1
          this.district = s.body
        }, f => {
          if (retry > 0) {
            this.fetchDistrict(uri, retry - 1)
          } else {
            this.loaded = -1
          }
        })
      },
      triggerFetch: function () {
        this.loaded = 0
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.district[0].replace('{0}', this.id)
        this.fetchDistrict(url, 3)
      }
    }
  })
</script>
