<template>
  <div class="event-organizers-dropdown">
    <sprsimpletext
      v-validate="'invalidEventOrganizer'"
      :hint="hint"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      name="eventPage.eventDistrictSection"
      :is-valid="isValid && !errors.has('eventPage.eventDistrictSection')"
      :value="eventOrganizer"
      :list="datalistid"
      @input="onInput"
    />
    <datalist :id="datalistid" class="dl">
      <option v-for="item in eventOrganizerList" :key="item.id" :class="{ 'eventOrganizerType': item.eventOrganizerType }">
        {{ item.name }}
      </option>
    </datalist>
  </div>
</template>

<script>
  import sprsimpletext from './SPRSimpleText.vue'

  export default {
    name: 'EventOrganizersDropdown',
    components: { sprsimpletext },
    props: {
      hint: {
        type: String,
        default: ''
      },
      value: {
        type: Object,
        default: () => {
          return {
            eventOrganizerType: null,
            organizerId: null
          }
        }
      },
      placeholder: {
        type: String,
        default: ''
      },
      required: Boolean,
      disabled: Boolean,
      isValid: Boolean
    },
    data () {
      return {
        eventOrganizer: ''
      }
    },
    computed: {
      datalistid () {
        return `datalist_${this._uid}`
      },
      eventOrganizerList () {
        // EventOrganizerType: Institution = 0, District = 100, Section = 200
        const eventOrganizerTypeInstitution = 0
        const eventOrganizerTypeDistrict = 100
        const eventOrganizerTypeSection = 200
        var list = []

        if (!this.$store.state.institutions || !this.$store.state.institutions.length) return list

        for (var institutionIndex = 0; institutionIndex < this.$store.state.institutions.length; institutionIndex++) {
          var institution = this.$store.state.institutions[institutionIndex]

          list.push({
            code: `${eventOrganizerTypeInstitution}:${institution.id}`,
            name: institution.name,
            eventOrganizer: {
              eventOrganizerType: eventOrganizerTypeInstitution,
              organizerId: institution.id
            }
          })
        }

        if (!this.$store.state.districts || !this.$store.state.districts.length) return list

        for (var districtIndex = 0; districtIndex < this.$store.state.districts.length; districtIndex++) {
          var district = this.$store.state.districts[districtIndex]
          var sections = _.sortBy(_.filter(this.$store.state.sections, (section) => {
            // select in this district
            return section.districtId === district.id
          }), (section) => {
            // sort by
            return section.name
          })

          list.push({
            code: `${eventOrganizerTypeDistrict}:${district.id}`,
            name: district.name.toUpperCase(),
            eventOrganizer: {
              eventOrganizerType: eventOrganizerTypeDistrict,
              organizerId: district.id
            }
          })

          for (var j = 0; j < sections.length; j++) {
            var section = sections[j]
            list.push({
              code: `${eventOrganizerTypeSection}:${section.id}`,
              name: section.name,
              eventOrganizer: {
                eventOrganizerType: eventOrganizerTypeSection,
                organizerId: section.id
              }
            })
          }
        }
        return list
      }
    },
    watch: {
      value (newVal) {
        this.setLocalVal(newVal)
      }
    },
    created () {
      var self = this
      this.$validator.extend('invalidEventOrganizer', {
        getMessage (field, args) {
          return 'EventOrganizer Invalid'
        },
        validate (value, args) {
          // check the value is one of the ones in the list
          var item = _.find(self.eventOrganizerList, (eventOrganizer) => {
            return eventOrganizer.name === value
          })

          if (!item) {
            return false
          }

          return true
        }
      })
    },
    mounted () {
      if (this.value) {
        this.setLocalVal(this.value)
      }
    },
    methods: {
      setLocalVal (val) {
        if (!val) {
          this.eventOrganizer = null
        } else {
          var ds = _.find(this.eventOrganizerList, (eventOrganizer) => {
            return eventOrganizer.code === `${val.eventOrganizerType}:${val.organizerId}`
          })
          if (ds) {
            this.eventOrganizer = ds.name
          } else {
            this.eventOrganizer = null
          }
        }
      },
      onInput (eventOrganizerName) {
        this.eventOrganizer = eventOrganizerName
        var self = this
        this.$validator.validate().then(result => {
          if (result) {
            var item = _.find(self.eventOrganizerList, (item) => {
              return item.name.toLowerCase() === eventOrganizerName.toLowerCase()
            })

            if (!item) {
              self.$emit('input', null)
              return
            }

            var data = item.eventOrganizer

            self.$emit('input', data)
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .event-organizers-dropdown {
    input::-webkit-calendar-picker-indicator {
      display: none;
    }
    input.form-control {
      border: solid 2px $brand-grey5;
        &:focus {
          box-shadow: 0 0 0 0.2rem $brand-grey3;
        }
      }
  }

</style>
