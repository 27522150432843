<template>
  <div class="comp-horizontal-navigation mb-3">
    <router-link v-for="(o, k) in options"
                 :key="'nav_' + o.text + '_option_' + k + '_' + _uid"
                 class="opt"
                 :to="o.uri"
    >
      {{ o.text }}
    </router-link>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  export default Vue.extend({
    name: 'AdminHorizontalNavigation',
    props: {
      options: {
        type: Array,
        default: () => []
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
.comp-horizontal-navigation {
  .opt {
    display: inline-block;
    padding: 10px;

    font-size: 18px;
    text-decoration: none;

    font-family: "Signa-Bold", Sans-Serif;

    color: $brand-grey10;

    transition: color 0.33s;

    &:hover {
      color: $brand-black;
      transition: color 0.33s;
    }
  }

  .router-link-active {
    color: $brand-red !important;
    border-bottom: 2px solid $brand-red;
  }
}
</style>
