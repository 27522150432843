<template>
  <div class="pager-wrapper">
    <div v-if="moreThanOnePage" class="pager">
      <div class="pagernav" :class="(!statisticsvisible) ? 'pagernavcenter' : ''">
        <span class="page-title">{{ $t('listingPager.profilePage') }}: </span>
        <div v-if="debug">
          CurrentPage: {{ currentPage }}, Take: {{ take }}, Skip: {{ skip }}, total: {{ total }}, Max Display: {{ maxDisplay }}, Lastpage? {{ isLastPage }}
        </div>
        <a :style="{visibility: !isFirstPage ? 'visible' : 'hidden'}" href="javascript:void(0);" class="arrow-box" @click="onPrevious">
          <div class="arrow1 previous"></div>
        </a>
        <div v-for="page in pages" :key="page.value" class="pagecontainer">
          <div v-if="page.visible" class="page" :class="{'selected': page.selected}">
            <a href="javascript:void(0);" @click="onClicked(page)">
              {{ page.value }}
            </a>
          </div>
        </div>
        <a v-if="!isLastPage" href="javascript:void(0);" class="arrow-box" @click="onNext">
          <div class="arrow1 next"></div>
        </a>
      </div>
      <div v-if="statisticsvisible" class="page-stats">
        <pagerstats :total="total" :skip="skip" :take="take"></pagerstats>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import pagerstats from './SPRPagerStats.vue'

  export default Vue.extend({
    name: 'Pager',
    components: { pagerstats },
    props: {
      total: {
        type: Number,
        default: 0
      },
      take: {
        type: Number,
        default: 0
      },
      skip: {
        type: Number,
        default: 0
      },
      statisticsvisible: {
        type: Boolean,
        default: true
      },
      maxDisplay: {
        type: Number,
        default: 0
      }
    },
    data () {
      return {
        actualDisplayed: 0,
        debug: false
      }
    },
    computed: {
      currentPage (): number {
        return Math.floor(this.skip / this.take) + 1
      },
      currentStartNumber (): number {
        return this.skip + 1
      },
      currentEndNumber (): number {
        const maxTotal = this.skip + this.take
        return maxTotal > this.total ? this.total : maxTotal
      },
      isFirstPage (): boolean {
        return this.currentPage === 1
      },
      isLastPage (): boolean {
        return this.currentPage >= this.total / this.take
      },
      moreThanOnePage (): boolean {
        return this.pages.length > 1
      },
      pages (): any {
        if (this.take < 0) {
          return []
        }

        const skipping = this.skip % this.take === 0 ? this.skip : Math.max(Math.floor(this.skip / this.take), 0) * this.take
        const retval: object[] = []
        let count = 0
        this.resetActualDisplayed()
        for (let i = 0; i < this.total; i = i + this.take) {
          count++
          const isVisible = this.checkIsVisible(count)
          retval.push({ value: count, skip: i, selected: skipping === i, visible: isVisible })
        }
        return retval
      }
    },
    created () {
      this.resetActualDisplayed()
    },
    methods: {
      onPageSizeChanged (pageSize): void {
        this.$emit('pagesizechanged', pageSize)
      },
      checkIsVisible (page): boolean {
        // If set to display all, ignore
        if (this.maxDisplay <= 0) {
          this.actualDisplayed++

          return true
        }

        // start from one side of the middle one, then display until we run out of things to display
        const oneside = Math.floor(this.maxDisplay / 2)

        const tempCalc = this.currentPage - oneside

        if (page < tempCalc) {
          return false
        }

        if (this.actualDisplayed >= this.maxDisplay) {
          return false
        }

        this.actualDisplayed++

        return true
      },
      onClicked (val) {
        this.resetActualDisplayed()
        this.$emit('pagerchanged', val)
      },
      onPrevious () {
        this.resetActualDisplayed()
        this.$emit('pagerprevious')
      },
      onNext () {
        this.resetActualDisplayed()
        this.$emit('pagernext')
      },
      resetActualDisplayed () {
        this.actualDisplayed = 0
      }
    }
  })
</script>

<style lang="scss" >
  @import "../assets/scss/_variables.scss";
  .pager-wrapper{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .pager {
    background: $brand-white;
    display: flex;
    align-content: center;
    height: 3rem;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    font-family: Verdana, sans-serif; // Because Signa-Light numbers jump all over the place!

    .page-title {
      color: $brand-grey13;
      margin-top: 4px;
    }
    .pagernav {
      display: flex;
      align-content: center;
      align-self: center;
      flex: 1 0 0;
      width: 100%;
    }

    .pagernavcenter {
      justify-content: center;
    }

    @media (min-width: $media-md) {
      .pagernav {
        flex: 1 0 0;
        width: auto;
        float: left;
      }
    }

    .pagecontainer {
      display: inline-block;
    }

    .arrow-box {
      background-color: $brand-white;
      color:$brand-grey13;
      border: solid 2px $brand-grey1;
      border-radius: 100%;
      display: inline-block;
      width: 30px;
      height: 30px;
      margin: 0px 20px 0px;
      text-decoration: none;
    }

    .page {
      background-color: $brand-white;
      color:$brand-grey13;
      border: solid 2px $brand-grey1;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      display: inline-block;
      margin-right:5px;
      margin-left: 5px;

      &:hover {
        background-color:$brand-red;
        a {
          text-decoration: none;
          color:$brand-white;
        }
      }

      a {
        display:block;
        text-align:center;
        color:$brand-grey13;
        margin-top:2px;
      }

      &.selected {
        background-color:$brand-grey1;

        a {
          color: $brand-red;
        }
      }
    }
  }

  .statistics-title{
    color: $brand-grey13;
    height: 30px;
 }

  .arrow1 {
    color: $brand-grey13;
    border-top: solid 3px $brand-grey13;
    border-right: solid 3px $brand-grey13;
    height:9px;
    width: 9px;
    &.previous {
      transform: rotate(-135deg) translate(-12px, 1px);
    }
    &.next {
      transform: rotate(45deg) translate(12px, 1px);
    }
  }

  .page-stats {
    flex: 0 0 auto;
    align-self: center;
  }

  @media (max-width: $media-md) {
    .pager {
      height: 4rem !important;
      flex-direction: column;
    }
    .pagernav {
      margin-top: 5px;
      flex: 0 0 0 !important;
    }
    .page-stats {
      margin-top: 5px;
      align-self: flex-start;
    }
  }

  @media (max-width: $media-xs) {
    .pager {
      height: 5rem !important;
    }
  }

  @media (max-width: $media-xs) {
    .arrow-box {
      margin: 0 !important;
    }
  }

  @media (min-width: $media-md) {
    .showing {
      width: auto;
    }
  }
</style>
