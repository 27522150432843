<template>
  <div class="row customermatchdetail">
    <div class="col-6">
      <div class="img">
        <friendshipicon :friendname="friendship.volunteer.firstName" :state="friendship.friendshipState" />
      </div>
      <div class="summary">
        <div class="volunteername">
          <a href="javascript:void(0)" @click="selectVolunteer">{{ friendship.volunteer.firstName }} {{ friendship.volunteer.lastName }}</a>
          <span v-if="isNetFriendship" class="icon" :title="$t('customerSearch.netFriendship')">
            <font-awesome-icon icon="tv" />
          </span>
          <span v-else class="icon" :title="$t('friendshipTypes.oneToOne')">
            <font-awesome-icon :icon="['far', 'handshake']" />
          </span>
        </div>
        <span class="">{{ genderAgeText }}</span>
        <div class="">
          {{ section }}
        </div>
      </div>
    </div>
    <div class="col-6 contact">
      <div class="">
        {{ customerState }}
      </div>
      <div class="">
        {{ startedDate }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import { LocaleMessage } from 'vue-i18n'
  import friendshipicon from './FriendshipIcon.vue'
  import datehelper from '../../mixins/dates.js'
  import enumhelper from '../../mixins/enums.js'
  import moment from 'moment'
  import { FriendshipStandardDto, FriendshipNetDto, FriendManagementDto } from '@/types'

  export default Vue.extend({
    name: 'CustomerMatchDetail',
    components: { friendshipicon },
    mixins: [enumhelper, datehelper],
    props: {
      friendship: {
        type: Object as () => FriendshipNetDto | FriendshipStandardDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      }
    },
    computed: {
      computedGender (): LocaleMessage | string {
        // @ts-ignore Cannot find mixins
        return this.getGenderTranslation(this.friendship.volunteer.gender)
      },
      computedAge (): number {
        // @ts-ignore Cannot find mixins
        return this.calculateAge(this.friendship.volunteer.birthDate)
      },
      genderAgeText (): LocaleMessage | string {
        return [this.computedGender, this.computedAge ? `${this.computedAge} ${this.$t('customerSearch.years')}` : ''].filter(Boolean).join(', ')
      },
      section (): string {
        var section = _.find(this.$store.state.sections, (s) => {
          return s.id === this.friendship.volunteer.sectionId
        })

        return section ? section.name : ''
      },
      customerState (): LocaleMessage | string {
        // @ts-ignore Cannot find mixins
        return this.getCustomerStatesTranslation(this.friendship.volunteer.state)
      },
      startedDate (): string {
        return moment(this.friendship.started).format('D.M.YYYY')
      },
      isNetFriendship (): boolean {
        return this.group.netFriendship
      }
    },
    methods: {
      selectVolunteer (): void {
        this.$emit('selectFriendship', this.friendship)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .customermatchdetail {
    display: flex;
    align-items: center;
    background-color: $brand-white;
    border-left: solid 5px $volunteer-color-strong;
    padding: 10px 0;
    .img {
      margin-right:5px;
      display: inline-block;
      vertical-align: top;
      margin-top: 5px;
    }
    .summary {
      font-size: 13px;
      display: inline-block;

      .volunteername {
        font-weight: bold;
        text-transform: uppercase;
      }

      .icon {
        color: $brand-red;
        margin-left: 5px;
      }
    }

    .contact {
      font-size: 13px;
      display: inline-block;
      text-align: right;
      width: 100%;
    }
  }
</style>
