import dateHelper from '../helpers/datehelper'
import bitmaskhelper from '../helpers/bitmaskhelper'
import * as _ from 'lodash'

export default {
  methods: {
    matchAvailabilityFrequency (friendAvailabilityFrequency, customerAvailabilityFrequency) {
      return _.intersection(
        bitmaskhelper.bitmaskToArray(friendAvailabilityFrequency),
        bitmaskhelper.bitmaskToArray(customerAvailabilityFrequency)).length > 0
    },
    matchAvailabilityTimes (friendAvailabilityTimes, customerAvailabilityTimes) {
      return _.intersection(
        bitmaskhelper.bitmaskToArray(friendAvailabilityTimes),
        bitmaskhelper.bitmaskToArray(customerAvailabilityTimes)).length > 0
    },
    // Match the age and desired age in both directions, to decide if it is a match.
    ageMatchFriend (volunteerBirthDate, customerDesiredAge, customerBirthDate, volunteerDesiredAge) {
      return this.isDesiredAge(customerBirthDate, volunteerDesiredAge) &&
      this.isDesiredAge(volunteerBirthDate, customerDesiredAge)
    },
    // see if a birthdate is within the age range they wish to meet with.
    // The age ranges are a string, so make sure if these change in the translations, this method will need to change
    isDesiredAge (bdate, desiredAge) {
      return (this.calculateBitmaskFromAge(dateHelper.calculateAge(bdate)) & desiredAge) !== 0
    },
    calculateBitmaskFromAge (age) {
      if (age < 18) {
        return 1
      }
      if (age <= 29) {
        return 2
      }
      if (age <= 45) {
        return 4
      }
      if (age <= 65) {
        return 8
      }
      if (age <= 79) {
        return 16
      }
      return 32
    }
  }
}
