<template>
  <div v-if="isVisible" class="groupdetail">
    <div v-if="selectedGroup">
      <div class="row">
        <div class="col-12">
          <h5>{{ $t('userAdmin.editGroup') }} {{ selectedGroup.name }}</h5>
        </div>
      </div>
      <div class="row groupstatus">
        <div class="col-12">
          <groupstatus :group="selectedGroup" @disable="onDisableGroup" @enable="onEnableGroup" @delete="onDeleteGroup" />
        </div>
      </div>
      <div class="row editgroup">
        <div class="col-12">
          <groupedit ref="groupEditChild" :group="selectedGroup" @saveGroup="onSaveGroup" />
        </div>
      </div>
      <div class="row editmembers">
        <div class="col-12">
          <groupmembers :group="selectedGroup" @makeGroupAdmin="onMakeGroupAdmin" @removeGroupAdmin="onRemoveGroupAdmin" />
        </div>
      </div>
    </div>
    <div v-if="forbidden">
      <div class="row">
        <div class="col-12 text-center mt-5 mb-4">
          <font-awesome-icon icon="ban" size="5x" class="brand-red" />
        </div>
        <div class="col-12 text-center">
          <p>{{ $t('userAdmin.groupDisabledHint') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import groupstatus from './GroupStatus.vue'
  import groupedit from './GroupEdit.vue'
  import groupmembers from './GroupMembers.vue'

  export interface TemplateComponentData {
    selectedGroup: any,
    forbidden: boolean,
    isVisible: boolean
  }

  export default Vue.extend({

    name: 'GroupDetail',
    components: { groupstatus, groupedit, groupmembers },
    data (): TemplateComponentData {
      return {
        selectedGroup: null,
        forbidden: false,
        isVisible: false
      }
    },
    computed: {
      isGroupMemberEditingAllowed (): boolean {
        //@ts-ignore
        if (this.selectedGroup.groupType === 2 && !this.hasAdmin()) {
          // Open Group
          return false
        }
        return true
      }
    },
    watch: {
      selectedGroup () {
        const res = this.canEditGroupDetails()
        if (res) {
          this.isVisible = true
        } else {
          this.isVisible = false
          this.alertAndRedirect()
        }
      }
    },
    mounted () {
      this.loadGroup()

      // register a listener for loadGroup, which will fire this.loadGroup function if the event is evoked
      this.$bus.$on('loadGroup', this.loadGroup)
    },
    beforeDestroy: function () {
      // stop listening to the event.
      this.$bus.$off('loadGroup')
    },
    methods: {
      canEditGroupDetails () {
        // if user is either Admin or Employee or J1 inside correct district or section, all good.
        // @ts-ignore cannot find mixins
        if (this.hasRole('Admin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee')) return true
        // @ts-ignore cannot find mixins
        if (this.hasRole('Employee') && (this.selectedGroup && this.selectedGroup.section && this.hasClaim(this.selectedGroup.section.districtId.toString(), 'DistrictMembership'))) return true
        // @ts-ignore cannot find mixins
        if ((this.hasRole('J1') || this.hasRole('J2')) && this.selectedGroup && this.selectedGroup.section && this.hasClaim(this.selectedGroup.section.id.toString(), 'SectionMembership')) return true

        // if its an action group, only the users above can edit
        if (this.selectedGroup) {
          return this.isAdmin()
        }

        return false
      },
      isAdmin () {
        if (!this.selectedGroup) {
          return false
        }
        var self = this

        //@ts-ignore
        return _.find(this.selectedGroup.members, function (m) {
          return m.id === self.$store.state.profile.profileId && m.isAdmin
        }) !== undefined
      },
      alertAndRedirect () {
        //@ts-ignore
        this.showAlertPermissionDenied().then(() => {
          this.$router.push('/profile')
        })
      },
      back () {
        this.$router.go(-1)
      },
      getDto (group) {
        // group saving needs a simple group dto, not the detailed group
        return {
          id: group.id,
          name: group.name,
          description: group.description,
          groupType: group.groupType,
          groupOrganizer: group.groupOrganizer,
          externalForm: group.externalForm,
          //@ts-ignore
          members: _.map(group.members, function (item) {
            return item.id
          }),
          isAllLocations: group.isAllLocations,
          isActive: group.isActive,
          languages: group.languages,
          category: group.category
        }
      },
      onMakeGroupAdmin (member) {
        var self = this
        var url = self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupmakeadmin[0]
        var groupSectionDto = {
          sectionId: self.selectedGroup.section.id,
          groupId: self.selectedGroup.id,
          profileId: member.id
        }
        this.$http.put(url, groupSectionDto).then((resp) => {
          this.loadGroup()
        })
      },
      onRemoveGroupAdmin (member) {
        var self = this
        var url = self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupremoveadmin[0]
        var groupSectionDto = {
          sectionId: self.selectedGroup.section.id,
          groupId: self.selectedGroup.id,
          profileId: member.id
        }
        this.$http.put(url, groupSectionDto).then((resp) => {
          this.loadGroup()
        })
      },
      onSaveGroup (group) {
        this.saveGroup(group, true).catch((error) => {
          console.log('something went wrong: ', error)
        })
      },
      onEnableGroup () {
        this.selectedGroup.isActive = true
        this.saveGroup(this.selectedGroup, true)
      },
      onDisableGroup () {
        var self = this as any
        this.selectedGroup.isActive = false
        var sectionId = this.selectedGroup.section.id
        this.saveGroup(this.selectedGroup, self.hasRole('Admin')).then(() => {
          if (!self.hasRole('Admin')) {
            self.$router.push(`/admin/section/${sectionId}/group`)
          }
        })
      },
      onDeleteGroup () {
        var self = this
        var sectionId = self.selectedGroup.section.id
        var districtId = self.selectedGroup.district.id
        var url = `${self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupdelete[0]}`.replace('{id}', self.selectedGroup.id)
        this.$http.delete(url).then((resp) => {
          if (sectionId) {
            self.$router.push(`/admin/section/${sectionId}/group`)
          } else {
            self.$router.push(`/admin/district/${districtId}/group`)
          }
        })
      },
      loadGroup () {
        // load the detailed group info for this group
        var groupid = this.$route.params.id
        var self = this
        var url = `${self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupgetbyidlight[0]}?id=${groupid}`
        this.$http.get(url).then((resp: any) => {
          self.selectedGroup = resp.body
        }, f => {
          if (f && f.status === 403) {
            self.forbidden = true
          }
        })
      },
      async saveGroup (group, reload): Promise<void> {
        return new Promise(async (resolve, reject) => {
          try {
            const url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupupdate[0]}`
            const groupDto = this.getDto(group)
            const response = await this.$http.put(url, groupDto)

            if (response.ok) {
              //@ts-ignore
              this.showAlertSuccess(this.$t('groups.updateSuccessful'))
            } else {
              //@ts-ignore
              this.showAlertInfo(this.$t('groups.noUpdate'))
            }

            if (reload) {
              this.loadGroup()
            }

            resolve()
          } catch (error) {
            console.log('something went wrong: ', error)

            //@ts-ignore
            this.showAlertError(this.$t('groups.updateError'))
            reject(error)
          }
        })
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .groupdetail {
    .back {
      margin-bottom:20px;
      svg {
        margin-right:5px;
      }
    }
    .editgroup {
      background-color:$brand-white;
      padding:20px 0;
      margin-bottom: 20px;
    }
    .grouporganizer {
      margin-bottom: 10px;
    }
    .editmembers {
      margin-top: 120px;
    }
  }
</style>
