<template>
  <div class="row comp-search-result my-2">
    <div class="col-12 grid-row">
      <div class="grid-name">
        {{ volunteer.firstName }} {{ volunteer.lastName }}
      </div>
      <div v-if="!pending" class="grid-zip">
        <span v-if="canDelete">
          <span class="action-link" tabindex="0" @click="deleteVolunteer">
            {{ $t('userAdmin.deleteCustomer') }}
          </span>
        </span>
      </div>
      <div v-else class="griz-zip text-right">
        <span class="mt-1"><loading /></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import loading from '../SPRLoading.vue'
  import { CustomerState, UserProfileFindDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    selected: boolean,
    pending: boolean
  }
  export default Vue.extend({
    name: 'FriendVolunteerSearchResult',
    components: { loading },
    props: {
      volunteer: {
        type: Object as () => UserProfileFindDto,
        required: true
      },
      groupId: {
        type: String,
        required: true
      },
      typedProfileId: {
        type: Number,
        required: true
      },
      isNetProfile: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        selected: false,
        pending: false
      }
    },
    computed: {
      canDelete (): boolean {
        return this.isNetProfile
          ? this.volunteer.friendInfo.netProfile.state === CustomerState.NonActive
          : this.volunteer.friendInfo.standardProfile.state === CustomerState.NonActive
      },
      deleteUrl (): string {
        if (this.isNetProfile === true) {
          return this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendvolunteerdeletenet[0].replace('{groupId}', this.groupId).replace('{friendVolunteerNetProfileId}', this.typedProfileId)
        }
        return this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendvolunteerdeletestandard[0].replace('{groupId}', this.groupId).replace('{friendVolunteerStandardProfileId}', this.typedProfileId)
      }
    },
    methods: {
      deleteVolunteer (): void {
        if (confirm(this.$t('userAdmin.confirmCustomerDelete').toString())) {
          var uri = (this.deleteUrl)
          this.$http.delete(uri).then(s => {
            this.$bus.$emit('user-search-research')
          }, f => {
            console.log(f)
          })
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
  .comp-search-result {
    background: $brand-white;
    border: 2px solid $brand-white;

    &:hover {
      border: 2px solid $brand-red;
    }

    .portrait-size {
      height: 40px;
      width: 40px;
      margin: 10px;
    }

    .grid-row {
      display: grid;
      display: -ms-grid;
      grid-template-rows: 60px;
      -ms-grid-rows: 60px;
      grid-template-columns: 1fr 120px;
      -ms-grid-columns: 1fr 120px;
    }

    .grid-name {
      grid-column: 1;
      -ms-grid-column: 1;
      line-height: 60px;

      font-size: 13px;
      letter-spacing: 0.3px;
      font-weight: 700;
      color: $brand-red;
      text-transform: uppercase;
      font-family: "Signa-Light", Sans-Serif;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .grid-zip {
      grid-column: 3;
      -ms-grid-column: 3;
      text-align: right;
      line-height: 60px;

      span {
        line-height: 17px;
        overflow-wrap: break-word;
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
      }
    }

    .action-link {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .action-link-disabled {
      cursor: not-allowed;
      color: $brand-grey3;
    }
  }
</style>
