<template>
  <div class="view-event-roles">
    <div class="event-block-container">
      <div class="row">
        <div class="col-12">
          <p v-for="(iterator) in value" :key="iterator.id">
            {{ iterator.name }}
            <span v-if="iterator.maximumParticipants > 0">({{ iterator.participants.length }}/{{ iterator.maximumParticipants }})</span>
            <span v-else>({{ iterator.participants.length }})</span>
          </p>
          <div v-if="!value || (value && value.length == 0)">
            {{ $t('eventPage.noRoles') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ViewEventRoles',
    components: {
    },
    props: {
      value: {
        type: Array,
        default () {
          return []
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .view-event-roles {

  }
</style>
