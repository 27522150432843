<template>
  <div class="comp-profile-editor-event-admins">
    <div class="row">
      <div class="col-12 mt-3">
        <p>{{ $t('profile.eventAdminsInstructions') }}</p>
      </div>
      <div v-if="hasError" class="col-12 text-center pb-3 pt-3">
        <sprwarning
          :warnings="savewarnings"
        />
      </div>
      <div class="col-7 col-sm-6 col-md-4 pb-3 pt-3">
        <div class="emailinput">
          <sprinput
            v-model="adminemail"
            v-validate="'email'"
            input-type="email"
            :placeholder="placeholdertext"
            name="admin.adminemail"
            :is-valid="!errors.has('admin.adminemail')"
          />
        </div>
      </div>
      <div class="col-5 col-sm-3 col-md-3 pb-3 pt-3">
        <sprbutton
          :size="5"
          :title="$t('profile.addEventAdminButton')"
          click-event="addAdmin"
          class="add-button"
          @addAdmin="onAddAdmin"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 pb-3">
        <span v-if="eventAdmins">
          <pill v-for="admin in eventAdmins" :key="admin.email" v-model="admin.email" :show-delete="true" class="eventadmin-pill" @remove="onRemoveAdmin(admin)" />
        </span>
        <span v-else>
          {{ $t('profile.noEventAdminsCreated') }}
        </span>
      </div>
    </div>
    <div class="row">
      <!-- Save and cancel buttons -->
      <div v-if="!isSaving" class="col-12 col-md-6 my-1">
        <sprbutton click-event="save-event" :title="$t('profile.save')"
                   :size="1"
                   :bordersize="2" class="fill-width" @save-event="onSave()"
        />
      </div>
      <div v-if="!isSaving" class="col-12 col-md-6 my-1">
        <sprbutton click-event="cancel-event" :title="$t('profile.cancel')"
                   :size="1"
                   :bordersize="2" class="fill-width" @cancel-event="onCancel"
        />
      </div>
      <div v-if="isSaving" class="col-12">
        <loading />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import loading from '../SPRLoading.vue'
  import sprwarning from '../SPRWarning.vue'
  import sprinput from '../SPRSimpleText.vue'
  import sprbutton from '../SPRButton.vue'
  import pill from '../SPRPill.vue'
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'

  // the data object
  export interface TemplateComponentData {
    hasError: boolean,
    hasChanges: boolean,
        isSaving: boolean,
        adminemail: string,
        eventAdmins: Admin[],
        origEventAdmins: Admin[],
        newAdmins: string[],
        placeholdertext: LocaleMessage,
        savewarnings: Array<LocaleMessage>
  }
  export interface Admin {
    email: string,
    delegatedByProfileId: any
  }
  export default Vue.extend({
    name: 'ProfileEditorEventAdmins',
    components: { loading, sprwarning, sprinput, sprbutton, pill },
    props: {
      editor: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        hasError: false,
        isSaving: false,
        hasChanges: false,
        adminemail: '',
        eventAdmins: this.$store.state.profile.eventAdmins.slice() as Admin[],
        origEventAdmins: this.$store.state.profile.eventAdmins.slice() as Admin[],
        newAdmins: [] as string[], // Specify the type explicitly
        placeholdertext: this.$t('profile.eventAdminPlaceholderText'),
        savewarnings: [
          this.$t('profile.eventAdminEditorSaveWarning')
        ]
      }
    },
    watch: {
      adminemail: function (val) {
        this.checkForChanges()
      }
    },
    methods: {
      onAddAdmin () {
        if (!this.adminemail) return

        this.$validator.validateAll().then(resp => {
          if (resp) {
            if (!this.eventAdmins.find(admin => admin.email.toLowerCase() === this.adminemail.toLowerCase())) {
              var addedAdmin: Admin = {
                email: this.adminemail.toLowerCase(),
                delegatedByProfileId: this.$store.state.profile.profileId
              }
              this.eventAdmins.push(addedAdmin)
              this.newAdmins.push(addedAdmin.email)
            }
          }
          this.adminemail = ''
        })
      },
      checkForChanges () {
        if (this.newAdmins.length > 0 ||
          this.adminemail !== '' ||
          this.origEventAdmins.length !== this.eventAdmins.length) {
          this.hasChanges = true
        } else {
          this.hasChanges = false
        }
        this.$emit('onChange', this.hasChanges)
      },
      onRemoveAdmin (adminToRemove: Admin) {
        this.eventAdmins = this.eventAdmins.filter(admin => admin.email.toLowerCase() !== adminToRemove.email.toLowerCase())
        this.newAdmins = this.newAdmins.filter(email => email.toLowerCase() !== adminToRemove.email.toLowerCase())
        this.checkForChanges()
      },
      clearNewAdmins () {
        for (const adminToDelete in this.newAdmins) {
          this.eventAdmins = this.eventAdmins.filter(admin => admin.email.toLowerCase() !== adminToDelete.toLowerCase())
          this.newAdmins = this.newAdmins.filter(email => email.toLowerCase() !== adminToDelete.toLowerCase())
          this.checkForChanges()
        }
      },
      onSave () {
        this.hasError = false
        this.isSaving = true
        this.$validator.validateAll().then(success => {
          if (success) {
            this.$store.commit('profileeventadmins', this.eventAdmins)
            //@ts-ignore
            this.updateOwnProfile(succ => {
              // invalidate the short token to force a refresh of section data from the back end
              //@ts-ignore
              this.getShortToken(sessionStorage.getItem('authtoken'))
                .then(() => {
                  // send messages to the users
                  var url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.sendnewprofileeventadminmessages[0]}`
                  var newEventAdminMessageDto = {
                    emails: this.newAdmins
                  }
                  return this.$http.post(url, newEventAdminMessageDto)
                })
                .then(() => {
                  this.newAdmins = []
                  this.isSaving = false
                  //@ts-ignore
                  this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
                  window.location.href = '/profile'
                })
                .catch(() => {
                  this.newAdmins = []
                  this.hasError = true
                  this.isSaving = false
                  //@ts-ignore
                  this.showAlertError(this.$t('common.fetchFailed'))
                })
            }, () => {
              this.newAdmins = []
              this.hasError = true
              this.isSaving = false
              //@ts-ignore
              this.showAlertError(this.$t('common.fetchFailed'))
            })
          } else {
            this.isSaving = false
          }
        })
      },
      onCancel () {
        this.clearNewAdmins()
        window.location.href = '/profile'
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';

.comp-profile-editor-event-admins {
  .eventadmin-pill {
    margin-right: 4px;
  }
}

</style>
