<template>
  <div v-if="message" class="container messagedetail">
    <div class="row">
      <div class="col-12">
        <backbar />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="subject">
          <h3>{{ message.subject }}</h3>
        </div>
      </div>
    </div>
    <div class="row message">
      <div class="col-12">
        <div class="messagesection">
          <div class="cap-label">
            {{ $t('message.sender') }}
          </div>
          <div class="label-content">
            {{ computedSender }}
          </div>
        </div>
        <div class="messagesection">
          <div class="cap-label">
            {{ $t('message.date') }}
          </div>
          <div class="label-content">
            {{ sentDate }}
          </div>
        </div>
        <div v-if="message && message.content" class="messagebody">
          <!-- TODO: message.content is sanitized on the back end, but still we should not use v-html if possible. This is a possible security issue -->
          <p v-linkified:options="{ nl2br: true }" v-html="message.content" />
        </div>
        <div v-if="message && message.messageType === 1" class="actions text-right">
          <sprbutton type="primary"
                     :size="1"
                     :title="$t('message.delete')"
                     class="spr-redborder button-width"
                     click-event="deleteMessage"
                     @deleteMessage="showRemove = !showRemove"
          />
        </div>
      </div>
    </div>
    <b-modal v-model="showRemove"
             :title="$t('message.youSure')"
    >
      <div>
        {{ $t('message.confirmDelete') }}
      </div>
      <mfoot slot="modal-footer"
             :buttons="modalButtons()"
             @cancel="showRemove = !showRemove"
             @remove="onDelete"
      />
    </b-modal>
  </div>
  <div v-else>
    ...
  </div>
</template>

<script>
  import moment from 'moment'
  import sprbutton from '../SPRButton.vue'
  import messages from '../../mixins/messages.js'
  import mfoot from '../modals/ModalFooter.vue'
  import backbar from '../BackBar.vue'
  import urlPlaceholder from '../../types/constants'

  export default {
    name: 'MessageDetail',
    components: { sprbutton, mfoot, backbar },
    mixins: [messages],
    data () {
      return {
        showRemove: false,
        message: {},
        sender: {}
      }
    },
    computed: {
      sentDate () {
        return moment.utc(this.message.sent).local().format('D.MM.YYYY HH:mm')
      },
      computedSender () {
        if (this.sender.firstName) {
          return this.sender.firstName + ' ' + this.sender.lastName
        }
        return this.message.sender
      }
    },
    mounted () {
      var self = this
      this.getMessage().then(function () {
        if (self.message.senderId) {
          var uriProfile = (self.$store.state.apiBases.userservice + self.$store.state.apiEndpoints.profile[0]).replace('{0}', self.message.senderId)

          self.$http.get(uriProfile).then(s => {
            self.sender = s.body
            self.markMessageAsRead(self.message)
          })
        } else {
          self.markMessageAsRead(self.message)
        }
      })
    },
    methods: {
      modalButtons: function () {
        return [
          { text: this.$t('message.delete'), event: 'remove' },
          { text: this.$t('message.cancel'), event: 'cancel' }
        ]
      },
      findMessage () {
        var self = this
        var messageId = Number(self.$route.params.id)
        var messageType = Number(self.$route.params.type)
        return _.find(self.$store.state.messages, function (msg) {
          return msg.id === messageId && msg.messageType === messageType
        })
      },
      replaceUrls (msg) {
        var escapedUrl = urlPlaceholder.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        var regex = new RegExp(escapedUrl, 'g')
        const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')

        return msg.replace(regex, baseUrl)
      },
      getMessage () {
        var self = this
        return new Promise((resolve, reject) => {
          if (self.$store.state.messages.length > 0) {
            self.message = self.findMessage()
            self.message.content = self.replaceUrls(self.message.content)
            resolve()
          } else {
            // need to load the back end
            this.loadMessages().then(() => {
              self.message = self.findMessage()
              self.message.content = self.replaceUrls(self.message.content)
              resolve()
            })
          }
        })
      },
      onDelete () {
        this.deleteMessage(this.message).then(() => {
          this.$router.replace('/messages')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";
  .messagedetail {

    .back {
      margin-top: 15px;

      svg {
        margin-right: 5px;
      }
    }

    .subject {
      margin-top:15px;
    }

    .message {
      background-color:$brand-white;
    }

    .messagesection {
      margin: 10px 0 20px 0;
      .cap-label {
        color:$brand-red;
      }
    }

    .actions {
      margin: 30px 0;
    }
  }
</style>
