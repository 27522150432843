<template>
  <div class="row groupview" :class="{ 'is-group-admin': isGroupAdmin }">
    <div class="col-md-7">
      <div class="name">
        <a href="javascript:void(0)" @click="viewGroup">{{ group.name }}</a>
      </div><br>
      <div v-if="isGroupAdmin" class="isgroupadmin">
        <font-awesome-icon icon="star" />{{ $t('groups.groupManager') }}
      </div>
      <div class="d-inline-block">
        {{ group.totalMembers }} {{ $t('groups.totalMembers') }}
      </div>
      <div v-if="category" class="d-inline-block">
        &nbsp;|| {{ category }}
      </div>
    </div>

    <div class="col-md-5 text-right">
      <div class="grouptype">
        {{ getGroupTypesTranslation(group.groupType) }}
      </div>
      <div class="opento">
        {{ openTo }}
      </div>
    </div>
    <div v-if="detailed" class="col-12">
      <div v-linkified:options="{ nl2br: true }" class="description">
        {{ group.description }}
      </div>
      <div class="smalltext">
        {{ languages }}
      </div>
    </div>
    <div v-else class="col-12">
      <div class="smalltext">
        {{ languages }}
      </div>
    </div>
    <div v-if="hasControls" class="col-12">
      <div v-if="hasSectionName" class="smalltext">
        {{ $t('groups.sectionCaption') + ': ' + group.sectionName }}
      </div>
      <div v-if="hasOrganizerName" class="smalltext">
        <span>{{ $t('groups.organizerCaption') + ': ' }}<groupOrganizer :text-only="true" :value="group.groupOrganizer" /></span>
      </div>
      <div v-if="!applyPending" class="controls text-right">
        <div v-if="isGroupMember" class="joined">
          <span>{{ $t('groups.isMember') }}</span><font-awesome-icon icon="check" />
        </div>
        <div v-if="appliedToGroup === 1 || isMemberPendingByOwnRequest" class="joined">
          <span>{{ $t('groups.hasApplied') }}</span><font-awesome-icon icon="check" />
        </div>
        <sprbutton v-if="appliedToGroup !== 1 && !isMemberPendingByAdminRequest && !isMemberPendingByOwnRequest && !isGroupMember && group.groupType === 1" type="primary"
                   :size="1"
                   :title="$t('groups.requestJoinGroup')"
                   class="spr-redborder button-width"
                   click-event="joinGroup"
                   @joinGroup="joinActionGroup"
        />
        <sprbutton v-if="!isGroupMember && isMemberPendingByAdminRequest && group.groupType === 1" type="primary"
                   :size="1"
                   :title="$t('groups.joinGroup')"
                   class="spr-redborder button-width"
                   click-event="acceptInvitation"
                   @acceptInvitation="acceptInvitation"
        />
        <sprbutton v-if="!isGroupMember && group.groupType === 2" type="primary"
                   :size="1"
                   :title="$t('groups.joinGroup')"
                   class="spr-redborder button-width"
                   click-event="joinGroup"
                   @joinGroup="joinStandardGroup"
        />
      </div>
      <div v-else class="controls text-right">
        <div class="spinner brand-red">
          <loading />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="mobile-info text-right">
        <div class="mobile opento">
          {{ openTo }} |
        </div>
        <div class="mobile grouptype">
          {{ getGroupTypesTranslation(group.groupType) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import _ from 'lodash'
  import enumhelper from '../../mixins/enums.js'
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'
  import groupOrganizer from '../SPRGroupOrganizersDropdown.vue'
  import messages from '../../mixins/messages.js'

  export interface GroupViewComponentData {
    appliedToGroup: number,
    applyPending: boolean
  }

  export default Vue.extend({
    name: 'GroupView',
    components: { sprbutton, loading, groupOrganizer },
    mixins: [enumhelper, messages],
    props: {
      group: {
        type: Object,
        required: true
      },
      detailed: {
        type: Boolean,
        default: false
      },
      hasControls: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        appliedToGroup: 0,
        applyPending: false
      }
    },
    computed: {
      category (): string {
        return this.getEventCategoriesTranslation(this.group.category)
      },
      hasSectionName (): boolean {
        // return this.group.sectionname
        // disable for now
        return false
      },
      hasOrganizerName (): boolean {
        if (this.group.groupType === 2) {
          return false
        }
        return this.group.groupOrganizer
      },
      isGroupMember (): boolean {
        var profile = this.$store.state.profile
        return _.includes(this.group.members, profile.profileId)
      },
      isGroupAdmin (): boolean {
        var profile = this.$store.state.profile
        if (this.group && this.group.members && profile != null) {
          return _.find(this.group.members, (m) => {
            return m.Id === profile.profileId && m.isAdmin
          })
        }
        return false
      },
      languages (): string {
        return this.getLanguagesMaskTranslation(this.group.languages)
      },
      openTo (): any {
        return this.group.isAllLocations ? this.$t('groups.allLocations') : (this.group.sectionId > 0 ? this.$t('groups.sectionOnly') : this.$t('groups.districtOnly'))
      },
      isMemberPendingByAdminRequest (): boolean {
        var self = this
        return _.find(this.group.pendingMembers, function (m) {
          return m.key === self.$store.state.profile.profileId && m.value
        })
      },
      isMemberPendingByOwnRequest (): boolean {
        var self = this
        return _.find(this.group.pendingMembers, function (m) {
          return m.key === self.$store.state.profile.profileId && !m.value
        })
      }
    },
    methods: {
      viewGroup () {
        if (this.isGroupMember) this.$router.push(`/groups/group/${this.group.id}`)
        return false
      },
      joinActionGroup () {
        if (!this.isGroupMember) {
          var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.grouprequestmembership[0]}`
          const friendProfile = {
            id: this.$store.state.profile.profileId,
            name: this.$store.state.profile.firstName + ' ' + this.$store.state.profile.lastName,
            sectionId: this.$store.state.profile.sectionId
          }
          const payload = {
            payload1: this.group.id,
            payload2: [friendProfile],
            payload3: this.group.name
          }
          this.$http.put(url, payload).then((resp) => {
            this.sendEmailAfterJoiningActionGroup()
          })
        }
        return false
      },
      sendEmailAfterJoiningActionGroup () {
        this.applyPending = true
        var req = {
          payload1: this.group.name,
          payload2: this.group.id,
          payload3: this.$store.state.profile.firstName + ' ' + this.$store.state.profile.lastName
        }
        var self = this
        var uri = this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.applytoactiongroup[0]
        this.$http.post(uri, req).then(s => {
          self.applyPending = false
          self.appliedToGroup = 1
        }, f => {
          self.applyPending = false
          self.appliedToGroup = -1
        })
      },
      joinStandardGroup () {
        var self = this
        if (!this.isGroupMember) {
          var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupjoin[0]}`
          var messageDto = {
            groupId: this.group.id,
            profileId: this.$store.state.profile.profileId
          }
          this.$http.put(url, messageDto)
            // add the new group to the token
            //@ts-ignore
            .then(() => this.getShortToken())
            .then((resp) => {
              self.group.members += self.$store.state.profile.profileId
            })
        }
        return false
      },
      acceptInvitation () {
        var self = this
        var url = `${self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupacceptinvitation[0]}`.replace('{0}', self.group.id)
        this.$http.put(url, null).then((resp) => {
          this.$router.push(`/groups/group/${this.group.id}`)
        })
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .group-organizers-dropdown {
    display: inline-block;
  }
  .groupview {
    padding: 20px 10px;
    .name {
      color: #CC0000;
      font-size: 13px;
      font-family: $font-light;
      font-weight: bold;
      display: inline-block;
      text-transform: uppercase;
    }
    .isgroupadmin {
      display:inline-block;
      color:$brand-success;
      svg {
        margin-right:5px;
      }
    }
    .description {
      margin: 10px 0;
    }
    .grouptype {
      font-family: $font-bold;
    }

    .joined {
      color:$brand-success;
      margin-bottom: 10px;
      svg {
        margin-left:5px;
      }
    }

    .mobile {
      display: none;
    }

    .mobile-info {
      margin-bottom: -15px;
      margin-top: 10px;
    }
  }

  @media (max-width: $media-sm-max) {
    .groupview {

      .grouptype, .opento {
        display: none;
      }

      .mobile {
        display: inline-block;
      }
    }
  }
</style>
