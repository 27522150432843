<template>
  <div class="landing-page">
    <div class="lp-content-container">
      <div class="header">
      </div>
      <div class="content">
        <span v-html="$t('campaignLandingPage.hungerdaysContent')" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Event',
    pageName: 'eventpage',
    data () {
      return {
        sprCampaign: {
          id: -1,
          name: '',
          moreInfoQuestion: '',
          description: '',
          date: '',
          location: '',
          price: '',
          eventRoles: [],
          startTime: {
            day: 1,
            month: 1,
            year: 0,
            hour: 0,
            minute: 0
          },
          endTime: {
            day: 1,
            month: 1,
            year: 0,
            hour: 0,
            minute: 0
          },
          isAllDay: false,
          occurance: 1,
          repeat: 2,
          registrationDeadline: null,
          streetAddress: '',
          zipCode: '',
          city: '',
          contact: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
          },
          translations: [],
          image: 'https://sprvatistorage.blob.core.windows.net/eventimages/event_-1-51453337-6F05-4C55-A7E5-9040AD35168A.jpeg',
          imageThumb: 'https://sprvatistorage.blob.core.windows.net/eventimages/eventthumb6-96ef1a0b-b84e-4ce5-84a9-f0764b15278d.jpeg',
          oldImage: '',
          oldImageThumb: '',
          placeName: '',
          isOnline: false,
          isPublic: true,
          duration: '',
          category: 15,
          type: 11,
          eventAdmins: [],
          eventOrganizer: {
            eventOrganizerType: null,
            organizerId: null
          },
          languages: 1
        }
      }
    }
  }

</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";
  .landing-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    .lp-content-container {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      width: 100%;
      max-width: 1200px;

      .header {
        flex: 1 0 auto;
        min-height: 300px;
        width: 60%;
        margin: auto;
        background-image: url(https://sprvatistorage.blob.core.windows.net/eventimages/event_-1-51453337-6F05-4C55-A7E5-9040AD35168A-1600.jpeg);
        background-repeat: no-repeat;
        background-size: cover;
        padding: 60px 0;
        color: #fff;
      }

      .content {
        align-self: center;
        width: 60%;
        min-height: calc(100vh - 489px);
      }

      @media (max-width: $media-sm) and (orientation: portrait) {
        .header {
          width: 90%;
        }
        .content {
          width: 90%;
        }
      }
    }
  }

</style>
