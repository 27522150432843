<template>
  <div class="socialmedia">
    <div class="sharethis-inline-share-buttons"></div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  export default Vue.extend({
    name: 'ShareThis',
    mounted (): void {
      // script to add sharethis social media buttons
      // @ts-ignore
      if (window.__sharethis__) {
        // @ts-ignore
        window.__sharethis__.initialize()
      } else {
        const scr = document.createElement('script')
        scr.setAttribute('async', 'true')
        scr.setAttribute(
          'src',
          '//platform-api.sharethis.com/js/sharethis.js#property=5a6083b04fc3d70011b2ee01&product=inline-share-buttons'
        )

        var head = document.getElementsByTagName('head')
        if (head.length) {
          head[0].appendChild(scr)
        }
      }
    }
  })
</script>