<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .poolchooserstandard {
    .loading {
      text-align: center;
    }
  }

</style>

<template>
  <div class="poolchooserstandard">
    <div class="row">
      <div class="col-12">
        <poolview
          v-for="pool in pools"
          v-show="!loading"
          :key="pool.id"
          :pool="pool"
          :groupid="friendManagementGroup.id"
          action-view="join"
          @join="onJoin"
        />
        <div v-show="loading" class="loading">
          <div class="spinner">
            <loading />
          </div>
        </div>
      </div>
      <div v-if="!pools || !pools.length" class="col-12 text-center">
        {{ $t('customerSearch.noPoolsAvailable') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import poolview from './PoolView.vue'
  import loading from '../SPRLoading.vue'
  import { FriendManagementDto, FriendPoolFindStandardDto } from '@/types'

  // the data object
  export interface TemplateComponentData {
    loading: boolean,
    pools: Array<FriendPoolFindStandardDto>
  }

  export default Vue.extend({
    name: 'PoolChooserStandard',
    components: { poolview, loading },
    props: {
      selectedPools: {
        type: Array as () => Array<FriendPoolFindStandardDto>,
        default: () => []
      },
      friendManagementGroup: {
        type: Object as () => FriendManagementDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        loading: false,
        pools: []
      }
    },
    mounted (): void {
      this.getPools()
    },
    methods: {
      getPools (): void {
        this.loading = true
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.getfriendmanagementpoolsstandard[0].replace('{fmgid}', this.friendManagementGroup.id)
        this.$http.get(url).then(resp => {
          this.pools = _.filter(resp.data, (pool) => {
            return pool.status === 1 && // Only active
              !this.isMember(pool) // not a member of this pool already
          })
          this.loading = false
        })
      },
      isMember (pool): boolean {
        // is the member in the selected Pool already?
        return _.includes(
          _.map(this.selectedPools, (pool) => {
            return pool.id
          })
          , pool.id)
      },
      onJoin (pool): void {
        this.$emit('poolchosen', pool)
      }
    }
  })
</script>
