<template>
  <div class="ad-state-machine" />
</template>

<script lang="ts">
  import Vue from 'vue'

  // the data object
  export interface TemplateComponentData {
    uri: string
  }

  export default Vue.extend({
    name: 'AdStateMachine',
    data (): TemplateComponentData {
      return {
        uri: ''
      }
    },
    computed: {
      adState (): any {
        if (!this.uri || this.uri.indexOf('#') < 0) {
          return null
        }

        const fragment = this.uri.split('#')[1]

        if (!fragment || fragment.indexOf('id_token=') < 0) {
          return null
        }

        var params = fragment.split('&')
        var rv = { }
        params.forEach(function (val) {
          var pair = val.split('=')
          if (pair.length === 2) {
            rv[pair[0]] = pair[1]
          }
        })

        return rv
      }
    },
    mounted: function () {
      this.uri = window.location.href.replace('#/', '#')
      this.passAdToBackend()
    },
    methods: {
      passAdToBackend: function () {
        if (this.adState && this.adState.id_token) {
          var uri = (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.adauth[0]).replace('{token}', this.adState.id_token).replace('{session_state}', this.adState.session_state)
          this.$http.get(uri).then(this.actOnSuccessfullAdResponse, (response) => {
            // Handle in case of an error
            if (response.body && response.body.error && response.body.error.errors.length > 0) {
              console.log('AD login failed: ' + response.body.error.errors)
            }
            this.$router.push('/login?message=aderr&err=ADcallfail')
          })
        }
      },
      actOnSuccessfullAdResponse: function (response) {
        // in case of success, ther response always has a body with an object of type LoginResult
        // this LoginResult Object has a couple of relevant properties for this case:
        // - Operation: Which can have the value HandleValidatedAdUserLoginRequest(2), or RegisterNewAdUser (5)
        // - Token: is the JWT token that is used for authentication, or a token that is fabricated to act as a JWT token without content of an actual underlying user
        // These are the only properties that are relevant for this case
        if (response.body && response.body.token) {
          // this means that the authorization was successfull and a token has been returned
          if (response.body.operation === 2) {
            //2 is HandleValidatedAdUserLoginRequest
            var redirectUrl = '/login?token=' + response.body.token
            var callback = sessionStorage.getItem('logincallback')
            if (callback) {
              sessionStorage.removeItem('logincallback')
              redirectUrl += `&from=${callback}`
            }
            this.$router.push(redirectUrl)
          } else if (response.body.operation === 5) {
            this.$router.push({ name: 'wizard-ad', params: { token: response.body.token } })
          }
        } else {
          this.$router.push('/login?message=aderr&err=no_response')
        }
      }
    }
  })
</script>
