<template>
  <div class="col-12 user-row">
    <div class="row">
      <div class="col-md-6 col-12 user">
        <div class="user-img">
          <sprportrait :style="portraitStyle" class="portrait-size" />
        </div>
        <div class="user-name">
          <a href="#">{{ member.firstName }} {{ member.lastName }}</a>
          <span class="section">{{ section(member.sectionId) }}</span>
        </div>
      </div>
      <div class="col-md-6 col-12 actions">
        <div v-if="member.requestedByAdmin" class="pendingtext">
          {{ $t('groups.pendingConsent') }}
          <font-awesome-icon icon="exclamation-triangle" class="pendingsave" />
        </div>
        <sprbutton v-if="!inProgress && !added && !member.requestedByAdmin"
                   type="primary"
                   :size="4"
                   :title="$t('groups.addPendingVolunteerButton')"
                   class="spr-redborder button-width"
                   click-event="addVolunteer"
                   @addVolunteer="addPendingVolunteer"
        />
        <sprbutton v-if="!inProgress && !added"
                   type="primary"
                   :size="4"
                   :title="$t('groups.remove')"
                   class="spr-redborder button-width"
                   click-event="removeVolunteer"
                   @removeVolunteer="removePendingVolunteer"
        />
        <sprbutton v-if="!inProgress && added"
                   type="primary"
                   :size="4"
                   :title="$t('groups.cancelAddPendingVolunteerButton')"
                   class="spr-redborder button-width"
                   click-event="cancelAdd"
                   @cancelAdd="cancelPendingAdd"
        />
        <div v-if="!inProgress && added" class="alreadyselected">
          {{ $t('groups.volunteerIsSelected') }}
          <font-awesome-icon icon="check" />
        </div>
        <div v-if="inProgress" class="loading">
          <div class="spinner">
            <loading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import sprportrait from '../SPRPortrait.vue'
  import loading from '../SPRLoading.vue'
  import anonPortrait from '../../assets/img/anon-portrait.png'

  export default {
    name: 'Pendingmember',
    components: { sprbutton, sprportrait, loading },
    props: {
      member: {
        type: Object,
        default: null
      },
      groupId: {
        type: Number,
        default: 0
      },
      groupName: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        inProgress: false,
        added: false
      }
    },
    computed: {
      portraitStyle: function () {
        var port = this.member.portrait ? this.member.portrait : anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      }
    },
    methods: {
      cancelPendingAdd () {
        const self = this
        const payload = { payload1: this.groupId, payload2: [this.member.id] }
        const uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupremmembers[0]
        this.$http.post(uri, payload).then(s => {
          const url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.grouprequestmembership[0]}`
          const friendProfile = {
            id: this.member.id,
            name: this.$store.state.profile.firstName + ' ' + this.$store.state.profile.lastName,
            sectionId: this.member.sectionId
          }
          const adminIsCancelling = true
          const membershippayload = {
            payload1: this.groupId,
            payload2: [friendProfile],
            payload3: this.groupName,
            payload4: adminIsCancelling
          }
          this.$http.put(url, membershippayload).then((resp) => {
            self.added = false
            self.$bus.$emit('cancelAddPendingVolunteer', self.member)
          })
        })
      },
      addPendingVolunteer: function () {
        if (this.inProgress) {
          return
        }
        this.inProgress = true

        const self = this
        const payload = {
          payload1: this.groupId,
          payload2: [this.member.id]
        }

        const uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupaddmembers[0]
        this.$http.put(uri, payload).then(s => {
          self.inProgress = false
          self.added = true
          self.$bus.$emit('addPendingVolunteer', this.member)
        }, f => {
          self.inProgress = false
        })
      },
      removePendingVolunteer: function () {
        if (this.inProgress) {
          return
        }
        this.inProgress = true

        const self = this
        const payload = {
          payload1: this.groupId,
          payload2: this.member.id
        }

        const uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupremmembershiprequest[0]
        this.$http.post(uri, payload).then(s => {
          self.inProgress = false
          self.$bus.$emit('removePendingVolunteer', this.member)
          self.$bus.$emit('group-member-removed', payload)
        }, f => {
          self.inProgress = false
        })
      },
      section (sectionId) {
        var section = _.find(this.$store.state.sections, (s) => {
          return s.id === sectionId
        })

        return section ? section.name : ''
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
    .nav-header  {
      background-color: #EEEEEE;
      height: 80px;
      margin-bottom: 30px;
      border-bottom: solid 1px #DDDDDD;
      h3 {
        color: $brand-red;
      }
      .back {
        color: #000;
        i{
          color: $brand-red;
          margin-right: 5px;
        }
      }
    }

    .button-row {
      margin-bottom: 40px;
      .spr-button {
        background: $brand-red;
        color: #fff;
      }
    }
    .spr-searchbox {
      margin-bottom: 20px;
    }

    .results-row {
      .loading {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color:rgba(255, 255, 255, 0.8);

        .spinner {
          margin: auto;
          color:$brand-red;
        }
      }
      .user-row {
        background: #fff;
        margin-bottom: 5px;
        padding: 15px;
        border-bottom: 1px solid $brand-grey2;
        .user {
          .user-img {
            float: left;
            margin-right: 15px;
          }
          a {
            color: $brand-red;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
        .portrait-size {
          width: 50px;
          height: 50px;
        }
      }
    }
    .spacer {
      margin-bottom:20px;
    }
  .pendingtext{
    display: inline;
  }
</style>
