<template>
  <div class="sprmessage">
    <div class="row">
      <div class="d-none d-sm-none d-md-none d-lg-block col-2">
        <userprofileicon :profile="typeof sender === 'object' ? sender : { }" />
      </div>
      <div class="col-12 col-sm-5">
        <div class="sender" :title="getStatus(message.messageStatus)">
          <!-- links to group messages go to the message thread instead of the plain message view-->
          <div v-if="isGroupLink" class="subject">
            <div class="state" :class="stateClass" />
            <router-link :to="{ name: 'groupnotes', params: { groupid: message.groupId.toString() }, query: { msg: message.id}}">
              <span class="sentby cap-label">{{ messageSender }}</span>
            </router-link>
          </div>
          <!-- non-group messages get the plain old message view. Poor them. -->
          <div v-else>
            <div class="state" :class="stateClass" />
            <router-link :to="`/messages/${message.messageType}/${message.id}`">
              <span class="sentby cap-label">{{ messageSender }}</span>
            </router-link>
          </div>
          <div class="date">
            {{ sentDate }}
          </div>
        </div>
      </div>
      <div class="col-12 col-sd-7 col-lg-5">
        <div v-if="isGroupLink" class="subject">
          <router-link :to="{ name: 'groupnotes', params: { groupid: message.groupId.toString() }, query: { msg: message.id}}">
            <span v-if="isReply">{{ $t('groups.replyPrefix') }} </span>{{ message.subject }}
          </router-link>
        </div>
        <div v-else class="subject">
          <router-link :to="`/messages/${message.messageType}/${message.id}`">
            {{ message.subject }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
message schema:
{
  "subject":"This is another message",
  "content":"This is another message.",
  "sent":"2017-12-27T10:13:08.4366667",
  "messageStatus":1,
  "messageType":1,
  "sender":"",
  "senderId":1
}
*/
  import userprofileicon from '../UserProfileIcon.vue'
  import moment from 'moment'
  export default {
    name: 'SprMessage',
    components: { userprofileicon },
    props: {
      message: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        sender: {
        }
      }
    },
    computed: {
      isReply: function () {
        return this.message && !!this.message.parentMessageId
      },
      isGroupLink: function () {
        return this.message && !!this.message.groupId
      },
      messageSender () {
        if (this.message.sender) {
          return this.message.sender
        } else {
          return this.sender.firstName ? `${this.sender.firstName} ${this.sender.lastName}` : ''
        }
      },
      sentDate () {
        return moment.utc(this.message.sent).local().format('D.MM.YYYY HH:mm')
      },
      stateClass () {
        switch (this.message.messageStatus) {
        case 1:
          return 'new'

        case 2:
          return 'marked-as-read'

        case 3:
          return 'hidden'

        case 4:
          return 'deleted'

        default:
          return 'unknown'
        }
      }
    },
    created () {
      if (this.message && this.message.type === 1) {
        var self = this
        var uriProfile = (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.profile[0]).replace('{0}', this.message.senderId)
        this.$http.get(uriProfile).then(s => {
          self.sender = s.body
        })
      }
    },
    methods: {
      getStatus (state) {
        switch (state) {
        case 1:
          return this.$t('message.new')
        case 2:
          return this.$t('message.read')
        default:
          return ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";
  .sprmessage {
    display: flex;
    flex-wrap:wrap;
    padding: 5px;
    &:nth-child(odd) {
      background: rgba(0,0,0,0.03);
    }

    .sender {
      .state {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        margin-right: 5px;

        &.new {
          display: inline-block;
          background-color:$brand-success;
          border-color:$brand-success;
        }
        &.marked-as-read {
          display: inline-block;
          background-color:$brand-grey5;
          border-color:$brand-grey5;
        }
      }

      .sentby {
        color:$brand-red;

        &:hover {
          background-color:auto;
        }
      }
    }

    .subject {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .cap-label {
    margin-left: -13px;
    white-space: pre-wrap;
    }
  }
</style>
