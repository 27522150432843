<template>
  <div class="container">
    <div class="profileidentvolunteerformstandard">
      <div class="row">
        <div class="col-12">
          <h2>{{ addEditTitle }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center py-2">
          <validation :id="'validationTarget' + _uid"
                      ref="validationtarget"
                      :validation-errors="validationErrors"
                      exclude-field-reference
                      include-title
          />
        </div>
      </div>
      <div class="row profilehead">
        <div class="col-7">
          <span class="subheader">{{ $t('volunteerForm.standardInfo') }}</span>
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <VolunteerProfileStandard v-model="volunteer.standardProfile" :volunteer="volunteer.standardProfile" @update="onVolunteerStandardProfileChanged" />
        </div>
      </div>
      <div class="row feature-panel buttons">
        <div class="col-6 cancel">
          <SprButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.cancel')"
                     click-event="onCancel"
                     @onCancel="cancel"
          />
        </div>
        <div class="col-6 save">
          <SprButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.save')"
                     click-event="onSave"
                     @onSave="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import SprButton from '@/components/SPRButton.vue'
  import VolunteerProfileStandard from '@/components/friends/VolunteerProfileStandard.vue'
  import validation from '../SPRValidationError.vue'
  import Alerts from '@/mixins/alerts.js'
  import { ErrorBag, FieldError } from 'vee-validate'
  import { FriendVolunteerDto, FriendVolunteerProfileStandardDto, FriendVolunteerProfileNetDto, CustomerState, PreferredGenders, PreferredAges, FriendshipTypes } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    volunteer: FriendVolunteerDto,
    isSavedOrCancelled: boolean
  }

  // This is the component for a user to add and update their own Friend profile.
  export default Vue.extend({
    name: 'ProfileIdentVolunteerFormStandard',
    components: { validation, VolunteerProfileStandard, SprButton },
    mixins: [Alerts],
    props: {
      isAdd: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        volunteer: {} as FriendVolunteerDto,
        isSavedOrCancelled: false
      }
    },
    computed: {
      addEditTitle (): string | LocaleMessage {
        return this.isAdd
          ? this.$t('volunteerForm.addTitle')
          : this.$t('volunteerForm.updateTitle')
      },
      validationErrors (): Array<Array<string>> {
        var errs: ErrorBag = this.errors ? this.errors : []
        if (errs.items.length) {
          return errs.items.map((err: FieldError) => {
            return [`${err.field}`, err.rule ? err.rule : '', err.msg ? err.msg : '']
          })
        }
        return []
      }
    },
    created (): void {
      this.volunteer = JSON.parse(JSON.stringify(this.$store.state.profile.friendInfo))
      if (this.isAdd) {
        // set default values
        this.volunteer.standardProfile = {
            availabilityDetails: '',
            availabilityFrequence: 0,
            customerTypes: 0,
            friendManagementGroupId: 0,
            friendships: [],
            friendshipTypes: (FriendshipTypes.OneToOne),
            friendPools: [],
            id: 0,
            interests: 0,
            lastFriendshipEndDate: new Date(),
            multipleCustomers: false,
            onHold: false,
            onHoldDeadline: undefined,
            onHoldDetails: '',
            otherInterests: '',
            preferredAges: 0,
            preferredGenders: (PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female),
            shortDescription: '',
            startedPending: new Date(),
            state: CustomerState.PendingApproval,
            volunteerInterviewed: false,
            volunteerInterviewedBy: '',
            volunteerInterviewedDate: '',
            availabilityTimes: 0,
            hasAlternativeAddress: false,
            alternativeCity: '',
            alternativeStreet: '',
            alternativeZipCode: '',
            privateNotes: ''
          } as FriendVolunteerProfileStandardDto
      }
    },
    provide (): Record<string, any> {
      return {
        $validator: this.$validator // allow child components to have validator injected
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.changeDetected() && !this.isSavedOrCancelled) {
        var text = this.$t('profile.unsavedChanges').toString()
        const answer = window.confirm(text)
        if (!answer) {
          return next(false)
        }
      }
      this.isSavedOrCancelled = false
      return next()
    },
    methods: {
      changeDetected (): boolean {
        return JSON.stringify(this.$store.state.profile.friendInfo) !== JSON.stringify(this.volunteer)
      },
      onVolunteerStandardProfileChanged (newProfile: FriendVolunteerProfileStandardDto): void {
        this.volunteer.standardProfile = newProfile
      },
      cancel (): void {
        this.isSavedOrCancelled = true
        this.$router.push('/profile')
      },
      save (): void {
        this.isSavedOrCancelled = true
        if (this.volunteer?.standardProfile?.preferredAges === PreferredAges.NotDefined) {
          this.volunteer.standardProfile.preferredAges = 1 // tricking preferredAges to update in order to trigger a validation
          this.$nextTick(() => {
            if (!this.volunteer) return
            this.volunteer.standardProfile.preferredAges = PreferredAges.NotDefined
            this.validateAndSave()
          })
        } else {
          this.validateAndSave()
        }
      },
      validateAndSave (): void {
        this.$validator.validateAll().then(result => {
          // check any child controls have not added errors
          if (!result || (this.validationErrors && this.validationErrors.length)) {
            var target = this.$refs['validationtarget']
            if (target) {
              window.scrollTo({
                // @ts-ignore
                top: target.$el.parentElement.clientHeight,
                left: 0,
                behavior: 'smooth'
              })
            }
            return
          }

          const data = this.volunteer
          data.profileId = this.$store.state.profile.profileId
          this.updateFriendProfile(data)
        })
      },
      updateFriendProfile (data: FriendVolunteerDto): void {
        const updateUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteeridentupdate[0]
        this.$http.put(updateUrl, data).then((result: any) => {
          // @ts-ignore (doesnt always pick up mixins)
          this.fetchProfile((response: any) => {
            this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
            this.$store.commit('setProfile', response.body)
            this.$router.push('/profile')
          })
        }, (err: Response | any) => {
          this.displayError(err)
        })
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .profileidentvolunteerformstandard {
    .feature-panel {
      background-color: $brand-white;
      margin-bottom: 30px;
      padding-top: 15px;

      &.buttons {
        margin-top: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        .cancel {
          text-align: left;
        }
        .save {
          text-align: right;
        }
      }
    }

    .profilehead {
      margin-top: 25px;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $brand-white;
    }

    .subheader {
      margin-top: 15px;
      margin-bottom: 15px;
      display: block;
    }

    .profile-button {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .fa-exclamation-triangle {
      color: $brand-red;
    }
  }
</style>
