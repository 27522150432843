<template>
  <div class="comp-wizard-content container join-container">
    <div class="row mx-auto">
      <div class="col-12">
        <span class="h1"
              role="heading"
              aria-level="1"
        >{{ $t('join.contactHeader') }}</span>
      </div>
      <div class="col-12">
        <sprvalidation class="validation-box"
                       :validation-errors="validationErrors"
        />
      </div>
      <div class="col-12">
        <p>{{ $t('join.contactDescription') }}</p>
      </div>
    </div>

    <div class="row mx-auto">
      <div class="col-12">
        <sprinput v-model="value.address.street"
                  v-validate="'required'"
                  :hint="$t('wizard.streetAddressField')"
                  :is-valid="!errors.has('streetAddressField')"
                  class="w-100"
                  name="streetAddressField"
                  data-vv-delay="15000"
                  dark
        />
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-12 col-sm-6">
        <sprinput v-model="value.address.zipCode"
                  v-validate="'required|digits:5'"
                  :hint="$t('wizard.zipCodeField')"
                  class="w-100 mt-2"
                  name="zipCodeField"
                  data-vv-delay="15000"
                  :is-valid="!errors.has('zipCodeField')"
                  dark
        />
      </div>
      <div class="col-12 col-sm-6">
        <sprinput v-model="value.address.city"
                  v-validate="'required'"
                  :hint="$t('wizard.cityField')"
                  class="w-100 mt-2"
                  :is-valid="!errors.has('cityField')"
                  name="cityField"
                  data-vv-delay="15000"
                  dark
        />
      </div>
    </div>
    <!-- email asked here for the first time -->
    <div class="row mx-auto">
      <div class="col-12">
        <sprinput v-model="value.address.email"
                  v-validate="'email'"
                  :hint="$t('wizard.emailField')"
                  class="w-100 mt-2"
                  :is-valid="!errors.has('emailField')"
                  name="emailField"
                  data-vv-delay="15000"
        />
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-12">
        <sprinput v-model="value.address.phone"
                  :hint="$t('wizard.phoneNumberField')"
                  class="w-100 mt-2"
                  :is-valid="!errors.has('phoneField')"
                  name="phoneField"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import sprinput from '../SPRInput.vue'
  import sprvalidation from '../SPRValidationError.vue'

  export default {
    name: 'JoinContact',
    components: { sprinput, sprvalidation },
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        validatedFields: ['streetAddressField', 'zipCodeField', 'cityField']
      }
    },
    computed: {
      validationErrors: function () {
        if (!this.validatedFields) {
          return []
        }

        var arr = []
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['', err.field + '_' + err.rule, ''])
          }
        })

        if (self.registerErrors) {
          self.registerErrors.forEach(function (registrationError) {
            arr.push(registrationError)
          })
        }

        return arr
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

  .join-container {
    .row {
      max-width: 600px;
    }

    .cap-label {
      display: none;
    }

    .form-group {
      margin-bottom: 0px;
    }

    .spr-simple-dropdown {
      padding-top: 5px !important;
      padding-bottom: 3px !important;
      margin-right: -4px;
    }
  }
</style>
