<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .volunteerlistsearch {
    .instructions-text {
      min-height: 30px;
    }
    .selected-volunteers {
      margin-bottom: 15px;
      .pill {
        margin-right: 5px;
      }
    }
    .btnsavevolunteer {
      color:$brand-white;
      background-color: $brand-red;
    }
    .loading-icon {
      display:inline-block;
      padding-top: 3px;
    }
  }
</style>

<template>
  <div class="row volunteerlistsearch">
    <div class="col-8">
      <div v-if="!selectedVolunteers.length" class="instructions-text">
        {{ $t('groups.addVolunteerInstructions') }}
      </div>
      <div v-if="selectedVolunteers.length" class="selected-volunteers">
        <pill v-for="volunteer in selectedVolunteers" :key="volunteer.id" v-model="volunteer.name" :show-delete="true" @remove="onRemoveVolunteerPill(volunteer)" />
      </div>
    </div>
    <div class="col-4 text-right">
      <span class="loading-icon"><loading v-if="isSaving" /></span>
      <sprbutton v-if="selectedVolunteers.length && !isSaving" type="primary"
                 :size="4"
                 :title="$t('groups.saveVolunteer')"
                 icon="chevron-right"
                 class="spr-redborder button-width btnsavevolunteer"
                 click-event="saveVolunteers"
                 @saveVolunteers="onSaveVolunteers"
      />
    </div>
    <div class="col-12">
      <div class="list-group">
        <div v-for="(item) in list" :key="item.id" class="user-row">
          <volunteerviewsearch :volunteer="item" @addVolunteer="onAddVolunteer" @removeVolunteer="onRemoveVolunteer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import volunteerviewsearch from './VolunteersSearchResultView.vue'
  import sprbutton from '../SPRButton.vue'
  import pill from '../SPRPill.vue'
  import loading from '../SPRLoading.vue'

  export default {
    name: 'Volunteerlistsearch',
    components: { volunteerviewsearch, sprbutton, pill, loading },
    props: {
      list: {
        type: Array,
        default: () => []
      },
      selectedVolunteers: {
        type: Array,
        default: () => []
      },
      isSaving: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      onAddVolunteer (volunteer) {
        this.$emit('addVolunteer', volunteer)
      },
      onRemoveVolunteer (volunteer) {
        this.$emit('removeVolunteer', volunteer)
      },
      onSaveVolunteers () {
        this.$emit('saveVolunteers', this.selectedVolunteers)
      },
      onRemoveVolunteerPill (volunteer) {
        this.$emit('removeVolunteer', volunteer)
      }
    }
  }
</script>
