<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .spr-multiselect-inline {
    .selecteditems {
      margin-top:-15px;

      .pill {
        background-color:$brand-white;
        border: solid 2px $brand-red;
        padding:5px 10px;
        border-radius:20px;
        position: relative;
        display: inline-block;
        color:$brand-red;
        margin-top: 5px;
        font-family: $font-light;
        font-weight: bold;
        font-size:12px;

        &.inactive {
          color: $brand-grey7;
          border-color: $brand-grey7;
        }

        .lbl {
          margin-right:10px;
        }

        svg {
          top:14px;
          right:8px;
          display:inline-block;
          position: absolute;
          margin-top:-10px;
          color:$brand-red;
          cursor:pointer;
        }
      }
    }
  }

</style>

<template>
  <div class="spr-multiselect-inline">
    <sprdropdown v-model="selectedItem"
                 :title="title"
                 :items="availableItems"
                 :placeholder="placeholder"
                 :is-valid="true"
    />
    <div class="selecteditems">
      <ul class="list-inline">
        <li v-for="item in selectedItems" :key="item.value" class="list-inline-item" :value="item.value">
          <span :class="{pill:true, inactive:!item.active}">
            <span class="lbl">{{ item.text }}</span>
            <font-awesome-icon v-if="item.active && closeAriaLabel" icon="times" :aria-label="$t(closeAriaLabel, {role: item.text})" @click="removeItem(item)" />
            <font-awesome-icon v-if="item.active && !closeAriaLabel" icon="times" @click="removeItem(item)" />
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import sprdropdown from './SPRSimpleDropdown.vue'
  export default {
    name: 'Sprmultiselectinline',
    components: { sprdropdown },
    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: Array,
        default: () => []
      }, // selected items
      items: {
        type: Array,
        default: () => []
      }, // all items
      placeholder: {
        type: String,
        default: ''
      },
      closeAriaLabel: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        itemsLoaded: false,
        selectedItem: null
      }
    },
    computed: {
      availableItems: function () {
        return _.filter(_.difference(this.items, this.selectedItems), function (x) { return x.active })
      },
      selectedItems () {
        const arr = []
        const options = []
        this.value.forEach(item => {
          var res = _.find(this.items, function (x) {
            return x.value === item
          })
          if (res) {
            arr.push(res)
            if (res.active) {
              options.push(res)
            }
          }
        })
        return arr
      }
    },
    watch: {
      items: function (val) {
        this.itemsLoaded = true
      },
      selectedItem: function (item) {
        // if newly selected item does not already belong to selected items
        if (item && !this.selectedItems.some(i => (i.value === item))) {
          // find the item from the list of all items
          var chosenItem = _.find(this.items, function (i) {
            return i.value === item
          })
          this.$set(this.selectedItems, this.selectedItems.length, chosenItem)
          this.$emit('input', this.selectedItems)
        }
      }
    },
    methods: {
      removeItem (item) {
        var index = (_.indexOf(this.selectedItems, item))
        this.$delete(this.selectedItems, index)
        this.$emit('input', this.selectedItems)
        this.selectedItem = null
      }
    }
  }
</script>
