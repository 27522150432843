<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .payment-options {
    background: rgba(255, 255, 255, 0.3);
    padding:30px 0;

    .payment-provider {
      display: inline-block;
    }
  }

  @media (max-width: 400px) {
    .payment-options {
      padding: 0;
      background: none;
    }
  }
</style>

<template>
  <div class="payment-options">
    <paymentprovider v-for="(provider, i) in optionsResponse.providers"
                     :key="i"
                     :provider="provider"
    />
  </div>
</template>

<script>
  import paymentprovider from '@/components/payment/PaymentProvider.vue'
  export default {
    name: 'PaymentOptions',
    components: { paymentprovider },
    props: {
      optionsResponse: {
        type: Object,
        required: true
      }
    }
  }
</script>
