<template>
  <div :class="classCombined">
    <input :id="_uid" type="checkbox" :checked="checked" :value="value" @change="updateValue($event.target)">
    <label :for="_uid" :aria-label="ariaLabel">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-if="allowHtml" v-linkified v-html="title" />
      <span v-else>{{ title }}</span>
    </label>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  export default Vue.extend({
    name: 'SprSimpleNumberCheckbox',
    props: {
      title: {
        type: String,
        default: ''
      },
      allowHtml: {
        type: Boolean,
        default: false
      },
      value: {
        type: Number,
        required: true
      },
      checked: {
        type: Boolean,
        default: false
      },
      ariaLabel: {
        type: String,
        default: ''
      },
      hasError: Boolean,
      additionalClass: {
        type: String,
        default: 'pb-3'
      }
    },
    computed: {
      classCombined: function (): string {
        var rv = 'spr-simple-checkbox ' + this.additionalClass
        if (this.hasError !== null && this.hasError) {
          rv += ' validation-error'
        }
        return rv
      }
    },
    methods: {
      updateValue: function (target) {
        this.$emit('changed', this.value, target.checked)
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.spr-simple-checkbox {

  /* hiding the initial actual checkbox */
  input[type="checkbox"]:not(:checked),
  input[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
  }

  /* the actual label styling */
  input[type="checkbox"]:not(:checked) + label,
  input[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
  }

  /* checkbox aspect */
  input[type="checkbox"]:not(:checked) + label:before,
  input[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 1.5em;
    height: 1.5em;
    border: 2px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
  }
  /* checked mark aspect */
  input[type="checkbox"]:not(:checked) + label:after,
  input[type="checkbox"]:checked + label:after {
    content: '✓';
    position: absolute;
    top: .2em;
    left: .4em;
    font-size: 1.1em;
    line-height: 0.8;
    color: $brand-red;
    transition: all .2s;
  }
  /* checked mark aspect changes */
  input[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  input[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
  /* disabled checkbox */
  input[type="checkbox"]:disabled:not(:checked) + label:before,
  input[type="checkbox"]:disabled:checked + label:before {
    box-shadow: none;
    border-color: #bbb;
    background-color: #ddd;
  }
  input[type="checkbox"]:disabled:checked + label:after {
    color: #999;
  }
  input[type="checkbox"]:disabled + label {
    color: #aaa;
  }

  /* accessibility */
  input[type="checkbox"]:checked:focus + label:before,
  input[type="checkbox"]:not(:checked):focus + label:before {
    border: 2px solid $brand-red;
  }

  &.validation-error {
    label {
      color: red;
    }
  }
}
</style>
