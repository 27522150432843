<template>
  <div class="row delete-user-profile my-2">
    <div class="col-12">
      <div class="deletelink" @click="initiateDeleteOrAnonymize">
        {{ $t('profile.moveToDeleteUserProfile') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  export default Vue.extend({
    name: 'DeleteUser',
    components: { },
    methods: {
      initiateDeleteOrAnonymize (): void {
        this.$router.push('/profile/deleteprofile')
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
.delete-user-profile {
  .deletelink {
    color: $brand-red;
    text-align: center;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
