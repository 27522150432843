<template>
  <div v-if="volunteer" class="container volunteerfindstandardmatch">
    <div class="row header">
      <div class="col-lg-4 col-xl-3">
        <HomeButton
          v-if="group"
          :group-id="group.id"
        />
        <BackButton />
      </div>
      <div v-if="customerSelected" class="col-lg-8 col-xl-9 d-none d-lg-block">
        <h4>{{ $t('customerSearch.volunteerMatchTitle') }}</h4>
      </div>
    </div>

    <div v-if="group && volunteer" class="row content">
      <div class="col-lg-4 col-xl-3 mb-3 mb-lg-0 volunteer-area">
        <VolunteerCardStandard
          v-model="volunteer"
          :group="group"
          @refresh="refresh"
        />
        <div v-show="isSending" class="loading">
          <div class="spinner">
            <loading />
          </div>
        </div>
      </div>

      <div class="col-lg-8 col-xl-9 customer-area">
        <div v-if="!customerSelected" class="row customer-selection">
          <div class="col-md-4 col-lg-4 order-md-2 search-filter">
            <matchfilter v-model="matchFilter" @filterchanged="onFilterChanged" />
          </div>

          <div class="col-md-8 col-lg-8 order-md-1 customer-list">
            <h4>{{ $t('customerSearch.volunteerMatchTitle') }}</h4>
            <volunteermatchresults :list="filteredcustomers" @customerSelected="onCustomerSelected" />
            <div v-show="isLoading" class="loading">
              <div class="spinner">
                <loading />
              </div>
            </div>
            <div v-if="!isLoading && noResults" class="noresults infocontainer">
              <div class="info warning">
                <font-awesome-icon icon="exclamation-triangle" />{{ $t('customerSearch.noResults') }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="customerSelected" class="row d-lg-none customer-heading">
          <h4>{{ $t('customerSearch.volunteerMatchTitle') }}</h4>
        </div>
        <div v-if="customerSelected" class="row ml-lg-1 customer-summary">
          <div class="col">
            <CustomerSummaryStandard
              :volunteer="volunteer"
              :customer="customer"
              :group="group"
              :score="score"
              :is-first="isFirst"
              :is-last="isLast"
              :is-inappropriate="isInappropriate"
              @prev="onPrev"
              @next="onNext"
              @close="onClose"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import { HttpResponse } from 'vue-resource/types/vue_resource'
  import VolunteerCardStandard from './VolunteerCardStandard.vue'
  import CustomerSummaryStandard from './CustomerSummaryStandard.vue'
  import volunteermatchresults from './VolunteerMatchResults.vue'
  import BackButton from '../BackButton.vue'
  import HomeButton from '../HomeButton.vue'
  import matchfilter from './MatchFilter.vue'
  import loading from '../SPRLoading.vue'
  import datehelper from '@/helpers/datehelper'
  import friendMatch from '@/mixins/friendMatch'
  import bitmaskhelper from '@/helpers/bitmaskhelper'

  import { MatchItemStandardCustomerDto, FriendCustomerStandardDto, UserProfileDto, FriendManagementDto, CustomerState, LanguageDto, FriendshipStandardCommandCriteria, GetFriendProfileByFriendVolunteerIdCriteria } from '@/types/index'
  import { MatchFilter, StandardMatchPair } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    customerlist: Array<MatchItemStandardCustomerDto>,
    filteredcustomers: Array<MatchItemStandardCustomerDto>,
    customer: undefined | FriendCustomerStandardDto,
    volunteer: undefined | UserProfileDto,
    score: number,
    isLoading: boolean,
    isSending: boolean,
    matchFilter: MatchFilter
  }

  export default Vue.extend({
    name: 'VolunteerFindStandardMatch',
    components: { VolunteerCardStandard, CustomerSummaryStandard, volunteermatchresults, BackButton, HomeButton, matchfilter, loading },
    mixins: [friendMatch],
    props: {
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      },
      volunteerid: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        customerlist: [] as Array<MatchItemStandardCustomerDto>,
        filteredcustomers: [] as Array<MatchItemStandardCustomerDto>,
        customer: undefined,
        volunteer: undefined,
        score: 0,
        isLoading: false,
        isSending: false,
        matchFilter: {
          customerTypes: [],
          languages: [],
          motherTongs: [],
          ages: []
        }
      }
    },
    computed: {
      isInactive (): boolean {
        return this.group?.netFriendship
          ? this.volunteer?.friendInfo.netProfile.state === CustomerState.NonActive
          : this.volunteer?.friendInfo.standardProfile.state === CustomerState.NonActive
      },
      noResults (): boolean {
        return this.customerlist === null || this.filteredcustomers.length === 0
      },
      isFirst (): boolean {
        if (this.filteredcustomers.length <= 1) return true
        return this.customer?.id === this.filteredcustomers[0].match.customerProfileStandardId
      },
      isLast (): boolean {
        if (this.filteredcustomers.length <= 1) return true
        return this.customer?.id === this.filteredcustomers[this.filteredcustomers.length - 1].match.customerProfileStandardId
      },
      isInappropriate (): boolean {
        if (!this.customerSelected) return false

        var selectedCustomer = this.filteredcustomers.find(x => x.match.customerProfileStandardId === this.customer?.id)
        return selectedCustomer ? selectedCustomer.isInappropriateMatch : false
      },
      hasFriendship (): boolean {
        return this.volunteer?.friendInfo.standardProfile?.friendships != null && this.volunteer.friendInfo.standardProfile.friendships.length > 0
      },
      customerSelected (): boolean {
        return !_.isEmpty(this.customer)
      }
    },
    mounted (): void {
      this.getVolunteer()
      this.getCustomers()
      window.scrollTo(0, 0)

      this.$bus.$on('inappropriate-friendship', this.onInappropriateFriendship)
      this.$bus.$on('end-inappropriate-friendship', this.onEndInappropriateFriendship)
      this.$bus.$on('initiate-friendship', this.onInitiate)
    },
    beforeDestroy (): void {
      this.$bus.$off('inappropriate-friendship')
      this.$bus.$off('end-inappropriate-friendship')
      this.$bus.$off('initiate-friendship')
    },
    methods: {
      getVolunteer (): void {
        var self = this
        var criteria: GetFriendProfileByFriendVolunteerIdCriteria = {
          friendManagementGroupId: this.group.id,
          friendVolunteerId: this.volunteerid,
          includeFriendships: true,
          includeFriendPools: false
        }
        var volunteerUrl = `${this.$store.state.apiBases.friendservice}${this.$store.state.apiEndpoints.volunteergetstandardprofilebyid[0]}`
        this.$http.post(volunteerUrl, criteria).then(resp => {
          self.volunteer = resp.data
        })
      },
      getCustomer (customerId: number): void {
        var self = this
        var getCustomerUrl = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customergetstandardbyid[0]}`.replace('{customerid}', customerId.toString()).replace('{groupid}', this.group.id.toString())
        this.isLoading = true
        this.$http.get(getCustomerUrl).then(resp => {
          if (resp.data) {
            self.customer = resp.data
            self.isLoading = false
          } else {
            this.displayError(this.$t('common.fetchFailed'))
          }
        }, f => this.displayError(f))
      },
      getCustomers (): void {
        var self = this
        this.isLoading = true
        var customersUrl = `${this.$store.state.apiBases.friendservice}${this.$store.state.apiEndpoints.volunteerfindstandardmatch[0]}`

        customersUrl = customersUrl.replace('{groupid}', this.group.id.toString()).replace('{volunteerid}', this.volunteerid.toString())
        this.$http.get(customersUrl).then(resp => {
          self.customerlist = _.reverse(_.sortBy(resp.data.items, ['score']))
          self.filteredcustomers = self.customerlist
          self.isLoading = false
        })
      },
      onCustomerSelected (match: MatchItemStandardCustomerDto): void {
        window.scrollTo(0, 0)
        this.getCustomer(match.match.customerProfileStandardId)
        this.score = match.score
      },
      onInitiate (activation: StandardMatchPair): void {
        var data: FriendshipStandardCommandCriteria = {
          customerProfileStandardId: activation.customer.id,
          volunteerStandardProfileId: activation.volunteer.friendInfo.standardProfile.id,
          groupId: this.group.id
        }
        var url = `${this.$store.state.apiBases.friendservice}${this.$store.state.apiEndpoints.friendshipinitiatestandard[0]}`
        this.$http.post(url, data).then(resp => {
          this.$router.push(`/friend/volunteermatch/${this.group.id}/${this.volunteerid}/${activation.customer.id}`)
        }, f => {
          this.displayError(f || this.$t('common.fetchFailed'))
        })
      },
      onPrev (): void {
        var self = this
        var currentCustomer = _.findIndex(self.filteredcustomers, function (item) {
          return item.match.customerProfileStandardId === self.customer?.id
        })

        if (currentCustomer > 0) {
          var id = this.filteredcustomers[currentCustomer - 1].match.customerProfileStandardId
          this.getCustomer(id)
          self.score = self.filteredcustomers[currentCustomer - 1].score
        }
      },
      onNext (): void {
        var self = this
        var currentCustomer = _.findIndex(self.filteredcustomers, function (item) {
          return item.match.customerProfileStandardId === self.customer?.id
        })

        if (currentCustomer > -1 && currentCustomer < self.filteredcustomers.length - 1) {
          var id = self.filteredcustomers[currentCustomer + 1].match.customerProfileStandardId
          this.getCustomer(id)
          self.score = self.filteredcustomers[currentCustomer + 1].score
        }
      },
      onClose (): void {
        this.customer = undefined
      },
      onInappropriateFriendship (friendshipInfo: StandardMatchPair): void {
        var data: FriendshipStandardCommandCriteria = {
          customerProfileStandardId: friendshipInfo.customer.id,
          volunteerStandardProfileId: friendshipInfo.volunteer.friendInfo.standardProfile.id,
          groupId: this.group.id
        }
        var url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendshipmarkinappropriatestandard[0]}`
        this.$http.post(url, data).then((resp) => {
          this.customer = undefined
          this.getCustomers()
        }, (f) => {
          // @ts-ignore (Mixins not found)
          this.showAlertError(this.$t('common.fetchFailed'))
        })
      },
      onEndInappropriateFriendship (friendshipInfo: StandardMatchPair): void {
        var data: FriendshipStandardCommandCriteria = {
          customerProfileStandardId: friendshipInfo.customer.id,
          volunteerStandardProfileId: friendshipInfo.volunteer.friendInfo.standardProfile.id,
          groupId: this.group.id
        }
        var url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendshipendinappropriatestandard[0]}`
        this.isLoading = true
        this.$http.post(url, data).then((resp) => {
          this.customer = undefined
          this.getCustomers()
        }, (f) => {
          // @ts-ignore (Mixins not found)
          this.showAlertError(this.$t('common.fetchFailed'))
        })
      },
      onFilterChanged (val): void {
        var self = this
        this.matchFilter = val
        this.filteredcustomers = this.customerlist.filter(function (customerObject) {
          return (!self.matchFilter.customerTypes?.length || bitmaskhelper.bitmaskToArray(customerObject.match.customerTypes).some(customerType => self.matchFilter.customerTypes?.includes(customerType))) &&
            (!self.matchFilter.languages?.length || customerObject.match.spokenLanguages.some(customerLanguage => self.matchFilter.languages?.includes(customerLanguage))) &&
            (!self.matchFilter.motherTongs?.length || self.matchFilter.motherTongs?.includes(customerObject.match.nativeLanguage)) &&
            (!self.matchFilter.ages?.length || self.matchFilter.ages?.includes(friendMatch.methods.calculateBitmaskFromAge(datehelper.calculateAge(customerObject.match.birthDate))))
        })
      },
      refresh (): void {
        window.location.reload()
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteerfindstandardmatch {
    .header {
      margin-top: 10px;
      margin-bottom: 15px;
      .nav-header {
        border-bottom: 0;
      }
      h4 {
        padding-left: 10px;
      }
    }
    .content {

      .volunteer-area {
        padding-top: 30px;
        background-color: $brand-white;
        border-left: solid 5px $volunteer-color-strong;
      }

      .customer-area {
        .customer-selection {
          height: 100%;
          .search-filter {
            background-color:$brand-white;
            padding-top: 20px;
          }
          .customer-list {
            h4 {
              margin: 20px 0 10px 0;
            }
            .loading {
              position: absolute;
              display: flex;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              height: 100%;
              width: 100%;
              background-color:rgba(255, 255, 255, 0.8);
              .spinner {
                margin: auto;
                color:$brand-red;
              }
            }
            .infocontainer {
              height: 200px;
              width: 100%;
              background-color:$brand-white;
              display: flex;
              justify-content: center;
              align-items:center;
              align-content: center;
              padding:50px;
              .info {
                margin: auto;
                svg {
                  margin-right: 5px;
                  color:$brand-red;
                  &.warning {
                    color:$brand-warning;
                  }
                }
              }
            }
          }
        }
        .customer-heading {
          h4 {
            padding-left: 10px;
          }
        }
        .customer-summary {
          @media (min-width: $media-lg) {
            height: 100%;
          }
        }
      }

    }
  }
</style>
