<template>
  <div class="col-12 comp-admin-friend-level my-3">
    <div class="row group-header">
      <div class="col-12 col-md-8">
        <label class="groupname"> {{ friendshipGroup.name }} </label>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <friendshipcontact :contact-info-list="friendshipGroup.contactInfo" :editable="false" :is-friend-request="true" :show-standard-button="friendshipGroup.friendRequestFormAllowed" @next="onNextClicked()" />
      </div>
      <!--<div class="col-12">
      <SPRSimpleCheckbox v-model="friendshipGroup.friendRequestFormAllowed" :title="$t('userAdmin.friendRequestFormAllowed')" @input="onFormAllowedChecked(item)" />
    </div>-->
    </div>
    <div class="row">
    </div>
    <div class="row contactinfo netTitle">
      <div class="col-12 title">
        {{ $t('customerForm.groupNetTitle') }}
      </div>
      <div class="buttons">
        <sprbutton
          :size="4"
          :title="$t('customerForm.sendNetRequest')"
          :primary="true"
          icon="chevron-right"
          class="next"
          click-event="click"
          @click="onNetFriendRequestClicked"
        />
      </div>
    </div>
    <div class="col-12">
      <div class="buttons">
        <prevnextbuttons
          :next-text="$t('customerForm.sendRequest')"
          :show-next="false"
          @prev="onPrevClicked()"
          @next="onNextClicked()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import friendshipcontact from '../useradmin/FriendshipContact.vue'
  import sprbutton from '../SPRButton.vue'

  import cache from '../../mixins/cache.js'
  import { FriendManagementLightDto, AvailabilityFrequence, FriendCustomerStandardDto, PreferredGenders, Gender, CustomerState, CustomerTypes, Interests, PreferredAges, AvailabilityTimes } from '@/types/index'

  import prevnextbuttons from '../SPRPrevNextButtons.vue'

  // the data object
  export interface TemplateComponentData {
  friendshipGroup: FriendManagementLightDto,
  loaded: number,
  zipsAll: boolean,
  localfmgid: number
}

  export default Vue.extend({
    name: 'GroupContactInfo',
    components: {
      prevnextbuttons,
      friendshipcontact,
      sprbutton
    },
    mixins: [cache],
    props: {
      primary: {
        type: String,
        default: ''
      },
      fmgid: {
        type: Number,
        required: true
      },
      id: {
        type: String,
        default: ''
      },
      filter: {
        type: String,
        default: ''
      },
      customer: {
        type: Object as () => FriendCustomerStandardDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        friendshipGroup: {
          name: this.$t('userAdmin.toFriendshipLink').toString(),
          contactInfo: [],
          friendRequestFormAllowed: false,
          national: false,
          netFriendship: false,
          id: 0

        },
        loaded: 0,
        zipsAll: false,
        localfmgid: 2
      }
    },
    computed: {
      errNoFound (): string | LocaleMessage {
        return this.$t('common.noFmgFound')
      },
      deleteThanksTitle (): string | LocaleMessage {
        return this.deletionrequest
          ? this.$t('profile.deleteRequestedPageTitle')
          : this.$t('profile.deletePageTitle')
      }
    },
    mounted: function () {
      this.localfmgid = this.fmgid
      this.triggerFetch()
    },
    methods: {

      onPrevClicked () {
        this.$emit('prev', this.customer)
      },
      onNextClicked () {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.customer.friendManagementGroupId = this.fmgid
            this.$emit('next', this.customer)
          }
        })
      },
      onNetFriendRequestClicked () {
        this.$router.push('/verkkoystava')
      },
      triggerFetch () {
        this.loaded = 0
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.fmgContactInfo[0].replace('{0}', this.fmgid.toString())
        var self = this
        this.fetchItem(url, this.expSeconds(120), s => {
          self.friendshipGroup = s
          self.loaded = 1
        }, () => {
          self.loaded = -1
          // @ts-ignore cannot find mixins
          this.showAlertError(this.errNoFound)
          this.$emit('prev', null)
        })
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';

.comp-admin-friend-level {
  .groupname {
    display: inline-block;
    margin-top: 5px;
    vertical-align: top;
    margin-right: 10px;
  }

  .enter-friendship-group {
    font-size: 13px;
    line-height: 20px;
    margin-right: -15px;
  }

  .few-zips {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .group-header {
    font-family: Signa-Bold, Sans-Serif;
    font-size: 32px;
    line-height: 42px;
    padding-bottom: 20px;
  }
  .contactinfo {
      margin-bottom: 10px;
      .title {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .contactinfoitem {
        svg {
          color:$brand-red;
          margin-right: 5px;
        }
      }
    }
    .netTitle {
      margin-top: 35px;
    }
    .buttons {
      padding-top: 30px;
      padding-bottom: 30px;
    }
}
</style>