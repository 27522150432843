<template>
  <div class="checkboxlist">
    <div class="row">
      <div v-for="item in list" :key="item.id" :class="calculatedClass" :value="item.id">
        <checkbox v-model="item.selected" :title="item.text" @input="onChecked(item)" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import checkbox from './SPRSimpleCheckbox.vue'
  import { SprDropDownValue } from '../types/custom'
  export default Vue.extend({
    name: 'SprSimpleCheckboxList',
    components: { checkbox },
    props: {
      list: {
        type: Array as () => Array<SprDropDownValue>,
        default () { return [] }
      },
      value: {
        type: Array as () => Array<SprDropDownValue>,
        default () { return [] }
      },
      rows: {
        type: Number,
        default () { return 1 }
      }
    },
    computed: {
      calculatedClass (): string {
        return 'col-12 col-sm-' + Math.floor(12 / this.rows)
      }
    },
    methods: {
      onChecked (val): void {
        var index = _.indexOf(this.value, val.value)
        if (val.selected) {
          // add the item, if it doesn't exist already
          if (index === -1) {
            this.$set(this.value, this.value.length, val.value)
            this.$emit('input', this.value)
          }
        } else {
          // if the item exists, remove it
          if (index > -1) {
            this.$delete(this.value, index)
            this.$emit('input', this.value)
          }
        }
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/scss/_variables.scss";

.checkboxlist {

}
</style>
