export default {
  methods: {
    checkboxLanguages: function (selected, potential) {
      var self = this
      return _.map(potential, function (val, key) {
        return {
          value: val.value,
          text: self.$t('friendCustomerLanguages.' + val.text),
          selected: selected ? selected.indexOf(val.value) >= 0 : false
        }
      })
    },
    splitField: function (original) {
      if (!original || original.length === '') {
        return ['', '']
      }

      var splt = original.split(' || ')
      if (splt.legth === 1) {
        return [splt[0], '']
      }

      return [splt[0], splt[1]]
    },
    combineFields: function (el1, el2) {
      return el1 + ' || ' + el2
    },
    tBool: function (bool) {
      if (bool) {
        return this.$t('boolean.true')
      }
      return this.$t('boolean.false')
    }
  }
}
