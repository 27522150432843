<template>
  <div class="volunteernetfindresult" :class="{ 'hascustomer': hasCustomer }">
    <font-awesome-icon v-if="!volunteer.isNonIdent" icon="user" class="identuser" :title="$t('customerSearch.isIdent')" />
    <div class="col-lg-6 col-xl-4 friend-container">
      <div class="friendimage">
        <friendicon :image="volunteer.portrait" :friendname="volunteer.firstName" :state="volunteerState" :multiple-friendships="hasMultipleCustomers" />
      </div>
      <div class="friendinfo">
        <div class="friendname">
          <router-link :to="'/friend/volunteerpreview/' + group.id + '/' + volunteer.friendInfo.id">
            {{ volunteer.firstName }} {{ volunteer.lastName }}
          </router-link>
        </div>
        <div class="frienddetails">
          <span class="age">{{ genderAgeText }}</span><br>
          <span class="section">{{ section }} ({{ zipCodeText }})</span>
        </div>
      </div>
    </div>

    <div class="d-none d-xl-block col-xl-4 description-container">
      <div v-show="volunteer.friendInfo.netProfile.shortDescription" class="description">
        &#8220;{{ volunteer.friendInfo.netProfile.shortDescription }}&#8221;
      </div>
    </div>

    <div class="col-lg-6 col-xl-4 customer-container">
      <div v-if="!hasCustomer" class="row nocustomer">
        <div class="col-12 pending">
          {{ daysWithoutFriend(volunteer.friendInfo.netProfile.lastFriendshipEndDate) }}
        </div>
      </div>
      <div v-else>
        <div v-for="(friendship, i) in volunteer.friendInfo.netProfile.friendships" :key="i" class="row customerrow">
          <div class="col-12">
            <div class="customerinfo">
              <router-link :to="'/friend/customerpreview/' + group.id + '/' + friendship.customerId">
                {{ friendship.firstName }} {{ friendship.lastName }}
              </router-link>
            </div>
            <div class="customerimage">
              <friendshipicon :friendname="friendship.firstName" :state="friendship.friendshipState" />
            </div>
          </div>
        </div>
      </div>
      <div v-for="(pool) in volunteer.friendInfo.netProfile.friendPools" :key="pool.id" class="row pools">
        <div class="col-12">
          <div class="poolinfo" @click="onPoolClicked(pool)">
            {{ pool.name }}
          </div>
          <div class="poolimage">
            <poolicon :pool="pool" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'

  // @ts-nocheck
  // Do not check ts at this time as this file has not been converted to ts.
  import friendicon from './FriendIcon.vue'
  import friendshipicon from './FriendshipIcon.vue'
  import poolicon from './PoolIcon.vue'
  import datehelper from '../../mixins/dates.js'
  import enumhelper from '../../mixins/enums.js'
  import { CustomerState, UserProfileFindDto, FriendshipState, FriendManagementDto } from '../../types/index'

  export default Vue.extend({
    name: 'VolunteerNetFindResult',
    components: { friendicon, friendshipicon, poolicon },
    mixins: [enumhelper, datehelper],
    props: {
      volunteer: {
        type: Object as () => UserProfileFindDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      }
    },
    computed: {
      hasCustomer (): boolean {
        return Boolean(this.volunteer?.friendInfo?.netProfile?.friendships?.length)
      },
      hasMultipleCustomers (): boolean {
        return Boolean(this.volunteer?.friendInfo?.netProfile?.multipleCustomers)
      },
      volunteerState (): CustomerState {
        return this.volunteer?.friendInfo?.netProfile ? this.volunteer.friendInfo.netProfile.state : CustomerState.PendingApproval
      },
      computedGender (): string | LocaleMessage {
        // @ts-ignore (mixin not found)
        return this.getGenderTranslation(this.volunteer.friendInfo.gender)
      },
      computedAge (): number {
        // @ts-ignore (mixin not found)
        return this.calculateAge(this.volunteer.age)
      },
      genderAgeText (): string {
        return [this.computedGender, this.computedAge ? `${this.computedAge} ${this.$t('customerSearch.years')}` : ''].filter(Boolean).join(', ')
      },
      section (): string {
        var self = this
        var section = _.find(this.$store.state.sections, (s) => {
          return s.id === self.volunteer.sectionId
        })

        return section ? section.name : ''
      },
      zipCodeText (): string {
        return this.volunteer.useWorkingZipCode ? this.volunteer.workingZipCode : this.volunteer.zipCode
      }
    },
    methods: {
      daysWithoutFriend (lastFriendshipDate): string {
        if (!lastFriendshipDate) {
          return `${this.$t('customerSearch.neverHadFriend')}`
        }
        // @ts-ignore (mixin not found)
        return `${this.calculateDayDiff(lastFriendshipDate)} ${this.$t('customerSearch.daysWithoutFriend')}`
      },
      onVolunteerSelected (): void {
        this.$bus.$emit('volunteerFriendshipSelected', this.volunteer)
      },
      onPoolClicked (pool): void {
        this.$router.push(`/friend/pools/${this.group.id}/${pool.id}`)
      },
      selectVolunteer (): void {
        this.$bus.$emit('volunteerSelected', this.volunteer.profileId)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .volunteernetfindresult {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $brand-white;
    border-left: solid 5px $volunteer-color-strong;
    margin: 10px 0;
    padding: 20px 0;
    position: relative;

    &.hascustomer {
      border-right: solid 5px $customer-color-strong;
    }

    .identuser {
      position: absolute;
      top: 5px;
      left: 5px;
      color: $brand-red;
    }

    .friend-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .friendinfo {
        padding-left: 10px;
        text-align: left;
        font-size: 13px;
        .friendname {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }

    .description-container {
      .description {
        color: $brand-red;
        font-style: italic;
        font-size: 13px;
        text-align: center;
      }
    }

    .customer-container {
      text-align: right;
      font-size: 13px;
      .customerrow {
        padding: 3px 0;
        display: block;

        .customerinfo {
          display: inline-block;
          margin-right: 5px;
          text-transform: uppercase;
          color: $brand-red;
          font-weight: bold;
        }
        .customerimage {
          display: inline-block;
          .friendicon {
            width: 24px;
            height: 24px;
            .lettertext {
              font-size: 13px;
            }
          }
        }
      }

      .nocustomer {
        text-align: right;
        font-size: 13px;
      }

      .pools {
        cursor: pointer;
        padding: 3px 0;
        display: block;
        .poolinfo {
          display: inline-block;
          margin-right: 5px;
          text-transform: uppercase;
          color: $brand-red;
          font-weight: bold;
        }
        .poolimage {
          display: inline-block;
          .poolicon {
            width: 24px;
            height: 24px;
            .lettertext {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
</style>
