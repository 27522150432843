<template>
  <div class="comp-section-groups">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading" class="loading text-center brand-red">
          <div class="my-2">
            <loading />
          </div>
          <div class="my-3">
            <p>{{ $t('userAdmin.loading') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        <h5>{{ $t('userAdmin.actionGroups') }}</h5>
      </div>
      <div class="col-5 text-right add-cta">
        <sprbutton v-if="hasRightsToCreateActionGroup"
                   type="primary"
                   :size="4"
                   :title="$t('userAdmin.createGroup')"
                   class="spr-redborder button-width"
                   click-event="createGroup"
                   @createGroup="onCreateActionGroup"
        />
      </div>
    </div>
    <div v-if="showCreateActionGroup" class="row creategroup">
      <div class="col-12">
        <groupcreate :grouptype="1" is-section-group @save="onSaveActionGroup" @cancel="onCancelSaveActionGroup" />
      </div>
    </div>
    <div class="row actiongrouplist">
      <div class="col-12">
        <div v-if="!isLoading && actiongroups.length" class="listing">
          <grouplist :list="actiongroups" />
        </div>
        <div v-if="!isLoading && !actiongroups.length" class="noresults">
          {{ $t('userAdmin.noResults') }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-7">
        <h5>{{ $t('userAdmin.standardGroups') }}</h5>
      </div>
      <div class="col-5 text-right add-cta">
        <sprbutton type="primary"
                   :size="4"
                   :title="$t('userAdmin.createGroup')"
                   class="spr-redborder button-width"
                   click-event="createGroup"
                   @createGroup="onCreateStandardGroup"
        />
      </div>
    </div>
    <div v-if="showCreateStandardGroup" class="row creategroup">
      <div class="col-12">
        <groupcreate :grouptype="2" is-section-group @save="onSaveStandardGroup" @cancel="onCancelSaveStandardGroup" />
      </div>
    </div>
    <div class="row standardgrouplist">
      <div class="col-12">
        <div v-if="!isLoading && standardgroups.length" class="listing">
          <grouplist :list="standardgroups" />
        </div>
        <div v-if="!isLoading && !standardgroups.length" class="noresults">
          {{ $t('userAdmin.noResults') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import grouplist from './GroupList.vue'
  import groupcreate from './GroupCreate.vue'
  import loading from '../SPRLoading.vue'

  export interface TemplateComponentData {
    actiongroups: any[],
    standardgroups: any[],
    isLoading: boolean,
    showCreateActionGroup: boolean,
    showCreateStandardGroup: boolean
  }

  export default Vue.extend({
    name: 'AdminSectionGroups',
    components: { sprbutton, grouplist, groupcreate, loading },
    data (): TemplateComponentData {
      return {
        actiongroups: [],
        standardgroups: [],
        isLoading: false,
        showCreateActionGroup: false,
        showCreateStandardGroup: false
      }
    },
    computed: {
      hasRightsToCreateActionGroup (): boolean {
        //@ts-ignore
        return this.hasAdminToSection(this.$route.params.id, true)
      }
    },
    mounted () {
      this.loadGroups()
    },
    methods: {
      loadGroups () {
        this.isLoading = true
        var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupsbysection[0]}`
        url = url.replace('{0}', this.$route.params.id)
        this.$http.get(url).then((success: any) => {
          var groups = success.body

          this.actiongroups = _.filter(groups, (group) => {
            return group.groupType === 1
          })

          this.standardgroups = _.filter(groups, (group) => {
            return group.groupType === 2
          })
          this.isLoading = false
        })
      },
      onCreateActionGroup () {
        this.showCreateActionGroup = true
      },
      onSaveActionGroup (group) {
        group.groupType = 1
        this.createGroup(group).then((resp) => {
          this.loadGroups()
          this.showCreateActionGroup = false
        })
      },
      onCancelSaveActionGroup () {
        this.showCreateActionGroup = false
      },
      onCreateStandardGroup () {
        this.showCreateStandardGroup = true
      },
      onSaveStandardGroup (group) {
        group.groupType = 2
        this.createGroup(group).then((resp) => {
          this.loadGroups()
          this.showCreateStandardGroup = false
        })
      },
      onCancelSaveStandardGroup () {
        this.showCreateStandardGroup = false
      },
      createGroup (group) {
        var self = this
        var url = `${self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupsectionadd[0]}`
        return self.$http.post(url, group)
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .comp-section-groups {
    margin-top:20px;
    .actiongrouplist,
    .standardgrouplist,
    .editgroup {
      background-color:$brand-white;
      padding:20px 0;
      margin-bottom: 40px;
    }

    .noresults {
      text-align:center;
    }

    .add-cta {
      margin-bottom: 5px;
    }

    .creategroup {
      margin-bottom: 30px;
    }
  }
</style>
