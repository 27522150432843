<template>
  <div class="friendshipstatus">
    <div class="row">
      <div class="col maintitle">
        <span class="title">{{ $t('customerSearch.friendshipStatusTitle') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <statusindicator v-model="selectedStatus" :statuses="statuses" />
      </div>
      <div class="col rule">
        <hr>
      </div>
    </div>
    <div v-if="isPending">
      <div class="row">
        <div class="col proposedtitle">
          <span class="title">{{ $t('customerSearch.friendshipProposalSentTitle') }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col proposed-instructions">
          {{ $t('customerSearch.friendshipProposalInstructions') }}
        </div>
      </div>
      <div class="row">
        <div class="col buttons">
          <sprbutton :size="4"
                     :title="$t('customerSearch.activateButton')"
                     click-event="activate"
                     class="activate-button"
                     @activate="activate"
          />
          <sprbutton :size="4"
                     :title="$t('customerSearch.cancelProposedButton')"
                     click-event="cancel"
                     class="cancel-button"
                     @cancel="cancel"
          />
        </div>
      </div>
    </div>
    <div v-if="isActive">
      <div class="row">
        <div class="col started">
          <span class="title">{{ $t('customerSearch.friendshipStartedTitle') }}</span>
          {{ startedPending }}
        </div>
      </div>

      <div class="row">
        <div class="col proposed-instructions">
          <span>{{ $t('customerSearch.friendshipActivationInstructions') }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col status">
          <span class="title">{{ $t('customerSearch.statusTitle') }}: {{ selectedStatusText }}</span>
        </div>
      </div>
      <div v-if="canSendMessage">
        <div v-if="!messaging" class="row">
          <div class="col messagelink">
            <a href="javascript:void(0)" @click="startSendMessage"><span class="title">{{ $t('customerSearch.sendMessageTitle') }} <font-awesome-icon icon="chevron-right" /></span></a>
          </div>
        </div>
        <div v-if="sendSuccess" class="sendsuccess">
          {{ $t('customerSearch.messageSendSuccess') }}
        </div>
        <div v-if="messaging" class="row messageform">
          <div class="col-12 message">
            <div class="subject">
              <forminput v-model="subject"
                         :hint="$t('customerSearch.messageSubject')"
                         :is-valid="true"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="messagebody">
              <textareainput v-model="content"
                             :title="$t('customerSearch.messageContent')"
                             :rows="5"
              />
            </div>
          </div>
          <div class="col-12 text-left">
            <checkboxinput v-model="sendAsMail"
                           :title="$t('customerSearch.sendAsMail')"
                           :aria-label="$t('customerSearch.sendAsMail')"
            />
          </div>
        </div>
        <div v-if="messaging" class="row">
          <div class="col buttons">
            <sprbutton type="primary"
                       :size="4"
                       :title="$t('sectionMessages.sendMessage')"
                       class="spr-redborder button-width"
                       click-event="sendMessage"
                       @sendMessage="sendMessage"
            />
            <div class="cancel">
              <a href="javascript:void(0);" @click="cancelSendMessage">{{ $t('customerSearch.cancel') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col buttons">
          <sprbutton :size="4"
                     :title="$t('customerSearch.endFriendshipButton')"
                     click-event="end"
                     class="end-button"
                     @end="end"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import statusindicator from '../SPRStatusIndicator.vue'
  import sprbutton from '../SPRButton.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import forminput from '../SPRSimpleText.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import moment from 'moment'
  import { FriendshipStandardDto, FriendshipNetDto, FriendshipState, SendFriendshipVolunteerMessage, FriendManagementDto } from '@/types/index'
  import { TranslateResult } from 'vue-i18n'

  export default Vue.extend({
    name: 'FriendshipStatus',
    components: { statusindicator, sprbutton, textareainput, forminput, checkboxinput },
    props: {
      friendship: {
        type: Object as () => FriendshipStandardDto | FriendshipNetDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      }
    },
    data () {
      return {
        statuses: [
          { key: 0, label: '1', title: this.$t('customerSearch.customerStateNone') },
          { key: 1, label: '2', title: this.$t('customerSearch.customerStatePending') },
          { key: 2, label: '3', title: this.$t('customerSearch.customerStateActive') }
        ],
        messaging: false,
        subject: '',
        content: '',
        sendAsMail: false,
        sendSuccess: false
      }
    },
    computed: {
      selectedStatus (): FriendshipState {
        return this.friendship.friendshipState
      },
      selectedStatusText (): string | TranslateResult {
        var self = this
        var status = _.find(this.statuses, function (s) {
          return s.key === self.selectedStatus
        })

        if (status) return status.title

        return ''
      },
      isPending (): boolean {
        return this.friendship.friendshipState === FriendshipState.PendingApproval
      },
      isActive (): boolean {
        return this.friendship.friendshipState === FriendshipState.Active
      },
      isTerminated (): boolean {
        return this.friendship.friendshipState === FriendshipState.Terminated
      },
      startedPending (): string {
        return moment(this.friendship.started).format('DD.MM.YYYY HH:mm')
      },
      canSendMessage (): boolean {
        return this.friendship.volunteer && !this.friendship.volunteer.isNonIdent
      }
    },
    methods: {
      cancel (): void {
        var self = this
        //todo: change to this.friendship
        this.$emit('cancelpending', this.friendship)
      },
      activate (): void {
        var self = this
        this.$emit('activate', this.friendship)
      },
      end (): void {
        var self = this
        this.$emit('terminate', this.friendship)
      },
      isStandardGroup (friendship: FriendshipStandardDto | FriendshipNetDto): boolean {
        return (friendship as FriendshipStandardDto).customerProfileStandardId !== undefined
      },
      sendMessage (): void {
        var self = this
        var url = this.group.netFriendship
          ? `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.sendnetfriendshipvolunteermessage[0]}`
          : `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.sendstandardfriendshipvolunteermessage[0]}`
        var messageDto = {
          subject: this.subject,
          content: this.content,
          senderProfileId: this.$store.state.profile.profileId,
          sendAsEmail: this.sendAsMail,
          volunteerId: this.friendship.volunteer.volunteerId,
          friendManagementGroupId: this.group.id
        } as SendFriendshipVolunteerMessage
        this.$http.post(url, messageDto).then((resp) => {
          this.messaging = false
          this.sendSuccess = true
          this.subject = ''
          this.content = ''
        })
      },
      cancelSendMessage (): void {
        this.messaging = false
      },
      startSendMessage (): void {
        this.messaging = true
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .friendshipstatus {
    text-align: center;

    .title {
      text-transform: uppercase;
      color:$brand-red;
      font-weight: bold;
      display:block;
    }

    .sendsuccess {
      color: $brand-success;
      margin-bottom: 20px;
    }

    .maintitle {
      margin: 30px 0;
    }

    .rule {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .started {
      margin-bottom: 20px;
    }

    .status {
      margin-bottom: 20px;
    }

    .messagelink {
      margin-bottom: 20px;
    }

    .messageform {
      text-align: left;
    }

    .proposedtitle {
      margin-bottom: 20px;
    }

    .proposed-instructions {
      margin-bottom: 30px;
    }

    .buttons {
      margin-bottom: 30px;
      button {
        margin-bottom:5px;
      }
    }
  }
</style>
