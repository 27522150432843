<template>
  <div class="register-thanks">
    <div class="swapped-elements container-fluid brand-white">
      <div class="row mt-5">
        <div class="col-12">
          <span class="h1"
                role="heading"
                aria-level="1"
          >{{ $t('wizard.thanksHeader') }}</span>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-linkified:options="{ nl2br: true }" class="top-span" v-html="$t('wizard.joinCallToAction')" />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 mt-1">
          <sprbutton :size="1"
                     :title="$t('wizard.joinButton')"
                     click-event="tojoin"
                     @tojoin="join"
          />
        </div>
        <div class="col-12 mt-1">
          <sprbutton :size="1"
                     :title="$t('wizard.noJoinButton')"
                     click-event="tologin"
                     @tologin="login"
          />
        </div>
        <div class="col-12 mt-1">
          <sprbutton :size="1"
                     :title="$t('wizard.memberAlready')"
                     click-event="tologin"
                     @tologin="login"
          />
        </div>
      </div>
    </div>
    <div class="common-elements">
      <a class="privacy-link link" href="https://www.punainenristi.fi/tietosuoja" target="_blank">{{ $t('wizard.privacyPolicy') }}</a>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'

  export default {
    name: 'ThanksWizard',
    pageName: 'signup-wizard-thankyou',
    components: { sprbutton },
    methods: {
      join: function () {
        this.$router.push({
          name: 'join',
          query: {
            source: 'register'
          }
        })
      },
      login: function () {
        sessionStorage.setItem('email', this.$store.state.wizardModel.email)
        this.$router.push({
          name: 'login',
          query: {
            message: 'registersuccess'
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';

  .register-thanks {
    box-sizing: border-box;
    padding: 0 10px;
    width: 100%;

    min-height: calc(100vh - 200px);
    padding-bottom: 25px;

    background:$brand-back center center;
    background-repeat: no-repeat;
    background-size: contain cover;
    text-align: center;

    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;

    -ms-grid-columns: 1fr;
    grid-template-rows: auto 40px 180px;
    -ms-grid-rows: auto 40px 180px;

    .next-button {
      width: 250px;
    }

    .back-link {
      padding: 0;
      background: none;
      border: none;
      font-size: 15px;
      text-transform: uppercase;
      font-family: $font-bold;
      letter-spacing: 1.25px;
      color: white;
      margin: 25px 0 15px 0;
      display: inline-block;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .swapped-elements {
      grid-column: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    .common-elements {
      grid-column: 1;
      grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    .privacy-link {
      color: $brand-grey5;
      padding: 2px;
      margin: 18px;
      display: inline-block;
    }
  }

</style>
