import { TranslateResult, LocaleMessage } from 'vue-i18n'
import { EventParticipantDto, FriendCustomerStandardDto, FriendCustomerNetDto, UserProfileDto } from './index'

export interface SprDropDownValue {
  value: number | string | Record<string, any>,
  text: string | TranslateResult,
  selected?: boolean
}

export interface NotificationContacts {
  contacts: string[] | null,
  notificationFrequency: number
}

export interface DatePickerOptions {
  type: string,
  week: string[],
  month: string[],
  format: string,
  placeholder?: string,
  inputStyle?: any,
  errorStyle?: any,
  color?: any,
  buttons?: any,
  overlayOpacity?: number,
  dismissible?: boolean
}

export interface SprDropDownNumberValue {
  value: number,
  text: string,
  selected?: boolean
}

export interface DropdownSelectItem {
  id: string,
  text: string
}

export interface EventOrganizerIdentifier {
  eventOrganizerType: number,
  organizerId: number
}

export interface VueMultiSelectItem {
  id: number,
  text: string
}

export interface SprCheckboxBitmaskValue {
  value: number,
  text: string
}

export interface SprCheckboxObjectItem {
  key: number,
  obj: Record<string, any>,
  text: string
}

export interface KeyNumberStringValuePair {
  key: number,
  value: string
}

export interface KeyStringValueNumberPair {
  key: string,
  value: number
}

export interface TextStringValueNumberPair {
  text: string,
  value: number
}

export interface StringKeyValuePair {
  value: string,
  key: string
}

export interface FilterItem {
  id: number | string,
  name: string | TranslateResult
}

export interface SprCheckboxValue {
  value: string | number | Record<string, any>,
  text: string,
  selected?: boolean
}

// this is the object that will be stored in the imageLib array
// it contains the filename, the url of the image and a preview url
// the preview url is the same as the image url, but it is used to display the image
export  interface ImageObject {
    filename: string,
    url: string,
    prev: string
}

export interface SprRadioPillOption {
  value: string | number | boolean,
  text: string | TranslateResult
}

export interface InterviewData {
  interviewed: boolean,
  interviewedBy: string,
  interviewedDate: Date | undefined
}

export interface OnHoldData {
  onHold: boolean,
  onHoldDeadline?: Date,
  onHoldDetails: string
}

export interface ApprovalData {
  approval: boolean,
  approvalDate: string,
  approvedBy: string
}

export interface SuitabilityData {
  notForPrivateRelations: boolean,
  notForSpr: boolean,
  notForPrivateRelationsReason: string,
  notForPrivateRelationsBy: string,
  notForSprReason: string,
  notForSprBy: string
}

export interface SearchFilter {
  searchText: SearchFilterText,
  skip: number,
  pagesize: number,
  searchType: 'CUSTOMER' | 'VOLUNTEER' | 'POOLS',
  sortBy: 'NEWEST' | '',
  customerStates: number[] | null,
  customerTypes: number[] | null,
  interests: number[] | null,
  friendshipTypes: number[] | null,
  languages: string[] | null,
  motherTongs: string[] | null,
  ages: number[] | null
}

export interface SearchFilterText {
  customer: string,
  volunteer: string
}

export interface MatchFilter {
  customerTypes: number[] | null,
  languages: string[] | null,
  motherTongs: string[] | null,
  ages: number[] | null
}

export interface TravelData {
  travelDistanceTime: string,
  isMatch: boolean,
  isRealDistance: boolean
}

export interface MatchData {
  travelData: TravelData | undefined,
  availabilityTimesMatch: boolean | undefined,
  availabilityFrequencyMatch: boolean | undefined,
  genderMatch: YesNoWarning,
  ageMatch: boolean,
  languageMatch: boolean,
  interestMatch: boolean
}

export interface MatchDataNet {
  genderMatch: YesNoWarning,
  ageMatch: boolean,
  languageMatch: boolean,
  interestMatch: boolean
}

export interface MatchPair {
  volunteer: UserProfileDto,
  message: string | LocaleMessage
}

export interface PortraitInfo {
  id: number,
  url: string
}

export interface SprPaymentProduct {
  id: number,
  code: string,
  priceCents: number,
  vat: number,
  disabled: boolean,
  annual: boolean,
  descriptionFin: string,
  descriptionSwe: string,
  minAge: number | null,
  maxAge: number | null
}

export interface StandardMatchPair extends MatchPair {
  customer: FriendCustomerStandardDto
}

export interface NetMatchPair extends MatchPair {
  customer: FriendCustomerNetDto
}

export const enum YesNoWarning {
  No = 2,
  Warning = 3,
  Yes = 1
}

export const enum AnonNetCustomerRegistrationStep {
  Start = 1,
  PersonalInfo = 2,
  LanguageInfo = 3,
  InterestInfo = 4,
  FriendInfo = 5,
  ContactInfo = 6,
  Summary = 7,
  Success = 8
}

export const enum AnonStandardCustomerRegistrationStep {
  Start = 1,
  GroupContactInfo = 2,
  PersonalInfo = 3,
  LanguageInfo = 4,
  InterestInfo = 5,
  FriendInfo = 6,
  ContactInfo = 7,
  Summary = 8,
  Success = 9
}
