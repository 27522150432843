<template>
  <div class="anonnetcustomerform col-12">
    <div v-if="isStart">
      <Start
        @next="next"
      />
    </div>
    <div v-else>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <StepVizualizer
              :step="step"
            />
            <PersonalInfo
              v-if="isPersonalInfo"
              :customer="customer"
              @next="next"
              @prev="prev"
            />
            <LanguageInfo
              v-if="isLanguageInfo"
              :customer="customer"
              @next="next"
              @prev="prev"
            />
            <InterestInfo
              v-if="isInterestInfo"
              :customer="customer"
              @next="next"
              @prev="prev"
            />
            <FriendInfo
              v-if="isFriendInfo"
              :customer="customer"
              @next="next"
              @prev="prev"
            />
            <ContactInfo
              v-if="isContactInfo"
              :customer="customer"
              @next="next"
              @prev="prev"
            />
            <Summary
              v-if="isSummary"
              :customer="customer"
              @next="next"
              @prev="prev"
            />
            <Success
              v-if="isSuccess"
              @next="next"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import StepVizualizer from './StepVizualizer.vue'
  import Start from './Start.vue'
  import PersonalInfo from './PersonalInfo.vue'
  import LanguageInfo from './LanguageInfo.vue'
  import InterestInfo from './InterestInfo.vue'
  import FriendInfo from './FriendInfo.vue'
  import ContactInfo from './ContactInfo.vue'
  import Summary from './Summary.vue'
  import Success from './Success.vue'
  import { AvailabilityFrequence, CustomerState, FriendCustomerNetDto, Gender, Interests, NetCustomerTypes, PreferredAges, PreferredGenders } from '@/types/index'
  import { AnonNetCustomerRegistrationStep } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    initialized: boolean,
    isSending: boolean,
    customer: FriendCustomerNetDto,
    emptyCustomer: FriendCustomerNetDto,
    intervDate: string,
    intervName: string,
    approvDate: string,
    approvName: string,
    groupDate: string,
    groupName: string,
    declineDate: string,
    declineName: string,
    step: AnonNetCustomerRegistrationStep
  }

  export default Vue.extend({
    name: 'AnonNetCustomerForm',
    components: { StepVizualizer, Start, PersonalInfo, LanguageInfo, InterestInfo, FriendInfo, ContactInfo, Summary, Success },
    props: {
      id: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        step: AnonNetCustomerRegistrationStep.Start,
        customer: (this as any).initialCustomer(),
        emptyCustomer: (this as any).initialCustomer(),
        isSending: false,
        intervDate: '',
        intervName: '',
        approvDate: '',
        approvName: '',
        groupDate: '',
        groupName: '',
        declineDate: '',
        declineName: '',
        initialized: false
      }
    },
    computed: {
      isStart (): boolean {
        return this.step === Number(AnonNetCustomerRegistrationStep.Start)
      },
      isPersonalInfo (): boolean {
        return this.step === Number(AnonNetCustomerRegistrationStep.PersonalInfo)
      },
      isLanguageInfo (): boolean {
        return this.step === Number(AnonNetCustomerRegistrationStep.LanguageInfo)
      },
      isInterestInfo (): boolean {
        return this.step === Number(AnonNetCustomerRegistrationStep.InterestInfo)
      },
      isFriendInfo (): boolean {
        return this.step === Number(AnonNetCustomerRegistrationStep.FriendInfo)
      },
      isContactInfo (): boolean {
        return this.step === Number(AnonNetCustomerRegistrationStep.ContactInfo)
      },
      isSummary (): boolean {
        return this.step === Number(AnonNetCustomerRegistrationStep.Summary)
      },
      isSuccess (): boolean {
        return this.step === Number(AnonNetCustomerRegistrationStep.Success)
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.changeDetected()) {
        var text = this.$t('profile.unsavedChanges').toString()
        const answer = window.confirm(text)
        if (!answer) {
          return next(false)
        }
      }

      return next()
    },
    methods: {
      changeDetected (): boolean {
        return JSON.stringify(this.emptyCustomer) !== JSON.stringify(this.customer)
      },
      initialCustomer (): FriendCustomerNetDto {
        return {
          id: 0,
          availabilityFrequency: AvailabilityFrequence.NotDefined,
          otherLanguages: '',
          firstName: '',
          lastName: '',
          streetAddress: '',
          city: '',
          zipCode: '',
          gsmPhone: '',
          homePhone: '',
          email: '',
          birthdate: new Date(),
          contactFirstName: '',
          contactLastName: '',
          contactStreetAddress: '',
          contactZipCode: '',
          contactCity: '',
          contactPhonenumber: '',
          contactEmail: '',
          contactRelation: '',
          gender: Gender.Male,
          customerTypes: NetCustomerTypes.NotDefined,
          preferredGenders: PreferredGenders.Male | PreferredGenders.Female | PreferredGenders.Other,
          preferredAges: PreferredAges.NotDefined,
          nativeLanguage: 'fi',
          customerLanguages: [],
          interests: Interests.NotDefined,
          interestOther: '',
          description: '',
          shortDescription: '',
          customerInterviewed: false,
          customerApproval: false,
          customerNotForPrivateRelations: false,
          customerNotForSpr: false,
          customerNotForSprBy: '',
          customerNotForPrivateRelationsBy: '',
          state: CustomerState.NotDefined,
          customerInterviewedBy: '',
          customerApprovedBy: '',
          startedPending: new Date(),
          otherContact: '',
          econtactEmail: '',
          econtactTelegram: '',
          econtactKik: '',
          econtactInstagram: '',
          econtactSkype: '',
          econtactDiscord: '',
          econtactFreetext: '',
          onHold: false,
          onHoldComment: '',
          onHoldUntilDate: undefined,
          privateNotes: '',
          friendPools: [],
          friendships: [],
          customerTypeOtherInfo: '',
          friendManagementGroupId: 0,
          fullname: '',
          lastFriendshipEndDate: new Date(),
          multipleVolunteers: false
        } as FriendCustomerNetDto
      },
      next (cust: FriendCustomerNetDto) {
        if (this.step === Number(AnonNetCustomerRegistrationStep.Success)) {
          this.$router.push('/')
        }
        if (this.step === Number(AnonNetCustomerRegistrationStep.Summary)) {
          this.save()
        }
        if (cust) {
          this.customer = cust
        }
        ++this.step
      },
      prev (cust: FriendCustomerNetDto) {
        if (this.step === Number(AnonNetCustomerRegistrationStep.Start)) return
        if (cust) {
          this.customer = cust
        }
        --this.step
      },
      save () {
        var self = this
        this.$validator.validateAll().then(result => {
          var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.anonymousnetcustomeradd[0]

          this.$http.post(url, this.customer).then(response => {
            if (response.ok) {
              self.isSending = false

              this.step = AnonNetCustomerRegistrationStep.Success
            }
          }, response => {
            this.displayError(response)
            self.isSending = false
          })
        })
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .anonnetcustomerform {
    padding: 0;
  }

</style>