export default {
  methods: {
    firstError () {
      var firstErrorId = this.$validator.errors.items[0].id
      const firstErrorField = this.$validator.fields.itemsById[firstErrorId]
      if (firstErrorField) {
        const errorElement = firstErrorField.el
        if (errorElement) {
          errorElement.scrollIntoView()
        }
      }
    }
  }
}
