export default {
  methods: {
    isoToId: function (iso) {
      var lang = _.find(this.$store.state.allLanguages, function (x) {
        return x.isoCode === iso
      })

      if (lang) {
        return lang.id
      }

      return null
    },
    eventTranslation: function (model) {
      if (model && model.translations && model.translations.length !== 0) {
        const code = this.isoToId(this.$store.state.language)
        const lang = _.find(model.translations, function (x) {
          return x.languageId === code
        })
        if (lang) {
          return lang
        }

        // fall back to finnish, or if finnish was already searhed for, swedish
        const fallbackCode = code === 54 ? 200 : 54
        const fallbackLang = _.find(model.translations, function (y) {
          return y.languageId === fallbackCode
        })
        if (fallbackLang) {
          return fallbackLang
        }

        // just pick whatever language we can find
        return model.translations[0]
      }

      return {}
    }
  }
}
