
<template>
  <!-- BACK BUTTON -->
  <div class="nav-header row py-2">
    <div class="container">
      <a class="back" tabindex="0" href="javascript: void(0);" @click="goBack">
        <font-awesome-icon icon="chevron-left" />{{ $t('customerForm.back') }}
      </a>
      <h3 v-if="title">
        {{ title }} <a v-if="infobutton" :href="infobutton" target="_blank" rel="noopener"><font-awesome-icon icon="info-circle" /></a>
      </h3>
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BackBar',
    props: {
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
      infobutton: {
        type: String,
        default: ''
      },
      back: {
        type: String,
        default () {
          return ''
        }
      }
    },
    methods: {
      goBack () {
        if (this.back) {
          this.$router.push(this.back)
        } else if (sessionStorage.getItem('prev') === '/') {
          this.$router.push('/')
        } else {
          this.$router.back()
        }
      }
    }
  }
</script>

<style lang="scss">
@import "../assets/scss/_variables.scss";
  .nav-header {
    background-color: #EEEEEE;
    overflow-wrap: break-word;
    border-bottom: solid 1px #DDDDDD;

    h3 {
      color: $brand-red;
      margin-top: 10px;

      svg {
        display: inline-block;
        position: relative;
        font-size: 0.67em;
        top: -1px;
      }
    }

    a {
      color:#000;

      svg {
        color:$brand-red;
        margin-right: 5px;
      }
    }
  }
</style>
