<template>
  <div class="customermatchresults">
    <div class="row search">
      <div class="col-12">
        <searchbox v-model="search" :placeholder="placeholder" />
      </div>
    </div>
    <div class="col">
      <div class="row results-list">
        <ul>
          <li v-for="item in filteredItems" :key="item.id">
            <customermatchview :match="item" @volunteerSelected="onVolunteerSelected(item)" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import customermatchview from './CustomerMatchView.vue'
  import searchbox from '../SPRSearchBox.vue'
  import { MatchItemNetVolunteerDto, MatchItemStandardVolunteerDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    placeholder: string | LocaleMessage,
    search: string
  }

  export default Vue.extend({
    name: 'CustomerMatchResults',
    components: { customermatchview, searchbox },
    props: {
      list: {
        type: Array as () => Array<MatchItemNetVolunteerDto> | Array<MatchItemStandardVolunteerDto>,
        required: true
      }
    },
    data () {
      return {
        placeholder: this.$t('customerSearch.friendSearchPlaceholder'),
        search: ''
      }
    },
    computed: {
      filteredItems (): Array<MatchItemNetVolunteerDto> | Array<MatchItemStandardVolunteerDto> {
        if (this.search === '') {
          return this.list
        }
        var searchTerms = this.search.toLowerCase().split(/[^a-z0-9åäö]/)

        var textParts: string[] = _.filter(searchTerms, s => { return isNaN(Number(s)) })
        var zipParts: string[] = _.filter(searchTerms, s => { return !isNaN(Number(s)) })

        // @ts-ignore
        return _.filter(this.list, (item: MatchItemNetVolunteerDto | MatchItemStandardVolunteerDto) => {
          return (
            _.every(textParts, s => {
              return _.includes(item.match.firstName.toLowerCase() + ' ' + item.match.lastName.toLowerCase(), s)
            }) ||
            _.every(textParts, s => {
              return _.includes(item.match.shortDescription.toLowerCase(), s)
            })
          ) && (
            !zipParts.length ||
            !item.match.hasNetProfile || // its a MatchItemNetVolunteerDto, which doesnt have postalCode or workingZipCode
            _.some(zipParts, z => {
              // @ts-ignore
              return item.postalCode
                // @ts-ignore
                ? _.startsWith(item.match.postalCode, z)
                // @ts-ignore
                : _.startsWith(item.match.workingZipCode, z)
            })
          )
        })
      }
    },
    methods: {
      onVolunteerSelected (item: MatchItemNetVolunteerDto | MatchItemStandardVolunteerDto): void {
        this.$emit('volunteerSelected', item)
      }
    }
  })
</script>
<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .customermatchresults {
    ul {
      width: 100%;
      list-style-type: none;
      margin: 0;
      padding: 0;
        li {
          margin:0;
          padding:0;
        }
      }
    .search {
      margin-bottom: 15px;
    }
  }
</style>
