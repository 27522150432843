<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .groupmemberspending {
    background: #fff;
    padding-top: 20px;
  }
</style>

<template>
  <div class="row groupmemberspending">
    <div class="col-sm-12">
      <p v-if="group.pendingMembers && group.pendingMembers.length === 0">
        Ei uusia hakemuksia
      </p>
      <div v-for="(member) in group.pendingMembers" :key="member.id" class="row results-row">
        <pendingmember :member="member" :group-id="group.id" :group-name="group.name" />
      </div>
    </div>
  </div>
</template>

<script>
  import pendingmember from './PendingMemberView.vue'

  export default {
    name: 'Groupmemberspending',
    components: { pendingmember },
    props: {
      group: {
        type: Object,
        default: () => { return {} }
      }
    },
    mounted () {
      this.$bus.$on('removePendingVolunteer', this.removePendingVolunteer)
    },
    beforeDestroy: function () {
      this.$bus.$off('removePendingVolunteer')
    },
    methods: {
      removePendingVolunteer (volunteer) {
        const self = this
        _.find(this.group.pendingMembers, function (v, index) {
          if (v && v.id === volunteer.id) {
            self.group.pendingMembers.splice(index, 1)
          }
        })
      }
    }
  }
</script>
