<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .selectedVolunteers  {
    margin-bottom: 14px;
  }
  .messages {
    margin-top: 20px;
    .messagelist {
      background-color:$brand-white;
    }
  }

  .all-messages {
    margin-top: 20px;
  }

  .sendMessage {
    textarea {
      min-width: 100%;
    }

  input[type=text] {
    min-width: 100%;
  }
  }

  .sendButtons {
    margin-top: 25px;
  }
  .sendsuccess {
    color: $brand-success;
  }

</style>

<template>
  <div class="container messages">
    <div v-if="!sendMessageShown" class="row">
      <div class="text-center col-4">
        <sprbutton type="primary"
                   :size="4"
                   :title="$t('sectionMessages.sendSectionMessage')"
                   class="spr-redborder button-width"
                   click-event="sendMessage"
                   @sendMessage="showSendMessage(2)"
        />
      </div>
      <div class="text-center col-4">
        <sprbutton type="primary"
                   :size="4"
                   :title="$t('sectionMessages.sendToGroupManagers')"
                   class="spr-redborder button-width"
                   click-event="sendMessage"
                   @sendMessage="showSendMessage(1)"
        />
      </div>
      <div class="text-center col-4">
        <sprbutton v-if="hasSelectedVolunteers" type="primary"
                   :size="4"
                   :title="$t('sectionMessages.sendToSelectedContact')"
                   class="spr-redborder button-width"
                   click-event="sendMessage"
                   @sendMessage="showSendMessage(3)"
        />
      </div>
      <div v-if="sendSuccess" class="sendsuccess text-center col-12 my-3">
        {{ $t('sectionMessages.messageSendSuccess') }}
      </div>
      <div v-if="removeSuccess" class="sendsuccess text-center col-12 my-3">
        {{ $t('sectionMessages.messageRemoveSuccess') }}
      </div>
    </div>
    <div v-if="this.type == 3" class="row mb-2 new-volunteers">
      <selectedlisting :users="this.$store.state.selectedVolunteers" />
    </div>
    <div v-if="sendMessageShown" class="row">
      <div class="col-12">
        <h5>{{ messageHeader }}</h5>
      </div>
      <div class="sendMessage col-12">
        <div class="subject">
          <forminput v-model="subject"
                     :hint="$t('sectionMessages.messageSubject')"
                     :is-valid="true"
          />
        </div>
        <div class="messagebody">
          <textareainput v-model="content"
                         :title="$t('sectionMessages.messageContent')"
                         :rows="5"
          />
        </div>
      </div>
      <div class="col-12">
        <checkboxinput v-model="sendAsMail"
                       :title="$t('sectionMessages.sendAsMail')"
                       :aria-label="$t('sectionMessages.sendAsMail')"
        />
      </div>
      <div class="col-12 align-bottom sendButtons">
        <sprbutton type="primary"
                   :size="4"
                   :title="$t('sectionMessages.sendMessage')"
                   class="spr-redborder button-width"
                   click-event="sendMessage"
                   @sendMessage="sendMessage"
        />
        <sprbutton type="primary"
                   :size="4"
                   :title="$t('sectionMessages.cancel')"
                   class="spr-redborder button-width"
                   click-event="sendMessage"
                   @sendMessage="cancelSendMessage"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 all-messages">
        <h3>{{ $t('sectionMessages.title') }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="messagelist">
          <div v-if="messages && !messagesLoading">
            <message v-for="(message, index) in messages" :key="_uid + '_message_' + index" :message="message" />
          </div>
          <div v-if="!messages && !messagesLoading" class="nomessages">
            <span>{{ $t('sectionMessages.noMessages') }}</span>
          </div>
          <div v-if="messagesLoading" class="brand-red">
            <loading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import message from './AdminMessageView.vue'
  import sprbutton from '../SPRButton.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import forminput from '../SPRSimpleText.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import loading from '../SPRLoading.vue'
  import moment from 'moment'
  import selectedlisting from './SelectedVolunteerList.vue'
  export default {
    name: 'Sectionmessages',
    components: { message, sprbutton, textareainput, forminput, checkboxinput, loading, selectedlisting },
    data () {
      return {
        messages: [],
        messagesLoading: false,
        subject: '',
        content: '',
        sendMessageShown: false,
        sendSuccess: false,
        removeSuccess: false,
        type: 0,
        sendAsMail: false
      }
    },
    computed: {
      messageHeader: function () {
        if (this.type === 2) {
          return this.$t('sectionMessages.sectionMessageHeader')
        }
        if (this.type === 1) {
          return this.$t('sectionMessages.sectionGroupLeadersHeader')
        }
        if (this.type === 3) {
          const header = this.$t('sectionMessages.sectionSendSelectedHeader') + this.$store.state.selectedContact.length.toString()
          return header
        }

        return ''
      },
      hasSelectedVolunteers () {
        return this.$store.state.selectedContact.length > 0
      }
    },
    mounted () {
      this.getMessages()
      this.$bus.$on('reload-section-messages', this.removeMessage)
    },
    beforeDestroy () {
      this.$bus.$off('reload-section-messages')
    },
    methods: {
      getMessages () {
        var self = this
        self.messagesLoading = true
        var url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.getmessagesbysectionid[0]}${this.$route.params.id}`
        this.$http.get(url).then(resp => {
          self.messages = _.sortBy(resp.body, function (message) {
            return moment(message.sent)
          }).reverse()
          self.messagesLoading = false
        })
      },
      showSendMessage (type) {
        this.sendSuccess = false
        this.sendMessageShown = true
        this.type = type
      },
      cancelSendMessage () {
        this.sendMessageShown = false
        this.subject = ''
        this.content = ''
        this.type = 0
      },
      sendMessage () {
        this.sendSuccess = false
        var url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.sendgroupmessage[0]}`
        if (this.type === 1) {
          url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.sendtosectiongroupmanagers[0]}`
        } else if (this.type === 3) {
          url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.sendtoselectedcontact[0]}`
        }
        var messageDto = {}
        if (this.type === 3) {
          messageDto.subject = this.subject
          messageDto.content = this.content
          messageDto.sectionId = this.$route.params.id
          messageDto.senderProfileId = this.$store.state.profile.profileId
          messageDto.sendAsEmail = this.sendAsMail
          messageDto.contactList = this.$store.state.selectedContact
        } else {
          messageDto.subject = this.subject
          messageDto.content = this.content
          messageDto.sectionId = this.$route.params.id
          messageDto.senderProfileId = this.$store.state.profile.profileId
          messageDto.sendAsEmail = this.sendAsMail
        }
        this.$http.post(url, messageDto).then((resp) => {
          this.sendMessageShown = false
          this.sendSuccess = true
          this.subject = ''
          this.content = ''
          this.getMessages()
          this.$store.state.selectedContact = []
          this.$store.state.selectedVolunteers = []
        })
      },
      removeMessage () {
        this.sendSuccess = false
        this.removeSuccess = true
        this.getMessages()
      }
    }
  }
</script>
