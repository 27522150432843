<template>
  <div class="spr-collapsible">
    <div @click="collapsed = !collapsed">
      <span class="brand-red cap-label head-container">
        {{ heading }}
        <font-awesome-icon v-if="collapsed" icon="chevron-down" />
        <font-awesome-icon v-else icon="chevron-up" />
      </span>
    </div>
    <div v-if="!collapsed">
      <slot name="body">
        Remember to insert element to 'body' slot!
      </slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SprCollabsible',
    props: {
      heading: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {
        collapsed: true
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";
  .spr-collapsible {
    .head-container {
      cursor: pointer;
    }
  }
</style>
