<template>
  <div class="container comp-front-logged">
    <slot name="adhandler" />
    <!-- portrait -->
    <div class="row">
      <div class="wrapper mx-auto my-5">
        <sprportrait
          :style="portraitStyle"
          :completion="completionPercentage"
          class="portrait-size"
        />
      </div>
    </div>
    <!-- welcome header -->
    <div class="row text-center my-2">
      <div class="col col-12">
        <span role="heading" aria-level="1" class="h1">{{ $t("frontPageRegistered.welcome") }}
          {{ this.$store.state.profile.firstName }}!</span>
      </div>
    </div>
    <!-- completion hint -->
    <div v-show="completionPercentage < 100.0" class="row text-center mb-5">
      <div class="col col-12 my-4">
        <sprbutton
          type="primary"
          :size="1"
          :title="$t('frontPageRegistered.updateProfile')"
          class="spr-redborder button-width"
          click-event="update-clicked"
          @update-clicked="updateButton"
        />
      </div>
    </div>
    <!-- My section -->
    <div class="row">
      <div class="col-12">
        <span role="heading" aria-level="3" class="h4 brand-black">
          {{ $t("frontPageRegistered.mySection") }}
        </span>
      </div>
    </div>
    <div class="row feature">
      <p v-if="!sectionLoading && !inAnySection" class="col-12">
        {{ $t("frontPageRegistered.notInAnySection") }}
      </p>
      <div v-if="sectionLoading" class="brand-red centerloading">
        <loading />
      </div>
      <div v-if="!sectionLoading && inAnySection" class="col-8">
        <h3>{{ mySection.name }}</h3>
      </div>
      <div v-if="!sectionLoading && inAnySection" class="col-4">
        <p>{{ mySection.districtName }}</p>
        <div>{{ $t("userAdmin.sectionLanguage") }} {{ sectionLanguage }}</div>
      </div>

      <div v-if="!sectionLoading && inAnySection" class="col-12 text-center">
        <sprbutton
          type="primary"
          :size="1"
          :title="$t('frontPageRegistered.viewSection')"
          class="spr-redborder button-width"
          click-event="click"
          @click="viewSection"
        />
      </div>
    </div>
    <!-- messages sections -->
    <div class="row">
      <div class="col-12">
        <span role="heading" aria-level="3" class="h4 brand-black">
          {{ $t("frontPageRegistered.messages") }}
          <span v-if="newNumber">{{ newNumber }}</span>
        </span>
      </div>
    </div>
    <div class="row feature messages">
      <div class="col col-12 col-md-8">
        <div v-if="messagesLoading" class="brand-red text-center centerloading">
          <loading />
        </div>
        <div v-if="messages.length" :class="{ ismessagesloading: messagesLoading }">
          <sprmessage
            v-for="(message, index) in firstMessages"
            :key="_uid + '_message_' + index"
            :message="message"
            @messageClicked="onMessageClicked"
          />
        </div>
        <div v-if="!messages.length && !messagesLoading" class="nomessages">
          <span>{{ $t("frontPageRegistered.noMessages") }}</span>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="featurebuttonscontainer text-right">
          <div class="featurebuttons">
            <sprbutton
              type="primary"
              :size="1"
              :title="$t('frontPageRegistered.viewAllMessages')"
              class="spr-redborder button-width my-2"
              click-event="viewMessages"
              @viewMessages="viewMessages"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- My groups, suggested groups -->
    <div class="row">
      <div class="col-12">
        <span role="heading" aria-level="3" class="h4 brand-black">
          {{ $t("frontPageRegistered.groups") }}
        </span>
      </div>
    </div>
    <div class="row feature">
      <div class="col-12 col-md-8">
        <div v-if="groupsLoading" class="brand-red centerloading">
          <loading />
        </div>
        <div v-if="myGroups.length && !groupsLoading">
          <grouplist :list="myGroups" />
        </div>
        <div v-if="!myGroups.length && !groupsLoading" class="nomessages">
          <span>{{ $t("frontPageRegistered.noGroups") }}</span>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="featurebuttonscontainer text-right">
          <div class="featurebuttons">
            <sprbutton
              type="primary"
              :size="1"
              :title="$t('frontPageRegistered.viewGroups')"
              class="spr-redborder button-width"
              click-event="viewGroups"
              @viewGroups="viewGroups"
            />
            <sprbutton
              type="primary"
              :size="1"
              :title="$t('frontPageRegistered.addGroup')"
              class="spr-redborder button-width"
              click-event="addGroup"
              @addGroup="addGroup"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Events -->
    <div class="row feature justify-content-center">
      <div class="col-12 event-title">
        <span role="heading" aria-level="3" class="h4">
          {{ $t("frontPageRegistered.events") }}
        </span>
      </div>
      <div class="col-12 event-filter">
        <a
          href="javascript:void(0)"
          :class="{ selected: selectedEventView == eventView.UPCOMING }"
          @click="onEventsUpcomingClick"
        >{{ $t("frontPageRegistered.eventsUpcoming") }}</a>
        <a
          href="javascript:void(0)"
          :class="{ selected: selectedEventView == eventView.RECOMMENDED }"
          @click="onEventsRecommendedClick"
        >{{ $t("frontPageRegistered.eventsRecommended") }}</a>
        <a
          href="javascript:void(0)"
          :class="{ selected: selectedEventView == eventView.POPULAR }"
          @click="onEventsPopularClick"
        >{{ $t("frontPageRegistered.eventsPopular") }}</a>
        <a
          href="javascript:void(0)"
          :class="{ selected: selectedEventView == eventView.VOLUNTEERTASKS }"
          @click="onEventsVolunteerTasksClick"
        >{{ $t("frontPageRegistered.eventsVolunteerTasks") }}</a>
        <a
          href="javascript:void(0)"
          :class="{ selected: selectedEventView == eventView.ORGANISER }"
          @click="onEventsOrganiserClick"
        >{{ $t("frontPageRegistered.eventsOrganiser") }}</a>
        <a
          href="javascript:void(0)"
          :class="{ selected: selectedEventView == eventView.PARTICIPATING }"
          @click="onEventsParticipatingClick"
        >{{ $t("frontPageRegistered.eventsParticipating") }}</a>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-12 text-center">
        <sprbutton
          type="primary"
          :size="1"
          :title="$t('frontPageRegistered.search')"
          class="spr-redborder button-width mb-2"
          click-event="viewEventsWithAttribute"
          @viewEventsWithAttribute="onViewAllEventsWithAttribute"
        />
      </div>
    </div>
    <div
      v-show="selectedEventView == eventView.ORGANISER || selectedEventView == eventView.PARTICIPATING"
      class="row justify-content-center"
    >
      <div class="col-12 event-filter">
        <a
          href="javascript:void(0)"
          :class="{ selected: includeEventsFromPast == true }"
          @click="includeEventsFromPastClick"
        >{{ $t("frontPageRegistered.eventsInPast") }}</a>
        <a
          href="javascript:void(0)"
          :class="{ selected: includeEventsFromPast == false }"
          @click="excludeEventsFromPastClick"
        >{{ $t("frontPageRegistered.eventsUpcoming") }}</a>
      </div>
    </div>
    <div class="row event-list">
      <div v-if="eventsLoading" class="brand-red text-center col-12 centerloading">
        <loading />
      </div>
      <div
        v-for="sprevent in eventsToShow"
        :key="sprevent.id"
        class="event col-sm-12 col-md-6 col-lg-4"
      >
        <eventtile :sprevent="sprevent" />
      </div>
    </div>
    <div v-if="!hasEvents && !eventsLoading" class="row no-events text-center">
      <div class="col-12">
        <span>{{ $t("frontPageRegistered.noEventsFound") }}</span>
      </div>
    </div>
    <div v-if="moreEventsToShow && !eventsLoading" class="row more-events">
      <div class="col-12 text-center">
        <sprbutton
          type="primary"
          :size="4"
          :title="$t('frontPageRegistered.showMoreEvents')"
          class="show-more"
          click-event="showMore"
          @showMore="onShowMoreEventsClick"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <sprbutton
          v-if="jwtToken.ActionGroupMembership || hasRole('Admin') || hasRole('Employee')"
          type="primary"
          :size="1"
          :title="$t('frontPageRegistered.addEvent')"
          class="spr-redborder button-width mb-2"
          click-event="addEvent"
          @addEvent="onAddEvent"
        />
        <sprbutton
          type="primary"
          :size="1"
          :title="$t('frontPageRegistered.allEvents')"
          class="spr-redborder button-width mb-2"
          click-event="viewEvents"
          @viewEvents="onViewAllEvents"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import sprportrait from './SPRPortrait.vue'
  import sprbutton from './SPRButton.vue'
  import sprmessage from './messages/SPRMessage.vue'
  import grouplist from './groups/GroupList.vue'
  import eventtile from './events/EventTile.vue'
  import messages from '../mixins/messages.js'
  import events from '../mixins/event.js'
  import loading from './SPRLoading.vue'
  import anonPortrait from '../assets/img/anon-portrait.png'

  export default {
    name: 'FrontRegistered',
    pageName: 'front-page-logged-in',
    components: {
      sprportrait,
      sprbutton,
      sprmessage,
      grouplist,
      eventtile,
      loading
    },
    mixins: [messages, events],
    data () {
      return {
        completionPercentage: 90,
        mySection: Object,
        sectionLoading: false,
        inAnySection: false,
        messages: [],
        messagesLoading: false,
        myGroups: [],
        groupsLoading: false,
        events: [],
        eventsLoading: false,
        eventView: {
          RECOMMENDED: 1,
          POPULAR: 3,
          VOLUNTEERTASKS: 4,
          ORGANISER: 5,
          PARTICIPATING: 6,
          UPCOMING: 7
        },
        selectedEventView: null,
        eventsToShowInitial: 6,
        eventsToShowActual: 6,
        searchtext: '',
        includeEventsFromPast: false,
        refreshMessagesInterval: null
      }
    },
    computed: {
      portraitStyle: function () {
        var port = this.$store.state.profile.portrait
          ? this.$store.state.profile.portrait
          : anonPortrait
        return (
          'background: url(' +
          port +
          '); background-size: cover; background-position: center center;'
        )
      },
      hasEvents () {
        return this.events.length > 0
      },
      moreEventsToShow () {
        return this.eventsToShow.length < this.filteredEvents.length
      },
      firstMessages: function () {
        if (this.messages.length === 0) {
          return []
        }

        if (this.messages.length <= 5) {
          return this.messages
        }

        return this.messages.slice(0, 5)
      },
      newNumber: function () {
        if (this.messages.length > 5) {
          return '(5 ' + this.$t('frontPageRegistered.latestMessages') + ')'
        }

        return null
      },
      filteredEvents () {
        if (this.searchtext.length === 0) return this.events

        var self = this
        const filtered = _.filter(this.events, (evt) => {
          return (
            self
              .eventTranslation(evt)
              .name.toLowerCase()
              .indexOf(self.searchtext.toLowerCase()) > -1 ||
            evt.translations.some((elem) => {
              return elem.city.toLowerCase().indexOf(self.searchtext.toLowerCase()) > -1
            }) ||
            evt.zipCode.indexOf(self.searchtext) > -1
          )
        })
        return filtered
      },
      eventsToShow () {
        return _.take(this.filteredEvents, this.eventsToShowActual)
      },
      sectionLanguage: function () {
        if (this.mySection.language === 0) {
          return this.$t('languages.fi')
        } else if (this.mySection.language === 1) {
          return this.$t('languages.sv')
        } else if (this.mySection.language === 2) {
          return this.$t('userAdmin.bilingual')
        }

        return ''
      }
    },
    created: function () {
      this.messagesLoading = true
      this.selectedEventView = sessionStorage.getItem('selectedEventView')
        ? parseInt(sessionStorage.getItem('selectedEventView'))
        : this.eventView.RECOMMENDED
      this.getMyGroups()
      this.getEvents()
      this.getMySection()
      this.loadUserMessages()

      // Start message fetch loop every 3 mins
      // this.refreshMessagesInterval = setInterval(function () {
      //   this.loadUserMessages()
      // }.bind(this), 180000)

      // temporarily disabled loop
      this.loadUserMessages()
    },
    beforeDestroy: function () {
      clearInterval(this.refreshMessagesInterval)
    },
    methods: {
      onViewAllEventsWithAttribute () {
        this.$router.push({ path: 'events', query: { search: this.searchtext } })
      // this.$router.push('/publicevents')
      },
      loadUserMessages () {
        if (this.$store.state.profile) {
          this.messagesLoading = true
          this.loadMessages().then(() => {
            this.getMessages()
          })
        }
      },
      updateButton () {
        this.$router.push('/profile')
      },
      eventSingup () {
        console.log('event singup clicked')
      },
      organizeButton () {
        this.$router.push('/groups/createaction')
      },
      onMessageClicked (message) {
        this.$router.push(`/messages/${message.id}`)
      },
      viewSection () {
        this.$router.push('/section')
      },
      viewMessages () {
        this.$router.push('/messages')
      },
      viewGroups () {
        this.$router.push('/groups')
      },
      addGroup () {
        this.$router.push('/groups/create')
      },
      getMessages () {
        this.messagesLoading = false
        this.messages = this.$store.state.messages
      },
      getMySection () {
        var self = this
        self.sectionLoading = true
        var url =
          this.$store.state.apiBases.userservice +
          this.$store.state.apiEndpoints.mysection[0]
        this.$http.get(url).then(
          (resp) => {
            self.mySection = resp.body
            self.inAnySection = true
            self.sectionLoading = false
          },
          (resp) => {
            self.sectionLoading = false
          }
        )
      },
      getMyGroups () {
        var self = this
        self.groupsLoading = true
        var url = `${
          this.$store.state.apiBases.userservice +
          this.$store.state.apiEndpoints.groupmygroups[0]
        }`
        this.$http.get(url).then((resp) => {
          self.myGroups = _.filter(resp.body, (g) => {
            return g.isActive
          })
          self.groupsLoading = false
        })
      },
      getEvents () {
        var self = this
        self.eventsLoading = true

        let url = this.$store.state.apiBases.eventservice
        sessionStorage.setItem('selectedEventView', this.selectedEventView)

        switch (this.selectedEventView) {
        case this.eventView.RECOMMENDED:
          url += this.$store.state.apiEndpoints.eventrecommended[0]
          break

        case this.eventView.POPULAR:
          url += this.$store.state.apiEndpoints.eventpopular[0]
          break

        case this.eventView.VOLUNTEERTASKS:
          url += this.$store.state.apiEndpoints.eventvolunteertasks[0]
          break

        case this.eventView.ORGANISER:
          url +=
            this.$store.state.apiEndpoints.eventorganised[0] +
            '/' +
            this.includeEventsFromPast
          break

        case this.eventView.PARTICIPATING:
          url += this.$store.state.apiEndpoints.eventparticipated[0] +
            '/' +
            this.includeEventsFromPast
          break

        case this.eventView.UPCOMING:
        default:
          url += this.$store.state.apiEndpoints.eventupcoming[0]
          break
        }

        this.$http.get(url).then(
          (resp) => {
            self.events = resp.body
            self.eventsLoading = false
          },
          (resp) => {
            self.events = null
            self.eventsLoading = false
          }
        )
      },
      onAddEvent () {
        this.$router.push('/event')
      },
      onViewAllEvents () {
        this.$router.push('/events')
      },
      onViewOrganisedEvents () {
        this.$router.push('/events/organised')
      },
      onViewParticipatedEvents () {
        this.$router.push('/events/participated')
      },
      onEventsUpcomingClick () {
        this.selectedEventView = this.eventView.UPCOMING
        this.eventsToShowActual = this.eventsToShowInitial
        this.getEvents()
      },
      onEventsRecommendedClick () {
        this.selectedEventView = this.eventView.RECOMMENDED
        this.eventsToShowActual = this.eventsToShowInitial
        this.getEvents()
      },
      onEventsPopularClick () {
        this.selectedEventView = this.eventView.POPULAR
        this.eventsToShowActual = this.eventsToShowInitial
        this.getEvents()
      },
      onEventsVolunteerTasksClick () {
        this.selectedEventView = this.eventView.VOLUNTEERTASKS
        this.eventsToShowActual = this.eventsToShowInitial
        this.getEvents()
      },
      onEventsOrganiserClick () {
        this.selectedEventView = this.eventView.ORGANISER
        this.eventsToShowActual = this.eventsToShowInitial
        this.getEvents()
      },
      onEventsParticipatingClick () {
        this.selectedEventView = this.eventView.PARTICIPATING
        this.eventsToShowActual = this.eventsToShowInitial
        this.getEvents()
      },
      includeEventsFromPastClick () {
        this.includeEventsFromPast = true
        this.eventsToShowActual = this.eventsToShowInitial
        this.getEvents()
      },
      excludeEventsFromPastClick () {
        this.includeEventsFromPast = false
        this.eventsToShowActual = this.eventsToShowInitial
        this.getEvents()
      },
      onSearchChanged (text) {
        this.searchtext = text
      },
      onShowMoreEventsClick () {
        this.eventsToShowActual += this.eventsToShowInitial
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.comp-front-logged {
  min-height: calc(100vh - 200px);

  .button-width {
    width: 100%;
    max-width: 300px;
    direction: inline-block;
  }

  .portrait-size {
    width: 100px;
    height: 100px;
  }

  .wrapper {
    padding: 1em;
    overflow: hidden;
  }

  .feature {
    background-color: $brand-white;
    padding: 15px 0;
    margin-bottom: 30px;
    position: relative;
  }

  .featurebuttonscontainer {
    display: flex;
    height: 100%;
    .featurebuttons {
      margin: auto;
      text-align: center;
    }

    .spr-button {
      margin-bottom: 10px;
    }
  }

  .event-title {
    text-align: center;
    color: $brand-red;
  }

  .event-filter {
    text-align: center;

    a {
      color: $brand-grey8;
      cursor: pointer;
      padding: 10px 5px;
      display: inline-block;

      &.selected {
        color: $brand-red;
        font-weight: bold;
      }
    }
  }

  .event-search {
    margin-bottom: 30px;
  }

  .event-list {
    position: relative;
    min-height: 50px;
  }

  .event-actions {
    margin-bottom: 15px;
    .col-sm-12 {
      margin-bottom: 10px;
    }

    .col-sm-12:last-child {
      margin-bottom: 0;
    }
  }

  .event-list {
    .event {
      background-color: $brand-grey1;
    }
  }

  .no-events {
    color: $brand-font;
    margin-bottom: 15px;
  }

  .more-events {
    margin-bottom: 30px;

    .show-more {
      background-color: $brand-red;
      color: $brand-white;
    }
  }

  .messages {
    .ismessagesloading {
      opacity: 0.4;
    }
  }

  .centerloading {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;

    span {
      margin: auto;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }
  }
}
</style>
