<template>
  <div class="row volunteersummarystandard" :class="isInappropriateCss">
    <div class="col-md-6 mb-3 mb-md-0 maincolumn">
      <div class="topsection">
        <div class="controls">
          <div v-if="!isFirst" class="prev">
            <a href="javascript:void(0)" @click="prev"><font-awesome-icon icon="chevron-left" /></a>
          </div>
          <div v-if="!isLast" class="next">
            <a href="javascript:void(0)" @click="next"><font-awesome-icon icon="chevron-right" /></a>
          </div>
        </div>
      </div>
      <volunteercardstandard
        v-model="volunteer"
        :group="group"
        :show-matches="true"
        :show-actions="false"
        :matches="matchData"
        @refresh="onRefresh"
      />
    </div>
    <div class="col-md-6 sidecolumn">
      <div class="row">
        <div class="col controls">
          <div class="back">
            <a href="javascript:void(0)" @click="close">{{ $t('customerSearch.close') }}</a>
          </div>
        </div>
      </div>
      <customermatchstandardconfirm
        :customer="customer"
        :volunteer="volunteer"
        :group="group"
        :is-inappropriate="isInappropriate"
        :score="score"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import customermatchstandardconfirm from './CustomerMatchStandardConfirm.vue'
  import volunteercardstandard from './VolunteerCardStandard.vue'
  import datehelper from '../../mixins/dates.js'
  import locationhelper from '../../mixins/locationutils.js'
  import googlemaps from '../../mixins/googlemaps.js'
  import friendMatch from '../../mixins/friendMatch'
  import bitmaskhelper from '@/helpers/bitmaskhelper'
  import addresshelper from '@/helpers/addresshelper'
  import { LanguageDto, UserProfileDto, FriendCustomerStandardDto, FriendManagementDto, PreferredGenders, Gender } from '@/types/index'
  import { TravelData, MatchData, YesNoWarning } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    unsub: undefined | Function,
    travelData: TravelData
  }

  export default Vue.extend({
    name: 'VolunteerSummaryStandard',
    components: { customermatchstandardconfirm, volunteercardstandard },
    mixins: [datehelper, locationhelper, googlemaps, friendMatch],
    props: {
      volunteer: {
        type: Object as () => UserProfileDto,
        required: true
      },
      isFirst: Boolean,
      isLast: Boolean,
      customer: {
        type: Object as () => FriendCustomerStandardDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      },
      isInappropriate: {
        type: Boolean,
        default: false
      },
      score: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        travelData: {
          travelDistanceTime: '',
          isMatch: false,
          isRealDistance: false
        },
        unsub: undefined
      }
    },
    computed: {
      isInappropriateCss (): string {
        return this.isInappropriate ? 'inappropriate' : ''
      },
      availabilityTimesMatch (): boolean {
        return friendMatch.methods.matchAvailabilityTimes(this.volunteer.friendInfo.standardProfile.availabilityTimes, this.customer.availabilityTimes)
      },
      availabilityFrequencyMatch (): boolean {
        return friendMatch.methods.matchAvailabilityFrequency(this.volunteer.friendInfo.standardProfile.availabilityFrequence, this.customer.availabilityFrequency)
      },
      genderPreferenceMatch (): YesNoWarning {
        if (this.customer.preferredGenders === Number(PreferredGenders.Male | PreferredGenders.Female)) { // male or female
          if (this.volunteer.friendInfo.gender === Gender.Other) { // other or undefined
            return YesNoWarning.Warning // 'WARNING'
          }
        }
        return YesNoWarning.Yes // 'SUCCESS' // back end calculates it, always return true
      },
      ageMatch (): boolean {
        return friendMatch.methods.ageMatchFriend(this.customer.birthdate, this.volunteer.friendInfo.standardProfile.preferredAges, this.volunteer.age, this.customer.preferredAges)
      },
      interestMatch (): boolean {
        return this.interestCount > 0
      },
      interestCount (): number {
        return _.intersection(
          bitmaskhelper.bitmaskToArray(this.volunteer.friendInfo.standardProfile.interests),
          bitmaskhelper.bitmaskToArray(this.customer.interests)).length
      },
      languageMatch (): boolean {
        // Combine both spoken languages and native language and check for any matches
        var volunteerLanguages = this.volunteer.spokenLanguages

        // if volunteerLanguages doesn't include the native language yet, add it:
        if (this.volunteer.nativeLanguage !== 'none' && !_.includes(volunteerLanguages, this.volunteer.nativeLanguage)) {
          volunteerLanguages.push(this.volunteer.nativeLanguage)
        }

        var customerLanguages = this.customer.customerLanguages.map((x: LanguageDto) => x.isoCode)

        // if customerLanguages doesn't include the native language yet, add it:
        if (this.customer.nativeLanguage !== 'none' && !_.includes(customerLanguages, this.customer.nativeLanguage)) {
          customerLanguages.push(this.customer.nativeLanguage)
        }

        // Now see if any of the languages in customerLanguages exists in the vulunteerLanguages.
        return volunteerLanguages.some(language => customerLanguages.includes(language))
      },
      volunteerAddress (): string {
        if (this.volunteer.friendInfo.standardProfile.hasAlternativeAddress) {
          return addresshelper.formatAddress(this.volunteer.friendInfo.standardProfile.alternativeStreet, this.volunteer.friendInfo.standardProfile.alternativeZipCode, this.volunteer.friendInfo.standardProfile.alternativeCity)
        } else {
          return addresshelper.formatAddress(this.volunteer.streetAddress, this.volunteer.zipCode, this.volunteer.city)
        }
      },
      customerAddress (): string {
        return addresshelper.formatAddress(this.customer.streetAddress, this.customer.zipCode, this.customer.city)
      },
      volunteerTransportation (): number | undefined {
        var self = this
        var fastest = [1, 2, 4, 3]
        return _.find(fastest, (item) => {
          return _.includes(self.volunteer.transportation, item)
        })
      },
      matchData (): MatchData {
        return {
          travelData: this.travelData,
          availabilityTimesMatch: this.availabilityTimesMatch,
          availabilityFrequencyMatch: this.availabilityFrequencyMatch,
          genderMatch: this.genderPreferenceMatch,
          ageMatch: this.ageMatch,
          languageMatch: this.languageMatch,
          interestMatch: this.interestMatch
        } as MatchData
      }
    },
    watch: {
      volunteer () {
        // re-initialize travel data
        this.travelData = {
          travelDistanceTime: this.$t('customerSearch.calculatingDistance').toString(),
          isMatch: false,
          isRealDistance: false
        }
        this.getTravelData()
      }
    },
    mounted () {
      this.getTravelData()

      this.unsub = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
        case 'setLanguage':
          this.getTravelData()
          break
        }
      })
    },
    beforeDestroy () {
      if (this.unsub) {
        this.unsub()
      }
    },
    methods: {
      prev (): void {
        this.$emit('prev')
      },
      next (): void {
        this.$emit('next')
      },
      close (): void {
        this.$emit('close')
      },
      getTravelData (): void {
        var self = this
        // @ts-ignore (Mixins not found)
        this.getTravelDistanceTime(this.volunteerAddress, this.customerAddress, this.volunteerTransportation).then(resp => {
          this.travelData = {
            travelDistanceTime: `${self.$t('customerSearch.travelToCustomer')}: ${resp.text}`,
            isMatch: resp.minutes <= parseInt(self.volunteer.travelTime),
            isRealDistance: resp.isRealDistance
          }
        }, () => {
          this.travelData = {
            travelDistanceTime: self.$t('customerSearch.distanceCalculationFailed').toString(),
            isMatch: false,
            isRealDistance: false
          }
        })
      },
      onRefresh (): void {
        window.location.reload()
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteersummarystandard {
    height: 100%;
    .maincolumn {
      background-color: $brand-white;
      border-left: solid 5px $volunteer-color-strong;
      padding-bottom: 15px;

      @media (min-width: $media-md) {
        border-right: solid 1px $brand-grey3;
      }

      .topsection {
        margin-top: 30px;
        .controls {
          a {
            color:$brand-red;
            cursor: pointer;
          }
          .prev {
            font-size:22px;
            position: absolute;
            top: 15px;
            left: 15px;
          }
          .next {
            font-size:22px;
            position: absolute;
            top: 15px;
            right: 15px;
          }
        }
      }
    }

    .sidecolumn {
      background-color: $brand-white;
      .controls {
        a {
          color:$brand-red;
          cursor: pointer;
        }
        .back {
          position: absolute;
          right:20px;
          top: 15px;
        }
      }
    }

    &.inappropriate {
      .maincolumn {
        color: $brand-grey8;
        background-color: $brand-grey3;

        .icon, .match {
          svg {
            color: $brand-grey6;
          }
        }
        .friendicon {
          background-color: $brand-grey6;
        }
      }
    }
  }
</style>
