<template>
  <div>
    <div class="search-criteria-title">
      {{ title }}
    </div>
    <div class="search-criteria">
      <div class="search-item">
        <div class="cap-label">
          {{ $t('userAdmin.groupName') }}
        </div>
        <div class="form-group">
          <div :class="{ 'spr-searchbox': true, 'spr-roundbox-1': true }">
            <input v-model="searchWord" class="form-control" tabindex="0">
          </div>
        </div>
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('groups.sCGroupTypes') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.groupTypes"
          :items="groupTypes"
          @change="onGroupTypesChanged"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('groups.searchLanguages') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.languages"
          :items="grouplanguages"
          @change="onLanguageChanged"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('groups.sCCategories') }}
        </div>
        <multiselectinput
          v-model="searchCriteria.categories"
          :items="categories"
          @change="onCategoriesChanged"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('groups.sCIsAllLocations') }}
        </div>
        <singleselect
          v-model="searchCriteria.isAllLocations"
          :items="isAllLocations"
          @change="onIsAllLocationsChanged"
        />
      </div>
      <div class="search-item">
        <div class="cap-label">
          {{ $t('groups.sCGroupOrganizers') }}
        </div>
        <grouporganizer
          v-validate="'hasOptionalGroupOrganizer'"
          name="groups.sCGroupOrganizers"
          :value="groupOrganizer"
          :required="false"
          :is-valid="!errors.has('groups.sCGroupOrganizers')"
          @input="onGroupOrganizerChanged"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import grouporganizer from '../SPRGroupOrganizersDropdown.vue'
  import singleselect from '../common/Dropdown/SingleSelectDropdown.vue'
  import multiselectinput from '../common/Dropdown/MultiSelectDropdown.vue'
  import { DropdownSelectItem } from '../../types/custom'
  import cache from '../../mixins/cache.js'
  import enumhelper from '../../mixins/enums.js'
  import inpututils from '../../mixins/inpututils'

  export default Vue.extend({
    name: 'GroupSearchCriteria',
    components: { multiselectinput, singleselect, grouporganizer },
    mixins: [cache, enumhelper, inpututils],
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data: () => {
      return {
        disabled: false,
        searchCriteria: {
          searchWord: '' as string,
          categories: [],
          groupOrganizers: [],
          groupTypes: [],
          languages: [],
          isActive: true,
          isAllLocations: { id: 0, text: '-' }
      } as Record<string, any>,
        isSearching: false as boolean,
        searchTimeout: undefined as unknown as ReturnType<typeof setTimeout>,
        searchWaitIntervalMs: 600 as number,
        categories: [],
        grouplanguages: [],
        groupTypes: [] as unknown,
        groupOrganizers: [] as DropdownSelectItem[] | undefined,
        searchWord: '' as string,
        groupOrganizer: {
            groupOrganizerType: -1,
            organizerId: null
          } as unknown,
        isAllLocations: undefined as boolean | undefined,
        sectionId: 0 as number // this value cannot be used and will be overridden by the backend
      }
    },
    watch: {
      searchWord (value) {
        this.delayedModify('searchWord', value)
      }
    },
    mounted () {
      const lang = this.$store.state.customerLanguages
      this.grouplanguages = this.$store.state.groupLanguages.filter((l) => l.id !== 0).map((v) => {
        return { id: v.id, text: this.$t('actionLanguage.' + lang.find((l) => l.displayName.toUpperCase() === v.text.toUpperCase()).isoCode) }
      })
      this.isAllLocations = this.$store.state.optionalBooleanValues.map((v) => { return { id: v.id, text: this.$t('boolean.' + v.text) } })
      this.categories = this.$store.state.eventCategories.map((v) => { return { id: v.id, text: this.$t('eventCategory.' + v.text) } })
      this.groupTypes = [{ id: 1, text: this.$t('groups.actionGroupsTitle') }, { id: 2, text: this.$t('groups.standardGroupsTitle') }]
    },
    methods: {
      onLanguageChanged (value) {
        this.modifySearchCriteria('languages', value)
      },
      onGroupTypesChanged (value) {
        this.modifySearchCriteria('groupTypes', value)
      },
      onCategoriesChanged (value) {
        this.modifySearchCriteria('categories', value)
      },
      onIsAllLocationsChanged (value) {
        this.modifySearchCriteria('isAllLocations', value)
      },
      delayedModify (criterion, value) {
        clearTimeout(this.searchTimeout as ReturnType<typeof setTimeout>)
        this.searchTimeout = setTimeout(() => {
          this.modifySearchCriteria(criterion, value)
        }, this.searchWaitIntervalMs)
      },
      onGroupOrganizerChanged (value): void {
        if (!value || value.groupOrganizerType === -1) {
          this.modifySearchCriteria('groupOrganizers', undefined)
        } else { this.modifySearchCriteria('groupOrganizers', [Object.freeze(value)]) }
      },
      convertBoolean (value) {
        return value.id === 0 ? undefined : value.id < 2
      },
      modifySearchCriteria (criterion, value) {
        const groupOrganizers = criterion === 'groupOrganizers' ? value : undefined

        const searchCriteria = {
          searchWord: this.searchWord,
          categories: this.searchCriteria.categories.map(v => v.id),
          groupOrganizers: groupOrganizers,
          groupTypes: this.searchCriteria.groupTypes.map(v => v.id),
          languages: this.searchCriteria.languages.map(v => v.id),
          isActive: true,
          isAllLocations: this.convertBoolean(this.searchCriteria.isAllLocations)
        }
        this.$emit('change', searchCriteria)
      }
    }
  })
</script>
<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  .spr-searchbox {
    background-color:$brand-white;
    position: relative;
    border: solid 2px $brand-grey5;

    input
    {
      margin-right: 30px;
      border-radius: 2rem;
      &:focus-visible {
        color: #495057;
        border: solid 2px $brand-grey5;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $brand-grey3;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

    }
    svg {
      position: absolute;
      right: 15px;
      top: 10px;
    }
   }
  .form-group {
    .spr-roundbox-1 {
      border: none;
      select.form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background-image: url('../../assets/img/caret.png');
        background-position: 95% center;
        background-repeat: no-repeat;
        outline: none;
        border: solid 2px $brand-grey5;
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
        }
      }
    }
  }

  .search-item{
   flex: 1 0 auto;
   max-width: 100%;

 }

</style>
