<template>
  <div v-if="volunteer" class="customernetmatchvolunteer">
    <div class="row content">
      <div class="col-lg-4 col-xl-3 mb-3 mb-lg-0 customer-area">
        <CustomerCardNet
          v-model="customer"
          :group="group"
          :show-actions="false"
        />
      </div>

      <div class="col-lg-8 col-xl-9 volunteer-area">
        <div class="row d-lg-none">
          <h4>{{ $t('customerSearch.customerMatchDetails') }}</h4>
        </div>
        <div class="row ml-lg-1 volunteer-summary">
          <div v-if="hasFriendship" class="col">
            <CustomerMatchSummaryNet
              :group="group"
              :volunteer="volunteer"
              :customer="customer"
              @close="onClose"
              @cancelpending="onCancelPending"
              @activate="onActivate"
              @terminate="onTerminate"
            />
          </div>
          <div v-else class="volunteer-not-found">
            <font-awesome-icon icon="exclamation-triangle" />{{ $t('customerSearch.matchNoLongerExists') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import CustomerCardNet from './CustomerCardNet.vue'
  import CustomerMatchSummaryNet from './CustomerMatchSummaryNet.vue'
  import { FriendManagementDto, FriendCustomerNetDto, FriendProfileDto, FriendshipStandardDto, FriendshipNetDto, FriendshipStandardCommandCriteria, FriendshipNetCommandCriteria } from '@/types/index'

  export default Vue.extend({
    name: 'CustomerNetMatchVolunteer',
    components: { CustomerCardNet, CustomerMatchSummaryNet },
    props: {
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      },
      customer: {
        type: Object as () => FriendCustomerNetDto,
        required: true
      },
      volunteer: {
        type: Object as () => FriendProfileDto,
        required: true
      }
    },
    data () {
      return {
        score: 0,
        isLoading: false,
        isSending: false
      }
    },
    computed: {
      hasFriendship (): boolean {
        return this.customer.friendships != null && this.customer.friendships.length > 0
      }
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      onClose () {
        // go back to the matches of the customer
        this.$router.push(`/friend/customerpreview/${this.group.id}/${this.customer.id}`)
      },
      onCancelPending (friendship: FriendshipNetDto) {
        var data: FriendshipNetCommandCriteria = this.getNetFriendshipCommandCriteria(friendship)
        var url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendshipcancelnet[0]}`
        this.$http.post(url, data).then(resp => {
          this.$emit('oncancelpending')
        })
      },
      onActivate (friendship: FriendshipNetDto) {
        var self = this
        var data: FriendshipNetCommandCriteria = this.getNetFriendshipCommandCriteria(friendship)
        var url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendshipactivatenet[0]}`
        this.$http.post(url, data).then(resp => {
          this.$emit('onactivate')
        })
      },
      onTerminate (friendship: FriendshipNetDto) {
        var data: FriendshipNetCommandCriteria = this.getNetFriendshipCommandCriteria(friendship)
        var url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendshipterminatenet[0]}`
        this.$http.post(url, data).then(resp => {
          this.$emit('onterminate')
        })
      },
      getNetFriendshipCommandCriteria (friendship: FriendshipNetDto) {
        return {
          groupId: this.group?.id,
          customerProfileNetId: friendship.customerProfileNetId,
          volunteerNetProfileId: friendship.friendVolunteerProfileNetId
        } as FriendshipNetCommandCriteria
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .customernetmatchvolunteer {
    .header {
      margin-top: 10px;
      .nav-header {
        border-bottom: 0;
      }
      h4 {
        padding-left: 10px;
      }
    }
    .content {

      .customer-area {
        padding-top: 30px;
        background-color: $brand-white;
        border-left: solid 5px $customer-color-strong;
      }

      .volunteer-area {
        h4 {
          padding-left: 10px;
        }
        .volunteer-summary {
          @media (min-width: $media-lg) {
            height: 100%;
          }
        }
        .volunteer-not-found {
          height: 100%;
          text-align: center;
          margin-top: 50px;
          font-weight: bold;
          .fa {
            color: $brand-red;
            margin-right: 5px;
          }
        }
      }
    }
  }
</style>
