<template>
  <div class="comp-profile-editor-login mx-auto text-center container my-5">
    <!-- begin restore here -->
    <div v-if="sent === 0" class="row">
      <div class="col-12 my-3">
        <span>{{ $t('profile.restoreInstructionsStart') }}</span>
      </div>
      <div class="col-12">
        <sprinput v-model="forWhichAccount" v-validate="'required'"
                  :hint="$t('profile.email')" class="editor-field"
                  :is-valid="!errors.has('email')" name="email" data-vv-validate-on="none" autocomplete="email username"
        />
      </div>
      <div class="col-12">
        <sprbutton click-event="restore-event" :title="$t('profile.restore')"
                   :size="1"
                   :bordersize="2" class="d-inline-block" @restore-event="sendRestore"
        />
      </div>
    </div>
    <div v-if="sent === 1" class="row">
      <div class="col-12">
        <loading />
      </div>
      <div class="col-12 my-3">
        <span>{{ $t('profile.restoreInstructionsSending', { email: forWhichAccount }) }}</span>
      </div>
    </div>
    <div v-if="sent === 2" class="row">
      <div class="col-12">
        <font-awesome-icon icon="envelope" size="5x" class="brand-red" />
      </div>
      <div class="col-12 my-3">
        <span>{{ $t('profile.restoreInstructionsMailSent', { email: forWhichAccount }) }}</span>
      </div>
    </div>
    <div v-if="sent === 3" class="row">
      <div class="col-12">
        <font-awesome-icon :icon="['far', 'smile']" size="5x" class="brand-red" />
      </div>
      <div class="col-12 my-3">
        <span>{{ $t('profile.restoreInstructionsRestoreFailed', { email: forWhichAccount }) }}</span>
      </div>
      <div class="col-12 my-3">
        <sprbutton click-event="reset-event" :title="$t('profile.retry')"
                   :size="1"
                   :bordersize="2" class="d-inline-block" @reset-event="sent = 0"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import sprinput from '../SPRSimpleText.vue'
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'

  export default {
    name: 'ProfileEditorForgotPassword',
    components: { sprinput, sprbutton, loading },
    data () {
      return {
        forWhichAccount: '',
        sent: 0,
        password: '',
        confirmPassword: '',
        pending: false,
        validatedFields: ['email', 'password', 'retypePassword']
      }
    },
    computed: {
      validationErrors: function () {
        if (!this.validatedFields) {
          return []
        }

        var arr = []
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['profile.' + err.field, String(err.rule), String(err.msg)])
          }
        })
        return arr
      }
    },
    methods: {
      sendRestore: function () {
        this.sent = 1
        const uri = (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.restore[0]).replace('{email}', this.forWhichAccount)
        this.$http.get(uri).then(s => {
          this.sent = 2
        }, f => {
          this.sent = 3
        })
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
.comp-profile-editor-login {
  .editor-field {
    width: 100%;
    text-align: left;
  }

  .row input {
    box-shadow: none;
    border: 2px solid $brand-grey3;
  }

  .spr-shadow {
    box-shadow: none !important;
    border: 2px solid $brand-grey3;
  }

  .wrap-date input {
    box-shadow: none !important;
    border: 2px solid $brand-grey3 !important;
  }

  .instructions {
    display: block;
    margin-bottom: 30px;
    text-align:left
  }

  .pending {
    color: $brand-red;
  }
}
</style>
