<template>
  <div class="list-group">
    <div v-if="list.length > 0">
      <div v-for="(item, index) in list" :key="item.id" class="user-row">
        <groupview :key="_uid + index + 'list'" :group="item" :detailed="detailed" :has-controls="true" />
      </div>
    </div>
    <!--
    <div v-if="actionGroups.length > 0">
      <div v-for="(item, index) in actionGroups" :key="item.id" class="user-row">
        <groupview :key="_uid + index + 'action'" :group="item" :detailed="detailed" :has-controls="true" />
      </div>
    </div>
    <div v-if="openGroups.length > 0">
      <div v-for="(item, index) in openGroups" :key="item.id" class="user-row">
        <groupview :key="_uid + index + 'open'" :group="item" :detailed="detailed" :has-controls="true" />
      </div>
    </div>
    -->
  </div>
</template>

<script>
  import groupview from './GroupView.vue'

  export default {
    name: 'GroupList',
    components: { groupview },
    props: {
      list: {
        type: Array,
        default: () => []
      },
      detailed: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      actionGroups: function () {
        return this.filterByGroup(this.list, 'groupType', 1)
      },
      openGroups: function () {
        return this.filterByGroup(this.list, 'groupType', 2)
      }
    },
    methods: {
      filterByGroup: function (set, parameter, val) {
        return _.filter(set, function (x) {
          return x[parameter] === val
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .list-group {
    .user-row {
      padding: 5px;

      &:nth-child(odd) {
        background: rgba(0,0,0,0.03);
      }
    }
  }

  @media (max-width: $media-sm-max) {
    .list-group {
      margin: 0 -15px;
    }
  }
</style>