<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .stars {
    text-align:center;
    color:$brand-red;

    .star {
      display:inline-block;

      i.hidden {
        display:none;
      }
    }

    .description {
      font-weight: bold;
      font-size: 0.8em;
      text-transform: uppercase;
    }
  }
</style>
<template>
  <div class="stars">
    <div v-if="numStars === 0">
      <font-awesome-icon :icon="['far', 'times-circle']" />
    </div>
    <div v-else>
      <div class="star">
        <font-awesome-icon :icon="getStarClass(1)" />
      </div>
      <div class="star">
        <font-awesome-icon :icon="getStarClass(2)" />
      </div>
      <div class="star">
        <font-awesome-icon :icon="getStarClass(3)" />
      </div>
    </div>
    <div class="description">
      {{ getDescription(numStars) }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Stars',
    props: {
      score: {
        type: Number,
        default () {
          return 0
        }
      },
      isNet: {
        type: Boolean,
        default () {
          return false
        }
      }
    },
    computed: {
      numStars () {
        var tempScore = ~~this.score
        if (this.isNet) {
          if (tempScore < 30) return 0

          if (tempScore <= 59) return 1

          if (tempScore <= 89) return 2

          return 3
        } else {
          if (tempScore < 0) return 0

          if (tempScore <= 70) return 1

          if (tempScore <= 100) return 2

          return 3
        }
      }
    },
    methods: {
      getStarClass (star) {
        return this.numStars >= star ? ['fas', 'star'] : ['far', 'star']
      },
      getDescription (star) {
        switch (star) {
        case 1:
          return this.$t('customerSearch.scoreSlightlySuitable') // + ' - ' + this.score //uncomment these for testing purposes, if you want to see the actual total score on the screen

        case 2:
          return this.$t('customerSearch.scoreFairlySuitable') //+ ' - ' + this.score

        case 3:
          return this.$t('customerSearch.scoreVerySuitable') //+ ' - ' + this.score

        default:
          return this.$t('customerSearch.scoreNotSuitable') //+ ' - ' + this.score
        }
      }
    }
  }
</script>
