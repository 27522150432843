<template>
  <button :type="type" :class="style" :disabled="disabled" :value="value" :aria-label="ariaLabel" @click="emitClickEvent($event)" :title="tooltip">
    <span><font-awesome-icon v-if="preicon != null && preicon.length > 0" :icon="preicon" /></span>
    {{ title }}
    <span><font-awesome-icon v-if="icon != null && icon.length > 0" :icon="icon" /></span>
  </button>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue'
  export default Vue.extend({
    name: 'SprButton',

    props: {
      contrast: {
        type: Boolean,
        default: false
      },
      clickEvent: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      tooltip: {
        type: String,
        default: ''
      },
      primary: {
        type: Boolean,
        default: false
      },
      size: {
        type: Number,
        default: 1
      },
      bordersize: {
        type: Number,
        default: 0
      },
      ariaLabel: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        default: ''
      },
      preicon: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      type: {
        type: String as PropType<string>,
        default () {
          return 'button'
        }
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      style (): string {
        const baseStyle = `spr-roundbox-${this.size} ${this.bordersize ? 'border-width:' + this.bordersize + 'px' : ''} ${this.primary ? 'primary' : ''} ${this.disabled ? 'disabled' : ''}`   
        
        return this.contrast ? `spr-button-contrast ${baseStyle}` : `spr-button ${baseStyle}`
        
      }
    },
    methods: {
      emitClickEvent (evt): void {
        if (this.clickEvent) {
          this.$emit(this.clickEvent, this.value, evt)
        }
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .spr-button-contrast {
    box-sizing: border-box;
    background-color: $brand-white;
    border: solid 2px $brand-red;
    color: $brand-grey15;
    padding: 12px 42px;
    text-transform: uppercase;
    font-weight: bold;
    transition: none;
    font-family: "Signa-Light", Sans-Serif;
    cursor: pointer;

    &:hover {
      color: $brand-red;
      background-color:$brand-white;
      border: solid 1px $brand-red;
    }


   .fa {
     margin-left: 15px;
     display: inline-block;
   }

    &.primary {
      background-color: $brand-red;
      color: $brand-white;
      &:hover {
        background-color: $brand-white;
        color:$brand-red;
      }
    }

    &.spr-roundbox-4 {
      text-transform: none;
      padding: 12px 15px;
      border-radius: 23px;
      border-width: 2px;
    }

    &.spr-roundbox-5 {
      text-transform: none;
      padding: 6px 12px;
      border-radius: 23px;
      border-width: 2px;
    }
  }

  .spr-button {
    box-sizing: border-box;
    background-color: $brand-white;
    border: solid 2px $brand-red;
    color: $brand-red;
    padding: 12px 42px;
    text-transform: uppercase;
    font-weight: bold;
    transition: none;
    font-family: "Signa-Light", Sans-Serif;
    cursor: pointer;

    &:not(:disabled):hover {
      color: $brand-white;
      background-color:$brand-red;
      border: solid 2px $brand-red;
    }

   .fa {
     margin-left: 15px;
     display: inline-block;
   }

    &.primary {
      background-color: $brand-red;
      color: $brand-white;
      &:not(:disabled):hover {
        background-color: $brand-white;
        color:$brand-red;
      }
    }

    &.spr-roundbox-4 {
      text-transform: none;
      padding: 12px 15px;
      border-radius: 23px;
      border-width: 2px;
    }

    &.spr-roundbox-5 {
      text-transform: none;
      padding: 6px 12px;
      border-radius: 23px;
      border-width: 2px;
    }
  }

  button {
    &.disabled {
      border-color: $brand-grey4;
      color: $brand-grey4;
      cursor: not-allowed;
      outline: none;
    }

  }
</style>
