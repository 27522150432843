<template>
  <div v-if="localOnHoldData" class="onholdform">
    <div v-if="disabled" class="row">
      <div class="col-12">
        <span class="onholdchangeprohibited">{{ $t('volunteerForm.onHoldChangeNotAllowed') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <checkboxinput
          :value="localOnHoldData.onHold"
          :disabled="disabled"
          :title="$t('volunteerForm.onHold')"
          @input="onHoldChecked"
        />
      </div>
    </div>
    <div v-if="localOnHoldData.onHold" class="row">
      <div class="col-12 col-sm-6">
        <forminput
          :value="localOnHoldData.onHoldDetails"
          :disabled="disabled"
          :hint="$t('volunteerForm.onHoldDescription')"
          :is-valid="true"
          @input="onHoldDetailsUpdate"
        />
      </div>
      <div class="col-12 col-sm-6">
        <datepicker
          v-validate="onHoldDeadlineRules"
          :value="localOnHoldData.onHoldDeadline"
          :disabled="disabled"
          data-vv-scope="onHoldForm"
          :title="$t('volunteerForm.onHoldDeadline')"
          name="volunteerForm.onHoldDeadline"
          :is-valid="!errors.has('volunteerForm.onHoldDeadline')"
          :future="5"
          @input="onHoldDeadlineUpdate"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import forminput from '../SPRSimpleText.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import datepicker from '../SPRSimpleDatepicker.vue'
  import moment from 'moment'
  import { OnHoldData } from '../../types/custom'

  // the data object
  export interface TemplateComponentData {
    localOnHoldData: undefined | OnHoldData
  }

  export default Vue.extend({
    name: 'OnHoldForm',
    components: {
      forminput,
      checkboxinput,
      datepicker
    },
    inject: ['$validator'],
    props: {
      value: {
        type: Object as () => OnHoldData,
        required: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        localOnHoldData: undefined as undefined | OnHoldData
      }
    },
    computed: {
      onHoldDeadlineRules (): string {
        if (this.disabled) {
          return ''
        }
        return this.localOnHoldData && this.localOnHoldData.onHold
          ? `required|dateisafter:${moment().add(1, 'day').startOf('day').format('DD.MM.YYYY')}`
          : ''
      }
    },
    watch: {
      value: function (newVal: OnHoldData): void {
        this.localOnHoldData = newVal
      }
    },
    mounted (): void {
      if (this.value) {
        this.localOnHoldData = this.value
      }
    },
    methods: {
      onHoldChecked (newVal): void {
        if (!this.localOnHoldData) {
          return
        }
        this.localOnHoldData.onHold = newVal
        if (this.localOnHoldData?.onHold) {
          // if its checked on, set the default value to 2 years ahead.
          var d = new Date()
          var year = d.getFullYear()
          var month = d.getMonth()
          var day = d.getDate()
          this.localOnHoldData.onHoldDeadline = new Date(year + 2, month, day)
        }
        this.update()
      },
      onHoldDetailsUpdate (newVal): void {
        if (!this.localOnHoldData) {
          return
        }
        this.localOnHoldData.onHoldDetails = newVal
        this.update()
      },
      onHoldDeadlineUpdate (newVal): void {
        if (!this.localOnHoldData) {
          return
        }
        this.localOnHoldData.onHoldDeadline = newVal
        this.update()
      },
      update (): void {
        if (this.localOnHoldData?.onHold) {
          this.$validator.validate('onHoldForm.*').then(result => {
            if (result) {
              this.$emit('input', this.localOnHoldData)
            }
          })
        } else {
          this.$emit('input', this.localOnHoldData)
        }
      }
    }
  })
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.onholdform {
  background-color: $brand-white;

  .onholdchangeprohibited {
    color: $brand-red;
    font-size: 12px;
  }

  .subheader {
    color: $brand-red;
    text-transform: uppercase;
    font-family: $font-bold;
    margin: 0 0 10px 20px;
    display: block;
    font-size: 14px;
  }

  .sectionheader {
    margin: 15px 0;
  }
}
</style>
