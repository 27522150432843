<template>
  <div v-show="validationErrors.length > 0" class="comp-validation-error">
    <div v-show="includeTitle" class="validation-title">
      {{ $t("validation.title") }}:
    </div>
    <span class="fa-stack fa-lg error-icon">
      <font-awesome-icon :icon="['far', 'frown']" class="anne-sized" aria-hidden="true" />
    </span>
    <div v-if="includeTitle">
      <ul class="error-container fill-height">
        <li v-for="(err, index) in validationComputed" :key="index">
          {{ fieldReference(err) }} {{ translationCombined(err) }}
        </li>
      </ul>
    </div>
    <div v-else>
      <span class="error-container fill-height">
        <p v-for="(err, index) in validationComputed" :key="index">{{ fieldReference(err) }} {{ translationCombined(err) }}</p>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import { TranslateResult } from 'vue-i18n'

  export default Vue.extend({
    name: 'ValidationError',
    props: {
      validationErrors: {
        // Converting this to ts, so it seems the error needs to be a string array in the format:
        // [
        //    'field',
        //    'translation rule', (can start with 'error.' or a field name that gets put into 'validation.')
        //    'message' (a number for interpolation of error messages)
        // ]
        type: Array as () => Array<Array<string>>,
        default: () => []
      },
      excludeFieldReference: {
        type: Boolean,
        default: false
      },
      includeTitle: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      validationComputed (): Array<Array<string>> {
        if (this.validationErrors) {
          return this.validationErrors
        }
        return []
      }
    },
    methods: {
      fieldReference (err): string | TranslateResult {
        if (!this.excludeFieldReference) { return this.$t(err[0]) }
        return ''
      },
      translationCombined (err): string | TranslateResult {
        if (err[1] === null) {
          return ''
        }

        if (_.startsWith(err[1], 'error.')) {
          return this.$t(err[1])
        }
        if (_.startsWith(err[2], 'validation.')) {
          return this.$t(err[2])
        }
        const t = this.$t('validation.' + err[1], err[2].match(new RegExp('[0-9]+', 'g')))
        return t
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .validation-title {
    text-align: left;
    margin-left: 30px;
  }
  .comp-validation-error {
    box-sizing: border-box;
    color: $brand-red;
    background: $brand-white;
    border: solid 2px $brand-red;
    display: inline-block;
    position: relative;
    align-content: center;
    flex-flow: row;
    border-radius: 5px;

    max-width: 500px;
    width: 100%;
    padding: 10px 0;
    margin: 0;

    .error-icon {
      position: absolute;
      display: flex;
      top: 0;
      bottom: 0;
      margin: auto;
      margin-left: 20px;
      font-size: 20px;
      align-items: center;
    }

    .error-container {
      display: block;
      text-align: left;
      padding-left: 80px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;

      p {
        display: block;
        margin: 0;
        color: $brand-red;
      }
    }

    .anne-sized {
      font-size: 150%;
    }
  }
</style>
