<template>
  <div class="comp-wizard-content container-fluid join-container">
    <div class="row mx-auto">
      <div class="col-12">
        <span class="h1"
              role="heading"
              aria-level="1"
        >{{ $t('join.paymentHeader') }}</span>
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-12">
        <p>{{ $t('join.paymentDescription') }}</p>
      </div>
    </div>

    <div v-if="!value.address.email" class="row mx-auto">
      <div class="col-12">
        <p>{{ $t('join.emailForReceipt') }}</p>
      </div>
      <div class="col-12">
        <sprinput v-model="protoMail"
                  v-validate="'email'"
                  :hint="$t('wizard.emailField')"
                  class="w-100 mt-2"
                  :is-valid="!errors.has('emailField')"
                  name="emailField"
        />
      </div>
      <div class="col-12">
        <sprbutton :size="1"
                   :title="$t('join.useEmail')"
                   click-event="email"
                   @email="inputEmail"
        />
      </div>
    </div>

    <div class="row mx-auto">
      <div class="col-12">
        <p>{{ $t('join.paySelect') }}</p>
      </div>
      <div class="col-12">
        <paymentoptions v-if="paymentResponse"
                        :options-response="paymentResponse"
        />
        <div v-else class="loading-payments">
          <div class="brand-red spinner">
            <loading />
          </div>
          <div class="brand-white">
            {{ $t('join.payLoading') }}
          </div>
        </div>
      </div>
    </div>

    <!-- paper invoice -->
    <div class="row mx-auto mt-3">
      <div class="col-12">
        <p>{{ $t('join.orInvoice') }}</p>
      </div>
      <div class="col-12">
        <sprbutton :size="1"
                   :title="$t('join.payInvoice')"
                   click-event="invoice"
                   icon="envelope"
                   @invoice="invoice"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import paymentoptions from '@/components/payment/PaymentOptions.vue'
  import sprinput from '../SPRInput.vue'
  import loading from '../SPRLoading.vue'

  export default {
    name: 'JoinPayment',
    components: { sprbutton, paymentoptions, sprinput, loading },
    props: {
      value: {
        type: Object,
        required: true
      },
      flow: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {
        transactionId: null,
        paymentResponse: null,
        protoMail: '',
        submitted: false
      }
    },
    computed: {
      validationErrors: function () {
        return []
      },
      checkoutUrl: function () {
        return `${this.$store.state.apiBases.paymentservice + this.$store.state.apiEndpoints.checkout[0]}`
      },
      optionsUrl: function () {
        return `${this.$store.state.apiBases.paymentservice + this.$store.state.apiEndpoints.paymentoptions[0]}`
      },
      cancelUrl: function () {
        return `${this.$store.state.apiBases.paymentservice + this.$store.state.apiEndpoints.paymentcancel[0]}`
      },
      confirmUrl: function () {
        return `${this.$store.state.apiBases.paymentservice + this.$store.state.apiEndpoints.confirminvoice[0]}`
      }
    },
    mounted: function () {
      const self = this
      this.checkout(this.toDto(this.value))

      this.$bus.$on('payment-selected', function (event) {
        self.submitted = true
        event.target.submit()
      })
    },
    beforeDestroy: async function () {
      this.$bus.$off('payment-selected')
      if (!this.submitted && this.transactionId) {
        await this.cancel(this.transactionId)
      }
    },
    methods: {
      inputEmail: function () {
        const self = this
        this.$validator.validateAll().then(function (data) {
          if (data === true) {
            self.value.address.email = self.protoMail
            self.value.marketingEmail = false
            self.checkout(self.toDto(self.value))
          } else {
            window.scrollTo(0, 0)
          }
        })
      },
      invoice: function () {
        const self = this
        var dto = this.toDto(this.value)
        this.httpPromise('post', this.checkoutUrl, dto)
          .then(success => {
            self.transactionId = success.transactionId
            self.confirmInvoice(success.transactionId)
          })
          .catch(failure => { console.log('failed checking out', dto) })
      },
      checkout: function (dto) {
        const self = this
        this.httpPromise('post', this.checkoutUrl, dto)
          .then(success => {
            self.transactionId = success.transactionId
            self.options(success.transactionId)
          })
          .catch(failure => { console.log('failed checking out', dto) })
      },
      options: function (transactionId) {
        const self = this
        this.httpPromise('get', `${this.optionsUrl}${transactionId}/${this.flow}/${this.$store.state.language}`)
          .then(success => {
            self.paymentResponse = success
          })
          .catch(failure => {
            console.log('failed fetching payment options', failure)
          })
      },
      confirmInvoice: function (transactionId) {
        const self = this
        self.submitted = true
        this.httpPromise('post', `${this.confirmUrl}${transactionId}`)
          .then(success => {
            self.paymentResponse = success
            self.$router.push({
              name: 'joinReturn',
              params: {
                status: 'invoice',
                transactionId: transactionId,
                flow: this.flow
              }
            })
          })
          .catch(failure => {
            self.submitted = false
            console.log('failed ordering invoice', failure)
          })
      },
      cancel: async function (transactionId) {
        await this.$http.delete(`${this.cancelUrl}${transactionId}`)
      },
      toDto: function (jso) {
        return {
          email: (jso.address.email && jso.address.email !== '') ? jso.address.email : 'null-mail@dev.redcross.fi',
          locale: jso.personal.nativeLanguage.toUpperCase(), // this is not correct language
          omaId: jso.omaId, // get this for logged in users
          city: jso.address.city,
          country: jso.address.country,
          phone: jso.address.phone,
          street: jso.address.street,
          zipCode: jso.address.zipCode,
          dateofBirth: jso.personal.age,
          firstName: jso.personal.firstName,
          lastName: jso.personal.lastName,
          nativeLanguage: jso.personal.nativeLanguage.toUpperCase(),
          marketingConsentEmail: jso.marketingEmail,
          marketingConsentPhone: jso.marketingSms,
          section: jso.section,
          items: [
            {
              count: 1,
              code: jso.membershipType
            }
          ]
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

  .join-container {
    .row {
      max-width: 600px;
    }
  }

  .loading-payments {
    background: rgba(255, 255, 255, 0.3);
    padding: 30px 0;

    .spinner {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 400px) {
    .join-container {
      padding: 0;

      .col-12 {
          padding: 0;
        }
    }
  }
</style>
