<template>
  <div class="poolview" :class="{'removed' : pool.status === this.$store.state.poolStatus.REMOVED}">
    <div class="row">
      <div class="col-7 col-lg-5">
        <div class="poolname" @click="onPoolClicked(pool.id)">
          {{ pool.name }}
        </div>
        <div class="address">
          {{ getAddress(pool) }}
        </div>
      </div>
      <div class="col-lg-4 d-none d-sm-none d-md-none d-lg-block">
        <div class="description">
          <span v-if="pool.shortDescription">"{{ pool.shortDescription }}"</span>
        </div>
      </div>
      <div class="col-5 col-lg-3">
        <div v-if="actionView === 'remove'" class="removal">
          <sprbutton :size="4"
                     :title="$t('poolPreview.removeMember')"
                     click-event="remove"
                     @remove="onRemove"
          />
        </div>
        <div v-if="actionView === 'join'" class="join">
          <sprbutton :size="4"
                     :title="$t('poolPreview.join')"
                     click-event="join"
                     @join="onJoin"
          />
        </div>
        <div v-if="actionView === 'counts'" class="counts">
          <div>{{ pool.customerCount }} {{ $t('customerSearch.poolCustomers') }}</div>
          <div>{{ pool.volunteerCount }} {{ $t('customerSearch.poolVolunteers') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import locationhelper from '../../mixins/locationutils.js'
  import addresshelper from '@/helpers/addresshelper'
  import { FriendPoolFindStandardDto, FriendPoolFindNetDto } from '@/types/index'

  export default Vue.extend({
    name: 'PoolView',
    components: { sprbutton },
    mixins: [locationhelper],
    props: {
      pool: {
        type: Object as () => FriendPoolFindStandardDto | FriendPoolFindNetDto,
        required: true
      },
      groupid: {
        type: Number,
        default: 0
      },
      actionView: {
        type: String,
        default: 'counts',
        validator: function (value: string) {
          // The value must match one of these strings
          return ['counts', 'remove', 'join'].indexOf(value) !== -1
        }
      }
    },
    methods: {
      getAddress (pool) {
        return addresshelper.formatAddress(pool.address, pool.postalCode, pool.city)
      },
      onPoolClicked (id) {
        const uri = `/friend/pools/${this.groupid}/${id}`
        this.$router.push(uri)
      },
      onJoin () {
        this.$emit('join', this.pool)
      },
      onRemove () {
        this.$emit('remove', this.pool)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .poolview {
    border-left: solid 5px $pools-color-strong;
    background-color:$brand-white;
    margin: 10px 0;
    padding: 20px 15px;

    &.removed {
      border-left: solid 5px $brand-grey7;
      background-color: $brand-grey3;
    }

    .poolname {
      text-transform: uppercase;
      color:$brand-red;
      font-weight: bold;
      cursor: pointer;
    }

    .address {
      font-size: 13px;
    }

    .description {
      color: $brand-red;
      font-style: italic;
      font-size: 13px;
      text-align: center;
    }

    .counts {
      font-size: 13px;
      text-align: right;
    }

    .removal, .join {
      text-align: right;
      button {
        font-size: 13px;
        padding: 5px 10px;
        margin-right: 10px;
      }
    }
  }
</style>
