<template>
  <div :id="'msg-' + note.id" :class="'sprmessage thread-node thread-message' + activeClass">
    <!-- thread OP sender -->
    <div class="msg-sender">
      <div class="sender-img">
        <div class="spr-portrait portrait-size"
             :style="portraitStyle"
        />
      </div>
      <div class="sender-details">
        <div class="sender">
          <span class="sentby cap-label brand-red">{{ note.sender }}</span>
        </div>
        <span class="date">{{ formattedDate(note.sent) }}</span>
      </div>
      <div v-if="canDelete" title="Poista" class="rem-btn" @click="removeNote(note)">
        <span>
          <font-awesome-icon icon="ban" class="brand-red" />
        </span>
      </div>
    </div>

    <!-- thread OP message -->
    <div class="msg-content">
      <div class="head-cont">
        <div class="subject">
          <div v-if="!note.deleted">
            {{ note.subject }}
          </div>
        </div>
        <div v-if="!note.deleted">
          <p v-linkified:options="{ nl2br: true }">
            {{ note.content }}
          </p>
          <p>
            <a href="reply" @click.prevent="replyTo(true)">{{ $t('groups.reply') }}</a>
          </p>
        </div>
        <div v-else class="pt-3">
          <p>{{ $t('groups.messageRemoved') }}</p>
        </div>
      </div>
    </div>

    <!-- replies to -->
    <div v-if="isCollapsed" class="show-all-messages caret-down" @click="isCollapsed = false">
      <span>{{ $t('groups.showAll').replace('{number}', totalReplies - 1) }}</span>
    </div>
    <div v-if="!isCollapsed && totalReplies > 1" class="show-all-messages caret-up" @click="isCollapsed = true">
      <span>{{ $t('groups.onlyLatest') }}</span>
    </div>

    <reply v-for="(child, i) in filteredChildren"
           :key="'reply_' + child.id"
           :note="child"
           :focus="focus"
           :can-delete="canDelete"
           :is-last="i == filteredChildren.length - 1"
           @remove="removeNote"
           @reply="replyTo(true)"
    />

    <!-- reply dialog -->
    <div v-if="showReplyUi" class="sendMessage">
      <div class="messagebody">
        <textarea :id="'reply-' + note.id" v-model="replyMessage" rows="3" placeholder="Viestin sisältö" />
      </div>
      <div class="actions">
        <checkboxinput v-if="canSendAsEmail"
                       v-model="asEmail" :title="$t('sectionMessages.sendAsMail')"
        />
        <sprbutton click-event="reply" type="primary" :size="1" :title="$t('groups.reply')" :disabled="replyMessage.length === 0" @reply="replyToThread" />
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import sprbutton from '../SPRButton.vue'
  import reply from './ReplyView.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import messages from '../../mixins/messages.js'
  import anonPortrait from '../../assets/img/anon-portrait.png'

  export default {
    name: 'ThreadView',
    components: { sprbutton, reply, checkboxinput },
    mixins: [messages],
    props: {
      note: {
        type: Object,
        required: true
      },
      children: {
        type: Array,
        default: () => []
      },
      portraits: {
        type: Array,
        default: () => []
      },
      focus: {
        type: Number,
        default: 0
      },
      canDelete: Boolean,
      canSendAsEmail: Boolean
    },
    data () {
      return {
        asEmail: false,
        replyMessage: '',
        collapseSelection: true,
        showReplyUi: false
      }
    },
    computed: {
      activeClass: function () {
        return this.note.id === this.focus ? ' active' : ''
      },
      totalReplies: function () {
        return this.children.length
      },
      portraitUrl: function () {
        const self = this
        const found = _.find(this.portraits, function (x) {
          return self.note.senderId === x.id
        })

        return (found && found.url) ? found.url : anonPortrait
      },
      portraitStyle: function () {
        return 'background: rgba(0, 0, 0, 0) url(' + this.portraitUrl + ') repeat scroll center center / cover;'
      },
      isCollapsed: {
        get: function () {
          return this.collapseSelection && this.totalReplies > 1
        },
        set: function (newValue) {
          this.collapseSelection = newValue
        }
      },
      filteredChildren: function () {
        if (this.isCollapsed) {
          return [this.children[this.children.length - 1]]
        }

        return this.children
      }
    },
    mounted: function () {
      this.handleThreadLoad()
    },
    methods: {
      handleThreadLoad: function () {
        const self = this
        if (this.children) {
          var found = _.some(this.children, function (x) {
            return x.id === self.focus
          })

          if (found) {
            this.collapseSelection = false
          }

          if (found) {
            this.$nextTick(function () {
              const element = document.getElementById('msg-' + self.focus)
              element.scrollIntoView({ behavior: 'smooth' })
            })
          }
        }

        if (this.note.id === this.focus) {
          this.$nextTick(function () {
            const element = document.getElementById('msg-' + self.focus)
            element.scrollIntoView({ behavior: 'smooth' })
          })
        }
      },
      replyTo: function (scroll) {
        this.showReplyUi = !this.showReplyUi
        if (this.showReplyUi) {
          const id = 'reply-' + this.note.id
          this.$nextTick(function () {
            const element = document.getElementById(id)
            element.focus({ preventScroll: true })
            if (scroll) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
          })
        }
      },
      formattedDate (date) {
        return moment.utc(date).local().format('DD.MM.YYYY H:mm')
      },
      removeNote: function (whichNote) {
        this.$emit('remove', whichNote)
      },
      replyToThread: function () {
        this.$bus.$emit('reply-to-thread', {
          asEmail: this.asEmail,
          message: this.replyMessage,
          parentId: this.note.id
        }, this.resetForm)
      },
      resetForm: function () {
        this.replyMessage = ''
        this.asEmail = false
        this.showReplyUi = false
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  @keyframes msg-active-animation {
    0% {background: $brand-grey2;}
    100% {background: unset;}
  }

  .thread-message {
    &.active {
      animation-name: msg-active-animation;
      animation-duration: 5s;
    }
  }

  .thread-node {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }

    &:nth-child(odd) {
      background: unset !important;
    }
  }

  .sprmessage {
    padding: 15px !important;

    .head-cont {
      .subject {
        font-weight: bold;
        margin-bottom: 3px;
      }
    }

    .sprmessage {
      border-left: 5px solid rgba(0,0,0,0.5);
      padding: 0 0 0 20px !important;
      margin-left: 50px;
      background: transparent !important;

      @media (max-width: $media-md-max) {
        margin-left: 5px;
      }

      .msg-content {
        padding-left: 0;
      }
    }

    .msg-sender {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      position: relative;

      .rem-btn {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }

      .spr-portrait {
        height: 40px;
        width: 40px;
        display: inline-block;
        border-radius: 50px;
        margin-right: 13px;
      }

      @media (max-width: $media-md-max) {
        .spr-portrait {
          display: none;
        }
      }
    }

    .msg-content {
      padding-left: 50px;

      .sendMessage {
        textarea {
          min-height: 80px;
        }
      }

      @media (max-width: $media-md-max) {
        padding-left: 0px;
      }
    }

    .sendMessage {
      padding-left: 50px;

      @media (max-width: $media-md-max) {
        padding-left: 0px;
      }

      textarea {
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 10px 20px;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #bdbdbd;
        border-radius: 0;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-content: center;

        * {
            display: block;
          }

        .spr-simple-checkbox {
          padding: 10px 10px 0 0 !important;
        }
      }
    }

    .show-all-messages {
      padding: 10px 15px 10px 15px;
      margin-bottom: 15px;
      margin-left: 50px;
      color: #c00;
      cursor: pointer;
      border: 1px solid rgba(0,0,0,0.1);
      margin-top: 25px;

      span {
        padding-left: 10px;
      }

      @media (max-width: $media-md-max) {
        margin-left: 0;
      }
    }

    .caret-down:before {
      content: "»";
      display: inline-block;
      transform: rotate(90deg);
    }

    .caret-up:before {
      content: "»";
      display: inline-block;
      transform: rotate(270deg);
    }

    .show-all-messages:hover span {
      text-decoration: underline;
    }
  }
</style>
