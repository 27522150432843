<template>
  <div class="event-tile" :title="sprevent.name" @click="onEventClicked">
    <earmark :visible="showIfFull" :text="$t('eventPage.earmarkFull')"></earmark>
    <earmark :visible="showIfClosed" :text="$t('eventPage.earmarkClosed')"></earmark>
    <div class="img" :style="eventimage" />
    <div class="event-content clearfix">
      <div class="times">
        <div v-if="sameDayEvent">
          <span>{{ startDate }}
            <span v-if="!sprevent.isAllDay">
              {{ $t("eventPage.startingAt") }} {{ startTime }}</span>
          </span>
        </div>
        <div v-else>
          <span>{{ startDate }} - {{ endDate }}</span>
        </div>
      </div>
      <div class="name">
        <span class="cap-event-label">{{ translation.name }}</span>
        <div class="type">
          {{ eventtype }}
        </div>
      </div>
      <div class="languages">
        {{ languages }}
      </div>
      <div class="event-tile-footer">
        <div class="location">
          <span v-if="!sprevent.isOnline" class="smalltext">{{ translation.city }}{{ postcode }}</span>
          <span v-if="sprevent.isOnline" class="smalltext online">{{
            $t("eventPage.onlineTag")
          }}</span>
        </div>
        <div class="category">
          <span class="smalltext">{{ eventcategory }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import enumhelper from '../../mixins/enums.js'
  import eventhelper from '../../mixins/event.js'
  import earmark from '../common/Warning/EarMark.vue'
  import moment from 'moment'

  export default {
    name: 'EventTile',
    components: { earmark },
    mixins: [enumhelper, eventhelper],
    props: {
      sprevent: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    computed: {
      showIfFull () {
        if (!this.sprevent) return false
        return this.sprevent.isFull && !this.sprevent.isClosed
      },
      showIfClosed () {
        if (!this.sprevent) return false
        return this.sprevent.isClosed
      },
      languages () {
        return this.getLanguagesMaskTranslation(this.sprevent.languages)
      },
      translation: function () {
        return this.eventTranslation(this.sprevent)
      },
      isRegistrationDeadlinePassed () {
        return (
          this.sprEvent.registrationDeadline &&
          moment.utc(this.sprEvent.registrationDeadline) < moment()
        )
      },
      eventimage () {
        const src =
          this.sprevent.imageThumb && this.sprevent.imageThumb.length
            ? this.sprevent.imageThumb
            : this.$store.state.eventDefault.thumb

        return `background-image: url(${src});`
      },
      eventtype () {
        return this.getEventTypesMaskTranslation(this.sprevent.type)
      },
      eventcategory () {
        return this.getInvolvedEventCategoriesTranslation(this.sprevent.category)
      },
      sameDayEvent () {
        const evS = this.sprevent.startTime
        const evE = this.sprevent.endTime
        return evS.day === evE.day && evS.month === evE.month && evS.year === evE.year
      },
      startDate () {
        const ev = this.sprevent.startTime
        return `${ev.day}.${ev.month}.${ev.year}`
      },
      startTime () {
        const ev = this.sprevent.startTime
        return `${this.formatTime(ev.hour)}:${this.formatTime(ev.minute)}`
      },
      endDate () {
        const ev = this.sprevent.endTime
        return `${ev.day}.${ev.month}.${ev.year}`
      },
      postcode () {
        return this.translation.city &&
          this.translation.city.length > 0 &&
          this.sprevent.zipCode &&
          this.sprevent.zipCode.length > 0
          ? `, ${this.sprevent.zipCode}`
          : this.sprevent.zipCode
      }
    },
    methods: {
      formatTime (time) {
        if (Number(time) > 9) return time

        return `0${time}`
      },
      onEventClicked () {
        this.$router.push(`/event/${this.sprevent.id}`)
      }
    }
  }
</script>
<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.event-tile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  background-color: $brand-white;
  clear: both;
  margin-bottom: 30px;
  min-height: 340px;
  cursor: pointer;

  .img {
    flex: 0 0 auto;
    width: 100%;
    height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .event-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    flex: 1 0 auto;
    padding: 15px 5px 5px 5px;
  }

  .times {
    flex: 1 0 auto;
  }

  .name {
    flex: 1 0 auto;
    margin: 10px;
    min-height: 60px;
    .cap-event-label {
      text-transform: uppercase;
      font-family: $font-bold;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.3px;
      color: $brand-black;
      min-height: 15px;
      color: $brand-red;
    }
    .type {
      font-size: 15px;
      margin-left: -10px;
    }
  }

  .languages {
    flex: 1 0 auto;
    margin-bottom: 5px;
  }

  .event-tile-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    flex: 1 0 auto;
    width: 100%;

    .category {
      flex: 1 0 auto;
      text-align: right;
    }

    .location {
      flex: 1 0 auto;
      .online {
        color: $brand-red;
        display: block;
      }
    }
  }
}
</style>
