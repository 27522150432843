<template>
  <div v-if="localVolunteer" class="volunteerprofilecommon">
    <!-- PERSONAL INFO -->
    <div class="row">
      <div class="col-sm-6 sectionheader">
        <h3>{{ $t('volunteerForm.personalInformation') }}</h3>
      </div>
    </div>

    <!-- user gender -->
    <div class="row">
      <div class="col-sm-6">
        <sprdropdownnumberkey v-model="localVolunteer.gender"
                              :title="$t('volunteerForm.gender')"
                              :items="userGenders"
                              :is-valid="true"
                              @input="update"
        />
      </div>
    </div>

    <!-- healthcare training -->
    <div class="row">
      <div class="col-12">
        <span class="subheader">{{ $t('volunteerForm.healthcareTraining') }}</span>
        <checkboxinput v-model="localVolunteer.hasHealthCareTraining"
                       :title="$t('volunteerForm.hasHealthcareTraining')"
                       :aria-label="$t('volunteerForm.hasHealthcareTraining')"
                       @input="update"
        />
      </div>
    </div>
    <div v-if="localVolunteer.hasHealthCareTraining" class="row">
      <div class="col-12">
        <forminput v-model="volunteer.healthCareTrainingFreetext"
                   :hint="$t('nonIdentProfileForm.whichHealthCareTraining')"
                   :is-valid="true"
                   @input="update"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import forminput from '../SPRSimpleText.vue'
  import sprdropdownnumberkey from '../SPRDropdownNumberKey.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import enums from '../../mixins/enums.js'
  import scroll from '../../mixins/scroll.js'
  import locationutils from '../../mixins/locationutils.js'
  // import enumhelper from '../../mixins/enums.js'
  import * as _ from 'lodash'
  import { FriendVolunteerDto } from '../../types/index'
  import { SprDropDownNumberValue } from '../../types/custom'

  export default Vue.extend({
    name: 'VolunteerProfileCommon',
    components: {
      forminput,
      sprdropdownnumberkey,
      checkboxinput
    },
    mixins: [enums, scroll, locationutils],
    inject: ['$validator'],
    props: {
      volunteer: {
        type: Object as () => FriendVolunteerDto,
        required: true
      }
    },
    data () {
      return {
        localVolunteer: undefined as undefined | FriendVolunteerDto
      }
    },
    computed: {
      userGenders (): Array<SprDropDownNumberValue> {
        var self = this
        return _.map(this.$store.state.genders, function (val, key) {
          return { value: val.id, text: self.$t('genders.' + val.text) } as SprDropDownNumberValue
        })
      }
    },
    watch: {
      volunteer: function (newVal: FriendVolunteerDto): void {
        this.localVolunteer = newVal
      }
    },
    mounted (): void {
      if (this.volunteer) {
        this.localVolunteer = this.volunteer
      }
    },
    methods: {
      update () {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('update', this.localVolunteer)
          }
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteerprofilecommon {
    background-color:$brand-white;

    .subheader {
      color:$brand-red;
      text-transform: uppercase;
      font-family: $font-bold;
      margin: 0 0 10px 20px;
      display:block;
      font-size: 14px;
    }

    .sectionheader {
      margin: 15px 0;
    }
  }
</style>
