<template>
  <div v-if="localCustomer" class="personalinfo">
    <div class="row">
      <div class="col-12">
        <!-- personal info section -->
        <div class="row headertitle">
          <div class="col">
            <h3>{{ $t('customerForm.netPersonalInformation') }}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <forminput v-model="localCustomer.firstName"
                       v-validate="'required'"
                       :hint="$t('customerForm.firstname')"
                       :is-valid="!errors.has('customerForm.firstname')"
                       name="customerForm.firstname"
                       :required="true"
            />
          </div>
          <div class="col-sm-6">
            <forminput v-model="localCustomer.lastName"
                       v-validate="'required'"
                       :hint="$t('customerForm.lastname')"
                       :is-valid="!errors.has('customerForm.lastname')"
                       name="customerForm.lastname"
                       :required="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-6">
            <forminput v-model="localCustomer.streetAddress"
                       v-validate="'required'"
                       :hint="$t('customerForm.street')"
                       :is-valid="!errors.has('customerForm.streetAddress')"
                       name="customerForm.streetAddress"
                       :required="true"
            />
          </div>
          <div class="col-12 col-sm-4 col-lg-2">
            <forminput v-model="localCustomer.zipCode"
                       v-validate="'required|regex:^[0-9]{5}$'"
                       :hint="$t('customerForm.zipCode')"
                       :is-valid="!errors.has('customerForm.zipCode')"
                       name="customerForm.zipCode"
                       :required="true"
            />
          </div>
          <div class="col-12 col-sm-8 col-lg-4">
            <forminput v-model="localCustomer.city"
                       v-validate="'required'"
                       :hint="$t('customerForm.city')"
                       :is-valid="!errors.has('customerForm.city')"
                       name="customerForm.city"
                       :required="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <forminput v-model="localCustomer.homePhone"
                       v-validate="'required'"
                       :hint="$t('customerForm.netHomePhone')"
                       :is-valid="!errors.has('customerForm.homePhone')"
                       name="customerForm.homePhone"
                       :required="true"
            />
          </div>
          <div class="col-sm-6">
            <forminput v-model="localCustomer.email"
                       v-validate="'required|email'"
                       :hint="$t('customerForm.email')"
                       :is-valid="!errors.has('customerForm.email')"
                       name="customerForm.email"
                       :required="true"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="datepicker">
              <simpledate v-model="localCustomer.birthdate"
                          v-validate="'required'"
                          :title="$t('customerForm.birthDate')"
                          name="customerForm.birthDate"
                          :is-valid="!errors.has('customerForm.birthDate')"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg-2">
            <sprdropdownnumberkey
              v-model="localCustomer.gender"
              :title="$t('customerForm.gender')"
              :items="genders"
              :is-valid="true"
            />
          </div>
        </div>

        <!-- underage -->
        <div v-if="isUnderage" class="row">
          <div class="col-12">
            <div class="underageinstructions">
              {{ $t('customerForm.underageConsent') }}
            </div>
          </div>
          <div class="col-sm-6">
            <forminput v-model="localCustomer.contactFirstName"
                       v-validate="'required'"
                       :hint="$t('customerForm.contactName')"
                       :is-valid="!errors.has('customerForm.contactFirstName')"
                       :required="true"
                       name="customerForm.contactFirstName"
                       :placeholder="$t('customerForm.contactFirstname')"
            />
          </div>
          <div class="col-sm-6">
            <forminput v-model="localCustomer.contactLastName"
                       v-validate="'required'"
                       :hint="$t(' ')"
                       :is-valid="!errors.has('customerForm.contactLastName')"
                       name="customerForm.contactLastName"
                       :placeholder="$t('customerForm.contactLastname')"
            />
          </div>
        </div>

        <div v-if="isUnderage" class="row">
          <div class="col-sm-6">
            <forminput v-model="localCustomer.contactPhoneNumber"
                       v-validate="'required'"
                       :hint="$t('customerForm.underagePhone')"
                       :is-valid="!errors.has('customerForm.contactPhoneNumber')"
                       :required="true"
                       name="customerForm.contactPhoneNumber"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="buttons">
          <prevnextbuttons
            @prev="onPrevClicked"
            @next="onNextClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import forminput from '../SPRSimpleText.vue'
  import simpledate from '../SPRSimpleDatepicker.vue'
  import sprdropdownnumberkey from '../SPRDropdownNumberKey.vue'
  import prevnextbuttons from '../SPRPrevNextButtons.vue'
  import { FriendCustomerNetDto } from '@/types/index'
  import { SprCheckboxBitmaskValue } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    localCustomer: FriendCustomerNetDto | undefined
  }

  export default Vue.extend({
    name: 'PersonalInfo',
    components: { forminput, simpledate, sprdropdownnumberkey, prevnextbuttons },
    props: {
      customer: {
        type: Object as () => FriendCustomerNetDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        localCustomer: undefined
      }
    },
    computed: {
      isUnderage (): boolean {
        if (!this.localCustomer?.birthdate) {
          return false
        }

        var underageYears = 13
        var tmpDate = new Date(this.localCustomer?.birthdate)
        tmpDate.setFullYear(tmpDate.getFullYear() + underageYears)
        return Boolean(tmpDate.getTime() >= new Date().getTime())
      },
      genders (): Array<SprCheckboxBitmaskValue> {
        var self = this
        return _.map(this.$store.state.genders, function (val, key) {
          return { value: val.id, text: self.$t('genders.' + val.text) } as SprCheckboxBitmaskValue
        })
      }
    },
    mounted (): void {
      this.localCustomer = this.customer
    },
    methods: {
      onPrevClicked (): void {
        this.$emit('prev', this.localCustomer)
      },
      onNextClicked (): void {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('next', this.localCustomer)
          }
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .personalinfo {
    background-color: $brand-white;
    padding: 15px;
    padding-top: 30px;

    .headertitle {
      margin-bottom: 30px;
    }

    .underageinstructions {
      margin-bottom: 10px;
    }

    .buttons {
      padding-top: 30px;
    }
  }

</style>