<template>
  <div class="poolpreviewstandard">
    <div class="row content">
      <div class="col-lg-4 mb-3 mb-lg-0 pool-area" :class="{ 'removed': pool.status === $store.state.poolStatus.REMOVED }">
        <div class="row">
          <div class="col-12">
            <div class="title">
              {{ pool.name }}
            </div>
          </div>
          <div class="col-12 description">
            {{ pool.description }}
          </div>
          <div class="col-12 shortdescription text-center">
            {{ pool.shortDescription }}
          </div>
        </div>
        <div v-if="address" class="row iconrow">
          <div class="column col-2 col-md-1 col-lg-2">
            <font-awesome-icon icon="map-marker-alt" aria-hidden="true" />
          </div>
          <div class="column col-10 col-md-11 col-lg-10">
            {{ address }}
          </div>
        </div>
        <div v-if="pool.assemblyTimes" class="row iconrow">
          <div class="column col-2 col-md-1 col-lg-2">
            <font-awesome-icon :icon="['far', 'clock']" aria-hidden="true" />
          </div>
          <div class="column col-10 col-md-11 col-lg-10">
            {{ pool.assemblyTimes }}
          </div>
        </div>
        <div v-if="pool.contactInfo" class="row iconrow">
          <div class="column col-2 col-md-1 col-lg-2">
            <font-awesome-icon icon="phone" aria-hidden="true" />
          </div>
          <div class="column col-10 col-md-11 col-lg-10">
            {{ pool.contactInfo }}
          </div>
        </div>
        <div v-if="pool.notes" class="row notes">
          <div class="column col-12">
            {{ pool.notes }}
          </div>
        </div>
        <div class="row button-row">
          <div class="col-12 buttons text-center">
            <div class="edit-pool">
              <sprbutton :size="4"
                         :title="$t('poolPreview.editPoolButton')"
                         click-event="edit"
                         @edit="onEditPool"
              />
            </div>
            <div v-if="pool.status === $store.state.poolStatus.ACTIVE" class="disable-pool">
              <sprbutton :size="4"
                         :title="$t('poolPreview.disablePoolButton')"
                         click-event="disable"
                         @disable="onDisablePool"
              />
            </div>
            <div v-if="pool.status === $store.state.poolStatus.REMOVED" class="enable-pool">
              <sprbutton :size="4"
                         :title="$t('poolPreview.enablePoolButton')"
                         click-event="enable"
                         @enable="onEnablePool"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-8">
        <div class="row poolmembers">
          <div class="col-12">
            <h4>{{ $t('poolPreview.customersTitle') }}</h4>
          </div>
          <div class="col-12">
            <poolmemberview
              v-for="customer in pool.customers"
              :key="customer.id"
              :member="customer"
              type="CUSTOMER"
              :disabled="pool.status === $store.state.poolStatus.REMOVED"
              @remove="onCustomerRemoved"
              @memberclicked="onCustomerClicked"
            />
          </div>
          <div v-if="!pool.customers || !pool.customers.length" class="col-12 text-center">
            {{ $t('poolPreview.noCustomersInPool') }}
          </div>
          <div class="col-12 volunteerstitle">
            <h4>{{ $t('poolPreview.volunteersTitle') }}</h4>
          </div>
          <div class="col-12">
            <poolmemberview
              v-for="volunteer in pool.volunteers"
              :key="volunteer.id" :member="volunteer"
              type="VOLUNTEER"
              :disabled="pool.status === $store.state.poolStatus.REMOVED"
              @remove="onVolunteerRemoved"
              @memberclicked="onVolunteerClicked"
            />
          </div>
          <div v-if="!pool.volunteers || !pool.volunteers.length" class="col-12 text-center">
            {{ $t('poolPreview.noVolunteersInPool') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import poolmemberview from './PoolMemberView.vue'
  import sprbutton from '../SPRButton.vue'
  import locationhelper from '../../mixins/locationutils.js'
  import addresshelper from '@/helpers/addresshelper'
  import { FriendPoolCustomerSummaryStandardDto, FriendPoolStandardDto, FriendPoolVolunteerSummaryStandardDto } from '@/types/index'

  export default Vue.extend({
    name: 'PoolPreviewStandard',
    components: { poolmemberview, sprbutton },
    mixins: [locationhelper],
    props: {
      pool: {
        type: Object as () => FriendPoolStandardDto,
        required: true
      }
    },
    computed: {
      address (): string {
        if (!this.pool.id) return ''
        return addresshelper.formatAddress(this.pool.address, this.pool.postalCode, this.pool.city)
      }
    },
    methods: {
      onEditPool () {
        const uri = `/friend/pools/${this.pool.friendManagementGroupId}/${this.pool.id}/edit`
        this.$router.push(uri)
      },
      onEnablePool () {
        this.$emit('enable')
      },
      onDisablePool () {
        this.$emit('disable')
      },
      onCustomerRemoved (customer: FriendPoolCustomerSummaryStandardDto) {
        this.$emit('removecustomer', customer)
      },
      onVolunteerRemoved (volunteer: FriendPoolVolunteerSummaryStandardDto) {
        this.$emit('removevolunteer', volunteer)
      },
      onCustomerClicked (customer: FriendPoolCustomerSummaryStandardDto) {
        this.$router.push(`/friend/customerpreview/${this.pool.friendManagementGroupId}/${customer.customerProfileStandardId}`)
      },
      onVolunteerClicked (volunteer: FriendPoolVolunteerSummaryStandardDto) {
        this.$router.push(`/friend/volunteerpreview/${this.pool.friendManagementGroupId}/${volunteer.volunteerId}`)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .poolpreviewstandard {
    .header {
      margin-top: 10px;
      min-height: 48px;
      .nav-header {
        border-bottom: 0;
      }
    }

    .content {
      .title {
        text-transform: uppercase;
        color: $brand-red;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .description {
        font-size: 0.8em;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .shortdescription {
        font-size: 0.8em;
        text-transform: italic;
        color: $brand-red;
        margin-bottom: 15px;
      }
      .pool-area {
        padding-top: 30px;
        background-color: $brand-white;
        border-left: solid 5px $pools-color-strong;

        &.removed {
          border-left: solid 5px $brand-grey7;
          background-color: $brand-grey3;
        }

        .iconrow {
          font-size: 0.8em;
          padding: 5px 0;
          border-bottom: solid 1px $brand-grey3;

          .column {
            margin: auto;
            svg {
              color:$brand-red;
              font-size: 2em;
              text-align: center;
            }
          }
        }
        .notes {
          font-size: 0.8em;
          padding: 15px 0;
        }
        .button-row {
          text-align: center;
          margin-top: 15px;
          .load-indicator {
            width: 100%;
            margin-bottom: 15px;
            color: $brand-red;
          }
          button {
            margin-bottom: 15px;
            &.button-disabled {
              border-color: $brand-grey4;
              color: $brand-grey4;
              cursor: not-allowed;
              outline: none;
              &:hover {
                background-color:$brand-white;
              }
            }
          }
        }
      }
      .poolmembers {
        padding: 15px;

        .volunteerstitle {
          margin-top: 20px;
        }
      }
    }
  }
</style>