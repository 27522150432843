const validatorColonTime = {
  getMessage (field, args) {
    return `${field} must be in the format hh:mm.`
  },
  validate (value, args) {
    var regex = RegExp(/^([01]\d|2[0-3]):([0-5]\d)$/)
    return regex.test(value)
  }
}

export default validatorColonTime
