<template>
  <div class="col-12 comp-admin-friend-level my-3">
    <div class="row group-header">
      <div class="col-12 col-md-8">
        <router-link :to="'/friend/search/' + id + '/customer'" class="groupname">
          {{ friendshipGroup.name }}
        </router-link>
      </div>
      <div class="col-12 col-md-4 text-md-right">
        <sprbutton class="enter-friendship-group"
                   :primary="true"
                   :title="$t('userAdmin.enterFriendshipGroup')"
                   :size="5"
                   :aria-label="$t('userAdmin.enterFriendshipGroup')"
                   click-event="enterFriendshipGroup"
                   @enterFriendshipGroup="enterFriendshipGroup"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <friendshipcontact
          :contact-info-list="friendshipGroup.contactInfo"
          :editable="canEditContactInfo"
          @ContactAdd="onContactAdd"
          @ContactSave="onContactSave"
          @ContactDelete="onContactDelete"
        />
      </div>
      <div class="col-12">
        <SPRSimpleCheckbox v-model="friendshipGroup.friendRequestFormAllowed" :title="$t('userAdmin.friendRequestFormAllowed')" @input="onFormAllowedChecked(item)" />
      </div>

      <div class="col-12">
        <dl>
          <dt v-if="memberSections">
            {{ $t('userAdmin.friendshipGroupMemberSections') }}
          </dt>
          <dd v-if="memberSections">
            {{ memberSections }}
          </dd>
          <dt>
            {{ $t('userAdmin.friendshipGroupMemberZipCodes') }}
            <span v-if="!friendshipGroup.national" @click="zipsAll = !zipsAll">
              <font-awesome-icon v-if="zipsAll" icon="chevron-down" class="brand-red" />
              <font-awesome-icon v-else icon="chevron-up" class="brand-red" />
            </span>
          </dt>
          <dd v-if="!friendshipGroup.national" :class="{ 'few-zips': !zipsAll }">
            {{ memberZips }}
          </dd>
          <dd v-if="friendshipGroup.national">
            {{ $t('userAdmin.national') }}
          </dd>
        </dl>
      </div>
      <div class="col-12">
        <dl>
          <dt>{{ $t('userAdmin.numberOfVolunteers') }}</dt>
          <dd>{{ friendshipGroup.volunteerCount }}</dd>
          <dt>{{ $t('userAdmin.numberOfCustomers') }}</dt>
          <dd>{{ friendshipGroup.customerCount }}</dd>
        </dl>
      </div>
    </div>

    <!--still loading-->
    <div v-if="!loaded" class="row mb-2">
      <div class="col-12 brand-red text-center">
        <loading />
      </div>
    </div>

    <!-- error has occured, reload option -->
    <div v-if="loaded === -1" class="row mb-2">
      <div class="col-12 brand-red text-center">
        <span class="cap-label d-block mb-2">{{ $t('userAdmin.friendshipLoadFailed') }}</span>
        <a class="d-block retry-area"
           href="javascript:void(0)"
           tabindex="0"
           @click="triggerFetch"
        >
          <font-awesome-icon icon="redo" size="2x" fixed-width aria-hidden="true" /><br>
          <span class="cap-label brand-red">{{ $t('userAdmin.retryLoad') }}</span>
        </a>
      </div>
    </div>
    <adminnavigation :options="optionsConstant" />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import adminnavigation from './AdminHorizontalNavigation.vue'
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'
  import friendshipcontact from './FriendshipContact.vue'
  import cache from '../../mixins/cache.js'
  import SPRSimpleCheckbox from '../SPRSimpleCheckbox.vue'

  // the data object
  export interface TemplateComponentData {
    optionsConstant: Array<object>,
    friendshipGroup: any,
    loaded: number,
    zipsAll: boolean
  }

  export default Vue.extend({
    name: 'FriendshipAdmin',
    components: {
      adminnavigation,
      sprbutton,
      loading,
      friendshipcontact,
      SPRSimpleCheckbox
    },
    mixins: [cache],
    props: {
      primary: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      filter: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        optionsConstant: [
          { uri: '/admin/friendship/' + this.id + '/clients', text: this.$t('userAdmin.friendshipClients'), primary: 'client' },
          { uri: '/admin/friendship/' + this.id + '/volunteers', text: this.$t('userAdmin.friendshipVolunteers'), primary: 'volunteer' },
          { uri: '/admin/friendship/' + this.id + '/pools', text: this.$t('userAdmin.friendshipPools'), primary: 'pools' },
          { uri: '/admin/friendship/' + this.id + '/brokers', text: this.$t('userAdmin.friendshipBrokers'), primary: 'broker' }
        ],
        friendshipGroup: {
          name: this.$t('userAdmin.toFriendshipLink')
        },
        loaded: 0,
        zipsAll: false
      }
    },
    computed: {
      memberSections (): string {
        return (this.friendshipGroup.sections && this.friendshipGroup.sections.length) ? this.friendshipGroup.sections.join(', ') : ''
      },
      memberZips (): string {
        return (this.friendshipGroup.zipCodes && this.friendshipGroup.zipCodes.length) ? this.friendshipGroup.zipCodes.join(', ') : ''
      },
      canEditContactInfo (): boolean {
        //@ts-ignore
        return (this.hasRole('Admin') || this.hasRole('FriendshipAdmin') || this.hasRole('DistrictFriendshipAdmin') || this.hasRole('FriendshipGroupManager'))
      }
    },
    mounted () {
      this.triggerFetch()
    },
    methods: {
      enterFriendshipGroup: function () {
        var routeName = '/friend/search/' + this.id + '/customer'
        this.$router.push(routeName)
      },
      triggerFetch: function () {
        this.loaded = 0
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.id)
        var self = this
        this.fetchItem(url, this.expSeconds(120), s => {
          self.friendshipGroup = s
          self.loaded = 1
        }, () => { self.loaded = -1 })
      },
      updateFmg (): any {
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.updateFmg[0]
       return this.$http.put(url, this.friendshipGroup)
      },
      onContactAdd (contact) {
        this.friendshipGroup.contactInfo.push(contact)
        this.updateFmg().then((response: any) => {
          if (response.ok) {
            // reload with the new ids
            this.friendshipGroup = response.body
          }
        })
      },
      onFormAllowedChecked () {
        this.updateFmg()
      },
      onContactSave (contact) {
        this.friendshipGroup.contactInfo = this.friendshipGroup.contactInfo.map(x => x.id === contact.id ? contact : x)
        this.updateFmg().then(response => {
          if (response.ok) {
            // reload with the new ids
            this.friendshipGroup = response.body
          }
        })
      },
      onContactDelete (id) {
        //@ts-ignore
        _.remove(this.friendshipGroup.contactInfo, (add) => {
          return add.id === id
        })

        this.updateFmg().then(response => {
          if (response.ok) {
            // reload with the new ids
            this.friendshipGroup = response.body
          }
        })
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .comp-admin-friend-level {
    .groupname {
      display: inline-block;
      margin-top: 5px;
      vertical-align: top;
      margin-right: 10px;
    }
    .enter-friendship-group {
      font-size: 13px;
      line-height: 20px;
      margin-right: -15px;
    }
    .few-zips {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .group-header {
      font-family: Signa-Bold,Sans-Serif;
      font-size: 32px;
      line-height: 42px;
      padding-bottom: 20px;
    }
  }
</style>
