<template>
  <div class="prevnextbuttons">
    <div class="row">
      <div class="col-6 prev">
        <sprbutton
          :size="4"
          :title="previousText.length>0 ? previousText : $t('wizard.back')"
          preicon="chevron-left"
          class="back"
          click-event="prev"
          @prev="onPrevClicked"
        />
      </div>
      <div class="col-6 next">
        <sprbutton v-if="showNext"
          :size="4"
          :title="nextText.length > 0 ? nextText : isSave ? $t('wizard.save') : $t('wizard.next')"
          :primary="true"
          icon="chevron-right"
          class="next"
          click-event="next"
          @next="onNextClicked"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprbutton from './SPRButton.vue'
  export default Vue.extend({
    name: 'PrevNextButtons',
    components: { sprbutton },
    props: {
      isSave: {
      type: Boolean,
      default: false
      },
      showNext: {
        type: Boolean,
        default: true
      },
      previousText: {
        type: String,
        default: ''
      },
      nextText: {
        type: String,
        default: ''
      }
    },
    methods: {
      onPrevClicked (): void {
        this.$emit('prev')
      },
      onNextClicked (): void {
        this.$emit('next')
      }
    }
  })
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .prevnextbuttons {
    .prev {
      text-align: left;
    }
    .next {
      text-align: right;
    }
  }

</style>