<template>
  <div class="groupmembers">
    <div class="row">
      <div class="col-12">
        <h5>{{ $t('userAdmin.existingMembersTitle') }}</h5>
      </div>
    </div>
    <div class="row existingmembers">
      <div class="col-12">
        <div v-for="member in groupMembers" :key="member.id" class="member">
          <existingmember :member="member" @remove="onRemove" @makeAdmin="onMakeAdmin" @removeAdmin="onRemoveAdmin" />
        </div>
        <div v-if="!group.members.length" class="text-center">
          <div>{{ $t('userAdmin.noMembers') }}</div>
        </div>
      </div>
    </div>

    <div v-if="group.groupType === 1" class="row">
      <div class="col-12">
        <h5>{{ $t('groups.addPendingVolunteer') }}</h5>
      </div>
    </div>
    <div v-if="group.groupType === 1" class="row addpendingmember">
      <div class="col-12">
        <groupmemberpending :group="group" />
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <h5>{{ $t('userAdmin.addMemberTitle') }}</h5>
      </div>
    </div>
    <div class="row addmember">
      <div class="col-12">
        <groupmemberadd :group="group" />
      </div>
    </div>
  </div>
</template>

<script>
  import groupmemberadd from '../groups/GroupMemberAdd.vue'
  import groupmemberpending from '../groups/GroupMemberPending.vue'
  import existingmember from './GroupExistingMember.vue'

  export default {
    name: 'GroupMembers',
    components: { groupmemberadd, groupmemberpending, existingmember },
    props: {
      group: {
        type: Object,
        default: null
      }
    },
    computed: {
      groupMembers () {
        return _.sortBy(this.group.members, (m) => {
          return m.firstName
        })
      }
    },
    methods: {
      onMakeAdmin (member) {
        this.$emit('makeGroupAdmin', member)
      },
      onRemoveAdmin (member) {
        this.$emit('removeGroupAdmin', member)
      },
      onRemove (member, cb) {
        const self = this
        const payload = { payload1: this.group.id, payload2: [member.id] }
        const uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupremmembers[0]
        this.$http.post(uri, payload).then(s => {
          self.$delete(self.group.members, self.findMemberIndex(member))
          self.$bus.$emit('group-member-removed', payload)
          cb.inProgress = false
        }, f => {
          cb.inProgress = false
        })
      },
      findMemberIndex (member) {
        return _.findIndex(this.group.members, function (m) {
          return m.id === member.id
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .groupmembers {

    .addmember {
      background-color:$brand-white;
      padding-top:15px;
      margin-bottom:20px;
    }

  .addpendingmember {
    background-color: $brand-white;
    margin-bottom: 20px;
  }

    .existingmembers {
      background-color:$brand-white;
      padding:15px 0;
      margin-bottom:15px;

      .member:nth-child(odd) {
        background: rgba(0, 0, 0, 0.03);
      }
    }
  }
</style>
