// THIS FILE IS OBSOLETE - Prefer to use Helpers, rather than Mixins in TS files, as Mixins are not picked up.
import moment from 'moment'
export default {
  methods: {
    // Gets the age in years
    calculateAge (bDate, offset = 0) {
      return moment().diff(moment(bDate) + moment.duration(offset), 'years')
    },
    calculateDayDiff (pDate) {
      if (!pDate) {
        return
      }
      return moment().diff(moment(pDate), 'days')
    }
  }
}
