<template>
  <div class="row">
    <div class="col col-12 spr-header spr-header-grid spr-header-layout">
      <div class="logo-area">
        <router-link to="/front">
          <img class="spr-logo" src="../assets/img/redcross_logo_fi.svg">
        </router-link>
        <div>
          <SwitchLanguage />
        </div>
      </div>
      <!-- Navigation -->
      <div class="nav-area">
        <router-link v-if="adminTranslateUrl" :to="adminTranslateUrl" class="header-link-basic" tabindex="0">
          {{ $t('common.translate') }}
        </router-link>
        <a v-if="ossiUrl" :href="ossiUrl" target="_blank" class="header-link-basic" tabindex="0">
          {{ $t('common.headerOSSI') }}</a>
        <a v-if="hupsisUrl" :href="hupsisUrl" target="_blank" class="header-link-basic" tabindex="0">{{ $t('common.headerHUPSIS') }}</a>
        <router-link v-if="friendUrl" :to="friendUrl" class="header-link-basic" tabindex="0">
          {{ $t('common.headerFriends') }}
        </router-link>
        <router-link v-if="adminUrl" :to="adminUrl" class="header-link-basic" tabindex="0">
          {{ $t('common.headerAdmin') }}
        </router-link>
      </div>
      <!-- Login -->
      <div class="login-area">
        <div v-if="hasProfile">
          <sprbutton type="primary"
                     :size="1"
                     :title="$t('common.headerLogin')"
                     class="spr-shadow margintop20"
                     click-event="toLogin"
                     @toLogin="toLogin"
          />
        </div>
        <a v-show="!hasProfile"
           href="javascript:void(0);"
           class="header-link"
           tabindex="0"
           @click="logout('/login')"
        >
          {{ $t('common.headerLogout') }}
        </a>
      </div>
      <!-- Burger Menu -->
      <div class="burger-area">
        <font-awesome-icon icon="bars" class="spr-burger" aria-hidden="true" @click="toggleBurger" />
      </div>
      <div v-if="burgerExpanded" class="burger-menu d-block d-lg-none burger-expanded">
        <div v-if="hasProfile">
          <sprbutton type="primary"
                     :size="1"
                     :title="$t('common.headerLogin')"
                     class="spr-shadow margintop20"
                     click-event="toLogin"
                     @toLogin="toLogin"
          />
        </div>
        <a v-else href="javascript:void(0);" class="d-inline-block lnk header-link m-3" tabindex="0" @click="logout('/login')">{{ $t('common.headerLogout') }}</a>
        <router-link v-if="adminTranslateUrl" :to="adminTranslateUrl" class="d-inline-block lnk header-link-basic m-3" tabindex="0">
          {{ $t('common.translate') }}
        </router-link>
        <a v-if="ossiUrl" :href="ossiUrl" target="_blank" class="d-inline-block lnk header-link-basic m-3" tabindex="0">{{ $t('common.headerOSSI') }}</a>
        <a v-if="hupsisUrl" :href="hupsisUrl" target="_blank" class="d-inline-block lnk header-link-basic m-3" tabindex="0">{{ $t('common.headerHUPSIS') }}</a>
        <router-link v-if="friendUrl" :to="friendUrl" class="d-inline-block lnk header-link-basic m-3" tabindex="0">
          {{ $t('common.headerFriends') }}
        </router-link>
        <router-link v-if="adminUrl" :to="adminUrl" class="d-inline-block lnk header-link-basic m-3" tabindex="0">
          {{ $t('common.headerAdmin') }}
        </router-link>
      </div>
    </div>
    <div v-if="passwordExpires" class="banner">
      <div class="option warning-banner">
        <div class="option-value warning-option">
          <tickcrosswarning :value="option1Value" />
        </div>
        <div class="option-label">
          {{ $t('common.passwordAboutToExpireBanner') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import SwitchLanguage from './SwitchLanguage.vue'
  import sprbutton from './SPRButton.vue'
  import tickcrosswarning from '@/components/SPRTickCrossWarning.vue'

  // the data object
  export interface TemplateComponentData {
    burgerExpanded: boolean,
    hupsisUrl: string | null,
    ossiUrl: string | null
  }

  export default Vue.extend({
    name: 'SprHeader',
    components: { SwitchLanguage, sprbutton },
    props: {
      option1Value: {
        type: Number,
        required: false,
        default: 3 // 3 - Warning
      }
    },
    data (): TemplateComponentData {
      return {
        burgerExpanded: false,
        hupsisUrl: null,
        ossiUrl: null
      }
    },
    computed: {
      passwordExpires (): boolean {
        // @ts-ignore cannot find mixins
        return this.jwtToken?.PasswordExpiring
      },
      hasProfile (): any {
        return !this.$store.state.profile
      },
      adminTranslateUrl (): string {
        // @ts-ignore cannot find mixins
        if (this.hasRole('Admin')) {
          return '/cms/translate'
        }
        return ''
      },
      adminUrl: function () {
        // @ts-ignore cannot find mixins
        if (this.hasRole('Admin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee')) {
          return '/admin/district'
        }

        // @ts-ignore cannot find mixins
        if (this.hasRole('Employee') || this.hasRole('DistrictAdmin')) {
          // @ts-ignore cannot find mixins
          return '/admin/district/' + this.jwtToken.DistrictMembership + '/section'
        }

        // @ts-ignore cannot find mixins
        if (this.hasRole('J1') || this.hasRole('J2')) {
          // @ts-ignore cannot find mixins
          return '/admin/section/' + this.jwtToken.SectionMembership + '/user'
        }

        // @ts-ignore cannot find mixins
        if (this.hasRole('DistrictFriendshipAdmin') || this.hasRole('FriendshipTrainer')) {
          return '/admin/training/1'
        }

        return null
      },
      friendUrl: function () {
        // @ts-ignore cannot find mixins
        if (!this.jwtToken) return null

        // @ts-ignore cannot find mixins
        if (this.hasRole('Admin') || this.hasRole('FriendshipAdmin') || this.hasRole('DistrictFriendshipAdmin')) {
          return '/admin/friendship'
        }

        // everyone below must be in a group from here on
        // @ts-ignore cannot find mixins
        if ((!this.jwtToken.FriendshipGroups || !this.jwtToken.FriendshipGroups.length) && (!this.jwtToken.NetFriendshipGroups || !this.jwtToken.NetFriendshipGroups.length)) return null

        // if they are a manager and only have membership of one group, the link goes there
        // @ts-ignore cannot find mixins
        if (this.hasRole('FriendshipGroupManager') && (this.jwtToken.FriendshipGroups && this.jwtToken.FriendshipGroups.split(';').length === 1) && !this.hasRole('NetFriendshipBroker')) {
          // @ts-ignore cannot find mixins
          return '/admin/friendship/' + this.jwtToken.FriendshipGroups.split(':')[1] + '/clients'
        }

        // if they are a broker and not a net friendship broker only have membership of one group, and no net friendship groups, go there
        // @ts-ignore cannot find mixins
        if (this.hasRole('FriendshipBroker') && !this.hasRole('NetFriendshipBroker') && (this.jwtToken.FriendshipGroups && this.jwtToken.FriendshipGroups.split(';').length === 1)) {
          // @ts-ignore cannot find mixins
          return '/friend/search/' + this.jwtToken.FriendshipGroups.split(':')[1] + '/customer'
        }

        // if they are a net broker not a friend broker, go there
        // @ts-ignore cannot find mixins
        if (this.hasRole('NetFriendshipBroker') && !this.hasRole('FriendshipBroker') && !(this.jwtToken.FriendshipGroups && this.jwtToken.FriendshipGroups.split(';').length > 0) && (this.jwtToken.NetFriendshipGroups && this.jwtToken.NetFriendshipGroups.split(';').length === 1)) {
          // @ts-ignore cannot find mixins
          return '/friend/search/' + this.jwtToken.NetFriendshipGroups.split(':')[1] + '/customer'
        }

        // members of multiple groups. Show the groups list page
        // @ts-ignore cannot find mixins
        if ((this.hasRole('FriendshipGroupManager') || this.hasRole('FriendshipBroker') || this.hasRole('NetFriendshipBroker'))) {
          return '/friend/groups'
        }

        return null
      }
    },
    created: function () {
      // @ts-ignore cannot find mixins
      this.getHupsisData()
      // @ts-ignore cannot find mixins
      this.getOssiData()
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'setLanguage') {
          // @ts-ignore cannot find mixins
          this.getHupsisData()
          // @ts-ignore cannot find mixins
          this.getOssiData()
        }
      })
    },
    methods: {
      toLogin (): void {
        if (this.$route.path !== '/login') {
          this.$router.push({ name: 'login' })
        }
      },
      toggleBurger (): void {
        this.$data.burgerExpanded = !this.$data.burgerExpanded
      },
      getHupsisData (self: Vue): void {
        const hupsisBaseUrl = this.$store.state.language.toLowerCase() === 'sv' ? this.$store.state.hupsisUrl.sv : this.$store.state.language.toLowerCase() === 'fi' ? this.$store.state.hupsisUrl.fi : this.$store.state.hupsisUrl.en
        // @ts-ignore cannot find mixins
        if (this.jwtToken && this.jwtToken.ProfileId) {
          const ivStr = this.randomIv()

          var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupgethupsislink[0].replace('{iv}', encodeURIComponent(ivStr))
          this.$http.get(url).then(resp => {
            // if they are not members of a first aid group, no link for them.
            // @ts-ignore cannot find mixins
            if (resp.body) {
              // @ts-ignore cannot find mixins
              this.hupsisUrl = hupsisBaseUrl + '?i=' + encodeURIComponent(ivStr) + '&u=' + encodeURIComponent(resp.body)
            }
          })
        }
      },
      getOssiData (): void {
        var self = this

        // @ts-ignore cannot find mixins
        if (this.jwtToken && this.jwtToken.ProfileId) {
          const ossiBaseUrl = (this.$store.state.language.toLowerCase() === 'sv' ? this.$store.state.ossiUrl.sv : this.$store.state.ossiUrl.fi) as string
          self.$data.ossiUrl = ossiBaseUrl
        }
      },
      randomIv (): string {
        var strResult = ''
        var valid = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        for (var i = 0; i < 16; i++) {
          var rand = Math.floor(Math.random() * valid.length)
          strResult += valid[rand]
        }
        return btoa(strResult)
      }
    }
  })
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

  $headerheight: 85px;
  $separatorheight: 71px;
  $logoheight: 45px;
  $separatormargin: 20px;
  $burgersize: 22px;

  .banner {
    width: 100%;
  }

  .warning-icon {
    margin-right: 5px;
    color: $brand-danger;
  }
  .warning-option {
    margin-right: 10px;
    margin-left: 10px;
  }

  .option-label {
    margin-right: 10px;
    margin-left: 10px;
    font-size: 12px;
    line-height: 110%;
    align-self: center;

    @media screen and (max-width: $media-sm) {
          font-size: 10px;
          line-height: 110%;
    }
  }

  .warning-banner {
    margin: auto;
    margin-left: 10px;
    padding: 8px 0;
    display: flex;
    text-align: right;
    background: lemonchiffon;
    justify-content: center;
  }

  .right-margin{
    margin-right: 20px;
  }
  .spr-header {
    background-color: $brand-white;
    padding: 10px;
    width: 100%;
    border-bottom: solid 1px $brand-grey3;

    .spr-logo {
      height: $logoheight;
      cursor: pointer;
    }

    div {
      align-items: center;
    }

    .search-area {
      justify-content: flex-end;
    }

    .nav-area {
      justify-content: flex-end;
      padding-right: 30px;
    }

    .login-area {
      justify-content: center;
    }

    .burger-menu {
      &.burger-expanded {
        grid-column-end: 6;
      }
    }

    .header-link {
      font-family: $font-light;
      font-size: 14px;
      color: $brand-red;
      padding: 0;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        height: $separatorheight;
        border-left: 1px solid $brand-grey1;
        transform: translate(-30px, ((2 * $separatorheight - $headerheight) / -2));
      }
    }

    .header-link-basic {
      font-family: $font-light;
      font-size: 14px;
      color:$brand-black;

      &:not(:first-of-type) {
        margin: 0 0 0 15px;
      }
    }

    .search-box {
       border: 2px solid $brand-grey3;
       width: 100%;
       padding-left: 30px;
       font-family: $font-light;
       padding: 5px 20px;
       max-width: 300px;
     }

     .search-toggler {
       margin-left: 20px;
       margin-right: 10px;
     }
  }

  .spr-header-layout {
    grid-template-rows: $headerheight;
    -ms-grid-rows: $headerheight;
    grid-template-columns: 120px 1fr 1fr 100px;
    -ms-grid-columns: 120px 1fr 1fr 100px;

    @media (min-width: $media-md) {
      grid-template-rows: auto;
      -ms-grid-rows: $headerheight;
      grid-template-columns: 120px 1px auto 150px;
      -ms-grid-columns: 120px 1px 1fr 250px;
    }
  }

  .spr-header-layout-expanded {
    grid-template-rows: $headerheight;
    -ms-grid-rows: $headerheight;
    grid-template-columns: 120px auto 150px 150px;
    -ms-grid-columns: 120px 1fr 150px 150px;

    @media (max-width: $media-sm-max) {
      height: 100vh;
      z-index: 10;
      grid-template-rows: $headerheight auto;
      -ms-grid-rows: $headerheight;
      grid-template-columns: 120px auto 100px 100px;
      -ms-grid-columns: 120px 1fr 100px 100px;
    }
  }

  .spr-header-grid {
    display: grid;
    display: -ms-grid;

    .logo-area {
      display: flex;
      grid-row: 1;
      -ms-grid-row: 1;
      grid-column: 1;
      -ms-grid-column: 1;

      h6 {
           margin-bottom: 0;
         }
    }

    .login-area {
      display: none;
    }

    .search-area {
      display: none;
    }

    .nav-area {
      display: none;
    }

    .burger-area {
      display: flex;
      grid-row: 1;
      -ms-grid-row: 1;
      grid-column: 5;
      -ms-grid-column: 5;

      .spr-burger {
        padding: 10px;
        font-size: 54px;
      }
    }

    .burger-menu {
      grid-row: 2;
      -ms-grid-row: 2;
      grid-column: 1;
      -ms-grid-column: 1;

      .lnk {
             font-size: 23px;
           }
    }

    @media (min-width: $media-md) {
      .search-area {
        display: flex;
        grid-row: 1;
        -ms-grid-row: 1;
        grid-column: 2;
        -ms-grid-column: 2;
      }

      .nav-area {
        display: flex;
        grid-row: 1;
        -ms-grid-row: 1;
        grid-column: 3;
        -ms-grid-column: 3;
      }

      .login-area {
        display: flex;
        grid-row: 1;
        -ms-grid-row: 1;
        grid-column: 4;
        -ms-grid-column: 4;
      }

      .burger-area {
        display: none;
      }
    }
  }
</style>
