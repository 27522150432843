<template>
  <div class="start">
    <div class="row">
      <div class="col-12">
        <div class="backimage"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="maincontent">
          <div class="container">
            <div class="row">
              <div class="col-12 title">
                <h3 class="whitebackground">
                  {{ $t('customerForm.newNetCustomerTitle') }}
                </h3>
              </div>
              <div class="col-12 subtitle">
                <div class="whitebackground">
                  {{ $t('customerForm.newNetCustomerSubTitle') }}
                </div>
              </div>
            </div>
            <div class="row instructions">
              <div class="col-12">
                <div class="cap-label">
                  {{ $t('customerForm.newNetCustomerInstructionsTitle') }}
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="$t('customerForm.newNetCustomerText')"></div>
              </div>
              <div class="col-12 buttons">
                <sprbutton
                  :size="4"
                  :title="$t('wizard.next')"
                  :primary="true"
                  icon="chevron-right"
                  click-event="start"
                  @start="onStartClicked"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  export default Vue.extend({
    name: 'Start',
    components: { sprbutton },
    methods: {
      onStartClicked (): void {
        this.$emit('next')
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .start {
    .backimage {
      width: 100%;
      height: 500px;
      overflow: hidden;
      background: url('https://sprvatistorage.blob.core.windows.net/eventimages/defaultevent_18.jpg') no-repeat 0 0;

      img {
        width: 100%;
      }
    }
    .maincontent {
      margin-top: -400px;

      .title, .subtitle {
        text-align: center;
        color: $brand-red;
      }
      .whitebackground {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        background-color: $brand-white;
      }
      .subtitle {
        margin-bottom: 50px;
      }

      .instructions {
        background-color: $brand-white;
        padding-top: 30px;
        padding-bottom: 30px;

        .cap-label {
          color: $brand-red;
          margin-bottom: 15px;
        }

        .buttons {
          margin-top: 30px;
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }
  }

</style>