<template>
  <div class="comp-profile-editor-reset-password mx-auto text-center container my-5">
    <div class="col-12">
      <div v-if="expired" class="row">
        <div class="col-12">
          <font-awesome-icon :icon="['far', 'smile']" size="5x" class="brand-red" />
        </div>
        <div class="col-12 my-3">
          <span>{{ $t('profile.resetPasswordTokenExpired') }}</span>
        </div>
      </div>
      <div v-if="error" class="row">
        <div class="col-12">
          <font-awesome-icon :icon="['far', 'smile']" size="5x" class="brand-red" />
        </div>
        <div class="col-12 my-3">
          <span>{{ $t('profile.resetPasswordError') }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <sprvalidation :validation-errors="validationErrors" class="mb-5" />
        </div>
      </div>
      <div v-if="!expired" class="row">
        <div class="col-12">
          <span class="instructions">{{ $t('profile.resetPasswordInstructions') }}</span>
        </div>
      </div>
      <div class="row">
        <!-- password -->
        <div class="col-12 col-md-6">
          <sprPassword ref="password"
                       v-model="password"
                       v-validate="'min:12|max:128'"
                       :hint="$t('profile.password')"
                       class="editor-field"
                       input-type="password"
                       :is-valid="!errors.has('password')"
                       name="password"
                       data-vv-validate-on="none"
                       autocomplete="false"
          />
        </div>
        <!-- retype password -->
        <div class="col-12 col-md-6">
          <sprPassword v-model="confirmPassword"
                       v-validate="{required: retypeRequired, confirmed:password }"
                       data-vv-as="password"
                       :hint="$t('profile.retypePassword')"
                       class="editor-field"
                       input-type="password"
                       :is-valid="!errors.has('retypePassword')"
                       name="retypePassword"
                       data-vv-validate-on="none"
                       autocomplete="false"
          />
        </div>
      </div>
      <!-- save button -->
      <div v-if="!pending" class="row py-4">
        <div class="col-12 col-md-6 my-1">
          <sprbutton click-event="save-event" :title="$t('profile.save')"
                     :size="1"
                     :bordersize="2" class="fill-width" @save-event="onSave"
          />
        </div>
      </div>
      <div v-else class="row pending">
        <div class="col-12 py-4">
          <loading />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprPassword from '../SprPassword.vue'
  import sprbutton from '../SPRButton.vue'
  import sprvalidation from '../SPRValidationError.vue'
  import loading from '../SPRLoading.vue'

  // the data object
  export interface TemplateComponentData {
        password: string,
        hash: string,
        currentPasswordFailure: any,
        confirmPassword: string,
        pending: boolean,
        expired: boolean,
        error: boolean,
        validatedFields: Array<string>
  }

  export default Vue.extend({
    name: 'ProfileEditorResetPassword',
    components: { sprPassword, sprbutton, sprvalidation, loading },
    props: {
      token: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        password: '',
        hash: '',
        currentPasswordFailure: null,
        confirmPassword: '',
        pending: false,
        expired: false,
        error: false,
        validatedFields: ['password', 'retypePassword']
      }
    },
    computed: {
      retypeRequired (): boolean {
        return this.password.length > 0
      },
      validationErrors (): Array<any> {
        var arr = [] as Array<any>
        var self = this

        if (self.currentPasswordFailure) {
          arr.push(['login.attempt', 'currentPasswordFailed', ''])
          self.currentPasswordFailure.forEach(err => {
            //@ts-ignore
            if (err.code) { arr.push(['', 'error.' + this.toLowerCamelCase(err.code), '']) }
          })
        }

        if (!this.validatedFields) {
          return arr
        }

        this.validatedFields.forEach(function (fieldName) {
          //@ts-ignore
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })

          if (err != null) {
            arr.push(['profile.' + err.field, String(err.rule), String(err.msg)])
          }
        })
        return arr
      },
      resetPassword (): any {
        return {
          Token: this.token,
          Password: this.password
        }
      }
    },
    methods: {
      onSave (): void {
        const self = this

        this.currentPasswordFailure = null

        this.$validator.validateAll().then((result) => {
          if (result) {
            this.pending = true

            const url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.resetpassword[0]
            this.$http.post(url, this.resetPassword).then(resp => {
              // @ts-ignore
              this.logout('/login')
            }, (resp) => {
              if (resp.body?.error?.errors[0]?.description.toLowerCase() === 'token has expired!') {
                self.expired = true
              } else {
                self.currentPasswordFailure = resp.body?.error?.errors
                this.error = true
              }
              this.pending = false
            })
          } else {
            if (this.validationErrors && this.validationErrors.length) {

            }
          }
        })
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
.comp-profile-editor-reset-password {
    max-width: 600px;

   .editor-field {
    width: 100%;
    text-align: left;
  }

  .row input {
    box-shadow: none;
    border: 2px solid $brand-grey3;
    display: inline-block;
  }

  .spr-shadow {
    box-shadow: none !important;
    border: 2px solid $brand-grey3;
  }

  .wrap-date input {
    box-shadow: none !important;
    border: 2px solid $brand-grey3 !important;
  }

  .instructions {
    display: block;
    margin-bottom: 30px;
    text-align:left
  }

  .pending {
    color: $brand-red;
  }
}
</style>
