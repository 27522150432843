<template>
  <div class="settings-admin">
    <h1>{{ $t('settingsAdmin.title') }}</h1>
    <div v-if="primary === 'settings'">
      <div class="filter-links">
        <router-link v-for="(f, i) in settingFilters"
                     :key="'filter_' + _uid + '_' + i"
                     :to="settingsLink(f.value)"
                     class="d-inline-block m-2"
        >
          {{ f.text }}
        </router-link>
      </div>
      <div v-if="folder !== ''">
        <EditImages container="eventimages" :folder="folder" />
      </div>
      <div v-else>
        <Settings></Settings>
        <div v-if="!loading"></div>
        <div v-else class="spinner my-4 col-12">
          <loading />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import loading from '../SPRLoading.vue'
  import { SprCheckboxValue } from '@/types/custom'
  import EditImages from '@/components/common/Images/EditImages.vue'
  import Settings from '@/components/useradmin/Settings.vue'

  // the data object
  export interface TemplateComponentData {
    isAddFormVisible: boolean,
    isEditFormVisible: boolean,
    settingFilters: Array<SprCheckboxValue>,
    loading: boolean
  }

  export default Vue.extend({
    name: 'SettingsAdmin',
    components: { loading, EditImages, Settings },
    props: {
      primary: {
        type: String,
        required: true
      },
      results: {
        type: Object,
        default: () => ({ results: [], total: 0 }),
        required: false
      },
      id: {
        type: String,
        required: false
      }
    },
    data (): TemplateComponentData {
      return {
        isAddFormVisible: false,
        isEditFormVisible: false,
        loading: false,
        settingFilters: [{ text: this.$t('settingsAdmin.eventBackgroundImageHeader').toString(), value: 'eventbackgroundimages' }, { text: this.$t('settingsAdmin.eventThumbnailImageHeader').toString(), value: 'eventthumbnailimages' }, { text: this.$t('settingsAdmin.systemSettingsHeader').toString(), value: 'systemsettings' }]
      }
    },
    computed: {
      folder (): string {
        switch (this.id) {
        case 'eventbackgroundimages':
          return 'Background'
        case 'eventthumbnailimages':
          return 'Thumbnails'
        default:
          return ''
        }
      }
    },
    methods: {
      settingsLink (newState): string {
        return '/admin/settings/' + newState
      },
      async fetchExternalForms () {
        try {
          const url =
            this.$store.state.apiBases.externalformservice +
            this.$store.state.apiEndpoints.externalformsall[0]
          this.loading = true
          const response = await this.$http.get(url).then((response) => {
            this.loading = false
            //            this.externalForms = response.data as Array<ExternalFormUpdateDto>
          })
        } catch (error) {
          console.error('Error fetching external forms:', error)
        }
      },
      showAddForm () {
        this.isAddFormVisible = true
      },
      async addRow (externalForm: {name: string, link: string }) {
        try {
          const url =
            this.$store.state.apiBases.userservice +
            this.$store.state.apiEndpoints.externalformsadd[0]

          const response = await this.$http.post(url, externalForm)
          //          this.externalForms.push(response.data)
          this.isAddFormVisible = false
        } catch (error) {
          console.error('Error adding external form:', error)
        }
      },
      async deleteRow (id: number) {
        try {
          let url =
            this.$store.state.apiBases.userservice +
            this.$store.state.apiEndpoints.externalformsdelete[0]
          url = url.replace('{0}', id)

          await this.$http.delete(url)
          //          this.externalForms = this.externalForms.filter(form => form.id !== id)
        } catch (error) {
          console.error('Error deleting external form:', error)
        }
      },
      cancelRow () {
        this.isAddFormVisible = false
        this.isEditFormVisible = false
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  .settings-admin {
    background: $brand-white;
    padding: 30px 15px;

    .create-row {
      margin-top: 30px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0; /* Optional: to remove default padding */
    margin: 0;  /* Optional: to remove default margin */
  }
  .settings-admin {
    p {
      font-family: $font-bold;
    }
    .filter-links {
        a {
          text-transform: uppercase;
          font-weight: bold;
          color: $brand-grey7 !important;

          &.router-link-active {
            color: $brand-red !important;
          }
        }
    }
    .spinner {
      color: $brand-red;
      text-align: center;
    }
  }
</style>
