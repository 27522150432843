<template>
  <div class="col-12 superadmin">
    error
  </div>
</template>

<script>
  export default {
    name: 'AdminError',
    data () {
      return {
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
.superadmin {
  background: $brand-white;
}
</style>
