<template>
  <div class="success">
    <div class="row">
      <div class="col-12">
        <div class="row headertitle">
          <div class="col-12">
            <h3>{{ $t('customerForm.netCustomerSuccess') }} <font-awesome-icon :icon="['far', 'check-circle']" class="tick" /></h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="$t('customerForm.newNetCustomerSuccessText')" />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="buttons">
          <sprbutton
            :size="4"
            :title="$t('wizard.complete')"
            :primary="true"
            icon="chevron-right"
            click-event="finish"
            @finish="onFinishClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import { TranslateResult } from 'vue-i18n'

  export default Vue.extend({
    name: 'Success',
    components: { sprbutton },
    methods: {
      onFinishClicked (): void {
        this.$emit('next')
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .success {
    background-color: $brand-white;
    padding: 15px;
    padding-top: 30px;

    .headertitle {
      margin-bottom: 30px;
      .tick {
        color: $brand-success;
      }
    }

    .cap-label {
      color: $brand-red;
    }

    .buttons {
      padding-top: 30px;
      text-align: center;
    }
  }

</style>