<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .statusindicator {
    border-top:solid 1px $brand-red;
    margin-top: 20px;
    display:inline-block;

    .indicatorcontainer {
      display:inline-block;
      top: -18px;
      margin-right: 32px;
      margin-left: 32px;
      position:relative;

      &:first-child {
        margin-left: -2px;
      }

      &:last-child {
        margin-right: -2px;
      }

      .indicator {
        border: solid 2px $brand-red;
        background-color:$brand-white;
        color: $brand-red;
        border-radius: 100%;
        height: 32px;
        width: 32px;
        display:flex;
        font-family:Verdana, sans-serif; //Signa-Light numbers jump above and below baseline!

        &.selected {
          background-color:$brand-red;
          color:$brand-white;
        }

        .status {
          margin: auto;
        }
      }

      .indicatortitle {
        position: absolute;
        left: -19px;
        width:70px;
        display:block;
        font-size:0.8em;
        font-weight:bold;
        margin-top:10px;
      }
    }
  }
</style>
<template>
  <div class="statusindicator">
    <div v-for="status in statuses" :key="status.key" class="indicatorcontainer">
      <div class="indicator" :class="{'selected': isSelected(status.key)}">
        <span class="status">{{ status.label }}</span>
      </div>
      <div class="indicatortitle">
        {{ status.title }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Statusindicator',
    props: {
      statuses: {
        type: Array,
        default () {
          return []
        }
      },
      value: {
        type: Number,
        required: true
      }
    },
    methods: {
      isSelected (key) {
        return key === this.value
      }
    }
  }
</script>
