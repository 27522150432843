<template>
  <div id="test" :class="{ '.spr-multiselect-dropdown': true, 'spr-roundbox-1': true }" @click="onClick">
    <multiselect ref="list"
                 :value="selectedItems"
                 :hide-selected="true"
                 :close-on-select="false"
                 placeholder=""
                 label="text"
                 track-by="text"
                 :options="items"
                 :searchable="false"
                 :multiple="true"
                 @input="onChange"
                 @open="onOpen"
                 @close="onClose"
    ></multiselect>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'

  export default {
    components: {
      Multiselect
    },
    model: {
      prop: 'selectedItems',
      event: 'change'
    },
    props: {
      selectedItems: {
        type: Array,
        default: () => []
      },
      items: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        isClosing: false,
        isOpening: false
      }
    },
    methods: {
      onOpen () {
        this.isOpening = true
      },
      onClose () {
        this.isClosing = true
      },
      onClick () {
        if (!this.isClosing && !this.isOpening) {
          this.$refs.list.isOpen = !this.$refs.list.isOpen
        }
        this.isClosing = false
        this.isOpening = false
      },
      onChange (e) {
        this.$emit('change', e)
      }
    }
  }</script>

<style lang="scss">
  @import "~@/assets/scss/_variables.scss";

  .multiselect {
    margin-top: 10px;
    margin-bottom: 10px;
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-width: 240px;
    min-height: 40px;
    text-align: left;
    color: #35495e;
    outline: none;
    &:focus .multiselect__tags{
      color: #495057;
      border: solid 2px $brand-grey5;
      outline: 0;
      box-shadow: 0 0 0 0.2rem $brand-grey3;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  .multiselect__content{
    display: inline-block;
    background: white;
    list-style-type: none;
    cursor: pointer;
    width: 100%;
  }
  .multiselect__content-wrapper{
    background: $brand-white;
    overflow-y: overlay;
    width: 100%;
    border: solid 1px black;
    position: absolute;
    z-index: 10;
    ul {
      padding-inline-start: unset;
      margin-bottom: 0;
    }
  }
  .multiselect__option {
    display: block;
  }
  .multiselect__element {
    font-family: "Signa-Light", Sans-Serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    font-weight: normal;
    display: block;
    white-space: nowrap;
    min-height: 1.2em;
    padding: 0px 2px 1px 14px;
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    text-align: start;
    appearance: auto;
    box-sizing: border-box;
    align-items: center;
    cursor: default;
    &:hover {
      background: $brand-grey2;
    }
  }

  .multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform .2s ease;
  }
  .multiselect__select:before {
      border-width: 5px 5px 0;
      border-style: solid;
      position: relative;
      margin-top: 4px;
      border-color: rgba(0, 0, 0, 0) !important;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAQklEQVQImWNkYGBYx8DA4MLAwPCNAQK4GBgY9oAY/gwMDP/RMEgMDPYjSYDYcOCAJAFio4AXUAwGLEhSJXAWAwMDAHqfD4znzHk9AAAAAElFTkSuQmCC);
      background-repeat: no-repeat;
      top: 13px !important;
      content: "";
  }
  .multiselect__tags{
    overflow-wrap: anywhere;
    min-height: 40px;
    display: block;
    border-radius: 2rem;
    padding: 8px 40px 0 8px;
    border: solid 2px $brand-grey5;
    background: #fff;
    font-size: 14px;
  }

  .multiselect__tag{
    border-radius: 2rem;
    white-space: pre;
    color: white;
    background-color: #CC0000;
    padding: 2px;
    margin-right: .25rem;
    border-spacing: 1em;
    position: relative;
    display: inline-block;
    padding: 4px 15px 4px 10px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    span {
      padding: 9px;
    }
    i {
      padding: 9px;
    }
}
  .multiselect__tag-icon:after {
    content: "\D7";
    font-size: 14px;
  }

</style>
