<template>
  <div class="checkboxlist">
    <div class="row">
      <div :class="{ 'spr-danger': !isValid, 'checkboxlist': true }">
        <checkbox v-for="item in list" :key="item.id"
                  v-model="item.selected"
                  additional-class="checkbox"
                  :title="item.text"
                  no-wrap
                  @input="onChecked(item)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import checkbox from './SPRSimpleCheckbox.vue'
  import { SprDropDownValue } from '../types/custom'
  export default Vue.extend({
    name: 'SprSimpleCheckboxList2',
    components: { checkbox },
    model: {
      prop: 'list',
      event: 'change'
    },
    props: {
      list: {
        type: Array<SprDropDownValue>,
        default () { return [] }
      },
      isValid: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      onChecked (val): void {
        this.$emit('change', this.list)
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/scss/_variables.scss";

.checkboxlist {
  width: 75%;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line when there's not enough space */

}
.row {
  width: 100%;
  margin-left: 0; /* Unset the negative margin */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line when there's not enough space */
}
.checkbox {
  flex: 1; /* Make checkboxes stretch and fill up the full width of the row */
  margin-right: 10px; /* Add some spacing between checkboxes */
  margin-bottom: 10px; /* Add some spacing between rows */

  /* Add any other styling for your individual checkboxes here */
}

</style>
