<template>
  <div class="spr-simple-radio" :class="{ 'spr-danger': !isValid }" @click="onRadioClick">
    <input :id="id" type="radio" :value="value" :checked="isChecked" :name="name">
    <label :for="id">{{ title }}</label>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  export default Vue.extend({
    name: 'SprSimpleRadio',
    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: [String, Number, Boolean],
        required: true
      },
      modelValue: {
        type: [String, Number, Boolean],
        required: true
      },
      id: {
        type: String,
        default: ''
      },
      isValid: {
        type: Boolean,
        default: true
      },
      name: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
      }
    },
    computed: {
      isChecked (): boolean {
        return this.value === this.modelValue
      }
    },
    methods: {
      onRadioClick (): void {
        this.$emit('input', this.value)
      }
    }
  })
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .spr-simple-radio {
    //move real radio off the screen
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        display: inline-block;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 10px;
        height: 10px;
        background: $brand-red;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
  }

</style>
