import Vue from 'vue'
import VueResource from 'vue-resource'
import moment from 'moment'

Vue.use(VueResource)

const validatorDateIsAfter = {
  getMessage (field, args) {
    return `${field} must be later than ${moment(args[0], ['DD.MM.YYYY', 'YYYY-MM-DDTHH:mm:ss']).format('DD.MM.YYYY')}.`
  },
  validate (value, args) {
    return moment(value, ['DD.MM.YYYY', 'YYYY-MM-DDTHH:mm:ss']) >= moment(args[0], ['DD.MM.YYYY', 'YYYY-MM-DDTHH:mm:ss'])
  }
}

export default validatorDateIsAfter
