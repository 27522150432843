<template>
  <div class="comp-wizard-content container join-container">
    <div class="row mx-auto">
      <div class="col-12">
        <span class="h1"
              role="heading"
              aria-level="1"
        >{{ $t('join.selectionHeader') }}</span>
      </div>
      <div class="col-12">
        <sprvalidation class="validation-box"
                       :validation-errors="validationErrors"
        />
      </div>
      <div class="col-12">
        <p>
          {{ $t('join.selectionDescription') }}
        </p>
      </div>
    </div>

    <div class="row mx-auto">
      <div class="col-12">
        <div v-for="(p, i) in membershipsFiltered" :key="i">
          <input :id="'product_' + i"
                 v-model="membershipType"
                 type="radio"
                 name="products"
                 :value="p.productCode"
          >
          <label :class="{
                   'membership': true,
                   selected: membershipType === p.productCode }"
                 :for="'product_' + i"
          >

            <span v-if="showSwedish" class="product">{{ p.descriptionSwe }}</span>
            <span v-else class="product">{{ p.descriptionFin }}</span>
            <span class="price">{{ p.priceCents / 100 }}€ <span v-if="p.annual">/ Vuosi</span></span>
          </label>
        </div>
      </div>
    </div>

    <div class="row mx-auto text-left">
      <div class="col-12">
        <p>
          {{ $t('join.numberOfSections').replace('{number}', sections.length) }}
        </p>
      </div>
    </div>

    <div class="row mx-auto">
      <div class="col-12">
        <dropdown v-model="value.section"
                  title=""
                  :items="sections"
                  :is-valid="true"
        />
      </div>
    </div>

    <div class="row mx-auto">
      <div class="col-12 text-left">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-linkified:options="{ nl2br: true }" class="smaller" v-html="$t('join.gdpr')" />
      </div>
    </div>

    <div class="row mx-auto text-left">
      <div class="col-12">
        <p>
          {{ $t('join.selectionConsentMarket') }}
        </p>
      </div>
    </div>
    <div class="row mx-auto">
      <div class="col-12 text-left">
        <checkbox v-model="value.marketingEmail"
                  :title="$t('join.withMail')"
        />
      </div>
    </div>

    <div class="row mx-auto">
      <div class="col-12 text-left">
        <checkbox v-model="value.marketingSms"
                  :title="$t('join.withSms')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import sprvalidation from '../SPRValidationError.vue'
  import checkbox from '../SPRSimpleCheckbox.vue'
  import dropdown from '../SPRSimpleDropdown.vue'
  import dates from '../../mixins/dates.js'

  export default {
    name: 'JoinSection',
    components: { sprvalidation, checkbox, dropdown },
    mixins: [dates],
    props: {
      value: {
        type: Object,
        required: true
      },
      memberships: {
        type: Array,
        default: () => []
      }
    },
    data: function () {
      return {
        membershipType: ''
      }
    },
    computed: {
      membershipsFiltered: function () {
        var self = this
        return _.orderBy(_.filter(this.memberships, function (x) {
          if (x.minAge || x.maxAge) {
            // Offset by +1 day because youth membership should be valid thru the day the person turns 29.
            var age = self.calculateAge(self.value.personal.age, { days: +1 })
            if (x.minAge && age < x.minAge) {
              return false
            }
            if (x.maxAge && age > x.maxAge) {
              return false
            }
          }
          return true
        }), ['priceCents'], ['asc'])
      },
      validationErrors: function () {
        if (!this.validatedFields) {
          return []
        }

        var arr = []
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['', err.field + '_' + err.rule, ''])
          }
        })

        if (self.registerErrors) {
          self.registerErrors.forEach(function (registrationError) {
            arr.push(registrationError)
          })
        }

        return arr
      },
      sections: function () {
        return _.orderBy(_.filter(_.map(this.$store.state.joinsections, function (x) {
          return { value: x.name, text: x.name.replace('SPR ', '').replace('FRK ', '') }
        }), function (x) {
          return x.value !== 'Ei osastoa'
        }), function (x) {
          return x.text
        })
      },
      showSwedish: function () {
        return this.$i18n.locale === 'sv'
      }
    },
    watch: {
      membershipType: function (x) {
        // v-model just won't work for some reason. doing it manually then
        this.value.membershipType = x
      }
    },
    mounted: function () {
      if (this.membershipsFiltered && this.membershipsFiltered.length > 0) {
        this.membershipType = this.membershipsFiltered[0].productCode
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

  .join-container {
    .smaller {
               font-size: 0.75em;
             }

    .row {
      max-width: 600px;
      color: $brand-white;
    }

    input[type=radio] {
      display: none;
    }

    .membership {
      display: flex;
      text-align: left;
      border: 2px solid $brand-white;
      border-radius: 60px;
      padding: 10px 20px;
      justify-content: space-between;
      cursor: pointer;
      transition: background-color 0.3s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.selected {
        border-color: $brand-red;
        color: $brand-red;
        background-color: $brand-white;
        transition: border-color background-color 0.3s;
      }

      &:hover {
        border-color: $brand-red;
        transition: border-color 0.3s;
      }
    }
  }
</style>
