<template>
  <div class="container-fluid position-relative comp-front-anon">
    <div class="hero-image" />
    <div class="container position-relative">
      <div class="row head-2 brand-white flex-middle">
        <div class="col-12 mx-auto text-center mt-2 mt-md-5">
          <div class="main-header" role="heading" aria-level="2">
            {{ $t('frontPageRegistered.anonWelcome') }}
          </div>
        </div>
        <div class="col-12 mx-auto text-center">
          <div class="subheader">
            {{ $t('frontPageRegistered.anonCallToAction') }}
          </div>
        </div>
      </div>
      <div class="row head-3">
        <div class="col-12 text-center">
          <sprbutton type="primary"
                     :size="1"
                     :title="$t('frontPageRegistered.createProfile')"
                     class="spr-shadow margintop20"
                     click-event="to_wizard_start"
                     @to_wizard_start="toWizard"
          />
        </div>
      </div>
      <div class="row rise-block brand-red text-center">
        <!-- TODO: Localization -->
        <!-- TODO: Change placeholder icons! -->

        <div class="col col-12 col-sm-4 h6 py-4">
          <font-awesome-icon :icon="['far', 'heart']" />
          <span role="heading" aria-level="3">{{ $t('frontPageRegistered.tellAboutYourselfTitle') }}</span>
          <p>{{ $t('frontPageRegistered.tellAboutYourselfText') }}</p>
        </div>
        <div class="col col-12 col-sm-4 h6 py-4">
          <font-awesome-icon icon="binoculars" />
          <span role="heading" aria-level="3">{{ $t('frontPageRegistered.findSomethingToDoTitle') }}</span>
          <p>{{ $t('frontPageRegistered.findSomethingToDoText') }}</p>
        </div>
        <div class="col col-12 col-sm-4 h6 py-4">
          <font-awesome-icon :icon="['far', 'lightbulb']" />
          <span role="heading" aria-level="4">{{ $t('frontPageRegistered.makeEventsTitle') }}</span>
          <p>{{ $t('frontPageRegistered.makeEventsText') }}</p>
        </div>
      </div>
      <!-- Events -->
      <div class="row justify-content-center">
        <div class="col-12 event-title">
          <span role="heading" aria-level="3" class="h4">
            {{ $t('frontPageRegistered.events') }}
          </span>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="btn h6
                    col-12
                    col-md-12"
        >
          <div class="col-12">
            <sprbutton type="primary"
                       :size="1"
                       :title="$t('frontPageRegistered.search')"
                       class="spr-redborder button-width mb-2"
                       click-event="viewEventsWithAttribute"
                       @viewEventsWithAttribute="onViewAllEventsWithAttribute"
            />
          </div>
        </div>
      </div>
      <div class="row event-list">
        <div v-if="eventsLoading" class="brand-red text-center col-12 my-5">
          <loading />
        </div>
        <div v-for="sprevent in eventsToShow" v-else :key="sprevent.id" class="event col-sm-12 col-md-6 col-lg-4">
          <eventtile :sprevent="sprevent" />
        </div>
      </div>
      <div v-if="!hasEvents && !eventsLoading" class="row no-events text-center">
        <div class="col-12">
          <span>{{ $t('frontPageRegistered.noEventsFound') }}</span>
        </div>
      </div>
      <div v-if="moreEventsToShow && !eventsLoading" class="row more-events">
        <div class="col-12 text-center">
          <sprbutton type="primary"
                     :size="4"
                     :title="$t('frontPageRegistered.showMoreEvents')"
                     class="show-more"
                     click-event="showMore"
                     @showMore="onShowMoreEventsClick"
          />
        </div>
      </div>
      <div v-if="hasEvents" class="row">
        <div class="col-12 text-center">
          <sprbutton type="primary"
                     :size="1"
                     :title="$t('frontPageRegistered.allEvents')"
                     class="spr-redborder button-width mb-2"
                     click-event="viewEvents"
                     @viewEvents="onViewAllEvents"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from './SPRButton.vue'
  import eventtile from './events/EventTile.vue'
  import searchbox from './SPRSearchBox.vue'
  import loading from './SPRLoading.vue'
  import events from '../mixins/event.js'

  export default {
    name: 'FrontAnon',
    pageName: 'front-page-anonymous',
    components: { sprbutton, eventtile, searchbox, loading },
    mixins: [events],
    data () {
      return {
        events: [],
        eventsLoading: false,
        eventFilter: {
          search: '',
          take: 6,
          skip: 0
        },
        eventsToShowInitial: 6,
        take: 60
      }
    },
    computed: {
      hasEvents () {
        return this.events.length > 0
      },
      moreEventsToShow () {
        return this.eventsToShow.length < this.filteredEvents.length
      },
      filteredEvents () {
        if (this.eventFilter.search.length === 0) return this.events

        var self = this
        const filtered = _.filter(this.events, (evt) => {
          return self.eventTranslation(evt).name.toLowerCase().indexOf(self.eventFilter.search.toLowerCase()) > -1 ||
            evt.translations.some(elem => {
              return elem.city.toLowerCase().indexOf(self.eventFilter.search.toLowerCase()) > -1
            }) ||
            evt.zipCode.indexOf(self.eventFilter.search) > -1
        })
        return filtered
      },
      eventsToShow () {
        return _.take(this.filteredEvents, this.eventFilter.take)
      }
    },
    created () {
      this.getEvents()
    },
    methods: {
      toWizard () {
        this.$router.push({ name: 'wizard' })
      },
      getEvents () {
        var self = this
        self.eventsLoading = true

        const url = this.$store.state.apiBases.eventservice + this.$store.state.apiEndpoints.eventallpublic[0]
          .replace('{search}', '')
          .replace('{locale}', '')
          .replace('{district}', '')
          .replace('{section}', '')
          .replace('{eventcategory}', '')
          .replace('{eventtype}', '')
          .replace('{isonline}', '-1')
          .replace('{take}', self.take)
          .replace('{skip}', 0)

        this.$http.get(url).then(resp => {
          self.events = resp.body
          self.eventsLoading = false
        }, resp => {
          self.eventsLoading = false
        })
      },
      onViewAllEvents () {
        this.$router.push('/publicevents')
      },
      onViewAllEventsWithAttribute () {
        this.$router.push({ path: 'publicevents', query: { search: this.eventFilter.search } })
        // this.$router.push('/publicevents')
      },
      onSearchChanged (text) {
        this.eventFilter.search = text
      },
      onShowMoreEventsClick () {
        this.eventFilter.take += this.eventsToShowInitial
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  $heroheight: 600px;
  $herohover: 80px;
  $hilightheight: 220px;

  $head: $heroheight - $herohover;
  $domain: $head / 4;
  $header: $head / 2;
  $button: $head / 4;

  .comp-front-anon {
    min-height: $heroheight;
    .main-header {
      font-family: "Signa-Bold", Sans-Serif;
      font-size: 50px;
      line-height: 60px;
      color: $brand-white;
      display: inline-block;

      @media (max-width: $media-sm-max) {
        font-size: 25px;
        line-height: 35px;
      }
    }

    .subheader {
      font-size: 150%;
      line-height: normal;

      @media (max-width: $media-sm-max) {
        font-size: 130%;
      }
    }

    .hero-image {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      height: $heroheight;
      background-image: url(../assets/img/vati-fp-hero-image.jpg);
      background-repeat: no-repeat;
      background-position: center center;
    }

    .rise-block {
      background-color: $brand-white;
      margin-bottom: 40px;
      z-index: 1;
    }

    .head-1 {
      height: $domain;
    }

    .head-2 {
      height: $header;
    }

    .head-3 {
      height: $button;
    }

    .h6 {
      p {
        margin-top: 20px;
        display: block;
      }
    }

    .event-title {
      text-align: center;
      color: $brand-red;
    }

    .event-filter {
      text-align: center;
      a {
        color: $brand-grey8;
        cursor: pointer;
        padding: 10px 5px;
        display: inline-block;
        &.selected {
          color: $brand-red;
          font-weight: bold;
        }
      }
    }

    .event-search {
      margin-bottom: 30px;
    }

    .event-actions {
      margin-bottom: 15px;
      .col-sm-12 {
        margin-bottom: 10px;
      }
      .col-sm-12:last-child {
        margin-bottom: 0;
      }
    }

    .event-list {
      .event {
        background-color: $brand-grey1;
      }
    }

    .no-events {
      color: $brand-font;
      margin-bottom: 15px;
    }

    .more-events {
      margin-bottom: 30px;
      .show-more {
        background-color: $brand-red;
        color: $brand-white;
      }
    }

    .flex-middle {
      display: flex;
      align-items: center;
    }

    .margintop20 {
      margin-top: 20px;
    }
  }
</style>
