<template>
  <div class="startstandard">
    <div class="row">
      <div class="col-12">
        <div class="backimagestandard"></div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="maincontentstandard">
          <div class="container">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-6 titlestandard">
                <h3 class="whitebackground">
                  {{ $t('customerForm.newStandardCustomerTitle') }}
                </h3>
              </div>
              <div class="col-3"></div>
              <div class="col-3"></div>
              <div class="col-6 subtitlestandard">
                <div class="whitebackground">
                  {{ $t('customerForm.newStandardCustomerSubTitle') }}
                </div>
              </div>
            </div>
            <div class="row instructions">
              <div class="col-12">
                <div class="cap-label">
                  {{ $t('customerForm.newStandardCustomerInstructionsTitle') }}
                </div>
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="$t('customerForm.newStandardCustomerText')"></div>
              </div>
              <div class="col-12" style="margin-top: 30px;">
                <span class="or-span">{{ /*$t('wizard.orBySectionPostcode')*/ }}</span>
              </div>
              <div class="col-12 col-sm-10 col-md-6 col-lg-4 offset-sm-1 offset-md-3 offset-lg-4" style="margin-top: 30px;">
                <sprautocomplete
                  v-model="sectionZip"
                  v-validate="'required'"
                  :options="sectionsZips"
                  option-label="value"
                  :placeholder="$t('wizard.zipCodeField')"
                  :required="true"
                  name="zipCodeField"
                  :is-valid="!errors.has('zipCodeField')"
                  :max-results="200"
                  :show-arrow="false"
                  :show-default-options="false"
                  dark
                />
              </div>
              <div class="col-12 buttons">
                <sprbutton
                  :size="4"
                  :title="$t('wizard.next')"
                  :primary="true"
                  icon="chevron-right"
                  click-event="start"
                  @start="onStartClicked"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  import * as _ from 'lodash'
  import sprautocomplete from '../SPRAutocompleteDropdown.vue'
  import locationutils from '../../mixins/locationutils.js'
  import googlemaps from '../../mixins/googlemaps.js'
  import { StringKeyValuePair } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    zipCode: string,
    zipCodeChanged: boolean,
    sectionsZips: Array<StringKeyValuePair>,
    sectionZip: StringKeyValuePair | undefined,
    section: number
  }
  export default Vue.extend({
    name: 'Start',
    components: { sprbutton, sprautocomplete },
    mixins: [locationutils, googlemaps],
    data (): TemplateComponentData {
      return {
        zipCode: this.$store.state.wizardModel.workingZipCode,
        zipCodeChanged: false,
        sectionsZips: [],
        sectionZip: undefined,
        section: this.$store.state.wizardModel.sectionId
      }
    },
    watch: {
      zipCode (val): void {
        // set the sectionszips dropdown. Key is in format zip-sectionid
        if (val) {
          var secs = _.filter(this.sectionsZips, function (sz) {
            return val === sz.key.split('-')[0]
          })

          if (secs.length === 1) {
            this.sectionZip = secs[0]
          } else if (secs.length > 1) {
            // if there are 2 sections with this zip code, look at the users language and set the section to the one matching that
            var desiredLanguageId = 2
            if (this.$store.state.language === 'sv') desiredLanguageId = 1
            if (this.$store.state.language === 'fi') desiredLanguageId = 0
            if (this.$store.state.language === 'en') desiredLanguageId = 0

            for (var i = 0; i < secs.length; i++) {
              var section = _.find(this.$store.state.sections, function (s) {
                return s.id === Number(secs[i].key.split('-')[1])
              })

              if (section.language === desiredLanguageId) {
                this.sectionZip = secs[i]
                break
              }
            }
          } else {
            // no section found for this postcode. Shouldn't happen though.
            return
          }

          this.zipCodeChanged = true
        }
      },
      sectionZip (val) {
        if (!val) {
          this.$store.commit('setWizardWorkingZip', null)
          this.$store.commit('setWizardZipcode', null)
          this.$store.commit('setWizardSectionId', null)
        } else {
          var arr = val.key.split('-')
          this.$store.commit('setWizardWorkingZip', arr[0])
          this.$store.commit('setWizardZipcode', arr[0])
          this.$store.commit('setWizardSectionId', Number(arr[1]))
        }
      }
    },
    created () {
      var sections = [] as Array<StringKeyValuePair>
      _.each(this.$store.state.joinablezipcodes, (section) => {
        _.each(section.zipCodes, (zip) => {
          sections.push({ key: `${zip}-${section.friendManagementGroupId}`, value: `${zip} - ${section.name}` } as StringKeyValuePair)
        })
      })

      this.sectionsZips = _.sortBy(sections, 'value')
    },
    methods: {
      onStartClicked (): void {
        const parts = this.sectionZip?.key.split('-')
        if (parts && parts.length > 1) {
          const partAfterDash = parseInt(parts[1])
          this.$emit('next', partAfterDash)
        } else {

        }
        //this.$emit('next', 0)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .startstandard {
    .backimagestandard {
      width: 100%;
      height: 1000px;
      overflow: hidden;
      background: url('https://sprvatistorage.blob.core.windows.net/eventimages/spr_brand_hki_ok_35.jpg') center/cover no-repeat fixed;

      img {
        width: 100%;
      }
    }
    .maincontentstandard {
      margin-top: -800px;

      .whitebackground {
        background-color: $brand-white;
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
      }
      .titlestandard, .subtitlestandard {
        text-align: center;
        color: $brand-red;
        width: 100%;
        margin-left: 5%;
        //background-color: $brand-white;
      }

      .subtitlestandard {
        margin-bottom: 50px;
        margin-left: 30%;
        display: inline-block;
      }

      .instructions {
        background-color: $brand-white;
        padding-top: 30px;
        padding-bottom: 30px;

        .cap-label {
          color: $brand-red;
          margin-bottom: 15px;
        }

        .buttons {
          margin-top: 30px;
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }
  }

</style>