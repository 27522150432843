<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .useradmin {
    padding-top: 50px;
    width: 100%;

    .h4 {
      margin-bottom: 20px;
    }

  .useradmin-list, .useradmin-messages, .useradmin-groups, .useradmin-events {
    margin-bottom: 50px;
  }

  .volunteeradmin-header {
    margin-bottom: 30px;
  }

  .spr-simple-dropdown.pb-3 {
    padding-bottom: 0 !important;
  }

  .spr-button {
    box-shadow: none;
    &.spr-redborder {
      border: 2px solid $brand-red;
    }
    &.button-small {
      font-size: 12px;
    }
   }

  .align-right {
    text-align: right;
  }

  .useradmin-list-new {
    margin-bottom: 30px;
    span.h5
      {
        color: #000;
        display: inline-block;
        padding: 10px 20px 10px 15px;
        margin: 0;
        cursor: pointer;
      }
  }

  .tab-content {
    &.main {
     padding: 15px;
    }
  }

   .user-row {
    background: $brand-white;
    width: 100%;
    margin: 0 0 5px 0;
    padding: 10px 0;
    font-size:13px;
    align-items: center;
    justify-content: center;
    > .row
    {
      align-items: center;
      justify-content: center;
    }

    strong {
      font-weight: bold;
    }

    .column
      {
        padding: 15px 10px;
      }
      .user-groups {
        margin: 10px 0 0 0;
        font-size:13px;
      }
      .user-role {
        text-align: right;
        font-size: 14px;
        font-weight: bold;
        display: block;
        position: relative;
        padding-right: 20px;
        span{
              display: inline-block;
            }
        svg {
            color: $brand-red;
            cursor: pointer;
            padding-left: 10px;
            font-size: 16px!important;
            position: absolute;
            right: 0;
            top: 2px;
             }
      }
      .user-img {
        float: left;
        margin-right: 20px;
      }
      .portrait-size {
        width: 50px;
        height: 50px;
      }
      .user {
        font-size:13px;
        a {
          color: $brand-red;
          font-size: 13px;
          font-family:$font-light;
          font-weight:bold;
          display: block;
          text-transform: uppercase;
        }
      }

      .actions {
        .form-group {
          margin-bottom: 0;
        }
      }
    }
  .useradmin-tabs {
    border-bottom: 1px solid #C8C8C8;
    margin-bottom: 10px;
    h4 {
      display: inline-block;
      margin-bottom: -1px;
      font-size: 20px;
      a {
        font-family: $font-bold;
        color: $brand-grey8;
        padding: 10px 15px;
        display: block;
        &.selected {
          color: $brand-red;
          border-bottom: 3px solid $brand-red;
          padding: 10px 15px;
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  &.main {
    border-bottom: 1px solid $brand-grey4;
    margin-bottom: 20px;
    h4 {
      display: inline-block;
      margin-bottom: -1px;
      font-size: 28px;
    }
  }
 }
 .groups,
 .departments {
   margin-top:10px;
 }

 .white-block {
   background: $brand-white;
   padding: 15px;
  }
 .useradmin-messages {
   margin-bottom: 40px;
  }
  .useradmin-groups {
    margin-bottom: 40px;
  }

  .list-group {
    .row {
       margin: 0;
    }
  .table-header {
    padding: 10px;

  }

  .list-row {
    padding: 10px 15px;
    .name {
      color: $brand-red;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .district {
      float: right;
    }
  }

  .group-row, .list-row {
    button {
      min-height: 45px!important;
      padding: 0 20px!important;
     }
    .column
  {
    padding: 15px 10px 15px 10px;
    &.group-activate {
      padding: 5px 10px;
                     }
  }
  &:nth-of-type(even) {
    background: rgba(0,0,0,0.03);
  }
  }

  .event-row {
    .column {
      padding: 15px 10px 15px 10px;
    }
    .time {
      display: block;
    }
    &:nth-of-type(even) {
      background: rgba(0,0,0,0.03);
    }
  }
 }

  @media (max-width: $container-sm) {
    .useradmin-events {
      .table-header {
          display: none;
       }
      .event-row {
         padding: 15px 0;

        .column {
          width: 100% !important;
          float: none;
          display: block;
          max-width: 100%;
          flex: 0 0 100%;
          padding: 0 15px;
          a{
             font-size: 18px;
           }
           &.event-time {
             opacity: 0.8;
             font-size: 14px;
             margin-top: -5px;
           }
        }
        .time {
          display: inline;
        }
      }
     }
  }

  @media (max-width: $media-sm-max) {

    .useradmin-tabs.main {
      h4 {
           display: block;
           text-align:center;
           a{
               display: inline-block;
              font-size: 24px;
            }
         }
    }

    .list-group {
      .table-header {
        display: none;
      }
      .group-row {
        padding-top: 15px;
        padding-bottom: 15px;
        .group-name {
          a{
             font-size: 18px;
           }
        }
      }
      .column {
        padding: 3px 10px!important;
        &.group-activate {
          padding-top: 12px!important;
                         }
      }
      .align-right {
        text-align: center;
      }
    }

  .user-row {
    .interests {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .useradmin-messages {
    button {
        width: 100%;
        margin-bottom: 15px;
      }
    }

  }

}

</style>

<template>
  <div class="useradmin">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Hallintasivu</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac ipsum sed est blandit efficitur. Quisque quis tincidunt nunc. Integer facilisis diam risus. Aliquam nibh quam, viverra a lacus consequat, finibus mattis erat. Nulla nisi metus, tempus nec vestibulum ac.</p>
        </div>
      </div>
    </div>

    <div class="useradmin-tabs main">
      <div class="container">
        <h4><a :class="{ 'selected' : mainSearchType == 'volunteerRelated' }" href="javascript:void(0)" @click="showMainSearchType('volunteerRelated')">{{ $t('userAdmin.administerVolunteerInfo') }}</a></h4>
        <h4><a :class="{ 'selected' : mainSearchType == 'departmentRelated' }" href="javascript:void(0)" @click="showMainSearchType('departmentRelated')">{{ $t('userAdmin.administerDepartmentInfo') }}</a></h4>
      </div>
    </div>

    <div class="container">
      <div v-if="mainSearchType === 'volunteerRelated'" class="tab-content main">
        <div class="row">
          <div class="col col-md-6 col-12">
            <span role="heading" aria-level="3" class="h4 brand-black volunteeradmin-header">{{ $t('userAdmin.manageVolunteers') }}</span>
          </div>
          <div class="col col-md-6 col-12 align-right">
            <sprbutton type="primary"
                       :size="1"
                       :title="$t('userAdmin.addVolunteer')"
                       class="spr-redborder button-width"
                       icon="plus"
                       click-event="createVolunteer"
                       @createVolunteer="createVolunteerButton"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <searchbox v-model="searchText" :placeholder="placeholder" />
          </div>
        </div>

        <div class="useradmin-list-new">
          <div class="row">
            <div class="col col-12">
              <span role="heading" aria-level="4" class="h5 brand-black">{{ $t('userAdmin.newVolunteers') }}</span>

              <useradminvolunteerslist :list="newvolunteers" />
            </div>
          </div>
        </div>

        <div class="useradmin-list">
          <div class="row">
            <div class="col col-12">
              <div class="useradmin-tabs">
                <h4><a :class="{ 'volunteertab' : true, 'selected' : volunteerSearchType == 'ALL' }" href="javascript:void(0)" @click="showVolunteerSearchType('ALL')">{{ $t('userAdmin.myDepartmentVolunteers') }}</a></h4>
                <h4><a :class="{ 'volunteertab' : true, 'selected' : volunteerSearchType == 'KEY' }" href="javascript:void(0)" @click="showVolunteerSearchType('KEY')">{{ $t('userAdmin.keyVolunteers') }}</a></h4>
              </div>

              <div v-if="volunteerSearchType === 'ALL'" class="all-colunteers">
                <useradminvolunteerslist :list="allvolunteers" />
              </div>
              <div v-if="volunteerSearchType === 'KEY'" class="key-colunteers">
                <useradminvolunteerslist :list="keyvolunteers" />
              </div>
            </div>
          </div>
        </div>

        <div class="useradmin-messages">
          <div class="row">
            <div class="col col-12">
              <span role="heading" aria-level="3" class="h4 brand-black">{{ $t('userAdmin.messages') }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col col-12">
              <sprbutton type="primary"
                         :size="1"
                         :title="$t('userAdmin.sendMessage')"
                         class="spr-redborder button-width"
                         click-event="sendMessage"
                         @sendMessage="sendMessageButton"
              />
              <sprbutton type="primary"
                         :size="1"
                         :title="$t('userAdmin.sendLeaderMessage')"
                         class="spr-redborder button-width"
                         click-event="sendLeaderMessage"
                         @sendLeaderMessage="sendLeaderMessageButton"
              />
            </div>
          </div>
        </div>

        <div class="useradmin-groups">
          <div class="row">
            <div class="col col-12">
              <span role="heading" aria-level="3" class="h4 brand-black">{{ $t('userAdmin.groups') }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-12">
              <searchbox v-model="searchText" :placeholder="placeholdergroups" />
            </div>
          </div>

          <div class="row">
            <div class="col col-12">
              <div class="white-block groups">
                <div class="row">
                  <div class="col col-md-6 col-12">
                    <span role="heading" aria-level="4" class="h5 brand-black">{{ $t('userAdmin.actionGroups') }}</span>
                  </div>
                  <div class="col col-md-6 col-12 align-right">
                    <sprbutton type="primary"
                               :size="1"
                               :title="$t('userAdmin.createActionGroup')"
                               class="spr-redborder button-width"
                               icon="plus"
                               click-event="createActionGroup"
                               @createActionGroup="createActionGroupButton"
                    />
                  </div>
                </div>

                <useradmingrouplist :list="grouplist" />

                <div class="row">
                  <div class="col col-12">
                    <span role="heading" aria-level="4" class="h5 brand-black">{{ $t('userAdmin.otherGroups') }}</span>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-12">
                    <useradmingrouplist :list="grouplist" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="useradmin-events">
          <div class="row">
            <div class="col col-12">
              <span role="heading" aria-level="3" class="h4 brand-black">{{ $t('userAdmin.events') }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-12">
              <searchbox v-model="searchText" :placeholder="placeholderevents" />
            </div>
          </div>

          <div class="row">
            <div class="col col-12 col-12">
              <div class="useradmin-tabs">
                <h4><a :class="{'selected' : eventSearchType == 'UPCOMING' }" href="javascript:void(0)" @click="showEventSearchType('UPCOMING')">{{ $t('userAdmin.upcomingEvents') }}</a></h4>
                <h4><a :class="{'selected' : eventSearchType == 'PAST' }" href="javascript:void(0)" @click="showEventSearchType('PAST')">{{ $t('userAdmin.pastEvents') }}</a></h4>
              </div>

              <div v-if="eventSearchType === 'UPCOMING'" class="tab-content">
                <div class="white-block">
                  <useradmineventlist :list="upcomingEventsList" />
                </div>
              </div>

              <div v-if="eventSearchType === 'PAST'" class="tab-content">
                <div class="white-block">
                  <useradmineventlist :list="pastEventsList" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="mainSearchType === 'departmentRelated'" class="tab-content main">
        <div class="row">
          <div class="col col-md-6 col-12">
            <span role="heading" aria-level="3" class="h4 brand-black">Hae osastoja</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-12">
            <searchbox v-model="searchText" :placeholder="placeholderdepartment" />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="white-block departments">
              <div class="list-group">
                <useradmindepartment v-for="(department) in departmentList"
                                     :key="department.id"
                                     :department="department"
                                     click-event="departmentPage"
                                     @departmentPage="departmentPageLink"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import searchbox from '../SPRSearchBox.vue'
  import useradmingrouplist from './GroupList.vue'
  import useradmineventlist from './EventList.vue'
  import useradminvolunteerslist from './AdminVolunteersList.vue'
  import useradmindepartment from './DepartmentView.vue'

  export default {
    name: 'Useradmin',
    components: { sprbutton, searchbox, useradmingrouplist, useradmineventlist, useradminvolunteerslist, useradmindepartment },
    data () {
      return {
        volunteer: {
          roles: '0'
        },
        mainSearchType: 'volunteerRelated',
        volunteerSearchType: 'ALL',
        eventSearchType: 'UPCOMING',
        searchText: '',
        placeholder: this.$t('customerSearch.searchPlaceholder'),
        placeholdergroups: this.$t('userAdmin.groupSearchPlaceholder'),
        placeholderevents: this.$t('userAdmin.eventSearchPlaceholder'),
        placeholderdepartment: this.$t('userAdmin.departmentSearchPlaceholder'),
        grouplist: [
          {
            id: 1,
            title: 'Varainkeruun toimintaryhmä',
            leader: 'Anna Auttaja'
          },
          {
            id: 2,
            title: 'Toimintaryhmä 2',
            leader: 'Ville välittäjä'
          },
          {
            id: 3,
            title: 'Toimintaryhmä XYZ',
            leader: 'Ville välittäjä'
          }
        ],
        upcomingEventsList: [
          {
            id: 1,
            title: 'Varainkeruun toimintaryhmä',
            date: '12.10.2017',
            time: '11:00',
            location: 'Tapiola',
            category: 'Kategoria',
            type: 'Kurssit ja koulutus',
            organizer: 'Ville Vapaaehtoinen'
          },
          {
            id: 2,
            title: 'Varainkeruun toimintaryhmä',
            date: '12.10.2017',
            time: '11:00',
            location: 'Tapiola',
            category: 'Kategoria',
            type: 'Kurssit ja koulutus',
            organizer: 'Ville Vapaaehtoinen'
          },
          {
            id: 3,
            title: 'Varainkeruun toimintaryhmä',
            date: '12.10.2017',
            time: '11:00',
            location: 'Tapiola',
            category: 'Kategoria',
            type: 'Kurssit ja koulutus',
            organizer: 'Ville Vapaaehtoinen'
          }
        ],
        pastEventsList: [
          {
            id: 1,
            title: 'Eventti',
            date: '12.10.2017',
            time: '11:00',
            location: 'Tapiola',
            category: 'Kategoria',
            type: 'Kurssit ja koulutus',
            organizer: 'Ville Vapaaehtoinen'
          },
          {
            id: 2,
            title: 'Eventti 2',
            date: '12.10.2017',
            time: '11:00',
            location: 'Tapiola',
            category: 'Kategoria',
            type: 'Kurssit ja koulutus',
            organizer: 'Ville Vapaaehtoinen'
          },
          {
            id: 3,
            title: 'Varainkeruun toimintaryhmä',
            date: '12.10.2017',
            time: '11:00',
            location: 'Tapiola',
            category: 'Kategoria',
            type: 'Kurssit ja koulutus',
            organizer: 'Ville Vapaaehtoinen'
          }
        ],
        newvolunteers: [
          {
            id: 1,
            name: 'Ville Välittäjä',
            interests: 'musiikki',
            group: 'Ryhmä ABC'
          },
          {
            id: 2,
            name: 'Anna Auttaja',
            interests: 'lorem ipsum',
            group: 'Ryhmä ABC, Toimintaryhmä B'
          }
        ],
        keyvolunteers: [
          {
            id: 1,
            name: 'Ville Välittäjä',
            interests: 'musiikki',
            group: 'Ryhmä ABC'
          },
          {
            id: 2,
            name: 'Anna Auttaja',
            interests: 'lorem ipsum',
            group: 'Ryhmä ABC, Toimintaryhmä B'
          }
        ],
        allvolunteers: [
          {
            id: 1,
            name: 'Ville Välittäjä',
            interests: 'musiikki',
            group: 'Ryhmä ABC'
          },
          {
            id: 2,
            name: 'Anna Auttaja',
            interests: 'lorem ipsum',
            group: 'Ryhmä ABC, Toimintaryhmä B'
          }
        ],
        departmentList: [
          {
            id: 1,
            name: 'Kokkola',
            district: 'Länsi-Suomen piiri',
            link: '/front'
          },
          {
            id: 2,
            name: 'Lohtaja',
            district: 'Länsi-Suomen piiri',
            link: '/front'
          },
          {
            id: 3,
            name: 'Petäjävesi',
            district: 'Länsi-Suomen piiri',
            link: '/front'
          },
          {
            id: 4,
            name: 'Seinäjoki',
            district: 'Länsi-Suomen piiri',
            link: '/front'
          }
        ]
      }
    },
    methods: {
      createVolunteer: function () {
        console.log('createVolunteer clicked')
      },
      sendMessageButton: function () {
        console.log('sendMessage clicked')
      },
      sendLeaderMessageButton: function () {
        console.log('sendLeaderMessage clicked')
      },
      activateGroupButton: function () {
        console.log('activateGroup clicked')
      },
      createActionGroupButton: function () {
        console.log('createActionGroup clicked')
      },
      showMainSearchType (type) {
        this.mainSearchType = type
      },
      showVolunteerSearchType (type) {
        this.volunteerSearchType = type
      },
      showEventSearchType (type) {
        this.eventSearchType = type
      }
    }
  }
</script>
