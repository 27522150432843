<template>
  <div class="comp-section-list">
    <div class="row search">
      <div class="col-12 col-sm-9 col-md-7 col-lg-5">
        <searchbox v-model="search" :placeholder="placeholder" />
      </div>
      <div class="col-12 col-sm-3 col-md-5 col-lg-7 text-right">
        <sprbutton title="Export CSV" :size="5" click-event="export" @export="onExportClick" />
      </div>
    </div>
    <div v-if="sections" class="listing">
      <div v-for="(d, i) in filteredItems" :key="i" class="listing-row">
        <router-link :to="'/admin/section/' + d.id + '/user'">
          {{ d.name }}
        </router-link>
      </div>
    </div>
    <div v-else class="loading text-center brand-red">
      <div class="my-2">
        <loading />
      </div>
      <div class="my-3">
        <p>{{ $t('userAdmin.loading') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import searchbox from '../SPRSearchBox.vue'
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'
  export default {
    name: 'AdminSectionList',
    components: { searchbox, sprbutton, loading },
    props: {
      primary: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      filter: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {
        sections: null,
        placeholder: this.$t('userAdmin.searchSectionPlaceholder'),
        search: ''
      }
    },
    computed: {
      uri: function () {
        var rv = this.$store.state.apiBases.userservice
        if (!this.id) {
          rv += this.$store.state.apiEndpoints.sections[0]
        } else {
          rv += this.$store.state.apiEndpoints.sectionsbydistrict[0]
          rv = rv.replace('{0}', this.id)
        }

        return rv
      },
      filteredItems () {
        if (this.search === '') {
          return this.sections
        }
        return this.sections.filter(item => {
          return item.name.toLowerCase().concat(' ' + item.name.toLowerCase()).indexOf(this.search.toLowerCase()) > -1
        })
      }
    },
    mounted: function () {
      this.$http.get(this.uri).then(success => {
        this.sections = success.body
      })
    },
    methods: {
      onExportClick () {
        var writeTitles = () => {
          return '"Postinumero","Osasto","Piiri","Kieli"\r\n'
        }

        var wrapQuotesAndComma = (content) => {
          var containsNumber = !isNaN(content)
          if (containsNumber) { // force excel to render as number
            return '"=""' + (content ? content.toString().replace('"', '""') : '') + '""",'
          } else {
            return '"' + (content ? content.toString().replace('"', '""') : '') + '",'
          }
        }

        var padZeros = (content, numZeros) => {
          content = content.toString()
          return content.length < numZeros
            ? new Array((numZeros + 1) - content.length).join('0') + content
            : content
        }

        var writeLanguage = (lang) => {
          switch (lang.toLowerCase()) {
          case 'swedish':
            return 'ruotsi'
          case 'finnish':
            return 'suomi'
          case 'bilingual':
            return 'kaksikielinen'
          default:
            return ''
          }
        }
        var writeContent = (data) => {
          var content = ''
          var newLine = '\r\n'
          for (var i = 0; i < data.length; i++) {
            var row = data[i]
            content += wrapQuotesAndComma(padZeros(row.postcode, 5))
            content += wrapQuotesAndComma(row.sectionName)
            content += wrapQuotesAndComma(row.districtName)
            content += wrapQuotesAndComma(writeLanguage(row.language))
            content = content.slice(0, -1) // chop last comma
            content += newLine
          }

          return content.slice(0, -1)
        }

        function JSONToCSVConvertor (data) {
          var dataForConverting = typeof data !== 'object' ? JSON.parse(data) : data

          var d = new Date()
          var fileDateStr = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}-${d.getHours()}-${d.getMinutes() < 10 ? ('0' + d.getMinutes()) : d.getMinutes()}`
          var exportFileName = `osastot-${fileDateStr}`
          var csvResult = 'SEP=,\r\n'

          csvResult += writeTitles()
          csvResult += writeContent(dataForConverting)

          // IE 10+
          if (navigator.msSaveOrOpenBlob) {
            var utf8Text = csvResult
            var ab = new ArrayBuffer(utf8Text.length)
            var uint8Array = new Uint8Array(ab)
            for (var j = 0; j < uint8Array.length; j++) {
              var charCode = utf8Text[j].charCodeAt(0)
              if (charCode > 256) {
                charCode = 182 // ¶
              }
              uint8Array[j] = charCode
            }
            navigator.msSaveOrOpenBlob(new Blob([uint8Array], { type: 'text/csv;charset=ISO-8859-1;' }), exportFileName + '.csv')
          } else {
            var link = document.createElement('a')
            var uri = 'data:text/csv;charset=utf-8,' + escape(csvResult)
            link.href = uri
            link.style = 'visibility:hidden'
            link.download = exportFileName + '.csv'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        }

        var sections = this.filteredItems.map(x => x.id)
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.sectionsexport[0]
        this.$http.post(url, sections).then((response) => {
          JSONToCSVConvertor(response.data)
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .comp-section-list {
    padding: 0 15px 15px;

    .listing {
      background: $brand-white;
      display: flex;
      display: -ms-flexbox;
      flex-direction: column;
      -ms-flex-direction: column;

      .listing-row {
        padding: 10px 15px;
        color: $brand-red;

        &:nth-child(even) {
          background: rgba(0,0,0,0.03);
        }

        a {
          color: $brand-red;
        }
      }
    }
  }
</style>
