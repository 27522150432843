const bitmaskhelper = {
  /// Converts an integer flag value into an array of numbers
  /// e.g. 63 => [1,2,4,8,16,32]
  bitmaskToArray(integer: number): Array<number> {
    if (integer <= 0) {
      return [] as Array<number>
    }

    var rv = [] as Array<number>
    for (var i = 0; i < 32; i++) {
      var mask = Math.pow(2, i)
      if ((integer & mask) !== 0) {
        rv.push(mask)
      }
    }

    return rv
  },
  //returns a sum of the values in the array e.g. [1,2,4,8,16,32] => 63
  arrayToBitmask(arr: Array<number>): number {
    return arr.reduce((a, b) => a + b, 0)
  },

  //this translates e.g. [1,2,3,4,5,6] => 1+2+4+8+16+32 = 63
  translateEnumsToBitmask(arr: Array<number>): number {
    let bitmask = 0

    for (const id of arr) {
      bitmask += Math.pow(2, id - 1)
    }

    return bitmask
  },
  //this translates e.g. [1,2,4,8,16,32] => [1,2,3,4,5,6]
  convertBitmaskToInteger(bitmaskValues: Array<number>): Array<number> {
    const regularIntegers: number[] = [];

    for (let bitmaskValue of bitmaskValues) {
      let exponent = 0;
      let currentBit = 1;

      while (currentBit <= bitmaskValue) {
        if (currentBit & bitmaskValue) { // Bitwise AND operation
          regularIntegers.push(exponent + 1);
        }
        currentBit *= 2;
        exponent++;
      }
    }

    return regularIntegers;
  },
  //this translates e.g. [1,2,3,4,5,6] => [1,2,4,8,16,32]
  convertIntegerToBitmask(regularIntegers) {
    const bitmaskValues: number[] = [];

    for (let regularInteger of regularIntegers) {
      bitmaskValues.push(Math.pow(2, regularInteger - 1));
    }

    return bitmaskValues;
  }
}

export default bitmaskhelper