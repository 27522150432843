<template>
  <div class="row volunteermatchdetail">
    <div class="col-6">
      <div class="img">
        <friendshipicon :friendname="friendship.customer.firstName" :state="friendship.friendshipState" />
      </div>
      <div class="summary">
        <div class="customername">
          <a href="javascript:void(0)" @click="selectCustomer">{{ friendship.customer.firstName }} {{ friendship.customer.lastName }}</a>
          <span v-if="isNetFriendship" class="netfriendship" :title="$t('customerSearch.netFriendship')">
            <font-awesome-icon icon="tv" />
          </span>
          <span v-else class="netfriendship" :title="$t('friendshipTypes.oneToOne')">
            <font-awesome-icon :icon="['far', 'handshake']" />
          </span>
        </div>
        <span class="">{{ genderAgeText }}</span>
        <div class="">
          {{ zipCityText }}
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="contact">
        <div class="">
          {{ customerState }}
        </div>
        <div class="">
          {{ startedDate }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import friendshipicon from './FriendshipIcon.vue'
  import enumhelper from '../../mixins/enums.js'
  import locationhelper from '../../mixins/locationutils.js'
  import moment from 'moment'
  import addresshelper from '@/helpers/addresshelper'
  import datehelper from '@/helpers/datehelper'
  import { FriendshipStandardDto, FriendshipNetDto, FriendManagementDto } from '@/types'

  export default Vue.extend({
    name: 'VolunteerMatchDetail',
    components: { friendshipicon },
    mixins: [enumhelper, locationhelper],
    props: {
      friendship: {
        type: Object as () => FriendshipNetDto | FriendshipStandardDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      }
    },
    computed: {
      computedGender (): LocaleMessage | string {
        // @ts-ignore Cannot find mixins
        return this.getGenderTranslation(this.friendship.customer.gender)
      },
      computedAge (): number {
        return datehelper.calculateAge(this.friendship.customer.birthDate)
      },
      genderAgeText (): LocaleMessage | string {
        return [this.computedGender, this.computedAge ? `${this.computedAge} ${this.$t('customerSearch.years')}` : ''].filter(Boolean).join(', ')
      },
      zipCityText (): string {
        return addresshelper.formatAddress(null, this.friendship.customer.zipCode, this.friendship.customer.city)
      },
      isNetFriendship (): boolean {
        return Boolean(this.group.netFriendship)
      },
      customerState (): LocaleMessage | string {
        // @ts-ignore Cannot find mixins
        return this.getCustomerStatesTranslation(this.friendship.customer.state)
      },
      startedDate (): string {
        return moment(this.friendship.started).format('d.M.YYYY')
      }
    },
    methods: {
      selectCustomer (): void {
        this.$emit('selectFriendship', this.friendship)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .volunteermatchdetail {
    display: flex;
    align-items: center;
    background-color: $brand-white;
    border-left: solid 5px $customer-color-strong;
    padding: 10px 0;
    .img {
      margin-right:5px;
      display: inline-block;
      vertical-align: top;
      margin-top: 5px;
    }
    .summary {
      font-size: 13px;
      display: inline-block;

      .customername {
        font-weight: bold;
        text-transform: uppercase;
        svg {
          margin-left: 5px;
        }
      }

      .netfriendship {
        color: $brand-red;
      }
    }

    .contact {
      font-size: 13px;
      display: inline-block;
      text-align: right;
      width: 100%;
    }
  }
</style>
