import moment from 'moment'
export default {
  methods: {
    loadMessages () {
      var self = this
      return new Promise((resolve, reject) => {
        var url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.getMessagesByUserId[0]}${this.$store.state.profile.profileId}`
        this.$http.get(url).then(resp => {
          var messages = _.sortBy(resp.body, function (message) {
            return moment(message.sent)
          }).reverse()
          self.$store.commit('setMessages', messages)
          resolve()
        })
      })
    },
    markMessageAsRead (message) {
      if (message.messageStatus !== 2) {
        return this.updateMessage(message, 2)
      }
    },
    deleteMessage (message) {
      return this.updateMessage(message, 4)
    },
    updateMessage (message, status) {
      var self = this
      return new Promise((resolve, reject) => {
        var url = `${self.$store.state.apiBases.messageservice + self.$store.state.apiEndpoints.updatemessagestatus[0]}`
        if (message.messageType === 2) {
          url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.marknotificationasread[0]}`
        }
        url = url.replace('{id}', message.id)
        url = url.replace('{statusid}', status)
        url = url.replace('{notificationid}', message.id)

        self.$http.post(url).then(() => {
          var messages = self.$store.state.messages
          var foundMessageIndex = _.findIndex(messages, function (msg) {
            return message.id === msg.id
          })

          if (status === 4) { // deleted
            self.$delete(messages, foundMessageIndex)
          } else {
            if (self.$store.state.messages.length > 0) {
              messages[foundMessageIndex].messageStatus = status
            }
          }

          self.$store.commit('setMessages', messages)
          resolve()
        })
      })
    },
    getMessage (id) {
      if (this.$store.state.messages.length === 0) {
        var self = this
        this.loadMessages().then(() => {
          return _.find(self.$store.state.messages, function (msg) {
            return id === msg.id
          })
        })
      } else {
        return _.find(this.$store.state.messages, function (msg) {
          return id === msg.id
        })
      }
    }
  }
}
