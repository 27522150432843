<template>
  <div class="checkpill" :class="{'checked': checked}" @click="onClick">
    <span class=".pillcontent">{{ $t(title) }}</span>
  </div>
</template>

<script>
  export default {
    name: 'CheckPill',
    props: {
      title: {
        type: String,
        default () {
          return 'title placeholder'
        }
      },
      checked: {
        type: Boolean,
        default () {
          return false
        }
      }
    },
    methods: {
      onClick (v) {
        this.$emit('click', { id: this.$vnode.key, title: this.title, selected: !this.checked })
      }
    }

  }
</script>

<style lang="scss">
  @import "~@/assets/scss/_variables.scss";

  .checkpill {
    border: solid 2px $brand-red;
    background: $brand-white;
    color: $brand-red;
    padding: 5px 10px;
    border-radius: 20px;
    font-family: $font-light;
    font-weight: bold;
    font-size:13px;
    margin-left: 3px;
    margin-right: 3px;
    cursor: pointer;

    &.checked {
      background-color: $brand-red;
      color: $brand-white;
    }

    &:hover {
      background: #ba0000;
      border: 2px solid #ba0000 !important;
      color: #FFFFFF !important;
    }

    .pillcontent {
      display:inline-block;
      margin: 0 auto;
      font-weight: bold;
      font-size:14px;
    }
    @media (max-width: $media-sm) {
        margin-bottom: 5px;
      }

  }
  </style>
