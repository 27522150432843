<template>
  <div class="homebutton">
    <SprButton
      :size="4"
      :primary="true"
      :title="$t('customerForm.homeButton')"
      preicon="home"
      click-event="home"
      class="home"
      @home="onHomeClicked"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import SprButton from './SPRButton.vue'

  export default Vue.extend({
    name: 'HomeButton',
    components: { SprButton },
    props: {
      // If this is supplied, it will go there. Otherwise browser back.
      homeUrl: {
        type: String,
        required: false,
        default: ''
      },
      groupId: {
        type: Number,
        required: true
      }
    },
    methods: {
      onHomeClicked (): void {
        if (this.homeUrl) {
          this.$router.push(this.homeUrl)
        } else {
          this.$router.push(`/friend/search/${this.groupId}/customer`)
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .homebutton {
    display: inline-block;
    .home {
      padding: 3px 6px;
      font-size: 13px;
    }
  }
</style>