<template>
  <div class="userprofileicon" :style="style">
    <div v-if="!profile.portrait" class="lettercontainer">
      <div class="lettertext">
        {{ letter }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Userprofileicon',
    props: {
      profile: {
        type: Object,
        default: null
      }
    },
    computed: {
      letter () {
        if (this.profile && this.profile.firstName && this.profile.firstName.length > 0) {
          return this.profile.firstName.substring(0, 1)
        }
        return '?'
      },
      style () {
        if (!_.isEmpty(this.profile.portrait)) {
          return 'background:url(' + this.profile.portrait + '); background-size: cover; background-position: center center no-repeat;'
        }
        return ''
      }
    }
  }
</script>
<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .userprofileicon {
    display: inline-block;
    border: solid 2px $brand-grey3;
    border-radius:50%;
    width:50px;
    height:50px;
    margin:0 auto;
    position: relative;

    .lettercontainer {
      display:flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      align-content: center;

      .lettertext {
        align-self: center;
        font-weight: bold;
        color:$brand-grey5;
        font-size: 1.4em;
        text-transform: uppercase;
      }
    }
  }

</style>
