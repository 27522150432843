<template>
  <div class="event-participants-grid">
    <div class="row">
      <div class="col-12">
        <sprgrid :data="participants" :columns="columns" translation-key="eventParticipants" v-on="$listeners" @show="onShowAdditionalModalInfo" />
      </div>
    </div>
  </div>
</template>

<script>
  import sprgrid from '../SPRGrid.vue'
  import moment from 'moment'

  export default {
    name: 'EventParticipantsGrid',
    components: {
      sprgrid
    },
    props: {
      value: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        columns: [
          {
            name: 'name'
          },
          {
            name: 'created',
            cssClass: 'd-none d-md-table-cell'
          },
          {
            name: 'phone',
            cssClass: 'd-xl-table-cell'
          },
          {
            name: 'email',
            cssClass: 'd-xl-table-cell'
          },
          {
            name: 'postalCode',
            cssClass: 'd-none d-lg-table-cell'
          },
          {
            name: 'role',
            cssClass: 'd-none d-lg-table-cell'
          },
          {
            name: 'participated',
            cssClass: 'd-none d-lg-table-cell'
          },
          {
            name: 'extra',
            cssClass: 'd-xl-table-cell',
            callBack: 'onShowAdditionalModalInfo',
            iconName: 'info-circle'
          },
          {
            name: 'remove',
            cssClass: 'd-none d-lg-table-cell'
          }
        ]
      }
    },
    computed: {
      participants () {
        var gridData = []
        _.forEach(this.value, (role) => {
          _.forEach(role.participants, (participant) => {
            gridData.push({
              id: participant.id,
              role: role.name,
              name: [participant.lastName, participant.firstName].join(' '),
              created: moment(participant.created),
              phone: participant.phone,
              email: participant.email,
              postalCode: participant.zipCode,
              moreInfo: participant.moreInfo,
              extra: participant.moreInfo,
              participated: participant.participated,
              remove: false
            })
          })
        })
        return gridData
      }
    },
    methods: {
      getMessageBodyWithLineBreaks (name) {
        var arr = []
        var breaksArr = name.split('\n')
        for (var i = 0; i < breaksArr.length; i++) {
          var el = this.$createElement('div', breaksArr[i])
          arr.push(el)
        }
        return arr
      },
      onParticipationChanged (val) {
        this.$emit('change', val)
      },
      onRemovalChanged (val) {
        this.$emit('changeRemoval', val)
      },
      onShowAdditionalModalInfo (item) {
        if (item.extra) {
          this.$bvModal.msgBoxOk(this.getMessageBodyWithLineBreaks(item.extra), {
            title: item.name,
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'success',
            headerClass: 'modal-body',
            footerClass: 'p-2 border-top-0',
            centered: true
          })
        }
      },
      onSaveParticipation () {
        this.$emit('input', this.value)
        this.userHasInteracted = false
      },
      checkboxClicked () {
        this.userHasInteracted = true
      },
      displayTime (value) {
        if (value) {
          return moment.utc(value).local().format('D.M.YYYY H.mm')
        }
        return ''
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .modal-body {
    div {
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
  }
  .modal-footer {
    .btn-success {
      border-radius: 20px;
      background-color: $brand-red;
      color: $brand-white;
      text-transform: uppercase;
      border-color: $brand-red;

      &:hover,
      &:active,
      &:focus {
        background-color: $brand-red !important;
      }
    }
  }
</style>