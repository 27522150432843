<template>
  <div class="row comp-useradmin-role-editor-inline">
    <div class="col-12">
      <sprmulti v-model="editedRoles"
                title=""
                :placeholder="$t('customerSearch.chooseUserRole')"
                :items="possibleRoles"
                close-aria-label="common.removeRoleAriaLabel"
                class="select-style"
                :has-errors="false"
                @input="mutate"
      />
    </div>
  </div>
</template>

<script>
  import sprmulti from '../SPRMultiSelectInline.vue'

  export default {
    name: 'UserRoleEditorInline',
    components: { sprmulti },
    props: {
      roles: {
        type: Array,
        default: () => []
      },
      id: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {
        editedRoles: []
      }
    },
    computed: {
      postValue: function () {
        const self = this

        // user cannot revoke roles they are not allowed to revoke. So there may be some roles they cannot see in the pills
        // we have to make sure that these roles remain in the array for updating
        // add new roles to the existing roles
        const mutatedRoles = _.union(this.roles, this.editedRoles)

        // remove any roles that the user is allowed to remove, and are removed in editedRoles
        _.remove(mutatedRoles, function (role) {
          const possibleToDeleteRole = _.find(self.possibleRoles, function (r) {
            if (r === '') return false

            return r.value === role
          })

          const editedRole = self.editedRoles[_.indexOf(self.editedRoles, role)]

          return _.isUndefined(editedRole) && possibleToDeleteRole
        })

        return {
          userId: this.id,
          roles: mutatedRoles
        }
      },
      possibleRoles: function () {
        const isAdmin = this.hasRole('Admin')
        const isNationalAdmin = this.hasRole('NationalAdmin')
        const isNationalEmployee = this.hasRole('NationalEmployee')
        const isDistrictAdmin = this.hasRole('DistrictAdmin')
        const isEmployee = this.hasRole('Employee')
        const isJ1 = this.hasRole('J1')
        const isFriendshipAdmin = this.hasRole('FriendshipAdmin')
        const isDistrictFriendshipAdmin = this.hasRole('DistrictFriendshipAdmin')
        const isFriendshipGroupManager = this.hasRole('FriendshipGroupManager')

        var rv = []
        rv.push({ value: 'Admin', text: this.$t('roles.admin'), active: isAdmin })
        rv.push({ value: 'NationalAdmin', text: this.$t('roles.nationalAdmin'), active: isAdmin })
        rv.push({ value: 'NationalEmployee', text: this.$t('roles.nationalEmployee'), active: isAdmin || isNationalAdmin })
        rv.push({ value: 'DistrictAdmin', text: this.$t('roles.districtAdmin'), active: isAdmin })
        rv.push({ value: 'Employee', text: this.$t('roles.employee'), active: isAdmin || isDistrictAdmin || isNationalAdmin || isNationalEmployee })
        rv.push({ value: 'J1', text: this.$t('roles.j1'), active: isAdmin || isDistrictAdmin || isNationalAdmin || isNationalEmployee || isEmployee })
        rv.push({ value: 'J2', text: this.$t('roles.j2'), active: isAdmin || isDistrictAdmin || isNationalAdmin || isNationalEmployee || isEmployee || isJ1 })

        rv.push({ value: 'FriendshipAdmin', text: this.$t('roles.friendshipAdmin'), active: isAdmin })
        rv.push({ value: 'DistrictFriendshipAdmin', text: this.$t('roles.districtFriendshipAdmin'), active: isAdmin })
        rv.push({ value: 'FriendshipGroupManager', text: this.$t('roles.friendshipGroupManager'), active: isAdmin || isFriendshipAdmin || isDistrictFriendshipAdmin })
        rv.push({ value: 'FriendshipBroker', text: this.$t('roles.friendshipBroker'), active: isAdmin || isFriendshipAdmin || isDistrictFriendshipAdmin || isFriendshipGroupManager })
        rv.push({ value: 'NetFriendshipBroker', text: this.$t('roles.netFriendshipBroker'), active: isAdmin || isFriendshipAdmin })
        rv.push({ value: 'FriendshipTrainer', text: this.$t('roles.friendshipTrainer'), active: isAdmin || isDistrictAdmin || isNationalAdmin || isFriendshipAdmin || isDistrictFriendshipAdmin })

        return rv
      }
    },
    watch: {
      roles: {
        deep: true,
        handler: function (val, old) {
          this.editedRoles = val
        }
      }
    },
    mounted: function () {
      this.editedRoles = this.roles
    },
    methods: {
      mutate: function (value) {
        this.editedRoles = _.map(value, function (v) { return v.value })
        this.editedRoles = _.filter(this.editedRoles, function (v) { return v !== '' })
        this.postEdit()
      },
      postEdit: function (callback) {
        const self = this
        var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.setroles[0]
        this.$http.post(uri, this.postValue).then(s => {
          self.$bus.$emit('section-roles-changed', this.postValue)
        }, f => {
          if (callback) {
            // todo: Switch to promise
            callback('roles-error') // eslint-disable-line standard/no-callback-literal
          }
        })
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
  .comp-useradmin-role-editor-inline {

  }
</style>
