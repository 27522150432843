<template>
  <div class="container row">
    <div class="input-container col-5">
      <span class="input-text">{{ name }}</span>
    </div>
    <div v-if="isEditing" class="input-container col-4">
      <sprinput v-if="formData.type === 'Integer'"
                v-model="formData.value" class="input-text"
                :hint="formData.type"
                :placeholder="formData.type"
                :size="'5'"
                :input-type="'number'"
                :center-text="true"
      />
      <datepicker v-if="formData.type === 'Date'"
                  v-model="formData.value"
                  :required="false"
                  :is-nullable="true"
                  :initial="formData.value"
      />
    </div>
    <div v-else class="input-container col-4">
      <span class="input-text">{{ formData.value }}</span>
    </div>
    <div class="action-icons">
      <a v-show="!isEditing" class="action-icon" tabindex="0" href="javascript:void(0);" @click="toggleEdit">
        <font-awesome-icon icon="pencil-alt" aria-hidden="true" />
      </a>
      <a v-show="isEditing" class="action-icon" tabindex="0" href="javascript:void(0);" @click="toggleEdit">
        <font-awesome-icon icon="check" class="save" aria-hidden="true" />
      </a>
      <a v-show="isEditing" class="action-icon" tabindex="0" href="javascript:void(0);" @click="revertChanges">
        <font-awesome-icon icon="times" aria-hidden="true" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import moment from 'moment'

  import sprinput from '../SPRInput.vue'
  import datepicker from '../SPRDatepicker.vue'

  import { ExternalFormUpdateDto, OmaSettingsDto } from '@/types/index'

  export interface TemplateComponentData {
    isEditing: boolean,
    formData: OmaSettingsDto,
    backup: string
  }

  export default Vue.extend({
    components: { sprinput, datepicker },
    props: {
      name: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      },
      isNew: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isEditing: this.isNew,
        formData: {
          name: this.name,
          value: this.value,
          type: this.type
        } as OmaSettingsDto,
        updatedFormData: {
          name: '',
          value: '',
          type: ''
        } as OmaSettingsDto,
        backup: ''
      }
    },
    methods: {
      edit () {
        this.isEditing = true
      },
      toggleEdit: function () {
        this.isEditing = !this.isEditing

        if (this.isEditing) {
          this.backup = this.formData.value
        } else {
          if (this.backup !== this.formData.value) {
            this.submitForm()
          }
        }
      },
      cancel () {
        this.$emit('cancel')
      },
      revertChanges: function () {
        if (this.isEditing) {
          this.formData.value = this.backup
          this.toggleEdit()
        }
      },
      submitForm () {
        var self = this
        var updateurl = this.$store.state.apiBases.systemservice + this.$store.state.apiEndpoints.settingsupdate[0]
        this.updatedFormData = this.formData
        if (this.formData.type === 'Date') {
          this.updatedFormData.value = moment(this.formData.value, 'DD.MM.YYYY').format('YYYY-MM-DD')
        }
        this.$http.put(updateurl, this.updatedFormData).then(response => {
          if (response.ok) {
            self.isEditing = false
            if (this.formData.type === 'Date') {
              this.formData.value = moment(this.updatedFormData.value, 'YYYY-MM-DD').format('DD.MM.YYYY')
            }
            this.backup = this.formData.value
            // @ts-ignore - mixins not picked up
            this.showAlertSuccess(`${this.$t('common.settingUpdated')}`)
            //get the store state settings and update the value in that array where the names match
            this.$store.dispatch('setSetting', this.formData)
          }
        }, response => {
          // @ts-ignore - mixins not picked up
          this.showAlertError(`${this.$t('common.saveFailed')}`)
        })
        this.$emit('save', this.formData)
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';

.container {
  display: flex;
  align-items: center;
}

.input-container {
  display: flex;
  flex-grow: 1;
}

.input-text {
  flex: 1;
  margin-right: 10px; /* Adjust the space between the inputs if needed */
}

.input-button {
  margin-left: 10px; /* Adjust the space between the inputs and buttons if needed */
}

.action-icons {
  display: flex;
  align-items: center;
}

.action-icon {
  display: flex;
  align-items: center;
  color: $brand-red;
  align-items: center;
  font-family: $font-bold;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 20px;

  .save {
    color: $brand-success;
  }
}
</style>