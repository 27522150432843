<template>
  <div :class="{ 'spr-danger': !isValid, 'spr-multiselect-dropdown': true, 'spr-roundbox-1': true, 'fixed-width': fixedWidth }">
    <singleselect :value="selectedItem"
                  :options="items"
                  :placeholder="placeholder"
                  label="text"
                  track-by="text"
                  :allow-empty="allowempty"
                  :searchable="searchable"
                  :multiple="false"
                  @input="onChange"
    />
  </div>
</template>

<script>
  import singleselect from 'vue-multiselect'

  export default {
    components: {
      singleselect
    },
    model: {
      prop: 'selectedItem',
      event: 'change'
    },
    props: {
      selectedItem: {
        type: Object,
        default: null
      },
      items: {
        type: Array,
        default: () => []
      },
      fixedWidth: {
        type: Boolean,
        default: false
      },
      isValid: {
        type: Boolean,
        default: true
      },
      placeholder: {
        type: String,
        default: ''
      },
      searchable: {
        type: Boolean,
        default: false
      },
      allowempty: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onChange (e) {
        this.$emit('change', e)
      }
    }

  }</script>

<style lang="scss">
  @import "../../../assets/scss/_variables.scss";

    .spr-danger {
      .multiselect {
        .multiselect__tags {
          border: 2px solid $brand-red !important;
        }
      }
    }
  .fixed-width {
      .multiselect {
        .multiselect__tags {
          width: 150px
        }
      }
  }
</style>