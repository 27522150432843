import Vue from 'vue'
import VueToast from 'vue-toast-notification'
// below is overridden by _alerts.scss
import 'vue-toast-notification/dist/theme-default.css'

Vue.use(VueToast) // warning alerts

export default {
  methods: {
    showAlert403 () {
      this.showAlertPermissionDenied()
      this.$router.push('/404')
    },
    showAlertSuccess (html) {
      return new Promise((resolve, reject) => {
        this.showAlert(html, 'success', () => {
          resolve()
        })
      })
    },
    showAlertInfo (html) {
      return new Promise((resolve, reject) => {
        this.showAlert(html, 'info', () => {
          resolve()
        })
      })
    },
    showAlertWarning (html) {
      return new Promise((resolve, reject) => {
        this.showAlert(html, 'warning', () => {
          resolve()
        })
      })
    },
    showAlertError (html) {
      return new Promise((resolve, reject) => {
        this.showAlert(html, 'error', () => {
          resolve()
        })
      })
    },
    showAlertPermissionDenied () {
      return new Promise((resolve, reject) => {
        this.showAlert(this.$t('common.permissionDenied'), 'error', () => {
          resolve()
        })
      })
    },
    showAlert (html, type, closefn, duration = 7000) {
      this.$toast.open({
        message: html,
        type: type,
        position: 'top',
        duration: duration,
        onClose: closefn
      })
    }
  }
}
