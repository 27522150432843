<template>
  <div class="row">
    <div class="col-md-6 col-12 user">
      <div class="user-img">
        <sprportrait :style="portraitStyle"
                     class="portrait-size"
        />
      </div>
      <div class="user-name">
        <a href="#">{{ volunteer.name }}</a>
        <span class="section">{{ section }}</span>
      </div>
    </div>

    <div class="col-md-6 col-12 actions">
      <sprbutton v-if="volunteer.selected === 0 && !volunteer.pendingConsent"
                 type="primary"
                 :size="4"
                 :title="$t('groups.addVolunteerButton')"
                 class="spr-redborder button-width"
                 click-event="addVolunteer"
                 @addVolunteer="onAddVolunteer"
      />
      <div v-if="volunteer.selected === 1" class="alreadyselected">
        {{ $t('groups.volunteerIsSelected') }}
        <font-awesome-icon icon="check" />
      </div>
      <div v-if="volunteer.selected === 2" class="pendingtext">
        {{ $t('groups.pendingSave') }}
        <font-awesome-icon icon="exclamation-triangle" class="pendingsave" />
      </div>
      <div v-if="volunteer.pendingConsent" class="pendingtext">
        {{ $t('groups.pendingConsent') }}
        <font-awesome-icon icon="exclamation-triangle" class="pendingsave" />
      </div>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import sprportrait from '../SPRPortrait.vue'
  import anonPortrait from '../../assets/img/anon-portrait.png'

  export default {
    name: 'Volunteerviewsearch',
    components: { sprbutton, sprportrait },
    props: {
      volunteer: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        //
      }
    },
    computed: {
      section () {
        var self = this
        var section = _.find(this.$store.state.sections, (s) => {
          return s.id === self.volunteer.sectionId
        })

        return section ? section.name : ''
      },
      portraitStyle: function () {
        var port = this.volunteer.portrait ? this.volunteer.portrait : anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      }
    },
    methods: {
      onAddVolunteer: function () {
        this.$emit('addVolunteer', this.volunteer)
      },
      onRemoveVolunteer: function () {
        this.$emit('removeVolunteer', this.volunteer)
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .section {
    display: block;
  }

  .actions {
    text-align: right;
  }

  .spr-button {
    &.selected

  {
    background-color: $brand-red;
    color: $brand-white;
  }

  }

  .alreadyselected {
    color: $brand-success;
  }

  .pendingsave {
    color: $brand-warning;
  }

  .pendingtext {
    color: $brand-grey11;
  }
</style>
