<template>
  <div class="testcors">
    <h1>Test CORS</h1>
    <div>
      <h4>Login to test endpoints</h4>
      <input v-model="username" type="text" placeholder="username"><br>
      <input v-model="password" type="password"><br>
      <button tabindex="0" value="Login" name="Login"
              @click="onLogin"
      >
        Login
      </button>
    </div>
    <div>
      <div v-for="(result, index) in results" :key="index">
        Url: <strong>{{ result.url }}</strong><br>
        HTTP Status: <span class="httpstatus" :class="{ success: result.status }">{{ result.status }}</span><br>
        Server Status: {{ result.body.status }}<br>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Testcors',
    data () {
      return {
        username: '',
        password: '',
        loginUrl: 'https://spr-vati-oma-webapi.azurewebsites.net/api/users/accounts/GetLoginToken',
        //loginUrl: 'http://localhost:3758/api/users/accounts/GetLoginToken',
        endpoints: [
          'https://spr-vati-oma-webapi.azurewebsites.net/api/content/areasofinterest',
          'https://spr-vati-oma-webapi.azurewebsites.net/api/content/availabilitytimes',
          'https://spr-vati-oma-webapi.azurewebsites.net/api/content/languages',
          'https://spr-vati-oma-webapi.azurewebsites.net/api/content/personalskills',
          'https://spr-vati-oma-webapi.azurewebsites.net/api/content/transportation',
          'https://spr-vati-oma-webapi.azurewebsites.net/api/content/translations',
          'https://spr-vati-oma-webapi.azurewebsites.net/api/users/user',
          'https://spr-vati-oma-webapi.azurewebsites.net/api/users/user/11e5d4a3-0836-4a60-b445-eb8df9d6d8e4',
          'https://spr-vati-oma-webapi.azurewebsites.net/api/users/accounts/GetCurrentUsername',
          'https://spr-vati-oma-webapi.azurewebsites.net/api/users/accounts/GetCurrentUsername'
        ],
        results: []
      }
    },
    methods: {
      onLogin: function () {
        var loginObj = { Email: this.username, Password: this.password }
        this.$http.post(this.loginUrl, loginObj).then(success => {
          if (success.ok) {
            for (var i = 0; i < this.endpoints.length; i++) {
              this.$http.get(this.endpoints[i], { headers: { Authorization: 'bearer ' + success.body.token } }).then(resp => {
                this.results.push(resp)
              })
            }
          } else {
            this.results.push('login failed')
          }
        }, error => {
          console.log(error)
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .testcors {
    margin:15px;
  }

  .httpstatus {
    color:red;
  }

  .success {
    color: green;
    font-weight:bold;
  }
</style>
