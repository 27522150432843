import moment from 'moment'

const datehelper = {
  // Custom function to parse dates in dd.MM.yyyy format
  parseDateFromString (dateString) {
    const parts = dateString.split('.')

    const d = new Date(parts[2], parts[1] - 1, parts[0])
    return d
  },
  toUtcWithoutTime (date: Date | undefined) {
    if (!date) {
      return
    }
    // Create a UTC date for January 1, 2023, at 00:00:00
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
  },
  // Gets the age in years
  calculateAge (bDate) {
    return moment().diff(moment(bDate), 'years')
  },
  calculateDayDiff (pDate) {
    if (!pDate) {
      return
    }
    return moment().diff(moment(pDate), 'days')
  },
  birthdateValidation (): string {
    const date = new Date()
    const nowDay = date.getDate()
    const nowDayString = nowDay < 10 ? '0' + nowDay : nowDay.toString()
    let nowMonth = date.getMonth()
    if (nowMonth === 0) {
      nowMonth = 1
    }
    const nowMonthString = nowMonth < 10 ? '0' + nowMonth : nowMonth.toString()
    const nowYear = date.getUTCFullYear() - 2
    const currentDateAsString = `${nowDayString}.${nowMonthString}.${nowYear}`

    return `required|dateisbefore:${currentDateAsString}`
  }
}

export default datehelper