<template>
  <div class="row groupstatus">
    <div class="col col-sm-7">
      <div class="status">
        <div class="cap-label title">
          {{ $t('userAdmin.groupStatus') }}
        </div>
        <span v-if="group.isActive" class="active">{{ $t('userAdmin.groupStatusActive') }}</span>
        <span v-if="!group.isActive" class="inactive">{{ $t('userAdmin.groupStatusInactive') }}</span>
      </div>
    </div>
    <div class="col-sm-5 text-right">
      <sprbutton
        v-if="!group.isActive"
        :size="4"
        :title="$t('userAdmin.btnActivateGroup')"
        :bordersize="2"
        class="btnactivate"
        click-event="activate"
        @activate="onActivate"
      />
      <sprbutton
        v-if="group.isActive"
        :size="4"
        :title="$t('userAdmin.btnDeactivateGroup')"
        :bordersize="2"
        class="btndeactivate"
        click-event="deactivate"
        @deactivate="onDeactivate"
      />
    </div>
    <div class="col-12 text-right delete">
      <sprbutton
        v-if="!group.isActive"
        :size="4"
        :title="$t('userAdmin.btnDeleteGroup')"
        :bordersize="2"
        class="btndelete"
        click-event="delete"
        @delete="onDelete"
      />
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'

  export default {
    name: 'GroupStatus',
    components: { sprbutton },
    props: {
      group: {
        type: Object,
        default: null
      }
    },
    methods: {
      onActivate () {
        this.$emit('enable')
      },
      onDeactivate () {
        this.$emit('disable')
      },
      onDelete () {
        this.$emit('delete')
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .groupstatus {
    background-color:$brand-white;
    padding:15px 0;
    margin-bottom: 20px;

    .status {
      .title {
        color:$brand-red;
      }
      .active {
        color:$brand-success;
      }
      .inactive {
        color:$brand-red;
      }
    }

    .delete {
      margin-top: 15px;
    }
  }
</style>
