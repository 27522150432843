<template>
  <div class="col-12 comp-admin-organization-level my-3">
    <adminnavigation :options="options" />
  </div>
</template>

<script>
  import adminnavigation from './AdminHorizontalNavigation.vue'
  export default {
    name: 'AdminOrganizationLevel',
    components: {
      adminnavigation
    },
    props: {
      primary: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      filter: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        optionsConstant: []
      }
    },
    computed: {
      options: function () {
        var rv = this.optionsConstant.concat()

        rv.push({ uri: '/admin/district', text: this.$t('userAdmin.districts'), primary: 'district' })
        rv.push({ uri: '/admin/section', text: this.$t('userAdmin.sections'), primary: 'section' })
        rv.push({ uri: '/admin/user', text: this.$t('userAdmin.users'), primary: 'user' })
        rv.push({ uri: '/admin/employee', text: this.$t('userAdmin.employees'), primary: 'employee' })
        rv.push({ uri: '/admin/group', text: this.$t('userAdmin.groups'), primary: 'group' })
        rv.push({ uri: '/admin/deleteprofile', text: this.$t('userAdmin.deleteProfile'), primary: 'deleteprofile' })
        rv.push({ uri: '/admin/friendship', text: this.$t('userAdmin.friendshipManagement'), primary: 'friendship' })
        rv.push({ uri: '/admin/training/1', text: this.$t('userAdmin.training'), primary: 'training' })
        rv.push({ uri: '/admin/products', text: 'Jäsenmaksutuotteet', primary: 'products' })
        rv.push({ uri: '/admin/externalforms', text: this.$t('userAdmin.externalForms'), primary: 'externalforms' })
        rv.push({ uri: '/admin/settings/eventbackgroundimages', text: this.$t('userAdmin.settingsHeader'), primary: 'settings' })
        return rv
      }
    }
  }
</script>
