<template>
  <div v-if="transformed" class="translations container">
    <div class="row">
      <div v-for="(lang, index) in transformed" :id="lang.lang" :key="index" class="col-12 col-md-4">
        <h2>{{ lang.lang }}</h2>
        <div v-for="(cat, catindex) in lang.cats" :id="lang.lang + '_' + cat.cat" :key="catindex">
          <div v-for="(key, catkey) in cat.keys" :key="catkey">
            <div class="row">
              <div class="col-12 my-2">
                <font-awesome-icon
                  icon="edit"
                  class="brand-red edit-icon"
                  title="cms.editorHover"
                  @click="editTranslation(key.value, lang.lang, cat.cat, key.key)"
                />
                <span class="translation-key">{{ lang.lang }}.{{ cat.cat }}.{{ key.key }}</span>
                <br>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-linkified:options="{ nl2br: true }" v-html="key.value" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Dialog -->
    <b-modal v-model="editorOpen" :title="editing ? `${editing.lang}.${editing.cat}.${editing.key}` : ''">
      <div v-if="saving" class="col-12 text-center brand-red">
        <loading />
      </div>
      <div v-if="!saving && editing" class="row">
        <div class="col-12">
          <textarea v-model="editing.value"
                    :placeholder="$t('cms.newTranslation')"
                    :is-valid="true"
                    name="cms.translationFor"
                    :required="false"
                    style="width: 100%;"
                    rows="10"
          />
        </div>
      </div>
      <div slot="modal-footer" class="container-fluid">
        <div class="row">
          <div v-if="!saving" class="col-12 text-right">
            <sprbutton type="primary"
                       :size="1"
                       :title="$t('cms.save')"
                       class="spr-redborder d-block mx-auto my-1"
                       click-event="sav"
                       @sav="saveTranslation"
            />
            <sprbutton type="primary"
                       :size="1"
                       :title="$t('cms.cancel')"
                       class="spr-redborder d-block mx-auto my-1"
                       click-event="can"
                       @can="editorOpen = false"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
  <div v-else>
    cms.loading
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'
  import loading from '../SPRLoading.vue'

  export default {
    name: 'Translations',
    components: {
      sprbutton, loading
    },
    data: function () {
      return {
        translation: {},
        transformed: null,
        editing: null,
        editorOpen: false,
        saving: false
      }
    },
    computed: {
      schema: function () {
        return this.translation.schema
      }
    },
    mounted: function () {
      this.getTranslations()
    },
    methods: {
      editTranslation: function (val, lang, cat, key) {
        this.editorOpen = true
        this.editing = {
          original: val.slice(0),
          value: val.slice(0),
          lang: lang.slice(0),
          cat: cat.slice(0),
          key: key.slice(0)
        }
      },
      getTranslations: function (callBack) {
        const uri = this.$store.state.apiBases.contentservice + this.$store.state.apiEndpoints.translationscached[0]
        const self = this
        this.$http.get(uri).then(s => {
          self.translation = s.body
          self.transformed = self.languages()
          if (callBack) {
            callBack()
          }
        })
      },
      languages: function () {
        var rv = []

        const keys = Object.keys(this.translation)
        for (var i = 0; i < keys.length; i++) {
          const langKey = keys[i]
          var langObj = {
            lang: langKey,
            cats: []
          }

          var catKeys = Object.keys(this.translation[langKey])
          for (var j = 0; j < catKeys.length; j++) {
            const catKey = catKeys[j]
            var catObj = {
              cat: catKey,
              keys: []
            }

            const keyKeys = Object.keys(this.translation[langKey][catKey])
            for (var k = 0; k < keyKeys.length; k++) {
              const finalKey = keyKeys[k]
              catObj.keys.push({
                key: finalKey,
                value: this.translation[langKey][catKey][finalKey]
              })
            }

            langObj.cats.push(catObj)
          }

          rv.push(langObj)
        }

        return rv
      },
      saveTranslation: function (del) {
        this.saving = true
        const uri = this.$store.state.apiBases.contentservice + this.$store.state.apiEndpoints.translations[0]
        // Can send multiple 'actions' in same array if making batched saving in future
        const request = [{
          language: this.editing.lang,
          category: this.editing.cat,
          key: this.editing.key,
          value: this.editing.value,
          delete: !!del
        }]

        const self = this
        this.$http.post(uri, request).then(s => {
          self.getTranslations(() => {
            self.editorOpen = false
            self.editing = null
            self.saving = false
          })
        })
      }
    }
  }
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";
  .translations {
    .edit-icon {
      cursor: pointer;
    }

  .translation-key {
    font-weight: bold;
  }

  .mx-auto {
    min-width: 400px;
  }
}
</style>
