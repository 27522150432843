<template>
  <div class="switch-language">
    <span :class="{ 'selected' : fiSelected }" @click="changeLanguage('fi')">fi</span>
    <span :class="{ 'selected' : svSelected }" @click="changeLanguage('sv')">sv</span>
    <span :class="{ 'selected' : enSelected }" @click="changeLanguage('en')">en</span>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'Switchlanguage',
    data () {
      return {
        fiSelected: this.$store.state.language === 'fi',
        svSelected: this.$store.state.language === 'sv',
        enSelected: this.$store.state.language === 'en'
      }
    },
    computed: {
      ...mapState({
        language: state => state.language,
        options: state => state.languages
      })
    },
    methods: {
      changeLanguage: function (e) {
        if (e === 'fi') {
          this.fiSelected = true
          this.svSelected = false
          this.enSelected = false
        }

        if (e === 'sv') {
          this.fiSelected = false
          this.svSelected = true
          this.enSelected = false
        }

        if (e === 'en') {
          this.fiSelected = false
          this.svSelected = false
          this.enSelected = true
        }

        // set html root tag language to selected
        window.setHtmlLang(e)
        this.$store.commit('setLanguage', e)
        this.$i18n.locale = e
        sessionStorage.setItem('locale', e)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";
  .switch-language {
    display: inline-block;
    margin-left: 17px;
    position: relative;
    z-index: 200;
  }

  .selected {
    font-weight: bold;
    color: #CC0000;
  }
  span {
    cursor: pointer;
    margin-right: 5px;
  }
</style>
