<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";

  .sprmessage {
    padding: 5px;

    &:nth-child(odd) {
      background: rgba(0,0,0,0.03);
    }

    .sender {
      .state {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        display: none;
        margin-right: 5px;

        &.new {
          display: inline-block;
          background-color: $brand-success;
          border-color: $brand-success;
        }
      }

      .sentby {
        color: $brand-red;
        &:hover {
          background-color: auto;
        }
      }
    }

    .subject {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .content {
      padding-top: 10px;
    }
  }
</style>

<template>
  <div class="sprmessage">
    <div class="row" @click="clicked">
      <div class="col-1">
        <font-awesome-icon v-if="open" icon="chevron-down" class="brand-red" aria-hidden="true" />
        <font-awesome-icon v-else icon="chevron-up" class="brand-red" aria-hidden="true" />
      </div>
      <div class="col-4">
        <div class="sender">
          <div class="state" :class="stateClass" />
          <span class="sentby cap-label">{{ message.sender }}</span>
          <div class="date">
            {{ sentDate }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="subject">
          {{ message.subject }}
        </div>
      </div>
    </div>
    <div v-show="open" class="row justify-content-center content">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-linkified:options="{ nl2br: true }" class="col-10" @click="clicked">
        {{ message.content }}
      </div>
      <div v-if="showRemoveButton" class="col-2 text-right">
        <sprbutton type="primary"
                   :size="4"
                   :title="$t('eventPage.remove')"
                   class="spr-redborder button-width"
                   click-event="removeMessage"
                   @removeMessage="remove"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import sprbutton from '../SPRButton.vue'
  import messages from '../../mixins/messages.js'
  export default {
    name: 'SprMessage',
    components: { sprbutton },
    mixins: [messages],
    props: {
      message: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        sender: {},
        open: false
      }
    },
    computed: {
      sentDate () {
        return moment(this.message.sent).format('DD.MM.YYYY H:mm')
      },
      stateClass () {
        switch (this.message.messageStatus) {
        case 1:
          return 'new'

        case 2:
          return 'marked-as-read'

        case 3:
          return 'hidden'

        case 4:
          return 'deleted'

        default:
          return 'unknown'
        }
      },
      showRemoveButton () {
        if (this.hasRole('Admin') || this.hasRole('J1') || this.hasRole('J2') || this.hasRole('Emplyee')) {
          return true
        }
        return false
      }
    },
    methods: {
      remove: function () {
        const self = this
        const url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.removenotification[0]}/${self.message.id}`
        this.$http.post(url).then(function () {
                                    self.$bus.$emit('reload-section-messages')
                                  },
                                  function (y) {
                                    console.log('error removing notification!')
                                  })
      },
      clicked: function () {
        this.open = !this.open
      }
    }
  }
</script>
