<template>
  <div class="container">
    <div class="profilenonidentstandardvolunteerform">
      <div class="row">
        <div class="col-12">
          <h2>{{ addEditTitle }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <backbar />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center py-2">
          <validation
            v-if="friendProfile"
            :id="'validationTarget' + _uid"
            ref="validationtarget"
            :validation-errors="validationErrors"
            exclude-field-reference
            include-title
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12 col-sm-5 col-md-4">
          <forminput
            v-if="friendProfile"
            v-model="friendProfile.phoneNumber"
            v-validate="'required'"
            :hint="$t('volunteerForm.phone')"
            :is-valid="!errors.has('volunteerForm.phone')"
            name="volunteerForm.phone"
            :required="true"
            :disabled="isIdentProfile"
            :tooltip="$t('volunteerForm.disabledPhone')"
          />
        </div>
        <div class="col-12 col-sm-7 col-md-4">
          <forminput
            v-if="friendProfile"
            v-model="friendProfile.email"
            v-validate="'email'"
            :hint="$t('profile.email')"
            :is-valid="!errors.has('volunteerForm.email')"
            name="volunteerForm.email"
          />
        </div>
      </div>
      <div v-if="friendProfile" class="row feature-panel">
        <div class="col-12">
          <ProfileInfoForm
            v-if="friendProfile"
            :profile="friendProfile"
            :is-net-profile="false"
            @input="onFriendProfileChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <VolunteerProfileCommon
            v-if="friendProfile"
            :volunteer="friendProfile.friendInfo"
            @input="onVolunteerChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <VolunteerProfileStandard
            v-if="friendProfile"
            :volunteer="friendProfile.friendInfo.standardProfile"
            @input="onVolunteerStandardProfileChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12 sectionheader">
          <h3>{{ $t("nonIdentProfileForm.applicationState") }}</h3>
        </div>
        <div class="col-12">
          <InterviewForm
            v-if="friendProfile"
            :value="computedInterviewData"
            :disabled="isStateChangeDisabled"
            @input="onInterviewDataChanged"
          />
        </div>
        <div class="col-12">
          <OnHoldForm
            v-if="friendProfile"
            :value="computedOnHoldData"
            :disabled="isStateChangeDisabled"
            @input="onOnHoldDataChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <textareainput
            v-if="friendProfile"
            v-model="friendProfile.friendInfo.standardProfile.privateNotes"
            :title="$t('volunteerForm.privateNotes')"
            :rows="6"
          />
        </div>
      </div>
      <div class="row feature-panel buttons">
        <div class="col-6 cancel">
          <SprButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.cancel')"
                     click-event="onCancel"
                     @onCancel="cancel"
          />
        </div>
        <div class="col-6 save">
          <SprButton type="button"
                     :size="1"
                     :disabled="isSaving"
                     :title="$t('volunteerForm.save')"
                     click-event="onSave"
                     @onSave="save"
          />
        </div>
      </div>
    </div>
    <b-modal
      :id="_uid + 'modal'"
      v-model="showModal"
      size="huge"
      centered
      cancel-disabled
      ok-disabled
      @close="cancelModal"
    >
      <div slot="modal-header">
        <h3>{{ $t('volunteerForm.emailExists') }}</h3>
      </div>
      <div v-if="showModal" class="modal-body">
        <div>
          <p>{{ $t('volunteerForm.emailExistsMoreInfo') }}</p>
          <div v-if="userProfile">
            <p> <b style="font-weight:bold;">{{ $t('nonIdentProfileForm.firstName') }}:</b> {{ userProfile.firstName }}</p>
            <p> <b style="font-weight:bold;">{{ $t('nonIdentProfileForm.lastName') }}:</b> {{ userProfile.lastName }} </p>
            <p> <b style="font-weight:bold;">{{ $t('nonIdentProfileForm.email') }}:</b>  {{ userProfile.email }}</p>
            <p> <b style="font-weight:bold;">{{ $t('volunteerForm.phone') }}:</b> {{ userProfile.phoneNumber }}</p>
            <p> <b style="font-weight:bold;">{{ $t('nonIdentProfileForm.city') }}:</b> {{ userProfile.city }}</p>
            <p> <b style="font-weight:bold;">{{ $t('nonIdentProfileForm.birthdate') }}:</b> {{ birthdateString }} </p>
            <p> <b style="font-weight:bold;">{{ $t('userAdmin.districtName') }}:</b> {{ existingProfileSectionName }}</p>
            <p v-if="userProfile.friendInfo && userProfile.friendInfo.hasStandardProfile">
              <b style="font-weight:bold;">{{ $t('nonIdentProfileForm.hasStandardProfile') }}:</b> {{ $t('boolean.true') }}
            </p>
            <p v-if="userProfile.friendInfo && userProfile.friendInfo.hasNetProfile">
              <b style="font-weight:bold;">{{ $t('nonIdentProfileForm.hasNetProfile') }}:</b>  {{ $t('boolean.true') }}
            </p>

            <!-- Add more fields as necessary -->
          </div>
        </div>
      </div>
      <!-- footer -->
      <div slot="modal-footer" class="container-fluid">
        <div class="row">
          <div
            class="col-12 text-right close-link brand-red"
          >
            <SprButton v-if="userProfile && !userProfile.friendInfo" type="button"
                       :size="1"
                       :title="$t('volunteerForm.connect')"
                       click-event="onConnect"
                       @onConnect="connectProfiles"
            />
            <SprButton type="button"
                       :size="1"
                       :title="$t('volunteerForm.cancel')"
                       click-event="onCancel"
                       @onCancel="cancelModal"
            />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import { LocaleMessage } from 'vue-i18n'
  import SprButton from '@/components/SPRButton.vue'
  import forminput from '../SPRSimpleText.vue'
  import VolunteerProfileCommon from '@/components/friends/VolunteerProfileCommon.vue'
  import ProfileInfoForm from '@/components/friends/ProfileInfoForm.vue'
  import VolunteerProfileStandard from '@/components/friends/VolunteerProfileStandard.vue'
  import InterviewForm from '@/components/friends/InterviewForm.vue'
  import OnHoldForm from '@/components/friends/OnHoldForm.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import backbar from '../BackBar.vue'
  import validation from '../SPRValidationError.vue'
  import Alerts from '@/mixins/alerts.js'
  import { ErrorBag, FieldError } from 'vee-validate'
  import { UserProfileDto, FriendProfileDto, ProfileState, FriendVolunteerDto, FriendVolunteerWithFriendshipsDto, FriendVolunteerProfileStandardDto, CustomerState, PreferredGenders, FriendshipTypes, NetFriendshipTypes, GetFriendProfileByFriendVolunteerIdCriteria } from '@/types/index'
  import { InterviewData, OnHoldData } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    friendProfile: FriendProfileDto | undefined,
    userProfile: UserProfileDto | undefined,
    localProfileId: number,
    isSaving: boolean,
    showModal: boolean,
    existingProfileSectionName: string
  }

  // This is the component for managing adding and updating an ident users Friend profile.
  export default Vue.extend({
    name: 'ProfileNonIdentStandardVolunteerForm',
    components: { validation, backbar, ProfileInfoForm, VolunteerProfileCommon, VolunteerProfileStandard, InterviewForm, OnHoldForm, SprButton, textareainput, forminput },
    mixins: [Alerts],
    props: {
      profileId: {
        type: Number,
        default: 0
      },
      groupId: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        friendProfile: undefined,
        isSaving: false,
        showModal: false,
        userProfile: undefined,
        existingProfileSectionName: '',
        localProfileId: 0
      }
    },
    computed: {
      isIdentProfile (): boolean {
        return !Boolean(this.friendProfile?.isNonIdent)
      },  
      isAdd (): boolean {
        return Boolean(!this.profileId)
      },
      isEdit (): boolean {
        return Boolean(this.profileId)
      },
      birthdateString (): string {
        if (!this.userProfile) return ''
        var d = new Date(this.userProfile.age)
        return d ? d.toLocaleDateString('fi-FI') : ''
      },
      addEditTitle (): string | LocaleMessage {
        return this.isEdit
          ? this.$t('volunteerForm.updateTitle')
          : this.$t('volunteerForm.addTitle')
      },
      validationErrors (): Array<Array<string>> {
        const errs: ErrorBag = this.errors ? this.errors : []
        if (errs.items.length) {
          return errs.items.map((err: FieldError) => {
            return [`error_${err.field}`, err.rule ? err.rule : '', err.msg ? err.msg : '']
          })
        }
        return []
      },

      computedInterviewData (): InterviewData | null {
        if (!this.friendProfile?.friendInfo?.standardProfile) {
          return {
          interviewed: false,
          interviewedBy: '',
          interviewedDate: undefined
        } as InterviewData
        }

        var d = new Date()
        var defaultDate = new Date(`${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate().toString()}T00:00:00Z`) // must be in UTC date format
        const p = this.friendProfile.friendInfo.standardProfile
        return {
            interviewed: p.volunteerInterviewed,
            interviewedBy: p.volunteerInterviewedBy,
            interviewedDate: p.volunteerInterviewedOnDate || defaultDate
          } as InterviewData
      },
      computedOnHoldData (): OnHoldData | null {
        if (!this.friendProfile?.friendInfo?.standardProfile) return null

        const p = this.friendProfile.friendInfo.standardProfile
        return {
            onHold: Boolean(p.onHoldDeadline),
            onHoldDeadline: p.onHoldDeadline,
            onHoldDetails: p.onHoldDetails
          } as OnHoldData
      },
      isStateChangeDisabled (): boolean {
        if (!this.friendProfile?.friendInfo?.standardProfile) return false
        /// Cannot change the state if the customer has friendships
        return this.friendProfile?.friendInfo?.standardProfile?.state === CustomerState.PendingFriend ||
          this.friendProfile?.friendInfo?.standardProfile?.state === CustomerState.ActiveFriendship ||
          Boolean(this.friendProfile?.friendInfo?.standardProfile?.friendships?.length)
      }
    },
    mounted (): void {
      if (this.isEdit) {
        var criteria: GetFriendProfileByFriendVolunteerIdCriteria = {
          friendManagementGroupId: this.groupId,
          friendVolunteerId: this.profileId,
          includeFriendships: false,
          includeFriendPools: false
        }
        var url = (this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteergetstandardprofilebyid[0])
        this.$http.post(url, criteria).then(s => {
          if (s.ok) {
            // @ts-ignore
            this.friendProfile = s.body as FriendProfileDto
            return
          }
          this.failLoad()
        }, f => this.failLoad())
      } else {
        this.friendProfile = {
          age: new Date(),
          availabilityTimes: [],
          city: '',
          country: '',
          email: '',
          firstName: '',
          hasFriendInfo: true,
          otherLanguages: '',
          involvedEventCategories: 0,
          friendInfo: {
            city: '',
            desiredAge: 0,
            friendManagementGroupIds: [],
            friendshipTypes: [],
            friendVolunteerNetId: undefined,
            friendVolunteerStandardId: undefined,
            gender: 1,
            hasHealthCareTraining: false,
            hasNetProfile: false,
            hasStandardProfile: false,
            healthCareTrainingFreetext: '',
            id: 0,
            netProfile: {
              availabilityDetails: '',
              availabilityFrequence: 0,
              availabilityTimes: 0,
              customerTypes: 0,
              friendManagementGroupId: this.groupId,
              friendships: [],
              friendshipTypes: (NetFriendshipTypes.OneToOne),
              friendPools: [],
              id: 0,
              interests: 0,
              lastFriendshipEndDate: new Date(),
              multipleCustomers: false,
              onHold: false,
              onHoldDeadline: undefined,
              onHoldDetails: '',
              otherInterests: '',
              preferredAges: 0,
              preferredGenders: (PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female),
              shortDescription: '',
              startedPending: new Date(),
              state: CustomerState.PendingApproval,
              volunteerInterviewed: false,
              volunteerInterviewedBy: '',
              volunteerInterviewedDate: '',
              volunteerInterviewedOnDate: new Date(),
              econtactDiscord: '',
              econtactEmail: '',
              econtactFreetext: '',
              econtactInstagram: '',
              econtactKik: '',
              econtactSkype: '',
              econtactTelegram: '',
              privateNotes: ''
            },
            otherLanguages: '',
            phone: '',
            postalCode: '',
            profileId: 0,
            standardProfile: {
              availabilityDetails: '',
              availabilityFrequence: 0,
              customerTypes: 0,
              friendManagementGroupId: this.groupId,
              friendships: [],
              friendshipTypes: (FriendshipTypes.OneToOne),
              friendPools: [],
              id: 0,
              interests: 0,
              lastFriendshipEndDate: new Date(),
              multipleCustomers: false,
              onHold: false,
              onHoldDeadline: undefined,
              onHoldDetails: '',
              otherInterests: '',
              preferredAges: 0,
              preferredGenders: (PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female),
              shortDescription: '',
              startedPending: new Date(),
              state: CustomerState.PendingApproval,
              volunteerInterviewed: false,
              volunteerInterviewedBy: '',
              volunteerInterviewedDate: '',
              availabilityTimes: 0,
              hasAlternativeAddress: false,
              alternativeCity: '',
              alternativeStreet: '',
              alternativeZipCode: '',
              privateNotes: ''
            },
            streetAddress: ''
          },
          id: 0,
          isNonIdent: true,
          lastName: '',
          nativeLanguage: 'fi',
          phoneNumber: '',
          spokenLanguages: [],
          streetAddress: '',
          transportation: [],
          travelTime: '',
          zipCode: ''
        } as FriendProfileDto
      }
    },
    provide (): Record<string, any> {
      return {
        $validator: this.$validator // allow child components to have validator injected
      }
    },
    methods: {
      failLoad (): void {
        this.displayError(this.$t('common.fetchFailed'))
      },
      onFriendProfileChanged (newFriendProfile: FriendProfileDto): void {
        this.friendProfile = newFriendProfile
      },
      onVolunteerChanged (newVolunteer: FriendVolunteerDto): void {
        if (!this.friendProfile) return

        this.friendProfile.friendInfo = newVolunteer
      },
      onVolunteerStandardProfileChanged (newProfile: FriendVolunteerProfileStandardDto): void {
        if (!this.friendProfile) return

        this.friendProfile.friendInfo.standardProfile = newProfile
      },

      onInterviewDataChanged (newData: InterviewData): void {
        if (!this.friendProfile) return

        this.friendProfile.friendInfo.standardProfile.volunteerInterviewed = newData.interviewed
        this.friendProfile.friendInfo.standardProfile.volunteerInterviewedBy = newData.interviewedBy
        this.friendProfile.friendInfo.standardProfile.volunteerInterviewedOnDate = newData.interviewedDate
      },
      onOnHoldDataChanged (newData: OnHoldData): void {
        if (!this.friendProfile) return

        this.friendProfile.friendInfo.standardProfile.onHoldDeadline = newData.onHold ? newData.onHoldDeadline : undefined
        this.friendProfile.friendInfo.standardProfile.onHoldDetails = newData.onHold ? newData.onHoldDetails : ''
      },
      cancel (): void {
        const url = this.isEdit
          ? `/friend/volunteerpreview/${this.groupId}/${this.profileId}`
          : `/friend/search/${this.groupId}/volunteer`
        this.$router.push(url)
      },
      save (): void {
        //this.showModal = true
        if (this.friendProfile?.friendInfo?.standardProfile?.preferredAges === 0) {
          this.friendProfile.friendInfo.standardProfile.preferredAges = 1
          this.$nextTick(() => {
            if (!this.friendProfile) return
            this.friendProfile.friendInfo.standardProfile.preferredAges = 0
            this.validateAndSave()
          })
        } else {
          this.validateAndSave()
        }
      },
      validateAndSave (): void {
        this.$validator.validateAll().then(result => {
          // check any child controls have not added errors
          if (!result || (this.validationErrors && this.validationErrors.length)) {
            var target = this.$refs['validationtarget']
            if (target) {
              window.scrollTo({
                // @ts-ignore
                top: target.$el.parentElement.clientHeight,
                left: 0,
                behavior: 'smooth'
              })
            }
            return
          }
          if (this.friendProfile) {
            const data = this.friendProfile
            // @ts-ignore
            data.friendInfo.netProfile = null
            if (this.isAdd) {
              this.addFriendProfile(data)
            } else {
              this.updateFriendProfile(data)
            }
          }
        })
      },
      convertFriendProfileToUserProfile (friendProfile: FriendProfileDto): UserProfileDto {
        var returnable = {
          // Fields from CrmUserProfileDto
          acceptMarketing: false, // Default value
          city: friendProfile.city,
          email: friendProfile.email,
          firstName: friendProfile.firstName,
          id: friendProfile.id.toString(), // Assuming id in FriendProfileDto is a number
          lastName: friendProfile.lastName,
          profileId: 0, // Default value
          streetAddress: friendProfile.streetAddress,
          volunteerOnline: false, // Default value

          // Fields from UserProfileDto
          actionGroups: [], // Default value
          age: friendProfile.age,
          areasOfInterest: [], // Default value
          availabilityTimes: friendProfile.availabilityTimes,
          contactedDate: undefined, // Default value
          country: 'Suomi', // Default value
          created: undefined, // Default value
          eventAdmins: [], // Default value
          friendInfo: friendProfile.friendInfo as FriendVolunteerWithFriendshipsDto, // Assuming type compatibility //FriendVolunteerDto in FriendProfileDto
          involvedEventCategories: friendProfile.involvedEventCategories,
          isNonIdent: friendProfile.isNonIdent,
          isPublic: false, // Default value
          lockoutEnabled: false, // Default value
          motto: '', // Default value
          nativeLanguage: friendProfile.nativeLanguage,
          otherLanguages: friendProfile.otherLanguages,
          personalSkills: [], // Default value
          phoneNumber: friendProfile.phoneNumber,
          portrait: '', // Default value
          preferredContactMethods: undefined, // Default value
          profileState: ProfileState.Active, // Default value, assuming ProfileState is an enum
          sectionId: 0, // Default value
          spokenLanguages: friendProfile.spokenLanguages,
          standardGroups: [], // Default value
          story: '', // Default value
          trainedDate: friendProfile.trainedDate,
          transportation: friendProfile.transportation,
          travelTime: friendProfile.travelTime,
          useWorkingZipCode: false, // Default value
          workingZipCode: friendProfile.zipCode,
          zipCode: friendProfile.zipCode,
          codeOfEthicsAccepted: false, // Default value
          dataProtectionAccepted: false // Default value

        } as UserProfileDto
        return returnable
      },
      setDefaultValues (payload: UserProfileDto) {
        // default native language to the language set for the user if its not set
        if (!payload.nativeLanguage || payload.nativeLanguage === '') {
          payload.nativeLanguage = this.$store.state.language
        }

        // quick and dirty fix
        if (!payload.travelTime || payload.travelTime === '') {
          payload.travelTime = '60'
        }
      },
      sectionName (id: number): string {
        var self = this
        return _.find(self.$store.state.sections, function (section) {
          return section.id === id
        }).name
      },
      async addFriendProfile (data: FriendProfileDto): Promise<void> {
        if (!data) return

        this.isSaving = true
        var addUrl = ''

        if (data.email) {
          //console.log('data.email has been populated')
          //we still need to grab the profile information and show it on the modal
          var profileInfoUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.getvolunteerinfobyemail[0].replace('{emailaddress}', data.email)
          //console.log('profileInfoUrl', profileInfoUrl)
          try {
            const resp = await this.$http.get(profileInfoUrl)

            if (resp.data) {
              this.userProfile = resp.data as UserProfileDto
              this.showModal = true
              this.localProfileId = this.userProfile.profileId
              this.existingProfileSectionName = this.sectionName(resp.data.sectionId)
            }
            if (this.showModal) {
              //showmodal is set to true when the email already exists.
              //If so, we just return out of the function and let the user handle the situation
              this.isSaving = false
              return
            }
          } catch (error) {
            console.log('error', error)
            this.handleError(error)
            this.isSaving = false
            return
          }

          // first add a profile/user using data.email and continue the process normally, send password via email etc.
          // somehow we need to convert FriendprofileDto to UserProfileDto
          var registerUrl = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.registerwithfriendprofile[0]

          var useProfileDto = this.convertFriendProfileToUserProfile(data)
          this.setDefaultValues(useProfileDto)
          var friendVolunteer = this.convertFriendProfileToVolunteer(data)

          const payload = { payload1: useProfileDto, payload2: friendVolunteer }
          const self = this

          this.$http.post(`${registerUrl}?AddedByBroker=true`, payload).then(s => {
            if (s.status === 200) {
              this.isSaving = false
              this.$router.push(`/friend/search/${this.groupId}/volunteer`)
            } else {
              console.log('register failed:', s)
              this.handleError(s)
            }
          }, (err) => {
            console.log('register failed:', err)
            this.handleError(err)
          })
        } else {
          addUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.addnonidentstandardfriendvolunteer[0]
          this.$http.post(addUrl, data).then((result: any) => {
            this.isSaving = false
            this.$router.push(`/friend/search/${this.groupId}/volunteer`)
          }, (err: Response | any) => {
            this.isSaving = false
            this.displayError(err)
          })
        }
        this.isSaving = false
      },
      async addNewFriendProfile (data: FriendProfileDto): Promise<void> {
        if (!data) return

        this.isSaving = true
        var addUrl = ''
        // first add a profile/user using data.email and continue the process normally, send password via email etc.
        // somehow we need to convert FriendprofileDto to UserProfileDto
        var registerUrl = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.brokervolunteeridentadd[0]

        var friendVolunteer = this.convertFriendProfileToVolunteer(data)
        friendVolunteer.profileId = this.localProfileId

        const self = this

        this.$http.post(`${registerUrl}`, friendVolunteer).then(s => {
          if (s.status === 200) {
            this.isSaving = false
            this.$router.push(`/friend/search/${this.groupId}/volunteer`)
          } else {
            console.log('register failed:', s)
            this.handleError(s)
          }
        }, (err) => {
          console.log('register failed:', err)
          this.handleError(err)
        })

        this.isSaving = false
      },
      convertFriendProfileToVolunteer (profile: FriendProfileDto): FriendVolunteerDto {
        const volunteer: FriendVolunteerDto = {
          id: profile.id,
          friendVolunteerNetId: profile.friendInfo.friendVolunteerNetId,
          friendVolunteerStandardId: profile.friendInfo.friendVolunteerStandardId,
          gender: profile.friendInfo.gender,
          hasHealthCareTraining: profile.friendInfo.hasHealthCareTraining,
          healthCareTrainingFreetext: profile.friendInfo.healthCareTrainingFreetext,
          otherLanguages: profile.otherLanguages,
          phone: profile.phoneNumber,
          profileId: profile.id,
          hasNetProfile: profile.friendInfo.hasNetProfile,
          hasStandardProfile: profile.friendInfo.hasStandardProfile,
          netProfile: profile.friendInfo.netProfile,
          standardProfile: profile.friendInfo.standardProfile
        }
        return volunteer
      },
      handleError: function (error) {
        var rv = [] as Array<Array<string>>
        if (error.body) {
          // error.body.forEach(s => rv.push(['', this.lcFirst(s.code), '']))
          if (error.body.ErrorCode === 101) {
            // @ts-ignore cannot find mixins
            rv.push(['', this.lcFirst(error.body.TranslationKey), ''])
          } else {
            console.log('handleError.errorCode not 101', error.body)
            console.log('error: ', error)
          }
        }

        console.log(error, rv)
        // @ts-ignore
        //this.$refs.currentStage.registerErrors = rv
        this.focusOnErrors()
      },
      focusOnErrors (): void {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      },
      connectProfiles (): void {
        //this is still todo
        this.showModal = false
        if (this.friendProfile) {
          const data = this.friendProfile
          // @ts-ignore
          data.friendInfo.netProfile = null
          this.addNewFriendProfile(data)
        }
      },
      updateFriendProfile (data: FriendProfileDto): void {
        if (!data) return

        this.isSaving = true
        var updateUrl = ''
        if (data.email) {
          updateUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.updateidentstandardprofile[0]
        } else {
          updateUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.updatenonidentstandardfriendvolunteer[0]
        }
        this.$http.put(updateUrl, data).then((result: any) => {
          this.isSaving = false
          this.$router.push(`/friend/search/${this.groupId}/volunteer`)
        }, (err: Response | any) => {
          this.isSaving = false
          this.displayError(err)
        })
      },
      cancelModal (): void {
        this.showModal = false
      },
      displayError (err: Response | any): void {
        if (!err) return

        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";
  .profilenonidentstandardvolunteerform {
    .feature-panel {
      background-color: $brand-white;
      margin-bottom: 30px;
      padding-top: 15px;

      &.buttons {
        margin-top: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        .cancel {
          text-align: left;
        }
        .save {
          text-align: right;
        }
      }
    }

    .profilehead {
      margin-top: 25px;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $brand-white;
    }

    .subheader {
      margin-top: 15px;
      margin-bottom: 15px;
      display: block;
    }

    .profile-button {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .fa-exclamation-triangle {
      color: $brand-red;
    }
  }

  @media (min-width: 992px) {
  .modal .modal-huge {
     max-width: 90% !important;
     width: 90% !important;;
     max-height: 90% !important;
     height: 90% !important;
   }
   .modal-body {
    font-weight: none !important;
   }
}
</style>
