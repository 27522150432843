<template>
  <div class="container notes">
    <div class="row">
      <div class="col-md-8 col-lg-9 group-list">
        <h3>{{ $t('groups.groupMessages') }}</h3>
        <p>{{ $t('groups.getEmailsHelp') }}</p>
        <div v-if="isMemberOfGroup">
          <div v-if="subscribeToEmails !== null" class="consent-checkbox">
            <checkboxinput v-model="subscribeToEmails"
                           :title="subscribeToEmailsText"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="sendMessage">
              <div class="subject">
                <input v-model="messageSubject" type="text" :placeholder="$t('groups.messageSubjectPlaceholder')">
              </div>
              <div class="messagebody">
                <textarea v-model="messageBody" rows="5" :placeholder="$t('groups.messageBodyPlaceholder')" />
              </div>
              <!-- Styling will be improved on later tickets when doing complete overhaul on this page, leaving as it
            is for now. -->
              <div class="message-actions">
                <div>
                  <checkboxinput v-if="allowSendAsEmail"
                                 v-model="asEmail" :title="$t('sectionMessages.sendAsMail')"
                  />
                </div>
                <sprbutton type="primary"
                           :size="4"
                           :title="$t('groups.sendMessage')"
                           class="spr-redborder button-width"
                           click-event="sendMessage"
                           @sendMessage="sendMessageThread"
                />
                <sprbutton type="secondary"
                           :size="4"
                           :title="$t('groups.cancel')"
                           class="spr-redborder button-width"
                           click-event="cancel"
                           :disabled="!isPartiallyEdited"
                           @cancel="cancelSendMessage"
                />
              </div>
            </div>
          </div>
        </div>
        <noteview v-if="notes && notes.length"
                  :notes="notes"
                  :groupid="$route.params.groupid"
                  :portraits="portraits"
                  :focus="focusMessage"
                  :can-delete="isGroupAdmin"
                  :can-send-as-email="allowSendAsEmail"
                  @removed="removed"
        />
        <div v-if="messagesLoading" class="loading">
          <loading />
        </div>
      </div>
      <div class="col-md-4 col-lg-3 group-secondary flex-first">
        <a class="back" tabindex="0" href="javascript: void(0);" @click="$router.back()">
          <font-awesome-icon icon="chevron-left" class="back" />{{ $t('volunteerForm.back') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import noteview from './NotesView.vue'
  import moment from 'moment'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import loading from '../SPRLoading.vue'
  import { GroupDetailDto, MessageDto } from '@/types'
  import { PortraitInfo } from '@/types/custom'
  import { TranslateResult } from 'vue-i18n'

  // the data object
  export interface TemplateComponentData {
    notes: null | Array<MessageDto>,
    messageSubject: string,
    messageBody: string,
    asEmail: boolean,
    subscribeToEmails: null | boolean,
    group: GroupDetailDto | undefined,
    isGroupAdmin: boolean,
    messagesLoading: boolean
  }

  export default Vue.extend({
    name: 'GroupNotes',
    components: { sprbutton, noteview, checkboxinput, loading },
    data (): TemplateComponentData {
      return {
        notes: null,
        messageSubject: '',
        messageBody: '',
        asEmail: false,
        subscribeToEmails: null,
        group: undefined,
        isGroupAdmin: false,
        messagesLoading: false
      }
    },
    computed: {
      isMemberOfGroup (): boolean {
        if (!this.group) {
          return false
        }
        // @ts-ignore mixins not found
        return this.jwtToken.GroupMembership && this.jwtToken.GroupMembership.split(';').find(x => x === this.group?.id.toString())
      },
      isEdited (): boolean {
        return this.messageSubject !== '' && this.messageBody !== ''
      },
      isPartiallyEdited (): boolean {
        return this.messageSubject !== '' || this.messageBody !== ''
      },
      portraits (): Array<PortraitInfo> {
        if (!this.group) {
          return []
        }

        return _.map(_.filter(this.group.members, function (y) {
          return !!y.portrait
        }), function (x) {
          return { id: x.id, url: x.portrait }
        })
      },
      focusMessage (): number | null {
        var qsMessageId = Number(this.$route.query?.msg) || 0
        return qsMessageId > 0 ? qsMessageId : null
      },
      subscribeToEmailsText (): TranslateResult {
        return this.group && this.group.groupType === 2 ? this.$t('groups.subscribeToAdminEmails') : this.$t('groups.subscribeToEmails')
      },
      allowSendAsEmail (): boolean {
        return !!this.group && (this.group.groupType === 1 || this.isGroupAdmin)
      }
    },
    watch: {
      subscribeToEmails (val, oldVal): void {
        if (oldVal != null && val != null) {
          const newStatus = val === true ? 1 : 0
          this.setSubscriptionStatus(newStatus)
        }
      }
    },
    mounted (): void {
      const groupId = this.$route.params.groupid
      var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupisadmin[0]}?groupId=${groupId}`
      this.$http.get(url).then((resp) => {
        this.isGroupAdmin = resp.data
        this.loadGroup()
      })
      this.getMessages()
      this.getSubscriptionStatus()
      this.$bus.$on('reply-to-thread', this.handleReply)
    },
    beforeDestroy (): void {
      this.$bus.$off('reply-to-thread')
    },
    methods: {
      loadGroup (): void {
        const self = this
        const groupId = this.$route.params.groupid

        if (!groupId) this.$router.push('/profile')

        // load group
        var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupgetbyidlight[0]}?id=${groupId}`
        // @ts-ignore cannot find mixins
        if (this.isGroupAdmin || this.hasRole('Admin')) {
          //isAdmin is ALWAYS false, when loading the group for the first time.
          //admins are allowed to see phone number and email address
          url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupgetbyid[0]}?id=${groupId}`
        }

        this.$http.get(url).then((resp) => {
          if (resp.status === 403) { // Forbidden
            // @ts-ignore mixins not found
            this.showAlert403()
          }
          self.group = resp.data
        }, fail => {
          if (fail.status === 403) {
            // @ts-ignore mixins not found
            this.showAlert403()
          } else {
            // @ts-ignore cannot find mixins
            this.showAlertError(this.$t('common.fetchFailed'))
          }
        })
      },
      handleReply (reply, cb): void {
        if (!this.group) {
          return
        }
        var parent = _.find(this.notes, (x) => {
          return x.id === reply.parentId
        })
        if (!parent) {
          return
        }
        this.sendMessage({
          subject: this.$t('groups.replyPrefix') + parent.subject,
          content: reply.message,
          groupId: this.$route.params.groupid,
          senderProfileId: this.$store.state.profile.profileId,
          sendAsEmail: reply.asEmail,
          parentMessageId: reply.parentId,
          groupName: this.group.name,
          senderName: this.$store.state.profile.firstName + ' ' + this.$store.state.profile.lastName,
          link: this.$route.path
        }, true)
        cb()
      },
      removed (x): void {
        const self = this
        const url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.removenotification[0]}/${x}`
        this.$http.post(url).then(function (resp) {
                                    self.getMessages()
                                  },
                                  function (y) {
                                    console.log('error removing notification!')
                                  })
      },
      getMessages (): void {
        var self = this
        self.messagesLoading = true
        var url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.groupmessages[0]}${this.$route.params.groupid}`
        this.$http.get(url).then(resp => {
          self.notes = _.sortBy(resp.data, function (message) {
            return moment(message.sent)
          }).reverse()
          self.messagesLoading = false
        }, (fail) => {
          console.log('failed to get messages')
          self.messagesLoading = false
          // @ts-ignore cannot find mixins
          this.showAlert403()
        })
      },
      getSubscriptionStatus (): void {
        const self = this
        const url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.getSubStatus[0]}/${this.$route.params.groupid}/${this.$store.state.profile.profileId}`
        this.$http.get(url).then(resp => {
          self.handleSubResp(resp.data, self)
        })
      },
      setSubscriptionStatus (newStatus): void {
        const url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.setSubStatus[0]}/${this.$route.params.groupid}/${this.$store.state.profile.profileId}/${newStatus}`
        this.$http.post(url).then(resp => {
          this.handleSubResp(resp.data, self)
        }, fail => {
          this.subscribeToEmails = null
        })
      },
      handleSubResp (response, context): void {
        if (response.value === 1) {
          context.subscribeToEmails = true
        } else if (response.value === 0) {
          context.subscribeToEmails = false
        } else {
          context.subscribeToEmails = null
        }
      },
      sendMessageThread (): void {
        if (!this.group) {
          return
        }
        this.sendMessage({
          subject: this.messageSubject,
          content: this.messageBody,
          groupId: this.$route.params.groupid,
          senderProfileId: this.$store.state.profile.profileId,
          sendAsEmail: this.asEmail,
          groupName: this.group.name,
          senderName: this.$store.state.profile.firstName + ' ' + this.$store.state.profile.lastName,
          link: this.$route.path
        }, false)
      },
      sendMessage (messageDto, forceSend): void {
        if (!this.isEdited && !forceSend) {
          return
        }

        var self = this
        this.messagesLoading = false
        var url = `${this.$store.state.apiBases.messageservice + this.$store.state.apiEndpoints.sendgroupmessage[0]}`
        this.cancelSendMessage()
        this.$http.post(url, messageDto).then((resp) => {
          self.getMessages()
        })
      },
      cancelSendMessage () {
        this.messageSubject = ''
        this.messageBody = ''
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .notes {
    .loading {
      text-align: center;
      margin-top: 15px;
      margin-bottom: 5px;
    }

    .message-actions {
      margin-bottom: 10px;
    }

    .subject {
      input {
        margin: 3px 0;
        width: 100%;
      }
    }

    .messagebody {
      textarea {
        margin: 3px 0;
        width: 100%;
      }
    }

    .consent-checkbox {
      margin-top: 20px;
      min-height: 3em;
    }

    a {
      color: $brand-red;
      &.back {
        color: #000;
        svg {
          margin-right: 5px;
          color: $brand-red;
        }
      }
    }

    h3 {
      margin-bottom: 10px;
    }
    .group-secondary {
      background: #fff;
      padding-top: 25px;
      padding-bottom: 25px;
      .cap-label {
        display: block;
        color: $brand-red;
        margin: 40px 0 10px 0;
      }
    }

    .group-list {
      padding: 25px 25px 0 25px;
      .user-row {
        background: #fff;
        margin-bottom: 5px;
        padding: 15px;
        .user {
          a {
            color: $brand-red;
            font-weight: bold;
            text-transform: uppercase;
          }
          .user-img {
            float: left;
            margin-right: 15px;
          }
        }
        .portrait-size {
          width: 50px;
          height: 50px;
        }
      }
    }

    .flex-first {
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
    }

    @media (max-width: $media-sm-max) {
      .flex-first {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
      }

      .list-header {
        padding-bottom: 15px;
      }
    }

    .note {
      background: #fff;
      padding: 25px;
      display: block;
      margin-bottom: 10px;
      p {
        font-size: 16px!important;
      }
      .note-author {
        display: block;
        font-size: 13px;
        color: $brand-grey8;
        display: block;
        margin-top: 10px;
        span {
          font-weight: bold;
          color: #000;
        }
      }
      .sender {
        .name {
          font-weight: bold;
        }
        .date, .time {
          color: $brand-grey8;
        }
      }
    }
    .submit-note {
      margin: 40px 0;
    }
  }

</style>
