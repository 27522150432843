<template>
  <div v-if="localSuitabilityData" class="suitabilityform">
    <div class="row">
      <div class="col-12">
        <checkboxinput
          v-model="localSuitabilityData.notForPrivateRelations"
          :title="$t('customerForm.customerNotForPrivateRelations')"
          :aria-label="$t('customerForm.customerNotForPrivateRelations')"
          @input="update"
        />
      </div>
    </div>
    <div v-if="localSuitabilityData.notForPrivateRelations" class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <forminput
          v-model="localSuitabilityData.notForPrivateRelationsBy"
          v-validate="'required'"
          :placeholder="$t('customerForm.customerInterviewedBy')"
          :is-valid="!errors.has('customerForm.groupName')"
          name="customerForm.groupName"
          :required="true"
          @input="update"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <forminput
          v-model="localSuitabilityData.notForPrivateRelationsReason"
          v-validate="'required'"
          :placeholder="$t('customerForm.customerForGroupsMoreInfo')"
          :is-valid="!errors.has('customerForm.notForPrivateReason')"
          name="customerForm.notForPrivateReason"
          :required="true"
          @input="update"
        />
      </div>
    </div>

    <!-- not for spr -->
    <div class="row">
      <div class="col-12">
        <checkboxinput
          v-model="localSuitabilityData.notForSpr"
          :title="$t('customerForm.customerNotForSPR')"
          :aria-label="$t('customerForm.customerNotForSPR')"
          @input="update"
        />
      </div>
    </div>
    <div v-if="localSuitabilityData.notForSpr" class="row">
      <div class="col-12 col-sm-6 col-md-4">
        <forminput
          v-model="localSuitabilityData.notForSprBy"
          v-validate="'required'"
          :placeholder="$t('customerForm.customerInterviewedBy')"
          :is-valid="!errors.has('customerForm.notForSprBy')"
          name="customerForm.notForSprBy"
          :required="true"
          @input="update"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <forminput
          v-model="localSuitabilityData.notForSprReason"
          v-validate="'required'"
          :placeholder="$t('customerForm.declineMoreInfo')"
          :is-valid="!errors.has('customerForm.notForSprReason')"
          name="customerForm.notForSprReason"
          :required="true"
          @input="update"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import forminput from '../SPRSimpleText.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import { SuitabilityData } from '../../types/custom'

  // the data object
  export interface TemplateComponentData {
    localSuitabilityData: undefined | SuitabilityData
  }

  export default Vue.extend({
    name: 'SuitabilityForm',
    components: {
      forminput,
      checkboxinput
    },
    inject: ['$validator'],
    props: {
      value: {
        type: Object as () => SuitabilityData,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        localSuitabilityData: undefined as undefined | SuitabilityData
      }
    },
    computed: {
    },
    watch: {
      value: function (newVal: SuitabilityData): void {
        this.localSuitabilityData = newVal
      }
    },
    mounted (): void {
      if (this.value) {
        this.localSuitabilityData = this.value
      }
    },
    methods: {
      update (): void {
        if (!this.localSuitabilityData?.notForPrivateRelations) {
          if (this.localSuitabilityData) {
            this.localSuitabilityData.notForPrivateRelationsReason = ''
            this.localSuitabilityData.notForPrivateRelationsBy = ''
          }
        }

        if (!this.localSuitabilityData?.notForSpr) {
          if (this.localSuitabilityData) {
            this.localSuitabilityData.notForSprReason = ''
            this.localSuitabilityData.notForSprBy = ''
          }
        }

        this.$emit('input', this.localSuitabilityData)
      }
    }
  })
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.suitabilityform {
  background-color: $brand-white;

  .subheader {
    color: $brand-red;
    text-transform: uppercase;
    font-family: $font-bold;
    margin: 0 0 10px 20px;
    display: block;
    font-size: 14px;
  }

  .sectionheader {
    margin: 15px 0;
  }
}
</style>
