<template>
  <div v-if="group" class="container volunteerfindmatch">
    <volunteer-find-net-match
      v-if="group.netFriendship"
      :group="group"
      :volunteerid="volunteerIdNumber"
    />
    <volunteer-find-standard-match
      v-else
      :group="group"
      :volunteerid="volunteerIdNumber"
    />
    <div v-show="isLoading" class="loading">
      <div class="spinner">
        <loading />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import { HttpResponse } from 'vue-resource/types/vue_resource'
  import loading from '../SPRLoading.vue'
  import VolunteerFindNetMatch from './VolunteerFindNetMatch.vue'
  import VolunteerFindStandardMatch from './VolunteerFindStandardMatch.vue'
  import { UserProfileDto, FriendManagementDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    group: undefined | FriendManagementDto,
    isLoading: boolean
  }

  export default Vue.extend({
    name: 'VolunteerFindMatch',
    components: { VolunteerFindNetMatch, VolunteerFindStandardMatch, loading },
    props: {
      groupid: {
        type: String,
        required: true
      },
      volunteerid: {
        type: String,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        group: undefined,
        isLoading: false
      }
    },
    computed: {
      volunteerIdNumber (): number {
        return Number(this.volunteerid)
      }
    },
    created (): void {
      this.isLoading = true
      this.getGroup().then(response => {
        this.group = response.data
        this.isLoading = false
      }, f => {
        // @ts-ignore (cannot find mixins)
        this.showAlertPermissionDenied()
      })
    },
    methods: {
      getGroup (): PromiseLike<HttpResponse> {
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.groupid)
        return this.$http.get(url)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteerfindmatch {
    .loading {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-color:rgba(255, 255, 255, 0.8);
      .spinner {
        margin: auto;
        color:$brand-red;
      }
    }
  }
</style>
