<template>
  <div class="row edit-event-roles">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <div v-for="(role, index) in computedRoles" :key="role.key" class="row edit-role">
            <div v-show="role.isEdit" class="col-4">
              <forminput
                v-model="role.name"
                v-validate.initial="initialRoleValidation(index)"
                :name="'eventPage.roleName'"
                :data-vv-scope="'role' + role.key"
                :is-valid="!errors.has('role' + role.key + '.eventPage.roleName')"
                :compact="true"
                :placeholder="$t('eventPage.roleNamePlaceholder')"
                class="pb-0"
              />
            </div>
            <div v-show="role.isEdit" class="col-3 mt-1">
              <checkboxinput :title="$t('eventPage.unlimitedParticipants')"
                             :value="role.maximumParticipants === 0"
                             :aria-label="$t('eventPage.unlimitedParticipants')"
                             @input="onUnlimitedParticipantsChanged(role, $event)"
              />
            </div>
            <div class="col-3">
              <numberinput
                v-show="role.isEdit && role.maximumParticipants > 0"
                v-validate="'between:1,10000'"
                :value="Number(role.selectedMaxParticipants)"
                :step="1"
                :min="1"
                :max="10000"
                :name="'maxParticipants'"
                :data-vv-scope="'role' + role.key"
                :is-valid="!errors.has('role' + role.key + '.maxParticipants')"
                :compact="true"
                @input="onMaxParticipantsChanged(role, $event)"
              />
            </div>
            <div v-show="!role.isEdit" class="col-10">
              <span>{{ role.name }}</span>
              <span v-if="role.maximumParticipants === 0"> ({{ role.participants ? role.participants.length : 0 }})</span>
              <span v-else> ({{ role.participants ? role.participants.length : 0 }}/{{ role.maximumParticipants }})</span>
            </div>
            <div class="col-2 editbuttons" :class="{'edit': role.isEdit}">
              <a :class="{ 'disabled': errors.any('role' + role.key) }" class="edit-role" tabindex="0" href="javascript:void(0);" @click="errors.any('role' + role.key) || editRole(role)">
                <font-awesome-icon :title="$t('eventPage.edit')" class="brand-red" :icon="role.isEdit ? 'check' : 'pencil-alt'" aria-hidden="true" />
              </a>
              <a :class="{ 'disabled': (role.participants && role.participants.length) || editorRoles.length <= 1 }" tabindex="1" href="javascript:void(0);" @click="deleteRole(index)">
                <font-awesome-icon :title="deleteRoleText(role)" class="brand-red" icon="times" aria-hidden="true" />
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <transition name="warn">
                <div v-if="isShowWarning" class="warning">
                  {{ $t('eventPage.noRolesWarning') }}
                </div>
              </transition>
            </div>
            <div class="col-12 mt-3">
              <sprbutton :size="5"
                         :disabled="hasErrors"
                         :title="$t('eventPage.addRole')"
                         :bordersize="2"
                         class="next-button"
                         click-event="addRole"
                         @addRole="addRole"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import _ from 'lodash'
  import forminput from '../SPRSimpleText.vue'
  import numberinput from '../SPRSimpleNumberInput.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import sprbutton from '../SPRButton.vue'

  let roleKey = 0 // unique key for dynamic validation

  // the data object
  export interface TemplateComponentData {
    editorRoles: Array<any>
  }

  export default Vue.extend({
    name: 'Editeventroles',
    components: {
      forminput,
      numberinput,
      checkboxinput,
      sprbutton
    },
    inject: ['$validator'],
    props: {
      // Value is the list of roles, bound to v-model
      value: {
        type: Array,
        default: () => []
      },
      eventid: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        editorRoles: [] // internal representation of roles, with added goodies
      } as TemplateComponentData
    },
    computed: {
      isShowWarning (): boolean {
        // TODO: redesign errors for this
        return false // this.errors.any()
      },
      hasErrors (): boolean {
        //@ts-ignore
        return Boolean(_.find(this.errors.items, function (e) {
          return e.scope && e.scope.startsWith('role')
        }))
      },
      computedRoles: {
        get () {
          //@ts-ignore
          return this.editorRoles
        },
        set (inputRoles) {
          //@ts-ignore
          this.editorRoles = _.map(inputRoles, function (role) {
            return _.assignIn({}, role, { // add these goodies to whatever we get from the prop
              isEdit: false,
              selectedMaxParticipants: role.maximumParticipants > 0 ? role.maximumParticipants : 20,
              key: roleKey++ // iterate validation key
            })
          })
        }
      }
    },
    watch: {
      computedRoles (): void {
        //@ts-ignore
        this.update()
      }
    },
    mounted (): void {
      //@ts-ignore
      this.computedRoles = this.value
    },
    methods: {
      initialRoleValidation (index) {
        //@ts-ignore
        return `required:true|excluded:${this.computedRoles.filter(function (_, i) { return index !== i }).map(x => x.name)}`
      },
      onUnlimitedParticipantsChanged: function (role, isUnlimited) {
        role.maximumParticipants = isUnlimited ? 0 : role.selectedMaxParticipants
      },
      onMaxParticipantsChanged: function (role, maxParticipants) {
        role.maximumParticipants = role.selectedMaxParticipants = Number(maxParticipants)
      },
      addRole: function () {
        //@ts-ignore
        _.forEach(this.editorRoles, function (role) {
          _.set(role, 'isEdit', false) // adding new role closes all other editors
        })
        //@ts-ignore
        this.$set(this.editorRoles, this.editorRoles.length, {
          name: '',
          //@ts-ignore
          eventId: this.eventid,
          maximumParticipants: 0, // internal value, 0 == unlimited
          selectedMaxParticipants: 20, // default value for when "unlimited" is unchecked
          isEdit: true,
          key: roleKey++ // iterate validation key
        })
      },
      update: function () {
        //@ts-ignore
        if (!this.hasErrors) {
          //@ts-ignore
          this.$emit('input', this.computedRoles)
        }
      },
      editRole: function (role) {
        role.isEdit = !role.isEdit
        //@ts-ignore
        if (!role.isEdit) this.update()
      },
      deleteRole: function (index) {
        //@ts-ignore
        if (this.editorRoles.length > 1 && (!this.editorRoles[index].participants || this.editorRoles[index].participants.length === 0)) {
          //@ts-ignore
          this.$delete(this.editorRoles, index)
        }
      },
      deleteRoleText: function (role) {
        if (role.participants && role.participants.length) return this.$t('eventPage.cannotDeleteWithParticipants')
        //@ts-ignore
        if (this.editorRoles.length <= 1) return this.$t('eventPage.onlyRole')
        return this.$t('eventPage.remove')
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";

  .edit-event-role-header {
      display: flex;
      align-items: center;
  }

  .event-header-chevron {
    margin-bottom: 9px;
  }

  .cap-label {
    margin-left: -13px;
    white-space: nowrap;
  }

  .edit-event-roles {
    .disabled {
      svg {
        color:$brand-grey3;
        cursor: default;
      }
    }
    .warning {
      color:$brand-danger;
      margin-bottom: 10px;
    }
    .warn-enter-active {
      transition: all .3s ease;
    }
    .warn-leave-active {
      transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .warn-enter, .warn-leave-to {
      opacity: 0;
    }
    .spr-danger {
      border: 2px solid $brand-red !important;
    }
    .edit-role {
      height: 40px;
      padding-right: 4px;
    }
    .editbuttons {
      svg {
        width: 16px;
      }
      &.edit {
        margin-top: 10.5px
      }
    }
  }
</style>