<template>
  <div v-show="warnings.length > 0" class="warning-message">
    <span class="fa-stack fa-lg warning-icon">
      <font-awesome-icon icon="exclamation-triangle" class="anne-sized" aria-hidden="true" />
    </span>
    <span class="warning-container fill-height">
      <span v-if="useTranslations">
        <p v-for="(warning, index) in warnings" :key="index">{{ $t(warning) }}</p>
      </span>
      <span v-else>
        <p v-for="(warning, index) in warnings" :key="index">{{ warning }}</p>
      </span>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'SprWarning',
    props: {
      warnings: {
        type: Array,
        default () {
          return []
        }
      },
      useTranslations: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .warning-message {
    box-sizing: border-box;
    color: $brand-warning;
    background: $brand-white;
    border: solid 2px $brand-warning;
    display: inline-block;
    position: relative;
    align-content: center;
    flex-flow: row;
    border-radius: 5px;

    max-width: 500px;
    width: 100%;
    padding: 10px 0;
    margin: 0;

    .warning-icon {
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      margin: auto;
      margin-left: 10px;
      margin-top: 15px;
      font-size: 20px;
      align-items: center;
    }

    .warning-container {
      display: block;
      text-align: left;
      padding-left: 80px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;

      p {
        display: block;
        color: $brand-red;
        margin: 0;
      }
    }

    .anne-sized {
      font-size: 150%;
    }
  }
</style>
