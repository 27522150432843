
<template>
  <div class="poolsliststandard">
    <div class="row">
      <div class="col-12">
        <poolview
          v-for="pool in filteredPools"
          v-show="!loading"
          :key="pool.id"
          :pool="pool"
          :groupid="fmgId"
          action-view="counts"
        />
        <div v-show="loading" class="loading">
          <div class="spinner">
            <loading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import poolview from './PoolView.vue'
  import loading from '../SPRLoading.vue'
  import { FriendPoolStandardDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    loading: boolean,
    fmgId: number,
    pools: Array<FriendPoolStandardDto>
  }

  export default Vue.extend({
    name: 'PoolsListStandard',
    components: { poolview, loading },
    props: {
      filter: {
        type: Array,
        default: () => []
      },
      search: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        loading: false,
        fmgId: Number(this.$route.params.id),
        pools: []
      }
    },
    computed: {
      filteredPools (): Array<FriendPoolStandardDto> {
        var self = this
        var filtered = this.pools
        if (this.filter && this.filter.length) {
          filtered = _.filter(self.pools, (pool) => {
            return _.includes(self.filter, pool.status)
          })
        }

        if (this.search) {
          filtered = _.filter(filtered, (pool) => {
            return pool.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          })
        }

        return filtered
      }
    },
    mounted (): void {
      this.getPools()
    },
    methods: {
      getPools (): void {
        this.loading = true
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.getfriendmanagementpoolsstandard[0].replace('{fmgid}', this.fmgId)
        var self = this
        this.$http.get(url).then(resp => {
          self.pools = resp.data
          self.loading = false
        })
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .poolsliststandard {
    .loading {
      text-align: center;
    }
  }
</style>
