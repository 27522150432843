<template>
  <div class="friendicon" :class="friendStateStyle" :title="titleText">
    <div class="lettercontainer">
      <div class="lettertext">
        {{ letter }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import { FriendshipState } from '@/types/index'

  export default Vue.extend({
    name: 'FriendshipIcon',
    props: {
      friendname: {
        type: String,
        default: ''
      },
      state: {
        type: Number as () => FriendshipState,
        default: -1
      },
      multipleFriendships: {
        type: Boolean,
        default: () => false
      }
    },
    computed: {
      letter (): string {
        if (this.friendname && this.friendname.length > 0) {
          return this.friendname.substring(0, 1)
        }
        return '?'
      },
      friendStateStyle (): string {
        return `state-${this.state}`
      },
      titleText (): string | LocaleMessage {
        if (!this.state) {
          return this.$t('customerStates.unknown')
        }
        if (this.state === -1) {
          return this.$t('customerStates.unknown')
        }

        return this.$t('friendshipStates.state' + this.state)
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";
  .state-1 {
    background-color: $state-pendingfriend;
    border-color: $state-pendingfriend;
  }

  .state-2 {
    background-color: $state-activefriendship;
    border-color: $state-activefriendship;
  }

  .unknown {
    display: none;
  }

  .friendicon {
    cursor: default;
    display: inline-block;
    border-radius:50%;
    width:50px;
    height:50px;
    margin:0 auto;
    position: relative;

    .lettercontainer {
      display:flex;
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;
      align-content: center;

      .lettertext {
        align-self: center;
        font-weight: bold;
        color:$brand-white;
        font-size: 1.4em;
        text-transform: uppercase;
      }
    }
  }
</style>
