<template>
  <div class="row comp-search-result my-2 selected-column">
      <div class="col-12 grid-row">
        <div class="grid-name">
            {{ volunteer.firstName }} {{ volunteer.lastName }}
        </div>
        <a class="action-icon remove-icon" tabindex="0" @click="removeVolunteer">
            <font-awesome-icon icon="times" aria-hidden="true" class="removeIcon"/>
        </a>
      </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import sprportrait from '../SPRPortrait.vue'
  import loading from '../SPRLoading.vue'
  import enumhelper from '../../mixins/enums.js'
  import cache from '../../mixins/cache.js'
  import inpututils from '../../mixins/inpututils.js'
  import moment from 'moment'
  import { CrmDataDto, UserProfileDto } from '@/types/index'
  import { TranslateResult } from 'vue-i18n'

  // the data object
  export interface TemplateComponentData {
    open: boolean,
    roleLoadState: number,
    roles: Array<string> | undefined,
    member: CrmDataDto | undefined,
    train: Array<string> | undefined
  }

  export default Vue.extend({
    name: 'SelectedVolunteerResult',
    components: { sprbutton, sprportrait, loading },
    mixins: [cache, enumhelper, inpututils],
    props: {
      volunteer: {
        type: Object as () => UserProfileDto,
        default: null
      },
      showContacted: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        open: false,
        roleLoadState: 0,
        roles: [] as Array<string>,
        member: undefined,
        train: undefined
      }
    },
    computed: {
    },
    methods: {
        removeVolunteer () {
          this.$store.state.selectedContact = this.$store.state.selectedContact.filter(item => item !== this.volunteer.email)
          this.$store.state.selectedVolunteers = this.$store.state.selectedVolunteers.filter(item => item.email !== this.volunteer.email)
        }
    }
  })
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
 .removeIcon {
    float: right;
    margin-top: 22px;
 }
  .remove-icon {
    grid-column: 2;
    -ms-grid-column: 2;
    line-height: 60px;
  }
  .selected-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin-right: 3px !important;
    margin-left: 3px !important;
    border-radius: 13px;
    max-width: 25%;
  }
  .comp-search-result {
    background: $brand-white;
    cursor: pointer;
    border: 2px solid $brand-white;

    &:hover {
      border: 2px solid $brand-red;
    }

    .grid-row {
      display: grid;
      display: -ms-grid;
      grid-template-rows: 60px;
      -ms-grid-rows: 60px;
      grid-template-columns: 187px;
    }
    .grid-name {
      width: 100%;
      grid-column: 1;
      -ms-grid-column: 1;
      line-height: 60px;

      font-size: 13px;
      letter-spacing: 0.3px;
      font-weight: 700;
      color: $brand-red;
      text-transform: uppercase;
      font-family: "Signa-Light", Sans-Serif;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    @media (max-width: $media-md-max) {
        .grid-row {
          display: flex;
        }
    }
  }
  @media (max-width: $media-sm-max) {
    .selected-column {
      max-width: 100% !important;
    }
  }
</style>
