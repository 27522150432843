import Vue from 'vue'
import Router from 'vue-router'
import wizard from '@/components/signupwizard/Wizard.vue'

/* eslint-disable */
import join from '@/components/joinform/Join.vue'
import joinreturn from '@/components/joinform/Return.vue'
/* eslint-enable */

import landingpage from '@/components/landingpage/LandingCampaign.vue'
import netcustomer from '@/components/netsignupwizard/AnonNetCustomerForm.vue'
import standardcustomer from '@/components/standardsignupwizard/AnonCustomerForm.vue'
import thanks from '@/components/signupwizard/Wizard-Thanks.vue'
import front from '@/components/Front.vue'
import login from '@/components/Login.vue'
import adsignin from '@/components/AdSignin.vue'
import profile from '@/components/Profile.vue'
import editor from '@/components/profile-editor/Editor.vue'
import profileidentvolunteerform from '@/components/friends/ProfileIdentVolunteerForm.vue' // this is for volunteers to update their OWN profile
import profileidentvolunteerformeditcommon from '@/components/friends/ProfileIdentVolunteerFormCommon.vue'
import profileidentvolunteerformeditstandard from '@/components/friends/ProfileIdentVolunteerFormStandard.vue'
import profileidentvolunteerformeditnet from '@/components/friends/ProfileIdentVolunteerFormNet.vue'
import profileidentstandardvolunteerform from '@/components/friends/ProfileIdentStandardVolunteerForm.vue'
import profileidentnetvolunteerform from '@/components/friends/ProfileIdentNetVolunteerForm.vue'
import profilenonidentstandardvolunteerform from '@/components/friends/ProfileNonIdentStandardVolunteerForm.vue'
import profilenonidentnetvolunteerform from '@/components/friends/ProfileNonIdentNetVolunteerForm.vue'
import customerstandardform from '@/components/friends/CustomerStandardForm.vue'
import customernetform from '@/components/friends/CustomerNetForm.vue'
import customersearch from '@/components/friends/CustomerSearch.vue'
import customerpreview from '@/components/friends/CustomerPreview.vue'
import customerfindmatch from '@/components/friends/CustomerFindMatch.vue'
import customermatchvolunteerdetails from '@/components/friends/CustomerMatchVolunteerDetails.vue'
import customersummarynetlink from '@/components/friends/CustomerSummaryNetLink.vue'
import customersummarystandardlink from '@/components/friends/CustomerSummaryStandardLink.vue'
import volunteerpreview from '@/components/friends/VolunteerPreview.vue'
import volunteerfindmatch from '@/components/friends/VolunteerFindMatch.vue'
import volunteermatchcustomerdetails from '@/components/friends/VolunteerMatchCustomerDetails.vue'
import pooladd from '@/components/friends/PoolAdd.vue'
import pooledit from '@/components/friends/PoolEdit.vue'
import pooldetail from '@/components/friends/PoolDetail.vue'

import translate from '@/components/cms/Translations.vue'

import testcors from '@/components/tools/TestCors'
import testenums from '@/components/tools/TestEnums'

import useradmin from '@/components/useradmin/UserAdmin.vue'
import superadmin from '@/components/useradmin/Admin.vue'
import friendshipgroups from '@/components/useradmin/FriendshipGroups.vue'

import newvolunteerform from '@/components/groups/AddVolunteer.vue'
import group from '@/components/groups/Group.vue'
import groupall from '@/components/groups/GroupAll.vue'
import creategroup from '@/components/groups/CreateGroup.vue'
import groupnotes from '@/components/groups/Notes.vue'
import acceptinvitation from '@/components/groups/AcceptInvitation.vue'

import eventpage from '@/components/events/Event.vue'
import createevent from '@/components/events/EventCreate.vue'
import eventlist from '@/components/events/EventList.vue'
import eventleave from '@/components/events/EventLeavePage.vue'
import myorganisedevents from '@/components/events/MyOrganisedEvents.vue'
import myparticipatedevents from '@/components/events/MyParticipatedEvents.vue'

import messages from '@/components/messages/Messages.vue'
import messagedetail from '@/components/messages/MessageDetail.vue'

import restorepw from '@/components/profile-editor/ForgotPassword.vue'
import resetpw from '@/components/profile-editor/ResetPassword.vue'
import resetexpiredpw from '@/components/profile-editor/ResetExpiredPassword.vue'

import section from '@/components/sections/Section.vue'
import deletionthankyou from '@/components/DeletionThankYou.vue'
import notfound from '@/components/NotFound.vue'
import CommonMixins from '../mixins/common.js'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'front',
      component: front,
      meta: { isAnon: true }
    },
    {
      path: '/restore/:token',
      name: 'restore',
      component: resetpw,
      props: true,
      meta: { isAnon: true }
    },
    {
      path: '/resetexpired/:email',
      name: 'resetexpired',
      component: resetexpiredpw,
      props: true,
      meta: { isAnon: true }
    },
    {
      path: '/restore',
      name: 'restoreinit',
      component: restorepw,
      meta: { isAnon: true }
    },
    {
      path: '/front',
      name: 'frontfull',
      component: front,
      meta: { isAnon: true }
    },
    {
      path: '/thankyou',
      name: 'thankyou',
      component: deletionthankyou,
      props (route) {
        const props = { ...route.query }
        props.deletionrequest = Boolean(props.deletionrequest) // force to boolean
        return props
      },
      meta: { isAnon: true }
    },
    {
      path: '/nalkapaiva',
      name: 'hungerdays',
      component: landingpage,
      meta: { isAnon: true },
      alias: ['/hungerdays', '/hungerdagen']
    },
    {
      path: '/tule-mukaan',
      name: 'wizard',
      component: wizard,
      meta: { isAnon: true },
      alias: ['/register', '/komma-med']
    },
    {
      path: '/liity-jaseneksi/:page?',
      name: 'join',
      component: join,
      meta: { isAnon: true },
      props: true,
      alias: ['/bli-medlem/:page?', '/join-as-member/:page?']
    },
    {
      path: '/liity-jaseneksi/:status/:transactionId/:flow?',
      name: 'joinReturn',
      component: joinreturn,
      props: true,
      meta: { isAnon: true },
      alias: ['/bli-medlem/:status/:transactionId/:flow?', '/join-as-member/:status/:transactionId/:flow?']
    },

    {
      path: '/verkkoystava',
      name: 'netcustomer',
      component: netcustomer,
      meta: { isAnon: true }
    },
    {
      path: '/verkkoystavayhteenveto/:key',
      name: 'customersummarynet',
      component: customersummarynetlink,
      meta: { isAnon: true },
      alias: ['/customersummarynet', '/' + encodeURIComponent('onlinevänssammanfattning')]
    },
    {
      path: '/ystavatoimintayhteenveto/:key',
      name: 'customersummarystandard',
      component: customersummarystandardlink,
      meta: { isAnon: true },
      alias: ['/customersummarystandard', '/' + encodeURIComponent('standardvänssammanfattning')]
    },
    {
      path: '/ystavatoiminta',
      name: 'standardcustomer',
      component: standardcustomer,
      meta: { isAnon: true }
    },
    {
      path: '/tule-mukaan/ilmoittautuminen-onnistui',
      name: 'registerthanks',
      component: thanks,
      meta: { isAnon: true },
      alias: ['/register-thanks', '/komma-med/registrering-var-framgangsrik']
    },
    {
      path: '/tule-mukaan/:token',
      name: 'wizard-ad',
      component: wizard,
      props: true,
      meta: { isAnon: true },
      alias: ['/register/:token', '/komma-med/:token']
    },
    {
      path: '/login',
      name: 'login',
      component: login,
      meta: { isAnon: true }
    },
    {
      path: '/adsignin',
      name: 'adsignin',
      component: adsignin,
      meta: { isAnon: true },
      props: true
    },
    {
      path: '/profile',
      name: 'profile',
      component: profile
    },
    {
      path: '/profile/:editor',
      name: 'profile-editor',
      component: editor,
      props: true
    },
    {
      path: '/friend/pools/:groupid/add',
      name: 'addfriendshippool',
      component: pooladd,
      props (route) {
        const props = { ...route.params }
        props.groupid = +props.groupid // force to number
        return props
      }
    },
    {
      path: '/friend/pools/:groupid/:poolid/edit',
      name: 'editfriendshippool',
      component: pooledit,
      props (route) {
        const props = { ...route.params }
        props.groupid = +props.groupid // force to number
        props.poolid = +props.poolid
        return props
      }
    },
    {
      path: '/friend/customerpreview/:groupid/:id/:volunteerid?',
      name: 'customerpreview',
      component: customerpreview,
      props: true
    },
    {
      path: '/friend/customermatch/:groupid/:customerid',
      name: 'customerfindmatch',
      component: customerfindmatch,
      props: true
    },
    {
      path: '/friend/customermatch/:groupid/:customerid/:volunteerid',
      name: 'customermatchvolunteer',
      component: customermatchvolunteerdetails,
      props (route) {
        const props = { ...route.params }
        props.groupid = +props.groupid // force to number
        props.volunteerid = +props.volunteerid
        props.customerid = +props.customerid
        return props
      }
    },
    {
      path: '/friend/volunteerpreview/:groupid/:id/:customerid?',
      name: 'volunteerpreview',
      component: volunteerpreview,
      props: true
    },
    {
      path: '/friend/volunteermatch/:groupid/:volunteerid',
      name: 'volunteerfindmatch',
      component: volunteerfindmatch,
      props: true
    },
    {
      path: '/friend/volunteermatch/:groupid/:volunteerid/:customerid',
      name: 'volunteermatchcustomer',
      component: volunteermatchcustomerdetails,
      props (route) {
        const props = { ...route.params }
        props.groupid = +props.groupid // force to number
        props.volunteerid = +props.volunteerid
        props.customerid = +props.customerid
        return props
      }
    },
    // This is for a volunteer to add their own Ident Friendship Profile
    {
      path: '/friend/volunteer/add',
      name: 'friendprofileadd',
      component: profileidentvolunteerform
    },
    // This is for a volunteer to update their own Friendship Profile
    {
      path: '/friend/volunteer/edit',
      name: 'friendprofileedit',
      component: profileidentvolunteerform
    },
    {
      path: '/friend/volunteer/edit/common',
      name: 'friendprofileeditcommon',
      component: profileidentvolunteerformeditcommon
    },
    {
      path: '/friend/volunteer/edit/standard',
      name: 'friendprofileeditstandard',
      component: profileidentvolunteerformeditstandard
    },
    {
      path: '/friend/volunteer/edit/net',
      name: 'friendprofileeditnet',
      component: profileidentvolunteerformeditnet
    },
    {
      path: '/friend/volunteer/add/standard',
      name: 'friendprofileaddstandard',
      component: profileidentvolunteerformeditstandard,
      props (route) {
        return {
          isAdd: true
        }
      }
    },
    {
      path: '/friend/volunteer/add/net',
      name: 'friendprofileaddnet',
      component: profileidentvolunteerformeditnet,
      props (route) {
        return {
          isAdd: true
        }
      }
    },
    {
      path: '/friend/search/:id/:searchmode',
      name: 'customersearch',
      component: customersearch,
      props: true
    },
    {
      path: '/friend/groups',
      name: 'friendshipgroups',
      component: friendshipgroups
    },
    {
      path: '/friend/pools/:groupid/:poolid',
      name: 'pooldetail',
      component: pooldetail,
      props (route) {
        const props = { ...route.params }
        props.groupid = +props.groupid // force to number
        props.poolid = +props.poolid
        return props
      }
    },
    {
      // Superadmin
      path: '/admin',
      name: 'admin',
      component: superadmin
    },
    {
      /*

      /admin/user
      /admin/district
      /admin/section
      /admin/group
      /admin/event
      /admin/employee

      lists available items of that primary type
      */
      path: '/admin/:primary',
      name: 'admin-action-listing',
      component: superadmin,
      props: true
    },
    {
      /*
        /admin/user/2 ...etc -> pull data for editing by id
      */
      path: '/admin/:primary/:id',
      name: 'admin-action-edit',
      component: superadmin,
      props: true
    },
    {
      /*

      /admin/section/23/user ... etc

      -> lists available items of primary type, filtered by
      predicate contstructed from :filter and :id

      :filter can be any of the same values as :primary
      =one of [user, district, section, group, event]

      Not all permutations make sense: listing district where
      section.id = 42 makes no sense, the different combinations
      need to be validated by the state machine handling
      the admin page state

      */
      path: '/admin/:filter/:id/:primary',
      name: 'admin-action-listing-filtered',
      component: superadmin,
      props: true
    },
    {
      path: '/useradmin',
      name: 'useradmin',
      component: useradmin
    },
    {
      path: '/tools/testcors',
      name: 'testcors',
      component: testcors
    },
    {
      path: '/tools/testenums',
      name: 'testenums',
      component: testenums
    },
    {
      path: '/groups/group/:groupid/addvolunteer',
      name: 'newvolunteerform',
      component: newvolunteerform
    },
    {
      path: '/groups/create',
      name: 'creategroup',
      component: creategroup,
      props: { type: 2 }
    },
    {
      path: '/groups/group/:groupid/notes',
      name: 'groupnotes',
      component: groupnotes
    },
    {
      path: '/groups',
      name: 'groupall',
      component: groupall
    },
    {
      path: '/groups/group/:id',
      name: 'group',
      component: group
    },
    {
      path: '/groups/acceptinvitation/:id',
      name: 'acceptinvitation',
      component: acceptinvitation
    },
    {
      path: '/messages',
      name: 'messages',
      component: messages
    },
    {
      path: '/messages/:type/:id',
      name: 'messagedetail',
      component: messagedetail
    },
    {
      path: '/friend/standardcustomer/add/:groupId',
      name: 'customerstandardadd',
      component: customerstandardform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        return props
      }
    },
    { // todo - adding a net customer
      path: '/friend/netcustomer/add/:groupId',
      name: 'customernetadd',
      component: customernetform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        return props
      }
    },
    {
      path: '/friend/standardcustomer/edit/:groupId/:customerId',
      name: 'customerstandardedit',
      component: customerstandardform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        props.customerId = +props.customerId // force to number
        return props
      }
    },
    {
      path: '/friend/netcustomer/edit/:groupId/:customerId',
      name: 'customernetedit',
      component: customernetform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        props.customerId = +props.customerId // force to number
        return props
      }
    },
    // This is for a friend broker or above to add a new non ident Volunteer to a standard group
    {
      path: '/friend/standardprofile/add/:groupId',
      name: 'nonidentprofilestandardadd',
      component: profilenonidentstandardvolunteerform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        return props
      }
    },
    // This is for a friend broker or above to edit a non ident Volunteer in a standard group
    {
      path: '/friend/standardprofile/edit/:groupId/:profileId',
      name: 'nonidentprofilestandardedit',
      component: profilenonidentstandardvolunteerform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        props.profileId = +props.profileId // force to number
        return props
      }
    },
    // This is for a friend broker or above to edit an ident volunteer in a standard group
    {
      path: '/friend/standardprofile/ident/edit/:groupId/:profileId',
      name: 'identprofilestandardedit',
      component: profileidentstandardvolunteerform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        props.profileId = +props.profileId // force to number
        return props
      }
    },
    // This is for a net friend broker or above to add a non ident volunteer in a net group
    {
      path: '/friend/netprofile/add/:groupId',
      name: 'nonidentprofilenetadd',
      component: profilenonidentnetvolunteerform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        return props
      }
    },
    // This is for a net friend broker or above to edit a non ident volunteer in a net group
    {
      path: '/friend/netprofile/edit/:groupId/:profileId',
      name: 'nonidentprofilenetedit',
      component: profilenonidentnetvolunteerform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        props.profileId = +props.profileId // force to number
        return props
      }
    },
    // This is for a net friend broker or above to edit an ident volunteer in a net group
    {
      path: '/friend/netprofile/ident/edit/:groupId/:profileId',
      name: 'identprofilenetedit',
      component: profileidentnetvolunteerform,
      props (route) {
        const props = { ...route.params }
        props.groupId = +props.groupId // force to number
        props.profileId = +props.profileId // force to number
        return props
      }
    },
    {
      path: '/events',
      name: 'eventlist',
      component: eventlist,
      props: true
    },
    {
      path: '/events/organised',
      name: 'organisedevents',
      component: myorganisedevents
    },
    {
      path: '/events/participated',
      name: 'participatedevents',
      component: myparticipatedevents
    },
    {
      path: '/publicevents',
      name: 'publiceventlist',
      component: eventlist,
      props: true,
      meta: { isAnon: true }
    },
    {
      path: '/event',
      name: 'eventadd',
      component: eventpage
    },
    {
      path: '/event/cancel/:cancellationToken',
      name: 'eventleavepublic',
      component: eventleave,
      props: true,
      meta: { isAnon: true }
    },
    {
      path: '/event/:id',
      name: 'eventpage',
      component: eventpage,
      meta: { isAnon: true }
    },
    {
      path: '/event/:id/:mode',
      name: 'eventpagemode',
      component: eventpage
    },
    {
      path: '/createevent',
      name: 'createevent',
      component: createevent
    },
    {
      path: '/section',
      name: 'section',
      component: section
    },
    {
      path: '/cms/translate',
      name: 'translator',
      component: translate,
      beforeEnter: (to, from, next) => {
        const hasAdminRole = CommonMixins.methods.hasAdminRole()
        if (hasAdminRole) {
          next()
        } else {
          next(false)
        }
      }
    },
    {
      path: '/notfound',
      component: notfound,
      meta: { isAnon: true }
    },
    {
      path: '*',
      redirect: '/notfound',
      meta: { isAnon: true }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    // by default, move to the top of the page when changing between routes
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.path.startsWith('/static.html')) {
    window.location.href = to.fullPath
  } else {
    if (sessionStorage.getItem('authtoken') == null) {
      if (!to.meta.isAnon) {
        window.location.href = '/login?from=' + to.path
      }
    }

    // Used by back button in case user came from login redirect. Perhaps there is a better way..?
    sessionStorage.setItem('prev', from.path)
    next()
  }
})

export default router
