<template>
  <div class="autocomplete-dropdown">
    <input
      class="search-input"
      type="text"
      :placeholder="placeholder"
      v-model="searchText"
      @input="filterOptions"
      @click="toggleDropdown"
      @keydown.down.prevent="movePointerDown"
      @keydown.up.prevent="movePointerUp"
      @keydown.enter.prevent="selectOption"
      @blur="closeDropdown"
    />
    <ul v-if="dropdownOpen" class="dropdown">
      <li
        v-for="(option, index) in filteredOptions"
        :key="option.key"
        :class="{ highlighted: option === hoveredItem }"
        @mouseenter="hoveredItem = option"
        @mouseleave="hoveredItem = null"
        @mousedown.prevent="selectOption(index)"
      >
        {{ option.value }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { StringKeyValuePair } from '@/types/custom'

export interface TemplateComponentData {
  startingUp: boolean,
  searchText: string,
  filteredOptions: Array<StringKeyValuePair>,
  selectedOption: StringKeyValuePair | null,
  dropdownOpen: boolean,
  pointer: number,
  hoveredItem: StringKeyValuePair | null

}

export default Vue.extend({
  name: 'AutocompleteDropdown',
  props: {
    value: {
      type: Object as () => StringKeyValuePair | null,
      required: false
    },  
    options: {
      type: Array as () => Array<StringKeyValuePair>,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Type to search...'
    }
  },
  data() {
    return {
      startingUp: true,
      searchText: '',
      filteredOptions: [] as Array<StringKeyValuePair>,
      selectedOption: null as StringKeyValuePair | null,
      dropdownOpen: false,
      pointer: -1,
      hoveredItem: null
    };
  },
  mounted() {
    this.searchText = this.value ? this.value.value: '';
    this.filterOptions()
  },
  methods: {
    filterOptions() {
      this.filteredOptions = this.options.filter(option =>
        option.value.toLowerCase().includes(this.searchText.toLowerCase())
      );
      this.pointer = -1;
      if (!this.startingUp) {
        this.dropdownOpen = true;
      }
      this.startingUp = false;
    },
    toggleDropdown() {
      this.searchText = ''
      this.selectedOption = null
      this.$emit('input', this.selectedOption);
      this.filterOptions()
    },
    closeDropdown() {
      this.dropdownOpen = false;
      this.pointer = -1;
    },
    movePointerDown() {
      if (this.pointer < this.filteredOptions.length - 1) {
        this.pointer++;
      }
    },
    movePointerUp() {
      if (this.pointer > 0) {
        this.pointer--;
      }
    },
    selectOption(index) {
      if (index >= 0 && index < this.filteredOptions.length) {
        this.selectedOption = this.filteredOptions[index];
        this.searchText = this.selectedOption.value;
        this.$emit('input', this.selectedOption);
        this.dropdownOpen = false;
        this.pointer = -1;
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
.autocomplete-dropdown {
  list-style-type: none;
  padding: 0;
  position: relative;
  width: 100%;
  margin: 20px;
  z-index: 5;
  text-align: center;

  li {
    cursor: pointer;
  }
  ul {
    list-style-type: none;
    padding: 0;
    position: absolute;
    z-index: 10;
    max-height: 100px; /* Set maximum height */
    overflow-y: auto; /* Add scrollbar if content exceeds max height */
    width: 100%; /* Set width to 100% */
  }

  li.highlighted {
    background-color: #f0f0f0;
  }

  .dropdown {
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    color: #606f7b;
    border-radius: 0.25em;
    line-height: 1.25;
    text-align: left;
  }

  .dropdown > li {
    padding: 0.5em 0.75em;
  }

  input {
    display: block;
    width: 100%;
    padding: 6px 20px;
    font-size: 1em;
    color: $brand-font;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid $brand-grey3;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: border-box;
    border-radius: 60px;
    outline: none;
  }
}

</style>