<template>
  <div class="group-listing">
    <div class="row p-2">
      <div class="col col-sm-12">
        <div class="list-group">
          <div class="row">
            <div class="col-md-6 d-sm-none d-md-block cap-label table-header">
              {{ $t('userAdmin.groupName') }}
            </div>
            <div class="col-6 d-sm-none d-md-block cap-label table-header">
              {{ $t('userAdmin.groupLeader') }}
            </div>
          </div>
          <div v-for="(item) in list" :key="item.id" class="group-row">
            <useradmingroupview :group="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import useradmingroupview from './GroupView.vue'

  export default {
    name: 'Useradmingrouplist',
    components: { useradmingroupview },
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>
