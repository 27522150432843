<template>
  <div :class="{ 'spr-danger': !isValid, 'checkboxbitmasklist': true }">
    <div class="row">
      <div v-for="item in internalList" :key="item.value" :class="calculatedClass">
        <checkbox
          :value="item.value"
          :checked="isSelected(item)"
          :title="item.text"
          @changed="onChecked"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import checkbox from './SPRSimpleNumberCheckbox.vue'
  import bitmaskhelper from '@/helpers/bitmaskhelper'
  import { SprCheckboxBitmaskValue } from '../types/custom'
  import { ErrorBag, FieldError, Validator } from 'vee-validate'
  import validatorHasOptionSelected from '../mixins/validator-hasOptionSelected'

  Validator.extend('optionRequired', validatorHasOptionSelected)

  // the data object
  export interface TemplateComponentData {
    internalList: Array<SprCheckboxBitmaskValue>,
    internalValue: number
  }

  export default Vue.extend({
    name: 'SprSimpleCheckboxBitmaskList',
    components: { checkbox },
    props: {
      list: {
        // these are the all the possible values, eg [1, 2, 4, 8]]
        type: Array as () => Array<SprCheckboxBitmaskValue>,
        default () { return [] }
      },
      value: {
        // the number represents a bitmask of the values, eg 3 = 1 + 2, so the value with 1 is checked and the value with 2 is checked
        type: Number,
        default: 0
      },
      rows: {
        type: Number,
        default () { return 1 }
      },
      isValid: {
        type: Boolean,
        default: true
      }
    },
    data (): TemplateComponentData {
      return {
        internalList: this.list,
        internalValue: this.value
      }
    },
    computed: {
      calculatedClass (): string {
        return 'col-12 col-sm-' + Math.floor(12 / this.rows)
      }
    },
    watch: {
      list (newValue: Array<SprCheckboxBitmaskValue>) {
        this.internalList = newValue
      },
      value (newValue: number) {
        this.internalValue = newValue
      }
    },
    methods: {
      isSelected (val: SprCheckboxBitmaskValue): boolean {
        const currentSelectedArray = bitmaskhelper.bitmaskToArray(this.internalValue)
        return currentSelectedArray.indexOf(val.value) > -1
      },
      onChecked (val: number, isChecked: boolean): void {
        var newValue = Number(this.internalValue)
        newValue = isChecked
          ? newValue += val
          : newValue -= val
        this.internalValue = newValue
        this.$emit('input', this.internalValue)
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/scss/_variables.scss";

  .checkboxbitmasklist {
    border: 2px solid transparent !important;
    box-sizing: border-box; /* Ensure the textarea's width and height include padding and borders */
  }
  .spr-danger {
      border: 2px solid $brand-red !important;
      box-sizing: border-box; /* Ensure the textarea's width and height include padding and borders */
  }
</style>
