<template>
  <div class="row poolsadmin">
    <div class="col-12">
      <poolview
        v-for="pool in pools"
        v-show="!loading"
        :key="pool.id"
        :pool="pool"
        :groupid="Number(id)"
        :action-view="getActionView(pool.status)"
        @remove="onRemove"
      />
      <div v-show="loading" class="loading">
        <div class="spinner">
          <loading />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { HttpResponse } from 'vue-resource/types/vue_resource'
  import poolview from '../friends/PoolView.vue'
  import loading from '../SPRLoading.vue'
  import { FriendPoolStandardDto, FriendPoolNetDto, FriendManagementDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    loading: boolean,
    pools: Array<FriendPoolStandardDto | FriendPoolNetDto> | undefined,
    group: FriendManagementDto | undefined
  }

  export default Vue.extend({
    name: 'PoolsAdmin',
    components: {
      poolview,
      loading
    },
    props: {
      primary: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      filter: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        loading: false,
        pools: undefined,
        group: undefined
      }
    },
    mounted (): void {
      this.loadFMG().then((gp) => {
        this.group = gp.data
        return this.getPools()
      }).then(resp => {
        this.pools = resp.data
        this.loading = false
      }, (err: Response | any) => {
        this.displayError(err)
      })
    },
    methods: {
      loadFMG (): PromiseLike<HttpResponse> {
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.id)
        return this.$http.get(url)
      },
      getPools (): PromiseLike<HttpResponse> {
        this.loading = true
        const url = this.group?.netFriendship
          ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.getfriendmanagementpoolsnet[0].replace('{fmgid}', this.id)
          : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.getfriendmanagementpoolsstandard[0].replace('{fmgid}', this.id)
        return this.$http.get(url)
      },
      onRemove (pool): void {
        if (!confirm(this.$t('poolPreview.confirmRemoveText').toString())) return

        const url = this.group?.netFriendship
          ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.deletefriendmanagementpoolnet[0].replace('{poolid}', pool.id)
          : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.deletefriendmanagementpoolstandard[0].replace('{poolid}', pool.id)

        this.$http.delete(url)
          .then(response => this.getPools())
          .then(resp => {
            this.pools = resp.data
            this.loading = false
          }, f => this.displayError(f))
      },
      getActionView (status) {
        return status === this.$store.state.poolStatus.REMOVED ? 'remove' : 'counts'
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>
