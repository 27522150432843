<template>
  <div class="comp-footer">
    <div class="footer-row center-items">
      <router-link to="/front" class="footer-item" tab-index="0" aria-label="placeholder">
        <font-awesome-icon icon="copyright" />
        {{ $t('common.copyrightHolder') }} {{ new Date().getFullYear() }}
      </router-link>
      <a href="https://www.punainenristi.fi/tietosuoja" target="_blank" class="footer-item" tab-index="0" aria-label="placeholder">{{ $t('common.footerTermsOfService') }}</a>
      <a href="https://www.punainenristi.fi/tietosuoja" target="_blank" class="footer-item" tab-index="0" aria-label="placeholder">{{ $t('common.footerInformationProtection') }}</a>
      <a href="https://rednet.punainenristi.fi/oma" class="footer-item" tab-index="0" aria-label="support link">{{ $t('common.footerSupport') }}</a>
    </div>
    <div class="footer-row center-items">
      <!-- twitter icon -->
      <a href="https://twitter.com/punainenristi" target="_blank" class="fa-stack fa-lg footer-item" tab-index="0" aria-label="twitter account link">
        <font-awesome-layers>
          <font-awesome-icon icon="circle" />
          <!-- Using the brand style icon -->
          <font-awesome-icon :icon="['fab', 'x-twitter']" transform="shrink-6" style="color: white;" />
        </font-awesome-layers>
      </a>

      <!-- facebook icon -->
      <a href="https://www.facebook.com/punainenristi/" target="_blank" class="fa-stack fa-lg footer-item" tab-index="0" aria-label="facebook community media link">
        <font-awesome-layers>
          <font-awesome-icon icon="circle" />
          <font-awesome-icon :icon="['fab', 'facebook-f']" transform="shrink-6" style="color: white;" />
        </font-awesome-layers>
      </a>
    </div>
    <div class="version-info">
      {{ versionInfo }}
      {{ versionInfoSeparator }}
      <a v-if="omaApiVersion" :href="omaWebApiVersionLink" target="_blank" class="footer-link-basic" tabindex="0">
        Oma Web API Version: {{ omaApiVersion }}</a>
    </div>
  </div>
</template>

<script>
  import version from '../../package'

  export default {
    name: 'SprFooter',
    data () {
      return {
        appVersion: version,
        omaApiVersion: '0.0.0'

      }
    },
    computed: {
      omaWebApiVersionLink: function () {
        const omaWebApiVersion = this.$store.state.apiBases.contentservice + this.$store.state.apiEndpoints.omaWebApiVersion[0]

        return omaWebApiVersion
      },
      versionInfo: function () {
        return this.appVersion.version
      },
      versionInfoSeparator: function () {
        return '| '
      }
    },
    created () {
      const url = this.$store.state.apiBases.contentservice + this.$store.state.apiEndpoints.omaWebApiVersion[0]
      const self = this
      this.$http.get(url).then(resp => {
        // if they are not members of a first aid group, no link for them.
        if (resp.body) {
          self.omaApiVersion = resp.body.fileVersion
        }
      })
    }
  }
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.comp-footer {
  width: 100%;
  min-height: $footer-height;
  background: $brand-grey1;

  .footer-row {
    width: 100%;
  }

  .center-items {
    text-align: center;
  }

  .footer-item {
    line-height: 40px;
    padding: 10px;
    font-family: "Signa-Light", Sans-Serif;
    text-decoration: none;
    color: $brand-grey13;
    font-weight: bold;
    transition: color 0.75s;
  }

  .footer-item:hover {
    color: $brand-black;
  }

  .version-info {
    text-align: center;
    font-size: 11px;
    margin-top: 12px;
  }

  .footer-link-basic {
      font-family: $font-light;
      font-size: 11px;
      color:$brand-black;

      &:not(:first-of-type) {
        margin: 0 0 0 15px;
      }
    }
}
</style>
