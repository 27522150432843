
<template>
  <div class="product-view">
    <div class="col-11 col-md-7 col-lg-9">
      <span :class="{'header': true, 'disabled': !!product.disabled}">{{ product.code }}, {{ product.priceCents }} snt</span>
    </div>
    <div class="d-none d-md-block col-11 col-md-5 col-lg-3">
      <sprbutton class="w-100 text-center"
                 type="primary"
                 :size="1"
                 title="Muokkaa"
                 click-event="edit"
                 @edit="$emit('edit', product)"
      />
    </div>
    <div class="d-block d-md-none col-1 text-center edit-icon">
      <font-awesome-icon icon="edit" class="brand-red" @click="$emit('edit', product)" />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  import { SprPaymentProduct } from '@/types/custom'

  export default Vue.extend({
    name: 'ProductView',
    components: { sprbutton },
    props: {
      product: {
        type: Object as () => SprPaymentProduct | null,
        default: null
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .product-view {
    margin-bottom: 5px;

    .fa {
      line-height: 48px;
    }

    .edit-icon {
      cursor: pointer;
    }

    .header {
      font-size: 20px;
      line-height: 42px;

      &.disabled {
        text-decoration:line-through;
        color: $brand-grey3;
        text-decoration-color: rgba(201, 201, 201, 0.4);
      }
    }
  }
</style>
