<template>
  <div :class="{ 'spr-searchbox': true, 'spr-roundbox-1': true }">
    <input v-model="searchtext" class="form-control" :placeholder="placeholder" tabindex="0">
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { debounce } from 'lodash'

  // the data object
  export interface TemplateComponentData {
    searchtext: string
  }
  export default Vue.extend({
    name: 'SprSearchBox',
    props: {
      value: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        searchtext: this.value
      }
    },
    watch: {
      value () {
        this.searchtext = this.value
      },
      searchtext: debounce(function (val: any) {
        //@ts-ignore
        this.$emit('input', val.trim())
      }, 500)

    }
  })
</script>

<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .spr-searchbox {
    background-color:$brand-white;
    position: relative;
    border: solid 1px $brand-grey3;

    input
    {
      margin-right: 30px;
      border-radius: 2rem;
      &:focus-visible {
        color: #495057;
        border: solid 1px $brand-grey5;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $brand-grey3;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

    }

    svg {
      position: absolute;
      right: 15px;
      top: 10px;
    }

  }

</style>
