<template>
  <button class="spr-roundbox-1 spr-cloud-check"
          :class="{ 'checked spr-shadow': checked, 'unchecked': !checked }"
          :tabindex="tabindex"
          @click="handleClick"
  >
    {{ title }}
  </button>
</template>

<script>
  export default {
    name: 'SprCheckbox',
    props: {
      title: {
        type: String,
        default: ''
      },
      clickEvent: {
        type: String,
        default: ''
      },
      value: {
        type: [Number, String],
        required: true
      },
      ariaLabel: {
        type: String,
        default: ''
      },
      tabindex: {
        type: Number,
        default: 0
      },
      collection: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        checked: false
      }
    },
    mounted: function () {
      /*
    Pre-selected handling. Not the most elegant of
    solutions. Iterates the array and tries to find its
    value from it. If it does, 'clicks' itself.
    TODO(JanneF): re-implement this to support v-model
    and drop this functionality from the component and
    delegate it to the user instead.
    */
      if (this.collection.indexOf(this.value) >= 0) {
        this.handleClick()
      }
    },
    methods: {
      handleClick: function () {
        this.checked = !this.checked

        if (this.clickEvent && this.value) {
          this.$emit(this.clickEvent, this.value, this.checked)
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .spr-cloud-check {
    display: inline-block;
    background: none;
    color: $brand-white;
    padding: 8px 15px;
    margin: 3px;
    cursor: pointer;
    font-family: "Signa-Light", Sans-Serif;
    font-weight: bold;
    letter-spacing: 0.75px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid $brand-white;

    @media (max-width: $media-sm-max) {
      width: 98%;
      display: block;
      margin-bottom: 10px;
    }
  }

  .checked {
    background: #eb0000;
    color: $brand-white !important;
    border: 1px solid #eb0000 !important;

    &:hover {
      background: #ba0000;
      border: 1px solid #ba0000 !important;
      color: $brand-white !important;
    }
  }

  .unchecked {
    &:hover {
      background: $brand-red;
      border: 1px solid $brand-red !important;
      color: $brand-white !important;
    }
  }
</style>
