<template>
  <div class="container">
    <div class="profileidentvolunteerform">
      <div class="row">
        <div class="col-12">
          <h2>{{ addEditTitle }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center py-2">
          <validation :id="'validationTarget' + _uid"
                      ref="validationtarget"
                      :validation-errors="validationErrors"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <VolunteerProfileCommon :volunteer="volunteer" @input="onVolunteerChanged" />
        </div>
      </div>
      <div v-if="!hasStandardProfile && !hasNetProfile" class="row profilehead">
        <div class="col-12">
          <span class="subheader">{{ $t('volunteerForm.selectProfileInfo') }}</span>
        </div>
      </div>
      <div v-if="hasStandardProfile">
        <div class="row profilehead">
          <div class="col-7">
            <span class="subheader">{{ $t('volunteerForm.standardInfo') }}</span>
          </div>
          <div class="col-5 text-right">
            <SprButton v-if="!volunteer.standardProfile.id"
                       :size="5"
                       class="compact"
                       :title="$t('volunteerForm.remove')"
                       click-event="onStandardProfileRemove"
                       @onStandardProfileRemove="removeStandardForm"
            />
          </div>
        </div>
        <div v-if="hasStandardProfile" class="row feature-panel">
          <div class="col-12">
            <VolunteerProfileStandard :volunteer="volunteer.standardProfile" @input="onVolunteerStandardProfileChanged" />
          </div>
        </div>
      </div>
      <div v-if="hasNetProfile">
        <div class="row profilehead">
          <div class="col-7">
            <span class="subheader">{{ $t('volunteerForm.netInfo') }}</span>
          </div>
          <div class="col-5 text-right">
            <SprButton v-if="!volunteer.netProfile.id"
                       :size="5"
                       class="compact"
                       :title="$t('volunteerForm.remove')"
                       click-event="onNetProfileRemove"
                       @onNetProfileRemove="removeNetForm"
            />
          </div>
        </div>
        <div v-if="hasNetProfile" class="row feature-panel">
          <div class="col-12">
            <VolunteerProfileNet :volunteer="volunteer.netProfile" @input="onVolunteerNetProfileChanged" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 profile-button">
          <div v-if="!hasStandardProfile && !hasNetProfile" class="btn-option">
            <a name="standard"></a>
            <SprButton :primary="true"
                       :size="4"
                       :title="$t('volunteerForm.addStandardProfile')"
                       click-event="onAddStandardProfile"
                       @onAddStandardProfile="showStandardForm"
            />
          </div>
          <a name="net"></a>
          <div v-if="!hasNetProfile && !hasStandardProfile" class="btn-option">
            <SprButton :primary="true"
                       :size="4"
                       :title="$t('volunteerForm.addNetProfile')"
                       click-event="onAddNetProfile"
                       @onAddNetProfile="showNetForm"
            />
          </div>
        </div>
      </div>
      <div class="row feature-panel buttons">
        <div class="col-6 cancel">
          <SprButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.cancel')"
                     click-event="onCancel"
                     @onCancel="cancel"
          />
        </div>
        <div class="col-6 save">
          <SprButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.save')"
                     :disabled="!hasStandardProfile && !hasNetProfile"
                     click-event="onSave"
                     @onSave="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import SprButton from '@/components/SPRButton.vue'
  import VolunteerProfileCommon from '@/components/friends/VolunteerProfileCommon.vue'
  import VolunteerProfileStandard from '@/components/friends/VolunteerProfileStandard.vue'
  import VolunteerProfileNet from '@/components/friends/VolunteerProfileNet.vue'
  import validation from '../SPRValidationError.vue'
  import Alerts from '@/mixins/alerts.js'
  import { ErrorBag, FieldError } from 'vee-validate'
  import { FriendVolunteerDto, FriendVolunteerProfileStandardDto, FriendVolunteerProfileNetDto, CustomerState, PreferredGenders, FriendshipTypes, NetFriendshipTypes } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    volunteer: FriendVolunteerDto
  }

  // This is the component for a user to add and update their own Friend profile.
  export default Vue.extend({
    name: 'ProfileIdentVolunteerForm',
    components: { validation, VolunteerProfileCommon, VolunteerProfileStandard, VolunteerProfileNet, SprButton },
    mixins: [Alerts],
    props: {
    },
    data (): TemplateComponentData {
      return {
        volunteer: {
          city: '',
          desiredAge: 0,
          friendManagementGroupIds: [],
          friendshipTypes: [],
          friendVolunteerNetId: undefined,
          friendVolunteerStandardId: undefined,
          gender: 1,
          hasHealthCareTraining: false,
          hasNetProfile: false,
          hasStandardProfile: false,
          healthCareTrainingFreetext: '',
          id: 0,
          netProfile: {
            availabilityDetails: '',
            availabilityFrequence: 0,
            availabilityTimes: 0,
            customerTypes: 0,
            friendManagementGroupId: 0,
            friendships: [],
            friendPools: [],
            friendshipTypes: (NetFriendshipTypes.OneToOne),
            id: 0,
            interests: 0,
            lastFriendshipEndDate: new Date(),
            multipleCustomers: false,
            onHold: false,
            onHoldDeadline: undefined,
            onHoldDetails: '',
            otherInterests: '',
            preferredAges: 0,
            preferredGenders: (PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female),
            shortDescription: '',
            startedPending: new Date(),
            state: CustomerState.PendingApproval,
            volunteerInterviewed: false,
            volunteerInterviewedBy: '',
            volunteerInterviewedDate: '',
            econtactDiscord: '',
            econtactEmail: '',
            econtactFreetext: '',
            econtactInstagram: '',
            econtactKik: '',
            econtactSkype: '',
            econtactTelegram: '',
            privateNotes: ''
          },
          otherLanguages: '',
          phone: this.$store.state.profile.phoneNumber,
          postalCode: '',
          profileId: this.$store.state.profile.profileId,
          standardProfile: {
            availabilityDetails: '',
            availabilityFrequence: 0,
            customerTypes: 0,
            friendManagementGroupId: 0,
            friendships: [],
            friendshipTypes: (FriendshipTypes.OneToOne),
            friendPools: [],
            id: 0,
            interests: 0,
            lastFriendshipEndDate: new Date(),
            multipleCustomers: false,
            onHold: false,
            onHoldDeadline: undefined,
            onHoldDetails: '',
            otherInterests: '',
            preferredAges: 0,
            preferredGenders: (PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female),
            shortDescription: '',
            startedPending: new Date(),
            state: CustomerState.PendingApproval,
            volunteerInterviewed: false,
            volunteerInterviewedBy: '',
            volunteerInterviewedDate: '',
            availabilityTimes: 0,
            hasAlternativeAddress: false,
            alternativeCity: '',
            alternativeStreet: '',
            alternativeZipCode: '',
            privateNotes: ''
          },
          streetAddress: ''
        } as FriendVolunteerDto
      }
    },
    computed: {
      isAdd (): boolean {
        return Boolean(!this.$store.state.profile.friendInfo)
      },
      isEdit (): boolean {
        return Boolean(this.$store.state.profile.friendInfo)
      },
      addEditTitle (): string | LocaleMessage {
        return this.isEdit
          ? this.$t('volunteerForm.updateTitle')
          : this.$t('volunteerForm.addTitle')
      },
      hasStandardProfile (): boolean {
        return this.volunteer.hasStandardProfile
      },
      hasNetProfile (): boolean {
        return this.volunteer.hasNetProfile
      },
      validationErrors (): Array<Array<string>> {
        var errs: ErrorBag = this.errors ? this.errors : []
        if (errs.items.length) {
          return errs.items.map((err: FieldError) => {
            return [`error_${err.field}`, err.rule ? err.rule : '', err.msg ? err.msg : '']
          })
        }
        return []
      }
    },
    mounted (): void {
      if (this.isEdit) {
        this.volunteer = this.$store.state.profile.friendInfo
      }
      // else its an add
    },
    provide (): Record<string, any> {
      return {
        $validator: this.$validator // allow child components to have validator injected
      }
    },
    methods: {
      onVolunteerChanged (newVolunteer: FriendVolunteerDto): void {
        // only change the basic details, dont overwrite the net and standard profiles.
        const origStandardProfile = { ...this.volunteer.standardProfile }
        const origNetProfile = { ...this.volunteer.netProfile }
        this.volunteer = newVolunteer
        this.volunteer.standardProfile = origStandardProfile
        this.volunteer.netProfile = origNetProfile
      },
      onVolunteerStandardProfileChanged (newProfile: FriendVolunteerProfileStandardDto): void {
        this.volunteer.standardProfile = newProfile
      },
      onVolunteerNetProfileChanged (newProfile: FriendVolunteerProfileNetDto): void {
        this.volunteer.netProfile = newProfile
      },
      showStandardForm (): void {
        this.volunteer.hasStandardProfile = true
        if (this.volunteer.standardProfile == null) {
          this.volunteer.standardProfile = {
            availabilityDetails: '',
            availabilityFrequence: 0,
            customerTypes: 0,
            friendManagementGroupId: 0,
            friendships: [],
            friendshipTypes: (FriendshipTypes.OneToOne),
            friendPools: [],
            id: 0,
            interests: 0,
            lastFriendshipEndDate: new Date(),
            multipleCustomers: false,
            onHold: false,
            onHoldDeadline: undefined,
            onHoldDetails: '',
            otherInterests: '',
            preferredAges: 0,
            preferredGenders: (PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female),
            shortDescription: '',
            startedPending: new Date(),
            state: CustomerState.PendingApproval,
            volunteerInterviewed: false,
            volunteerInterviewedBy: '',
            volunteerInterviewedDate: '',
            availabilityTimes: 0,
            hasAlternativeAddress: false,
            alternativeCity: '',
            alternativeStreet: '',
            alternativeZipCode: '',
            privateNotes: ''
          } as FriendVolunteerProfileStandardDto
        }
      },
      showNetForm (): void {
        this.volunteer.hasNetProfile = true
        if (this.volunteer.netProfile == null) {
          this.volunteer.netProfile = {
            availabilityDetails: '',
            availabilityFrequence: 0,
            availabilityTimes: 0,
            customerTypes: 0,
            friendManagementGroupId: 0,
            friendships: [],
            friendshipTypes: (NetFriendshipTypes.OneToOne),
            friendPools: [],
            id: 0,
            interests: 0,
            lastFriendshipEndDate: new Date(),
            multipleCustomers: false,
            onHold: false,
            onHoldDeadline: undefined,
            onHoldDetails: '',
            otherInterests: '',
            preferredAges: 0,
            preferredGenders: (PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female),
            shortDescription: '',
            startedPending: new Date(),
            state: CustomerState.PendingApproval,
            volunteerInterviewed: false,
            volunteerInterviewedBy: '',
            volunteerInterviewedDate: '',
            econtactDiscord: '',
            econtactEmail: '',
            econtactFreetext: '',
            econtactInstagram: '',
            econtactKik: '',
            econtactSkype: '',
            econtactTelegram: '',
            privateNotes: ''
          } as FriendVolunteerProfileNetDto
        }
      },
      removeStandardForm (): void {
        this.volunteer.hasStandardProfile = false
      },
      removeNetForm (): void {
        this.volunteer.hasNetProfile = false
      },
      cancel (): void {
        this.$router.push('/profile')
      },
      save (): void {
        this.$validator.validateAll().then(result => {
          // check any child controls have not added errors
          if (!result || (this.validationErrors && this.validationErrors.length)) {
            var target = this.$refs['validationtarget']
            if (target) {
              window.scrollTo({
                // @ts-ignore
                top: target.$el.parentElement.clientHeight,
                left: 0,
                behavior: 'smooth'
              })
            }
            return
          }

          const data = this.volunteer
          if (!this.volunteer.hasNetProfile) {
            // @ts-ignore
            data.netProfile = null
          }
          if (!this.volunteer.hasStandardProfile) {
            // @ts-ignore
            data.standardProfile = null
          }
          data.profileId = this.$store.state.profile.profileId
          if (this.isAdd) {
            this.addFriendProfile(data)
          } else {
            this.updateFriendProfile(data)
          }
        })
      },
      addFriendProfile (data: FriendVolunteerDto): void {
        const addUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteeridentadd[0]
        this.$http.post(addUrl, data).then((result: any) => {
          // @ts-ignore (doesnt always pick up mixins)
          this.fetchProfile((response: any) => {
            this.$store.commit('setProfile', response.body)
            this.$router.push('/profile')
          })
        }, (err: Response | any) => {
          this.displayError(err)
        })
      },
      updateFriendProfile (data: FriendVolunteerDto): void {
        const updateUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteeridentupdate[0]
        this.$http.put(updateUrl, data).then((result: any) => {
          // @ts-ignore (doesnt always pick up mixins)
          this.fetchProfile((response: any) => {
            this.$store.commit('setProfile', response.body)
            this.$router.push('/profile')
          })
        }, (err: Response | any) => {
          this.displayError(err)
        })
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .profileidentvolunteerform {
    .feature-panel {
      background-color: $brand-white;
      margin-bottom: 30px;
      padding-top: 15px;

      &.buttons {
        margin-top: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        .cancel {
          text-align: left;
        }
        .save {
          text-align: right;
        }
      }
    }

    .profilehead {
      margin-top: 25px;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $brand-white;
    }

    .subheader {
      margin-top: 15px;
      margin-bottom: 15px;
      display: block;
    }

    .profile-button {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .fa-exclamation-triangle {
      color: $brand-red;
    }
  }
</style>
