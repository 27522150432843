<template>
  <div class="friendship-contact">
    <div v-for="(listItem, index) in contactInfoList" :key="listItem.id" class="row contactinfo">
      <div class="col-12 title">
        <!-- Render header text only for the first element -->
        <span v-if="index === 0 && isFriendRequest" class="row header">
          {{ $t('customerForm.groupStandardTitle') }}
          <br>
        </span>
        <span v-show="!isEditing(listItem)">
          {{ listItem.title }}
          <a v-if="editable" tabindex="0" href="javascript:void(0);" @click="toggleEdit(listItem)">
            <font-awesome-icon icon="pencil-alt" aria-hidden="true" />
          </a>
        </span>
        <span v-show="isEditing(listItem)">
          <forminput
            :key="`contactinfo.title_${listItem.id}`"
            v-model="contact.title"
            v-validate="'required'"
            compact
            :name="`contactinfo.title_${listItem.id}`"
            :required="true"
            :hint="$t('userAdmin.friendshipGroupContactTitle')"
            :is-valid="!errors.has(`contactinfo.title_${listItem.id}`)"
          />
        </span>
      </div>
      <div class="col-12">
        <div class="row">
          <div v-if="!isEditing(listItem)" v-show="listItem.address" class="contactinfoitem col-sm-6">
            <span>
              <font-awesome-icon :icon="['far', 'hospital']" />
              {{ listItem.address }}</span>
          </div>
          <div v-else class="contactinfoitem col-sm-6">
            <forminput
              v-model="contact.address"
              compact
              name="contactinfo.address"
              :hint="$t('userAdmin.friendshipGroupAddress')"
              :is-valid="true"
              :icon="['far', 'hospital']"
            />
          </div>

          <div v-if="!isEditing(listItem)" v-show="listItem.onCallTimes" class="contactinfoitem col-sm-6">
            <span>
              <font-awesome-icon :icon="['far', 'clock']" />
              {{ listItem.onCallTimes }}
            </span>
          </div>
          <div v-else class="contactinfoitem col-sm-6">
            <forminput
              v-model="contact.onCallTimes"
              compact
              name="contactinfo.onCallTimes"
              :hint="$t('userAdmin.friendshipGroupOnCallTimes')"
              :is-valid="!errors.has('contactinfo.onCallTimes')"
              :icon="['far', 'clock']"
            />
          </div>

          <div v-if="!isEditing(listItem)" v-show="listItem.phone" class="contactinfoitem col-sm-6">
            <span>
              <font-awesome-icon icon="phone" />
              {{ listItem.phone }}
            </span>
          </div>
          <div v-else class="contactinfoitem col-sm-6">
            <forminput
              v-model="contact.phone"
              compact
              name="contactinfo.phone"
              :hint="$t('userAdmin.friendshipGroupPhone')"
              :is-valid="!errors.has('contactinfo.phone')"
              icon="phone"
            />
          </div>

          <div v-if="!isEditing(listItem)" v-show="listItem.email" class="contactinfoitem col-sm-6">
            <span>
              <font-awesome-icon icon="envelope" />
              {{ listItem.email }}
            </span>
          </div>
          <div v-else class="contactinfoitem col-sm-6">
            <forminput
              v-model="contact.email"
              compact
              name="contactinfo.email"
              :hint="$t('userAdmin.friendshipGroupEmail')"
              :is-valid="!errors.has('contactinfo.email')"
              icon="envelope"
            />
          </div>

          <div v-show="!isEditing(listItem) && !(listItem.address || listItem.onCallTimes || listItem.phone || listItem.email)" class="contactinfoitem col-sm-6">
            <span>
              {{ $t('userAdmin.noContactInfo') }}
            </span>
          </div>
        </div>
      </div>
      <!-- Render button after the loop if it's the last item -->
      <div v-if="contactInfoList.length > 0 && index === contactInfoList.length - 1 && isFriendRequest && showStandardButton" class="buttons">
        <sprbutton
          :size="4"
          :title="$t('customerForm.sendRequest')"
          :primary="true"
          icon="chevron-right"
          class="next"
          click-event="click"
          @click="onFriendRequestClicked"
        />
      </div>
      <div v-if="isEditing(listItem)" class="col-8 buttons">
        <sprbutton type="button"
                   :size="5"
                   :title="$t('userAdmin.editSave')"
                   class="spr-redborder primary"
                   click-event="savecontact"
                   @savecontact="onSave"
        />
        <sprbutton type="button"
                   :size="5"
                   :title="$t('userAdmin.editCancel')"
                   class="spr-redborder"
                   click-event="cancelsave"
                   @cancelsave="onCancel"
        />
      </div>
      <div v-if="isEditing(listItem)" class="col-4 text-right buttons">
        <sprbutton type="button"
                   :size="5"
                   :title="$t('userAdmin.delete')"
                   class="spr-redborder primary"
                   click-event="delete"
                   @delete="onDelete"
        />
      </div>
    </div>
    <div v-if="editable && !isAdding" class="row">
      <div class="col-12 buttons">
        <a href="#" @click="onAdd">{{ $t('userAdmin.addFriendshipGroupContact') }}</a>
      </div>
    </div>
    <div v-show="isAdding" class="row">
      <div class="col-12 title">
        <forminput
          :key="`addtitle_1`"
          v-model="contact.title"
          v-validate="'required'"
          compact
          name="contactinfo_add.addTitle"
          :required="true"
          :hint="$t('userAdmin.friendshipGroupContactTitle')"
          :is-valid="!errors.has('contactinfo_add.addTitle')"
        />
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <div class="contactinfoitem">
              <forminput
                v-model="contact.address"
                compact
                name="contactinfo.addAddress"
                :hint="$t('userAdmin.friendshipGroupAddress')"
                :is-valid="true"
                :icon="['far', 'hospital']"
              />
            </div>
            <div class="contactinfoitem">
              <forminput
                v-model="contact.onCallTimes"
                compact
                name="contactinfo.addOnCallTimes"
                :hint="$t('userAdmin.friendshipGroupOnCallTimes')"
                :is-valid="true"
                :icon="['far', 'clock']"
              />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="contactinfoitem">
              <forminput
                v-model="contact.phone"
                compact
                name="contactinfo.addPhone"
                :hint="$t('userAdmin.friendshipGroupPhone')"
                :is-valid="true"
                icon="phone"
              />
            </div>
            <div class="contactinfoitem">
              <forminput
                v-model="contact.email"
                compact
                name="contactinfo.addEmail"
                :hint="$t('userAdmin.friendshipGroupEmail')"
                :is-valid="true"
                icon="envelope"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 buttons">
        <sprbutton type="button"
                   :size="5"
                   :title="$t('userAdmin.editSave')"
                   class="spr-redborder primary"
                   click-event="savecontact"
                   @savecontact="onAddSave"
        />
        <sprbutton type="button"
                   :size="5"
                   :title="$t('userAdmin.editCancel')"
                   class="spr-redborder"
                   click-event="cancelsave"
                   @cancelsave="onAddCancel"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import forminput from '../SPRSimpleText.vue'
  import sprbutton from '../SPRButton.vue'
  export default {
    name: 'FriendshipContact',
    components: { forminput, sprbutton },
    props: {
      contactInfoList: {
        type: Array,
        default: () => []
      },
      editable: {
        type: Boolean,
        default: false
      },
      isFriendRequest: {
        type: Boolean,
        default: false
      },
      showStandardButton: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        contact: {}
      }
    },
    computed: {
      isAdding () {
        return this.contact && this.contact.id === 0
      }
    },
    methods: {
      isEditing (listItem) {
        if (!this.contact) return false
        return listItem.id === this.contact.id
      },
      toggleEdit (listItem) {
        this.contact = JSON.parse(JSON.stringify(listItem))
      },
      onFriendRequestClicked () {
        this.$emit('next')
      },
      onSave () {
        var self = this
        this.$validator.validateAll().then(result => {
          if (result) {
            self.$emit('ContactSave', self.contact)
            self.contact = {}
          }
        })
      },
      onCancel () {
        this.contact = {}
      },
      onAdd () {
        this.contact = {
          id: 0,
          title: '',
          address: '',
          onCallTimes: '',
          phone: '',
          email: ''
        }
      },
      onAddSave () {
        var self = this
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('ContactAdd', self.contact)
            this.contact = {}
          }
        })
      },
      onAddCancel () {
        this.contact = {}
      },
      onDelete () {
        this.$emit('ContactDelete', this.contact.id)
        this.contact = {}
      }
    }
  }
</script>
<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .friendship-contact {
    .few-zips {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .contactinfo {
      margin-bottom: 10px;
      .title {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .contactinfoitem {
        svg {
          color:$brand-red;
          margin-right: 5px;
        }
      }
    }
    .header {
      margin-bottom: 25px;
    }
    .buttons {
      margin-bottom: 15px;
      a {
        margin-top: 20px;
        display: block;
      }

      .primary {
        color:$brand-white;
        background-color:$brand-red;

        &:hover {
          color:$brand-red;
          background-color:$brand-white;
        }
      }
    }
  }
</style>
