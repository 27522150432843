<template>
  <div class="row volunteermatchstatus">
    <div
      v-if="canAddNewMatch"
      class="col-12 addnew text-center"
    >
      <sprbutton type="primary"
                 :size="4"
                 :title="$t('customerSearch.newCustomerMatch')"
                 click-event="new"
                 :disabled="!isActive"
                 @new="onNewMatch"
      />
    </div>
    <div v-if="inactiveText" class="col-12">
      <div class="inactive">
        <div>{{ inactiveText }}</div>
      </div>
    </div>
    <div v-if="!hasVolunteer" class="col-12">
      <div class="no-customers">
        <div>{{ $t('customerSearch.noCustomerMatches') }}</div>
      </div>
    </div>
    <div v-else class="col-12">
      <ul class="volunteer-list">
        <li v-for="item in friendships" :key="item.id" :class="{ 'starthidden fadein': isHighlightedItem(item.customerId) }">
          <volunteermatchdetail :friendship="item" :group="group" @selectFriendship="onSelectFriendship" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import volunteermatchdetail from './VolunteerMatchDetail.vue'
  import { FriendManagementDto, UserProfileDto, CustomerState, FriendshipStandardDto, FriendshipNetDto } from '@/types/index'

  export default Vue.extend({
    name: 'VolunteerMatchStatus',
    components: { sprbutton, volunteermatchdetail },
    props: {
      volunteer: {
        type: Object as () => UserProfileDto,
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      },
      highlightedcustomerid: {
        type: String,
        default: ''
      }
    },
    computed: {
      hasVolunteer (): boolean {
        return Boolean(this.friendships?.length)
      },
      canAddNewMatch (): boolean {
        return !this.hasVolunteer ||
          Boolean(this.group.netFriendship
            ? this.volunteer.friendInfo?.netProfile.multipleCustomers
            : this.volunteer.friendInfo?.standardProfile.multipleCustomers)
      },
      friendships (): Array<FriendshipNetDto | FriendshipStandardDto> {
        return this.group.netFriendship
          ? this.volunteer.friendInfo?.netProfile?.friendships
          : this.volunteer.friendInfo?.standardProfile?.friendships
      },
      isActive (): boolean {
        var activeStates = [
          CustomerState.Active,
          CustomerState.PendingFriend,
          CustomerState.ActiveFriendship
        ]
        return this.group.netFriendship
          ? Boolean(_.includes(activeStates, this.volunteer.friendInfo?.netProfile?.state))
          : Boolean(_.includes(activeStates, this.volunteer.friendInfo?.standardProfile?.state))
      },
      inactiveText (): string | LocaleMessage {
        if (this.volunteer.friendInfo) {
          var switcher = this.group.netFriendship
            ? this.volunteer.friendInfo.netProfile.state
            : this.volunteer.friendInfo.standardProfile.state

          switch (switcher) {
          case CustomerState.PendingApproval:
            return this.$t('customerSearch.noMatchingNotInterviewed')
          case CustomerState.NonActive:
            return this.$t('customerSearch.noMatchingRemoved')
          case CustomerState.OnHold:
            return this.$t('customerSearch.noMatchingOnHold')
          }
        }
        return ''
      }
    },
    methods: {
      onNewMatch (): void {
        this.$emit('addNewMatch')
      },
      onSelectFriendship (friendship): void {
        this.$emit('customerMatchSelected', friendship)
      },
      isHighlightedItem (id): boolean {
        return Boolean(this.highlightedcustomerid && Number(this.highlightedcustomerid) === Number(id))
      }
    }
  })
</script>
<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .volunteermatchstatus {
    margin-bottom: 30px;
    .addnew {
      margin-bottom: 20px;
    }
    .inactive {
      text-align: center;
      padding: 15px 5px 30px 5px;
    }
    .no-customers {
      text-align: center;
    }

    .volunteer-list {
      list-style-type: none;
      padding: 15px;

      li {
        padding: 10px 0 0 0;

        &.fadein {
          -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
          -moz-animation: fadein 2s; /* Firefox < 16 */
          -ms-animation: fadein 2s; /* Internet Explorer */
          -o-animation: fadein 2s; /* Opera < 12.1 */
          animation: fadein 2s;
        }

        @keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }

        /* Firefox < 16 */
        @-moz-keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }

        /* Safari, Chrome and Opera > 12.1 */
        @-webkit-keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }

        /* Internet Explorer */
        @-ms-keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }

        /* Opera < 12.1 */
        @-o-keyframes fadein {
            from { opacity: 0; }
            to   { opacity: 1; }
        }
      }
    }
  }
</style>
