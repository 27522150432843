<template>
  <div class="fill-width comp-wizard-content">
    <div>
      <span class="h1"
            role="heading"
            aria-level="1"
      >{{ $t('wizard.headerOne') }}</span><br>
      <p>{{ $t('wizard.ingressChekcboxes') }}</p>
    </div>
    <sprcheckbox v-for="(interest, index) in $store.state.profileAreasOfInterest"
                 :key="'wizard_1_' + index"
                 :value="interest.id"
                 :title="$t('interest.' + interest.text)"
                 click-event="box-selected"
                 :collection="$store.state.wizardModel.areasOfInterest"
                 @box-selected="handleCheckbox"
    />
  </div>
</template>

<script>
  import sprcheckbox from '../SPRCheckbox.vue'

  export default {
    name: 'WizardOne',
    components: { sprcheckbox },
    data () {
      return {
        selected: new Set(),
        interests: []
      }
    },
    methods: {
      handleCheckbox: function (value, isChecked) {
        if (isChecked) {
          this.selected.add(value)
        } else {
          this.selected.delete(value)
        }
        var arr = [...this.selected]

        this.$store.commit('setWizardInterests', arr)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
@import '~@/assets/scss/_wizard.scss';
</style>
