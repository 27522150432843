<template>
  <div class="row comp-useradmin-role-editor my-2">
    <!-- profile status -->
    <label class="cap-label">{{ $t('profile.headerState') }}</label>
    <br>
    <div class="row">
      <div class="col col-12">
        <div class="profile-state">
          <SPRRadioList
            :value="editedUser.profileState"
            :options="profileStatusOptions"
            @input="updateProfileState"
          />
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-6">
          <forminput v-model="editedUser.firstName"
                     v-validate="'required'"
                     :hint="$t('adminUserEditor.firstName')"
                     name="firstName"
                     :is-valid="!errors.has('firstName')"
                     @input="saveChanges"
          />
        </div>
        <div class="col-12 col-md-6">
          <forminput v-model="editedUser.lastName"
                     v-validate="'required'"
                     :hint="$t('adminUserEditor.lastName')"
                     name="lastName"
                     :is-valid="!errors.has('lastName')"
                     @input="saveChanges"
          />
        </div>
        <div class="col-12">
          <forminput v-model="editedUser.streetAddress"
                     :hint="$t('adminUserEditor.streetAddress')"
                     :is-valid="true"
                     @input="saveChanges"
          />
        </div>
        <div class="col-12 col-md-6">
          <forminput v-model="editedUser.zipCode"
                     :hint="$t('adminUserEditor.zipCode')"
                     :is-valid="true"
                     @input="saveChanges"
          />
        </div>
        <div class="col-12 col-md-6">
          <forminput v-model="editedUser.city"
                     :hint="$t('adminUserEditor.city')"
                     :is-valid="true"
                     @input="saveChanges"
          />
        </div>
        <div class="col-12 col-md-6 lang-drop">
          <dropinput v-model="editedUser.nativeLanguage"
                     v-validate="'required'"
                     :title="$t('adminUserEditor.native')"
                     :items="userLanguages"
                     name="nativelanguage"
                     :is-valid="!errors.has('nativelanguage')"
                     :required="true"
                     @input="saveChanges"
          />
        </div>
        <div class="col-12 col-md-6 date-pick">
          <datepicker :key="componentKey"
                      v-validate="birthdateValidation"
                      :value="formattedAge"
                      :title="$t('adminUserEditor.birthDate')"
                      :placeholder="$t('adminUserEditor.chooseDate')"
                      :has-error="errors.has('adminUserEditor.birthDate')"
                      name="adminUserEditor.birthDate"
                      :initial="userAge"
                      :required="true"
                      :options="datepickerOptions"
                      @input="onAgeChanged"
          />
        </div>
        <!-- languages -->
        <div class="row">
          <div class="col-12">
            <span style="padding:left 15px;" class="subheader">{{ $t('profile.languageEditorHeader') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 multi-options">
            <simplecheckboxlist v-model="editedUser.spokenLanguages"
                                :list="checkboxLanguages(editedUser.spokenLanguages, $store.state.friendCustomerLanguages)"
                                :rows="3"
                                @input="updateSpokenLanguages"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div v-if="canChangeSection" id="zipParent" class="admin-working-zip">
            <label class="cap-label">{{ $t('adminUserEditor.workingZipCode') }}</label>
            <!-- This is for users to choose a zip code outside of their section, if allowed -->
            <sprautocomplete
              v-model="adminSectionZip"
              v-validate="'required'"
              :options="adminSectionZips"
              option-label="value"
              placeholder=""
              :required="true"
              name="zipCodeField"
              :is-valid="!errors.has('zipCodeField')"
              :max-results="200"
              :show-arrow="false"
              :show-default-options="false"
              @input="saveChanges"
            />
            <div v-if="showSectionChangeWarning" class="section-change-warning">
              <font-awesome-icon icon="exclamation-triangle" />{{ $t('adminUserEditor.changeSectionWarning') }}
            </div>
          </div>
          <!-- This is for users to choose a zip code inside their section, but cannot choose one outside -->
          <dropinput v-else v-model="editedUser.workingZipCode"
                     :title="$t('adminUserEditor.workingZipCode')"
                     :items="sectionZips"
                     :is-valid="true"
                     :required="true"
                     @input="saveChanges"
          />
        </div>
        <div class="col-12">
          <checkboxinput
            v-model="editedUser.volunteerOnline"
            :title="$t('adminUserEditor.volunteerOnline')"
            :aria-label="$t('adminUserEditor.volunteerOnline')"
            @input="saveChanges"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <forminput v-model="editedUser.phoneNumber"
                     :hint="$t('adminUserEditor.phone')"
                     :is-valid="true"
                     @input="saveChanges"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <textareainput v-model="editedUser.motto"
                         :title="$t('adminUserEditor.motto')"
                         @input="saveChanges"
          />
        </div>
        <div class="col-12 col-md-6">
          <textareainput v-model="editedUser.story"
                         :title="$t('adminUserEditor.story')"
                         @input="saveChanges"
          />
        </div>
      </div>

      <!-- PreferredContactMethod -->
      <div class="row">
        <div class="col-12 text-left">
          <span class="subheader">{{ $t('wizard.preferredContactMethod') }}</span>
        </div>
        <div class="col-12 multi-options">
          <checkboxlist v-model="editedUser.preferredContactMethods"
                        :list="preferredContactMethodOptions"
                        :rows="1"
          />
        </div>
      </div>

      <!-- InvolvedEventCategories -->
      <div class="row">
        <div class="col-12 text-left">
          <span class="subheader">{{ $t('wizard.alreadyInvolved') }}</span>
        </div>
        <div class="col-12 multi-options">
          <checkboxlist v-model="editedUser.involvedEventCategories"
                        :list="involvedEventCategoriesOptions"
                        :rows="3"
          />
        </div>
      </div>

      <!-- interests -->
      <div class="row">
        <div class="col-12 text-left">
          <span class="subheader">{{ $t('volunteerForm.interests') }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 multi-options">
          <simplecheckboxlist v-model="editedUser.areasOfInterest"
                              :list="areasOfInterest"
                              :rows="3"
                              @input="updateProfileInterests"
          />
        </div>
      </div>

      <!-- skills -->
      <div class="row">
        <div class="col-12 text-left">
          <span class="subheader">{{ $t('profile.skillEditorHeader') }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 multi-options">
          <simplecheckboxlist v-model="editedUser.personalSkills"
                              :list="personalSkills"
                              :rows="3"
                              @input="updatePersonalSkills"
          />
        </div>
      </div>
      <!-- availability times -->
      <div class="row">
        <div class="col-12 text-left">
          <span class="subheader">{{ $t('profile.availabilityTimes') }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 multi-options">
          <simplecheckboxlist v-model="selectedAvailabilityTimes"
                              :list="availabilityTimes"
                              :rows="3"
                              @input="updateAvailabilityTimes"
          />
        </div>
      </div>
      <!-- transportation -->
      <div class="row">
        <div class="col-12 text-left">
          <span class="subheader">{{ $t('profile.travelOptions') }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 multi-options">
          <simplecheckboxlist v-model="editedUser.transportation"
                              :list="transportations"
                              :rows="3"
                              @input="updateTransportation"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <forminput v-model="editedUser.travelTime"
                     :hint="$t('adminUserEditor.travelTime')"
                     :is-valid="true"
                     @input="saveChanges"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import moment from 'moment'
  import forminput from '../SPRSimpleText.vue'
  import dropinput from '../SPRSimpleDropdown.vue'
  import datepicker from '../SPRDatepicker.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import checkboxlist from '../SPRSimpleCheckboxBitmaskList.vue'
  import simplecheckboxlist from '../SPRSimpleCheckboxList.vue'
  import sprautocomplete from '../SPRAutocompleteDropdown.vue'
  import locationutils from '../../mixins/locationutils.js'
  import inpututils from '../../mixins/inpututils.js'
  import alerts from '../../mixins/alerts'
  import SPRRadioList from '../SPRRadioList.vue'
  import { SectionDto, UserProfileDto } from '@/types'
  import { SprCheckboxValue, SprRadioPillOption, YesNoWarning, SprCheckboxBitmaskValue, SprDropDownValue, StringKeyValuePair } from '@/types/custom'

  import datetwoyears from '../../helpers/datehelper'
  import bitmaskhelper from '@/helpers/bitmaskhelper'
  import SPRDropdownNumberKeyVue from '../SPRDropdownNumberKey.vue'

  // the data object
  export interface TemplateComponentData {
    editedUser: UserProfileDto,
    datepickerOptions: {
      inputStyle: Record<string, any>
    },
    sectionZips: Array<SprDropDownValue>,
    adminSectionZips: Array<StringKeyValuePair> | undefined,
    adminSectionZip: StringKeyValuePair | undefined,
    backupSectionZip: StringKeyValuePair | undefined,
    //originalSection: SectionDto | undefined,
    componentKey: number
  }

  export default Vue.extend({
    name: 'UserEditorForm',
    components: {
      forminput,
      dropinput,
      datepicker,
      textareainput,
      checkboxinput,
      checkboxlist,
      simplecheckboxlist,
      SPRRadioList,
      sprautocomplete
    },
    mixins: [locationutils, alerts, inpututils],
    inject: ['parentValidator'],
    props: {
      id: {
        type: String,
        default: ''
      },
      user: {
        type: Object as () => UserProfileDto,
        required: true
      },
      originalSectionId: {
        type: Number,
        default: 0
      }
    },
    data (): TemplateComponentData {
      return {
        editedUser: Object.assign({}, this.user),
        datepickerOptions: {
          inputStyle: {
            display: 'inline-block',
            padding: '10px 20px',
            'line-height': '22px',
            'font-size': '16px',
            border: '2px solid #DDDDDD',
            'box-shadow': 'none',
            'border-radius': '24px',
            color: '#5F5F5F',
            width: '100%'
          }
        },
        sectionZips: [],
        adminSectionZips: [],
        adminSectionZip: undefined,
        backupSectionZip: undefined,
        //originalSection: undefined,
        componentKey: 0 // forces reactive update of datepicker when language changes
      }
    },
    computed: {
      userAge (): string {
        return moment(this.editedUser.age).format('DD.MM.YYYY')
      },
      birthdateValidation (): any {
        return datetwoyears.birthdateValidation()
      },
      preferredContactMethodOptions (): Array<SprCheckboxBitmaskValue> {
        var self = this
        var validItems = _.filter(this.$store.state.preferredContactMethods, (x) => x.id !== 0)

        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('wizard.preferredContactMethodOption' + val.id) } as SprCheckboxBitmaskValue
        })
      },
      profileStatusOptions (): Array<SprRadioPillOption> {
        return this.$store.state.profileState.map((status: any) => {
          return {
            value: status.id,
            text: this.$t(`profile.${status.text}`)
          }
        })
      },
      selectedAvailabilityTimes: {
        get (): any {
          return this.editedUser.availabilityTimes.map(time => {
            return Math.pow(2, time - 1) // Convert regular integer to bitmask
          })
        },
        set (value: number[]) {
          this.editedUser.availabilityTimes = value.map((bitmask: number) => {
            return Math.log2(bitmask) + 1 // Convert bitmask to regular integer
          })
        }
      },
      involvedEventCategoriesOptions (): Array<SprCheckboxBitmaskValue> {
        var self = this
        var validItems = _.filter(this.$store.state.involvedEventCategories, (x) => x.id !== 0)

        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('involvedEventCategories.' + val.text).toString() } as SprCheckboxBitmaskValue
        })
      },
      areasOfInterest (): Array<SprDropDownValue> {
        var self = this
        var validItems = _.filter(this.$store.state.profileAreasOfInterest, (x) => x.id !== 0)

        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('interest.' + val.text).toString(), selected: self.editedUser.areasOfInterest.indexOf(val.id) >= 0 } as SprCheckboxValue
        })
      },
      personalSkills (): Array<SprDropDownValue> {
        var self = this
        var validItems = _.filter(this.$store.state.profilePersonalSkills, (x) => x.id !== 0)

        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('skills.' + val.text).toString(), selected: self.editedUser.personalSkills.indexOf(val.id) >= 0 } as SprCheckboxValue
        })
      },
      spokenLanguages (): Array<SprDropDownValue> {
        var self = this
        var validItems = _.filter(this.$store.state.spokenLanguages, (x) => x.id !== 0)

        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('skills.' + val.text).toString(), selected: self.editedUser.personalSkills.indexOf(val.id) >= 0 } as SprCheckboxValue
        })
      },
      availabilityTimes (): Array<SprDropDownValue> {
        var self = this
        var validItems = _.filter(this.$store.state.availabilityTimes, (x) => x.id !== 0)
        var userAvailsAsBitmasks = bitmaskhelper.convertIntegerToBitmask(self.editedUser.availabilityTimes)
        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('availabilityTimes.' + val.text).toString(), selected: userAvailsAsBitmasks.indexOf(val.id) >= 0 } as SprCheckboxValue
        })
      },
      transportations (): Array<SprDropDownValue> {
        var self = this
        var validItems = _.filter(this.$store.state.transportation, (x) => x.id !== 0)
        //var userAvailsAsBitmasks = bitmaskhelper.convertIntegerToBitmask(self.editedUser.availabilityTimes)
        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('transportation.' + val.text).toString(), selected: self.editedUser.transportation.indexOf(val.id) >= 0 } as SprCheckboxValue
        })
      },
      clientFilters (): Array<SprCheckboxValue> {
        const self = this
        return _.map(this.$store.state.customerStates, function (val, key) {
          return { value: val.id, text: self.$t('customerStates.' + val.text), selected: false } as SprCheckboxValue
        })
      },
      userLanguages (): Array<SprDropDownValue> {
        return _.map(this.$store.state.allLanguages, function (val, key) {
          return { value: val.isoCode, text: val.displayName } as SprDropDownValue
        })
      },
      formattedAge (): string {
        return moment(this.editedUser.age).format('DD.MM.YYYY')
      },
      zipCode (): string {
        return this.editedUser.zipCode
      },
      canChangeSection (): boolean {
        // @ts-ignore mixins not found
        if (this.hasRole('Admin') || this.hasRole('Employee') || this.hasRole('J1') || this.hasRole('J2') || this.hasRole('DistrictAdmin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee')) {
          // these roles can change someone elses section to anything
          return true
        }
        return false
      },
      showSectionChangeWarning (): boolean {
        return this.originalSectionId !== Number(this.editedUser.sectionId)
      }
    },
    watch: {
      zipCode (val): void {
        if (val.length === 5) {
          var self = this
          // @ts-ignore mixins not found
          this.cityFromZip(val, this, response => {
            self.editedUser.city = self.$store.state.language === 'se' ? response.body.citySe : response.body.cityFi
          })
        }
      },
      adminSectionZip (newVal): void {
        if (newVal && newVal.key.indexOf('-')) {
          var key = newVal.key.split('-')

          this.editedUser.workingZipCode = key[0]
          this.editedUser.sectionId = Number(key[1])
        }
      }
    },
    created (): void {
      // @ts-ignore parentvalidator is added via provide()
      this.$validator = this.parentValidator
    },
    beforeMount (): void {
    },
    mounted (): void {
      this.editedUser.email = this.editedUser.email ? this.editedUser.email : 'thin@innofactortestaa.com'
      this.editedUser.id = this.id

      this.setOriginalSection()
      // Admins, Distict Admin, Employee (#2511) have the ability to change sections. Display an autocomplete with sections as well as postcodes
      if (this.canChangeSection) {
        var adminSections = [] as Array<StringKeyValuePair>
        _.each(this.$store.state.sections, (section) => {
          _.each(section.zipCodes, (zip) => {
            adminSections.push({ key: `${zip}-${section.id}`, value: `${zip} - ${section.name}` })
          })
        })

        var zip
        // Fill the autocomplete with a list of potential zips with sections
        this.adminSectionZips = _.sortBy(adminSections, 'value')
        // Select the actual one they have assigned at the moment
        var zipPossibles = _.filter(this.adminSectionZips, (zip: StringKeyValuePair) => {
          var zipArr = zip.key?.split('-')
          return zipArr[0] === this.editedUser.workingZipCode // && Number(zipArr[1]) === Number(this.editedUser.sectionId)
        })
        if (!zipPossibles) {
          // could be more than one as a working zip code could fit into 2 sections if the lang is different
          // @ts-ignore mixins not found
          this.showAlertError(this.$t('userEditor.invalidWorkingZipCode'))
          return
        }

        if (zipPossibles.length === 1) {
          zip = zipPossibles[0]
        } else {
          zip = _.find(zipPossibles, (zip: StringKeyValuePair) => {
            var zipArr = zip.key?.split('-')
            return Number(zipArr[1]) === Number(this.editedUser.sectionId)
          })
          if (!zip) {
            // rare case, but someones section could have been set previously outside of their working zip code
            zip = zipPossibles[0]
          }
        }

        this.adminSectionZip = zip || {} as StringKeyValuePair
        this.backupSectionZip = Object.assign({}, this.adminSectionZip)
        window.addEventListener('click', this.handleClick, true)
      }
      // Force the date component to re-render on language change
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'setLanguage') {
          this.componentKey += 1
        }
      })
    },
    beforeDestroy (): void {
      window.removeEventListener('click', this.handleClick)
    },
    methods: {
      onAgeChanged (newAge): void {
        // convert from dd.mm.yyyy to date format
        this.editedUser.age = new Date(moment(newAge, ['DD.MM.YYYY']).format('YYYY-MM-DD'))
        this.saveChanges()
      },
      setOriginalSection (): void {
        var originalUsersSection = _.find(this.$store.state.sections, section => {
          return section.id === Number(this.editedUser.sectionId)
        })

        if (originalUsersSection) {
          this.sectionZips = originalUsersSection.zipCodes.map(zip => {
            return { text: zip, value: zip } as SprDropDownValue
          })
        }
      },
      updateProfileState (newVal): void {
        this.editedUser.profileState = newVal
        //this.$store.commit('profileEdit', this.editedUser)
        this.saveChanges()
      },
      updateProfileInterests (newVal): void {
        this.editedUser.areasOfInterest = newVal
        //this.$store.commit('profileEdit', this.editedUser)
        this.saveChanges()
      },
      updatePersonalSkills (newVal): void {
        this.editedUser.personalSkills = newVal
        //this.$store.commit('profileEdit', this.editedUser)
        this.saveChanges()
      },
      updateTransportation (newVal): void {
        this.editedUser.transportation = newVal
        //this.$store.commit('profileEdit', this.editedUser)
        this.saveChanges()
      },
      updateAvailabilityTimes (newVal): void {
        var updated = bitmaskhelper.convertBitmaskToInteger(newVal)
        this.editedUser.availabilityTimes = updated
        //this.$store.commit('profileEdit', this.editedUser)
        this.saveChanges()
      },
      updateSpokenLanguages (newVal): void {
        this.editedUser.spokenLanguages = newVal
        //this.$store.commit('profileEdit', this.editedUser)
        this.saveChanges()
      },
      saveChanges (): void {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('input', this.editedUser)
            //this.setOriginalSection()
            this.backupSectionZip = Object.assign({}, this.adminSectionZip)
          }
        })
      },
      handleClick (event): void {
        // If the user has clicked outside of the section chooser, but has not got a section selected, set it back to the original one.
        if (event.target) {
          //@ts-ignore
          if (this.hasSpecifiedParentId(event.target, 'zipParent')) {
            if (!this.adminSectionZip) {
              this.adminSectionZip = this.backupSectionZip
            }
          }
        }
      },
      hasSpecifiedParentId (el, parentId, parentSelector? /* optional */): boolean {
        // If no parentSelector defined will bubble up all the way to *document*
        if (parentSelector === undefined) {
          parentSelector = document
        }

        var p = el.parentNode
        while (p && p !== parentSelector) {
          var o = p
          if (o.id === parentId) {
            return true
          }
          p = o.parentNode
        }

        return false
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
  .comp-useradmin-role-editor {
    .lang-drop {
      .spr-roundbox-1 {
        border: none !important;
      }
      select {
        border: 2px $brand-grey3 solid !important;
      }
    }
  .profile-state {
    margin-bottom: 30px;
  }
    .admin-working-zip {
      label {
        color: $brand-red;
        padding: 0 20px;
      }
      input {
        padding: 10px 20px;
        border: solid 2px $brand-grey3;
      }
      .section-change-warning {
        svg {
          margin-right: 7px;
        }
        background-color: $brand-warning;
        color:$brand-white;
        font-size: 0.8em;
        padding: 10px;
      }
    }

    .subheader {
      color:$brand-red;
      text-transform: uppercase;
      font-family: $font-bold;
      margin: 0px 0 20px 0;
      display:block;
      font-size: 14px;
      padding: 0 20px;
    }

    .multi-options {
      padding: 0 35px;
    }
  }
</style>
