import { render, staticRenderFns } from "./SPRMultiSelectInline.vue?vue&type=template&id=037b20c3"
import script from "./SPRMultiSelectInline.vue?vue&type=script&lang=js"
export * from "./SPRMultiSelectInline.vue?vue&type=script&lang=js"
import style0 from "./SPRMultiSelectInline.vue?vue&type=style&index=0&id=037b20c3&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports