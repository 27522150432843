<template>
  <div class="col-12 user-profile-usage">
    <div class="title">
      <h3>{{ title }}</h3>
    </div>
    <div class="grid-name profile-full-name">
      {{ data.identity.firstName }} {{ data.identity.lastName }}
    </div>
    <!-- Can -->
    <div class="table can">
      <table class="table-header">
        Can
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.can.canBeAnonymized}`">
            CanBeAnonymized
          </td>
          <td class="row-value" :class="`value-${data.can.canBeAnonymized}`">
            {{ data.can.canBeAnonymized }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.can.canBeDeleted}`">
            CanBeDeleted
          </td>
          <td class="row-value" :class="`value-${data.can.canBeDeleted}`">
            {{ data.can.canBeDeleted }}
          </td>
        </tr>
      </table>
    </div>
    <!-- Identity -->
    <div class="detail">
      <div class="header">
        Identity ({{ data.identity.profileId }})
      </div>
      <div class="label">
        Email
      </div>
      <div class="content">
        {{ data.identity.email }}
      </div>
      <div class="label">
        Section
      </div>
      <div class="content">
        {{ data.identity.sectionName }} ({{ data.identity.sectionId }})
      </div>
      <div class="label">
        Address
      </div>
      <div class="content">
        {{ data.identity.address.streetAddress }}
      </div>
      <div class="content">
        {{ data.identity.address.zipCode }} {{ data.identity.address.city }}
      </div>
      <div class="content">
        {{ data.identity.address.country }}
      </div>
      <div v-if="data.has.hasFriendVolunteerProfile" class="label">
        FriendManagementProfile
      </div>
      <div v-if="data.has.hasFriendVolunteerProfile" class="content">
        friendVolunteerId: {{ data.identity.friendVolunteerId }}
      </div>
      <div v-if="data.has.hasFriendVolunteerNetProfile" class="content">
        friendVolunteerProfileNetId: {{ data.identity.friendVolunteerProfileNetId }}
      </div>
      <div v-if="data.has.hasFriendVolunteerStandardProfile" class="content">
        friendVolunteerProfileStandardId: {{ data.identity.friendVolunteerProfileStandardId }}
      </div>
    </div>
    <!-- Has -->
    <div class="table has">
      <table class="table-header">
        Has
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasBeenAnonymized}`">
            hasBeenAnonymized
          </td>
          <td class="row-value" :class="`value-${data.has.hasBeenAnonymized}`">
            {{ data.has.hasBeenAnonymized }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasUserLogin}`">
            hasUserLogin
          </td>
          <td class="row-value" :class="`value-${data.has.hasUserLogin}`">
            {{ data.has.hasUserLogin }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasUserRoles}`">
            hasUserRoles
          </td>
          <td class="row-value" :class="`value-${data.has.hasUserRoles}`">
            {{ data.has.hasUserRoles }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasCreatedEvents}`">
            hasCreatedEvents
          </td>
          <td class="value" :class="`value-${data.has.hasCreatedEvents}`">
            {{ data.has.hasCreatedEvents }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasParticipatedInEvents}`">
            hasParticipatedInEvents
          </td>
          <td class="row-value" :class="`value-${data.has.hasParticipatedInEvents}`">
            {{ data.has.hasParticipatedInEvents }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasLightProfiles}`">
            hasLightProfiles
          </td>
          <td class="row-value" :class="`value-${data.has.hasLightProfiles}`">
            {{ data.has.hasLightProfiles }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasGroupMemberships}`">
            hasGroupMemberships
          </td>
          <td class="value" :class="`value-${data.has.hasGroupMemberships}`">
            {{ data.has.hasGroupMemberships }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasPendingGroupMemberships}`">
            hasPendingGroupMemberships
          </td>
          <td class="row-value" :class="`value-${data.has.hasPendingGroupMemberships}`">
            {{ data.has.hasPendingGroupMemberships }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasMessages}`">
            hasMessages
          </td>
          <td class="row-value" :class="`value-${data.has.hasMessages}`">
            {{ data.has.hasMessages }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasNotifications}`">
            hasNotifications
          </td>
          <td class="row-value" :class="`value-${data.has.hasNotifications}`">
            {{ data.has.hasNotifications }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendVolunteerProfile}`">
            hasFriendVolunteerProfile
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendVolunteerProfile}`">
            {{ data.has.hasFriendVolunteerProfile }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendVolunteerNetProfile}`">
            hasFriendVolunteerNetProfile
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendVolunteerNetProfile}`">
            {{ data.has.hasFriendVolunteerNetProfile }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendVolunteerStandardProfile}`">
            hasFriendVolunteerStandardProfile
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendVolunteerStandardProfile}`">
            {{ data.has.hasFriendVolunteerStandardProfile }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendVolunteerGroups}`">
            hasFriendVolunteerGroups
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendVolunteerGroups}`">
            {{ data.has.hasFriendVolunteerGroups }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendships}`">
            hasFriendships *)
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendships}`">
            {{ data.has.hasFriendships }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendshipsNet}`">
            hasFriendshipsNet
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendshipsNet}`">
            {{ data.has.hasFriendshipsNet }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendshipsStandard}`">
            hasFriendshipsStandard
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendshipsStandard}`">
            {{ data.has.hasFriendshipsStandard }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendPools}`">
            hasFriendPools *)
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendPools}`">
            {{ data.has.hasFriendPools }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendPoolsNet}`">
            hasFriendPoolsNet
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendPoolsNet}`">
            {{ data.has.hasFriendPoolsNet }}
          </td>
        </tr>
        <tr class="row">
          <td class="row-label" :class="`row-label-value-${data.has.hasFriendPoolsStandard}`">
            hasFriendPoolsStandard
          </td>
          <td class="row-value" :class="`value-${data.has.hasFriendPoolsStandard}`">
            {{ data.has.hasFriendPoolsStandard }}
          </td>
        </tr>
        <div class="info">
          *) Friend Management v1
        </div>
      </table>
    </div>
    <!-- Lists -->
    <div class="detail">
      <div class="header">
        Memberships
      </div>
      <div class="label">
        Roles
      </div>
      <div class="content">
        <li v-for="role in data.roles" :key="role.id">
          {{ role }}
        </li>
      </div>
      <div class="label">
        Groups
      </div>
      <div class="content">
        <li v-for="group in data.groups" :key="group.id">
          {{ group }}
        </li>
      </div>
      <div class="label">
        GroupsPending
      </div>
      <div class="content">
        <li v-for="groupPending in data.groupsPending" :key="groupPending.id">
          {{ groupPending }}
        </li>
      </div>
      <div class="label">
        FriendManagementGroups
      </div>
      <div class="content">
        <li v-for="(friendManagementGroup, index) in data.friendManagementGroups" :key="index">
          {{ friendManagementGroup }}
        </li>
      </div>
      <div class="label">
        FriendPools
      </div>
      <div class="content">
        <li v-for="friendPool in data.friendPools" :key="friendPool.id">
          {{ friendPool }}
        </li>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'UserProfileUsageReport',
    components: {},
    props: {
      title: {
        type: String,
        required: true
      },
      data: {
        type: Object,
        required: true
      },
      userId: {
        type: String,
        required: true
      }
    },
    methods: {
      getBooleanClass (value) {
        if (this.data.value === true) {
          return 'active faCheck fa-checkbox-marked'
        }
        return 'not-active fa-checkbox-unmarked'
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
  .user-profile-usage {
    .grid-name {
      grid-column: 2;
      -ms-grid-column: 2;
      line-height: 60px;

      font-size: 20px;
      letter-spacing: 1.5px;
      font-weight: 700;
      color: $brand-red;
      text-transform: uppercase;
      font-family: "Signa-Light", Sans-Serif;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .table {
      padding: 0rem;

      .info {
        font-size: 12px;
        color: grey;
        padding: 10px 15px 0px ;
      }

      td, tr {
      padding: 0rem;
      }

      .row {
        margin: 0 5px 0 5px;
        padding: 0rem;

        .row-label {
          font-size: 16px;
          //margin: 5px 0 5px 5px;
          color: black;
          min-width: 350px;
        }

        .row-label-value-true {
          color: green;
          font-weight: bold;
        }

        .row-label-value-false {
          color: grey;
        }

        .value-true {
          color: green;
          font-weight: bold;
        }

        .value-false {
          color: grey;
        }
      }

      .table-header {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1.5px;
        margin: 10px 0 10px 0;
        color:$brand-red;
      }

    }

    .detail {
      margin: 10px 0 10px 0;

      .content {
        margin: 0 20px 0 20px;

        .value-true {
          color: green;
          font-weight: bold;
        }

        .value-false {
          color: grey;
        }
      }

      .header {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 1.5px;
        margin: 10px 0 10px 0;
        color:$brand-red;
      }

      .label {
        margin: 10px 0 10px 10px;
        color:$brand-red;
      }
    }

  }
</style>
