<template>
  <div class="comp-delete-profile-list">
    <div v-if="profiledeletionrequests" class="listing">
      <div v-for="(d, i) in profiledeletionrequests" :key="i" class="listing-row">
        <router-link v-if="d.origin === 2" :to="'/admin/user/' + d.profileId">
          {{ d.firstName }} {{ d.lastName }} ({{ toFinnishDate(d.created) }}) {{ $t('profileDeleteRequest.origin' + getProfileDeleteRequestOriginText(d.origin)) + '. ' + $t('profile.membershipStatus') + ": " + getMembershipStatusText(d.membershipStatus)}}
        </router-link>
        <router-link v-else :to="'/admin/user/' + d.profileId">
          {{ d.firstName }} {{ d.lastName }} ({{ toFinnishDate(d.created) }}) {{ $t('profileDeleteRequest.origin' + getProfileDeleteRequestOriginText(d.origin)) }}
        </router-link>
      </div>
    </div>
    <div v-else class="loading text-center brand-red">
      <div class="my-2">
        <loading />
      </div>
      <div class="my-3">
        <p>{{ $t('userAdmin.loading') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import loading from '../SPRLoading.vue'
  import moment from 'moment'

  export default {
    name: 'DeleteProfileList',
    components: { loading },
    data: function () {
      return {
        profiledeletionrequests: null
      }
    },
    mounted: function () {
      if (!this.hasAdmin()) {
        this.showAlertPermissionDenied().then(() => this.$router.push('/profile'))
      } else {
        const url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.profiledeleterequests[0]
        this.$http.get(url).then(success => {
          this.profiledeletionrequests = JSON.parse(success.bodyText)
        }, f => {
          this.showAlertError(this.$t('common.fetchFailed'))
        })
      }
    },
    methods: {
      toFinnishDate: function (date) {
        if (date) {
          return moment.utc(date).local().format('D.M.YYYY H.mm')
        }
        return ''
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .comp-delete-profile-list {
    background: $brand-white;
    padding: 15px;

    .listing {
      display: flex;
      display: -ms-flexbox;
      flex-direction: column;
      -ms-flex-direction: column;

      .listing-row {
        padding: 10px 15px;
        color: $brand-red;

        &:nth-child(even) {
          background: rgba(0,0,0,0.03);
        }

        a {
          color: $brand-red;
        }
      }
    }
  }
</style>
