<template>
  <div v-if="!done" class="row comp-search-result my-2">
    <div class="col-12 grid-row">
      <div class="grid-img style-img">
        <sprportrait :style="portraitStyle"
                     class="portrait-size"
        />
      </div>
      <div class="grid-name style-name">
        <span>{{ volunteer.firstName }} {{ volunteer.lastName }}</span><br>
        <span class="more-info">{{ volunteer.email }}</span><br>
        <span class="more-info">{{ zipCode }}</span>
        <span v-if="section" class="more-info"> ({{ section.name }})</span>
      </div>
      <div v-if="!pending" class="grid-zip style-zip">
        <span v-if="!selected">
          <span v-if="actionText" class="action-link" tabindex="0" @click="handleClick(volunteer.id)">
            {{ actionText }}
          </span>
        </span>
        <span v-else>
          <span class="action-link" tabindex="0" @click="handleClick(volunteer.id, 0)">
            {{ $t('friendTraining.undoFriendOperation') }}
          </span>
        </span>
      </div>
      <div v-else class="griz-zip style-zip text-right">
        <span class="mt-1"><loading /></span>
      </div>
    </div>
  </div>
</template>

<script>
  import sprportrait from '../SPRPortrait.vue'
  import loading from '../SPRLoading.vue'
  import anonPortrait from '../../assets/img/anon-portrait.png'

  export default {
    name: 'FriendSearchResult',
    components: { sprportrait, loading },
    props: {
      volunteer: {
        type: Object,
        default: null
      },
      mode: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {
        selected: false,
        pending: false,
        done: false
      }
    },
    computed: {
      portraitStyle: function () {
        var port = this.volunteer && this.volunteer.Portrait ? this.volunteer.Portrait : anonPortrait
        return 'background: url(' + port + '); background-size: cover; background-position: center center;'
      },
      actionText: function () {
        if (this.hasRole('Admin') || this.hasRole('FriendshipTrainer') || this.hasRole('FriendshipAdmin') || this.hasRole('DistrictAdmin') || this.hasRole('NationalAdmin') || this.hasRole('DistrictFriendshipAdmin') || this.hasRole('FriendshipGroupManager')) {
          if (this.mode === '1') {
            return this.$t('friendTraining.grantFriendTraining')
          } else if (this.mode === '2') {
            return this.$t('friendTraining.revokeFriendStatus')
          }
        }
        return ''
      },
      zipCode: function () {
        return (this.volunteer.zipCode + '').padStart(5, '0')
      },
      section: function () {
        if (this.volunteer) {
          const self = this
          return _.find(self.$store.state.sections, function (s) {
            return s.id === self.volunteer.sectionId
          })
        }

        return null
      }
    },
    methods: {
      handleClick: function (id) {
        if (this.mode === '1') {
          this.makeRequest(id, 2)
        } else if (this.mode === '2') {
          this.makeRequest(id, 1)
        }
      },
      makeRequest: function (id, newState) {
        if (this.pending || newState < 1 || newState > 3) {
          return
        }
        this.pending = true

        var body = {}
        body.payload1 = Number(id)
        body.payload2 = Number(newState)
        var uri = (this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.setFriendTraining[0])

        var self = this
        this.$http.post(uri, body).then(s => {
          self.pending = false
          self.selected = !self.selected
          self.done = true
        }, f => {
          self.pending = false
          self.done = true
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
  .comp-search-result {
    background: $brand-white;
    border: 2px solid $brand-white;

    &:hover {
      border: 2px solid $brand-red;
    }

    .portrait-size {
      height: 40px;
      width: 40px;
      margin: 10px;
    }

    .grid-row {
      display: grid;
      display: -ms-grid;
      grid-template-rows: 60px;
      -ms-grid-rows: 60px;
      grid-template-columns: 60px 1fr 200px;
      -ms-grid-columns: 60px 1fr 200px;
      padding-right: 5px;
      padding-left: 5px;
    }
    .grid-img {
      grid-row: 1;
      -ms-grid-row: 1;
      grid-column: 1;
      -ms-grid-column: 1;
    }

    .more-info {
      color: $brand-grey10;
      text-transform: none;
    }

    .grid-name {
      grid-row: 1;
      -ms-grid-row: 1;
      grid-column: 2;
      -ms-grid-column: 2;
    }

    .grid-zip {
      grid-row: 1;
      -ms-grid-row: 1;
      grid-column: 3;
      -ms-grid-column: 3;
    }

    .style-zip {
      line-height: 60px;
      text-align: right;

      span {
        line-height: 17px;
        overflow-wrap: break-word;
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
      }
    }

    @media (max-width: $media-sm-max) {
      .grid-name {
        grid-row: 2;
        -ms-grid-row: 2;
        grid-column-start: 1;
        grid-column-end: 4;
        -ms-grid-column-span: 3;
      }
    }

    .style-name {
      font-size: 13px;
      letter-spacing: 0.3px;
      font-weight: 700;
      color: $brand-red;
      text-transform: uppercase;
      font-family: "Signa-Light", Sans-Serif;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .style-img {
      line-height: 60px;
    }

    .action-link {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
