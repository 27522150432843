<template>
  <div :id="uniqueid" class="comp-profile-editor">
    <div class="editor-header">
      <!-- This is intentionally <a> element. This is the proper way to
      enable keyboard navigation for the element, believe it or not. -->
      <a v-show="!editing" class="cap-label action-icon" tabindex="0" href="javascript:void(0);" @click="toggleEdit">
        {{ title }}
        <font-awesome-icon icon="pencil-alt" aria-hidden="true" />
      </a>
      <a v-show="editing" class="cap-label action-icon" tabindex="0" href="javascript:void(0);" @click="toggleEdit">
        {{ title }}
        <font-awesome-icon icon="check" class="save" aria-hidden="true" />
      </a>
      <a v-show="editing" class="cap-label action-icon" tabindex="0" href="javascript:void(0);" @click="revertChanges">
        <font-awesome-icon icon="times" aria-hidden="true" />
      </a>
    </div>
    <div v-if="!editing" class="editor-area">
      {{ value }}
    </div>
    <div v-else class="editor-area">
      <textarea ref="editorRef" rows="6" :value="value" :class="{'spr-danger':!isValid}" @input="handleInput" />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import messages from '../mixins/messages.js'

  // the data object
  export interface TemplateComponentData {
    editing: boolean,
    backup: string,
    uniqueid: string
  }

  export default Vue.extend({
    name: 'ProfileEditorText',
    mixins: [messages],
    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      saveEvent: {
        type: String,
        default: ''
      },
      isValid: Boolean
    },
    data (): TemplateComponentData {
      return {
        editing: false,
        backup: '',
        uniqueid: _.uniqueId('profile-editor-')
      }
    },
    computed: {
      parentNode (): HTMLTextAreaElement | null {
        return document.getElementById(this.uniqueid) as HTMLTextAreaElement
      }
    },
    beforeDestroy: function () {
      window.removeEventListener('focus', this.handleBlur)
      window.removeEventListener('click', this.detectClicks)
    },
    mounted: function () {
      window.addEventListener('focus', this.handleBlur, true)
      window.addEventListener('click', this.detectClicks, true)
    },
    methods: {
      toggleEdit: function () {
        if (!this.isValid) {
          return
        }

        this.editing = !this.editing

        if (this.editing) {
          // opening edit dialog
          this.backup = this.value
        } else {
          // closing edit dialog
          if (this.backup !== (this.$refs.editorRef as HTMLTextAreaElement).value) {
            this.$emit(this.saveEvent, (this.$refs.editorRef as HTMLTextAreaElement).value)
          }
        }
      },
      handleInput: function () {
        this.$emit('input', (this.$refs.editorRef as HTMLTextAreaElement).value)
      },
      handleBlur: function (event) {
        if (this.editing && event.target && this.parentNode && !this.parentNode.contains(event.target)) {
          this.toggleEdit()
        }
      },
      detectClicks: function (event) {
        this.handleBlur(event)
      },
      revertChanges: function () {
        if (this.editing) {
          (this.$refs.editorRef as HTMLTextAreaElement).value = this.backup
          this.handleInput()
          this.toggleEdit()
        }
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .comp-profile-editor {
    display: grid;
    display: -ms-grid;

    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-template-rows: 25px 1fr;
    -ms-grid-rows: 25px 1fr;

    .editor-header {
      grid-row: 1;
      -ms-grid-row: 1;
      grid-column: 1;
      -ms-grid-column: 1;

      .action-icon {
        color: $brand-red;
        font-family: $font-bold;
        cursor: pointer;
        text-decoration: none;
        text-transform:uppercase;
        margin-right: 5px;

        .save {
          color: $brand-success;
        }
      }
    }

    .editor-area {
      grid-row: 2;
      -ms-grid-row: 2;
      grid-column: 1;
      -ms-grid-column: 1;
      margin-left: 5px;
      white-space: pre-line;
      overflow-wrap: break-word;
      word-break: break-all;

      textarea {
        width: calc(100% - 15px);
        resize: none;
        margin-top: 10px;
        font-family: "Signa-Book", Sans-Serif;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .spr-danger {
      border: 2px solid $brand-red !important;
    }
  }
</style>
