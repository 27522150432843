<template>
  <div class="row friendvolunteerstandarddetails">
    <div class="col-12">
      <h5>{{ $t('volunteerForm.standardFormTitle') }}</h5>
      <router-link class="edit" :to="'/friend/volunteer/edit/standard'">
        <font-awesome-icon icon="pencil-alt" />
      </router-link>
    </div>
    <div v-if="profile.shortDescription" class="col-12">
      <dl>
        <dt>{{ $t('volunteerForm.shortDescription') }}</dt>
        <dd>{{ profile.shortDescription }}</dd>
      </dl>
    </div>
    <div class="col-12">
      <dl>
        <dt>{{ $t('volunteerForm.multipleCustomers2') }}</dt>
        <dd>{{ $t('boolean.' + profile.multipleCustomers) }}</dd>
      </dl>
    </div>
    <div v-if="profile.hasAlternativeAddress" class="col-12">
      <dl>
        <dt>{{ $t('volunteerForm.alternativeAddress') }}</dt>
        <dd>{{ computedAlternativeAddress }}</dd>
      </dl>
    </div>
    <div class="col-12 col-md-6">
      <dl>
        <dt>{{ $t('volunteerForm.genderPreference') }}</dt>
        <dd><pilllist class="pills" :value="computedGenderPreference" /></dd>
      </dl>
    </div>
    <div class="col-12 col-md-6">
      <dl>
        <dt>{{ $t('volunteerForm.desiredAge') }}</dt>
        <dd><pilllist class="pills" :value="computedPreferredAges" /></dd>
      </dl>
    </div>
    <div class="col-12 col-md-6">
      <dl>
        <dt>{{ $t('volunteerForm.friendshipType') }}</dt>
        <dd><pilllist class="pills" :value="computedFriendshipTypes" /></dd>
      </dl>
    </div>
    <div class="col-12 col-md-6">
      <dl>
        <dt>{{ $t('volunteerForm.interests') }}</dt>
        <dd><pilllist class="pills" :value="computedInterests" /></dd>
      </dl>
    </div>
    <div v-if="profile.otherInterests" class="col-12">
      <dl>
        <dt>
          {{ $t('volunteerForm.otherInterests') }}
        </dt>
        <dd>
          {{ profile.otherInterests }}
        </dd>
      </dl>
    </div>
    <div class="col-12 col-md-6">
      <dl>
        <dt>{{ $t('volunteerForm.availabilityTimes') }}</dt>
        <dd><pilllist class="pills" :value="computedAvailabilityTimes" /></dd>
        <dt>{{ $t('volunteerForm.availabilityFrequency') }}</dt>
        <dd>{{ computedAvailabilityFrequence }}</dd>
        <dt v-if="profile.availabilityDetails">
          {{ $t('volunteerForm.availabilityDetails') }}
        </dt>
        <dd v-if="profile.availabilityDetails">
          {{ profile.availabilityDetails }}
        </dd>
      </dl>
    </div>
    <div class="col-12 col-md-6">
      <dl>
        <dt>{{ $t('volunteerForm.customerType') }}</dt>
        <dd><pilllist class="pills" :value="computedCustomerTypes" /></dd>
      </dl>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { FriendVolunteerProfileStandardDto } from '@/types/index'
  import pilllist from '@/components/SPRPillList.vue'
  import enumhelper from '../../mixins/enums.js'
  import _ from 'lodash'

  export default Vue.extend({
    name: 'FriendVolunteerStandardDetails',
    components: {
      pilllist
    },
    mixins: [enumhelper],
    props: {
      profile: {
        type: Object as () => FriendVolunteerProfileStandardDto,
        required: true
      }
    },
    computed: {
      // all computed props should define return type, or you will get ts errors!
      computedGenderPreference (): string {
        // @ts-ignore can't find mixins
        var elements = this.integerIntoBitmaskProxy(this.profile.preferredGenders)
        // @ts-ignore can't find mixins
        return _.map(elements, this.getGenderTranslation)
      },
      computedPreferredAges (): string {
        // @ts-ignore can't find mixins
        var elements = this.integerIntoBitmaskProxy(this.profile.preferredAges)
        // @ts-ignore can't find mixins
        return _.map(elements, this.getAgeTranslation)
      },
      computedAvailabilityFrequence (): string {
        // @ts-ignore
        return this.getAvailabilityFrequencyTranslation(this.profile.availabilityFrequence)
      },
      computedAlternativeAddress (): string {
        if (!this.profile.hasAlternativeAddress) {
          return ''
        }

        return `${this.profile.alternativeStreet ? this.profile.alternativeStreet : ''} ${this.profile.alternativeZipCode ? this.profile.alternativeZipCode : ''} ${this.profile.alternativeCity ? this.profile.alternativeCity : ''}`
      },
      computedFriendshipTypes (): string {
        // @ts-ignore can't find mixins
        var elements = this.integerIntoBitmaskProxy(this.profile.friendshipTypes)
        // @ts-ignore can't find mixins
        return _.map(elements, this.getFriendshipTypesTranslation)
      },
      computedInterests (): string {
        // @ts-ignore can't find mixins
        var elements = this.integerIntoBitmaskProxy(this.profile.interests)
        // @ts-ignore can't find mixins
        return _.map(elements, this.getInterestsTranslation)
      },
      computedCustomerTypes (): string {
        // @ts-ignore can't find mixins
        var elements = this.integerIntoBitmaskProxy(this.profile.customerTypes)
        // @ts-ignore can't find mixins
        return _.map(elements, this.getCustomerTypesTranslation)
      },
      computedAvailabilityTimes (): string {
        // @ts-ignore can't find mixins
        var elements = this.integerIntoBitmaskProxy(this.profile.availabilityTimes)
        // @ts-ignore can't find mixins
        return _.map(elements, this.getAvailabilityTimesTranslation)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .friendvolunteerstandarddetails {
    background-color: $brand-white;
    padding-top: 15px;
    padding-bottom: 15px;
  }
</style>
