<template>
  <div class="searchheader">
    <div class="row resultstypeheader">
      <div class="col-3">
        <router-link
          class="resultstype customer"
          :class="{ 'selected' : selectedTab === 'customer' }"
          :to="`/friend/search/${id}/customer`"
        >
          {{ $t('customerSearch.customerTypeFriend') }}
        </router-link>
      </div>
      <div class="col-3">
        <router-link
          class="resultstype volunteer"
          :class="{ 'selected' : selectedTab === 'volunteer' }"
          :to="`/friend/search/${id}/volunteer`"
        >
          {{ $t('customerSearch.customerTypeVolunteer') }}
        </router-link>
      </div>
      <div class="col-3">
        <router-link
          class="resultstype pools"
          :class="{ 'selected' : selectedTab === 'pools' }"
          :to="`/friend/search/${id}/pools`"
        >
          {{ $t('customerSearch.pools') }}
        </router-link>
      </div>
      <div class="col-3">
        <router-link
          class="resultstype discussion"
          :class="{ 'selected' : selectedTab === 'discussion' }"
          :to="`/friend/search/${id}/discussion`"
        >
          {{ $t('customerSearch.discussionGroup') }}
        </router-link>
      </div>
      <div class="navindicator col-12" :class="selectedTab" />
    </div>
    <div v-show="selectedTab !== 'discussion'" class="row search">
      <div class="col-md-12 col-lg-8 col-xl-7">
        <searchbox :placeholder="searchPlaceholder" :value="search" @input="doSearch" />
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <searchsort v-show="selectedTab === 'customer' || selectedTab === 'volunteer'" v-model="sortBy" :list="sortByList" @input="doSort" />
      </div>
      <div class="col-7">
        <div v-if="selectedTab === 'customer'" class="d-flex add-customer justify-content-end">
          <sprbutton :size="4"
                     :title="$t('customerSearch.addNewCustomer')"
                     :bordersize="2"
                     class="next-button"
                     icon="plus"
                     click-event="click"
                     @click="addCustomer"
          />
        </div>
        <div v-else-if="selectedTab === 'volunteer'" class="d-flex add-customer justify-content-end">
          <sprbutton :size="4"
                     :title="$t('customerSearch.addNewVolunteer')"
                     :bordersize="2"
                     class="next-button"
                     icon="plus"
                     click-event="click"
                     @click="addVolunteer"
          />
        </div>
        <div v-else-if="selectedTab === 'pools'" class="d-flex add-customer justify-content-end">
          <sprbutton :size="4"
                     :title="$t('customerSearch.addNewPool')"
                     :bordersize="2"
                     class="next-button"
                     icon="plus"
                     click-event="click"
                     @click="addPool"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import sprbutton from '../SPRButton.vue'
  import searchbox from '../SPRSearchBox.vue'
  import searchsort from '../SPRSearchSort.vue'
  export default Vue.extend({
    name: 'SearchHeader',
    components: { sprbutton, searchbox, searchsort },
    props: {
      search: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      isNetFmg: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        sortBy: this.$store.state.searchFilter.sortBy,
        sortByList: [
          { type: 'NEWEST', text: this.$t('customerSearch.sortByNewest') },
          { type: 'OLDEST', text: this.$t('customerSearch.sortByOldest') }
        ]
      }
    },
    computed: {
      selectedTab (): string {
        return this.$route.params.searchmode
      },
      searchPlaceholder (): string | LocaleMessage {
        return this.selectedTab === 'pools' ? this.$t('customerSearch.poolsSearchPlaceholder') : this.$t('customerSearch.friendSearchPlaceholder')
      }
    },
    created () {
      if (this.selectedTab !== 'discussion' && this.selectedTab.toUpperCase() !== this.$store.state.searchFilter.searchType) {
        this.$store.state.searchFilter.skip = 0
      }
    },
    methods: {
      doSearch (val) {
        this.$emit('search', val)
      },
      doSort (type) {
        this.$emit('sort', type)
      },
      addCustomer () {
        const uri = this.isNetFmg ? `/friend/netcustomer/add/${this.id}` : `/friend/standardcustomer/add/${this.id}`
        this.$router.push(uri)
      },
      addVolunteer () {
        const uri = this.isNetFmg ? `/friend/netprofile/add/${this.id}` : `/friend/standardprofile/add/${this.id}`
        this.$router.push(uri)
      },
      addPool () {
        const uri = `/friend/pools/${this.id}/add`
        this.$router.push(uri)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .searchheader {
    .resultstypeheader {
      margin-top:20px;
      margin-bottom: 10px;
      // @media screen and (min-width: $media-md-max) {
      //   h4 {
      //     font-size:1.5em !important;
      //     display: inline;
      //   }
      // }

      .col-3 {
        padding: 0;
      }

      .resultstype {
        color:$brand-grey5;
        text-decoration:none;
        display:inline-block;
        font-size: 15px;
        border: solid 2px transparent;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 100%;
        padding: 5px 10px;
        text-align: center;
        color: $brand-white;
        margin-bottom: -2px;

        a {
          color:$brand-white;
        }

        &.selected {
          margin-top: -2px;
        }

        &.customer {
          background-color:$customer-color-strong;
        }
        &.volunteer {
          background-color:$volunteer-color-strong;
        }
        &.pools {
          background-color:$pools-color-strong;
        }
        &.discussion {
          background-color:$brand-grey10;
        }
      }
    }

    .navindicator {
      height: 5px;
      &.customer {
        background-color:$customer-color-strong;
      }
      &.volunteer {
        background-color:$volunteer-color-strong;
      }
      &.pools {
        background-color:$pools-color-strong;
      }
      &.discussion {
        background-color:$brand-grey10;
      }
    }

    // @media screen and (max-width: $media-sm-max) {
    //   .resultstypeheader {
    //     margin-bottom: 10px;
    //     // background-color:white;
    //     // text-align:center;
    //     h4 {
    //       font-size:0.75em !important;
    //       margin-bottom: 5px;

    //       a.resultstype.selected {
    //         color:$brand-red;
    //       }
    //     }
    //   }
    // }

    .add-customer {
      margin-bottom: 10px;
      min-width: 253px;
    }

    .search {
      margin-bottom: 15px;
    }
  }
</style>
