<template>
  <div v-if="groups.length > 0" class="col-12 groups">
    <div v-for="(item, index) in groups" :key="item.id" class="group">
      <groupview :key="_uid + index + 'action'" :group="item" :detailed="true" :has-controls="true" />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import groupview from '../groups/GroupView.vue'

  export default Vue.extend({
    name: 'Groups',
    components: { groupview },
    props: {
      groups: {
        type: Array,
        default: () => []
      }
    }
  })
</script>
<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .group {

    .row{
          padding: 5px 5px 10px;
        }

    .grouplist {
      background-color:$brand-white;
      margin-top:30px;
      padding: 15px 0;
      margin-bottom:30px;
    }

  &:nth-child(odd) {
    background: rgba(0,0,0,0.03);
  }
  }
</style>
