import Vue from 'vue'
import Store from '../store/index'

function redirectToLogin () {
  if (this && !this.$route.path.toLowerCase().startsWith('/login') && !this.$route.path.toLowerCase().startsWith('/adsignin')) {
    window.location.href = '/login?from=' + this.$route.fullPath
  }
}

// fuck ie 11 to death
Vue.http.interceptors.push((request, next) => {
  var ua = window.navigator.userAgent
  var msie = ua.indexOf('MSIE ') > -1
  if (msie) {
    request.url += (request.url.indexOf('?') > 0 ? '&' : '?') + `cb=${new Date().getTime()}`
  }

  next()
})

// this adds the auth token, if it exists, to every http method
Vue.http.interceptors.push(function (request, next) {
  // modify headers
  const token = sessionStorage.getItem('authtoken')
  const isException = (_.findIndex(Store.state.token.exceptions, function (item) {
    return item.test(request.url)
  }) > -1)

  const endpointCalled = _.find(Store.state.apiEndpoints, (endpoint) => {
    if (!Array.isArray(endpoint)) {
      return false
    }
    return request.url.indexOf(endpoint[0]) > -1
  })

  var noTokenNeeded = isException && endpointCalled && endpointCalled[2] === false
  if (noTokenNeeded) {
    next()
  }

  if (token && !isException) {
    var short = sessionStorage.getItem('shorttoken')
    var lifetime = short ? JSON.parse(atob(short.split('.')[1])).exp - (Date.now() / 1000) : -1 // lifetime left in seconds
    if (short && lifetime > 100) {
      // all good, short token is there with lifetime left.
      request.headers.set('Authorization', 'bearer ' + short)
      // might be a refresh of the page, which clears vue state but not session cookie
      if (!Store.state.sessionJwtToken) {
        Store.dispatch('setSessionJwtToken', short)
      }
      next()
    } else {
      if (token && !Store.state.token.isRefreshing) {
        Store.commit('setTokenRefreshing', true)

        Vue.http.get(Store.state.token.refresh + '?authtoken=' + token).then(s => {
          sessionStorage.setItem('shorttoken', s.body)
          request.headers.set('Authorization', 'bearer ' + s.body)
          Store.dispatch('setSessionJwtToken', s.body)
          Store.commit('setTokenRefreshing', false)
          next()
        }, r => { console.log('rejected call to token refresh') })
      }
    }
  } else {
    if (request.url.toLowerCase().indexOf(Store.state.token.refresh.toLowerCase()) > -1) {
      if (!token) {
        // user is not logged in, but tries to refresh. terminate the request.
        redirectToLogin()
        return Promise.reject(new Error('logged out'))
      } else {
        request.headers.set('Authorization', 'bearer ' + token)
      }
    }
    next()
  }
})

// Inject response handling here
Vue.http.interceptors.push(function (request, next) {
  next(response => {
    if (response.status === 403 || response.status === 401) {
      if (!request.__retry) {
        request.__retry = true
        var token = sessionStorage.getItem('authtoken')
        if (token === null) {
          redirectToLogin()
          return Promise.reject(response)
        }
        Store.commit('setTokenRefreshing', true)
        return Vue.http.get(Store.state.token.refresh + '?authtoken=' + token, {
          headers: { Authorization: 'bearer ' + token }
        }).then(s => {
          sessionStorage.setItem('shorttoken', s.body)
          request.headers.set('Authorization', 'bearer ' + s.body)
          Store.dispatch('setSessionJwtToken', s.body)
          Store.commit('setTokenRefreshing', false)

          return Vue.http(request).then(x => { return x })
        }).catch((err) => {
          sessionStorage.removeItem('shorttoken')
          sessionStorage.removeItem('authtoken')
          Store.commit('clearSessionJwtToken')
          // In case user has sessionstorage set, but server has dropped session from cache.
          redirectToLogin()
          return Promise.reject(err)
        })
      }
    }
  })
})
