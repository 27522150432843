<template>
  <div class="row comp-useradmin-password-editor my-2">
    <div class="row">
      <div class="col-12">
        <sprvalidation :validation-errors="validationErrors" class="mb-5" />
      </div>
    </div>
    <div class="col-12 col-md-6">
      <forminput v-model="loginData.password"
                 :hint="$t('adminUserEditor.password')"
                 :is-valid="true"
                 class="new-password"
      />
    </div>
    <div class="col-12 col-md-6">
      <div class="d-flex h-100 container">
        <div class="row align-self-center">
          <span v-if="loginData.password !== ''" class="pw-alert">
            <span v-if="loginData.password.length >= 6">{{ $t('adminUserEditor.passwordChangeAlert') }}</span>
            <span v-else>{{ $t('adminUserEditor.passwordLength') }}</span>
          </span>
          <span v-else class="pw-no">{{ $t('adminUserEditor.passwordNoChange') }}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <forminput v-model="loginData.email"
                 :hint="$t('adminUserEditor.loginName')"
                 :is-valid="true"
                 class="new-email"
      />
    </div>
    <div class="col-12 col-md-6">
      <div class="d-flex h-100 container">
        <div class="row align-self-center">
          <span v-if="loginData.email !== user.email" class="pw-alert">
            <span v-if="loginData.password.length < 6">{{ $t('adminUserEditor.mustSetNewPasswordOnEmailChange') }}</span>
            <span v-else>{{ $t('adminUserEditor.emailChangeAlert') }}</span>
          </span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <!-- password save/cancel buttons -->
      <div class="buttons">
        <div class="col-12">
          <sprbutton type="primary"
                     :primary="true"
                     :title="$t('userAdmin.editSave')"
                     class="my-2 mx-1"
                     :size="1"
                     click-event="save"
                     @save="savePassword"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { LoginDataDto } from '@/types'
  import Vue from 'vue'
  import sprvalidation from '../SPRValidationError.vue'
  import forminput from '../SPRSimpleText.vue'
  import sprbutton from '../SPRButton.vue'

  // the data object
  export interface TemplateComponentData {
    loginData: LoginDataDto,
    currentPasswordFailure: any,
    validatedFields: Array<string>
  }

  export default Vue.extend({
    name: 'UserPasswordEditor',
    components: { forminput, sprbutton, sprvalidation },
    props: {
      id: {
        type: String,
        default: ''
      },
      user: {
        type: Object,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        loginData: {
          password: '',
          email: this.user.email
        } as LoginDataDto,
        currentPasswordFailure: null,
        validatedFields: ['password', 'retypePassword']
      }
    },
    computed: {
      validationErrors (): Array<any> {
        var arr = [] as Array<any>
        var self = this

        if (self.currentPasswordFailure) {
          arr.push(['login.attempt', 'currentPasswordFailed', ''])
          self.currentPasswordFailure.forEach(err => {
            //@ts-ignore
            if (err.code) { arr.push(['', 'error.' + this.toLowerCamelCase(err.code), '']) }
          })
        }

        if (!this.validatedFields) {
          return arr
        }

        this.validatedFields.forEach(function (fieldName) {
          //@ts-ignore
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })

          if (err != null) {
            arr.push(['profile.' + err.field, String(err.rule), String(err.msg)])
          }
        })
        return arr
      }
    },
    methods: {
      savePassword (): void {
        if (!this.user) return
        var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.adminupdate[0].replace('{email}', this.user.email)
        this.$http.post(uri, this.loginData).then(
          (s): any => {
            if (this.user && this.loginData) {
              this.user.email = this.loginData.email
            }
            //this.loginData = undefined
            // @ts-ignore Cannot find mixins
            this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
            this.$emit('input', this.loginData)
          }, (f): any => {
            // @ts-ignore Cannot find mixins
            this.showAlertError(this.$t('common.saveFailed'))
            if (f.body?.error?.errors) { this.currentPasswordFailure = f.body.error.errors }
          })
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
.buttons {
      justify-content: center;
      button {
        padding: 5px 15px;
      }

      .delete-user-button {
        text-align: right;
      }
      @media (max-width: $media-xs-max) {
        .delete-user-button {
          text-align: left;
          margin-top: 30px;
        }
      }
    }
</style>
