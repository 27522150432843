<template>
  <div class="col-12 my-user-profile-usage">
    <div v-if="reportData.special.has.hasDeleteMyProfileRequest" class="row has-already-requested-delete-warning">
      <div class="col-12">
        {{ $t('deleteMyProfile.hasAlreadyRequestedDelete') }}
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-12 general-instructions">
          <div v-html="$t('deleteMyProfile.general')" />
        </div>
      </div>
      <div v-if="!isTypeA" class="row issue-options">
        <div class="col-12">
          <h5>{{ $t('deleteMyProfile.profileIs') }}</h5>
        </div>
        <div class="col-12">
          <div v-if="showOption1" class="option">
            <div class="option-value">
              <tickcrosswarning :value="option1Value" />
            </div>
            <div class="option-label">
              {{ $t('deleteMyProfile.option1') }}
            </div>
          </div>
          <div v-if="showOption2" class="option">
            <div class="option-value">
              <tickcrosswarning :value="option2Value" />
            </div>
            <div class="option-label">
              {{ $t('deleteMyProfile.option2') }}
            </div>
          </div>
          <div v-if="showOption3" class="option">
            <div class="option-value">
              <tickcrosswarning :value="option3Value" />
            </div>
            <div class="option-label">
              {{ $t('deleteMyProfile.option3') }}
            </div>
          </div>
          <div v-if="showOption4" class="option">
            <div class="option-value">
              <tickcrosswarning :value="option4Value" />
            </div>
            <div class="option-label">
              {{ $t('deleteMyProfile.option4') }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isTypeA" class="row">
        <div class="col-12 level-instructions-title">
          <h5>{{ $t('deleteMyProfile.levelInstructionsTitle') }}</h5>
        </div>
        <div class="col-12 level-instructions">
          <div v-html="levelInstructions" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 consent">
          <checkboxinput
            v-model="hasConsent"
            :title="$t('deleteMyProfile.consent')"
            :aria-label="$t('deleteMyProfile.consent')"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <sprbutton
            :disabled="!hasConsent || isSending"
            :title="deleteButtonTitle"
            :aria-label="deleteButtonTitle"
            :primary="true && hasConsent && !isSending"
            click-event="deleteProfile"
            @deleteProfile="onDeleteProfile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import sprbutton from '../SPRButton.vue'
  import tickcrosswarning from '../SPRTickCrossWarning.vue'
  import { YesNoWarning } from '@/types/custom'
  import { MyProfileLevel } from '@/types'
  import { TranslateResult } from 'vue-i18n'

  // the data object
  export interface TemplateComponentData {
    hasConsent: boolean,
    isSending: boolean
  }

  export default Vue.extend({
    name: 'MyUserProfileUsageReport',
    components: { checkboxinput, sprbutton, tickcrosswarning },
    props: {
      reportData: {
        type: Object,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        hasConsent: false,
        isSending: false
      }
    },
    computed: {
      deletionType (): MyProfileLevel {
        return this.reportData?.special?.level || MyProfileLevel.UserLevelA
      },
      isTypeA (): boolean {
        // See #4084 for types. These ones can be deleted straight away (1)
        return this.deletionType === MyProfileLevel.UserLevelA
      },
      isTypeB (): boolean {
        // These ones can be deleted, but it is recommended they remove their events, etc first (10)
        return this.deletionType === MyProfileLevel.UserLevelB
      },
      isTypeC (): boolean {
        // These ones require an admin to perform the deletion (100)
        return this.deletionType === MyProfileLevel.UserLevelC
      },
      levelInstructions (): string {
        if (this.isTypeB) {
          return this.$t('deleteMyProfile.userLevelB').toString().replace(/\n/g, '<br/>')
        }
        if (this.isTypeC) {
          return this.$t('deleteMyProfile.userLevelC').toString().replace(/\n/g, '<br/>')
        }
        return ''
      },
      deleteButtonTitle (): string | TranslateResult {
        return this.isTypeC
          ? this.$t('deleteMyProfile.userLevelC-Action')
          : this.$t('deleteMyProfile.action')
      },
      option1Value (): YesNoWarning {
        // whether the user is in a critical role. Yes is ok, can delete.
        return this.reportData?.special?.has?.hasRole
          ? YesNoWarning.Warning
          : YesNoWarning.Yes
      },
      option2Value (): YesNoWarning {
        // whether the user is the creator of a future event. Yes is ok, can delete.
        return this.reportData?.special?.has?.hasFutureEventAsCreator
          ? YesNoWarning.Warning
          : YesNoWarning.Yes
      },
      option3Value (): YesNoWarning {
        // whether the user is an action group leader. Yes is ok, can delete.
        return this.reportData?.special?.has?.hasActionGroupAdmin
          ? YesNoWarning.Warning
          : YesNoWarning.Yes
      },
      option4Value (): YesNoWarning {
        // whether the user is in an active friendshipo. Yes is ok, can delete.
        return this.reportData?.special?.has?.hasActiveFriendship
          ? YesNoWarning.Warning
          : YesNoWarning.Yes
      },
      showOption1 (): boolean {
        return this.option1Value === YesNoWarning.Warning
      },
      showOption2 (): boolean {
        return this.option2Value === YesNoWarning.Warning
      },
      showOption3 (): boolean {
        return this.option3Value === YesNoWarning.Warning
      },
      showOption4 (): boolean {
        return this.option4Value === YesNoWarning.Warning
      }
    },
    methods: {
      onDeleteProfile (): void {
        this.isSending = true
        this.$emit('deleteprofile', this.isTypeC)
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
  .my-user-profile-usage {
    .has-already-requested-delete-warning {
      position: relative;
      border: solid 2px $brand-warning;
      background-color: rgba($brand-warning, 0.5);
      padding: 15px;
      margin-bottom: 15px;
      text-align: center;
    }
    .level-instructions-title {
      margin-top: 30px;
    }
    .level-instructions {
      margin-top: 30px;
      ul li {
        margin-bottom: 10px;
      }
    }
    .consent {
      margin-top: 30px;
    }
    .issue-options {
      margin-top: 30px;
      h5 {
        margin-bottom: 20px;
      }
      .option {
        margin-bottom: 8px;
        .option-value {
          display: inline-block;
          margin-right: 10px;
        }
        .option-label {
          display: inline-block;
        }
      }
    }
  }
</style>
