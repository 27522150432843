<template>
  <div>
    <div class="col-12 mt-5 mb-3">
      <h4>{{ $t('customerSearch.listOfPrivilegedUsers') }}</h4>
    </div>
    <div v-if="userRoles">
      <userwithrole v-for="(u) in userRoles"
                    :key="`row_${_uid}_${u.userId}`"
                    :profile="u.profile"
                    :user-id="u.userId"
                    :roles="u.roles"
      />
    </div>
    <div v-else class="row brand-red text-center">
      <div class="col-12">
        <loading />
      </div>
    </div>

    <div class="col-12 mt-5 mb-3">
      <h4>{{ $t('customerSearch.listOfAllUsersForRoles') }}</h4>
    </div>
  </div>
</template>

<script>
  import userwithrole from './UserRolesCurrent'
  import loading from '../SPRLoading.vue'

  export default {
    name: 'CurrentSectionRoles',
    components: { userwithrole, loading },
    props: {
      id: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        userRoles: null
      }
    },
    computed: {
      url () {
        return `${this.$store.state.apiBases.userservice}/api/user/roles/sectionroles/${this.id}`
      }
    },
    watch: {
      sectionId () {
        this.getRoles()
      }
    },
    mounted () {
      this.getRoles()
      this.$bus.$on('section-roles-changed', this.handleChange)
    },
    beforeDestroy () {
      this.$bus.$off('section-roles-changed', this.handleChange)
    },
    methods: {
      getRoles: function () {
        const self = this
        this.userRoles = null
        this.$http.get(this.url).then(s => {
          self.userRoles = s.body
          self.$store.commit('setSectionRoles', s.body)
        }).catch(e => {
          self.userRoles = []
          console.error(e)
        })
      },
      handleChange: function (change) {
        if (!this.userRoles) {
          return
        }

        var found = _.findIndex(this.userRoles, function (x) {
          return x.userId === change.userId
        })

        if (found >= 0) {
          if (change.roles.length === 0) {
            this.$delete(this.userRoles, found)
          } else {
            this.userRoles[found].roles = change.roles
          }
        } else {
          // must reload
          this.getRoles()
        }
      }
    }
  }
</script>
