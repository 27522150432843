<template>
  <div class="radio-input">
    <label :for="title" class="radio-label">
      <input :id="title" class="input-radio" type="radio" name="radio" :value="option" tabindex="0" @change="$parent.$emit('input', option)" />
      {{ title }}
    </label>
  </div>
</template>

<script>
  export default {
    name: 'BaseRadioButton',
    props: {
      option: {
        type: Number,
        default: -1
      },
      title: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style>
 .radio-input {
    float: left;
    width: 350px;
    margin: 0px !important;
}

.radio-label{
  float: left;
  text-align: left;
}
</style>
