<template>
  <div class="spr-radio-list">
    <spr-radio
      v-for="(item, index) in options"
      :key="index"
      :title="item.text"
      :value="item.value"
      :model-value="value"
      :name="name"
      @input="onRadioClicked"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import SprRadio from './SPRSimpleRadio.vue'
  import { SprRadioPillOption } from '../types/custom'

  export default Vue.extend({
    name: 'SprRadioList',
    components: {
      SprRadio
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: [String, Number, Boolean],
        required: true
      },
      options: {
        type: Array as () => Array<SprRadioPillOption>,
        default: []
      },
      name: {
        type: String,
        default: 'defaultradio'
      }
    },
    data () {
      return {
      }
    },
    computed: {
    },
    methods: {
      onRadioClicked (value: string | number | boolean) {
        this.$emit('input', value)
      }
    }
  })
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .spr-radio-list {
    .spr-simple-radio {
      display: inline-block;
      input[type=radio] {
        color: blue;
        background-color: green;
      }
      input[type=radio] + label {
        padding: 8px 15px;
      }
      input[type=radio] + label:before,
      input[type=radio]:checked + label:before {
        content: none;
      }
      input[type=radio] + label:after,
      input[type=radio]:checked + label:after {
        content: none;
      }
      label {
        border-radius: 30px;
        padding: 8px 15px;
        margin: 3px;
        font-size: 13px;
        font-weight: bold;
        display: inline-block;
        border: solid 1px $brand-white;
        background-color: $brand-white;
      }
      input[type=radio]:checked + label {
        color: $brand-white;
        background-color: $brand-red;
        border-color: $brand-red;
      }
      input[type=radio] + label:hover {
        background: #ba0000;
        border: 1px solid #ba0000 !important;
        color: #FFFFFF !important;
        border-radius: 1.5em;
      }
    }
  }
</style>
