<template>
  <div class="comp-radio-group-key">
    <sprradio v-for="(element, index) in options"
              :id="`radiogp_${_uid}_${index}`"
              :key="element.value"
              :value="element.value"
              :title="element.text"
              :model-value="value"
              :is-valid="true"
              :name="groupName"
              @input="handleSelection"
    />
  </div>
</template>

<script>
  import sprradio from './SPRSimpleRadio.vue'

  export default {
    name: 'SprRadioGroupKey',
    components: { sprradio },
    props: {
      value: {
        type: Number,
        required: true
      },
      options: {
        type: Array,
        default () {
          return []
        }
      },
      translationKey: {
        type: String,
        default: ''
      },
      radioGroupColor: {
        type: String,
        default: ''
      },
      changeEvent: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        initialized: false
      }
    },
    computed: {
      groupName () {
        return `group_${this._uid}`
      }
    },
    methods: {
      handleSelection: function (value) {
        this.$emit('input', value)
      }
    }
  }
</script>
