import Vue from 'vue'
import VueResource from 'vue-resource'
import store from '../store/index.js'

Vue.use(VueResource)

const validatorEmailExists = {
  getMessage (field, args) {
    return 'Email already exists in the database'
  },
  validate (value, args) {
    return new Promise((resolve, reject) => {
      if (value === store.state.profile.email) resolve({ valid: true }) // not changed, do not validate
      var url = `${store.state.apiBases.userservice}${store.state.apiEndpoints.emailexists[0]}?emailaddress=${value}`
      Vue.http.get(url).then((resp) => {
        if (resp.ok) {
          resolve({
            valid: !resp.body // should NOT exist
          })
        } else {
          reject(new Error({
            valid: false,
            data: {
              message: 'Unable to get response from the server'
            }
          }))
        }
      })
    })
  }
}

export default validatorEmailExists
