<template>
  <div v-if="localCustomer" class="summary">
    <div class="row">
      <div class="col-12">
        <!-- summary section-->
        <div class="row headertitle">
          <div class="col-12">
            <h3>{{ $t('customerForm.summaryTitle') }}</h3>
            <div class="instructions">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="$t('customerForm.summaryInstructions')" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <span class="cap-label">{{ $t('customerForm.netPersonalInformation') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="inputcontent">
              <dl>
                <dt>
                  {{ localCustomer.firstName }} {{ localCustomer.lastName }}
                </dt>
                <dd>
                  <div>{{ localCustomer.streetAddress }}</div>
                  <div>{{ localCustomer.zipCode }} {{ localCustomer.city }}</div>
                </dd>
                <dd>
                  <div>{{ localCustomer.homePhone }}</div>
                  <div>{{ localCustomer.email }}</div>
                </dd>
              </dl>
            </div>
            <div v-if="localCustomer.contactFirstName || localCustomer.contactLastName" class="inputcontent">
              <dl>
                <dt>
                  {{ localCustomer.contactFirstName }} {{ localCustomer.contactLastName }}
                </dt>
                <dd>
                  <div>{{ localCustomer.contactPhoneNumber }}</div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="inputcontent">
              <dl v-if="birthdateString">
                <dt>{{ $t('customerForm.birthDate') }}</dt>
                <dd>{{ birthdateString }}</dd>
              </dl>
              <dl>
                <dt>{{ $t('customerForm.gender') }}</dt>
                <dd>{{ genderString }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span class="cap-label">{{ $t('customerForm.netLanguageInformation') }}</span>
            <div class="inputcontent">
              <dl>
                <dt>{{ $t('customerForm.nativeLanguage') }}</dt>
                <dd>{{ languageString }}</dd>
              </dl>
              <dl>
                <dt>{{ $t('customerForm.otherLanguages') }}</dt>
                <dd v-for="lang in customerOtherLanguages" :key="lang">
                  {{ lang }}
                </dd>
                <dd>{{ localCustomer.otherLanguages }}</dd>
              </dl>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <span class="cap-label">{{ $t('customerForm.myInterests') }}</span>
            <div class="inputcontent">
              {{ interestsString }}
            </div>
            <div v-if="localCustomer.interestOther" class="inputcontent">
              {{ localCustomer.interestOther }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <span class="cap-label">{{ $t('customerForm.friendWish') }}</span>
            <div class="inputcontent">
              <dl>
                <dt>{{ $t('customerForm.genderPreferences') }}</dt>
                <dd>{{ preferredGenders }}</dd>
                <dt>{{ $t('customerForm.desiredAge') }}</dt>
                <dd>{{ preferredAges }}</dd>
              </dl>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <span class="cap-label">{{ $t('customerForm.netFriendTitle') }}</span>
            <div class="inputcontent">
              <dl v-if="localCustomer.econtactEmail">
                <dt>{{ $t('connectivity.email') }}</dt>
                <dd>{{ localCustomer.econtactEmail }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactTelegram">
                <dt>{{ $t('connectivity.telegram') }}</dt>
                <dd>{{ localCustomer.econtactTelegram }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactKik">
                <dt>{{ $t('connectivity.kik') }}</dt>
                <dd>{{ localCustomer.econtactKik }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactInstagram">
                <dt>{{ $t('connectivity.instagram') }}</dt>
                <dd>{{ localCustomer.econtactInstagram }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactSkype">
                <dt>{{ $t('connectivity.skype') }}</dt>
                <dd>{{ localCustomer.econtactSkype }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactDiscord">
                <dt>{{ $t('connectivity.discord') }}</dt>
                <dd>{{ localCustomer.econtactDiscord }}</dd>
              </dl>
              <dl v-if="localCustomer.econtactFreetext">
                <dt>{{ $t('connectivity.freetext') }}</dt>
                <dd>{{ localCustomer.econtactFreetext }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8 inputcontent">
            <dl>
              <dt>{{ $t('customerForm.netDescriptionTitle') }}</dt>
              <dd>{{ netDescription }}</dd>
            </dl>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-8">
            <span class="cap-label">{{ $t('customerForm.netAnythingElse') }}</span>
            <sprtextarea
              v-model="localCustomer.customerTypeOtherInfo"
              v-validate="{ max: 300 }"
              name="customerForm.shortDescription"
              :is-valid="localCustomer.customerTypeOtherInfo.length <= 300"
              :max-characters="300"
              :show-counter="true"
            />
          </div>
          <div class="col-12">
            <checkboxinput v-model="privacyStatement"
                           v-validate="'required'"
                           :title="$t('customerForm.privacyStatement')"
                           :aria-label="$t('customerForm.privacyStatement')"
                           name="customerForm.privacyStatement"
                           :allow-html="true"
                           :white-warnings="false"
                           :has-error="privacyStatement === false"
                           :is-valid="privacyStatement === true"
                           :required="true"
            />
          </div>
          <div class="col-12">
            <div class="buttons">
              <prevnextbuttons
                is-save
                @prev="onPrevClicked"
                @next="onNextClicked"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprtextarea from '../SPRSimpleTextArea.vue'
  import prevnextbuttons from '../SPRPrevNextButtons.vue'
  import enums from '@/mixins/enums.js'
  import { FriendCustomerNetDto, LanguageDto } from '@/types/index'
  import { TranslateResult } from 'vue-i18n'
  import checkboxinput from '../SPRSimpleCheckbox.vue'

  // the data object
  export interface TemplateComponentData {
    privacyStatement: boolean,
    privacyStatementInError: boolean,
    localCustomer: FriendCustomerNetDto | undefined
  }

  export default Vue.extend({
    name: 'Summary',
    components: { sprtextarea, prevnextbuttons, checkboxinput },
    mixins: [enums],
    props: {
      customer: {
        type: Object as () => FriendCustomerNetDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        privacyStatement: false,
        privacyStatementInError: false,
        localCustomer: undefined
      }
    },
    computed: {
      birthdateString (): string {
        if (!this.localCustomer) return ''
        var d = new Date(this.localCustomer.birthdate)
        return d ? d.toLocaleDateString('fi-FI') : ''
      },
      genderString (): string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getGenderTranslation(this.localCustomer.gender)
      },
      netDescription (): string {
        if (this.localCustomer && this.localCustomer.description) { return this.localCustomer.description }

        return '-'
      },
      languageString (): string | TranslateResult {
        // @ts-ignore - ts intellisense not finding mixins
        var isoCode = this.getLanguage(this.localCustomer?.nativeLanguage)?.isoCode
        var translateString = `languages.${isoCode}`
        return this.$t(translateString)
      },
      interestsString (): string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getInterestsMaskTranslation(this.localCustomer?.interests)
      },
      preferredGenders (): string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getGenderPreferencesMaskTranslation(this.localCustomer?.preferredGenders)
      },
      preferredAges (): string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getAgePreferencesMaskTranslation(this.localCustomer?.preferredAges)
      },
      customerOtherLanguages (): Array<string> | undefined {
        return this.localCustomer?.customerLanguages
          .filter((x: LanguageDto) => x.isoCode !== 'other')
          .map((x: LanguageDto) => {
          return this.$t('languages.' + x.isoCode).toString()
        })
      }
    },
    mounted (): void {
      this.localCustomer = this.customer
    },
    methods: {
      onConsentChanged (val: boolean): void {
        console.log('Consent: ', val)
      },
      onPrevClicked (): void {
        this.$emit('prev', this.localCustomer)
      },
      onNextClicked (): void {
        if (this.privacyStatement) {
          this.$validator.validateAll().then(result => {
            if (result) {
              this.$emit('next', this.localCustomer)
            }
          })
        } else {
          this.privacyStatementInError = true
          this.$validator.validateAll()
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .summary {
    background-color: $brand-white;
    padding: 15px;
    padding-top: 30px;

    .headertitle {
      margin-bottom: 30px;
    }

    .cap-label {
      color: $brand-red;
    }

    .name {
      font-weight: bold;
    }

    .inputcontent {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .buttons {
      padding-top: 30px;
    }
  }

</style>