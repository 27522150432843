<template>
  <div class="spr-simple-numberinput pb-3" :class="{ 'compact': compact }">
    <label v-if="title" :for="_uid" class="cap-label">{{ title }}</label>
    <input
      :id="_uid"
      ref="inputRef"
      type="number"
      :name="name"
      :class="{ 'spr-danger': !isValid, 'form-control spr-roundbox-1': true }"
      :value="computedValue"
      :step="step"
      :min="min"
      :max="max"
      @input="$emit('input', sanitize($event.target.value))"
    >
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  /* eslint-disable */
  // Do not check ts at this time as this file has not been converted to ts.
  export default Vue.extend({
    name: 'Sprsimplenumberinput',
    props: {
      name: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      isValid: Boolean,
      compact: Boolean,
      value: {
        type: Number,
        default: 0
      },
      step: {
        type: Number,
        default: 1
      },
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: Number.MAX_SAFE_INTEGER
      }
    },
    $_veeValidate: {
      value () {
        return (this.$refs.inputRef as HTMLTextAreaElement).value
      }
    },
    computed: {
      computedValue () : number {
        return this.sanitize(this.value)
      }
    },
    methods: {
      sanitize(val: number): number {
        if (isNaN(val) || val < this.min) {
          return this.min;
        }
        if (this.max && val > this.max) {
          return this.max;
        }
        return val;
      }
    }
  })
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .spr-simple-numberinput {
    label {
      color:$brand-red;
      padding: 0 20px;
      min-height: 19px;
    }

    &.compact {
      padding-bottom: 0.5rem;

      label {
        font-size: 0.8em;
        padding: 0 10px;
        margin-bottom: 0;
      }
      input {
        font-size: 0.8em;
        padding: 5px 10px;
      }
    }

    input {
      display: block;
      box-sizing: border-box;
      font-family: "Signa-Light", Sans-Serif;
      border: 2px solid $brand-grey3;
      padding: 10px 20px;

      &.spr-danger {
        border: 2px solid $brand-red !important;
      }
    }
  }

</style>
