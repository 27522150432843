<template>
  <div v-if="visible" class="container">
    <div class="triangle">
      <span class="text">{{ text }}</span>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  export default Vue.extend({
    name: 'EarMark',
    props: {
      visible: {
        type: Boolean,
        required: true,
        default: false
      },
      text: {
        type: String,
        required: true,
        default: ''
      }
    }
  })
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";

.container {
  position: absolute;
  width: unset;
  top: 18px;
  left: 33px;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.triangle {
  position: relative;
  width: 0px;
  height: 0px;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 50px solid #000000;
  opacity: 0.8;
  pointer-events: none;
}
.text {
  position: absolute;
  transform: translate(-50%, -50%) translateY(35px);
  color: white;
  font-weight: 600;
  position: absolute;
  z-index: 1;
}
</style>
