<template>
  <div class="admin-section-level">
    <!--regular admin view-->
    <h3>{{ section.name }}</h3>
    <div v-if="loaded === 1" class="row section-editor mb-2">
      <div class="col-12">
        <descriptioneditor v-model="section.description"
                           class="section-desc-editor my-2"
                           :title="$t('userAdmin.sectionDescription')"
                           save-event="on-save"
                           :is-valid="!errors.has('description')"
                           name="description"
                           @on-save="updateDescription"
        />
      </div>
      <div class="col-sm-6 col-12">
        <dl>
          <dt>{{ $t('userAdmin.districtName') }}</dt>
          <dd>{{ section.districtName }}</dd>
          <dt>{{ $t('userAdmin.sectionLanguage') }}</dt>
          <dd>{{ sectionLanguage }}</dd>
        </dl>
      </div>
      <div class="col-sm-6 col-12">
        <dl>
          <dt>{{ $t('userAdmin.sectionZipCodes') }}</dt>
          <dd>{{ sectionZipCodes }}</dd>
        </dl>
      </div>
      <div class="col-12">
        <div class="section-block reportcontact">
          <div class="section-block-container">
            <sectioncontacts v-if="loaded === 1" class="section-report-definition-editor my-2"
                             :section-id="section.id"
                             :title="$t('userAdmin.sectionReportTitle')"
                             :contacts="section.contacts"
                             :report-frequency="reportFrequency"
                             @commitChanges="updateContacts"
            />
          </div>
        </div>
      </div>
    </div>
    <h5>{{ $t('userAdmin.newVolunteers') }}</h5>
    <div v-if="loaded === 1" class="row mb-2 new-volunteers">
      <userlisting :users="section.volunteers" />
    </div>

    <!--still loading-->
    <div v-if="loaded === 0" class="row mb-2">
      <div class="col-12 brand-red text-center">
        <loading />
      </div>
    </div>

    <!--error has occurred-->
    <div v-if="loaded === -1" class="row mb-2">
      <div class="col-12 brand-red text-center">
        <span class="cap-label d-block mb-2">{{ $t('userAdmin.sectionLoadFailed') }}</span>
        <a class="d-block retry-area"
           href="javascript:void(0)"
           tabindex="0"
           @click="triggerFetch"
        >
          <font-awesome-icon icon="redo" size="2x" fixed-width aria-hidden="true" /><br>
          <span class="cap-label brand-red">{{ $t('userAdmin.retryLoad') }}</span>
        </a>
      </div>
    </div>

    <adminnavigation :options="options" />
  </div>
</template>

<script>
  import adminnavigation from './AdminHorizontalNavigation.vue'
  import userlisting from './UserListing.vue'
  import sectioncontacts from './SectionContacts.vue'
  import descriptioneditor from '../SPRProfileEditor.vue'
  import loading from '../SPRLoading.vue'

  export default {
    name: 'SectionAdmin',
    components: {
      adminnavigation,
      sectioncontacts,
      descriptioneditor,
      userlisting,
      loading
    },
    props: {
      primary: {
        type: String,
        default: ''
      },
      id: {
        type: String,
        default: ''
      },
      filter: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        section: {
          id: 0,
          description: '',
          name: '',
          contacts: [],
          reportFrequency: 0
        },
        loaded: 0,
        loadedReport: 0
      }
    },
    computed: {
      options: function () {
        const optionsConstant = [
          { uri: '/admin/section/' + this.id + '/user', text: this.$t('userAdmin.users'), primary: 'user' },
          { uri: '/admin/section/' + this.id + '/group', text: this.$t('userAdmin.groups'), primary: 'group' },
          { uri: '/admin/section/' + this.id + '/messages', text: this.$t('userAdmin.messaging'), primary: 'message' }]
        const rv = optionsConstant.concat()
        if (this.hasRole('Admin') || this.hasRole('FriendshipAdmin') || this.hasRole('DistrictAdmin') || this.hasRole('NationalAdmin') || this.hasRole('DistrictFriendshipAdmin') || this.hasRole('FriendshipTrainer')) {
          rv.push({ uri: '/admin/training/1', text: this.$t('userAdmin.trainings'), primary: 'training' }) // Kouluttamattomat (Untrained)
        }

        rv.push(
          { uri: '/admin/section/' + this.id + '/roles', text: this.$t('userAdmin.roles'), primary: 'roles' }
        )

        return rv
      },
      reportFrequency () {
        if (this.section.reportFrequency) {
          return this.section.reportFrequency
        }
        return 7 // Weekly
      },
      sectionLanguage: function () {
        if (this.section.language === 0) {
          return this.$t('languages.fi')
        } else if (this.section.language === 1) {
          return this.$t('languages.sv')
        } else if (this.section.language === 2) {
          return this.$t('userAdmin.bilingual')
        }

        return ''
      },
      sectionZipCodes: function () {
        return (this.section.zipCodes && this.section.zipCodes.length) ? this.section.zipCodes.join(', ') : ''
      }
    },
    mounted: function () {
      this.triggerFetch()
    },
    methods: {
      updateDescription: function () {
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.setDistrictDescription[0].replace('{0}', this.id)
        var postBody = {
          payload: this.section.description
        }

        this.$http.post(url, postBody)
      },
      getContacts: function () {
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.getSectionReport[0].replace('{0}', this.id)

        this.fetchSectionReport(url, 3)
      },
      updateContacts: function (sectionReportDto) {
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.setSectionReport[0].replace('{0}', this.id)
        var postBody = sectionReportDto

        this.$http.post(url, postBody).then(s => {
          // @ts-ignore cannot find mixins
          this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
          this.getContacts()
        }, (fail) => {
          // @ts-ignore cannot find mixins
          this.showAlertError(this.$t('common.fetchFailed'))
        })
      },
      fetchSection: function (uri, retry) {
        this.$http.get(uri).then(s => {
          this.loaded = 1
          this.loadedReport = 1
          this.section = s.body
        }, f => {
          if (retry > 0) {
            this.fetchSection(uri, retry - 1)
          } else {
            this.loaded = -1
            this.loadedReport = -1
          }
        })
      },
      fetchSectionReport: function (uri, retry) {
        this.$http.get(uri).then(s => {
          this.loadedReport = 1
          this.section.reportFrequency = s.body.reportFrequency
          this.section.contacts = s.body.contacts
        }, f => {
          if (retry > 0) {
            this.fetchSectionReport(uri, retry - 1)
          } else {
            this.loadedReport = -1
          }
        })
      },
      triggerFetch: function () {
        this.loaded = 0
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.sectionbyid[0].replace('{0}', this.id)
        this.fetchSection(url, 3)
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .admin-section-level {
    .section-editor {
      background: $brand-white;
    }

    .retry-area {
      cursor: pointer;
    }
  }
</style>
