<template>
  <div class="fill-width comp-wizard-content">
    <div>
      <span class="h1"
            role="heading"
            aria-level="1"
      >{{ $t('wizard.headerSix') }}</span><br>
      <p>{{ $t('wizard.ingressTime') }}</p>
    </div><br>
    <sprcheckbox v-for="(choice, index) in availabilityTimes"
                 :key="'wizard_6_' + index"
                 :value="choice.id"
                 :title="$t('volunteerTimes.' + toLowerCamelCase(choice.name))"
                 click-event="box-selected"
                 :collection="$store.state.wizardModel.availabilityTimes"
                 @box-selected="handleCheckbox"
    /><br>
  </div>
</template>

<script>
  import sprcheckbox from '../SPRCheckbox.vue'

  export default {
    name: 'WizardSix',
    components: { sprcheckbox },
    data () {
      return {
        selected: new Set()
      }
    },
    computed: {
      availabilityTimes: function () {
        return this.$store.state.databaseAvailabilityTimes
      }
    },
    methods: {
      handleCheckbox: function (value, isChecked) {
        if (isChecked) {
          this.selected.add(value)
        } else {
          this.selected.delete(value)
        }
        var arr = [...this.selected]

        this.$store.commit('setWizardBestTimes', arr)
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';
</style>
