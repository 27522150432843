<template>
  <div class="container customersearch">
    <div v-if="isAuthorized" class="row">
      <div class="col-md-8 col-lg-9 order-md-2 search-list">
        <searchheader
          v-if="isInitialized"
          :id="id"
          :is-net-fmg="isNetFmg"
          :search="searchText"
          @search="onSearch"
          @sort="onSort"
        />
        <div v-show="isCustomerTab || isVolunteerTab" class="searchresults">
          <resultlist
            v-if="group"
            v-show="hasSearched"
            :group="group"
            :take="searchFilter.pagesize"
            :skip="searchFilter.skip"
            :total="total"
            :search-type="searchFilter.searchType"
            :customerlist="customerList"
            :volunteerlist="volunteerList"
            @pagechanged="onPageChanged"
            @next="onNext"
            @previous="onPrevious"
            @selected="onItemSelected"
          />
          <div v-show="!isLoading && noResults" class="noresults infocontainer">
            <div class="info warning">
              <font-awesome-icon icon="exclamation-triangle" />{{ $t('customerSearch.noResults') }}
            </div>
          </div>
          <div v-show="isLoading" class="loading">
            <div class="spinner">
              <loading />
            </div>
          </div>
        </div>
        <div v-show="isPoolsTab" class="pools">
          <div v-if="group">
            <PoolsListNet
              v-if="group.netFriendship"
              :filter="poolFilter"
              :search="poolSearchText"
            />
            <PoolsListStandard
              v-else
              :filter="poolFilter"
              :search="poolSearchText"
            />
          </div>
        </div>
        <div v-show="isDiscussionTab" class="discussion">
          <discussion />
        </div>
      </div>
      <div class="col-md-4 col-lg-3 order-md-1 mt-3 mt-md-0 search-filter" :class="{'disabled' : isLoading}">
        <customerfilter
          v-if="(isCustomerTab || isVolunteerTab) && searchFilter && group"
          :group="group"
          :filter-type="searchFilter.searchType"
          @filterchanged="onCustomerFilterChanged"
        />
        <poolfilter
          v-if="isPoolsTab"
          :filter="poolFilter"
          @filterchanged="onPoolFilterChanged"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import customerfilter from './CustomerFilter.vue'
  import poolfilter from './PoolFilter.vue'
  import searchheader from './SearchHeader.vue'
  import resultlist from './ResultList.vue'
  import PoolsListStandard from './PoolsListStandard.vue'
  import PoolsListNet from './PoolsListNet.vue'
  import discussion from './Discussion.vue'
  import loading from '../SPRLoading.vue'
  import { HttpResponse } from 'vue-resource/types/vue_resource'
  import bitmaskhelper from '../../helpers/bitmaskhelper'
  import { PreferredAges, CustomerTypes, NetCustomerTypes, CustomerState, FindNetFriendManagementGroupContentCriteria, FindStandardFriendManagementGroupContentCriteria, FindFriendManagementGroupCustomersResult, FindFriendManagementGroupVolunteersResult, FriendManagementDto, UserProfileFindDto, FriendCustomerFindDto } from '../../types/index'
  import { SearchFilter } from '../../types/custom'

  // the data object
  export interface TemplateComponentData {
    searchFilter: SearchFilter,
    customerList: Array<FriendCustomerFindDto>,
    volunteerList: Array<UserProfileFindDto>,
    total: number,
    hasSearched: boolean,
    isInitialized: boolean,
    isLoading: boolean,
    isAuthorized: boolean,
    searchTimeout: undefined | number,
    poolFilter: number[],
    poolSearchText: string,
    group: FriendManagementDto | undefined
  }

  export default Vue.extend({
    name: 'CustomerSearch',
    components: { poolfilter, customerfilter, searchheader, resultlist, PoolsListStandard, PoolsListNet, discussion, loading },
    props: {
      id: {
        type: String,
        default: ''
      }, // the group id
      searchmode: {
        type: String,
        default: 'customer'
      }
    },
    data (): TemplateComponentData {
      return {
        searchFilter: {} as SearchFilter,
        customerList: [],
        volunteerList: [],
        total: 0,
        hasSearched: false,
        isInitialized: false,
        isLoading: false,
        isAuthorized: false,
        searchTimeout: undefined,
        poolFilter: [1], // Active preselected
        poolSearchText: '',
        group: undefined
      }
    },
    computed: {
      isCustomerTab (): boolean {
        return this.searchmode.toLowerCase() === 'customer'
      },
      isVolunteerTab (): boolean {
        return this.searchmode.toLowerCase() === 'volunteer'
      },
      isPoolsTab (): boolean {
        return this.searchmode.toLowerCase() === 'pools'
      },
      isDiscussionTab (): boolean {
        return this.searchmode.toLowerCase() === 'discussion'
      },
      noResults (): boolean {
        if (this.searchFilter.searchType === 'CUSTOMER') {
          return this.customerList && this.customerList.length === 0 && this.hasSearched
        } else if (this.searchFilter.searchType === 'VOLUNTEER') {
          return this.volunteerList && this.volunteerList.length === 0 && this.hasSearched
        } else {
          return true
        }
      },
      searchText (): string {
        if (this.searchFilter.searchType === 'CUSTOMER') {
          return this.$store.state.searchFilter.searchText.customer
        } else if (this.searchFilter.searchType === 'VOLUNTEER') {
          return this.$store.state.searchFilter.searchText.volunteer
        } else {
          return ''
        }
      },
      isNetFmg (): boolean {
        return this.group ? this.group.netFriendship : false
      }
    },
    mounted (): void {
      // @ts-ignore
      if (!this.hasBrokerRole(this.id) && (!this.hasNetBrokerRole(this.id))) {
        this.isAuthorized = false
        // @ts-ignore
        this.showAlertPermissionDenied().then(() => this.$router.push('/profile'))
        return
      }
      this.isAuthorized = true

      this.loadFMG().then(x => {
        if (x.ok && x.data) {
          this.group = x.data

          this.searchFilter = this.$store.state.searchFilter
          switch (this.searchmode) {
          case 'customer':
            this.searchFilter.searchType = 'CUSTOMER'
            break
          case 'volunteer':
            this.searchFilter.searchType = 'VOLUNTEER'
            break
          case 'pools':
            this.searchFilter.searchType = 'POOLS'
          }

          if (!this.searchFilter.sortBy) this.searchFilter.sortBy = 'NEWEST'

          this.$store.commit('setSearchFilter', this.searchFilter)
          this.doSearch()
          this.$bus.$on('customerSelected', this.onItemSelected)
          this.$bus.$on('volunteerFriendshipSelected', this.onItemSelected)
          this.$bus.$on('volunteerSelected', this.onItemSelected)

          this.isInitialized = true
        }
      })
    },
    beforeDestroy (): void {
      this.$bus.$off('customerSelected')
      this.$bus.$off('volunteerFriendshipSelected')
      this.$bus.$off('volunteerSelected')
    },
    methods: {
      loadFMG (): PromiseLike<HttpResponse> {
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.id)
        return this.$http.get(url)
      },
      onCustomerFilterChanged (val): void {
        this.$store.commit('setSearchFilter', val)
        this.searchFilter.skip = 0
        this.doSearch()
      },
      onPoolFilterChanged (val): void {
        var existingIndex = this.poolFilter.indexOf(val)
        if (existingIndex >= 0) {
          this.poolFilter.splice(existingIndex, 1)
        } else {
          this.poolFilter.push(val)
        }
      },
      onPageChanged (val): void {
        this.searchFilter.skip = val.skip
        this.$store.commit('setSearchFilter', this.searchFilter)
        this.doSearch()
      },
      onPrevious (): void {
        this.searchFilter.skip -= this.searchFilter.pagesize
        if (this.searchFilter.skip < 0) this.searchFilter.skip = 0
        this.$store.commit('setSearchFilter', this.searchFilter)
        this.doSearch()
      },
      onNext (): void {
        if (this.searchFilter.skip + this.searchFilter.pagesize > this.total) return
        this.searchFilter.skip += this.searchFilter.pagesize
        this.$store.commit('setSearchFilter', this.searchFilter)
        this.doSearch()
      },
      onSearch (value: string): void {
        if (this.isPoolsTab) {
          this.poolSearchText = value
        } else {
          this.setSearchText(value)
          clearTimeout(this.searchTimeout)
          var self = this
          this.searchTimeout = window.setTimeout(function () {
            self.doSearch()
          }, 200)
        }
      },
      onSort (value): void {
        this.searchFilter.sortBy = value
        this.searchFilter.skip = 0 // reset to full results
        this.$store.commit('setSearchFilter', this.searchFilter)
        this.doSearch()
      },
      setSearchText (value: string): void {
        if (this.searchFilter.searchType === 'CUSTOMER' && (!value || this.searchFilter.searchText.customer !== value)) {
          this.searchFilter.skip = 0
          this.searchFilter.searchText.customer = value
        } else if (this.searchFilter.searchType === 'VOLUNTEER' && (!value || this.searchFilter.searchText.volunteer !== value)) {
          this.searchFilter.skip = 0
          this.searchFilter.searchText.volunteer = value
        }
        this.$store.commit('setSearchFilter', this.searchFilter)
      },
      onItemSelected (value): void {
        this.$router.push(`/friend/customerpreview/${this.id}/${value.id}`)
      },
      doSearch (): void {
        var self = this

        // get the last search from the store
        self.searchFilter = self.$store.state.searchFilter
        self.isLoading = true
        self.hasSearched = true
        var url = ''
        var request = {} as FindNetFriendManagementGroupContentCriteria | FindStandardFriendManagementGroupContentCriteria
        request.skip = self.searchFilter.skip
        request.take = self.searchFilter.pagesize
        request.orderByNew = self.searchFilter.sortBy === 'NEWEST'
        request.groupId = Number(this.id)
        request.languages = self.searchFilter.languages ? self.searchFilter.languages : []
        request.natives = self.searchFilter.motherTongs ? self.searchFilter.motherTongs : []
        request.ages = bitmaskhelper.arrayToBitmask(self.searchFilter.ages ? self.searchFilter.ages : []) as PreferredAges
        request.includeFriendPools = true
        request.includeFriendships = true

        if (self.searchFilter.customerTypes?.length) {
          request.customerTypes = bitmaskhelper.arrayToBitmask(self.searchFilter.customerTypes) as CustomerTypes | NetCustomerTypes
        }

        if (self.searchFilter.customerStates?.length) {
          request.customerStates = self.searchFilter.customerStates as Array<CustomerState>
        }

        if (self.searchFilter.friendshipTypes?.length) {
          request.friendshipTypes = bitmaskhelper.arrayToBitmask(self.searchFilter.friendshipTypes)
        }

        if (self.searchFilter.searchType === 'CUSTOMER') {
          request.searchString = self.searchFilter.searchText.customer
          url = this.isNetFmg
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customersearchnet[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customersearchstandard[0]
        } else {
          url = this.isNetFmg
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteersearchnet[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteersearchstandard[0]
          request.searchString = self.searchFilter.searchText.volunteer
        }

        self.customerList = []
        self.volunteerList = []

        this.$http.post(url, request).then(response => {
          if (response.ok) {
            var result: FindFriendManagementGroupCustomersResult | FindFriendManagementGroupVolunteersResult
            if (self.searchFilter.searchType === 'CUSTOMER') {
              result = response.data as FindFriendManagementGroupCustomersResult
              self.customerList = result.items
            } else {
              result = response.data as FindFriendManagementGroupVolunteersResult
              self.volunteerList = result.items
            }
            self.total = response.data.total
            self.isLoading = false
          }
        }, response => {
          console.log('Error loading search results')
          self.isLoading = false
        })
      },
      getManyToManyValues (list): string {
        var url = ''
        for (var i = 0; i < list.length; i++) {
          url += `${list[i]},`
        }
        url = url.substring(0, url.length - 1)

        return url
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .customersearch {
    .search-filter {
      background-color:$brand-white;
      padding-top:20px;

      &.disabled {
        pointer-events: none;
        /* for "disabled" effect */
        opacity: 0.5;
        background: #CCC;
        .customerfilter {
          background: #CCC;
        }
      }

      @media screen and (max-width: $media-sm-max) {
        &.disabled {
          display: none;
        }
      }
    }

    .infocontainer {
      height: 200px;
      width: 100%;
      background-color:$brand-white;
      display: flex;
      justify-content: center;
      align-items:center;
      align-content: center;
      padding:50px;
      .info {
        margin: auto;
        svg {
          margin-right: 5px;
          color:$brand-red;
          &.warning {
            color:$brand-warning;
          }
        }
      }
    }

    .search-list {
      margin-top:20px;
      .searchresults {
        position: relative;
        .loading {
          display: flex;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          height: 100%;
          width: 100%;
          .spinner {
            margin: 30px auto;
            color:$brand-red;
          }
        }
      }
    }
  }

</style>
