<template>
  <div class="container create-event">
    <div class="row back-nav">
      <div class="col">
        <a class="back" tabindex="0" href="javascript: return void(0);" @click="goBack">
          <font-awesome-icon icon="chevron-left" class="back" />Palaa
        </a>
      </div>
    </div>

    <div class="create-event-main">
      <div class="container">
        <div class="row title">
          <div class="col col-md-12">
            <h3>{{ $t('eventPage.selectTemplate') }}</h3>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row search">
          <div class="col-12 col-sm-9 col-md-7 col-lg-5">
            <searchbox v-model="searchText" :placeholder="placeholder" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="select-options">
              <span class="selected">{{ $t('eventPage.templates') }}</span>
              <span class="">{{ $t('eventPage.templateCopy') }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <eventtemplateselect :templates="templates" />
          </div>
        </div>
      </div>

      <div class="row button-row">
        <div class="col-md-12">
          <sprbutton type="primary"
                     :size="1"
                     :title="$t('eventPage.selectTemplate')"
                     class="spr-redborder button-width"
                     click-event="selectTemplate"
                     @selectTemplate="selectTemplateButton"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import searchbox from '../SPRSearchBox.vue'
  import sprbutton from '../SPRButton.vue'
  import eventtemplateselect from './EventTemplateSelect.vue'
  export default {
    name: 'Createevent',
    pageName: 'createevent',
    components: {
      searchbox, sprbutton, eventtemplateselect
    },
    data () {
      return {
        searchText: '',
        activeItemId: '',
        templates: [
          { title: 'Henkisen tuen peruskurssi', type: 'Kurssit ja koulutukset' },
          { title: 'Tyhjä toimintapohja', type: 'Yleinen' },
          { title: 'Ensiapukurssi', type: 'Kurssit ja koulutukset' },
          { title: 'Piirin järjestöseminaari', type: 'Kokoukset' }
        ]
      }
    },
    methods: {
      setActiveItemId (itemIndex) {
        this.activeItemId = itemIndex
      },
      selectTemplateButton: function () {
        this.$router.push('/event')
      }
    }
  }
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .create-event {
    background-color: $brand-white;
    padding-bottom: 40px;
    .back-nav {
      height: 50px;
      margin-bottom: 30px;
      border-bottom: solid 1px $brand-grey3;
    .back {
      font-weight: bold;
      color: $brand-red;
      cursor: pointer;
       svg {
        color: $brand-red;
        margin-right: 10px;
        }
     }
  }
  .search {
    margin-bottom: 40px;
  }
  .button-row {
    text-align: right;
    padding: 40px 0;
  }
  .select-options {
    font-family: Signa-bold;
    color: $brand-grey4;
    border-bottom: 1px solid $brand-grey1;
    text-transform: uppercase;
    span {
      padding: 10px;
      display: inline-block;
      cursor: pointer;
     }
    .selected {
      color: #000;
    }
  }
  .template-list {
    ul {
      list-style-type: none;
      padding-left: 0!important;
     }
    .template-view {
      padding: 10px 15px;
      cursor: pointer;
      .template-name {
        color: $brand-red;
        display: block;
        font-weight: bold;
      }
      &:hover {
        background: $brand-grey0;
      }
    }
  }
  h3 {
    color: $brand-red;
    margin-bottom: 30px;
  }
  .bottomspacer {
    margin-bottom: 50px;
  }
  .spr-searchbox {
    border: 1px solid $brand-grey2!important;
  }
}
</style>
