<template>
  <div class="container group">
    <div v-if="group">
      <div class="row mobiletitle d-block d-lg-none">
        <div class="col-12">
          <h3>{{ group.name }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-8 group-list order-2">
          <div class="row list-header">
            <div class="col-7">
              <h3 class="d-none d-md-block">
                {{ group.name }}
              </h3>
            </div>
            <div class="col-md-5 col-sm-12">
              <div class="d-flex justify-content-end">
                <sprbutton
                  v-if="isGroupAdmin && isActionGroup"
                  :size="4"
                  :title="$t('groups.addVolunteer')"
                  :bordersize="2"
                  class="next-button"
                  icon="plus"
                  click-event="click"
                  @click="onAddVolunteer"
                />
              </div>
            </div>
          </div>
          <volunteerslist :list="volunteers" :is-admin="isGroupAdmin" :is-loading="isLoading" :is-standard-group="group.groupType === 2" @remove="onRemoveVolunteer" />
        </div>
        <div class="col-sm-12 col-md-4 group-side order-1">
          <div class="group-secondary">
            <a class="back" tabindex="0" href="javascript: void(0);" @click="$router.back()">
              <font-awesome-icon icon="chevron-left" class="back" />{{ $t('volunteerForm.back') }}
            </a>
            <div>
              <span class="cap-label">
                {{ $t('groups.description') }}
              </span>
              <div>{{ group.description }}</div>
            </div>
            <span v-if="group.category" class="cap-label">
              {{ $t('groups.category') }}
            </span>
            <div>{{ category }}</div>
            <!-- Language -->
            <span v-if="language" class="cap-label">
              {{ $t('groups.language') }}
            </span>
            <div>{{ language }}</div>
            <!-- Group Organizer -->
            <div v-if="isActionGroup">
              <div class="grouporganizer">
                <groupOrganizer
                  v-validate="'hasOptionalGroupOrganizer'"
                  :text-only="true"
                  :hint="$t('userAdmin.groupOrganizer')"
                  name="userAdmin.groupOrganizer"
                  :value="group.groupOrganizer"
                  :required="false"
                  :is-valid="!errors.has('userAdmin.groupOrganizer')"
                  @input="onGroupOrganizerChanged"
                />
              </div>
            </div>
            <div v-if="hasExternalForm">
              <div>
                <label class="cap-label">{{ $t('externalForm.title') }}</label>
                <a :href="group.externalForm.link" target="_blank">{{ group.externalForm.name }}</a>
              </div>
            </div>
            <span class="cap-label">
              {{ $t('groups.messages') }}
            </span>
            <div v-if="group.id" class="allmessages">
              <a tabindex="0" href="javascript: void(0);" @click="toNotes">{{ $t('groups.allMessages') }}</a>
            </div>
            <div v-if="isMember">
              <span class="cap-label">
                {{ $t('groups.actions') }}
              </span>
              <div v-if="canEditGroupDetails">
                <a href="javascript:void(0)" @click="viewGroup">{{ $t('groups.edit') }}</a>
              </div>
              <div class="remove">
                <a v-if="canLeave" tabindex="0" href="javascript: void(0);" @click="removeFromGroup">{{ $t('groups.unsubscribe') }}</a>
                <span v-else class="remove-disabled" :title="$t('groups.actionGroupUnSubForbidden')">{{ $t('groups.unsubscribe') }}</span>
                <br>
                <a v-if="isGroupAdmin" tabindex="0" href="javascript: void(0);" @click="deactivateGroup">{{ $t('groups.deactivate') }}</a>
              </div>
            </div>
            <div v-if="isMemberPending">
              <span class="cap-label">
                {{ $t('groups.actions') }}
              </span>
              <a v-if="group.groupType == 1" tabindex="0" href="javascript: void(0);" @click="acceptInvitation">{{ $t('groups.joinGroup') }}</a>
            </div>
            <div v-if="isSaving" class="issaving">
              <Loading />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loading">
      <Loading />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import volunteerslist from './VolunteerList.vue'
  import enumhelper from '../../mixins/enums.js'
  import groupOrganizer from '../SPRGroupOrganizersDropdown.vue'
  import Loading from '../SPRLoading.vue'

  import { Validator } from 'vee-validate'
  import hasOptionalGroupOrganizer from '../../mixins/validator-hasOptionalGroupOrganizer.js'
  import { GroupDetailDto, GroupDetailLightDto, GroupPublicProfileDto, ExternalFormUpdateDto, GroupPublicProfileLightDto } from '@/types'
  import { HttpResponse } from 'vue-resource/types/vue_resource'

  Validator.extend('hasOptionalGroupOrganizer', hasOptionalGroupOrganizer)

  // the data object
  export interface TemplateComponentData {
    group: GroupDetailDto | GroupDetailLightDto | undefined,
    externalForm: ExternalFormUpdateDto | undefined,
    isLoading: boolean,
    isSaving: boolean,
    isGroupAdmin: boolean,
    groupId: number | undefined
  }

  export default Vue.extend({
    name: 'Group',
    components: { sprbutton, volunteerslist, groupOrganizer, Loading },
    mixins: [enumhelper],
    data (): TemplateComponentData {
      return {
        isLoading: false,
        isSaving: false,
        group: undefined,
        externalForm: undefined,
        isGroupAdmin: false,
        groupId: undefined
      }
    },
    computed: {
      isGroupMember (): boolean {
        var profile = this.$store.state.profile
        return _.includes(this.group?.members, profile.profileId)
      },
      canLeave (): boolean {
        if (!this.group) {
          return false
        }
        var result = this.group.groupType !== 1
        if (this.isMember) {
          if (!this.isGroupAdmin) {
            result = true
          }
        }
        return result
      },
      volunteers (): Array<GroupPublicProfileDto | GroupPublicProfileLightDto> {
        if (!this.group || !this.group.members) return []

        // @ts-ignore cannot find mixins
        if (this.isGroupAdmin || this.hasRole('Admin')) {
          return this.group.members as GroupPublicProfileDto[]
        } else {
          return this.group.members as GroupPublicProfileLightDto[]
        }
      },
      category (): string {
        // @ts-ignore cannot find mixins
        return this.getEventCategoriesTranslation(this.group.category)
      },
      language (): boolean {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getLanguagesMaskTranslation(this.group?.languages)
      },
      isMember (): boolean {
        if (!this.group) {
          return false
        }
        var self = this
        return _.find(this.group.members, function (m) {
          return m.id === self.$store.state.profile.profileId
        }) !== undefined
      },
      isMemberPending (): boolean {
        if (!this.group) {
          return false
        }
        var self = this
        return _.find(this.group.pendingMembers, function (m) {
          return m.id === self.$store.state.profile.profileId
        }) !== undefined
      },
      isActionGroup (): boolean {
        return this.group?.groupType === 1
      },
      hasExternalForm (): boolean {
        return this.group?.externalForm !== null
      },
      canEditGroupDetails (): boolean {
        // if user is either Admin, NationalAdmin, NationalEmployee, or Employee or J1 inside correct district or section, all good.
        // @ts-ignore cannot find mixins
        if (this.hasRole('Admin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee')) return true
        // @ts-ignore cannot find mixins
        if (this.hasRole('Employee') && (this.group && this.group.section && this.hasClaim(this.group.section.districtId.toString(), 'DistrictMembership'))) return true
        // @ts-ignore cannot find mixins
        if ((this.hasRole('J1') || this.hasRole('J2')) && this.group && this.group.section && this.hasClaim(this.group.section.id.toString(), 'SectionMembership')) return true

        // if its an action group, only the users above can edit
        if (this.group) {
          return this.isGroupAdmin
        }

        return false
      }
    },
    mounted (): void {
      this.groupId = parseInt(this.$route.params.id)
      var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupisadmin[0]}?groupId=${this.groupId}`
      this.$http.get(url).then((resp) => {
        this.isGroupAdmin = resp.data
        this.loadGroup()
      })
      this.$bus.$on('makeGroupAdmin', this.makeGroupAdmin)
      this.$bus.$on('removeGroupAdmin', this.removeGroupAdmin)
    },
    beforeDestroy (): void {
      this.$bus.$off('makeGroupAdmin', this.makeGroupAdmin)
      this.$bus.$off('removeGroupAdmin', this.removeGroupAdmin)
    },
    methods: {
      viewGroup () {
        if (this.canEditGroupDetails) this.$router.push(`/admin/group/${this.group?.id}`)
        return false
      },
      deactivateGroup (): void {
        if (!this.group) {
          return
        }
        if (confirm(this.$t('groups.deactivationConfirmation').toString())) {
          this.group.isActive = false
          this.updateGroup().then(s => {
            this.$router.push('/')
          })
        }
      },
      onGroupOrganizerChanged (groupOrganizer): void {
        if (!this.group) {
          return
        }
        this.group.groupOrganizer = groupOrganizer
      },
      makeGroupAdmin (volunteer): void {
        if (!this.group) {
          return
        }
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupmakeadmin[0]
        var groupSectionDto = {
          sectionId: this.group.section.id,
          groupId: this.group.id,
          profileId: volunteer.id
        }
        this.$http.put(url, groupSectionDto).then((resp) => {
          this.loadGroup()
        })
      },
      removeGroupAdmin (volunteer): void {
        if (!this.group) {
          return
        }
        var url = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupremoveadmin[0]
        var groupSectionDto = {
          sectionId: this.group.section.id,
          groupId: this.group.id,
          profileId: volunteer.id
        }
        this.$http.put(url, groupSectionDto).then((resp) => {
          this.loadGroup()
        })
      },
      onAddVolunteer (): void {
        this.$router.push(`/groups/group/${this.group?.id}/addvolunteer`)
      },
      onRemoveVolunteer (volunteer): void {
        const payload = { payload1: this.group?.id, payload2: [volunteer.id] }
        const uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupremmembers[0]
        this.$http.post(uri, payload).then(s => {
          this.loadGroup()
        })
      },
      removeFromGroup (): void {
        if (!this.group) {
          return
        }
        var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupleave[0]}`.replace('{0}', this.group.id.toString())
        this.$http.put(url, null)
          // @ts-ignore cannot find mixins
          .then(() => this.getShortToken())
          .then((resp) => {
            this.$router.push('/front')
          })
      },
      acceptInvitation (): void {
        if (!this.group) {
          return
        }
        var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupacceptinvitation[0]}`.replace('{0}', this.group.id.toString())
        this.$http.put(url, null).then((resp) => {
          this.loadGroup()
        })
      },
      onActivateGroup (): void {
        if (!this.group) {
          return
        }
        this.group.isActive = true
        this.isSaving = true
        this.updateGroup().then(x => {
          this.isSaving = false
        })
      },
      onDeactivateGroup (): void {
        if (!this.group) {
          return
        }
        this.group.isActive = false
        this.isSaving = true
        this.updateGroup().then(x => {
          this.isSaving = false
        })
      },
      loadGroup (): void {
        var groupId = this.$route.params.id

        if (!groupId) this.$router.push('/profile')

        // load group
        this.isLoading = true
        var url = ''
        // @ts-ignore cannot find mixins
        if (this.isGroupAdmin || this.hasRole('Admin')) {
          //isAdmin is ALWAYS false, when loading the group for the first time.
          //admins are allowed to see phone number and email address
          url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupgetbyid[0]}?id=${groupId}`
        } else {
          url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupgetbyidlight[0]}?id=${groupId}`
        }
        this.$http.get(url).then((resp) => {
          this.isLoading = false
          // @ts-ignore cannot find mixins
          if (this.isGroupAdmin || this.hasRole('Admin')) {
            this.group = resp.data as GroupDetailDto
          } else {
            this.group = resp.data as GroupDetailLightDto
          }
          this.group.members = _.sortBy(this.group.members, (m) => {
            return m.firstName
          })
        }, fail => {
          this.isLoading = false

          if (fail.status === 400 && fail.body.redirectUri.includes('NoMembership')) {
            this.$router.push(`/groups?groupId=${groupId}`)
          }
          if (fail.status === 403) {
            // @ts-ignore cannot find mixins
            this.showAlert403()
          } else {
            if (!groupId) {
              // @ts-ignore cannot find mixins
              this.showAlertError(this.$t('common.fetchFailed'))
            }
          }
        })
      },
      updateGroup (): PromiseLike<HttpResponse> {
        if (!this.group) {
          return Promise.reject(new Error('no group loaded'))
        }
        if (this.group.name === '') {
          return Promise.reject(new Error('no group name'))
        }
        var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupupdate[0]}`
        var groupDto = {
          id: this.group.id,
          name: this.group.name,
          description: this.group.description,
          groupType: this.group.groupType,
          members: _.map(this.group.members, function (item) {
            return item.id
          }),
          isAllLocations: this.group.isAllLocations,
          isActive: this.group.isActive
        }
        return this.$http.put(url, groupDto)
      },
      toNotes (): void {
        this.$router.push(`/groups/group/${this.group?.id}/notes`)
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .group.container {
    .remove-disabled {
      cursor: not-allowed;
    }

    padding: 0 15px!important;

    .mobiletitle {
      margin-top: 30px;
    }

    a {
      color: $brand-red;
      &.back {
        color: #000;
        svg {
          margin-right: 5px;
          color: $brand-red;
        }
       }
    }

    .group-secondary {
      background: #fff;
      padding: 40px 15px;

      .cap-label {
        display: block;
        color: $brand-red;
        margin: 20px 0 10px 0;
        outline: none;

        svg {
          margin-right: 5px;
        }
      }
    }

    .group-list {
      padding-top: 25px;
      position: relative;
      .user-row {
        background: #fff;
        margin-bottom: 5px;
        .user {
         a {
            color: $brand-red;
            font-weight: bold;
            text-transform: uppercase;
          }
          .user-img {
            float: left;
            margin-right: 15px;
          }
        }
        .portrait-size {
          width: 50px;
          height: 50px;
         }
      }
    }

  .sendsuccess {
    color:$brand-success;
    margin-bottom:5px;
  }

  .sendMessage {

    .subject {
      margin-bottom: 15px;
      input {
        width: 100%;
      }
    }
    .messagebody {
      textarea {
        width: 100%;
      }
    }

    .actions {
      .cancel {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .list-header {
    padding-bottom: 15px;
  }

  @media (max-width: $media-sm-max) {

    .actions {
      text-align: center;
      margin-top: 15px;
    }

    .group-side.col-md-3, .group-list.col-md-9 {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  .loading {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .issaving {
    text-align: center;
    margin-top: 5px;
  }
}
</style>
