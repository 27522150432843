
<template>
  <div class="volunteerprofiledetails">
    <div class="row section">
      <div class="col-12">
        <FriendVolunteerCommonDetails :volunteer="volunteer" />
      </div>
    </div>
    <div class="row section">
      <div v-if="volunteer.hasStandardProfile" class="col-12">
        <FriendVolunteerStandardDetails :profile="volunteer.standardProfile" />
      </div>
      <div v-else class="col-12 text-center">
        <SprButton type="primary"
                   :size="5"
                   :title="$t('volunteerForm.createStandardProfile')"
                   class="spr-redborder button-width mb-2"
                   click-event="click"
                   @click="createStandard"
        />
      </div>
    </div>
    <div class="row section">
      <div v-if="volunteer.hasNetProfile" class="col-12">
        <FriendVolunteerNetDetails :profile="volunteer.netProfile" />
      </div>
      <div v-else class="col-12 text-center">
        <SprButton type="primary"
                   :size="5"
                   :title="$t('volunteerForm.createNetProfile')"
                   class="spr-redborder button-width mb-2"
                   click-event="click"
                   @click="createNet"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import enumhelper from '../mixins/enums.js'
  import cache from '../mixins/cache.js'
  import SprButton from './SPRButton.vue'
  import FriendVolunteerCommonDetails from '@/components/friends/FriendVolunteerCommonDetails.vue'
  import FriendVolunteerStandardDetails from '@/components/friends/FriendVolunteerStandardDetails.vue'
  import FriendVolunteerNetDetails from '@/components/friends/FriendVolunteerNetDetails.vue'

  // the data object
  export interface TemplateComponentData {
    initialVolunteer: object
  }

  export default Vue.extend({
    name: 'VolunteerProfileDetails',
    components: { SprButton, FriendVolunteerCommonDetails, FriendVolunteerStandardDetails, FriendVolunteerNetDetails },
    mixins: [enumhelper, cache],
    props: {
      volunteer: {
        type: Object,
        required: true
      }
    },
    methods: {
      createStandard () {
        // @ts-ignore
        this.$router.push('/friend/volunteer/add/standard')
      },
      createNet () {
        // @ts-ignore
        this.$router.push('/friend/volunteer/add/net')
      }
    }
  })
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";
  .volunteerprofiledetails {
    margin-top:15px;

    .section {
      margin-bottom: 45px;

      h5 {
        display: inline-block;
      }

      svg {
        margin-left: 5px;
      }
    }
  }
</style>