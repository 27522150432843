<template>
  <div class="spr-event-collapsible">
    <div class="header" @click="toggleCollapse">
      <span class="cap-label">{{ title }}</span>
      <div v-if="showIcon === 'chevron'">
        <font-awesome-icon v-if="collapsed" icon="chevron-up" class="brand-red chevron" aria-hidden="true" />
        <font-awesome-icon v-else icon="chevron-down" class="brand-red  chevron" aria-hidden="true" />
      </div>
      <div v-else-if="showIcon === 'pencil'">
        <font-awesome-icon icon="pencil-alt" class="brand-red" aria-hidden="true" />
      </div>
      <div v-else>
        <font-awesome-icon
          icon="pencil-alt"
          class="brand-red"
          aria-hidden="true"
        />
      </div>
    </div>
    <div v-if="!collapsed">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  // the data object
  export interface TemplateComponentData {
    collapsed: boolean
  }

  export default Vue.extend({
    name: 'SprEventCollapsible',
    props: {
      title: {
        type: String,
        required: true
      },
      showChevron: {
        type: Boolean,
        default: false
      },
      showPencil: {
        type: Boolean,
        default: false
      },
      initialCollapsed: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        collapsed: this.initialCollapsed
      }
    },
    computed: {
      // Use a computed property to determine which icon to show
      showIcon (): string {
        if (this.showChevron && !this.showPencil) {
          return 'chevron'
        } else if (!this.showChevron && this.showPencil) {
          return 'pencil'
        }
        return 'pencil' // or a default value
      }
    },
    methods: {
      toggleCollapse () {
        this.collapsed = !this.collapsed
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/_variables.scss";
.spr-event-collapsible{
  .chevron {
    margin-bottom: 9px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}
</style>