<template>
  <div v-if="friendProfile && friendProfile.friendInfo && friendProfile.friendInfo.netProfile" class="container">
    <div class="profileidentnetvolunteerform">
      <div class="row">
        <div class="col-12">
          <h2>{{ $t('volunteerForm.updateTitle') }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <backbar />
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center py-2">
          <validation :id="'validationTarget' + _uid"
                      ref="validationtarget"
                      :validation-errors="validationErrors"
                      exclude-field-reference
                      include-title
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <VolunteerProfileCommon :volunteer="friendProfile.friendInfo" @input="onVolunteerChanged" />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <VolunteerProfileNet :volunteer="friendProfile.friendInfo.netProfile" @input="onVolunteerNetProfileChanged" />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12 sectionheader">
          <h3>{{ $t("nonIdentProfileForm.applicationState") }}</h3>
        </div>
        <div class="col-12">
          <InterviewForm
            v-if="friendProfile"
            :value="computedInterviewData"
            :disabled="isStateChangeDisabled"
            @input="onInterviewDataChanged"
          />
        </div>
        <div class="col-12">
          <OnHoldForm
            :value="computedOnHoldData"
            :disabled="isStateChangeDisabled"
            @input="onOnHoldDataChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <textareainput
            v-model="friendProfile.friendInfo.netProfile.privateNotes"
            :title="$t('volunteerForm.privateNotes')" :rows="6"
          />
        </div>
      </div>
      <div class="row feature-panel buttons">
        <div class="col-6 cancel">
          <SprButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.cancel')"
                     click-event="onCancel"
                     @onCancel="cancel"
          />
        </div>
        <div class="col-6 save">
          <SprButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.save')"
                     click-event="onSave"
                     @onSave="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import SprButton from '@/components/SPRButton.vue'
  import VolunteerProfileCommon from '@/components/friends/VolunteerProfileCommon.vue'
  import VolunteerProfileNet from '@/components/friends/VolunteerProfileNet.vue'
  import InterviewForm from '@/components/friends/InterviewForm.vue'
  import OnHoldForm from '@/components/friends/OnHoldForm.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import backbar from '../BackBar.vue'
  import validation from '../SPRValidationError.vue'
  import Alerts from '@/mixins/alerts.js'
  import { ErrorBag, FieldError } from 'vee-validate'
  import { UserProfileDto, FriendProfileDto, FriendVolunteerDto, FriendVolunteerProfileNetDto, GetFriendProfileByFriendVolunteerIdCriteria, CustomerState } from '@/types/index'
  import { InterviewData, OnHoldData } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    friendProfile: FriendProfileDto | undefined,
    isSavedOrCancelled: boolean
  }

  // This is the component for managing updating an ident users Net Friend profile.
  export default Vue.extend({
    name: 'ProfileIdentNetVolunteerForm',
    components: { validation, backbar, VolunteerProfileCommon, VolunteerProfileNet, InterviewForm, OnHoldForm, SprButton, textareainput },
    mixins: [Alerts],
    props: {
      profileId: {
        type: Number,
        default: 0
      },
      groupId: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        friendProfile: undefined,
        isSavedOrCancelled: false
      }
    },
    computed: {
      validationErrors (): Array<Array<string>> {
        var errs: ErrorBag = this.errors ? this.errors : []
        if (errs.items.length) {
          return errs.items.map((err: FieldError) => {
            return [`error_${err.field}`, err.rule ? err.rule : '', err.msg ? err.msg : '']
          })
        }
        return []
      },
      computedInterviewData (): InterviewData | null {
        var d = new Date()
        var defaultDate = new Date(`${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate().toString()}T00:00:00Z`) // must be in UTC date format
        if (this.friendProfile?.friendInfo?.netProfile) {
          const p = this.friendProfile.friendInfo.netProfile
          return {
            interviewed: p.volunteerInterviewed,
            interviewedBy: p.volunteerInterviewedBy,
            interviewedDate: p.volunteerInterviewedOnDate || defaultDate
          } as InterviewData
        }

        return {
          interviewed: false,
          interviewedBy: '',
          interviewedDate: undefined
        } as InterviewData
      },
      computedOnHoldData (): OnHoldData | null {
        if (this.friendProfile?.friendInfo?.netProfile) {
          const p = this.friendProfile.friendInfo.netProfile
          return {
            onHold: Boolean(p.onHoldDeadline),
            onHoldDeadline: p.onHoldDeadline,
            onHoldDetails: p.onHoldDetails
          } as OnHoldData
        }

        return null
      },
      isStateChangeDisabled (): boolean {
        /// Cannot change the state if the customer has friendships
        return this.friendProfile?.friendInfo.netProfile.state === CustomerState.PendingFriend ||
          this.friendProfile?.friendInfo.netProfile.state === CustomerState.ActiveFriendship ||
          Boolean(this.friendProfile?.friendInfo.netProfile.friendships?.length)
      }
    },
    mounted (): void {
      var criteria: GetFriendProfileByFriendVolunteerIdCriteria = {
        friendManagementGroupId: this.groupId,
        friendVolunteerId: this.profileId,
        includeFriendships: false,
        includeFriendPools: false
      }
      var url = (this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteergetnetprofilebyid[0])
      this.$http.post(url, criteria).then(s => {
        if (s.ok) {
          // @ts-ignore
          this.friendProfile = s.body as FriendProfileDto
          return
        }
        this.failLoad()
      }, f => this.failLoad())
    },
    provide (): Record<string, any> {
      return {
        $validator: this.$validator // allow child components to have validator injected
      }
    },
    methods: {
      failLoad (): void {
        this.displayError(this.$t('common.fetchFailed'))
      },
      onVolunteerChanged (newVolunteer: FriendVolunteerDto): void {
        if (this.friendProfile) {
          this.friendProfile.friendInfo = newVolunteer
        }
      },
      onVolunteerNetProfileChanged (newProfile: FriendVolunteerProfileNetDto): void {
        if (this.friendProfile) {
          this.friendProfile.friendInfo.netProfile = newProfile
        }
      },
      onInterviewDataChanged (newData: InterviewData): void {
        if (this.friendProfile) {
          this.friendProfile.friendInfo.netProfile.volunteerInterviewed = newData.interviewed
          this.friendProfile.friendInfo.netProfile.volunteerInterviewedBy = newData.interviewedBy
          this.friendProfile.friendInfo.netProfile.volunteerInterviewedOnDate = newData.interviewedDate
        }
      },
      onOnHoldDataChanged (newData: OnHoldData): void {
        if (this.friendProfile) {
          this.friendProfile.friendInfo.netProfile.onHoldDeadline = newData.onHold ? newData.onHoldDeadline : undefined
          this.friendProfile.friendInfo.netProfile.onHoldDetails = newData.onHold ? newData.onHoldDetails : ''
        }
      },
      cancel (): void {
        const url = `/friend/volunteerpreview/${this.groupId}/${this.profileId}`
        this.$router.push(url)
      },
      save (): void {
        if (this.friendProfile?.friendInfo?.netProfile?.preferredAges === 0) {
          this.friendProfile.friendInfo.netProfile.preferredAges = 1
          this.$nextTick(() => {
            if (!this.friendProfile) return
            this.friendProfile.friendInfo.netProfile.preferredAges = 0
            this.validateAndSave()
          })
        } else {
          this.validateAndSave()
        }
      },
      validateAndSave (): void {
        this.$validator.validateAll().then(result => {
          // check any child controls have not added errors
          if (!result || (this.validationErrors && this.validationErrors.length)) {
            var target = this.$refs['validationtarget']
            if (target) {
              window.scrollTo({
                // @ts-ignore
                top: target.$el.parentElement.clientHeight,
                left: 0,
                behavior: 'smooth'
              })
            }
            return
          }
          if (this.friendProfile) {
            const data = this.friendProfile
            // @ts-ignore
            data.friendInfo.standardProfile = null
            this.updateFriendProfile(data.friendInfo)
          }
        })
      },
      updateFriendProfile (data: FriendVolunteerDto): void {
        const updateUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.updateidentnetfriendvolunteer[0]
        this.$http.put(updateUrl, data).then((result: any) => {
          this.$router.push(`/friend/search/${this.groupId}/volunteer`)
        }, (err: Response | any) => {
          this.displayError(err)
        })
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .profileidentnetvolunteerform {
    .feature-panel {
      background-color: $brand-white;
      margin-bottom: 30px;
      padding-top: 15px;

      &.buttons {
        margin-top: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        .cancel {
          text-align: left;
        }
        .save {
          text-align: right;
        }
      }
    }

    .profilehead {
      margin-top: 25px;
      margin-bottom: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $brand-white;
    }

    .subheader {
      margin-top: 15px;
      margin-bottom: 15px;
      display: block;
    }

    .profile-button {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }

    .fa-exclamation-triangle {
      color: $brand-red;
    }
  }
</style>
