import { render, staticRenderFns } from "./Wizard-Transport.vue?vue&type=template&id=2fc73a64&scoped=true"
import script from "./Wizard-Transport.vue?vue&type=script&lang=js"
export * from "./Wizard-Transport.vue?vue&type=script&lang=js"
import style0 from "./Wizard-Transport.vue?vue&type=style&index=0&id=2fc73a64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc73a64",
  null
  
)

export default component.exports