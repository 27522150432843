<template>
  <div>
    <dt>{{ $t('profile.section') }}</dt>
    <dd v-if="member.section">
      {{ member.section }}
    </dd>
    <dd v-else>
      -
    </dd>
    <dt>{{ $t('profile.membershipId') }}</dt>
    <dd v-if="member.crmcustomerid">
      {{ member.crmcustomerid }}
    </dd>
    <dd v-else>
      -
    </dd>
    <dt>{{ $t('profile.joinDate') }}</dt>
    <dd v-if="member.membershipstartyear > 0">
      {{ member.membershipstartyear }}
    </dd>
    <dd v-else>
      -
    </dd>
    <dt>{{ $t('profile.membershipStatus') }}</dt>
    <dd v-if="membershipStatus">
      {{ $t('membershipStatus.' + membershipStatus) }}
    </dd>
    <dd v-else>
      -
    </dd>
    <dt>{{ $t('profile.membershipType') }}</dt>
    <dd v-if="membershipType">
      {{ $t('membershipType.' + membershipType) }}
    </dd>
    <dd v-else>
      -
    </dd>
    <dt>{{ $t('profile.membershipFees') }}</dt>
    <dd v-if="memberFees">
      {{ memberFees }}
    </dd>
    <dd v-else>
      -
    </dd>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { CrmDataDto, MembershipStatus, MembershipType } from '@/types/index'
  import moment from 'moment'
  import stringhelper from '@/helpers/stringhelper'

  export default Vue.extend({
    name: 'MembershipData',
    props: {
      member: {
        type: Object as () => CrmDataDto,
        default: null
      }
    },
    computed: {
      memberFees (): string {
        const translationExists = this.$t('profile.membershipFees')

        if (stringhelper.isEmptyOrSpaces(translationExists)) {
          return ''
        }

        if (!this.member || !this.member.lastPaymentDate) {
          return ' - '
        }

        const mom = moment(this.member.lastPaymentDate)

        if (mom) {
          return mom.format('DD.MM.YYYY')
        }

        return ''
      },
      membershipStatus (): string {
        const translationExists = this.$t('profile.membershipStatus')

        if (stringhelper.isEmptyOrSpaces(translationExists)) {
          return ''
        }

        if (!this.member || !this.member.membershipstatus.toString()) {
          return ' - '
        }

        const rv = MembershipStatus[this.member.membershipstatus]

        return stringhelper.toCamelCase(rv)
      },
      membershipType (): string {
        const translationExists = this.$t('profile.membershipType')

        if (stringhelper.isEmptyOrSpaces(translationExists)) {
          return ''
        }

        if (!this.member || !this.member.membershiptype.toString()) {
          return ' - '
        }

        const rv = MembershipType[this.member.membershiptype]

        return stringhelper.toCamelCase(rv)
      }

    }
  })
</script>