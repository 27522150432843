<template>
  <div class="comp-profile-editor col-12">
    <div class="row page-header py-2">
      <div class="container">
        <div class="row">
          <div class="col col-12">
            <router-link to="/profile" class="d-inline-block">
              <font-awesome-icon icon="chevron-left" aria-hidden="true" /> {{ $t('profile.back') }}
            </router-link>
          </div>
          <div class="col col-12">
            <span class="h3 editor-header" role="heading" aria-level="1">{{ headerTranslation }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row page-body">
      <div class="container">
        <div :is="chooseEditor" v-if="chooseEditor !== null" :editor="editor" @onChange="updateChildData" @onSave="childDataSaved" @onCancel="childCanceled" />
        <div v-else>
          Illegal editor.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import checkboxEditor from './Editor-Checkbox.vue'
  import personalEditor from './Editor-Personal.vue'
  import imageEditor from './Editor-Image.vue'
  import locationEditor from './Editor-Location.vue'
  import loginEditor from './Editor-Login.vue'
  import eventAdminEditors from './Editor-EventAdmins.vue'
  import deleteProfile from './Editor-DeleteProfile.vue'
  import profileInvolvedEventCategories from './Editor-FlagPill.vue'
  import Vue from 'vue'

  // the data object
  export interface TemplateComponentData {
    legal: string[],
    hasChanges: boolean
  }

  export default Vue.extend({
    name: 'ProfileEditor',
    props: {
      editor: {
        type: String,
        default: ''
      }
    },
    data (): TemplateComponentData {
      return {
        legal: ['skills', 'interest', 'languages', 'personal', 'image', 'location', 'login', 'eventadmins', 'deleteprofile', 'involvedEventCategories'] as string[],
        hasChanges: false
      }
    },
    computed: {
      isLegal: function () {
        var index = this.legal.indexOf(this.editor)
        return index !== -1
      },
      chooseEditor: function () {
        if (this.isLegal) {
          if (this.editor === 'personal') {
            return personalEditor
          } else if (this.editor === 'image') {
            return imageEditor
          } else if (this.editor === 'location') {
            return locationEditor
          } else if (this.editor === 'login') {
            return loginEditor
          } else if (this.editor === 'eventadmins') {
            return eventAdminEditors
          } else if (this.editor === 'deleteprofile') {
            return deleteProfile
          } else if (this.editor === 'involvedEventCategories') {
            return profileInvolvedEventCategories
          } else {
            return checkboxEditor
          }
        }
        return null
      },
      headerTranslation: function () {
        switch (this.editor) {
        case 'skills':
          return this.$t('profile.skillEditorHeader')
        case 'interest':
          return this.$t('profile.interestEditorHeader')
        case 'languages':
          return this.$t('profile.languageEditorHeader')
        case 'personal':
          return this.$t('profile.sectionPersonalInfo')
        case 'image':
          return this.$t('profile.imageEditorHeader')
        case 'location':
          return this.$t('profile.locationEditorHeader')
        case 'login':
          return this.$t('profile.loginEditorHeader')
        case 'eventadmins':
          return this.$t('profile.eventAdminsEditorHeader')
        case 'deleteprofile':
          return this.$t('profile.deleteUserProfile')
        case 'involvedEventCategories':
          return this.$t('wizard.alreadyInvolved')
        default:
          return '' // Error, should redirect immediately anyhow
        }
      }
    },
    mounted: function () {
      if (!this.isLegal) {
        this.$router.push('/profile')
      }
    },
    beforeRouteLeave (to, from, next) {
      if (this.hasChanges) {
        var text = this.$t('profile.unsavedChanges').toString()
        const answer = window.confirm(text)
        if (answer) {
          return next()
        } else {
          return next(false)
        }
      }
      return next()
    },
    methods: {

      updateChildData (data: boolean) {
        this.hasChanges = data
      },
      childDataSaved () {
        this.hasChanges = false
      },
      childCanceled () {
        this.hasChanges = false
      }
    }
  })
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';

$header: 80px;

.comp-profile-editor {
  .page-header {
    min-height: $header;
    background: $brand-grey1;
    overflow-wrap: break-word;

    a {
      color: $brand-black;
      text-decoration: none;

      &:hover {
        color: $brand-red;
      }

      svg {
        color: $brand-red;
      }
    }

    .editor-header {
      color: $brand-red;
    }
  }

  .page-body {
    min-height: calc(100vh - 266px);
    background: $brand-white;
    padding-bottom: 150px;
    position: relative;

    .button-footer {
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      height: 140px;
      margin-top: 10px;
    }
  }

  .inputs {
    width: calc(100% - 20px);
    max-width: 300px;
    border: 2px solid $brand-red;
    background: $brand-white;
    padding-top: 14px;
    padding-bottom: 14px;
    color: $brand-red;
    &:hover

  {
    color: $brand-white;
    background: $brand-red;
  }

  }
}
</style>
