import { EventOrganizerIdentifier } from '@/types/custom'

export function isEventOrganizerIdentifier (obj: any): obj is EventOrganizerIdentifier {
  return (
    typeof obj === 'object' && // Check if obj is an object
    obj !== null && // Exclude null values
    typeof obj.eventOrganizerType === 'number' &&
    typeof obj.organizerId === 'number'
  )
}
