<template>
  <div class="template-list">
    <ul>
      <li v-for="(template, index) in templates" :key="index">
        <div class="template-view">
          <span class="template-name">{{ template.title }}</span>
          <span>{{ template.type }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'TemplateSelect',
    props: {
      templates: {
        type: Array,
        default: () => []
      },
      title: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }

    },
    methods: {
    //
    }
  }
</script>
