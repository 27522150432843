<template>
  <!-- You have to give the element a size for it to function.-->
  <!-- You need to wrap this in a div with overflow: hidden to work around a chrome bug -->
  <div class="spr-portrait">
    <div v-for="section in sections" :key="section">
      <div v-show="completionRounded - (section * 25.0) > 0"
           :class="'arc-' + section + '-' + completionRounded"
      />
    </div>
    <div v-show="showNumericSafe" class="numeric">
      <div>{{ completion }}%</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Portrait',
    props: {
      completion: {
        type: Number,
        default: 0
      },
      showNumeric: Boolean
    },
    data () {
      return {
        sections: [0, 1, 2, 3]
      }
    },
    computed: {
      completionRounded: function () {
        /* Completion percentage is turned into degrees, then rounded up to next full ten.
      classes are generated for 0, 10, 20 ... 350, 360 deg progress only. */
        return Math.ceil((Number(this.completion * 3.6)) / 10) * 10
      },
      showNumericSafe: function () {
        if (this.showNumeric) {
          return true
        }
        return false
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .spr-portrait {
    border-radius: 50%;
    position: relative;
  }

  .numeric {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  // Generates the progress indicator styles.
  // TODO(JanneF): Convert to scss, less solution did not work as intended
  // because vue does not allow dynamic less.
  // .make-sections(3);
  // .make-sections(@n, @i: 0) when (@i =< @n) {
  //   .make-degs(360);
  //   .make-degs(@m, @j: 0) when (@j <= @m) {
  //     .arc-@{i}-@{j} {
  //       overflow: hidden;
  //       position: absolute;
  //       top: -0.25em;
  //       right: 50%;
  //       bottom: 50%;
  //       left: -0.25em;
  //       transform-origin: 100% 100%;
  //       transform: rotate(90deg + @i * 90deg) skewX(90deg - min(max(@j - (@i * 90deg), 0deg), 90deg));
  //     }

  //     .arc-@{i}-@{j}:before {
  //       box-sizing: border-box;
  //       display: block;
  //       border: solid 0.25em red;
  //       width: 200%;
  //       height: 200%;
  //       border-radius: 50%;
  //       transform: skewX(min(max(@j - (@i * 90deg), 0deg), 90deg) - 90deg);
  //       content: '';
  //     }
  //     .make-degs(@m, (@j + 10));
  //   }
  //   .make-sections(@n, (@i + 1));
  // }

// The code above needs to be converted to SCSS.
// Or better yet maybe use css variables to display the border as a pie chart so we dont have to write out loads of classes.
// Since the only use of this is hard coded to 90%. Just put in the classes for 90% for now.
// Awaiting feedback from the client about whether this should just be removed.
.arc-0-330 {
    overflow: hidden;
    position: absolute;
    top: -.25em;
    right: 50%;
    bottom: 50%;
    left: -.25em;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: rotate(90deg) skewX(0deg);
    transform: rotate(90deg) skewX(0deg)
}

.arc-0-330:before {
    box-sizing: border-box;
    display: block;
    border: .25em solid red;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg);
    content: ""
}

.arc-1-330 {
    overflow: hidden;
    position: absolute;
    top: -.25em;
    right: 50%;
    bottom: 50%;
    left: -.25em;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: rotate(180deg) skewX(0deg);
    transform: rotate(180deg) skewX(0deg)
}

.arc-1-330:before {
    box-sizing: border-box;
    display: block;
    border: .25em solid red;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg);
    content: ""
}

  .arc-2-330 {
    overflow: hidden;
    position: absolute;
    top: -.25em;
    right: 50%;
    bottom: 50%;
    left: -.25em;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: rotate(270deg) skewX(0deg);
    transform: rotate(270deg) skewX(0deg)
  }

  .arc-2-330:before {
    box-sizing: border-box;
    display: block;
    border: .25em solid red;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg);
    content: ""
  }

  .arc-3-330 {
    overflow: hidden;
    position: absolute;
    top: -.25em;
    right: 50%;
    bottom: 50%;
    left: -.25em;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: rotate(1turn) skewX(30deg);
    transform: rotate(1turn) skewX(30deg);
  }

  .arc-3-330:before {
    box-sizing: border-box;
    display: block;
    border: .25em solid red;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    -webkit-transform: skewX(-30deg);
    transform: skewX(-30deg);
    content: "";
  }

</style>
