<template>
  <div class="filteritems">
    <div v-show="customerTypes && customerTypes.length">
      <div class="row">
        <div class="col-12">
          <div class="cap-label">
            {{ $t('customerSearch.filterCustomerTypeTitle') }}
          </div>
        </div>
      </div>
      <div class="row filter-customer-type">
        <div class="col-12">
          <span v-for="(filteritem) in customerTypes" :key="filteritem.name">
            <span :class="{ 'filterbutton': true, 'selected': isSelected(list.customerTypes, filteritem.id) }">
              <sprbutton :title="filteritem.name"
                         :size="4"
                         :bordersize="2"
                         :value="filteritem.id.toString()"
                         click-event="changeCustomerType"
                         @changeCustomerType="changeCustomerType"
              />
            </span>
          </span>
        </div>
      </div>
    </div>

    <div v-show="customerStates && customerStates.length">
      <div class="row">
        <div class="col-12">
          <div class="cap-label">
            {{ $t('customerSearch.filterCustomerStatusTitle') }}
          </div>
        </div>
      </div>
      <div class="row filter-customer-status">
        <div class="col-12">
          <span v-for="(filteritem) in customerStates" :key="filteritem.name">
            <span :class="{ 'filterbutton': true, 'selected': isSelected(list.customerStates, filteritem.id) }">
              <sprbutton :title="filteritem.name"
                         :size="4"
                         :bordersize="2"
                         :value="filteritem.id.toString()"
                         :class="getStateFilterCssClass(filteritem.id)"
                         click-event="changeCustomerStatus"
                         @changeCustomerStatus="changeCustomerStatus"
              />
            </span>
          </span>
        </div>
      </div>
    </div>

    <div v-show="friendshipTypes && friendshipTypes.length">
      <div class="row">
        <div class="col-12">
          <div class="cap-label">
            {{ $t('customerSearch.filterFriendshipTypeTitle') }}
          </div>
        </div>
      </div>
      <div class="row filter-customer-status">
        <div class="col-12">
          <span v-for="(filteritem) in friendshipTypes" :key="filteritem.name">
            <span :class="{ 'filterbutton': true, 'selected': isSelected(list.friendshipTypes, filteritem.id) }">
              <sprbutton :title="filteritem.name"
                         :size="4"
                         :bordersize="2"
                         :value="filteritem.id.toString()"
                         click-event="changeFriendshipType"
                         @changeFriendshipType="changeFriendshipType"
              />
            </span>
          </span>
        </div>
      </div>
    </div>

    <!-- Mother tongue -->
    <div v-show="languages && languages.length">
      <div class="row">
        <div class="col-12">
          <div class="cap-label">
            {{ $t('customerForm.nativeLanguage') }}
          </div>
        </div>
      </div>
      <div class="row filter-customer-status">
        <div class="col-12">
          <span v-for="(filteritem) in languages" :key="filteritem.name">
            <span :class="{ 'filterbutton': true, 'selected': isSelected(list.motherTongs, filteritem.id) }">
              <sprbutton :title="filteritem.name"
                         :size="4"
                         :bordersize="2"
                         :value="filteritem.id.toString()"
                         click-event="changeLanguage"
                         @changeLanguage="changeMotherTongs"
              />
            </span>
          </span>
        </div>
      </div>
      <!-- knows language -->
      <div v-show="languages && languages.length" class="row">
        <div class="col-12">
          <div class="cap-label">
            {{ $t('customerForm.customerLanguages') }}
          </div>
        </div>
      </div>
      <div class="row filter-customer-status">
        <div class="col-12">
          <span v-for="(filteritem) in languages" :key="filteritem.name">
            <span :class="{ 'filterbutton': true, 'selected': isSelected(list.languages, filteritem.id) }">
              <sprbutton :title="filteritem.name"
                         :size="4"
                         :bordersize="2"
                         :value="filteritem.id.toString()"
                         click-event="changeLanguage"
                         @changeLanguage="changeLanguages"
              />
            </span>
          </span>
        </div>
      </div>
    </div>

    <!-- preferred age -->
    <div v-show="ages && ages.length">
      <div class="row">
        <div class="col-12">
          <div class="cap-label">
            {{ $t('customerSearch.filterAge') }}
          </div>
        </div>
      </div>
      <div class="row filter-customer-status">
        <div class="col-12">
          <span v-for="(filteritem) in ages" :key="filteritem.name">
            <span :class="{ 'filterbutton': true, 'selected': isSelected(list.ages, filteritem.id) }">
              <sprbutton :title="filteritem.name"
                         :size="4"
                         :bordersize="2"
                         :value="filteritem.id.toString()"
                         click-event="changeAges"
                         @changeAges="changeAges"
              />
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import { SearchFilter } from '../../types/custom'
  import { CustomerTypes, NetCustomerTypes, CustomerState, Interests, NetFriendshipTypes, FriendshipTypes, LanguageDto, PreferredAges } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    list: SearchFilter
  }

  export default Vue.extend({
    name: 'FilterItems',
    components: { sprbutton },
    props: {
      value: {
        type: Object as () => SearchFilter,
        required: true
      },
      customerTypes: {
        type: Array as () => Array<CustomerTypes> | Array<NetCustomerTypes>,
        default: () => []
      },
      customerStates: {
        type: Array as () => Array<CustomerState>,
        default: () => []
      },
      interests: {
        type: Array as () => Array<Interests>,
        default: () => []
      },
      friendshipTypes: {
        type: Array as () => Array<FriendshipTypes> | Array<NetFriendshipTypes>,
        default: () => []
      },
      languages: {
        type: Array as () => Array<LanguageDto>,
        default: () => []
      },
      ages: {
        type: Array as () => Array<PreferredAges>,
        default: () => []
      }
    },
    data (): TemplateComponentData {
      return {
        list: {} as SearchFilter
      }
    },
    watch: {
      value (newVal) {
        this.list = newVal
      }
    },
    mounted (): void {
      this.list = this.value
    },
    methods: {
      getStateFilterCssClass: function (id): string {
        return `statefilteritem filteritem-${id}`
      },
      changeCustomerType: function (id): void {
        this.updateList(this.list.customerTypes, id)
        this.$emit('input', this.list)
      },
      changeCustomerStatus: function (id): void {
        this.updateList(this.list.customerStates, id)
        this.$emit('input', this.list)
      },
      changeActivityType: function (id): void {
        this.updateList(this.list.interests, id)
        this.$emit('input', this.list)
      },
      changeFriendshipType: function (id): void {
        this.updateList(this.list.friendshipTypes, id)
        this.$emit('input', this.list)
      },
      changeMotherTongs: function (id): void {
        this.updateLanguageList(this.list.motherTongs, id)
        this.$emit('input', this.list)
      },
      changeLanguages: function (id): void {
        this.updateLanguageList(this.list.languages, id)
        this.$emit('input', this.list)
      },
      changeAges: function (id): void {
        this.updateList(this.list.ages, id)
        this.$emit('input', this.list)
      },
      updateList: function (list, id): void {
        var existingItemIndex = _.indexOf(list, Number(id))
        if (existingItemIndex >= 0) {
          this.$delete(list, existingItemIndex)
        } else {
          this.$set(list, list.length, Number(id))
        }
      },
      updateLanguageList: function (list, isoCode): void {
        var existingItemIndex = _.indexOf(list, isoCode)
        if (existingItemIndex >= 0) {
          this.$delete(list, existingItemIndex)
        } else {
          this.$set(list, list.length, isoCode)
        }
      },
      isSelected (list, id): boolean {
        return _.indexOf(list, id) >= 0
      }
    }
  })
</script>
<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .filteritems {
    .filter-customer-type,
    .filter-customer-status,
    .filter-activity-type {
      margin-bottom: 30px;
    }

    .cap-label {
      margin-bottom: 15px;
    }

    .filterbutton {
      padding-right:6px;
      margin-bottom: 8px;
      display: inline-block;

      .statefilteritem {
        &:hover {
          color: $brand-white;
        }
        &.filteritem-1 {
          border-color: $state-pendingapproval;
          &:hover {
            background-color: $state-pendingapproval;
          }
        }

        &.filteritem-2 {
          border-color: $state-active;
          &:hover {
            background-color: $state-active;
          }
        }

        &.filteritem-3 {
          border-color: $state-pendingfriend;
          &:hover {
            background-color: $state-pendingfriend;
          }
        }

        &.filteritem-4 {
          border-color: $state-activefriendship;
          &:hover {
            background-color: $state-activefriendship;
          }
        }

        &.filteritem-5 {
          border-color: $state-nonactive;
          &:hover {
            background-color: $state-nonactive;
          }
        }

        &.filteritem-6 {
          border-color: $state-onhold;
          &:hover {
            background-color: $state-onhold;
          }
        }
      }

      button {
        border-color:$brand-grey5;
        color:$brand-black;
        font-size:12px;
        padding:5px 10px;

        &:hover {
          border-color:$brand-red;
          color:$brand-white;
        }

        &:focus {
          outline: none;
        }
      }

      &.selected {
        outline: none;
        button {
          outline: none;
          border-color:$brand-red;
          color:$brand-white;
          background-color:$brand-red;

          &:hover {
            color:$brand-white;
          }

          &.filteritem-1 {
            background-color: $state-pendingapproval;
          }
          &.filteritem-2 {
            background-color: $state-active;
          }
          &.filteritem-3 {
            background-color: $state-pendingfriend;
          }
          &.filteritem-4 {
            background-color: $state-activefriendship;
          }
          &.filteritem-5 {
            background-color: $state-nonactive;
          }
          &.filteritem-6 {
            background-color: $state-onhold;
          }
        }
      }
    }
  }

</style>
