<template>
  <div v-if="isLegal" class="comp-profile-editor-location mx-auto container-fluid">
    <div class="row mt-3">
      <div class="col-12 text-center">
        <sprvalidation :validation-errors="validationErrors" />
      </div>
    </div>
    <div class="row text-center mt-5 mb-3">
      <div class="col-12">
        <span class="instruct">{{ $t('profile.workingZipEditor') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <sprautocompletedropdown
          v-if="hasChangeSectionWithinDistrictPermissions"
          v-model="sectionZip"
          :options="sectionsZips"
          option-label="value"
          :placeholder="$t('profile.workingZipEditorPlaceholder')"
          :required="true"
          :max-results="200"
          :show-arrow="false"
        />
        <sprsimpledropdown
          v-else
          v-model="disabledSectionZip"
          title=""
          :items="disabledSectionsZips"
          :required="false"
          :disabled="true"
          :is-valid="true"
        />
      </div>
      <div v-if="!hasChangeDistrictPermissions && hasChangeSectionWithinDistrictPermissions" class="col-12">
        <div class="district-change-warning">
          <font-awesome-icon icon="exclamation-triangle" />{{ $t('profile.cannotChangeDistrictWarning') }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <sprsimplecheckbox
          :title="$t('wizard.volunteerOnline')"
          :value="volunteerOnline"
          @input="onVolunteerOnlineChanged"
        />
      </div>
    </div>
    <div class="row text-center mt-5 mb-3">
      <div class="col-12">
        <span class="instruct">{{ $t('profile.availabilityEditor') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <sprcheckbox v-for="(entry) in objectSet"
                     :key="'cb_' + _uid + '_' + entry.name"
                     :title="$t('availabilityTimes.' + toLowerCamelCase(entry.name))"
                     click-event="on-click"
                     :value="entry.id"
                     :tabindex="0"
                     :collection="actualData"
                     class="checkbox"
                     @on-click="editSelection"
        />
      </div>
    </div>
    <div class="row text-center mt-5 mb-3">
      <div class="col-12">
        <span class="instruct">{{ $t('profile.transportationEditor') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <sprcheckbox v-for="(entry) in objectSetTransport"
                     :key="'cc_' + _uid + '_' + entry.text"
                     :title="$t('transportation.' + entry.text)"
                     click-event="on-click"
                     :value="entry.id"
                     :tabindex="0"
                     :collection="actualDataTransport"
                     class="checkbox"
                     @on-click="editSelectionTransport"
        />
      </div>
    </div>
    <div class="row text-center mt-5 mb-3">
      <div class="col-12">
        <span class="instruct">{{ $t('profile.travelTimeEditor') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <sprinput v-model="resonable"
                  v-validate="'required|numeric'" class="editor-field"
                  :is-valid="!errors.has('timeField')" name="timeField"
        />
      </div>
    </div>

    <!-- Save and cancel buttons -->
    <div v-if="!pending" class="row my-5">
      <div class="col-12 col-md-6 my-1">
        <sprbutton click-event="save-event" :title="$t('profile.save')"
                   :size="1"
                   :bordersize="2" class="fill-width" @save-event="onSave(3)"
        />
      </div>
      <div class="col-12 col-md-6 my-1">
        <sprbutton click-event="cancel-event" :title="$t('profile.cancel')"
                   :size="1"
                   :bordersize="2" class="fill-width" @cancel-event="onCancel"
        />
      </div>
    </div>
    <div v-else class="row my-5 text-center brand-red">
      <div class="col-12">
        <loading />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprautocompletedropdown from '../SPRAutocompleteDropdown.vue'
  import sprsimpledropdown from '../SPRSimpleDropdown.vue'
  import sprcheckbox from '../SPRCheckbox.vue'
  import sprsimplecheckbox from '../SPRSimpleCheckbox.vue'
  import sprinput from '../SPRInput.vue'
  import sprbutton from '../SPRButton.vue'
  import sprvalidation from '../SPRValidationError.vue'
  import loading from '../SPRLoading.vue'
  import { AvailabilityTimeDto } from '@/types'
  import { SprDropDownValue, StringKeyValuePair } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    resonable: string,
    savedTransport: Array<number>,
    savedAvailable: Array<number>,
    savedReasonable: string,
    hasChanges: boolean,
    pending: boolean,
    hasError: boolean,
    sectionsZips: Array<StringKeyValuePair> | undefined,
    sectionZip: StringKeyValuePair | undefined,
    disabledSectionZip: string | undefined,
    volunteerOnline: boolean | null
  }

  export default Vue.extend({
    name: 'ProfileEditorLocation',
    components: {
      sprautocompletedropdown, sprsimpledropdown, sprcheckbox, sprsimplecheckbox, sprinput, sprbutton, sprvalidation, loading
    },
    props: {
      editor: {
        type: String,
        default: ''
      },
      isOwnProfile: {
        type: Boolean,
        default: true
      }
    },
    data (): TemplateComponentData {
      return {
        resonable: '',
        savedTransport: [],
        savedAvailable: [],
        savedReasonable: '',
        hasChanges: false,
        pending: false,
        hasError: false,
        sectionsZips: [],
        sectionZip: undefined,
        disabledSectionZip: undefined,
        volunteerOnline: false
      }
    },
    computed: {
      actualData (): Array<number> {
        return this.savedAvailable
      },
      actualDataTransport (): Array<number> {
        return this.savedTransport
      },
      objectSet (): Array<AvailabilityTimeDto> {
        return this.$store.state.databaseAvailabilityTimes
      },
      objectSetTransport (): Array<Record<string, any>> {
        return this.$store.state.transportation
      },
      isLegal (): boolean {
        return this.editor === 'location'
      },
      validationErrors (): Array<Array<string>> {
        var arr = [] as Array<Array<string>>
        var self = this
        // @ts-ignore
        var err = _.find(self.errors.items, function (item) {
          return item.field === 'timeField'
        })
        if (err != null) {
          arr.push(['wizard.' + err.field, String(err.rule), String(err.msg)])
        }
        return arr
      },
      disabledSectionsZips (): Array<SprDropDownValue> {
        var arr = [] as Array<SprDropDownValue>
        if (this.sectionZip) {
          // @ts-ignore
          arr.push({ value: this.sectionZip.key, text: this.sectionZip.value })
        }

        return arr
      },
      hasChangeDistrictPermissions (): boolean {
        // these users are allowed to change their district, and sections within the district
        // @ts-ignore cannot find mixins
        return this.hasChangeSectionWithinDistrictPermissions && !(this.hasRole('Employee') || this.hasRole('DistrictAdmin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee'))
      },
      hasChangeSectionWithinDistrictPermissions (): boolean {
        // these users can only change to sections within their current district.
        // @ts-ignore cannot find mixins
        return !(this.hasRole('J1') || this.hasRole('J2'))
      },
      currentUserDistrictId (): number | undefined {
        var currentUserSectionId = this.$store.state.profile.sectionId
        var currentSection = _.find(this.$store.state.sections, function (section) {
          return section.id === currentUserSectionId
        })
        if (currentSection) {
          return currentSection.districtId
        }
        return undefined
      }
    },
    watch: {
      resonable: function (val) {
        if (!_.isEqual(this.$store.state.profile.transportation, this.savedTransport) ||
          !_.isEqual(this.$store.state.profile.volunteerOnline, this.volunteerOnline) ||
          !_.isEqual(this.$store.state.profile.availabilityTimes, this.savedAvailable) ||
          !_.isEqual(this.$store.state.profile.travelTime, this.resonable)) {
          this.hasChanges = true
        } else {
          this.hasChanges = false
        }
        this.$emit('onChange', this.hasChanges)
      },
      sectionZip: function (val) {
        // key comes in in the format workingZip-newSection
        if (val) {
          var arr = val.key.split('-')
          var workingZip = arr[0]
          var section = Number(arr[1])
        }
      },
      disabledSectionsZips () {
        this.disabledSectionZip = this.disabledSectionsZips.length
          ? String(this.disabledSectionsZips[0].value)
          : undefined
      }
    },
    created: function () {
      this.resonable = this.$store.state.profile.travelTime
      this.savedTransport = this.$store.state.profile.transportation
      this.savedAvailable = this.$store.state.profile.availabilityTimes
      this.savedReasonable = this.$store.state.profile.travelTime
      this.volunteerOnline = this.$store.state.profile.volunteerOnline

      var sections = [] as Array<StringKeyValuePair>

      _.each(this.$store.state.sections, (section) => {
        if (this.hasChangeDistrictPermissions) {
          _.each(section.zipCodes, (zip) => {
            sections.push({ key: `${zip}-${section.id}`, value: `${zip} (${section.name})` })
          })
        } else {
          // only load sections in this district
          if (section.districtId === this.currentUserDistrictId) {
            _.each(section.zipCodes, (zip) => {
              sections.push({ key: `${zip}-${section.id}`, value: `${zip} (${section.name})` })
            })
          }
        }
      })

      this.sectionsZips = _.sortBy(sections, 'value')
    },
    mounted: function () {
      var self = this
      if (!this.isLegal) {
        this.$router.push('/profile')
      }

      this.sectionZip = _.find(this.sectionsZips, function (section) {
        return section.key === `${self.$store.state.profile.workingZipCode}-${self.$store.state.profile.sectionId}`
      })
    },
    methods: {
      editSelection: function (value, truth) {
        if (truth) {
          if (this.actualData.indexOf(value) === -1) {
            var add = this.actualData.slice()
            add.push(value)
            this.savedAvailable = add
          }
        } else {
          var index = this.actualData.indexOf(value)
          if (index !== -1) {
            var remove = this.actualData.slice()
            remove.splice(index, 1)
            this.savedAvailable = remove
            //this.$store.commit('profilelocation', remove)
          }
        }
        if (!_.isEqual(this.$store.state.profile.transportation, this.savedTransport) ||
          !_.isEqual(this.$store.state.profile.volunteerOnline, this.volunteerOnline) ||
          !_.isEqual(this.$store.state.profile.availabilityTimes, this.savedAvailable) ||
          !_.isEqual(this.$store.state.profile.travelTime, this.resonable)) {
          this.hasChanges = true
        } else {
          this.hasChanges = false
        }
        this.$emit('onChange', this.hasChanges)
      },
      editSelectionTransport: function (value, truth) {
        if (truth) {
          if (this.actualDataTransport.indexOf(value) === -1) {
            var add = this.actualDataTransport.slice()
            add.push(value)
            this.savedTransport = add
          }
        } else {
          var index = this.actualDataTransport.indexOf(value)
          if (index !== -1) {
            var remove = this.actualDataTransport.slice()
            remove.splice(index, 1)
            this.savedTransport = remove
          }
        }
        if (!_.isEqual(this.$store.state.profile.transportation, this.savedTransport) ||
          !_.isEqual(this.$store.state.profile.volunteerOnline, this.volunteerOnline) ||
          !_.isEqual(this.$store.state.profile.availabilityTimes, this.savedAvailable) ||
          !_.isEqual(this.$store.state.profile.travelTime, this.resonable)) {
          this.hasChanges = true
        } else {
          this.hasChanges = false
        }
        this.$emit('onChange', this.hasChanges)
      },
      onVolunteerOnlineChanged () {
        this.volunteerOnline = !this.volunteerOnline
        //state.profile.volunteerOnline
        if (!_.isEqual(this.$store.state.profile.transportation, this.savedTransport) ||
          !_.isEqual(this.$store.state.profile.volunteerOnline, this.volunteerOnline) ||
          !_.isEqual(this.$store.state.profile.availabilityTimes, this.savedAvailable) ||
          !_.isEqual(this.$store.state.profile.travelTime, this.resonable)) {
          this.hasChanges = true
        } else {
          this.hasChanges = false
        }
        this.$emit('onChange', this.hasChanges)
      },
      onSave: function (c) {
        this.pending = true
        this.$validator.validateAll().then(success => {
          this.$store.state.profile.travelTime = this.resonable
          this.$store.state.profile.transportation = this.savedTransport
          this.$store.state.profile.availabilityTimes = this.savedAvailable
          this.$store.state.profile.volunteerOnline = this.volunteerOnline
          if (success) {
            if (this.isOwnProfile) {
              // @ts-ignore cannot find mixins
              this.updateOwnProfile(succ => {
                this.hasChanges = false
                // @ts-ignore cannot find mixins
                this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
                this.$emit('onSave')
                this.$router.go(-1)
              }, fail => {
                if (c > 0) {
                  this.onSave(c - 1)
                } else {
                  this.hasError = true
                }
                // @ts-ignore cannot find mixins
                this.showAlertError(this.$t('common.fetchFailed'))
              })
            } else {
              // @ts-ignore cannot find mixins
              this.updateProfile(succ => {
                this.hasChanges = false
                // @ts-ignore cannot find mixins
                this.showAlertSuccess(this.$t('userAdmin.saveSuccess'))
                this.$emit('onSave')
                window.location.href = '/profile'
              }, fail => {
                if (c > 0) {
                  this.onSave(c - 1)
                } else {
                  this.hasError = true
                }
                // @ts-ignore cannot find mixins
                this.showAlertError(this.$t('common.fetchFailed'))
              })
            }
          }
        })
      },
      onCancel: function () {
        this.$emit('onCancel')
        this.$router.go(-1)
      }
    }
  })
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.comp-profile-editor-location {
  max-width: 600px;

  .checkbox {
    color: $brand-black;
    border: 1px solid $brand-grey3;
  }

  .dropdown {
    margin: 30px auto;
    max-width: 300px;
  }

  .editor-field {
    width: calc(100% - 20px);
    max-width: 140px;
    text-align: center;
  }

  .row input {
      box-shadow: none;
      border: 2px solid $brand-grey3;
      &:focus-visible{
      color: #495057;
      border: solid 2px $brand-grey5;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  .instruct {
    font-family: "Signa-Book", Sans-Serif;
  }

  .district-change-warning {
    svg {
      margin-right: 7px;
    }
    background-color: $brand-warning;
    color:$brand-white;
    font-size: 0.8em;
    padding: 10px;
    margin-bottom: 15px;
  }
}
</style>
