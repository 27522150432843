<template>
  <div class="comp-wizard-join-return brand-white">
    <div class="swapped-elements">
      <div class="container-fluid comp-wizard-content">
        <div class="row">
          <div class="col-12">
            <span class="h1 mt-5 mb-3"
                  role="heading"
                  aria-level="1"
            >
              <span v-if="status === 'invoice'">{{ $t('join.thanksHeaderInvoice') }}</span>
              <span v-else-if="status === 'cancel'">{{ $t('join.thanksHeaderCancel') }}</span>
              <span v-else-if="status === 'success'">{{ $t('join.thanksHeader') }}</span>
              <span v-else>{{ $t('join.thanksHeaderError') }}</span>

            </span>
          </div>
        </div>

        <!-- invoice -->
        <div v-if="status == 'invoice'" class="row">
          <div class="col-12">
            <p>
              {{ $t('join.invoice') }}
            </p>
          </div>
        </div>
        <!-- success -->
        <div v-else-if="status == 'cancel'" class="row">
          <div class="col-12">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-linkified:options="{ nl2br: true }" v-html="$t('join.cancel')" />
          </div>
        </div>
        <div v-else-if="status == 'success'" />
        <!-- error -->
        <div v-else class="row">
          <div class="col-12">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-linkified:options="{ nl2br: true }" v-html="$t('join.error')" />
          </div>
        </div>

        <div v-if="showCto" class="row">
          <div class="col-12 mt-3">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-linkified:options="{ nl2br: true }" v-html="$t('join.volunteerCto')" />
          </div>
          <div class="col-12">
            <sprbutton :size="1"
                       :title="$t('join.volunteerButton')"
                       click-event="register"
                       class="mt-5"
                       icon="user-plus"
                       @register="register"
            />
          </div>
        </div>

        <!-- show for users in register-flow -->
        <div v-if="isFlow('register')" class="row">
          <div class="col-12 mt-3">
            <p>
              {{ $t('join.flowEndRegister') }}
            </p>
          </div>
        </div>

        <!-- show for users in profile-flow -->
        <div v-if="isFlow('profile')" class="row">
          <div class="col-12 mt-3">
            <p>
              {{ $t('join.flowEndProfile') }}
            </p>
          </div>
        </div>

        <div />
      </div>
    </div>
    <div class="common-elements">
      <a class="privacy-link link" href="https://www.punainenristi.fi/tietosuoja" target="_blank" rel="noopener">{{ $t('wizard.privacyPolicy') }}</a>
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'

  export default {
    name: 'JoinReturn',
    components: { sprbutton },
    props: {
      status: {
        type: String,
        default: ''
      },
      transactionId: {
        type: String,
        default: ''
      },
      flow: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
      }
    },
    computed: {
      showCto: function () {
        return this.isFlow('default') && this.transactionId && this.transactionId.length === 36
      },
      getEmail: function () {
        if (this.flow) {
          const split = this.flow.split('|')
          if (split.length === 2 && split[1].length > 0) {
            return atob(split[1])
          }
        }
        return ''
      }
    },
    mounted: function () {
      if (this.isFlow('register') && (this.status === 'invoice' || this.status === 'success')) {
        const fromQuery = (this.status === 'invoice') ? 'invoice' : 'join'

        if (this.getEmail) {
          sessionStorage.setItem('email', this.getEmail)
        }

        this.$router.push({ name: 'login', query: { message: fromQuery } })
      }
    },
    methods: {
      getCustomerUrl: function () {
        return `${this.$store.state.apiBases.paymentservice + this.$store.state.apiEndpoints.membershipcustomer[0]}`
      },
      register: function () {
        this.$router.push({ name: 'wizard', query: { cid: this.transactionId } })
      },
      isFlow: function (flow) {
        return (this.flow && (this.flow.indexOf(flow + '|') >= 0))
      }
    }
  }

</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';

  .comp-wizard-join-return {
    box-sizing: border-box;
    padding: 0 10px;
    width: 100%;

    min-height: calc(100vh - 200px);

    background: $brand-back;
    background-repeat: no-repeat;
    background-size: contain cover;
    text-align: center;
    padding-bottom: 20px;

    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr;

    -ms-grid-columns: 1fr;
    grid-template-rows: auto 10px 40px;
    -ms-grid-rows: auto 40px 180px;

    a {
        color: white;
        text-decoration: underline;
    }

    .comp-wizard-content {
      max-width: 600px;
    }

    .swapped-elements {
      grid-column: 1;
      grid-row: 1;
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    .common-elements {
      grid-column: 1;
      grid-row: 3;
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    .privacy-link {
      color: $brand-grey5;
      padding: 2px;
      margin: 18px;
      display: inline-block;
    }
  }

</style>
