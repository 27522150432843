<template>
  <div class="pooladd container">
    <backbar :title="formTitle" />
    <div v-if="group">
      <PoolAddEditNetForm
        v-if="group.netFriendship"
        :group="group"
      />
      <PoolAddEditStandardForm
        v-else
        :group="group"
      />
    </div>
    <div v-show="isLoading" class="row">
      <div class="col-12">
        <div class="loading">
          <div class="spinner">
            <loading />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { HttpResponse } from 'vue-resource/types/vue_resource'
  import { LocaleMessage } from 'vue-i18n'
  import loading from '../SPRLoading.vue'
  import backbar from '../BackBar.vue'
  import PoolAddEditStandardForm from './PoolAddEditStandardForm.vue'
  import PoolAddEditNetForm from './PoolAddEditNetForm.vue'
  import { FriendManagementDto } from '@/types'

  // the data object
  export interface TemplateComponentData {
    isLoading: boolean,
    group: FriendManagementDto | undefined
  }

  export default Vue.extend({
    name: 'PoolAdd',
    components: {
      loading,
      backbar,
      PoolAddEditStandardForm,
      PoolAddEditNetForm
    },
    props: {
      groupid: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        isLoading: true,
        group: undefined
      }
    },
    computed: {
      formTitle (): LocaleMessage | string {
        return this.$t('poolForm.addTitle')
      }
    },
    mounted () {
      this.isLoading = true
      this.loadFMG().then(x => {
        this.group = x.data
        this.isLoading = false
      }, (err: Response | any) => {
        this.displayError(err)
      })
    },
    methods: {
      loadFMG (): PromiseLike<HttpResponse> {
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.groupid)
        return this.$http.get(url)
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .pooladd {
    .loading {
      display: flex;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
      .spinner {
        margin: 30px auto;
        color:$brand-red;
      }
    }
  }
</style>