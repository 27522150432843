<template>
  <div v-if="localInterviewData" class="interviewform" :class="{ 'disabled': disabled }">
    <div class="row">
      <div class="col-12">
        <checkboxinput
          :value="localInterviewData.interviewed"
          :disabled="disabled"
          :title="$t(translationKey)"
          :aria-label="$t(translationKey)"
          @input="onInterviewedChanged"
        />
      </div>
      <div
        v-if="localInterviewData.interviewed"
        class="col-12 col-sm-6 col-md-4"
      >
        <forminput
          v-validate="'required'"
          :value="localInterviewData.interviewedBy"
          :disabled="disabled"
          :title="$t('nonIdentProfileForm.interviewedBy')"
          :is-valid="!errors.has('interviewForm.intervName')"
          name="interviewForm.intervName"
          :required="true"
          @input="onInterviewedByChanged"
        />
      </div>
      <div
        v-if="localInterviewData.interviewed"
        class="col-12 col-md-6 col-lg-4"
      >
        <simpledate
          v-validate="'required'"
          :value="localInterviewData.interviewedDate"
          name="interviewForm.interDate"
          :is-valid="!errors.has('interviewForm.interDate')"
          :disabled="disabled"
          @input="onInterviewDateChanged"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import forminput from '../SPRSimpleText.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import simpledate from '../SPRSimpleDatepicker.vue'
  import { InterviewData } from '../../types/custom'

  // the data object
  export interface TemplateComponentData {
    localInterviewData: undefined | InterviewData
  }
  export default Vue.extend({
    name: 'InterviewForm',
    components: {
      forminput,
      checkboxinput,
      simpledate
    },
    inject: ['$validator'],
    props: {
      value: {
        type: Object as () => InterviewData,
        required: true
      },
      translationKey: {
        type: String,
        default: 'nonIdentProfileForm.volunteerInterviewed'
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data (): TemplateComponentData {
      return {
        localInterviewData: undefined as undefined | InterviewData
      }
    },
    computed: {
      datePlaceholder (): string {
        return this.$store.state.language === 'sv' ? 'dd.mm.åååå' : 'pp.kk.vvvv'
      }
    },
    watch: {
      value: function (newVal: InterviewData): void {
        this.localInterviewData = newVal
      }
    },
    mounted (): void {
      if (this.value) {
        this.localInterviewData = this.value
      }
    },
    methods: {
      onInterviewedChanged (newVal: boolean): void {
        if (this.localInterviewData) {
          this.localInterviewData.interviewed = newVal
          this.update()
        }
      },
      onInterviewedByChanged (newVal: string): void {
        if (this.localInterviewData) {
          this.localInterviewData.interviewedBy = newVal
          this.update()
        }
      },
      onInterviewDateChanged (newVal: Date): void {
        if (this.localInterviewData) {
          this.localInterviewData.interviewedDate = newVal
          this.update()
        }
      },
      update (): void {
        if (this.localInterviewData?.interviewed) {
          this.$validator.validateAll().then((result: boolean) => {
            if (result) {
              this.$emit('input', this.localInterviewData)
            }
          })
        } else {
          if (this.localInterviewData) {
            this.localInterviewData.interviewedBy = ''
            this.localInterviewData.interviewedDate = undefined
            this.$emit('input', this.localInterviewData)
          }
        }
      }
    }
  })
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.interviewform {
  background-color: $brand-white;

  .subheader {
    color: $brand-red;
    text-transform: uppercase;
    font-family: $font-bold;
    margin: 0 0 10px 20px;
    display: block;
    font-size: 14px;
  }

  .sectionheader {
    margin: 15px 0;
  }
}
</style>
