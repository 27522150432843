<template>
  <div class="comp-user-search">
    <h3 v-if="primary === 'training'">
      {{ $t('friendTraining.trainingHeader') }}
    </h3>
    <div v-if="primary === 'clients' || primary === 'volunteers'">
      <checkboxlist v-model="activeClientFilters"
                    :list="clientFilters"
                    :rows="2"
      />
    </div>
    <p v-if="primary === 'brokers' && purpose === 5">
      {{ $t('userAdmin.existingBrokerRightsTitle') }}
    </p>
    <div class="search-section">
      <div class="search">
        <div class="searchbox">
          <searchbox v-model="search" />
        </div>
        <div v-if="includeAdvancedSearch || includeAdminSearch" class="advanced-search-select" @click="toggleAdvancedSearch">
          <span class="advanced-search">{{ $t('searchCriteria.sCAdvancedSearchTitle') }}</span>
          <font-awesome-icon v-if="advancedSearchVisible" icon="chevron-down" class="brand-red" aria-hidden="true" />
          <font-awesome-icon v-else icon="chevron-up" class="brand-red" aria-hidden="true" />
        </div>
      </div>
      <div v-if="(includeAdvancedSearch || includeAdminSearch) && advancedSearchVisible" class="advanced-search">
        <usersearchcriteria :v-if="advancedSearchVisible" @change="onChangedSearchCriteria" @clear="onClearSearchCriteria" />
      </div>
    </div>
    <div v-if="primary === 'user'" class="row">
      <div v-if="results.results && !loading" class="col-12">
        <pager-heading pagesizervisible
                       :total="total" :skip="skip" :take="take"
                       @pagesizechanged="onPageSizeChanged"
        ></pager-heading>
        <div v-if="messageEnabled" class="select-all">
          <div class="checkbox-all">
            <checkboxinput
              v-model="selectedAll"
              :title="$t('userAdmin.selectAll')"
              @input="onSelectAll"
            />
          </div>
          <div class="clear-all">
            <label class="selected-count">{{ selectedCount }}</label>
            <sprbutton v-if="hasSelectedVolunteers" type="primary"
                       :size="5"
                       :title="$t('userAdmin.sendVolunteerMessage')"
                       class="spr-redborder button-width selected-button"
                       click-event="navigateToSendMessage"
                       @navigateToSendMessage="navigateToSendMessage()"
            />
            <sprbutton type="primary"
                       :size="5"
                       :title="$t('userAdmin.clear')"
                       class="spr-redborder button-width selected-button"
                       click-event="clearSelected"
                       @clearSelected="clearSelected()"
            />
          </div>
        </div>
        <resultCheck v-for="(r, i) in results.results"
                     :key="'resultset_' + _uid + '_' + i + '_' + r.profileId"
                     :volunteer="r"
                     :show-checkbox="messageEnabled"
                     @selectedContact="onSelectedClick"
        />
        <div v-if="results.total === 0" class="text-center my-4">
          {{ $t('userAdmin.emptySet') }}
        </div>
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <div v-if="primary === 'roles'" class="row">
      <div v-if="results.results && !loading" class="col-12">
        <rresult v-for="(r, i) in results.results"
                 :key="'resultset_' + _uid + '_' + i + '_' + r.profileId"
                 :profile="r"
        />
        <div v-if="results.total === 0" class="text-center my-4">
          {{ $t('userAdmin.emptySet') }}
        </div>
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <div v-if="primary === 'training'">
      <div class="filter-links">
        <router-link v-for="(f, i) in filters"
                     :key="'filter_' + _uid + '_' + i"
                     :to="trainLink(f.value)"
                     class="d-inline-block m-2"
        >
          {{ f.text }}
        </router-link>
      </div>
      <div v-if="!loading">
        <fresult v-for="(r, i) in results.results"
                 :key="'resultsetf_' + _uid + '_' + i + '_' + r.profileId"
                 :volunteer="r"
                 :mode="id"
        />
        <div v-if="results.total === 0" class="text-center my-4">
          {{ $t('userAdmin.emptySet') }}
        </div>
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <!--Client search -->
    <div v-if="primary === 'clients'">
      <div v-if="results && results.items && !loading">
        <cresult v-for="(r, i) in results.items"
                 :key="'resultsetc_' + _uid + '_' + i + '_' + r.profileId"
                 :customer="r"
        />
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <!-- Friend volunteer search -->
    <div v-if="primary === 'volunteers'">
      <div v-if="results && results.items && !loading">
        <vresult v-for="(r, i) in results.items"
                 :key="'resultsetv_' + _uid + '_' + i + '_' + r.profileId"
                 :volunteer="r"
                 :is-net-profile="isNetProfile"
                 :group-id="id"
                 :typed-profile-id="getTypedProfileId(r)"
        />
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <!-- Friendship broker search -->
    <div v-if="primary === 'brokers'">
      <div v-if="results && results.results && !loading">
        <bresult v-for="(r, i) in results.results"
                 :key="'resultsetv_' + _uid + '_' + i + '_' + r.profileId"
                 :volunteer="r"
        />
      </div>
      <div v-else class="spinner my-4 col-12">
        <loading />
      </div>
    </div>

    <pager v-if="!loading"
           :take="take"
           :skip="skip"
           :total="total"
           :max-display="maxDisplay"
           of-text="/"
           :statisticsvisible="!pagerheadingvisible"
           @pagerchanged="pagerClick"
           @pagerprevious="pagerPrevious"
           @pagernext="pagerNext"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import searchbox from '../SPRSearchBox.vue'
  import pagerHeading from '../SPRPagerHeading.vue'
  import pager from '../SPRPager.vue'
  import resultCheck from './UserSimpleSearchResultCheckBox.vue'
  import fresult from './FriendSearchResult.vue'
  import cresult from './CustomerSearchResult.vue'
  import vresult from './FriendVolunteerSearchResult.vue'
  import bresult from './FriendshipBrokerSearchResult.vue'
  import rresult from './UserRolesCurrent.vue'
  import checkboxlist from '../SPRSimpleCheckboxList.vue'
  import loading from '../SPRLoading.vue'
  import { GetSectionVolunteersCriteria, ProfileState, AvailabilityTimesEnum,
           InvolvedEventCategories, FriendManagementDto, FindFriendManagementGroupVolunteersResult,
           FindFriendManagementGroupCustomersResult, FindNetFriendManagementGroupContentCriteria,
           FindStandardFriendManagementGroupContentCriteria, CustomerTypes,
           PreferredAges, FriendshipTypes, PersonalSkills, AreaOfInterest, PreferredContactMethods } from '@/types'
  import { SprCheckboxValue, FilterItem } from '@/types/custom'
  import { HttpResponse } from 'vue-resource/types/vue_resource'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import usersearchcriteria from '../sections/UserSearchCriteria.vue'
  import cache from '../../mixins/cache.js'
  import enumhelper from '../../mixins/enums.js'
  import inpututils from '../../mixins/inpututils'
  import sprbutton from '../SPRButton.vue'

  // the data object
  export interface TemplateComponentData {
    searchCriteria: GetSectionVolunteersCriteria,
    group: undefined | FriendManagementDto,
    search: string,
    take: number,
    skip: number,
    total: number,
    maxDisplay: number,
    pagerheadingvisible: boolean,
    results: FindFriendManagementGroupVolunteersResult | FindFriendManagementGroupCustomersResult | any, // todo: This should be updated for the new return types (see #3618, #3619)
    resultsAll: FindFriendManagementGroupVolunteersResult | FindFriendManagementGroupCustomersResult | any,
    totalAll: number,
    loading: boolean,
    filters: Array<SprCheckboxValue>,
    activeClientFilters: Array<number>,
    selectedAll: boolean,
    advancedSearchVisible: boolean
  }

  export default Vue.extend({
    name: 'UserSearchCheckBox',
    components: { searchbox, pager, pagerHeading, resultCheck, fresult, cresult, checkboxlist, vresult, bresult, rresult, loading, usersearchcriteria, checkboxinput, sprbutton },
    mixins: [cache, enumhelper, inpututils],
    props: {
      filter: {
        default: 'all',
        type: String
      },
      id: {
        default: '0',
        type: String
      },
      purpose: {
        default: 1,
        type: Number
      },
      primary: {
        default: 'user',
        type: String
      }
    },
    data (): TemplateComponentData {
      return {
        searchCriteria: {
          involvedEventCategories: InvolvedEventCategories.NotDefined as InvolvedEventCategories,
          profileStates: [] as ProfileState[],
          skills: [] as PersonalSkills[],
          spokenLanguages: [] as string[],
          isAgeBelow30: undefined,
          areasOfInterest: [] as AreaOfInterest[],
          availabilityTimes: [] as AvailabilityTimesEnum[],
          preferredContactMethods: 0,
          contacted: undefined,
          volunteerOnline: undefined,
          activityArea: ''
        } as GetSectionVolunteersCriteria,
        group: undefined,
        search: '',
        take: 5,
        skip: 0,
        total: 0,
        maxDisplay: 5,
        results: {},
        resultsAll: {},
        totalAll: 0,
        loading: false,
        pagerheadingvisible: false,
        advancedSearchVisible: false,
        filters: [
          { text: this.$t('friendTraining.noCert').toString(), value: 1 },
          { text: this.$t('friendTraining.hasCert').toString(), value: 2 }
        ],
        activeClientFilters: [],
        selectedAll: false
      }
    },
    computed: {
      messageEnabled (): boolean {
        return this.filter === 'section' || this.filter === 'district'
      },
      selectedCount (): string {
        return this.$t('userAdmin.selected') + ': ' + this.$store.state.selectedContact.length.toString()
      },
      includeAdvancedSearch (): boolean {
        if (this.id && this.filter === 'section' && this.primary === 'user') { return true }
        return false
      },
      includeAdminSearch (): boolean {
        if (this.id && this.filter === 'district' && this.primary === 'user') { return true }
        return false
      },
      clientFilters (): Array<SprCheckboxValue> {
        const self = this
        return _.map(this.$store.state.customerStates, function (val, key) {
          return { value: val.id, text: self.$t('customerStates.' + val.text), selected: false } as SprCheckboxValue
        })
      },
      isNetProfile (): boolean {
        return Boolean(this.group?.netFriendship)
      },
      hasSelectedVolunteers (): boolean {
        return this.$store.state.selectedContact.length > 0
      }
    },
    watch: {
      searchCriteria () {
        this.performSearch()
      },
      search: function (val) {
        this.skip = 0
        this.performSearch()
      },
      activeClientFilters: {
        handler: function (val) {
          this.skip = 0
          this.performSearch()
        },
        deep: true
      }
    },
    created () {
      this.pagerheadingvisible = this.primary === 'user'
    },
    mounted (): void {
      if (this.filter === 'friendship') {
        this.getGroup()
          .then((result: HttpResponse) => {
            this.group = result.data
            this.$bus.$on('user-search-research', this.performSearch)
            this.performSearch()
          })
          .catch((err: any) => {
            // @ts-ignore
            console.log(err)
            // @ts-ignore
            this.showAlertError(this.$t('common.fetchFailed'))
          })
      } else {
        this.performSearch()
      }
    },
    beforeDestroy: function () {
      this.$bus.$off('user-search-research')
    },
    methods: {
      onClearSearchCriteria (): void {
        this.search = ''
        this.onChangedSearchCriteria({
          involvedEventCategories: InvolvedEventCategories.NotDefined as InvolvedEventCategories,
          profileStates: [] as ProfileState[],
          skills: [] as PersonalSkills[],
          spokenLanguages: [] as string[],
          isAgeBelow30: undefined,
          areasOfInterest: [] as AreaOfInterest[],
          availabilityTimes: [] as AvailabilityTimesEnum[],
          preferredContactMethods: 0,
          contacted: undefined,
          volunteerOnline: undefined,
          activityArea: ''

        })
      },
      navigateToSendMessage () {
        this.$router.push(`/admin/${this.filter}/${this.id}/messages`)
      },
      clearSelected () {
        this.loading = true
        this.selectedAll = false
        this.$store.state.selectedContact = []
        this.$store.state.selectedVolunteers = []
        const params = {
           skip: this.skip,
           take: this.take,
           areasOfInterest: [], // areasOfInterest: AreaOfInterest[];
           availabilityTimes: [], // availabilityTimes: AvailabilityTimesEnum[];
           contacted: undefined,
           involvedEventCategories: 0, // involvedEventCategories: InvolvedEventCategories;
           isAgeBelow30: undefined,
           preferredContactMethods: undefined,
           profileStates: [], // profileStates: ProfileState[];
           searchWord: '', //searchWord: string;
           sectionId: Number(this.id), // sectionId: number;
           districtId: Number(this.id),
           skills: [], // skills: PersonalSkills[];
           spokenLanguages: [], // spokenLanguages: string[];
           volunteerOnline: undefined,
           activityArea: ''
         } as GetSectionVolunteersCriteria

        const url = this.url()
        if (this.filter === 'district' || this.filter === 'section') {
          this.$http.post(url, params).then(s => {
            this.results = s.data
            this.total = this.results.total
            this.loading = false
          })
        } else {
          this.$http.get(url, params).then(s => {
            this.results = s.data
            this.total = this.results.total
            this.loading = false
          })
        }
      },
      onSelectAll () {
        if (this.selectedAll === true) {
          this.loading = true
          const params = {
            skip: 0,
            take: 10000,
            areasOfInterest: this.searchCriteria.areasOfInterest, // areasOfInterest: AreaOfInterest[];
            availabilityTimes: this.searchCriteria.availabilityTimes, // availabilityTimes: AvailabilityTimesEnum[];
            contacted: this.searchCriteria.contacted,
            involvedEventCategories: this.searchCriteria.involvedEventCategories, // involvedEventCategories: InvolvedEventCategories;
            isAgeBelow30: this.searchCriteria.isAgeBelow30,
            preferredContactMethods: this.searchCriteria.preferredContactMethods,
            profileStates: this.searchCriteria.profileStates, // profileStates: ProfileState[];
            searchWord: this.search, //searchWord: string;
            sectionId: Number(this.id), // sectionId: number;
            districtId: Number(this.id),
            skills: this.searchCriteria.skills, // skills: PersonalSkills[];
            spokenLanguages: this.searchCriteria.spokenLanguages, // spokenLanguages: string[];
            volunteerOnline: this.searchCriteria.volunteerOnline,
            activityArea: this.searchCriteria.activityArea
          } as GetSectionVolunteersCriteria

          const url = this.url()
          if (this.primary === 'user' && this.id && (this.filter === 'section' || this.filter === 'district')) {
            this.$http.post(url, params).then(s => {
              this.resultsAll = s.data
              this.totalAll = this.resultsAll.total
              this.$store.state.selectedVolunteers = this.resultsAll.results
              for (var result of this.resultsAll.results) {
                if (!this.$store.state.selectedContact.includes(result.email)) {
                  this.$store.state.selectedContact.push(result.email)
                }
              }
            })
          } else {
            this.$http.get(url, params).then(s => {
              this.resultsAll = s.data
              this.totalAll = this.resultsAll.total

              this.$store.state.selectedVolunteers = this.resultsAll.results
              for (var result of this.resultsAll.results) {
                if (!this.$store.state.selectedContact.includes(result.email)) {
                  this.$store.state.selectedContact.push(result.email)
                }
              }
            })
          }
          this.loading = false
        } else {
          this.$store.state.selectedContact = []
          this.$store.state.selectedVolunteers = []
        }
      },
      onChangedSearchCriteria (searchCriteria): void {
        if (JSON.stringify(this.searchCriteria) !== JSON.stringify(searchCriteria)) {
          this.searchCriteria = searchCriteria
          this.skip = 0
        }
      },
      onSelectedClick (val) {
        if (this.$store.state.selectedContact.includes(val)) {
          this.$store.state.selectedContact = this.$store.state.selectedContact.filter(item => item !== val)
          this.$store.state.selectedVolunteers = this.$store.state.selectedVolunteers.filter(item => item.email !== val)
        } else {
          const volunteer = this.results.results.find((t) => {
            return t.email === val
          })
          this.$store.state.selectedVolunteers.push(volunteer)
          this.$store.state.selectedContact.push(val)
        }
      },
      toggleAdvancedSearch (): void {
        this.advancedSearchVisible = !this.advancedSearchVisible
      },
      onPageSizeChanged (pageSize): void {
        this.take = pageSize
        this.performSearch()
      },
      getTypedProfileId (volunteer): string {
        if (this.isNetProfile === true) {
          return volunteer.friendInfo.netProfile.id
        }
        return volunteer.friendInfo.standardProfile.id
      },
      trainLink (newState): string {
        return '/admin/training/' + newState
      },
      pagerClick (val): void {
        this.skip = val.skip
        this.performSearch()
      },
      pagerPrevious (): void {
        this.skip -= this.take
        this.performSearch()
      },
      pagerNext (): void {
        this.skip += this.take
        this.performSearch()
      },
      getGroup (): Promise<HttpResponse> {
        const url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', this.id)
        return this.$http.get(url) as Promise<HttpResponse>
      },
      performSearch (): void {
        this.loading = true
        if (this.skip < 0) this.skip = 0

        if (this.primary === 'training' || this.primary === 'user' || this.primary === 'roles') {
          // check if section is defined, if it is, use advanced search to collect the volunteers
          if (this.id && (this.filter === 'section' || this.filter === 'district') && this.primary === 'user') {
            const params = {
            skip: this.skip,
            take: this.take,
            areasOfInterest: this.searchCriteria.areasOfInterest, // areasOfInterest: AreaOfInterest[];
            availabilityTimes: this.searchCriteria.availabilityTimes, // availabilityTimes: AvailabilityTimesEnum[];
            contacted: this.searchCriteria.contacted,
            involvedEventCategories: this.searchCriteria.involvedEventCategories, // involvedEventCategories: InvolvedEventCategories;
            isAgeBelow30: this.searchCriteria.isAgeBelow30,
            preferredContactMethods: this.searchCriteria.preferredContactMethods,
            profileStates: this.searchCriteria.profileStates, // profileStates: ProfileState[];
            searchWord: this.search, //searchWord: string;
            sectionId: Number(this.id), // sectionId: number;
            districtId: Number(this.id),
            skills: this.searchCriteria.skills, // skills: PersonalSkills[];
            spokenLanguages: this.searchCriteria.spokenLanguages, // spokenLanguages: string[];
            volunteerOnline: this.searchCriteria.volunteerOnline,
            activityArea: this.searchCriteria.activityArea
          } as GetSectionVolunteersCriteria
            const url = this.url()
            this.$http.post(url, params).then(s => {
              this.results = s.data
              this.total = this.results.total
              this.loading = false
            })
          } else {
            const url = this.url()
            this.$http.get(url).then(s => {
              this.results = s.data
              this.total = this.results.total
              this.loading = false
            })
          }
        } else if (this.primary === 'brokers') {
          const url = this.group?.netFriendship
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationFindNetBrokers[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationFindStandardBrokers[0]

          const dto = {
            search: this.search,
            friendManagementGroupId: Number(this.id),
            potentialBrokersOnly: false,
            skip: this.skip,
            take: this.take
          }
          this.$http.post(url, dto).then(s => {
            this.results = s.data
            this.total = this.results.total
            this.loading = false
          })
        } else if (this.primary === 'volunteers') {
          const params = {
            skip: this.skip,
            take: this.take,
            searchString: this.search,
            orderByNew: false,
            ages: PreferredAges.NotDefined,
            customerTypes: CustomerTypes.NotDefined,
            friendshipTypes: FriendshipTypes.NotDefined,
            customerStates: this.activeClientFilters,
            languages: [],
            natives: [],
            includeFriendPools: false,
            includeFriendships: false,
            groupId: Number(this.id)
          } as FindStandardFriendManagementGroupContentCriteria

          const url = this.group?.netFriendship
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteersearchnet[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteersearchstandard[0]

          this.$http.post(url, params).then(s => {
            this.results = s.data as FindFriendManagementGroupVolunteersResult
            this.total = this.results.total
            this.loading = false
          })
        } else if (this.primary === 'clients') {
          const clientdto = {} as FindNetFriendManagementGroupContentCriteria | FindStandardFriendManagementGroupContentCriteria
          clientdto.skip = this.skip
          clientdto.take = this.take
          clientdto.searchString = this.search
          clientdto.customerStates = this.activeClientFilters
          clientdto.groupId = Number(this.id)
          clientdto.includeFriendPools = false
          clientdto.includeFriendships = false

          const uri = this.group?.netFriendship
            ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customersearchnet[0]
            : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customersearchstandard[0]

          this.$http.post(uri, clientdto).then(s => {
            this.results = s.data as FindFriendManagementGroupCustomersResult
            this.total = this.results.total
            this.loading = false
          })
        }
      },
      url: function () {
        if (this.primary === 'training') {
          return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findbyfriendship[0]) // returns SearchResult<PublicProfileDto>
            .replace('{state}', this.id)
            .replace('{skip}', this.skip)
            .replace('{take}', this.take)
            .replace('{search}', this.search)
        } else if (this.primary === 'clients') {
          // return (this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customersimplesearch[0]) // to be updated - update the TemplateComponentData (data) typescript type for results to new one)
        } else if (this.primary === 'volunteers') {
          // return (this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.volunteersimplesearch[0]) // to be updated - update the TemplateComponentData (data) typescript type for results to new one)
        } else if (this.primary === 'user') {
          if (this.id && (this.filter === 'section')) {
            return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findsectionvolunteers[0])
          } else if (this.id && this.filter === 'district') {
            return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.finddistrictvolunteers[0])
          } else {
            return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findSimpleIdentOnly[0]) // Returns SearchResult<SimpleUserProfileDto> to be updated - update the TemplateComponentData (data) typescript type for results to new one)
              .replace('{from}', this.filter)
              .replace('{id}', Number(this.id))
              .replace('{skip}', this.skip)
              .replace('{take}', this.take)
              .replace('{search}', this.search)
          }
        }

        return (this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.findIdentOnly[0]) // This does not seem to be used. I cannot replicate this.
          .replace('{from}', this.filter)
          .replace('{id}', this.id)
          .replace('{purpose}', this.purpose)
          .replace('{skip}', this.skip)
          .replace('{take}', this.take)
          .replace('{search}', this.search)
      }
    }
  })
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .selected-button {
    margin-top: 5px;
  }
  .selected-count {
    margin-right: 9px;
  }
  .clear-all {
    margin-left: auto;
  }
  .advanced-search{
    font-family: "Signa-Light", Sans-Serif;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.3px;
    margin-right: 20px;
  }

  .search-section {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
  }
  .search {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
  }
  .searchbox {
        flex: 1 0 auto;
  }
  .advanced-search {
    display: inline-block;
    padding: 10px;
    font-size: 18px;
    text-decoration: none;
    font-family: "Signa", Sans-Serif;
    color: #747473;
    transition: color 0.33s;
  }
  .advanced-search-select{
    flex: 0 0 auto;
    align-self: center;
    margin-left: 20px;
     cursor: pointer;

    :hover {
      color: #000000;
      transition: color 0.33s;
    }
  }
  .comp-user-search {
    p {
      font-family: $font-bold;
    }
    .filter-links {
      a {
        text-transform: uppercase;
        font-weight: bold;
        color: $brand-grey7 !important;

        &.router-link-active {
          color: $brand-red !important;
        }
      }
    }

    .spinner {
      color: $brand-red;
      text-align: center;
    }

    .actions {
      padding-top: 7px;
    }
  }

  .checkbox-all {
      margin-top: 12px;
  }

  .select-all {
    display: flex;
    flex: 1 0 0;
    font-family: Verdana, sans-serif; // Because Signa-Light numbers jump all over the place!
    margin-top: 10px;
    margin-right: -15px;
    margin-left: -15px;
    background: $brand-white;
    align-content: center;
    height: 3rem;
    padding-left: 15px;
    padding-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
</style>
