<template>
  <div class="external-form">
    <div v-if="(!loading && externalFormsAvailable) || isAddFormVisible">
      <ul>
        <li v-for="row in settingsData" :key="row.name">
          <SettingsRow
            :name="row.name"
            :value="row.type === 'Date' ? formatDate(row.value) : row.value"
            :type="row.type"
          />
        </li>
      </ul>
    </div>
    <div v-else-if="loading" class="loading text-center brand-red">
      <div class="my-2">
        <loading />
      </div>
      <div class="my-3">
        <p>{{ $t('userAdmin.loading') }}</p>
      </div>
    </div>
    <div v-else class="loading text-center brand-red">
      <div class="my-3">
        {{ $t('userAdmin.externalFormsNotAvailable') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import moment from 'moment'
  import loading from '../SPRLoading.vue'
  import SettingsRow from './SettingRow.vue'
  import { OmaSettingsDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    settingsData: OmaSettingsDto[],
    isAddFormVisible: boolean,
    isEditFormVisible: boolean,
    loading: boolean
  }

  export default Vue.extend({
    name: 'Settings',
    components: { loading, SettingsRow },
    data (): TemplateComponentData {
      return {
        settingsData: [] as OmaSettingsDto[],
        isAddFormVisible: false,
        isEditFormVisible: false,
        loading: false
      }
    },
    computed: {
      externalFormsAvailable (): boolean {
        return this.settingsData && this.settingsData.length > 0
      }
    },
    created () {
      this.settingsData = this.$store.state.omaSettings as OmaSettingsDto[]
    },
    methods: {
      showAddForm () {
        this.isAddFormVisible = true
      },
      cancelRow () {
        this.isAddFormVisible = false
        this.isEditFormVisible = false
      },
      formatDate (dateString: string): string {
        return moment(dateString).format('DD.MM.YYYY')
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  .external-form {
    background: $brand-white;
    padding: 30px 15px;

    .create-row {
      margin-top: 30px;
    }
  }

  ul {
    list-style-type: none;
    padding: 0; /* Optional: to remove default padding */
    margin: 0;  /* Optional: to remove default margin */
  }
</style>
