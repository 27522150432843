<template>
  <div class="row comp-search-result my-2">
    <div class="col-12 grid-row">
      <div class="grid-name">
        {{ customer.firstName }} {{ customer.lastName }}
      </div>
      <div v-if="!pending" class="grid-zip">
        <span v-if="isDeleteable">
          <span class="action-link" tabindex="0" @click="deleteCustomer">
            {{ $t('userAdmin.deleteCustomer') }}
          </span>
        </span>
      </div>
      <div v-else class="griz-zip text-right">
        <span class="mt-1"><loading /></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import loading from '../SPRLoading.vue'
  import { CustomerState, FriendCustomerFindDto, FriendManagementDto } from '@/types'
  import { HttpResponse } from 'vue-resource/types/vue_resource'

  // the data object
  export interface TemplateComponentData {
    selected: boolean,
    pending: boolean
  }

  export default Vue.extend({
    name: 'CustomerSearchResult',
    components: { loading },
    props: {
      customer: {
        type: Object as () => FriendCustomerFindDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        selected: false,
        pending: false
      }
    },
    computed: {
      isDeleteable (): boolean {
        return this.customer.state === CustomerState.NonActive
      }
    },
    methods: {
      deleteCustomer (): void {
        if (confirm(this.$t('userAdmin.confirmCustomerDelete').toString())) {
          this.getGroup(this.customer.friendManagementGroupId).then((gp: FriendManagementDto) => {
            var uri = gp.netFriendship
              ? this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customerdeletenet[0]
              : this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customerdeletestandard[0]

            uri = uri
              .replace('{customerid}', this.customer.id)
              .replace('{groupid}', this.customer.friendManagementGroupId)

            this.$http.delete(uri).then(s => {
              this.$bus.$emit('user-search-research')
            })
          }, f => {
            // @ts-ignore Doesnt pick up mixins
            this.showAlertError(this.$t('common.fetchFailed'))
          })
        }
      },
      getGroup (groupid: number): PromiseLike<FriendManagementDto> {
        return new Promise((resolve, reject) => {
          var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.friendOrganizationById[0].replace('{0}', groupid)
          this.$http.get(url).then((x: HttpResponse) => {
            resolve(x.data)
          }, () => reject(new Error('Failed to get group')))
        })
      }
    }
  })
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
  .comp-search-result {
    background: $brand-white;
    border: 2px solid $brand-white;

    &:hover {
      border: 2px solid $brand-red;
    }

    .portrait-size {
      height: 40px;
      width: 40px;
      margin: 10px;
    }

    .grid-row {
      display: grid;
      display: -ms-grid;
      grid-template-rows: 60px;
      -ms-grid-rows: 60px;
      grid-template-columns:1fr 120px;
      -ms-grid-columns: 1fr 120px;
    }

    .grid-name {
      grid-column: 1;
      -ms-grid-column: 1;
      line-height: 60px;

      font-size: 13px;
      letter-spacing: 0.3px;
      font-weight: 700;
      color: $brand-red;
      text-transform: uppercase;
      font-family: "Signa-Light", Sans-Serif;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
    }

    .grid-zip {
      grid-column: 3;
      -ms-grid-column: 3;
      text-align: right;
      line-height: 60px;

      span {
        line-height: 17px;
        overflow-wrap: break-word;
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
      }
    }

    .action-link {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
