<template>
  <div :is="selectFrontPage" />
</template>

<script>
  import anonfront from './FrontAnon.vue'
  import registeredfront from './FrontRegistered.vue'

  export default {
    name: 'Front',
    components: {
      anonfront, registeredfront
    },
    data () {
      return {
        frontPages: [anonfront, registeredfront]
      }
    },
    computed: {
      selectFrontPage: function () {
        return this.frontPages[this.$store.state.profile ? 1 : 0]
      }
    }
  }
</script>
