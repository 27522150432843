<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .useradmingroupview {
    .isall {
      display:inline-block;
      margin-right: 5px;
    }
    .group-leader {
      margin-bottom: 10px;
    }

    .group-name {
      .disabled {
        color:$brand-grey4;
      }
    }

    .noclick {
      cursor: not-allowed;
    }
  }
</style>
<template>
  <div class="row useradmingroupview">
    <div class="column col-md-6 col-sm-12 group-name">
      <span v-if="group.isAllLocations" class="isall" :title="$t('groups.allLocations')"><font-awesome-icon icon="globe" /></span>
      <router-link v-if="groupLink" :to="groupLink">
        <span :class="{'disabled':!group.isActive}" :title="groupActive">{{ group.name }}</span>
      </router-link>
      <span v-else :title="$t('groups.disabledToNonAdmins')">
        <span class="disabled noclick">{{ group.name }}</span>
      </span> ({{ group.totalMembers }})
      <div>{{ category }}</div>
      <div class="smalltext">
        {{ languages }}
      </div>
    </div>
    <div class="column col-md-6 col-sm-12 group-leader">
      {{ groupAdmins }}
    </div>
  </div>
</template>

<script>
  import enumhelper from '../../mixins/enums.js'

  export default {
    name: 'Useradmingroupview',
    components: { },
    mixins: [enumhelper],
    props: {
      group: {
        type: Object,
        default: null
      }
    },
    computed: {
      languages () {
        return this.getLanguagesMaskTranslation(this.group.languages)
      },
      category () {
        return this.getEventCategoriesTranslation(this.group.category)
      },
      groupActive: function () {
        return (this.group && this.group.isActive) ? this.$t('userAdmin.groupStatusActive') : this.$t('userAdmin.groupStatusInactive')
      },
      groupAdmins () {
        var adminStr = ''
        _.each(this.group.members, (val) => {
          adminStr += val.isAdmin ? `${val.firstName} ${val.lastName}, ` : ''
        })

        return adminStr.length > 0 ? adminStr.substring(0, adminStr.length - 2) : ''
      },
      groupLink: function () {
        if (this.group.isActive || this.hasRole('Admin') || this.hasRole('NationalAdmin') || this.hasRole('NationalEmployee')) {
          return '/admin/group/' + this.group.id
        }
        return null
      }
    }
  }
</script>
