<template>
  <div class="comp-master-user-list">
    <div class="row p-2">
      <div class="col col-sm-12">
        <div class="list-group">
          <div class="row">
            <div class="col-12">
              <h4>{{ $t('userAdmin.inactiveGroups') }}</h4>
            </div>
          </div>
          <div v-for="(item, index) in inActive" :key="item.id + '_group_' + _uid + '_' + index" class="group-row">
            <useradmingroupview :group="item" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import useradmingroupview from './GroupView.vue'

  export default {
    name: 'UseradminGroupsMasterlist',
    components: { useradmingroupview },
    data: function () {
      return {
        inActive: [],
        isLoading: false
      }
    },
    mounted: function () {
      this.loadGroups()
    },
    methods: {
      loadGroups: function () {
        this.isLoading = true
        var url = `${this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupsall[0]}`
        this.$http.get(url).then(success => {
          var groups = success.body

          this.inActive = _.filter(groups, (group) => {
            return !group.isActive
          })
          this.isLoading = false
        })
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .comp-master-user-list {
    background: $brand-white;
  }
</style>
