<template>
  <div class="volunteermatchview" :class="cssClass">
    <div class="row">
      <div class="col-8 col-xl-5">
        <div class="friendcontainer">
          <div class="friendimage">
            <friendicon :image="match.match.contactImage" :friendname="match.match.firstName" :state="friendshipState" :multiple-friendships="match.match.multipleVolunteers" />
          </div>
          <div class="friendinfo">
            <div class="friendname">
              <a href="javascript:void(0)" @click="onCustomerSelected">
                {{ match.match.firstName }} {{ match.match.lastName }}
              </a>
            </div>
            <div class="frienddetails">
              <div class="age">
                {{ genderAgeText }}
              </div>
              <div class="area">
                {{ suburbzip }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-xl-block col-xl-4">
        <div class="description-container">
          <div v-show="match.match.shortDescription" class="description">
            &#8220;{{ match.match.shortDescription }}&#8221;
          </div>
        </div>
      </div>
      <div class="col-4 col-xl-3 starblock">
        <div class="volunteercontainer justify-content-right">
          <div v-if="match.isInappropriateMatch" class="inappropriatelabel">
            {{ $t('customerSearch.inappropriate') }}
          </div>
          <stars v-else :score="match.score" :is-net="isNetItem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import friendicon from './FriendIcon.vue'
  import stars from '../SPRStars.vue'
  import datehelper from '../../mixins/dates.js'
  import enumhelper from '../../mixins/enums.js'
  import { MatchItemNetCustomerDto, MatchItemStandardCustomerDto, CustomerState } from '../../types/index'

  export default Vue.extend({
    name: 'Volunteermatchview',
    components: { friendicon, stars },
    mixins: [enumhelper, datehelper],
    props: {
      match: {
        type: Object as () => MatchItemNetCustomerDto | MatchItemStandardCustomerDto,
        required: true
      }
    },
    computed: {
      cssClass (): string {
        return this.match.isInappropriateMatch ? 'inappropriate' : ''
      },
      isNetItem (): boolean {
        return this.isMatchItemNetVolunteer(this.match)
      },
      computedGender (): string | LocaleMessage {
        // @ts-ignore (Cant find mixins)
        return this.getGenderTranslation(this.match.match.gender)
      },
      computedAge (): number {
        // @ts-ignore (Cant find mixins)
        return this.calculateAge(this.match.match.birthDate)
      },
      genderAgeText (): string {
        return [this.computedGender, this.computedAge ? `${this.computedAge} ${this.$t('customerSearch.years')}` : ''].filter(Boolean).join(', ')
      },
      friendshipState (): CustomerState {
        return this.match.match.state
      },
      suburbzip (): string {
        return `${this.match.match.zipCode} ${this.match.match.city}`
      }
    },
    methods: {
      onCustomerSelected (): void {
        this.$emit('customerSelected', this.match.match)
      },
      isMatchItemNetVolunteer (match: MatchItemNetCustomerDto | MatchItemStandardCustomerDto): match is MatchItemNetCustomerDto {
        return (match as MatchItemNetCustomerDto).match.customerProfileNetId !== undefined
      }
    }
  })
</script>
<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .volunteermatchview {
    background-color: $brand-white;
    border-left: solid 5px $customer-color-strong;
    margin-bottom: 10px;
    padding: 10px;

    .starblock {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .volunteercontainer {
      .identuser {
        position: absolute;
        right: 20px;
        color: $brand-red;
      }
      .volunteerimage {
        float: right;
        margin-left: 5px;
      }
      .summary {
        font-size: 13px;
        display: inline-block;
        text-align: right;
        .volunteername {
          text-transform: uppercase;
          color: $brand-red;
          font-weight: bold;
          display: block;
        }
      }
      .novolunteer {
        text-align: right;
        font-size: 13px;
      }
    }

    .description-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .description {
        color: $brand-red;
        font-style: italic;
        font-size: 13px;
        text-align: center;
        align-self: center;
      }
    }

    .friendcontainer {
      display: flex;
      align-items: center;
      .friendinfo {
        align-items: center;
        font-size: 13px;
        display: inline-block;
        text-align: left;
        .friendname {
          display: block;
          a {
            color: $brand-red;
            text-transform: uppercase;
            font-weight: bold;
          }
        }
      }
      .friendimage {
        align-items: center;
        display: inline-block;
        margin-right: 5px;
      }
    }

    &.inappropriate {
      color: $brand-grey6;
      background-color: $brand-grey3;

      .friendinfo {
        .friendname a {
          color: $brand-grey8;
        }
      }

      .friendimage .friendicon {
        background-color: $brand-grey6;
      }

      .inappropriatelabel {
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        color: $brand-grey8;
        text-align: center;
      }
    }
  }
</style>