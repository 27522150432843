<template>
  <div class="row comp-useradmin-delete-or-anomize">
    <div class="col-12 cl-md-6">
      <sprbutton type="primary"
                 :title="$t('adminUserEditor.removeUser')"
                 :size="1"
                 click-event="deleteOrAnomize"
                 @deleteOrAnomize="initiateDeleteOrAnonymize"
      />
    </div>
  </div>
</template>

<script>
  import sprbutton from '../SPRButton.vue'

  export default {
    name: 'DeleteUser',
    components: { sprbutton },
    methods: {
      initiateDeleteOrAnonymize: function () {
        this.$emit('initiate-delete-or-anonymize')
      }
    }
  }
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
</style>
