<style lang="scss">
  @import '~@/assets/scss/_variables.scss';
  .group.add {

    .nav-header  {
      margin-bottom: 30px;

      h3 {
        color: $brand-red;
      }
    }

    .results {
      margin-top: 20px;
    }
    .spr-searchbox {
      border: 1px solid $brand-grey4;
    }

    .button-row {
      margin-bottom: 40px;
      .spr-button {
        background: $brand-red;
        color: #fff;
      }
    }
    .spr-searchbox {
      margin-bottom: 20px;
    }
    .list-group {
      margin-bottom: 40px;
    }

    .results-row {
      .loading {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background-color:rgba(255, 255, 255, 0.8);

        .spinner {
          margin: auto;
          color:$brand-red;
        }
      }
      .user-row {
        background: #fff;
        margin-bottom: 5px;
        padding: 15px;
        border-bottom: 1px solid $brand-grey2;
        .user {
          .user-img {
            float: left;
            margin-right: 15px;
          }
          a {
            color: $brand-red;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
        .portrait-size {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
</style>

<template>
  <div class="group add col-12">
    <backbar :title="$t('groups.addVolunteer')" />
    <div class="container">
      <div v-if="group" class="form-container">
        <h4>{{ $t('groups.addPendingVolunteer') }}</h4>
        <groupmemberpending :group="group" />
      </div>
    </div>
    <div class="container results">
      <div v-if="group" class="form-container">
        <h4>{{ $t('groups.addMember') }}</h4>
        <groupmemberadd :group="group" />
      </div>
    </div>
  </div>
</template>

<script>
  import groupmemberadd from './GroupMemberAdd.vue'
  import groupmemberpending from './GroupMemberPending.vue'
  import backbar from '../BackBar.vue'

  export default {
    name: 'Newvolunteerform',
    components: { groupmemberadd, groupmemberpending, backbar },
    data () {
      return {
        group: {}
      }
    },
    mounted () {
      this.loadGroup()
      this.$bus.$on('loadGroup', this.loadGroup)
    },
    beforeDestroy: function () {
      this.$bus.$off('loadGroup')
    },
    methods: {
      getDto (group) {
        // group saving needs a simple group dto, not the detailed group
        return {
          id: group.id,
          name: group.name,
          description: group.description,
          groupType: 2,
          members: _.map(group.members, function (item) {
            return item.id
          }),
          isAllLocations: group.isAllLocations
        }
      },
      onSaveGroup () {
        var self = this

        var saveUrl = `${self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupupdate[0]}`
        this.$http.put(saveUrl, this.getDto(this.group)).then((resp) => {
          self.$router.push(`/groups/group/${self.group.id}`)
        })
      },
      loadGroup () {
        var self = this

        var groupId = Number(this.$route.params.groupid)
        var url = `${self.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.groupgetbyid[0]}?id=${groupId}`
        this.$http.get(url).then((resp) => {
          self.group = resp.body
        })
      }
    }
  }
</script>
