<template>
  <div class="spr-private-notes pb-2">
    <!--<label v-if="title" :for="_uid" class="cap-label">{{ title }}</label>-->
    <textarea :id="_uid" v-model="privateNotes" class="smalltext form-control" :disabled="isSending" @input="handleInput" />
    <div class="pn-buttons">
      <button class="smalltext" :disabled="isNotChanged || isSending" @click="saveChanges">
        <font-awesome-icon :icon="['far', 'save']" aria-hidden="true" />
        {{ $t('profile.save') }}
      </button>
      <button class="smalltext" :disabled="isNotChanged || isSending" @click="revertChanges">
        <font-awesome-icon icon="times" aria-hidden="true" />
        {{ $t('profile.cancel') }}
      </button>
    </div>
    <div v-if="isPostError" class="posterror">
      {{ $t('profile.saveFailed') }}
    </div>
    <div v-if="isSending" class="loading">
      <loading class="spinner" />
    </div>
  </div>
</template>

<script>
  import loading from './SPRLoading.vue'
  export default {
    name: 'SprPrivateNotes',
    components: { loading },
    props: {
      isCustomer: {
        type: Boolean,
        default: false
      },
      // title: String,
      value: {
        type: Object,
        required: true
      },
      endpoint: {
        type: String,
        required: true
      },
      isNet: {
        type: Boolean,
        default: false
      },
      friendManagementGroupId: {
        type: Number,
        default: -1
      },
      targetId: {
        type: Number,
        default: -1
      }
    },
    data () {
      return {
        privateNotes0: '',
        privateNotes: '',
        isSending: false,
        isPostError: false
      }
    },
    computed: {
      isNotChanged: function () {
        return this.privateNotes === this.privateNotes0
      },
      addNoteCommand: function () {
        return { groupId: this.friendManagementGroupId, targetId: this.targetId, note: this.privateNotes }
      }
    },
    watch: {
      value: {
        immediate: true,
        handler: function () {
          if (this.isCustomer) {
            this.privateNotes0 = this.value.privateNotes ? '\n-------------------------------------\n' + this.value.privateNotes : ''
          } else {
            if (this.isNet && this.value.netProfile) {
              this.privateNotes0 = this.value.netProfile.privateNotes ? '\n-------------------------------------\n' + this.value.netProfile.privateNotes : ''
            } else if (!this.isNet && this.value.standardProfile) {
              this.privateNotes0 = this.value.standardProfile.privateNotes ? '\n-------------------------------------\n' + this.value.standardProfile.privateNotes : ''
            }
          }
          this.revertChanges()
        }
      }
    },
    methods: {
      handleInput () {
        var txtArea = this.$el.querySelector('textarea')
        txtArea.style.height = 'auto'
        txtArea.style.height = txtArea.scrollHeight + 'px'
      },
      saveChanges () {
        this.isPostError = false
        this.$validator.validateAll().then(result => {
          if (result) {
            this.isSending = true
            var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints[this.endpoint][0]
            this.value.privateNotes = String(this.privateNotes)
            this.$http.put(url, this.addNoteCommand).then(response => {
              this.isSending = false
              if (response.ok && !isNaN(response.body) && Number(response.body) !== -1) {
                this.privateNotes0 = String(this.privateNotes)
              } else {
                this.isSending = false
                this.isPostError = true
                console.log('Error saving')
              }
            }, response => {
              this.isSending = false
              this.isPostError = true
              console.log('Error saving')
            })
          } else {
            this.isSending = false
            this.firstError()
          }
        })
      },
      revertChanges () {
        this.privateNotes = String(this.privateNotes0)
        var vm = this
        setTimeout(function () {
          var txtArea = vm.$el.querySelector('textarea')
          txtArea.focus()
          txtArea.selectionEnd = txtArea.selectionStart = 0
          vm.handleInput()
        }, 0)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .spr-private-notes {
    margin-top: 8px;
    border-radius: 20px;
    border:solid 2px $brand-red;
    label {
      color:$brand-red;
      padding: 0 20px 10px;
    }
    textarea {
      border:none;
      box-shadow: none;
      background: transparent;
      overflow-y: hidden;
    }
    .pn-buttons {
      margin-top: 8px;
      border-top: 1px solid $brand-grey3;
      padding: 9px 10px 0;
      text-align: right;

      button {
        border: none;
        background: none;
        color: $brand-red;
        cursor: pointer;
        &:disabled {
          color: $brand-grey4 !important;
          cursor:not-allowed;
        }
      }
    }

    .posterror {
      text-align: center;
      color: $brand-red;
      font-size: 13px;
    }

    .loading {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color:rgba(255, 255, 255, 0.8);

      .spinner {
        padding-top: 26px;
        margin: auto;
        color:$brand-red;
      }
    }
  }
</style>
