<template>
  <div v-show="messages.length > 0" class="success-message">
    <span class="fa-stack fa-lg success-icon">
      <font-awesome-icon :icon="['far', 'smile']" class="anne-sized" aria-hidden="true" />
    </span>
    <span class="success-container fill-height">
      <p v-for="(msg, index) in messages" :key="index">{{ msg }}</p>
    </span>
  </div>
</template>

<script>
  export default {
    name: 'Sprsuccess',
    props: {
      messages: {
        type: Array,
        default () {
          return []
        }
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .success-message {
    box-sizing: border-box;
    color: $brand-success;
    background: $brand-white;
    border: solid 2px $brand-success;
    display: inline-block;
    position: relative;
    align-content: center;
    flex-flow: row;
    border-radius: 5px;

    max-width: 500px;
    width: 100%;
    padding: 10px 0;
    margin: 0;

    .success-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      margin-left: 20px;
      font-size: 20px;
      display: flex;
      align-items: center;
    }

    .success-container {
      display: block;
      text-align: left;
      padding-left: 80px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;

      p {
        display: block;
        margin: 0;
      }
    }

    .anne-sized {
      font-size: 150%;
    }
  }
</style>
