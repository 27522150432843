
<template>
  <div class="eventjoinpublic">
    <div class="event-block-container">
      <div class="row">
        <div class="col-12">
          <div class="instructions">
            {{ $t('eventPage.joinInstructions') }}
          </div>
        </div>
      </div>
      <div v-if="rolesAvailable" class="row">
        <checkboxlist
          v-if="!isLeaving"
          v-model="selectedRoles"
          v-validate="'checkbox-selected'"
          name="participantRoles"
          :is-valid="!errors.has('participantRoles')"
          @change="validateSelectedRoles"
        />
        <checkboxlist
          v-else
          v-model="selectedRoles"
          name="participantRoles"
        />
        <forminput v-model="eventParticipant.firstName"
                   v-validate="'required'"
                   :hint="$t('eventPage.firstName') + ' *'"
                   name="eventPage.firstName"
                   :is-valid="!errors.has('eventPage.firstName')"
        />
        <div class="col-12 col-lg-6">
          <forminput v-model="eventParticipant.lastName"
                     v-validate="'required'"
                     :hint="$t('eventPage.lastName') + ' *'"
                     name="eventPage.lastName"
                     :is-valid="!errors.has('eventPage.lastName')"
          />
        </div>
        <div class="col-12 col-lg-6">
          <forminput v-model="eventParticipant.email"
                     v-validate="'required|email'"
                     :hint="$t('eventPage.email') + ' *'"
                     name="eventPage.email"
                     :is-valid="!errors.has('eventPage.email')"
          />
        </div>
        <div class="col-12 col-lg-6">
          <forminput v-model="eventParticipant.phone"
                     v-validate="'required'"
                     :hint="$t('eventPage.phone') + ' *'"
                     name="eventPage.phone"
                     :is-valid="!errors.has('eventPage.phone')"
          />
        </div>
        <div class="col-12 col-lg-6">
          <forminput v-model="eventParticipant.zipCode"
                     v-validate="'required|digits:5'"
                     :hint="$t('eventPage.zipCode') + ' *'"
                     name="eventPage.zipCode"
                     :is-valid="!errors.has('eventPage.zipCode')"
          />
        </div>
        <div class="col-12">
          <label class="cap-label brand-red">{{ $t('nonIdentProfileForm.volunteerInformation') }}</label>
          <p v-if="moreInfoQuestion">
            {{ moreInfoQuestion }}
          </p>
          <textareainput v-model="eventParticipant.moreInfo"
                         :rows="8"
          />
        </div>
        <div class="col-12">
          <checkboxinput
            v-model="eventParticipant.interestedInSpr"
            :title="$t('eventPage.interestedInSpr')"
            :aria-label="$t('eventPage.interestedInSpr')"
          />
        </div>

        <div class="col-12">
          <checkboxinput
            v-model="eventParticipant.privacyStatement"
            v-validate="'option-selected'"
            :title="$t('eventPage.privacyStatement')"
            :aria-label="$t('eventPage.privacyStatement')"
            name="privacyStatement"
            :allow-html="true"
            :white-warnings="false"
            :has-error="errors.has('privacyStatement')"
          />
        </div>

        <div class="col-12">
          <sprbutton type="primary"
                     :size="4"
                     :title="$t('eventPage.join')"
                     click-event="join"
                     @join="onJoin"
          />
        </div>
      </div>
      <div v-else class="row no-roles">
        <div class="col-12 no-roles-available-text">
          {{ $t('eventPage.noRolesAvailable') }}
        </div>
        <div class="col-12">
          <sprbutton type="primary"
                     :size="4"
                     :title="$t('eventPage.join')"
                     :disabled="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprbutton from '../SPRButton.vue'
  import forminput from '../SPRSimpleText.vue'
  import checkboxinput from '../SPRSimpleCheckbox.vue'
  import checkboxlist from '../SPRSimpleCheckboxList2.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import { EventRoleDto } from '../../types/index'
  import { SprDropDownValue } from '../../types/custom'

  export default Vue.extend({
    name: 'Eventjoinpublic',
    components: {
      sprbutton,
      forminput,
      checkboxinput,
      checkboxlist,
      textareainput
    },
    props: {
      eventId: {
        type: Number,
        default: null
      },
      isLeaving: {
        type: Boolean,
        default: false
      },
      roles: {
        type: Array as () => Array<EventRoleDto>,
        default () {
          return []
        }
      },
      moreInfoQuestion: {
        type: String,
        default: ''
      },
      cancellationToken: {
        type: String,
        default: null // Default value as null
      }
    },
    data () {
      return {
        selectedRoles: [] as Array<SprDropDownValue>,
        eventParticipant: {
          roleIds: [] as Array<number>,
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          zipCode: '',
          interestedInSpr: false,
          interestedInSimilarEvents: false,
          moreInfo: '',
          privacyStatement: false,
          privacyStatementInError: false
        }
      }
    },
    computed: {
      rolesAvailable (): boolean {
        return this.roles.length > 0 && this.selectedRoles.length > 0
      }
    },
    created () {
      const e = this.$store.state.publicEvents.find(p => p.eventId === this.eventId)
      if (e) {
        this.eventParticipant.moreInfo = e.moreInfo
        this.eventParticipant.email = e.email
        this.eventParticipant.firstName = e.firstName
        this.eventParticipant.lastName = e.lastName
        this.eventParticipant.phone = e.phone
        this.eventParticipant.zipCode = e.zipCode
      }
      // Selected roles are only visible if they are not occupied yet up to the maximum number of participants,
      // or if the role is occupied by the current user (based on his cancellation token.)
      this.selectedRoles = this.roles.filter((r) => {
        return r.maximumParticipants === 0 || r.maximumParticipants > r.participants.length || r.participants.some(p => p.cancellationToken === this.cancellationToken)
      }).map((r) => {
        let selected = false

        if (e) { selected = e.roleIds.some(p => p === r.id) }

        return {
          value: r.id,
          text: r.name,
          selected: selected
        } as SprDropDownValue
      })
    },
    methods: {
      validateSelectedRoles () {
        this.$validator.validate('participantRoles')
      },
      onJoin () {
        this.$validator.validateAll().then(resp => {
          if (resp) {
            this.eventParticipant.roleIds = this.selectedRoles
              .filter((r) => r.selected)
              .map((r) => r.value as number)

            this.$emit('join', this.eventParticipant)
          }
        }).catch(error => {
          console.log('onJoin.error', error)
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .eventjoinpublic {
   .instructions {
     margin-bottom: 15px;
   }

   .no-roles {
     .no-roles-available-text {
       margin-bottom: 15px;
     }
   }
  }
</style>