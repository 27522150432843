<template>
  <div class="customercardstandard">
    <div class="topsection">
      <div class="circle">
        <friendshipicon
          v-if="friendshipStateOverride"
          :friendname="customer.firstName"
          :state="friendshipStateOverride"
        />
        <friendicon
          v-else
          :friendname="customer.firstName"
          :state="customer.state"
          :multiple-friendships="customer.multipleVolunteers"
        />
      </div>
      <div class="details">
        <div class="name">
          <span>{{ customer.firstName }} {{ customer.lastName }}</span>
        </div>
        <div class="personal">
          <div>{{ genderAgeText }}</div>
          <div>{{ customerType }}</div>
          <div>{{ customer.customerTypeOtherInfo }}</div>
          <div v-if="customer.shortDescription" class="short-description">
            <font-awesome-icon icon="quote-left" aria-hidden="true" />
            <span>{{ customer.shortDescription }}</span>
            <font-awesome-icon icon="quote-right" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
    <div class="properties">
      <div class="row property location">
        <div class="icon">
          <font-awesome-icon icon="map-marker-alt" />
        </div>
        <div class="content">
          <div>{{ $t('customerSearch.address') }}: {{ address || '&ndash;' }}</div>
          <div v-if="showMatches">
            {{ matches.travelData.travelDistanceTime }}
          </div>
        </div>
        <div v-if="showMatches" class="match">
          <div v-if="showDistanceWarning">
            <tickcrosswarning v-model="showDistanceWarningValue" />
          </div>
          <div v-else>
            <tickcross v-model="matches.travelData.isMatch" />
          </div>
        </div>
      </div>
      <div class="row property availabilitytimes">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'clock']" />
        </div>
        <div class="content">
          {{ $t('customerSearch.myTimes') }}: <strong>{{ availabilityText || '&ndash;' }}</strong>
        </div>
        <div v-if="showMatches" class="match">
          <tickcross v-model="matches.availabilityTimesMatch" />
        </div>
      </div>
      <div class="row property availabilitytimes">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'clock']" />
        </div>
        <div class="content">
          {{ $t('customerSearch.myFrequency') }}: <strong>{{ availabilityFrequency || '&ndash;' }}</strong>
        </div>
        <div v-if="showMatches" class="match">
          <tickcross v-model="matches.availabilityFrequencyMatch" />
        </div>
      </div>
      <div class="row property genders">
        <div class="icon">
          <font-awesome-icon icon="venus-mars" />
        </div>
        <div class="content">
          <div>{{ $t('customerSearch.am') }} <strong>{{ gender.toLowerCase() || '...?' }}</strong></div>
          <div>{{ $t('customerSearch.forFriend') }}: <strong>{{ genderPreferences || '&ndash;' }}</strong></div>
        </div>
        <div v-if="showMatches" class="match">
          <tickcrosswarning v-model="matches.genderMatch" />
        </div>
      </div>

      <div class="row property age">
        <div class="icon">
          <font-awesome-icon icon="id-badge" />
        </div>
        <div class="content">
          <div>{{ $t('customerSearch.am') }} <strong>{{ age || '?' }} {{ $t('customerSearch.years') }}</strong></div>
          <div>{{ $t('customerSearch.forFriend') }}: <strong>{{ agePreferences || '&ndash;' }}</strong></div>
        </div>
        <div v-if="showMatches" class="match">
          <tickcross v-model="matches.ageMatch" />
        </div>
      </div>

      <div class="row property language">
        <div class="icon">
          <font-awesome-icon :icon="['far', 'comment']" />
        </div>
        <div class="content">
          <div>{{ $t('customerSearch.speak') }}: <strong>{{ normalizeTranslation(customer.nativeLanguage) }} ({{ $t('customerSearch.nativeLanguage') }})</strong></div>
          <div v-if="spokenLanguages">
            <strong>{{ spokenLanguages }}</strong>
          </div>
        </div>
        <div v-if="showMatches" class="match">
          <tickcross v-model="matches.languageMatch" />
        </div>
      </div>

      <div class="row property interest">
        <div class="icon">
          <font-awesome-icon icon="puzzle-piece" />
        </div>
        <div class="content">
          {{ $t('customerSearch.interestedIn') }}: <strong>{{ interests || '&ndash;' }}</strong>
        </div>
      </div>
    </div>

    <div class="moreinfo">
      <div class="row">
        <div class="col description">
          <sprcollapsible :heading="$t('profile.notes')">
            <div slot="body">
              <sprprivatenotes v-model="customer" endpoint="customerupdateprivatenotesstandard" :friend-management-group-id="group.id" :target-id="targetId" is-customer />
            </div>
          </sprcollapsible>
        </div>
      </div>
      <div class="row">
        <div class="col contact">
          <sprcollapsible :heading="$t('customerSearch.contactTitle')">
            <div slot="body">
              <p class="smalltext">
                {{ contactDetails }}
              </p>
            </div>
          </sprcollapsible>
        </div>
      </div>
      <div class="row">
        <div class="col other">
          <sprcollapsible :heading="$t('customerSearch.otherInfoTitle')">
            <div slot="body">
              <dl v-if="customer.customerApproval" class="row">
                <dt class="col-12">
                  {{ $t('customerForm.customerApproval') }}
                </dt>
                <dd class="col-12">
                  {{ customer.customerApprovedBy }}
                </dd>
              </dl>
              <dl v-if="customer.customerInterviewed" class="row">
                <dt class="col-12">
                  {{ $t('customerForm.customerInterviewed') }}
                </dt>
                <dd class="col-12">
                  {{ customer.customerInterviewedBy }}
                </dd>
              </dl>
              <dl v-if="customer.customerNotForSpr" class="row">
                <dt class="col-12">
                  {{ $t('customerForm.customerNotForSPR') }}
                </dt>
                <dd class="col-12">
                  {{ customer.customerNotForSprBy }}
                </dd>
              </dl>
              <dl v-if="customer.customerNotForPrivateRelations" class="row">
                <dt class="col-12">
                  {{ $t('customerForm.customerNotForPrivateRelations') }}
                </dt>
                <dd class="col-12">
                  {{ customer.customerNotForPrivateRelationsBy }}
                </dd>
              </dl>
              <dl v-if="contactPerson" class="row">
                <dt class="col-12">
                  {{ $t('customerForm.customerContactPerson') }}
                </dt>
                <dd class="col-12">
                  {{ contactPerson }}
                </dd>
              </dl>
              <dl v-if="customer.otherContact" class="row">
                <dt class="col-12">
                  {{ $t('customerForm.customerContactOther') }}
                </dt>
                <dd class="col-12">
                  {{ customer.otherContact }}
                </dd>
              </dl>
            </div>
          </sprcollapsible>
        </div>
      </div>
      <div v-if="isOnHold" class="row">
        <div class="col onhold">
          <sprcollapsible :heading="$t('customerSearch.onHoldTitle')">
            <div slot="body">
              <dl class="row">
                <dt class="col-12">
                  {{ $t('customerSearch.onHoldUntilDate') }}
                </dt>
                <dd class="col-12">
                  {{ formattedOnHoldUntilDate }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-12">
                  {{ $t('customerSearch.onHoldComment') }}
                </dt>
                <dd class="col-12">
                  {{ customer.onHoldComment }}
                </dd>
              </dl>
            </div>
          </sprcollapsible>
        </div>
      </div>
    </div>

    <div v-if="showActions" class="row">
      <div class="col buttons">
        <!-- Actions -->
        <customerActions
          v-model="customer"
          :group="group"
          :loaded="loaded"
          @saveCustomer="saveCustomer"
          @toggleActivity="toggleActivity"
          @moveCustomer="moveCustomer"
        />
      </div>
    </div>
    <div v-if="isSending" class="text-center">
      <Loading />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import * as _ from 'lodash'
  import friendicon from './FriendIcon.vue'
  import friendshipicon from './FriendshipIcon.vue'
  import tickcross from '../SPRTickCross.vue'
  import tickcrosswarning from '../SPRTickCrossWarning.vue'
  import sprcollapsible from '../SPRCollapsible.vue'
  import sprprivatenotes from '../SPRPrivateNotes.vue'
  import customerActions from './CustomerActions.vue'
  import Loading from '../SPRLoading.vue'
  import moment from 'moment'
  import enumhelper from '../../mixins/enums.js'
  import datehelper from '../../mixins/dates.js'
  import locationhelper from '../../mixins/locationutils.js'

  import { Validator } from 'vee-validate'
  import validatordateisafter from '../../mixins/validator-dateisafter.js'
  import { CustomerState, FriendManagementDto, FriendCustomerStandardDto, LanguageDto, FriendshipState } from '../../types/index'
  import { MatchData, YesNoWarning } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    loaded: boolean,
    isSending: boolean,
    customer: FriendCustomerStandardDto
  }

  Validator.extend('dateisafter', validatordateisafter)

  export default Vue.extend({
    name: 'CustomerCardStandard',
    components: { customerActions, friendicon, friendshipicon, tickcross, tickcrosswarning, sprcollapsible, sprprivatenotes, Loading },
    mixins: [enumhelper, datehelper, locationhelper],
    props: {
      value: {
        type: Object as () => FriendCustomerStandardDto, // Customer object
        required: true
      },
      group: {
        type: Object as () => FriendManagementDto,
        default: null
      },
      showMatches: {
        type: Boolean,
        default: () => {
          return false
        }
      },
      showActions: {
        type: Boolean,
        default: () => {
          return true
        }
      },
      matches: {
        type: Object as () => MatchData,
        default: () => {
          return {
            travelData: {
              travelDistanceTime: '',
              isMatch: false,
              isRealDistance: false
            },
            availabilityTimesMatch: false,
            availabilityFrequencyMatch: false,
            friendshipTypesMatch: false,
            genderMatch: 'FAIL',
            ageMatch: false,
            languageMatch: false,
            interestMatch: false
          }
        }
      },
      friendshipStateOverride: {
        type: Number as () => FriendshipState,
        required: false,
        default: undefined
      }
    },
    data (): TemplateComponentData {
      return {
        customer: this.value,
        loaded: true,
        isSending: false
      }
    },
    computed: {      
      isInactive (): boolean {
        return this.value.state === CustomerState.NonActive
      },
      gender (): string | LocaleMessage {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getGenderTranslation(this.customer.gender)
      },
      isOnHold (): boolean {
        return this.value.state === CustomerState.OnHold
      },
      formattedOnHoldUntilDate (): string {
        if (this.customer.onHoldUntilDate) {
          const date = this.customer.onHoldUntilDate
          const formattedDate = moment(date).format('DD.MM.YYYY')

          return formattedDate
        } else {
          return ''
        }
      },
      age (): number {
        // @ts-ignore - ts intellisense not finding mixins
        return this.calculateAge(this.customer.birthdate)
      },
      genderAgeText (): string | LocaleMessage {
        return [this.gender, this.age ? `${this.age} ${this.$t('customerSearch.years')}` : ''].filter(Boolean).join(', ')
      },
      customerType (): string {
        var self = this
        // @ts-ignore - ts intellisense not finding mixins
        return this.getCustomerTypesMaskTranslation(self.customer.customerTypes)
      },
      address (): string {
        // @ts-ignore - ts intellisense not finding mixins
        return this.formatAddress(this.customer.streetAddress, this.customer.zipCode, this.customer.city)
      },
      availabilityFrequency (): string {
        if (this.group.netFriendship) {
          return ''
        }

        // @ts-ignore - ts intellisense not finding mixins
        var frequency = this.getAvailabilityFrequencyTranslation(this.customer.availabilityFrequency)
        return frequency
      },
      availabilityText (): string {
        if (this.group.netFriendship) {
          return ''
        }

        var self = this
        // @ts-ignore - ts intellisense not finding mixins
        var times = this.getAvailabilityTimesMaskTranslation(self.customer.availabilityTimes)

        return times
      },
      genderPreferences (): string | LocaleMessage {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getGenderPreferencesMaskTranslation(this.customer.preferredGenders)
      },
      agePreferences (): string | LocaleMessage {
        // @ts-ignore - ts intellisense not finding mixins
        return this.getAgePreferencesMaskTranslation(this.customer.preferredAges)
      },
      spokenLanguages (): string {
        var self = this
        var languages = [] as string[]
        _.forEach(this.customer.customerLanguages, (item: LanguageDto) => {
          if (item.isoCode !== self.customer.nativeLanguage) {
            // @ts-ignore - ts intellisense not finding mixins
            languages.push(self.normalizeTranslation(item.isoCode))
          }
        })
        languages.push(this.customer.otherLanguages)
        return languages.filter(Boolean).join(', ')
      },
      interests (): string {
        var self = this
        // @ts-ignore - ts intellisense not finding mixins
        var interests = this.getInterestsMaskTranslation(self.customer.interests)
        if (this.customer.interestOther) {
          interests += `, ${this.customer.interestOther}`
        }
        return interests
      },
      contactDetails (): string {
        var phone = [this.customer.gsmPhone, this.customer.homePhone].filter(Boolean).join(' / ')
        var phoneText = phone ? `${this.$t('customerSearch.telAbbreviation')} ${phone}` : ''
        var email = this.customer.email ? `${this.$t('customerSearch.emailAbbreviation')}: ${this.customer.email}` : ''
        return [phoneText, email].filter(Boolean).join(' | ')
      },
      contactPerson (): string {
        var contactName = [this.customer.contactFirstName, this.customer.contactLastName].filter(Boolean).join(' ')
        var contactPhone = this.customer.contactPhonenumber ? `${this.$t('customerSearch.telAbbreviation')} ${this.customer.contactPhonenumber}` : ''
        // @ts-ignore - ts intellisense not finding mixins
        var contactAddress = this.formatAddress(this.customer.contactStreetAddress, this.customer.contactZipCode, this.customer.contactCity)
        return [this.customer.contactRelation, contactName, this.customer.contactEmail, contactPhone, contactAddress].filter(Boolean).join(' | ')
      },
      showEnable (): boolean {
        return this.customer.state === CustomerState.NonActive
      },
      showDisable (): boolean {
        return _.includes([CustomerState.PendingApproval, CustomerState.Active, CustomerState.OnHold], this.customer.state)
      },
      showDistanceWarning (): boolean {
        if (!this.showMatches) {
          return false
        }
        if (this.matches.travelData) {
          return !this.matches.travelData.isRealDistance
        }
        return false
      },
      showDistanceWarningValue (): YesNoWarning {
        return YesNoWarning.Warning
      },
      targetId (): number {
        return this.value.id
      }
    },
    watch: {
      value (newVal) {
        this.customer = newVal
      }
    },
    methods: {
      moveCustomer (): void {
        this.$emit('moveCustomer')
      },
      updateRemoteCustomer (): void {
        var self = this
        self.isSending = true
        var url = `${this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customerupdatestandard[0]}`
        this.$http.put(url, this.customer).then(response => {
          if (response.ok) {
            self.isSending = false
            this.notifyRefresh()
          }
        }, response => {
          // @ts-ignore - mixins not picked up
          this.showAlertError(`${this.$t('common.saveFailed')}`)
          console.log('Error saving customer state')
        })
      },
      saveCustomer (): void {
        this.updateRemoteCustomer()
      },
      notifyRefresh (): void {
        this.$emit('refresh')
      },
      toggleActivity (): void {
        var uri = this.$store.state.apiBases.friendservice
        if (this.customer.state === CustomerState.NonActive) {
          uri += this.$store.state.apiEndpoints.markactivecustomerstandard[0]
        } else {
          uri += this.$store.state.apiEndpoints.markinactivecustomerstandard[0]
        }

        uri = uri
          .replace('{customerid}', this.customer.id)
          .replace('{groupid}', this.group.id)

        const self = this

        this.$http.post(uri).then(() => {
          this.notifyRefresh()
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .customercardstandard {
    margin-bottom: 30px;
    .topsection {
      min-height: 120px;
      .circle {
        float: left;
        margin-top:20px;
      }
      .details {
        margin-left:70px;

        .name {
          text-transform: uppercase;
          color: $brand-red;
          font-weight: bold;
          position: relative;
          .edit {
            position: absolute;
            left:-15px;
            text-decoration: none;
            color:$brand-red;
          }
        }

        .personal {
          font-size: 0.8em;
          font-weight:bold;
          .short-description {
            font-weight:normal;
            color:$brand-red;
            font-style: italic;
            margin-top: 10px;
            position:relative;
            span {
              padding: 0 5px;
            }
            svg {
              font-size:12px;
              display:inline-block;
            }
          }
        }
      }
    }

    .properties {
      clear: both;
      margin-top: 10px;
      margin-bottom: 30px;

      .property {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        min-height: 55px;
        margin: 0;
        border-bottom: solid 1px $brand-grey3;
        padding: 5px 0;

        .icon {
          flex: 0 0 40px;
          text-align: center;

          font-size: 2em;
          color:$brand-red;
        }
        //IE11
        *::-ms-backdrop, .icon { margin-top: 15px; }

        .content {
          margin-left: 10px;
          flex: auto;

          font-size: 0.8em;
          dl {
            margin-bottom: 0;
            dt {
              font-size: 1em;
            }
          }
        }

        .match {
          flex: 0 0 40px;
          text-align: right;

          font-size: 1.5em;
        }
      }
    }

    button {
      margin-bottom: 15px;
      &.button-disabled {
        border-color: $brand-grey4;
        color: $brand-grey4;
        cursor: not-allowed;
        outline: none;
        &:hover {
          background-color:$brand-white;
        }
      }
    }

    .moreinfo {
      .description,
      .contact,
      .other {
        margin-bottom: 20px;
      }
      dl {
        margin-bottom: 0;
        font-size: 0.8em;
        dt {
          font-size: 1em;
        }
      }
    }

    .edit-customer,
    .enable-customer,
    .disable-customer,
    .onhold-customer,
    .onholdinfo {
      text-align: center;
      margin-top: 15px;
    }

    .onholdinfo {
      text-align:left;

      .holddate {
        margin-bottom:15px;
      }

      a {
        margin-top:14px;
        display:block;
      }
    }
  }
</style>
