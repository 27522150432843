<template>
  <div class="fill-width comp-wizard-content">
    <div class="col-12">
      <span class="h1"
            role="heading"
            aria-level="1"
      >{{ $t('wizard.headerFour') }}</span><br>
      <div>
        <sprvalidation v-show="!isCalculating && validationErrors.length > 0" class="validation-box"
                       :validation-errors="validationErrors"
        />
      </div>
      <div>
        <sprwarning class="validation-box"
                    :warnings="warnings"
                    :use-translations="true"
        />
      </div>
    </div>
    <div class="col-12">
      <span class="top-span">{{ $t('wizard.locationIngress') }}</span><br>
    </div>
    <div class="col-12 col-sm-10 col-md-6 col-lg-4 offset-sm-1 offset-md-3 offset-lg-4">
      <sprbutton v-show="isGeolocationEnabled"
                 :size="1"
                 :title="$t('wizard.currentLocation')"
                 click-event="handle-location"
                 class="geo-button"
                 @handle-location="handleLocation"
      />
    </div>
    <div class="col-12">
      <span v-if="isGeolocationEnabled" class="or-span">{{ $t('wizard.orBySectionPostcode') }}</span>
    </div>
    <div class="col-12 col-sm-10 col-md-6 col-lg-4 offset-sm-1 offset-md-3 offset-lg-4">
      <sprautocomplete
        v-model="sectionZip"
        v-validate="'required'"
        :options="sectionsZips"
        option-label="value"
        :placeholder="$t('wizard.workingZipPlaceholder')"
        :required="true"
        name="zipCodeField"
        :is-valid="!errors.has('zipCodeField')"
        :max-results="200"
        :show-arrow="false"
        :show-default-options="false"
        dark
      />
    </div>
    <div class="col-12 col-sm-10 col-md-6 col-lg-4 offset-sm-1 offset-md-3 offset-lg-4 volunteeronline">
      <simplecheckbox
        v-model="volunteerOnline"
        :title="$t('wizard.volunteerOnline')"
        :aria-label="$t('wizard.volunteerOnline')"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import sprbutton from '../SPRButton.vue'
  import sprvalidation from '../SPRValidationError.vue'
  import sprwarning from '../SPRWarning.vue'
  import sprautocomplete from '../SPRAutocompleteDropdown.vue'
  import simplecheckbox from '../SPRSimpleCheckbox.vue'
  import locationutils from '../../mixins/locationutils.js'
  import googlemaps from '../../mixins/googlemaps.js'
  import { StringKeyValuePair } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    zipCode: string,
    validatedFields: Array<string>,
    zipCodeChanged: boolean,
    geocodeWarning: string,
    geocodeDisabledWarning: string,
    warnings: Array<any>,
    isGeolocationEnabled: boolean,
    sectionsZips: Array<StringKeyValuePair>,
    sectionZip: StringKeyValuePair | undefined,
    section: number,
    volunteerOnline: boolean,
    isCalculating: boolean
  }

  export default Vue.extend({
    name: 'WizardFour',
    components: { sprbutton, sprvalidation, sprwarning, sprautocomplete, simplecheckbox },
    mixins: [locationutils, googlemaps],
    data (): TemplateComponentData {
      return {
        zipCode: this.$store.state.wizardModel.workingZipCode,
        validatedFields: ['zipCodeField'],
        zipCodeChanged: false,
        geocodeWarning: 'validation.geolocatePostcodeFailed',
        geocodeDisabledWarning: 'warning.geolocationDisabled',
        warnings: [],
        isGeolocationEnabled: true,
        sectionsZips: [],
        sectionZip: undefined,
        section: this.$store.state.wizardModel.sectionId,
        volunteerOnline: false,
        isCalculating: true
      }
    },
    computed: {
      validationErrors (): Array<Array<string>> {
        if (!this.validatedFields) {
          return []
        }

        if (this.isCalculating) {
          return []
        }
        var arr = [] as Array<Array<string>>
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['', err.field + '_' + err.rule, ''])
          }
        })
        return arr
      }
    },
    watch: {
      zipCode (val): void {
        this.sectionZip = undefined
        // set the sectionszips dropdown. Key is in format zip-sectionid
        if (val) {
          this.setSection()
          this.zipCodeChanged = true
        }
      },
      sectionZip (val) {
        if (!val) {
          this.$store.commit('setWizardWorkingZip', null)
          this.$store.commit('setWizardZipcode', null)
          this.$store.commit('setWizardSectionId', null)
        } else {
          var arr = val.key.split('-')
          this.$store.commit('setWizardWorkingZip', arr[0])
          this.$store.commit('setWizardZipcode', arr[0])
          this.$store.commit('setWizardSectionId', Number(arr[1]))
        }
      },
      volunteerOnline (val) {
        this.$store.commit('setWizardVolunteerOnline', val)
      }
    },
    created () {
      this.isCalculating = true
      var sections = [] as Array<StringKeyValuePair>

      _.each(this.$store.state.sections, (section) => {
        _.each(section.zipCodes, (zip) => {
          sections.push({ key: `${zip}-${section.id}`, value: `${zip} - ${section.name}` } as StringKeyValuePair)
        })
      })

      this.sectionsZips = _.sortBy(sections, 'value')
      this.isCalculating = false
    },
    methods: {

      handleLocation (): void {
        this.isCalculating = true
        this.sectionZip = undefined

        this.warnings = []
        var isLocationActive = navigator.geolocation // this doesn't work! (If Geolocation is disabled)

        // https://stackoverflow.com/questions/3397585/navigator-geolocation-getcurrentposition-sometimes-works-sometimes-doesnt
        if (isLocationActive) {
          navigator.geolocation.getCurrentPosition(this.locationCallback, this.failureCallback, { timeout: 7000 }) // 7 sec
        } else {
          this.setGeocodeDisabledWarning()
          this.isCalculating = false
        }
      },
      failureCallback (): void {
        this.$set(this.warnings, 0, this.geocodeWarning)
        this.isCalculating = false
      },
      setSection (): void {
        var self = this
        var secs = _.filter(this.sectionsZips, function (sz) {
          return self.zipCode === sz.key.split('-')[0]
        })
        if (secs.length === 1) {
          self.sectionZip = secs[0]
        } else if (secs.length > 1) {
          // if there are 2 sections with this zip code, look at the users language and set the section to the one matching that
          // if users language is English, default this to the Finnish section
          //this happens, when user clicks the button "use my current location", and we grab the zipcode based on the GPS (or connection)
          var desiredLanguageId = 2
          if (this.$store.state.language === 'sv') desiredLanguageId = 1
          if (this.$store.state.language === 'fi') desiredLanguageId = 0
          if (this.$store.state.language === 'en') desiredLanguageId = 0

          for (var z = 0; z < secs.length; z++) {
            var section = _.find(this.$store.state.sections, function (s) {
              return s.id === Number(secs[z].key.split('-')[1])
            })

            if (section.language === desiredLanguageId) {
              self.sectionZip = secs[z]
              break
            }
          }
        } else {
          self.$set(self.warnings, 0, self.geocodeWarning)
        }
        self.isCalculating = false
      },
      locationCallback (position): void {
        var self = this
        // @ts-ignore cannot find mixins
        this.proxiedReverseGeocode(position.coords.latitude, position.coords.longitude).then((resp) => {
          var i = 0

          resp.body.results.some(result => {
            return result.address_components.some(addressComponent => {
              const postalCodeType = addressComponent.types.find(type => type === 'postal_code')
              if (postalCodeType) {
                self.zipCode = addressComponent.short_name
                return true // Exit the loop when postal code is found
              }
              return false
            })
          })
          this.setSection()
        }, () => {
          self.$set(self.warnings, 0, self.geocodeWarning)
        })
      },
      setGeocodeDisabledWarning (): void {
        this.$set(this.warnings, 0, this.geocodeDisabledWarning)
      },
      validateSection (): Promise<boolean> {
        this.$emit('prefillCity')
        return this.$validator.validateAll()
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

  .validation-box {
    margin-bottom: 10px;
    display:inline-block;
    flex-wrap:wrap;
  }
  .help-zipcode {
    display: inline-block;
  }
  .postcode-box {
    max-width: 150px;
  }
  .geo-button {
    min-width: 300px;
  }
  .center-text {
    text-align: center;
  }
  .volunteeronline {
    margin-top: 20px;
    text-align: left;
    color: $brand-white;
  }
</style>
