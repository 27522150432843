<template>
  <div>
    <div class="msg-list">
      <thread v-for="thread in getThreads"
              :key="'thread_' + thread.id"
              :note="thread"
              :children="getThreadChildren(thread.id)"
              :focus="focus"
              :portraits="portraits"
              :can-delete="canDelete"
              :can-send-as-email="canSendAsEmail"
              @remove="remove"
      />
    </div>
    <b-modal v-model="showModal">
      <div slot="modal-header">
        <h3>{{ $t('groups.youSure') }}</h3>
      </div>
      <div slot="modal-footer" class="text-center">
        <sprbutton click-event="rem" type="primary" :size="1" :title="$t('groups.remove')" @rem="removeMessage" />
        <sprbutton click-event="can" type="primary" :size="1" :title="$t('groups.cancel')" @can="showModal = false" />
      </div>
      <div>
        <p>{{ $t('groups.deleteMessagePrompt') }}</p>
        <p v-if="toRem">
          {{ messageSummary(toRem) }}
        </p>
        <p v-if="toRem">
          – {{ toRem.sender }}, {{ formattedDate(toRem.sent) }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import moment from 'moment'
  import sprbutton from '../SPRButton.vue'
  import thread from './ThreadView.vue'
  import messages from '../../mixins/messages.js'

  export default {
    name: 'NoteView',
    components: { sprbutton, thread },
    mixins: [messages],
    props: {
      notes: {
        type: Array,
        default: () => []
      },
      content: {
        type: String,
        default: ''
      },
      sender: {
        type: String,
        default: ''
      },
      sent: {
        type: String,
        default: ''
      },
      subject: {
        type: String,
        default: ''
      },
      groupid: {
        type: String,
        default: ''
      },
      portraits: {
        type: Array,
        default: () => []
      },
      focus: {
        type: Number,
        default: 0
      },
      canDelete: Boolean,
      canSendAsEmail: Boolean
    },
    data () {
      return {
        toRem: null,
        showModal: false
      }
    },
    computed: {
      showRem: function () {
        if (this.hasRole('Admin') || this.hasRole('Employee')) {
          return true
        }

        if (this.notes[0] != null && (this.hasRole('J1') || this.hasRole('J2'))) {
          var result = this.hasClaim(this.notes[0].sectionId.toString(), 'SectionMembership')
          return result
        }

        if (this.groupid && this.hasClaim(this.groupid.toString(), 'GroupAdmin')) {
          return true
        }
        return false
      },
      getThreads: function () {
        return _.orderBy(_.filter(this.notes, function (x) {
          return x.parentMessageId === null
        }), ['id'], ['desc'])
      }
    },
    watch: {
      showModal: function (val) {
        if (!val) {
          this.toRem = null
        }
      }
    },
    mounted: function () {
      if (this.focus != null) {
        var message = {
          id: this.focus,
          messageType: 2
        }
        this.markMessageAsRead(message)
      }
    },
    methods: {
      formattedDate (date) {
        return moment.utc(date).local().format('DD.MM.YYYY H:mm')
      },
      getThreadChildren: function (threadId) {
        return _.orderBy(_.filter(this.notes, function (x) {
          return x.parentMessageId === threadId
        }), ['id'], ['asc'])
      },
      remove: function (x) {
        this.toRem = x
        this.showModal = true
      },
      removeMessage: function () {
        this.showModal = false
        this.$emit('removed', this.toRem.id)
      },
      messageSummary: function (msg) {
        if (msg.subject) return msg.subject
        if (msg.content.length <= 30 || msg.content.split(' ').length <= 5) return '"' + msg.content + '"'
        var trunc = msg.content.split(' ').splice(0, 5).join(' ') + '...'
        if (trunc.length <= 50) return '"' + trunc + '"'
        return '"' + trunc.substring(0, 30) + '..."'
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";

  .close-link {
    cursor: pointer;
  }

  .rem-btn {
    color: $brand-red;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .head-cont {
    display: flex;
  }

  .msg-list {
    padding: 15px;
    background-color: white;
  }

  .sprmessage {
    padding: 5px;
    background-color: white;
    &:nth-child(odd)

  {
    background: rgba(0, 0, 0, 0.03);
  }

  .sender {
    .state

  {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: none;
    &.new

  {
    display: inline-block;
    background-color: $brand-success;
    border-color: $brand-success;
  }

  }

  .sentby {
    color: $brand-red;
    &:hover

  {
    background-color: auto;
  }

  }
  }

  .subject {
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 800;
  }

  }
</style>
