<template>
  <div class="row customermatchsummarystandard">
    <div class="col-md-6 mb-3 mb-md-0 maincolumn">
      <VolunteerCardStandard
        v-model="volunteer"
        :group="group"
        :show-matches="false"
        :friendship-state-override="friendshipState"
      />
    </div>
    <div class="col-md-6 sidecolumn">
      <div class="row">
        <div class="col">
          <div class="controls">
            <div class="back">
              <a href="javascript:void(0)" @click="close">{{ $t('customerSearch.close') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <FriendshipStatus
          v-if="selectedFriendship"
          :friendship="selectedFriendship"
          :group="group"
          @cancelpending="onCancelPending"
          @activate="onActivate"
          @terminate="onTerminate"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import datehelper from '../../mixins/dates.js'
  import FriendshipStatus from './FriendshipStatus.vue'
  import VolunteerCardStandard from './VolunteerCardStandard.vue'
  import { FriendManagementDto, FriendCustomerStandardDto, FriendProfileDto, FriendshipStandardDto, FriendshipState } from '@/types/index'

  export default Vue.extend({
    name: 'CustomerMatchSummaryStandard',
    components: { FriendshipStatus, VolunteerCardStandard },
    mixins: [datehelper],
    props: {
      group: {
        type: Object as () => FriendManagementDto,
        required: true
      },
      customer: {
        type: Object as () => FriendCustomerStandardDto,
        required: true
      },
      volunteer: {
        type: Object as () => FriendProfileDto,
        required: true
      }
    },
    computed: {
      selectedFriendship (): FriendshipStandardDto | undefined {
        return this.volunteer.friendInfo.standardProfile.friendships.find((x: FriendshipStandardDto) => {
          return x.customer.id === this.customer.id
        })
      },
      friendshipState (): FriendshipState | undefined {
        return this.selectedFriendship?.friendshipState
      }
    },
    methods: {
      close (): void {
        this.$emit('close')
      },
      onCancelPending (friendship: FriendshipStandardDto): void {
        this.$emit('cancelpending', friendship)
      },
      onActivate (friendship: FriendshipStandardDto): void {
        this.$emit('activate', friendship)
      },
      onTerminate (friendship: FriendshipStandardDto): void {
        this.$emit('terminate', friendship)
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .customermatchsummarystandard {
    height: 100%;
    .maincolumn {
      background-color: $brand-white;
      border-left: solid 5px $volunteer-color-strong;
      padding-bottom: 15px;
      padding-top: 30px;

      @media (min-width: $media-md) {
        border-right: solid 1px $brand-grey3;
      }
    }

    .sidecolumn {
      background-color: $brand-white;
      .controls {
        a {
          color:$brand-red;
          cursor: pointer;
        }
        .back {
          position: absolute;
          right:20px;
          top: 15px;
        }
      }
    }
  }
</style>
