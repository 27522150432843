<template>
  <div v-if="localCustomer" class="contactinfo">
    <div class="row">
      <div class="col-12">
        <!-- network friend additional info section -->
        <div>
          <div class="row">
            <div class="col-12">
              <h3>{{ $t('customerForm.netFriendTitle') }}</h3>
              <div class="instructions">
                {{ $t('customerForm.netFriendSoMe') }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <forminput v-model="customer.econtactEmail"
                         :hint="$t('connectivity.email')"
                         :is-valid="true"
              />
            </div>
            <div class="col-12 col-sm-6">
              <forminput v-model="customer.econtactTelegram"
                         :hint="$t('connectivity.telegram')"
                         :is-valid="true"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <forminput v-model="customer.econtactKik"
                         :hint="$t('connectivity.kik')"
                         :is-valid="true"
              />
            </div>
            <div class="col-12 col-sm-6">
              <forminput v-model="customer.econtactInstagram"
                         :hint="$t('connectivity.instagram')"
                         :is-valid="true"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-6">
              <forminput v-model="customer.econtactSkype"
                         :hint="$t('connectivity.skype')"
                         :is-valid="true"
              />
            </div>
            <div class="col-12 col-sm-6">
              <forminput v-model="customer.econtactDiscord"
                         :hint="$t('connectivity.discord')"
                         :is-valid="true"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <forminput v-model="customer.econtactFreetext"
                         :hint="$t('connectivity.freetext')"
                         :is-valid="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="buttons">
          <prevnextbuttons
            @prev="onPrevClicked"
            @next="onNextClicked"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import * as _ from 'lodash'
  import forminput from '../SPRSimpleText.vue'
  import prevnextbuttons from '../SPRPrevNextButtons.vue'
  import { FriendCustomerNetDto } from '@/types/index'

  // the data object
  export interface TemplateComponentData {
    localCustomer: FriendCustomerNetDto | undefined
  }

  export default Vue.extend({
    name: 'ContactInfo',
    components: { forminput, prevnextbuttons },
    props: {
      customer: {
        type: Object as () => FriendCustomerNetDto,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        localCustomer: undefined
      }
    },
    mounted (): void {
      this.localCustomer = this.customer
    },
    methods: {
      onPrevClicked (): void {
        this.$emit('prev', this.localCustomer)
      },
      onNextClicked (): void {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit('next', this.localCustomer)
          }
        })
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";

  .contactinfo {
    background-color: $brand-white;
    padding: 15px;
    padding-top: 30px;

    .headertitle {
      margin-bottom: 30px;
    }

    .instructions {
      margin-bottom: 30px;
    }

    .buttons {
      padding-top: 30px;
    }
  }

</style>