export default {
  methods: {
    // Gets the translated lists of enum values
    getGendersTranslations (filter) {
      return this.getTranslations('genders', this.$store.state.genders, filter)
    },
    getGenderPreferencesTranslations (filter) {
      return this.getTranslations('genderPreferences', this.$store.state.preferredGenders, filter)
    },
    getPreferredAgeTranslations (filter) {
      return this.getTranslations('preferredAge', this.$store.state.preferredAge, filter)
    },
    getAvailabilityFrequencyTranslations (filter) {
      return this.getTranslations('availabilityFrequency', this.$store.state.availabilityFrequency, filter)
    },
    getAvailabilityTimesTranslations (filter) {
      return this.getTranslations('availabilityTimes', this.$store.state.availabilityTimes, filter)
    },
    getCustomerTypesTranslations (filter) {
      return this.getTranslations('customerTypes', this.$store.state.customerTypes, filter)
    },
    getNetCustomerTypesTranslations (filter) {
      return this.getTranslations('netCustomerTypes', this.$store.state.netCustomerTypes, filter)
    },
    getFriendshipTypesTranslations (filter) {
      return this.getTranslations('friendshipTypes', this.$store.state.friendshipTypes, filter)
    },
    getInterestsTranslations (filter) {
      return this.getTranslations('customerInterests', this.$store.state.customerInterests, filter)
    },
    getProfileInvolvedEventCategoriesTranslation (id) {
      return this.getTranslation('involvedEventCategories', this.$store.state.involvedEventCategories, id)
    },
    getProfilePreferredContactMethodsTranslation (id) {
      return this.getTranslation('preferredContactMethods', this.$store.state.preferredContactMethods, id)
    },
    getCustomerStatesTranslations (filter) {
      return this.getTranslations('customerStates', this.$store.state.customerStates, filter)
    },
    getGroupTypesTranslations (filter) {
      return this.getTranslations('groupTypes', this.$store.state.groupTypes(), filter)
    },
    getEventCategoriesTranslations (filter) {
      return this.getTranslations('eventCategory', this.$store.state.eventCategories, filter)
    },
    getEventTypesTranslations (filter) {
      return this.getTranslations('eventTypes', this.$store.state.eventTypes, filter)
    },
    getEventRecurrenceTranslations (filter) {
      return this.getTranslations('eventOccurance', this.$store.state.eventRecurrenceFrequencies, filter)
    },
    getTransportationTranslations (filter) {
      return this.getTranslations('transportation', this.$store.state.transportation, filter)
    },
    getLanguages (filter) {
      var list = this.$store.state.allLanguages
      if (filter) {
        list = _.filter(list, filter)
      }
      return list
    },

    // gets individual translations
    getGenderTranslation (id) {
      return this.getTranslation('genders', this.$store.state.genders, id)
    },
    getAvailabilityTimesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getAvailabilityTimesTranslation)
      return translations.join(', ')
    },
    getGenderPreferencesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getGenderPreferencesTranslation)
      return translations.join(', ')
    },
    getAgePreferencesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getAgeTranslation)
      return translations.join(', ')
    },
    getFriendshipTypesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getFriendshipTypesTranslation)
      return translations.join(', ')
    },
    getNetFriendshipTypesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getNetFriendshipTypesTranslation)
      return translations.join(', ')
    },
    getInterestsMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getInterestsTranslation)
      return translations.join(', ')
    },
    getLanguagesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getLanguagesTranslation)
      return translations.join(', ')
    },
    getProfileInvolvedEventCategoriesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getProfileInvolvedEventCategoriesTranslation)
      return translations.join(', ')
    },
    getEventTypesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getEventTypesTranslation)
      return translations.join(', ')
    },
    getProfileInvolvedEventCategoriesMaskItems (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      return elements
    },
    getProfileInvolvedEventCategoriesMaskItemsTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getProfileInvolvedEventCategoriesTranslation)
      return translations
    },
    getProfilePreferredContactMethodsMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getProfilePreferredContactMethodsTranslation)
      return translations.join(', ')
    },
    getCustomerTypesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getCustomerTypesTranslation)
      return translations.join(', ')
    },
    getNetCustomerTypesMaskTranslation (mask) {
      var elements = this.integerIntoBitmaskProxy(mask)
      var translations = _.map(elements, this.getNetCustomerTypesTranslation)
      return translations.join(', ')
    },
    getAgeTranslation (id) {
      return this.getTranslation('preferredAge', this.$store.state.preferredAge, id)
    },
    getAvailabilityFrequencyTranslation (id) {
      return this.getTranslation('availabilityFrequency', this.$store.state.availabilityFrequency, id)
    },
    getGenderPreferencesTranslation (id) {
      return this.getTranslation('genderPreferences', this.$store.state.preferredGenders, id)
    },
    getAvailabilityTimesTranslation (id) {
      return this.getTranslation('availabilityTimes', this.$store.state.availabilityTimes, id)
    },
    getCustomerTypesTranslation (id) {
      return this.getTranslation('customerTypes', this.$store.state.customerTypes, id)
    },
    getNetCustomerTypesTranslation (id) {
      return this.getTranslation('netCustomerTypes', this.$store.state.netCustomerTypes, id)
    },
    getFriendshipTypesTranslation (id) {
      return this.getTranslation('friendshipTypes', this.$store.state.friendshipTypes, id)
    },
    getNetFriendshipTypesTranslation (id) {
      return this.getTranslation('netFriendshipTypes', this.$store.state.netFriendshipTypes, id)
    },
    getInterestsTranslation (id) {
      return this.getTranslation('customerInterests', this.$store.state.customerInterests, id)
    },
    getLanguagesTranslation (id) {
      return this.getTranslation('languages', this.$store.state.groupLanguages, id)
    },
    getCustomerStatesTranslation (id) {
      return this.getTranslation('customerStates', this.$store.state.customerStates, id)
    },
    getGroupTypesTranslation (id) {
      return this.getTranslation('groupTypes', this.$store.state.groupTypes(), id)
    },
    getEventCategoriesTranslation (id) {
      return this.getTranslation('eventCategory', this.$store.state.eventCategories, id)
    },
    getInvolvedEventCategoriesTranslation (id) {
      return this.getTranslation('involvedEventCategories', this.$store.state.involvedEventCategories, id)
    },
    getEventTypesTranslation (id) {
      return this.getTranslation('eventTypes', this.$store.state.eventTypes, id)
    },
    getEventRecurrenceTranslation (id) {
      return this.getTranslation('eventOccurance', this.$store.state.eventRecurrenceFrequencies, id)
    },
    getTransportationTranslation (id) {
      return this.getTranslation('transportation', this.$store.state.transportation, id)
    },
    getLanguage (isoCode) {
      var langs = this.getLanguages(function (lang) {
        return lang.isoCode === isoCode
      })

      return langs && langs.length ? langs[0] : null
    },
    getTranslation (keyPrefix, list, id) {
      // todo : use method below
      var translations = this.getTranslations(keyPrefix, list, function (item) {
        return item.id === id
      })

      if (translations && translations.length > 0) return translations[0]

      return ''
    },

    getTranslations (keyPrefix, list, filter) {
      // filter is a predicate function to be run on each item of the list
      // it takes each item in the list as an argument
      var self = this
      var items = list
      if (filter) {
        items = _.filter(list, filter)
      }
      return _.map(items, function (item) {
        const prefix = self.lowerFirstLetter(keyPrefix)
        const text = self.lowerFirstLetter(item.text)
        return self.$t(`${prefix}.${text}`)
      })
    },
    lowerFirstLetter (s) {
      return s.charAt(0).toLowerCase() + s.slice(1)
    },
    /**
     * Parses an array of result entries into a translated comma separated string.
     * @param {Array} array - Array to be parsed
     * @param {{id: Number, text: String}[]} storeCollection - Vuex store state collection used for getting the translations, or null if translations are not required
     * @param {String} transKey - Translation key prefix for getting the translations, or null if translations are not required
     * @returns {String} A comma separated string of the parsed non-empty values or empty string if array is invalid.
     */
    parseArray: function (array, storeCollection, transKey) {
      if (!array || !array.length) {
        return ''
      }
      let results = []
      if (storeCollection && storeCollection.length && transKey) {
        results = this.getTranslations(transKey, storeCollection, function (x) {
          return array.indexOf(x.id) >= 0
        })
      } else {
        results = array.filter(Boolean)
      }
      return results.join(', ')
    },

    integerIntoBitmaskProxy: function (integer) {
      if (integer <= 0) {
        return []
      }

      var rv = []
      for (var i = 0; i < 32; i++) {
        var mask = Math.pow(2, i)
        if ((integer & mask) !== 0) {
          rv.push(mask)
        }
      }

      return rv
    }
  }
}
