<template>
  <div class="list-group">
    <div class="row">
      <div class="col col-md-2 col-sm-12 cap-label table-header">
        {{ $t('userAdmin.eventName') }}
      </div>
      <div class="col col-md-2 col-sm-12 cap-label table-header">
        {{ $t('userAdmin.eventTime') }}
      </div>
      <div class="col col-md-2 col-sm-12 cap-label table-header">
        {{ $t('userAdmin.eventPlace') }}
      </div>
      <div class="col col-md-2 col-sm-12 cap-label table-header">
        {{ $t('userAdmin.eventCategory') }}
      </div>
      <div class="col col-md-2 col-sm-12 cap-label table-header">
        {{ $t('userAdmin.eventType') }}
      </div>
      <div class="col col-md-2 col-sm-12 cap-label table-header">
        {{ $t('userAdmin.eventOrganizer') }}
      </div>
    </div>

    <div v-for="(item) in list" :key="item.id" class="group-row">
      <useradmineventview :sprevent="item" />
    </div>
  </div>
</template>

<script>
  import useradmineventview from './EventView.vue'

  export default {
    name: 'Useradmingrouplist',
    components: { useradmineventview },
    props: {
      list: {
        type: Array,
        default: () => []
      }
    }
  }
</script>
