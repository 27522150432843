<template>
  <div class="spr-simple-dropdown">
    <div class="form-group">
      <label for="exampleFormControlSelect1" class="cap-label">{{ requiredTitle }}</label>
      <div class="spr-roundbox-1" :class="{ 'spr-danger': !isValid, 'disabled': disabled }">
        <select :id="_uid" class="form-control" :value="value" :disabled="disabled" @change="$emit('input', $event.target.value.toString())">
          <option v-if="placeholder" disabled :value="null">
            {{ placeholder }}
          </option>
          <option v-for="option in items" :key="option.value" :value="option.value" :selected="option.value === value ? 'selected' : ''">
            {{ option.text }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'

  export default Vue.extend({
    name: 'SprSimpleDropdown',

    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      items: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String,
        default: ''
      },
      required: Boolean,
      disabled: Boolean,
      isValid: Boolean
    },
    computed: {
      requiredTitle (): string {
        return this.title + (this.isRequired ? '*' : '')
      },
      isRequired (): boolean {
        if (this.required) {
          return this.required
        } else {
          return false
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../assets/scss/_variables.scss";

  .spr-simple-dropdown {
    .spr-roundbox-1 {

      &.spr-danger {
        border: 2px solid $brand-red !important;
      }

      &.disabled {
        cursor: not-allowed;

        .form-control {
          cursor: not-allowed;
        }
      }

    }
    label {
      color:$brand-red;
      padding:0 20px;
    }
    select {
      border-radius:2rem;
      :active,
      :hover {
        outline-color:$brand-red;
        box-shadow: 0 0 0 0.2rem $brand-grey3;
      }

      > option:hover {
        box-shadow: 0 0 10px 100px #fff inset;
      }

      ::after {
        content: "asdasd";
        display: block;
        width:30px;
        border:solid 1px red;
      }

      &:focus-visible {
        color: #495057;
        border: solid 2px $brand-grey5;
        outline: 0;
        box-shadow: 0 0 0 0.2rem $brand-grey3;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

    }
  }

</style>
