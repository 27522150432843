<template>
  <div class="comp-wizard-content">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <span class="h1"
                    role="heading"
                    aria-level="1"
              >{{ $t('wizard.headerEight') }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <sprvalidation class="validation-box"
                             :validation-errors="validationErrors"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <sprinput v-model="streetaddress"
                        v-validate="'required'"
                        :hint="$t('wizard.streetAddressField')"
                        :is-valid="!errors.has('streetAddressField')"
                        class="bs-input"
                        name="streetAddressField"
                        dark
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm-4">
              <sprinput v-model="zipcode"
                        v-validate="'required|digits:5'"
                        :hint="$t('wizard.zipCodeField')"
                        class="bs-input"
                        name="zipCodeField"
                        :is-valid="!errors.has('zipCodeField')"
                        dark
              />
            </div>
            <div class="col-12 col-sm-8">
              <sprinput v-model="city"
                        v-validate="'required'"
                        :hint="$t('wizard.cityField')"
                        class="bs-input"
                        :is-valid="!errors.has('cityField')"
                        name="cityField"
                        dark
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <sprinput v-model="country"
                        v-validate="'required'"
                        :hint="$t('wizard.countryField')"
                        class="bs-input"
                        :is-valid="!errors.has('countryField')"
                        name="countryField"
                        dark
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-2">
              <sprdatepicker v-model="age"
                             v-validate="birthdateValidation"
                             :in-title="$t('wizard.ageField') + ': '"
                             class="d-inline-block fill-width wizard-style"
                             name="ageField"
                             :is-valid="!errors.has('ageField') && !dateIsValid"
                             :wizard-style="true"
                             dark
              />
            </div>
            <div class="col-12 col-sm-6">
              <sprnativelanguageselector v-model="language"
                                         class="spr-roundbox-1 spr-shadow pl-3"
                                         :lang-pool="$store.state.wizardModel.spokenLanguages"
                                         name="nativelanguage"
              />
            </div>
          </div>
          <!-- PreferredContactMethod -->
          <div class="row">
            <div class="col-12 text-left">
              <span class="subheader">{{ $t('wizard.preferredContactMethod') }}</span>
            </div>
            <div class="col-12">
              <checkboxlist v-model="preferredContactMethods"
                            :list="preferredContactMethodOptions"
                            :rows="1"
                            @input="vuixCommit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import sprinput from '../SPRInput.vue'
  import sprvalidation from '../SPRValidationError.vue'
  import sprnativelanguageselector from '../SPRNativeLanguageSelector.vue'
  import sprdatepicker from '../SPRSimpleDatepickerDashYear.vue'
  import checkboxlist from '../SPRSimpleCheckboxBitmaskList.vue'
  import locationutils from '../../mixins/locationutils.js'
  import * as _ from 'lodash'
  import { SprCheckboxBitmaskValue } from '../../types/custom'
  import datetwoyears from '../../helpers/datehelper'
  // the data object
  export interface TemplateComponentData {
    streetaddress: string,
    zipcode: string,
    city: string,
    country: string,
    age: string,
    language: string,
    preferredContactMethods: number,
    validatedFields: Array<string>
  }

  export default Vue.extend({
    name: 'WizardEight',
    components: { sprinput, sprvalidation, sprnativelanguageselector, sprdatepicker, checkboxlist },
    mixins: [locationutils],
    data (): TemplateComponentData {
      return {
        streetaddress: this.$store.state.wizardModel.streetAddress,
        zipcode: this.$store.state.wizardModel.zipCode,
        city: this.$store.state.wizardModel.city,
        country: this.$store.state.wizardModel.country ? this.$store.state.wizardModel.country : 'Suomi',
        age: this.$store.state.wizardModel.age,
        language: this.$store.state.wizardModel.nativeLanguage ? this.$store.state.wizardModel.nativeLanguage : this.$store.state.language,
        preferredContactMethods: this.$store.state.wizardModel.preferredContactMethods,
        validatedFields: ['streetAddressField', 'zipCodeField', 'cityField', 'countryField', 'ageField']
      }
    },
    computed: {
      birthdateValidation () {
        return 'required' //datetwoyears.birthdateValidation()
      },
      dateIsValid (): boolean {
        if (!this.age) return false
        else return !this.age.toString().includes('0000')
      },
      preferredContactMethodOptions (): Array<SprCheckboxBitmaskValue> {
        var self = this
        var validItems = _.filter(this.$store.state.preferredContactMethods, (x) => x.id !== 0)

        return _.map(validItems, function (val, key) {
          return { value: val.id, text: self.$t('wizard.preferredContactMethodOption' + val.id) } as SprCheckboxBitmaskValue
        })
      },
      validationErrors: function (): Array<Array<string>> {
        if (!this.validatedFields) {
          return []
        }
        var arr = [] as Array<Array<string>>
        var self = this
        this.validatedFields.forEach(function (fieldName) {
          var err = _.find(self.errors.items, function (item) {
            return item.field === fieldName
          })
          if (err != null) {
            arr.push(['', err.field + '_' + err.rule, ''])
          }
        })
        return arr
      }
    },
    watch: {
      streetaddress: function () {
        this.errors.remove('streetAddressField')
        this.vuixCommit()
      },
      zipcode: function (val) {
        this.errors.remove('zipCodeField')
        if (val.length === 5) {
          var self = this
          // @ts-ignore
          this.cityFromZip(val, this, response => {
            self.city = self.$store.state.language === 'se' ? response.body.citySe : response.body.cityFi
          })
        }
        this.vuixCommit()
      },
      city: function () {
        this.errors.remove('cityField')
        this.vuixCommit()
      },
      country: function () {
        this.errors.remove('countryField')
        this.vuixCommit()
      },
      age: function () {
        this.errors.remove('ageField')
        this.vuixCommit()
      },
      language: function () {
        this.vuixCommit()
      }
    },
    methods: {
      vuixCommit: function () {
        this.$store.commit('setWizardContactInformation', {
          streetaddress: this.streetaddress,
          zipcode: this.zipcode,
          city: this.city,
          country: this.country,
          age: this.age,
          language: this.language,
          preferredContactMethods: this.preferredContactMethods
        })
      },
      validateSection: function () {
        return this.$validator.validateAll()
      }
    }
  })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '~@/assets/scss/_variables.scss';
  @import '~@/assets/scss/_wizard.scss';

  .bs-input {
    width: 100%;
  }
  .container {
    max-width: 600px;
    color: $brand-white;
    text-align: left;

    .subheader {
      color:$brand-white;
      text-transform: uppercase;
      font-family: $font-bold;
      margin: 20px 0 20px 0;
      display:block;
      font-size: 14px;
    }
  }

  .wizard-style {
    .select-container {
      border: none;
      background: white;
    }
  }
</style>
