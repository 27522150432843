<template>
  <div class="editor-deleteprofile my-5">
    <myProfileUsageReport
      v-if="profileUsageReportData"
      :report-data="profileUsageReportData"
      @deleteprofile="deleteMyUserProfile"
    />
    <div v-if="loading" class="loading">
      <loading />
    </div>
  </div>
</template>

<script lang="ts">

  import Vue from 'vue'
  import { MyProfileUsageDto } from '@/types'
  import myProfileUsageReport from './MyUserProfileUsageReport.vue'
  import loading from '../SPRLoading.vue'

  // the data object
  export interface TemplateComponentData {
    loading: boolean,
    profileUsageReportData: MyProfileUsageDto | undefined
  }

  export default Vue.extend({
    name: 'EditorDeleteProfile',
    components: { myProfileUsageReport, loading },
    data (): TemplateComponentData {
      return {
        loading: false,
        profileUsageReportData: undefined
      }
    },
    mounted (): void {
      var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.usermyprofileusage[0]
      this.loading = true
      this.$http.get(uri).then(succ => {
        if (succ.data) {
          this.profileUsageReportData = succ.data // JSON.parse(succ.data)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
        this.loading = false
      }, fail => {
        console.log(fail)
        this.loading = false
        // @ts-ignore
        this.showAlertError(this.$t('common.fetchFailed'))
      })
    },
    methods: {
      deleteMyUserProfile (isOnlyDeletionRequest: boolean): void {
        var uri = this.$store.state.apiBases.userservice + this.$store.state.apiEndpoints.deletemyprofile[0]
        this.loading = true
        this.$http.delete(uri).then(succ => {
          if (succ.ok) {
            if (isOnlyDeletionRequest) {
              // @ts-ignore
              this.showAlertSuccess(this.$t('deleteMyProfile.deletionRequestSent'))
            }
            // @ts-ignore
            this.logout()
            // For type C deletions, they do not get deleted straight away, as admin needs to manually remove their friendships, roles, change the owner of events they are the creator of etc.
            var isOnlyRequestQS = isOnlyDeletionRequest
              ? '?deletionrequest=true'
              : ''
            this.$router.push(`/thankyou${isOnlyRequestQS}`)
          } else {
            // @ts-ignore
            this.showAlertError(this.$t('common.fetchFailed'))
          }
          this.loading = false
        }, fail => {
          console.log(fail)
          this.loading = false
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        })
      }
    }
  })
</script>

<style lang="scss">
@import '~@/assets/scss/_variables.scss';
.editor-deleteprofile {
  .loading {
    text-align: center;
    margin-top: 5px;
  }
}
</style>