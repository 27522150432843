<template>
  <div class="container">
    <div class="customerstandardform">
      <div class="row">
        <div class="col-12">
          <backbar />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2>{{ addEditTitle }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center py-2">
          <validation :id="'validationTarget' + _uid"
                      ref="validationtarget"
                      :validation-errors="validationErrors"
                      exclude-field-reference
                      include-title
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <ApprovalForm
            v-if="customer"
            :value="computedApprovalData"
            @input="onApprovalDataChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <forminput
            v-if="customer"
            v-model="customer.email"
            v-validate="'email'"
            :hint="$t('customerForm.email')"
            :is-valid="!errors.has('customerForm.email')"
            name="customerForm.email"
          />
        </div>
        <div class="col-12 feature-panel">
          <CustomerCommon v-if="customer"
                          :customer="customer"
                          @input="onCustomerCommonChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col">
          <h3>{{ $t('customerForm.applicationInformation') }}</h3>
        </div>
        <div class="col-12">
          <InterviewForm
            v-if="customer"
            :value="computedInterviewData"
            :disabled="isStateChangeDisabled"
            :translation-key="'customerForm.customerInterviewed'"
            @input="onInterviewDataChanged"
          />
        </div>
        <div class="col-12">
          <OnHoldForm
            v-if="customer"
            :value="computedOnHoldData"
            :disabled="isStateChangeDisabled"
            @input="onOnHoldDataChanged"
          />
        </div>
        <div class="col-12">
          <SuitabilityForm
            v-if="customer"
            :value="computedSuitabilityData"
            @input="onSuitabilityDataChanged"
          />
        </div>
      </div>
      <div class="row feature-panel">
        <div class="col-12">
          <textareainput
            v-if="customer"
            v-model="customer.privateNotes"
            :title="$t('volunteerForm.privateNotes')" :rows="6"
          />
        </div>
      </div>
      <div class="row feature-panel buttons">
        <div class="col-6 cancel">
          <SprButton type="button"
                     :size="1"
                     :title="$t('volunteerForm.cancel')"
                     click-event="onCancel"
                     @onCancel="cancel"
          />
        </div>
        <div class="col-6 save">
          <SprButton type="button"
                     :size="1"
                     :disabled="isSaving"
                     :title="$t('volunteerForm.save')"
                     click-event="onSave"
                     @onSave="save"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { LocaleMessage } from 'vue-i18n'
  import SprButton from '@/components/SPRButton.vue'
  import forminput from '../SPRSimpleText.vue'
  import textareainput from '../SPRSimpleTextArea.vue'
  import CustomerCommon from '@/components/friends/CustomerCommon.vue'
  import InterviewForm from '@/components/friends/InterviewForm.vue'
  import ApprovalForm from '@/components/friends/ApprovalForm.vue'
  import OnHoldForm from '@/components/friends/OnHoldForm.vue'
  import SuitabilityForm from '@/components/friends/SuitabilityForm.vue'
  import backbar from '../BackBar.vue'
  import validation from '../SPRValidationError.vue'
  import Alerts from '@/mixins/alerts.js'
  import { ErrorBag, FieldError } from 'vee-validate'
  import { AvailabilityFrequence, FriendCustomerStandardDto, PreferredGenders, Gender, CustomerState, CustomerTypes, Interests, PreferredAges, AvailabilityTimes } from '@/types/index'
  import { InterviewData, OnHoldData, ApprovalData, SuitabilityData } from '@/types/custom'

  // the data object
  export interface TemplateComponentData {
    loaded: boolean,
    isSaving: boolean,
    customer: FriendCustomerStandardDto | undefined
  }

  export default Vue.extend({
    name: 'CustomerStandardForm',
    components: { validation, backbar, textareainput, ApprovalForm, CustomerCommon, InterviewForm, OnHoldForm, SuitabilityForm, SprButton, forminput },
    mixins: [Alerts],
    props: {
      customerId: {
        type: Number,
        required: false, // only passed if its an edit
        default: 0
      },
      groupId: {
        type: Number,
        required: true
      }
    },
    data (): TemplateComponentData {
      return {
        loaded: false,
        isSaving: false,
        customer: undefined
      }
    },
    computed: {
      isEdit (): boolean {
        return this.customerId > 0
      },
      addEditTitle (): string | LocaleMessage {
        return this.isEdit
          ? this.$t('customerForm.editCustomerTitle')
          : this.$t('customerForm.newCustomerTitle')
      },
      validationErrors (): Array<Array<string>> {
        var errs: ErrorBag = this.errors ? this.errors : []
        if (errs.items.length) {
          return errs.items.map((err: FieldError) => {
            return [`error_${err.field}`, err.rule ? err.rule : '', err.msg ? err.msg : '']
          })
        }
        return []
      },
      computedInterviewData (): InterviewData | null {
        if (!this.customer) return null

        var d = new Date()
        var defaultDate = new Date(`${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate().toString()}T00:00:00Z`) // must be in UTC
        return {
          interviewed: this.customer.customerInterviewed,
          interviewedBy: this.customer.customerInterviewedBy,
          interviewedDate: this.customer.customerInterviewedOnDate || defaultDate
        } as InterviewData
      },
      computedOnHoldData (): OnHoldData | null {
        if (!this.customer) return null

        return {
          onHold: Boolean(this.customer.onHoldUntilDate),
          onHoldDeadline: this.customer.onHoldUntilDate,
          onHoldDetails: this.customer.onHoldComment
        } as OnHoldData
      },
      computedApprovalData (): ApprovalData | null {
        if (!this.customer) return null

        // weirdly, this contains two items of info in the database
        var approvalData = this.customer.customerApprovedBy
        var fields = approvalData?.split(' || ')
        if (fields && fields.length === 2) {
          return {
            approval: this.customer.customerApproval,
            approvalDate: fields[0],
            approvedBy: fields[1]
          } as ApprovalData
        }

        return {
          approval: this.customer.customerApproval,
          approvalDate: '',
          approvedBy: ''
        } as ApprovalData
      },
      computedSuitabilityData (): SuitabilityData | null {
        if (!this.customer) return null

        var notForPrivateData = this.customer.customerNotForPrivateRelationsBy
        var notForSprData = this.customer.customerNotForSprBy
        var pFields = notForPrivateData?.split(' || ')
        var sFields = notForSprData?.split(' || ')
        return {
          notForPrivateRelations: this.customer.customerNotForPrivateRelations,
          notForSpr: this.customer.customerNotForSpr,
          notForPrivateRelationsReason: pFields ? pFields[0] : '',
          notForPrivateRelationsBy: pFields && pFields.length > 1 ? pFields[1] : '',
          notForSprReason: sFields ? sFields[0] : '',
          notForSprBy: sFields && sFields.length > 1 ? sFields[1] : ''
        } as SuitabilityData
      },
      isStateChangeDisabled (): boolean {
        if (!this.customer) return true

        /// Cannot change the state if the customer has friendships
        return this.customer.state === CustomerState.PendingFriend ||
          this.customer.state === CustomerState.ActiveFriendship ||
          Boolean(this.customer.friendships?.length)
      }
    },
    provide (): Record<string, any> {
      return {
        $validator: this.$validator // allow child components to have validator injected
      }
    },
    mounted (): void {
      if (this.isEdit) {
        var url = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customergetstandardbyid[0].replace('{customerid}', this.customerId).replace('{groupid}', this.groupId)
        this.$http.get(url, this.customer).then((result: any) => {
          this.customer = result.body
          this.loaded = true
        }, (err: Response | any) => {
          this.displayError(err)
        })
      } else {
        this.customer = {
          availabilityFrequency: AvailabilityFrequence.OncePerWeek,
          birthdate: new Date(),
          city: '',
          contactCity: '',
          contactEmail: '',
          contactFirstName: '',
          contactLastName: '',
          contactPhonenumber: '',
          contactRelation: '',
          contactStreetAddress: '',
          contactZipCode: '',
          customerApproval: false,
          customerApprovedBy: '',
          customerInterviewed: false,
          customerInterviewedBy: '',
          customerInterviewedOnDate: new Date(),
          customerLanguages: [],
          customerNotForPrivateRelations: false,
          customerNotForPrivateRelationsBy: '',
          customerNotForSpr: false,
          customerNotForSprBy: '',
          customerTypeOtherInfo: '',
          customerTypes: CustomerTypes.NotDefined,
          description: '',
          firstName: '',
          friendManagementGroupId: this.groupId,
          friendPools: [],
          friendships: [],
          fullname: '',
          gender: Gender.Male,
          gsmPhone: '',
          homePhone: '',
          id: 0,
          interestOther: '',
          interests: Interests.NotDefined,
          lastFriendshipEndDate: new Date(),
          lastName: '',
          multipleVolunteers: false,
          nativeLanguage: 'fi',
          onHold: false,
          onHoldComment: '',
          onHoldUntilDate: undefined,
          otherContact: '',
          otherLanguages: '',
          preferredAges: PreferredAges.NotDefined,
          preferredGenders: PreferredGenders.Other | PreferredGenders.Male | PreferredGenders.Female,
          privateNotes: '',
          shortDescription: '',
          startedPending: new Date(),
          state: CustomerState.PendingApproval,
          streetAddress: '',
          zipCode: '',
          availabilityTimes: AvailabilityTimes.NotDefined,
          email: ''
        } as FriendCustomerStandardDto
        this.loaded = true
      }
    },
    methods: {
      failLoad (): void {
        this.displayError(this.$t('common.fetchFailed'))
      },
      onCustomerCommonChanged (newCustomer: FriendCustomerStandardDto): void {
        this.customer = newCustomer
      },
      onInterviewDataChanged (newData: InterviewData): void {
        if (!this.customer) return

        this.customer.customerInterviewed = newData.interviewed
        this.customer.customerInterviewedBy = newData.interviewedBy
        this.customer.customerInterviewedOnDate = newData.interviewedDate
      },
      onOnHoldDataChanged (newData: OnHoldData): void {
        if (!this.customer) return

        this.customer.onHoldUntilDate = newData.onHold ? newData.onHoldDeadline : undefined
        this.customer.onHoldComment = newData.onHold ? newData.onHoldDetails : ''
      },
      onApprovalDataChanged (newData: ApprovalData): void {
        if (!this.customer) return

        this.customer.customerApproval = newData.approval
        this.customer.customerApprovedBy = `${newData.approvalDate} || ${newData.approvedBy}`
      },
      onSuitabilityDataChanged (newData: SuitabilityData): void {
        if (!this.customer) return

        this.customer.customerNotForPrivateRelations = newData.notForPrivateRelations
        this.customer.customerNotForPrivateRelationsBy = `${newData.notForPrivateRelationsReason} || ${newData.notForPrivateRelationsBy}`
        this.customer.customerNotForSpr = newData.notForSpr
        this.customer.customerNotForSprBy = `${newData.notForSprReason} || ${newData.notForSprBy}`
      },
      cancel (): void {
        const url = this.isEdit
          ? `/friend/customerpreview/${this.groupId}/${this.customerId}`
          : `/friend/search/${this.groupId}/customer`
        this.$router.push(url)
      },
      save (): void {
        if (this.customer?.preferredAges === PreferredAges.NotDefined) {
          this.customer.preferredAges = 1
          this.$nextTick(() => {
            if (!this.customer) return
            this.customer.preferredAges = PreferredAges.NotDefined
            this.validateAndSave()
          })
        } else {
          this.validateAndSave()
        }
      },
      validateAndSave (): void {
        this.$validator.validateAll().then(result => {
          // check any child controls have not added errors
          if (!result || (this.validationErrors && this.validationErrors.length)) {
            var target = this.$refs['validationtarget']
            if (target) {
              window.scrollTo({
                // @ts-ignore
                top: target.$el.parentElement.clientHeight,
                left: 0,
                behavior: 'smooth'
              })
            }
            return
          }
          if (this.customer) {
            if (this.isEdit) {
              this.updateCustomer()
            } else {
              this.addCustomer()
            }
          }
        })
      },
      addCustomer (): void {
        this.isSaving = true
        const addUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customeraddstandard[0]
        this.$http.post(addUrl, this.customer).then((result: any) => {
          this.isSaving = false
          this.$router.push(`/friend/search/${this.groupId}/customer`)
        }, (err: Response | any) => {
          this.isSaving = false
          this.displayError(err)
        })
      },
      updateCustomer (): void {
        this.isSaving = true
        const updateUrl = this.$store.state.apiBases.friendservice + this.$store.state.apiEndpoints.customerupdatestandard[0]
        this.$http.put(updateUrl, this.customer).then((result: any) => {
          this.isSaving = false
          this.$router.push(`/friend/search/${this.groupId}/customer`)
        }, (err: Response | any) => {
          this.isSaving = false
          this.displayError(err)
        })
      },
      displayError (err: Response | any): void {
        if (Array.isArray(err.body)) {
          err.body.forEach(x => {
            // @ts-ignore
            this.showAlertError(x.errorMessage)
          })
        } else if (err.body && err.body.errorMessage) {
          // @ts-ignore
          this.showAlertError(err.errorMessage)
        } else if (err.bodyText) {
          // @ts-ignore
          this.showAlertError(err.bodyText)
        } else {
          // @ts-ignore
          this.showAlertError(this.$t('common.fetchFailed'))
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../assets/scss/_variables.scss";
  .customerstandardform {
    .nav-header {
      border-bottom: none;
    }
    .feature-panel {
      background-color: $brand-white;
      margin-bottom: 30px;
      padding-top: 15px;

      &.buttons {
        margin-top: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        .cancel {
          text-align: left;
        }
        .save {
          text-align: right;
        }
      }
    }
  }
</style>
