<template>
  <div class="searchsort" :class="{'open': isOpen}">
    <a href="javascript:void(0)" @click="changeSort">
      {{ computedSortBy }}<font-awesome-icon icon="chevron-down" />
    </a>
    <div v-show="isOpen" class="sortbox">
      <ul>
        <li v-for="(sortitem) in list" :key="sortitem.type" @click="chooseItem(sortitem)">
          {{ sortitem.text }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'SPRSearchSort',
    props: {
      value: {
        type: String,
        default: ''
      },
      list: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        isOpen: false
      }
    },
    computed: {
      computedSortBy () {
        var self = this
        if (self.value) {
          var selectedItem = _.find(self.list, function (o) {
            return o.type === self.value
          })

          if (selectedItem) return selectedItem.text
        }
        return ''
      }
    },
    methods: {
      changeSort: function () {
        this.isOpen = !this.isOpen
      },
      chooseItem: function (item) {
        this.isOpen = false
        this.$emit('input', item.type)
      }
    }
  }
</script>

<style lang="scss">
  @import "../assets/scss/_variables.scss";

  .searchsort {
    position: absolute;
    z-index:1000;

    &.open {
      box-shadow: 2px 2px 5px $brand-grey5;
    }

    a {
      display:block;
      padding:5px 10px;
      background-color:$brand-white;
       color: $brand-red;
       text-decoration: none;
     }
    svg {
      margin-left: 10px;
    }

    .sortbox {
      width:100%;
      border-top:0;
      z-index:1000;
      background-color:$brand-white;
      ul {
        margin:0;
        padding:0;
        li {
          list-style-type: none;
          padding:3px 10px;
          cursor: pointer;
        }
      }
    }
  }

</style>
